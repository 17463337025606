


import * as types from './types'

// Matches

export const selectTournament = (tournament) => ({ type: types.SELECT_TOURNAMENT, tournament})

export const hideSidebar = () => ({ type: types.UI_HIDE_SIDEBAR })

export const selectPlayer = (gameId, playerId, theme) => ({ type: types.UI_SELECT_PLAYER, gameId, playerId, theme })


export const selectMatch = (matchId) => ({ type: types.UI_SELECT_MATCH, matchId })


export const showGroupSettings = (view) => ({ type: types.UI_SHOW_GROUP_SETTINGS, view })


export const statisticsFilter = (options) => ({ type: types.UI_STATISTICS_FILTER, options })
export const setScheduleFilter = (options) => ({ type: types.UI_SCHEDULE_FILTER, options })

// Flyouts

export const pushFlyout = (view, props) => ({ type: types.UI_PUSH_FLYOUT, view, props })
export const popFlyout = () => ({ type: types.UI_POP_FLYOUT })
export const clearFlyouts = () => ({ type: types.UI_CLEAR_FLYOUTS })


// Filters

export const setData = (name, data) => ({ type : types.UI_SET_DATA, name, data })
export const replaceData = (name, data) => ({ type : types.UI_REPLACE_DATA, name, data })
export const clearData = (name) =>  ({ type : types.UI_CLEAR_DATA, name })

export const updateNotificationCount = (count) =>  ({ type : types.UPDATE_NOTIFICATION_COUNT, count })


// Titles

export const setTitle = (title) => ({ type : types.UI_SET_TITLE, title })

// Modal

export const hideModal = () => ({ type : types.HIDE_MODAL })
export const showModal = (name, props, callback) => ({ type : types.SHOW_MODAL, name, props, callback })



