import React, { useEffect, useRef, useState } from 'react'

import { space, layout } from 'styled-system'

import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import Icon from './Icon'


export const StyledContainer = styled(animated.div)`
    position: relative;

    overflow: hidden;

    ${ space }
    ${ layout }
`

const Fade = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--link-color);

    cursor: pointer;

    left: 0;
    right: 0;
    bottom: 0; 

    height: 48px;

    background-image: linear-gradient(to bottom, transparent, var(--card-background-color));
`


export const More = ({ children, maximumHeight, ...otherProps }) => {
    var ref = useRef();
    var fadeRef = useRef();
    var [showAll, setShowAll] = useState(false);

    useEffect(() => {
        if (fadeRef.current && ref.current) {
            fadeRef.current.style.display = (ref.current.offsetHeight > maximumHeight) ? 'flex' : 'none';
        }
    }, [children])

    const props = useSpring({ maxHeight: showAll ? 9999 : maximumHeight })
    
    return (
        <StyledContainer {...otherProps} style={ props }>
            <div ref={ ref }>
                { children }
            </div>

            {
                !showAll &&
                <Fade ref={ fadeRef } onClick={ () => setShowAll(true) }>
                    <Icon type="chevronDown" />
                </Fade>
            }
        </StyledContainer>
    )
}