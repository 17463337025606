import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'
import { fullName } from 'utils'

class WithPlayer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        const player = this.props.player && { ...this.props.player, ...this.props.extra };
        return this.props.children(player);
    }
}


WithPlayer.propTypes = {
    id : PropTypes.any.isRequired
}


export const makeGetPlayer = () => (
    createSelector([
        (state, props) => props.id,

        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.teams,
        (state) => state.entities.positions
    ], 
    (id, players, persons, teams, positions) => { 
        
        const player = players[id];
        if (!player)
            return null;

        const person = persons[player.person];
        const team = teams[player.team];
        const position = positions[player.position];
        
        return {
            ...player,
            person : {
                ...person,
                fullName : fullName(person)
            },
            team,
            position
        }
    })
);



const makeMapStateToProps = () => {
    const getPlayer = makeGetPlayer();

    return (state, ownProps) => ({
        player : getPlayer(state, ownProps)
    })
}




export default connect(makeMapStateToProps)(WithPlayer);

