import React from 'react'
import PropTypes from 'prop-types'

import { lo } from '../common/utils'

class MatchTitle extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    renderVersus() {
        const { match } = this.props;
        const homeParticipant = match.participants.find(p => p.type === 'home');
        const awayParticipant = match.participants.find(p => p.type === 'away');

        return (
            <div className="grid-x" style={{ minWidth : '96px' }}>
                <div className="cell small-4 text-left">{ homeParticipant && lo(homeParticipant.team, 'abbreviation') }</div>
                <div className="cell small-4 text-center">
                    { match.properties && match.properties.homeScore }
                    -
                    { match.properties && match.properties.awayScore }
                </div>
                <div className="cell small-4 text-right">{ awayParticipant && lo(awayParticipant.team, 'abbreviation') }</div>
            </div>
        )
    }
  
    render() {
        switch(this.props.format) {
            case 'versus':
                return this.renderVersus();
            default:
                return <span>{ this.props.match.name }</span>
        }
    }  
}

MatchTitle.propTypes = {
    format : PropTypes.string.isRequired,
    match : PropTypes.object.isRequired,
}

MatchTitle.defaultProps = {
    type : 'named'
}

export default MatchTitle;
