import React from 'react'
import PropTypes from 'prop-types'
import Checkable from './Checkable'

class RadioButtonList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.clickHandler = this.clickHandler.bind(this);
    }

    clickHandler(item) {
        if (!item.disabled) {
            this.props.onChange(item.value);
        }
    }


    render() {
        const children = this.props.items.map((item, index) => {
            
            const { disabled, value } = item;
            const checked = this.props.value === value;

            return <Checkable key={ index } checkedIcon="radiomarkChecked" uncheckedIcon="radiomark" checked={ checked } disabled={ disabled } onClick={ () => this.clickHandler(item) }>
                        { item.text }
                   </Checkable>
        })

        return (
            <div>
                { children }
            </div>
        )     
    }
}


RadioButtonList.propTypes = {
    value : PropTypes.any,
    items : PropTypes.array.isRequired,
    onChange : PropTypes.func.isRequired
}

RadioButtonList.defaultProps = {
    items : []
}

export default RadioButtonList;