// Fetch my competitions

export const FETCH_FRIENDS_REQUEST        = 'friends/fetch/request';
export const FETCH_FRIENDS_SUCCESS        = 'friends/fetch/success';
export const FETCH_FRIENDS_FAIL           = 'friends/fetch/fail';

export const FETCH_MORE_FRIENDS_REQUEST        = 'FETCH_MORE_FRIENDS_REQUEST';
export const FETCH_MORE_FRIENDS_SUCCESS        = 'FETCH_MORE_FRIENDS_SUCCESS';
export const FETCH_MORE_FRIENDS_FAIL           = 'FETCH_MORE_FRIENDS_FAIL';


export const INVALIDATE_FRIENDS           = 'friends/invalidate';
