// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S_PcKTEhOLkSU6BofuHO{background-color:var(--game-color);--badge-border-color: var(--game-color);--link-color: var(--game-header-link-color);--text-color: var(--game-header-text-color);background-image:var(--game-background-image);background-repeat:no-repeat;background-position:center center;color:#fff}.yqbM9FE2uUM4tgdwPofv{background-repeat:no-repeat;background-position:center center}.yqbM9FE2uUM4tgdwPofv .OC11aGCeAAMwXHBie9_P{max-width:1200px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./src/components/GameHeader.scss"],"names":[],"mappings":"AAAA,sBACI,kCAAA,CACA,uCAAA,CAEA,2CAAA,CACA,2CAAA,CAEA,6CAAA,CACA,2BAAA,CAEA,iCAAA,CACA,UAAA,CAIJ,sBACI,2BAAA,CACA,iCAAA,CAIJ,4CACI,gBAAA,CACA,aAAA","sourcesContent":[".gameHeader {\r\n    background-color: var(--game-color);\r\n    --badge-border-color: var(--game-color);\r\n\r\n    --link-color: var(--game-header-link-color);\r\n    --text-color: var(--game-header-text-color);\r\n\r\n    background-image: var(--game-background-image);\r\n    background-repeat: no-repeat;\r\n    //background-size: cover;\r\n    background-position: center center;\r\n    color: #fff;\r\n\r\n}\r\n\r\n.global {\r\n    background-repeat: no-repeat;\r\n    background-position: center center;\r\n    \r\n}\r\n\r\n.global .content {\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n}"],"sourceRoot":""}]);
// Exports
export var gameHeader = `S_PcKTEhOLkSU6BofuHO`;
export var global = `yqbM9FE2uUM4tgdwPofv`;
export var content = `OC11aGCeAAMwXHBie9_P`;
export default ___CSS_LOADER_EXPORT___;
