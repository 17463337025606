import React from 'react'

import * as styles from './MediaObject.scss'

const MediaObjectMedia = ({ children }) => {
    return <div className={ styles.media }>{ children }</div>
}

const MediaObjectContent = ({ children }) => {
    return <div className={ styles.content }>{ children }</div>
}


class MediaObject extends React.PureComponent {
    static Media = MediaObjectMedia;
    static Content = MediaObjectContent;

    render() {
        return (
            <div className={ styles.container }>
                { this.props.children }
            </div>
        )
    }
}

MediaObject.propTypes = {
}

export default MediaObject;