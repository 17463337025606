import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { exp } from 'utils';

import DataContext from '../../../components/DataContext'

const Sum = ({ properties, defaultValue, children }) => {

    const context = useContext(DataContext);
    
    const sum = properties.reduce((agg, prop) => {
        const value = exp(prop, context);

        agg += typeof value === 'number' ? value : defaultValue;
        return agg;
    }, 0);


    return (
        <DataContext.Provider value={{ current : sum }}>
            { children }
        </DataContext.Provider>
    )
}

Sum.propTypes = {
    properties : PropTypes.array.isRequired
}

Sum.defaultProps = {
    defaultValue : 0
}

export default Sum;