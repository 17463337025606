import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { XButton, Icon, Box } from 'components'

const AsyncButton = ({ onClick, children, disabled, ...otherProps }) => {
    const [busy, setBusy] = useState(false);

    const handleOnClick = (e) => {
        if (!busy) {
            setBusy(true);

            

            onClick(e).then(
                () => setBusy(false),
                () => setBusy(false)
            );
        }
    }
    
    return (
        <XButton {...otherProps} onClick={ handleOnClick } disabled={ busy || disabled }>
            <Box display="flex" justifyContent="center" width="100%">
            <Icon size="1-2x" />
            
            { children }

            <Icon type={ busy && 'spinner' } size="1-2x" animation="rotate" />
            </Box>
        </XButton>
    )
    
}

AsyncButton.propTypes = {
    onClick : PropTypes.func,
    children : PropTypes.node
}


export default AsyncButton;