import React from 'react';
import PropTypes from 'prop-types'

import { CoverImage } from 'components'

const CoverImageWidget = ({ children, ...otherProps }) => {
    return <CoverImage { ...otherProps}>
                { children }
           </CoverImage>

}


export default CoverImageWidget;