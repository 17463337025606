import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom'

import styled from 'styled-components'

import * as styles from './SiteFooter.module.css'

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: none;
    margin-top: 24px;

    color : var(--meta-text-color);
    font-size: 12px;

    p { 
        font-size: inherit; 
        line-height: 2; 
    }
    a { 
        color : var(--meta-text-color);
        text-decoration: underline;
    }
    
    @media screen and (min-width: 1024px) {
        display: block;
    }

`


const SiteFooter = ({ children }) => {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowContent(true), 250);
    }, [])

    return showContent ? <Container>{ children }</Container> : null;

}

const PortalSiteFooter = ({ children }) => {
    const el = document.getElementById('sw-footer');
    if (el) {
    
        return ReactDOM.createPortal(<SiteFooter>{ children }</SiteFooter>, el)
    }
    else {
        return null;
    }

}

export default PortalSiteFooter;