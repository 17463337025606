import * as types from './types'

const defaultState = {
    byName : {},
    byId : {},
    list : {
        isFetching : false,
        items : []
    },
    compiled : {},
    precompiled : {}
};

export default function view (state = defaultState, action) 
{
    switch (action.type) {

        case types.FETCH_COMPILED_VIEW_BY_NAME_REQUEST:
            return { 
                ...state,
                compiled : {
                    ...state.compiled,
                    [action.name] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_COMPILED_VIEW_BY_NAME_SUCCESS:
            return { 
                ...state,
                compiled : {
                    ...state.compiled,
                    ... Object.keys(action.payload).reduce((agg, name) => {
                        agg[name] = {
                            isFetching : false,
                            didInvalidate : false,
                            item : action.payload[name]
                        }
        
                        return agg;
        
                    }, {})
                }
            };
        case types.FETCH_COMPILED_VIEW_BY_NAME_FAILURE:
            return { 
                ...state,
                compiled : {
                    ...state.compiled,
                    [action.name] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };


        case types.FETCH_VIEW_BY_NAME_REQUEST:
            return { 
                ...state,
                byName : {
                    ...state.byName,
                    [action.name] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_VIEW_BY_NAME_SUCCESS:
            return { 
                ...state,
                byName : {
                    ...state.byName,
                    [action.name] : {
                        isFetching : false,
                        didInvalidate : false,
                        item : action.payload.result
                    }
                }
            };
        case types.FETCH_VIEW_BY_NAME_FAILURE:
            return { 
                ...state,
                byName : {
                    ...state.byName,
                    [action.name] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };

        case types.UPDATE_VIEW_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        didInvalidate : true
                    }
                }
            };
        case types.FETCH_VIEW_REQUEST:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_VIEW_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_VIEW_FAILURE:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };            
        case types.FETCH_VIEWS_REQUEST:
            return { 
                ...state,
                list : {
                    ...state.list,
                    isFetching : true,
                    count : 0,
                    items : []
                }
            };
        case types.FETCH_VIEWS_SUCCESS:
            return { 
                ...state,
                list : {
                    ...state.list,
                    isFetching : false,
                    count : action.count,
                    items : action.payload
                }
            };
        case types.FETCH_VIEWS_FAILURE:
            return { 
                ...state,
                list : {
                    ...state.list,
                    isFetching : false,
                    count : 0,
                    items : []
                }
            };            
        case types.FETCH_PRECOMPILED_VIEWS_REQUEST:
            return { 
                ...state,
                precompiled : {
                    ...state.precompiled,
                    [action.group] : {
                        ...state.precompiled[action.group],
                        isFetching : true
                    }
                    
                }
            };          
        case types.FETCH_PRECOMPILED_VIEWS_SUCCESS:

            return { 
                ...state,
                precompiled : {
                    ...state.precompiled,
                    [action.group] : {
                        ...state.precompiled[action.group],
                        items : action.payload.result,
                        isFetching : false,
                        didInvalidate : false
                    }
                    
                },
                byName : {
                    ...state.byName,
                   
                    ...Object.keys(action.payload.entities.views || {}).reduce((acc, key) => {
                        const view = action.payload.entities.views[key]

                        acc[view.name] = {
                            ...state.byName[view.name],
                            item : view.id,
                            isFetching : false,
                            didInvalidate: false
                        };
                        return acc;

                    }, {})
                }
            };   
        case types.FETCH_PRECOMPILED_VIEWS_FAILURE:
            return { 
                ...state,
                precompiled : {
                    ...state.precompiled,
                    [action.group] : {
                        ...state.precompiled[action.group],
                        isFetching : false
                    }
                }
            };                                    
        default:
            return state
    }
}

