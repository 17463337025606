import React, { useState,  useEffect } from 'react'

import useCurrentGame from '../containers/useCurrentGame'

import useFantasyTeams from '../containers/useFantasyTeams'
import CreateCompetitionDialog from './CreateCompetitionDialog'

import { MessageBox } from 'components'
import { useTranslator } from '../hooks/useTranslator'

const useCompetitionWizard = () => {
    const game = useCurrentGame();
    const [fantasyTeams, isFantasyTeamsFetching, didFetchFantasyTeams] = useFantasyTeams(game && game.id);
    const [step, setStep] = useState('');
    
    useEffect(() => {
        if (step == '' && game && didFetchFantasyTeams) {
            const lastRound = game.rounds[game.rounds.length - 1];
            if (new Date() > new Date(lastRound.end)) {
                setStep('closed');
            }
            else if (fantasyTeams.filter(f => f.status == 'activated').length == 0 )
                setStep('no_teams');
            else if (fantasyTeams.filter(f => f.status == 'activated' && f.level == 'gold').length == 0 )
                setStep('no_gold_teams');
            else
                setStep('create');
        }
    }, [game, didFetchFantasyTeams]);

    return [step, setStep];
}

const CreateCompetitionWizard = ({ onCancel, onFinish }) => {
    const [step, setStep] = useCompetitionWizard();
    const t = useTranslator();
    
    const handleFinish = () => {
        onClose();
    }

    switch(step) {
        case 'closed':
            return <MessageBox 
                        open={ true } 
                        title={ t('competitions.create_mini_league_heading') } 
                        message={ t('competitions.create_mini_league_game_closed_error') } 
                        okCaption={ t('common.ok_caption') } 
                        onClose={ onCancel } />
        case 'no_teams':
            return <MessageBox 
                        open={ true } 
                        title={ t('competitions.create_mini_league_heading') } 
                        message={ t('competitions.create_mini_league_no_active_teams_error') }
                        okCaption={ t('common.ok_caption') } 
                        onClose={ onCancel } 
                        />
        case 'no_gold_teams':
            return <MessageBox 
                        open={ true } 
                        title={ t('competitions.create_mini_league_heading') } 
                        message={ t('competitions.create_mini_league_no_gold_teams_error') }
                        okCaption={ t('common.ok_caption') } 
                        onClose={ onCancel } 
                        />
        case 'create':
            return <CreateCompetitionDialog open={ true } onComplete={ onFinish } onCancel={ onCancel } />
        default:
            return null;
    }
}

export default CreateCompetitionWizard;