import React, { Component } from 'react'
import PropTypes from 'prop-types';
import TranslationContext from './TranslationContext'

const interpolate = (s, o) => {
    return s.replace(/{([^{}]*)}/g,
        function (a, b) {
            var r = o[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
}


const flatten = (obj) => {
    var f = {};
      
      for (var p in obj) {
      if (!obj.hasOwnProperty(p)) 
        continue;
          
      if ((typeof obj[p]) == 'object') {
              var fObj = flatten(obj[p]);
              for (var pObj in fObj) {
          if (!fObj.hasOwnProperty(pObj)) 
            continue;
                  
                  f[p + '.' + pObj] = fObj[pObj];
              }
          } else {
              f[p] = obj[p];
          }
      }
      return f;
  }
  

class TranslationProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.apply = this.apply.bind(this);
        this.translate = this.translate.bind(this);
    }

    componentDidMount() {
        this.apply(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.translations !== this.props.translations) {
            this.apply(nextProps);
        }
    }

    apply (props) {

        
        if (!props.translations) {
            this.setState({
                fallbackTranslations : {},
                translations : {}
            })
        }
        else {

            
            this.setState({
                fallbackTranslations : flatten(props.translations[props.fallbackLocale]),
                translations : flatten(props.translations[props.locale])
            })
        }
    }

    translate(key, params) {
        if (!this.props.translations)
            return ''


        let t = this.state.translations[key];
        if (t === undefined) {
          t = this.state.fallbackTranslations[key];
  
          if (t === undefined) {
            return key;
          }
        }
  
        return interpolate(t, params);
    }

    render() {

        return (
            <TranslationContext.Provider value={{ translate : this.translate }}>
                { this.props.children }
            </TranslationContext.Provider>
        )
    }
}

TranslationProvider.propTypes = {
    locale : PropTypes.string.isRequired,
    fallbackLocale : PropTypes.string.isRequired
}


export default TranslationProvider;