import React from 'react'
import dayjs from 'dayjs'

import translatable from '../hocs/translatable'


const RelativeTime = ({ t, date }) => {
    
    var elapsed = new Date().getTime() - new Date(date).getTime();

    
    if (elapsed < 5 * 1000) {
        return t('time.now');   
    }
    else if (elapsed < 60 * 1000) {
        const seconds = Math.round(elapsed / 1000);
        return seconds !== 1 ? t('time.nSecondsAgo', { seconds }) : t('time.nSecondAgo', { seconds });   
    }
    else if (elapsed < 60 * 60 * 1000) {
        const minutes = Math.round(elapsed / (60 * 1000));
        return minutes !== 1 ? t('time.nMinutesAgo', { minutes }) : t('time.nMinuteAgo', { minutes });   
    }
    else if (elapsed < 24 * 60 * 60 * 1000 ) {
        const hours = Math.round(elapsed / (60 * 60 * 1000));
        return hours !== 1 ? t('time.nHoursAgo', { hours }) : t('time.nHourAgo', { hours });   
    }
    else {
        return dayjs(date).format('dddd DD. MMM, HH:mm');
    }
}

export default translatable(React.memo(RelativeTime));