import React from 'react'
import PropTypes from 'prop-types'

import ViewContainer from './ViewContainer'
import { Datasource } from 'components';

class ViewFlyout extends React.PureComponent {
    render() {
        const { view, args } = this.props;

        return (
            <Datasource value={{ args }}>
                <ViewContainer name={ view } />
            </Datasource>
        )
    }
}

ViewFlyout.propTypes = {
    view : PropTypes.string.isRequired
}


export default ViewFlyout;