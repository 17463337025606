import React from 'react'
import PropTypes from 'prop-types'

const defaultPhaseObject = {
    text : '',
    heading : '',
    deadline : new Date(1970, 1, 1),
    round : 0,
    rounds : 0
}

const Phase = ({ game, configuration, children }) => {

    if (!game || !configuration) {
        return children(defaultPhaseObject);
    }


    let phaseName = null;
    let deadline = null;
    let roundNumber = 0;

    const now = new Date();

    for(let i = 0; i < game.rounds.length; i++) {
        const round = game.rounds[i];
        const close = new Date(round.close);

        if (now < close) {
            if (i === 0) {
                phaseName = 'starting'
                deadline = close;
                
            } else {
                phaseName = 'open'
                deadline = new Date(round.close);
            }
            roundNumber = i +1;
            break;
        }
        else if (now < new Date(round.end)) {
            if (i === game.rounds.length - 1) {
                phaseName = 'ending',
                deadline = new Date(round.end);
            }
            else {
                phaseName = 'closed'
                deadline = new Date(round.end);
            }
            roundNumber = i +1;
            break;
        }
    }

    if (!phaseName) {
        phaseName = 'ended';
        deadline = new Date(game.rounds[game.rounds.length - 1 ].end);
        roundNumber = game.rounds.length;
    }
    
    let phaseObj = configuration && configuration.phases && configuration.phases[phaseName];
    if (!phaseObj) {
        phaseObj = {
            text : phaseName,
            heading : phaseName
           
        };
    }

    phaseObj = {
        ...phaseObj,
        deadline,
        round : roundNumber,
        rounds : game.rounds.length
    }

    return children(phaseObj);
}

Phase.propTypes = {
    game : PropTypes.object,
    configuration : PropTypes.object
}

export default Phase;