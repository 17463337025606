import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';

import { makeGetMatchFantasyEventNotifications } from '../redux/match/selectors';


const useMatchFantasyEventNotifications = (id) => {
    const getMatchFantasyEventNotification = useMemo(() => makeGetMatchFantasyEventNotifications(), []);
    
    return useSelector(state => getMatchFantasyEventNotification(state, id, 177));
}

export default useMatchFantasyEventNotifications;