import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import GameContext from '../GameContext';

import { 
    XButton,
    IconButton,
    CheckBoxButton,
    ListView,
    ListViewItem,
    PlayerAvatar,
    Icon,
    Modal
} from '../../components';

// Containers
import PlayerIdentity from '../PlayerIdentity'


// HOCS
import translatable from '../../hocs/translatable';
import { lo } from '../../common/utils';



const Player = ({ game, player, onSelect })  => {
    
    return (
        <ListViewItem>
            <div style={{ display: 'flex' }}>
                <div className="cell shrink">
                    <div style={{ marginTop: '12px'}}>
                        <IconButton inline tiny onClick={ onSelect }><Icon type="plus" size="1-2x" /></IconButton>
                    </div>
                </div>
                <div className="cell shrink">
                    <PlayerIdentity game={ game } player={ player } person={ player.person } team={ player.team } position={ player.position }  />
                </div>
            </div>
        </ListViewItem>
    );
}

const Slot = ({ slot, player, text, edit, game, active, onClick, onShowSettings }) => {
    const style = {
        position: 'absolute', 
        top : slot.y + '%', 
        left : slot.x + '%', 
        border: edit ? '2px solid rgba(255,255,0, 0.2)' : null,  
        cursor: 'pointer',
        borderRadius: '3px',
        width: '20%', 
        height : '20%',
        marginLeft: '-10%',
        marginTop: '-10%'
    }

    if (active && edit) {
        style.border ='2px solid rgba(255,255,0, 0.75)';
    }

    var self = this;

    
    return (
        <div style={ style } onClick={ onClick }>
            { 
                
                edit && 

                <div style={{ position: 'absolute', top: 0, right: 0 }} >
                    <IconButton inline transparent tiny onClick={ onShowSettings }><Icon type="settings"  /></IconButton>
                </div>
                
            }

            <div style={{ textAlign: 'center'}}>
                <div style={{ display: 'inline-block',  maxWidth: '75%'  }}>
                    { player && <PlayerAvatar size="huge" player={ player} flipX={ slot.flipX } flipY={ slot.flipY }  game={ game }/> }
                </div>
                <div>
                    { player && <div style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '1', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)' }}>{ player.person.firstname } { player.person.lastname }</div> }
                    
                    { text && <div style={{ fontWeight: 'bold', fontSize: '12px', lineHeight: '12px', marginTop: '4px' }}>{ text }</div> }
                </div>
            </div>
            
        </div>
    )
}

class LineupBuilder extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            selectedFormationIndex : 0,
            selectedSlot : 0,
            players: {},
            texts : {},
            edit: true,
            query : '',
            modalOpen : false

        }

    }

    componentDidMount() {
    }

    render() {

        if (!this.props.game || !this.props.tournament || !this.props.gameConfiguration || !this.props.ruleset)
            return null

        const players = (
            this.props.tournament && 
            this.props.tournament.players.filter(p => {
                const q = this.state.query.toLowerCase();
                return (
                    p.active &&
                    (
                        q === '' ||
                        p.person.firstname.toLowerCase().indexOf(q) >= 0 ||
                        p.person.lastname.toLowerCase().indexOf(q) >= 0 ||
                        lo(p.team, 'name').toLowerCase().indexOf(q) >= 0
                    )
                )
            }).slice(0, 24)
        
        ) || [];


        var left = players.slice(0, 12);
        var right = players.slice(12, 24);

        const formation = this.props.ruleset && this.props.ruleset.formations[this.state.selectedFormationIndex];
        const text = this.state.texts[this.state.selectedSlot] ;
        
        const showSettings = () => {
            this.setState({
                modalOpen : true
            });
        }

        return (
            <div>
                <div className="grid-container">
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ width: '60%' }}>

                            { this.props.ruleset && this.props.ruleset.formations.map((f, idx) => 
                                <XButton variant={ idx === this.state.selectedFormationIndex ? 'primary' : 'transparent' } key={f.id} onClick={ ()=> { this.setState({ selectedFormationIndex : idx })} }>{ f.name }</XButton> 
                            ) }



                            <div style={{ position: 'relative', margin: '20px 0' }}>

                                { 
                                    formation && formation.slots.map((s,idx) => {
                                        const player = this.state.players[idx];
                                        const text = this.state.texts[idx];
                                        
                                        return (
                                            <Slot key={ idx } edit={ this.state.edit } player={ player } text={ text } game={ this.props.game } slot={ s } active={ idx === this.state.selectedSlot } onShowSettings={ showSettings } onClick={ () => { this.setState({ selectedSlot : idx })} }/>
                                        )
                                    })
                                }

                                <img style={{ maxWidth: '100%' }} src={ this.props.gameConfiguration.ui.watermarkedFieldImageUrl } />
                            </div>

                            <CheckBoxButton checked={ this.state.edit } onChange={ (checked) => { this.setState({ edit: checked })} }>Redigér</CheckBoxButton>
                        </div>
                        <div style={{ width: '40%', marginLeft: '12px' }}>
                            <div style={{ marginBottom: '12px'}}>
                                <input value={ this.state.query } placeholder="Find spiller" onChange={ (ev) => { this.setState({ query : ev.target.value }) }} />
                            </div>

                            { !this.props.tournament &&
                                <div className="text-center">Øjeblik...</div>
                            
                            }
                            
                            <div style={{ display: 'flex'}}>
                            <div className="cell large-6">
                                <ListView border fixedHeight>
                                    { left && left.map((p, idx) => <Player key={ p.id } game={ this.props.game } player={ p } onSelect={ () => 
                                    {  
                                        this.setState({
                                            players : {
                                                ...this.state.players,
                                                [this.state.selectedSlot] : p
                                            }
                                        })


                                    } } />)}
                                </ListView>
                                </div>

                                <div className="cell large-6">
                                    <ListView border fixedHeight>
                                        { right && right.map((p, idx) => <Player key={ p.id } game={ this.props.game } player={ p } onSelect={ () => 
                                        {  
                                            this.setState({
                                                players : {
                                                    ...this.state.players,
                                                    [this.state.selectedSlot] : p
                                                }
                                            })


                                        } } />)}
                                    </ListView>
                                </div>
                            </div>
                            
                            <small className="meta">
                                Der vises maksimalt 24 resultater.
                            </small>
                        </div>
                    </div>
                </div>

                <Modal open={ this.state.modalOpen } heading="Vælg tekst" onClose={ () => this.setState({ modalOpen : false }) } 
                    footer={ 
                        <div>
                            <XButton textTransform="uppercase" onClick={ ()=> this.setState({ 
                                texts : {
                                    ...this.state.texts,
                                    [this.state.selectedSlot] : this.state.text
                                },
                                text: '',
                                modalOpen : false
                        }) }>SET</XButton></div> }>
                    Indtast tekst til spiller<br />
                    <input value={ this.state.text } onChange={ (event) => this.setState({ text : event.target.value })} />

                </Modal>
            </div>
        )
    }
}


const Wrapper = () => (
    <GameContext.Consumer>{ ({ game, gameConfiguration, ruleset, tournament}) => 
        <LineupBuilder game={ game } tournament={ tournament } ruleset={ ruleset } gameConfiguration={ gameConfiguration }/> 
    }</GameContext.Consumer>
)

LineupBuilder.propTypes = {
}
  
export default withRouter(
    translatable(
        Wrapper
    
));
