import React from 'react'
import { animated, useTransition } from 'react-spring';

import * as styles from './ChangeNotification.module.css'

export const ChangeNotification = ({ show, text, children, onClick }) =>{
    const transitions = useTransition(show, null, {
        from: { opacity: 0, transform: 'translate3d(0, -12px, 0)' },
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -12px, 0)' },
    })

    return (
        <div className={ styles.container }>
            { children }

            { transitions.map(({ item, key, props }) => item && <animated.div className={ styles.notification } key={ key } style={ props } onClick={ onClick }>{ text }</animated.div>) }
        </div>
    )
}


