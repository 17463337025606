import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Overlay.scss'

const Overlay = ({ color, children }) => {

    const style = {
        backgroundColor : color
    }

    return (
        <div className={ styles.container }>
            { children }

            <div style={ style } className={ styles.overlay }></div>
        </div>
    )
}


Overlay.propTypes = {
    color : PropTypes.string.isRequired
}

Overlay.defaultProps = {
    color : 'rgba(0, 0, 0, 0.5)',
}

export default Overlay;