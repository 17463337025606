// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vAPFpRwk72wHiA_w97pA{background:#666;color:#fff;min-height:48px;display:flex}.IiyjszBbZsk8l08uN66m{width:48px;height:48px;display:flex;align-items:center;justify-content:center}.tzkivzK0CcEkStlcfCqU{flex:1;padding:12px 12px 12px 0;line-height:24px;font-size:11px}.r2PYXejDOCMZHzD8Rfgb{background:#e80000}.CFgaJIwUSwb0HhFsuLxg{background:#ff640a}.bldX5r7zqmIIvR3wlI5e{background:#339}.S30HfXZligTY1tLhH1E_{background:#090}`, "",{"version":3,"sources":["webpack://./src/components/Alert.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,UAAA,CAEA,eAAA,CAEA,YAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,MAAA,CACA,wBAAA,CACA,gBAAA,CACA,cAAA,CAIJ,sBAAA,kBAAA,CACA,sBAAA,kBAAA,CACA,sBAAA,eAAA,CACA,sBAAA,eAAA","sourcesContent":[".alert {\r\n    background: #666;\r\n    color: #fff;\r\n\r\n    min-height: 48px;\r\n\r\n    display: flex;\r\n}\r\n\r\n.image {\r\n    width: 48px;\r\n    height: 48px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.content {\r\n    flex: 1;\r\n    padding: 12px 12px 12px 0;\r\n    line-height: 24px;\r\n    font-size: 11px;\r\n}\r\n\r\n\r\n.error { background: #e80000; }\r\n.warning { background: #ff640a; }\r\n.information { background: #339; }\r\n.success { background: #090; }"],"sourceRoot":""}]);
// Exports
export var alert = `vAPFpRwk72wHiA_w97pA`;
export var image = `IiyjszBbZsk8l08uN66m`;
export var content = `tzkivzK0CcEkStlcfCqU`;
export var error = `r2PYXejDOCMZHzD8Rfgb`;
export var warning = `CFgaJIwUSwb0HhFsuLxg`;
export var information = `bldX5r7zqmIIvR3wlI5e`;
export var success = `S30HfXZligTY1tLhH1E_`;
export default ___CSS_LOADER_EXPORT___;
