import React from 'react'

import {   Table, EventDate, Round } from '../../components'

import MatchStatus from '../MatchStatus'

import translatable from '../../hocs/translatable'


const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}


const MotorsportScheduleTableRow = ({ item, onClick }) => {
    const properties = item.properties || {};

    const totalDistance =  properties.circuitDistance ? parseInt(properties.circuitDistance) : 0;
    const laps =  properties.circuitLaps ? parseInt(properties.circuitLaps) : 0;

    return (
        <Table.Row onClick={ onClick } active={ item.current }>
            <Table.Cell center><Round post={ item.status === 'completed' }>{ item.round }</Round></Table.Cell>
            <Table.Cell><EventDate date={ new Date(item.start) } format="short" /></Table.Cell>
            <Table.Cell><span className="a">{ item.name }</span></Table.Cell>
            <Table.Cell center>{ laps > 0 ? laps : '-' }</Table.Cell>
            <Table.Cell right>{ totalDistance > 0 && laps > 0 ? <Kilometers value={ totalDistance / laps  } /> : '-' }</Table.Cell>
            <Table.Cell right><Kilometers value={ totalDistance } /></Table.Cell>
            <Table.Cell right><MatchStatus status={ item.status } /></Table.Cell>
        </Table.Row>
    )
}

const MotorsportScheduleTableView = ({ t, schedule, onSelectMatch }) => {
    return (

                <Table>
                    <Table.Head>
                        <Table.Row>
                            <Table.Header center>{ t('common.round' )}</Table.Header>
                            <Table.Header>{ t('common.start' )}</Table.Header>
                            
                            <Table.Header>{ t('motorsport.race' )}</Table.Header>
                            
                            <Table.Header center>{ t('motorsport.laps' )}</Table.Header>
                            <Table.Header right>{ t('motorsport.distance' )}</Table.Header>
                            <Table.Header right>{ t('motorsport.totalDistance' )}</Table.Header>
                            
                            <Table.Header right>{ t('common.status' )}</Table.Header>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        { schedule.items.map(item => <MotorsportScheduleTableRow key={ item.id } item={ item } onClick={ () => onSelectMatch(item) } ></MotorsportScheduleTableRow>) }
                    </Table.Body>
                </Table>
    )
}

export default translatable(MotorsportScheduleTableView);