import axios from 'axios'
import { normalize } from 'normalizr'

import {  notificationSchema } from '../../schemas';
import auth from '../../common/auth'

import * as types from './types';

/* Fetch notifications */

const fetchNotificationsRequest = (page, pageSize) => ({ type: types.FETCH_NOTIFICATIONS_REQUEST, page, pageSize });
const fetchNotificationsSuccess = (page, pageSize, count, payload) => ({ type: types.FETCH_NOTIFICATIONS_SUCCESS, page, pageSize, count, payload });
const fetchNotificationsFailure = (page, pageSize) => ({ type: types.FETCH_NOTIFICATIONS_FAIL, page, pageSize });

const fetchNotifications = (page, pageSize) => (dispatch) => { 
    dispatch(fetchNotificationsRequest(page, pageSize));

    const accessToken = auth.getAccessToken();

    return axios.get(`/users/me/notifications?page=${ page }&pageSize=${ pageSize }&access_token=${ accessToken }`).then(response => {
        const count = parseInt(response.headers['x-pagination-count']);
        dispatch(fetchNotificationsSuccess(page, pageSize, count, normalize(response.data, [notificationSchema])));
    }, e => {
        dispatch(fetchNotificationsFailure(page, pageSize));
    });
}

const shouldFetchNotifications = (state, page, pageSize) => {
    const s = state.notification.notifications;

    if (s.page !== page || s.pageSize != pageSize)
        return true;
    else if (s.isFetching) {
        return false;
    }
    else if (!s.items) {
        return true;
    }
    else {
        return s.didInvalidate;
    }
}

export const fetchNotificationsIfNeeded = (page, pageSize) => (dispatch, getState) => {
    if (shouldFetchNotifications(getState(), page, pageSize)) {
        dispatch(fetchNotifications(page, pageSize));
    }
}

/* Invote notification */

const invokeNotificationRequest = (id) => ({ type: types.INVOKE_NOTIFICATION_REQUEST, id });
const invokeNotificationSuccess = (id, payload) => ({ type: types.INVOKE_NOTIFICATION_SUCCESS, id, payload });
const invokeNotificationFailure = (id) => ({ type: types.INVOKE_NOTIFICATION_FAIL, id });

export const invokeNotification = (id) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(invokeNotificationRequest(id));

    const accessToken = auth.getAccessToken();

    return axios.put(`/users/me/notifications/${ id }/invoke?access_token=${ accessToken }`).then(response => {
        dispatch(invokeNotificationSuccess(id, response.data));
        resolve(response.data);
    }, e => {
        dispatch(invokeNotificationFailure(id));
        reject(e);
    });
})


/* Acknowledge */

const acknowledgeNotificationsRequest = () => ({ type: types.ACKNOWLEDGE_NOTIFICATIONS_REQUEST });
const acknowledgeNotificationsSuccess = (payload) => ({ type: types.ACKNOWLEDGE_NOTIFICATIONS_SUCCESS, payload });
const acknowledgeNotificationsFailure = () => ({ type: types.ACKNOWLEDGE_NOTIFICATIONS_FAIL });

export const acknowledgeNotifications = () => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(acknowledgeNotificationsRequest());

    const accessToken = auth.getAccessToken();

    return axios.post(`/users/me/notifications/acknowledge?access_token=${ accessToken }`).then(response => {
        dispatch(acknowledgeNotificationsSuccess(response.data));
        resolve(response.data);
    }, e => {
        dispatch(acknowledgeNotificationsFailure());
        reject(e);
    });
})



export const invalidateNotifications = () => ({ type: types.INVALIDATE_NOTIFICATIONS });

/* Mark notifications read */

const markNotificationsReadRequest = () => ({ type: types.MARK_NOTIFICATIONS_READ_REQUEST });
const markNotificationsReadSuccess = (payload) => ({ type: types.MARK_NOTIFICATIONS_READ_SUCCESS, payload });
const markNotificationsReadFailure = () => ({ type: types.MARK_NOTIFICATIONS_READ_FAIL });

export const markNotificationsRead = () => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(markNotificationsReadRequest());

    const accessToken = auth.getAccessToken();

    return axios.post(`/users/me/notifications/markread?access_token=${ accessToken }`).then(response => {
        dispatch(markNotificationsReadSuccess(response.data));
        dispatch(invalidateNotifications());
        resolve(response.data);
    }, e => {
        dispatch(markNotificationsReadFailure());
        reject(e);
    });
})

/* */

//const xinvalidateNotifications = (payload) => ({ type: types.INVALIDATE_NOTIFICATIONS, payload });
const _wsOnNotification = (payload) => ({ type: types.WS_NOTIFICATION, payload });

export const wsOnNotification = (notification) => (dispatch) => { 
    const normalized = normalize(notification, notificationSchema);
    return dispatch(_wsOnNotification(normalized));
}
