import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react'

import { typography, space, layout, flexbox  } from 'styled-system'
import styled, { css } from 'styled-components'
import { animated, useSpring } from 'react-spring'

const StyledFlyout = styled.div`
    display: flex;
    flex-direction: column;
    
    position: absolute;
    font-size: 14px;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${ typography }
`

const StyledFlyoutHeader = styled.div`
    min-height: 48px;

    background-color: ${ props => props.theme.colors.themes[props.themeName].primary };
    color :  ${ props => props.theme.colors.themes[props.themeName].primaryForeground };
    

    ${ flexbox }
    ${ space }
    ${ layout }
`;



const StyledFlyoutFooter = styled.div`
    padding: 0 12px;
    min-height: 48px;
`;


const StyledFlyoutContent = styled.div`
    flex: 1;
    overflow-x: hidden; 
    overflow-y: auto; 
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth; 

    position: relative;

    display: flex;
    flex-direction: column;

    ${ props => props.padding && css`
        padding: var(--padding);
    `}

    ${space}
`

export const FlyoutHeader = ({ onClose, children, ...props }) => {
    return (
        <StyledFlyoutHeader { ...props }>
            { children }
        </StyledFlyoutHeader>
    )
};


FlyoutHeader.defaultProps = {
    themeName : 'default'
}

export const FlyoutFooter = ({ children, ...props }) => {
    return (
        <StyledFlyoutFooter {...props}>
            { children }
        </StyledFlyoutFooter>
    )
};



export const FlyoutContent = React.forwardRef(({ children, ...props }, ref) => {
    return (
        <StyledFlyoutContent ref={ ref } {...props}>{ children }</StyledFlyoutContent>
    )
});

export class Flyout extends React.Component {
    static Header = FlyoutHeader;
    static Content = FlyoutContent;
    static Footer = FlyoutFooter;

    render() {
        return  (
            <StyledFlyout>    
               { this.props.children }
            </StyledFlyout>
        )
    }
}

Flyout.propTypes = {

}

Flyout.defaultProps = {
}




const useOnResize = (ref, callback) => {

    useLayoutEffect(() => {
        
        if (window.ResizeObserver) {
            
            if (ref.current) {
                const resizeObserver = new ResizeObserver(entries => {
                    callback(entries);
                });

                resizeObserver.observe(ref.current);

                return () => {
                    resizeObserver.unobserve(ref.current);
                }
            }
        }
    }, [ref.current])
}





const useScrollBarWidth = (ref) => {
    const [scrollBarWidth, setScrollBarWidth] = useState(ref.current && (ref.current.offsetWidth - ref.current.clientWidth) || 0);

    const callback = useCallback((entries) => {
        setScrollBarWidth(ref.current && (ref.current.offsetWidth - ref.current.clientWidth) || 0);
    })
    
    useOnResize(ref, callback)
    
    useEffect(() => {
        setScrollBarWidth(ref.current && (ref.current.offsetWidth - ref.current.clientWidth) || 0);
    }, [ref.current])

    return [scrollBarWidth]
}

const useScrollPosition = (ref) => {
    const [scrollPosition, setScrollPosition] = useState(ref.current && ref.current.scrollTop || 0);
    
    const onScroll = useCallback((e) => {
        setScrollPosition(e.target.scrollTop)
    })

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('scroll', onScroll);

            return () => {
                ref.current.removeEventListener('scroll', onScroll);
            }
        }
    }, [ref.current])

    return [scrollPosition]

}


const ScrollHeaderOverlay = ({ scrollRef, children }) => {
    const [scrollBarWidth] = useScrollBarWidth(scrollRef);

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, right : scrollBarWidth + 'px', zIndex: 2000  }}>
            { children }
        </div>
    )
};


const StyledHeaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right : ${ props => props.scrollBarWidth + 'px' };
    z-index: 2000 ;
`


const StyledToolbarWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`

const StyledHeaderContent = styled(animated.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translate3d(0, -48px, 0);
    background: ${ props => props.theme.colors.siteBackground };
`


export const FlyoutOverlayContent = ({ children, header, toolbar, minimumHeight,  ...props }) => {
    const ref = useRef();
    const [scrollBarWidth] = useScrollBarWidth(ref);
    const [scrollPosition] = useScrollPosition(ref);
    
    const isOpen = scrollPosition > 131;

    const style = useSpring({ transform: isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, -48px, 0)' })
 
    return (
                
            <React.Fragment>
                <StyledHeaderWrapper scrollBarWidth={ scrollBarWidth }>
                    <StyledHeaderContent style={ style }>{ header }</StyledHeaderContent>
                    <StyledToolbarWrapper>{ toolbar }</StyledToolbarWrapper>
                </StyledHeaderWrapper>

                <StyledFlyoutContent ref={ ref } {...props}>
                    { children }
                </StyledFlyoutContent>
            </React.Fragment>
        
            
            
        
    )
};
