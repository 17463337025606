import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types'

import { NavigationMenu } from 'components'

import { useAppConfig } from '../../AppConfigProvider'

import { format } from 'utils';

const NavigationMenuWidget = ({ menu }) => {
    const cfg = useAppConfig();
    const params = useParams();

    const menuItems = cfg && cfg.menus && cfg.menus[menu] || [];

    const mappedItems = menuItems.map(i => {
        return {
            text : i.text,
            href : format(i.url, params),
            external : i.external
        };
    });

    return <NavigationMenu items={ mappedItems } />

}

export default NavigationMenuWidget;