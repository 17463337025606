import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

export const makeGetTournamentInjuries = () => (createSelector([
    (state, props) => props.id,
    (state) => state.injury.byTournament,
    (state) => state.entities.injuries,
    (state) => state.entities.injuryTypes
], 
(id, byTournament, injuryEntities, injuryTypeEntities) => { 
    const { isFetching, items } = byTournament[id] || { isFetching : false, items : [] }

    return {
        isFetching,
        map : (items || []).reduce((acc, i) => {
            const injury = injuryEntities[i];
            const type = injuryTypeEntities[injury.type];

            if (acc[injury.person.id])
            {
                acc[injury.person.id].push({
                    ...injury,
                    type
                })
            }
            else {
                acc[injury.person.id] = [{
                    ...injury,
                    type
                }]
            }


           

            return acc;
        }, {})
    }
}
));