import React from 'react';

import CSSVariables from './CSSVariables'

const ThemeProvider = ({ theme, children }) => {

    const { colors, sizes } = theme;

    const vars = {

        muted : colors.foregroundMeta,

        panel: colors.panelBackground,
        onPanel: colors.panelForeground,

        size0: sizes.s100,
        size1: sizes.s200,
        size2: sizes.s300,
        size3: sizes.s400,
        size4: sizes.s500,
        size5: sizes.s600,
        size7: sizes.s700,
        size8: sizes.s800,
        size9: sizes.s900,

        action: colors.themes.default.action,
        actionLight: colors.themes.default.actionHover,
        onAction: colors.themes.default.onAction,

        utilitySilver: colors.utilitySilver,
        utilityBlack: colors.utilityBlack,
        utilityBlue: colors.utilityBlue,
        utilityWhite: colors.utilityWhite,
        utilityRed: colors.utilityRed,
        utilityGreen: colors.utilityGreen,
        utilityYellow: colors.utilityYellow,

        button: colors.buttonLikeBackground,
        buttonForegroundDisabled: colors.buttonLikeForegroundDisabled,
        buttonBackgroundDisabled: colors.buttonLikeBackgroundDisabled,
        
        card: colors.cardBackground,
        cardLight: colors.cardBackgroundHover,
        onCard: colors.cardForeground,
        cardSecondary: colors.cardBackgroundSecondary,
        cardSecondaryLight: colors.cardBackgroundSecondaryHover,


        dropdown: colors.dropdownBackground,
        dropdownLight: colors.dropdownBackgroundHover,
        onDropdown: colors.dropdownForeground,
        onDropdownLight: colors.dropdownForegroundHover,
        dropdownSeparator: colors.dropdownSeparatorBackground,
        onDropdownSeparator: colors.dropdownSeparatorForeground,
        dropdownBorderLight: colors.dropdownBorderUp,
        dropdownBorderDark: colors.dropdownBorderDown


    }
    
    return  <CSSVariables vars={ vars } root>
                { children }
            </CSSVariables>
}

export default ThemeProvider;