import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { ExternalLink } from '../../../components'
import translateable from '../../../hocs/translatable'

import { useTitle } from '../../../hooks/useTitle'

const Header = ({ title }) => {
    return (
         <span>{ title }</span>
    )
}

const Logo = () => {
    let siteImageUrl = window.swush.logoImageUrl;

    return (
        <ExternalLink href="/">
            <img src={ siteImageUrl} style={{ height: '24px' }} alt="Logo" />
        </ExternalLink>
    )
}

const TitleConductor = ({ t }) => {
    const [title, setTitle] = useTitle();

    return (
        <Switch>
            <Route path="/:language/games/:gameSlug/:gameId/matches/:matchSlug/:matchId" render={(props) => <Header title={ t('common.matchReport')} />} />
            <Route path="/:language/games/:gameSlug/:gameId/highscore" render={(props) => <Header title={ t('common.highscore' )} /> }/>
            <Route path="/:language/games/:gameSlug/:gameId/gameplay" render={(props) => <Header title={ t('common.rules' )} /> } />
            <Route path="/:language/games/:gameSlug/:gameId/prizes" render={(props) => <Header title={ t('common.prizes')} />} />
            <Route path="/:language/games/:gameSlug/:gameId/statistics" render={(props) => <Header title={ t('common.statistics')} />} />
            <Route path="/:language/games/:gameSlug/:gameId/schedule" render={(props) => <Header title={ t('common.rounds')} />} />
            <Route render={(props) => title && title.length > 0 ? title : (window.swush.defaultTitle ? window.swush.defaultTitle : <Logo />) }  />
        </Switch>
    )
}

export default translateable(
    withRouter(TitleConductor)
);


