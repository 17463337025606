import React from 'react';
import PropTypes from 'prop-types'

import { resolve } from 'utils'

const isMatch = (obj, condition) => {
    const value = resolve(condition.property, obj);

    switch(condition.op) {
        case 'eq':
            return value === condition.value;
        case 'neq':
            return value !== condition.value;
        case 'gt':
            return value > condition.value;
        case 'gte':
            return value >= condition.value;
        case 'lt':
            return value < condition.value;
        case 'lte':
            return value <= condition.value;
        case 'defined':
            return typeof value !== 'undefined';
        case 'undefined':
            return typeof value === 'undefined';
        default:
            throw 'Invalid operation \'' + condition.operation + '\'';
    }
}


const When = ({ obj, conditions, children }) => {
    for(let i = 0; i < conditions.length; i++) {
        if (!isMatch(obj, conditions[i]))
            return null;
    }

    return children;
}

When.propTypes = {
    obj : PropTypes.object.isRequired,
    conditions : PropTypes.array.isRequired    
}

When.defaultProps = {
}

export default When;