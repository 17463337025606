import React from 'react';

import styled from 'styled-components'

export const BlockGridLayout = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${ props => props.columns && `repeat(${ props.columns[0] }, 1fr)` || 'repeat(1, 1fr)' };
    grid-gap: ${ props => props.gap };
    overflow: hidden;
  
    @media screen and (min-width: 768px) {
        grid-template-columns: ${ props => props.columns && `repeat(${ props.columns[1] }, 1fr)` || 'repeat(1, 1fr)' };
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: ${ props => props.columns && `repeat(${ props.columns[2] }, 1fr)` || 'repeat(1, 1fr)' };
    }
`