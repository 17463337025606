import React from 'react'

import GameContext from './GameContext'
import ViewContainer from './ViewContainer'
import TopBanner from './TopBanner';
import { RequestAds } from '../components/ads';

class Statistics extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        
            
        return (

            <>
                <TopBanner />
                <GameContext.Consumer>{ ({ gameConfiguration }) => (
                    <ViewContainer name={ gameConfiguration && gameConfiguration.views && gameConfiguration.views.statistics } />
                )}</GameContext.Consumer>

                <RequestAds />
            </>

        )
    }
}
  
export default Statistics;