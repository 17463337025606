import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as styles from './Toolbar.scss'


const ToolbarItem = ({ align, expand, className, children }) => {


    const cName = classNames(
        styles.item,
        className,
        {
            [styles.right] : align === 'right',
            [styles.expand] : expand
        }
    )

    return (
        <div className={ cName }>{ children }</div>
    )
}


class Toolbar extends React.PureComponent {

    static Item = ToolbarItem;


    render() {

        const className = classNames(
            styles.container,
            {
                [styles.right] : this.props.right
            }
        )

        return (
            <div className={ className }>
                { this.props.children }
            </div>
        )
    }

}

Toolbar.propTypes = {
    right : PropTypes.bool
}


export default Toolbar;