import axios from 'axios'
import { normalize } from 'normalizr'

import { competitionSchema, leaderboardSchema,fantasyTeamSchema, enrollmentSchema, userSchema, cupQualificationSchema  } from '../../schemas';
import auth from '../../common/auth'

import * as types from './types';

import { shouldFetchItems, shouldFetchItem } from '../common'
import { safeget } from '../../common/utils';

export const enrollmentChange = (game, competition) => ({ type: types.ENROLLMENT_CHANGE, game, competition });

const invalidateUserCompetitions = (game) => ({ type: types.INVALIDATE_USER_COMPETITIONS, game });

/* Fetch User Competition Leaderboards */

const fetchUserCompetitionsRequest = () => ({ type: types.FETCH_USER_COMPETITIONS_REQUEST });
const fetchUserCompetitionsSuccess = (payload) => ({ type: types.FETCH_USER_COMPETITIONS_SUCCESS, payload });
const fetchUserCompetitionsFailure = () => ({ type: types.FETCH_USER_COMPETITIONS_FAIL});

const fetchUserCompetitions = (game) => (dispatch) => { 
    dispatch(fetchUserCompetitionsRequest());

    const accessToken = auth.getAccessToken();

    return axios.get(`/games/${ game }/users/me/competitions?access_token=${ accessToken }`).then(response => {
        dispatch(fetchUserCompetitionsSuccess(normalize(response.data, [
            { 
                competition : competitionSchema,
                teams : [fantasyTeamSchema]
            }
        ])));
    }, e => {
        dispatch(fetchUserCompetitionsFailure());
    });
}

export const fetchUserCompetitionsIfNeeded = () => (dispatch, getState) => {
    const state = getState();
    const game = state.games.selected;
    
    if (shouldFetchItems(state.competition.competitions)) {
        dispatch(fetchUserCompetitions(game));
    }
}

/* Fetch User Competition Leaderboards */

export const fetchUserLeaderboards = (user, competition, round) => (dispatch) => { 
    dispatch({ type: types.FETCH_USER_LEADERBOARDS_REQUEST, user, competition, round });

        axios.get(`/users/${ user }/competitions/${ competition }/leaderboards/${ round }`).then(response => {

            dispatch({ 
                type: types.FETCH_USER_LEADERBOARDS_SUCCESS, 
                user,
                competition,
                round, 
                payload : normalize(response.data, [leaderboardSchema])
            });
        }, error => {
            dispatch({ type: types.FETCH_USER_LEADERBOARDS_FAILURE, user, competition, round });
        });
}


/* Find Competitions */


/* Fetch User Competition Leaderboards */

const findCompetitionsRequest = () => ({ type: types.FIND_COMPETITIONS_REQUEST });
const findCompetitionsSuccess = (count, payload, links) => ({ type: types.FIND_COMPETITIONS_SUCCESS, count, payload, links });
const findCompetitionsFailure = () => ({ type: types.FIND_COMPETITIONS_FAIL });

export const findCompetitions = (game, query, view) => (dispatch) => { 
    dispatch(findCompetitionsRequest());

    return axios.get(`/games/${ game }/competitions?query=${ query }&view=${ view }`).then(response => {
        dispatch(findCompetitionsSuccess(
            response.data.count, 
            normalize(response.data.items, [{ competition : competitionSchema }]),
            response.data.links
        ));
    }, e => {
        dispatch(findCompetitionsFailure());
    });
}


const findMoreCompetitionsRequest = () => ({ type: types.FIND_MORE_COMPETITIONS_REQUEST });
const findMoreCompetitionsSuccess = (count, payload, links) => ({ type: types.FIND_MORE_COMPETITIONS_SUCCESS, count, payload, links });
const findMoreCompetitionsFailure = () => ({ type: types.FIND_MORE_COMPETITIONS_FAIL });

export const findMoreCompetitions = () => (dispatch, getState) => { 
    const { next } = getState().competition.search.links;
    if (next) {
        dispatch(findMoreCompetitionsRequest());

        return axios.get(next).then(response => {
            dispatch(findMoreCompetitionsSuccess(
                response.data.count, 
                normalize(response.data.items, [{ competition : competitionSchema }]),
                response.data.links
            ));
        }, e => {
            dispatch(findMoreCompetitionsFailure());
        });
    }
}



/* Fetch User Standings */

const fetchStandingsRequest = (game) => ({ type: types.FETCH_STANDINGS_REQUEST, game });
const fetchStandingsSuccess = (game, payload) => ({ type: types.FETCH_STANDINGS_SUCCESS, game, payload });
const fetchStandingsFailure = (game) => ({ type: types.FETCH_STANDINGS_FAIL, game });

const fetchStandings = (game) => (dispatch) => { 
    dispatch(fetchStandingsRequest(game));

    const accessToken = auth.getAccessToken();

    return axios.get(`/games/${ game }/users/me/standings?access_token=${ accessToken }`).then(response => {
        dispatch(fetchStandingsSuccess(game, normalize(response.data, [leaderboardSchema])));
    }, e => {
        dispatch(fetchStandingsFailure(game, user));
    });
}



export const fetchStandingsIfNeeded = (game) => (dispatch, getState) => {
    const state = getState();

    if (shouldFetchItems(state.competition.standings)) {
        dispatch(fetchStandings(game));
    }
}



/* Fetch User Qualifications */

const fetchQualificationsRequest = (game) => ({ type: types.FETCH_QUALIFICATIONS_REQUEST, game });
const fetchQualificationsSuccess = (game, payload) => ({ type: types.FETCH_QUALIFICATIONS_SUCCESS, game, payload });
const fetchQualificationsFailure = (game) => ({ type: types.FETCH_QUALIFICATIONS_FAIL, game });

const fetchQualifications = (game) => (dispatch) => { 
    dispatch(fetchQualificationsRequest(game));

    const accessToken = auth.getAccessToken();

    return axios.get(`/games/${ game }/users/me/qualifications?access_token=${ accessToken }`).then(response => {
        dispatch(fetchQualificationsSuccess(game, normalize(response.data, [cupQualificationSchema])));
    }, e => {
        dispatch(fetchQualificationsFailure(game));
    });
}

export const fetchQualificationsIfNeeded = (game) => (dispatch, getState) => {
    const state = getState();
    
    
    if (shouldFetchItems(state.competition.qualifications)) {
        dispatch(fetchQualifications(game));
    }
}




/* Create Competition */

const createCompetitionRequest = (game) => ({ type: types.CREATE_COMPETITION_REQUEST, game });
const createCompetitionSuccess = (game, item) => ({ type: types.CREATE_COMPETITION_SUCCESS, game, item });
const createCompetitionFailure = (game, e) => ({ type: types.CREATE_COMPETITION_FAIL, game, e });

export const createCompetition = (game, item) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(createCompetitionRequest());

    const accessToken = auth.getAccessToken();

    return axios.post(`/games/${ game }/competitions?access_token=${ accessToken }`, item).then(response => {
        
        dispatch(createCompetitionSuccess(game, item));
        dispatch(invalidateUserCompetitions());
        dispatch(fetchUserCompetitionsIfNeeded());
        

        resolve(response);
    }, e => {
        dispatch(createCompetitionFailure(game, e));
        reject(e);
    });
})


/* Delete Competition */

const deleteCompetitionRequest = (competitionId) => ({ type: types.DELETE_COMPETITION_REQUEST, competitionId });
const deleteCompetitionSuccess = (competitionId) => ({ type: types.DELETE_COMPETITION_SUCCESS, competitionId });
const deleteCompetitionFailure = (competitionId) => ({ type: types.DELETE_COMPETITION_FAIL, competitionId });

export const deleteCompetition = (competitionId) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(deleteCompetitionRequest(competitionId));

    const accessToken = auth.getAccessToken();

    return axios.delete(`/competitions/${ competitionId }?access_token=${ accessToken }`).then(() => {
        dispatch(deleteCompetitionSuccess(competitionId));
        resolve();
    }, e => {
        dispatch(deleteCompetitionFailure(competitionId));
        reject(e);
    });
})



/* Create Competition */

const updateCompetitionRequest = () => ({ type: types.UPDATE_COMPETITION_REQUEST });
const updateCompetitionSuccess = (item) => ({ type: types.UPDATE_COMPETITION_SUCCESS, item });
const updateCompetitionFailure = (e) => ({ type: types.UPDATE_COMPETITION_FAIL, e });

export const updateCompetition = (id, item) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(updateCompetitionRequest());

    const accessToken = auth.getAccessToken();

    return axios.put(`/competitions/${ id }?access_token=${ accessToken }`, item).then(response => {
        dispatch(updateCompetitionSuccess(item));
        dispatch(fetchCompetition(id));
        resolve(response);
    }, e => {
        dispatch(updateCompetitionFailure(e));
        reject(e);
    });
})


/* Fetch User Competition Enrollments */

const invalidateEnrollmentOptions = (competitionId) => ({ type: types.INVALIDATE_ENROLLMENT_OPTIONS, competitionId });

const fetchEnrollmentOptionsRequest = (competitionId) => ({ type: types.FETCH_ENROLLMENT_OPTIONS_REQUEST, competitionId });
const fetchEnrollmentOptionsSuccess = (competitionId, payload) => ({ type: types.FETCH_ENROLLMENT_OPTIONS_SUCCESS, competitionId, payload });
const fetchEnrollmentOptionsFailure = (competitionId) => ({ type: types.FETCH_ENROLLMENT_OPTIONS_FAIL, competitionId });

export const fetchEnrollmentOptions = (competitionId) => (dispatch) => { 
    dispatch(fetchEnrollmentOptionsRequest(competitionId));

    const accessToken = auth.getAccessToken();

    return axios.get(`/competitions/${ competitionId }/enrollment-options?access_token=${ accessToken }`).then(response => {
        dispatch(fetchEnrollmentOptionsSuccess(competitionId, normalize(response.data, {
                fantasyTeams : [fantasyTeamSchema]
            })
        ));
    }, e => {
        dispatch(fetchEnrollmentOptionsFailure(competitionId));
    });
}


export const fetchEnrollmentOptionsIfNeeded = (competitionId) => (dispatch, getState) => {
    const state = safeget(getState().competition.byCompetition, [competitionId, 'enrollmentOptions']);
    
    if (shouldFetchItem(state)) {
        return dispatch(fetchEnrollmentOptions(competitionId));
    }
}

/* Create Competition */

export const joinCompetition = (competitionId, fantasyTeamId, password) => (dispatch) => new Promise((resolve, reject) => { 


    const accessToken = auth.getAccessToken();

    const item = {
        fantasyTeam : { id : fantasyTeamId },
        password
    };

    return axios.post(`/competitions/${ competitionId }/enrollments?access_token=${ accessToken }`, item).then(_ => {
        dispatch(invalidateUserCompetitions());
        dispatch(fetchUserCompetitionsIfNeeded());
        dispatch(fetchMyEnrollments(competitionId));
        dispatch(invalidateEnrollmentOptions(competitionId));

        resolve();
    }, e => {
        reject(e);
    });
})




/* Fetch Competition */

const fetchCompetitionRequest = () => ({ type: types.FETCH_COMPETITION_REQUEST });
const fetchCompetitionSuccess = (payload) => ({ type: types.FETCH_COMPETITION_SUCCESS, payload });
const fetchCompetitionFailure = () => ({ type: types.FETCH_COMPETITION_FAIL});

export const fetchCompetition = (id) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(fetchCompetitionRequest());

    const accessToken = auth.getAccessToken();

    return axios.get(`/competitions/${ id }/?access_token=${ accessToken }`).then(response => {
        
        dispatch(fetchCompetitionSuccess(normalize(response.data, competitionSchema)));
        resolve(response.data);
    }, e => {
        dispatch(fetchCompetitionFailure());
        reject(e);
    });
})

export const fetchCompetitionIfNeeded = (id) => (dispatch, getState) => {
    const state = getState();

    if (shouldFetchItem(state.competition.competition)) {
        dispatch(fetchCompetition(id));
    }
}

/* Fetch Competition Enrollments */

const invalidateEnrollments = (competitionId) =>  ({ type: types.INVALIDATE_ENROLLMENTS, competitionId });

const fetchEnrollmentsRequest = (competitionId) => ({ type: types.FETCH_ENROLLMENTS_REQUEST, competitionId });
const fetchEnrollmentsSuccess = (competitionId, payload) => ({ type: types.FETCH_ENROLLMENTS_SUCCESS, competitionId, payload });
const fetchEnrollmentsFailure = (competitionId) => ({ type: types.FETCH_ENROLLMENTS_FAIL, competitionId });

export const fetchEnrollments = (competitionId, query) => (dispatch) => { 
    dispatch(fetchEnrollmentsRequest(competitionId));

    const accessToken = auth.getAccessToken();

    return axios.get(`/competitions/${ competitionId }/enrollments?query=${ query }&access_token=${ accessToken }`).then(response => {
        dispatch(
            fetchEnrollmentsSuccess(
                competitionId, 
                normalize(response.data, {
                    items : [enrollmentSchema]
                })
            )
        );
    }, e => {
        dispatch(fetchEnrollmentsFailure(competitionId));
    });
}

export const fetchEnrollmentsIfNeeded = (competitionId, query) => (dispatch, getState) => new Promise((resolve, reject) => {
    const { enrollments } = getState().competition.byCompetition[competitionId] || { enrollments : { isFetching : false, item : null, didInvalidate : false } }
    
    if (shouldFetchItem(enrollments)) {
        dispatch(fetchEnrollments(competitionId, query)).then(() => resolve(), () => reject());
    }
    else {
        resolve();
    }
});



const fetchMoreEnrollmentsRequest = (competitionId) => ({ type: types.FETCH_MORE_ENROLLMENTS_REQUEST, competitionId });
const fetchMoreEnrollmentsSuccess = (competitionId, payload) => ({ type: types.FETCH_MORE_ENROLLMENTS_SUCCESS, competitionId, payload });
const fetchMoreEnrollmentsFailure = (competitionId) => ({ type: types.FETCH_MORE_ENROLLMENTS_FAIL, competitionId });

export const fetchMoreEnrollments = (competitionId) => (dispatch, getState) => { 
    const next = safeget(getState().competition.byCompetition, [competitionId, 'enrollments', 'item', 'links', 'next']);

    if (!next)
        return;

    dispatch(fetchMoreEnrollmentsRequest(competitionId));

    const accessToken = auth.getAccessToken();

    return axios.get(`${ next }?access_token=${ accessToken }`).then(response => {
        dispatch(
            fetchMoreEnrollmentsSuccess(
                competitionId, 
                normalize(response.data, {
                    items : [enrollmentSchema]
                })
            )
        );
    }, e => {
        dispatch(fetchMoreEnrollmentsFailure(competitionId));
    });
}


/* Kick */

export const kick = (competitionId, fantasyTeamId, ban) => (dispatch) => new Promise((resolve, reject) => { 
    const accessToken = auth.getAccessToken();

    return axios.post(`/competitions/${ competitionId }/teams/${ fantasyTeamId }/kick?access_token=${ accessToken }`, { ban }).then(_ => {
        dispatch(invalidateEnrollments(competitionId))
        resolve();
    }, e => {
        reject(e);
    });
})


/* Create Competition */

const inviteRequest = (competitionId, userId) => ({ type: types.INVITE_REQUEST, competitionId, userId });
const inviteSuccess = (competitionId, userId) => ({ type: types.INVITE_SUCCESS, competitionId, userId });
const inviteFailure = (competitionId, userId) => ({ type: types.INVITE_FAIL, competitionId, userId });

export const invite = (competitionId, userId) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(inviteRequest(competitionId, userId));

    const accessToken = auth.getAccessToken();

    axios.post(`/competitions/${ competitionId }/users/${ userId }/invitations?access_token=${ accessToken }`).then(response => {
        resolve(response);
        dispatch(inviteSuccess(competitionId, userId));
    }, e => {
        reject(e);
        dispatch(inviteFailure(competitionId, userId));
        
    });
});


/* Fetch Created Competition */

const fetchMyEnrollmentsRequest = (competitionId) => ({ type: types.FETCH_MY_ENROLLMENTS_REQUEST, competitionId });
const fetchMyEnrollmentsSuccess = (competitionId, payload) => ({ type: types.FETCH_MY_ENROLLMENTS_SUCCESS, competitionId, payload });
const fetchMyEnrollmentsFailure = (competitionId) => ({ type: types.FETCH_MY_ENROLLMENTS_FAIL, competitionId });

export const fetchMyEnrollments = (competitionId) => (dispatch) => { 
    dispatch(fetchMyEnrollmentsRequest(competitionId));

    const accessToken = auth.getAccessToken();

    return axios.get(`/users/me/competitions/${ competitionId }/enrollments?access_token=${ accessToken }`).then(response => {
        dispatch(fetchMyEnrollmentsSuccess(competitionId, 
            normalize(response.data, [enrollmentSchema])
        ));
    }, e => {
        dispatch(fetchMyEnrollmentsFailure());
    });
}

/* Find Invitees */



/* Resign */

const resignSuccess = (competitionId, fantasyTeamId) => ({ type: types.RESIGN_SUCCESS, competitionId, fantasyTeamId });


export const resign = (competitionId, fantasyTeamId) => (dispatch) => { 
    const accessToken = auth.getAccessToken();

    return axios.delete(`/competitions/${ competitionId }/teams/${ fantasyTeamId }?access_token=${ accessToken }`).then(() => {
        dispatch(resignSuccess(competitionId, fantasyTeamId))
        dispatch(invalidateUserCompetitions());
        dispatch(fetchUserCompetitionsIfNeeded());
        dispatch(fetchMyEnrollments(competitionId));
        dispatch(fetchEnrollments(competitionId));
        dispatch(invalidateEnrollmentOptions(competitionId));
        
    }, e => {
        reject(e);
    });
}


export const fetchShareUrl = (competitionId, language) => (dispatch) => new Promise((resolve, reject) => { 
    const accessToken = auth.getAccessToken();

    return axios.get(`/competitions/${ competitionId }/share?access_token=${ accessToken }&language=${ language }`).then(response => {
        resolve(response.data);
    }, e => {
        reject();
    });
})



/* Invite From Other Competition */

export const inviteFromCompetition = (targetCompetitionId, sourceCompetitionId) => (dispatch) => { 
    const accessToken = auth.getAccessToken();
    return axios.post(`/competitions/${ targetCompetitionId }/competitions/${ sourceCompetitionId }/invite?access_token=${ accessToken }`, {});
}





const inviteAllRequest = () => ({ type: types.INVITE_ALL_REQUEST });
const inviteAllSuccess = () => ({ type: types.INVITE_ALL_SUCCESS });
const inviteAllFailure = () => ({ type: types.INVITE_ALL_FAIL });

export const inviteAll = () => (dispatch, getState) => new Promise((resolve, reject) => { 
    const state = getState();
    const selectedOption = state.recruits.selectedOption;
    const option = state.recruits.options.find(o => o.id === selectedOption);

    if (option.links.invite) {
        dispatch(inviteAllRequest());
        const accessToken = auth.getAccessToken();

        const sep =  option.links.invite.indexOf('?') > 0 ? '&' : '?';

        return axios.post(`${ option.links.invite }${ sep }access_token=${ accessToken }`).then(res => {
            
            dispatch(inviteAllSuccess());
            resolve(res);
        }, e => {
            dispatch(inviteAllFailure());
            reject();
        });
    }

    resolve(null);
})

/* */

const fetchMyCompetitionHistoryRequest = () => ({ type: types.FETCH_MY_COMPETITION_HISTORY_REQUEST });
const fetchMyCompetitionHistorySuccess = (payload) => ({ type: types.FETCH_MY_COMPETITION_HISTORY_SUCCESS, payload });
const fetchMyCompetitionHistoryFailure = () => ({ type: types.FETCH_MY_COMPETITION_HISTORY_FAIL });

export const fetchMyCompetitionHistory = (rel) => (dispatch, getState) => { 
    

    const accessToken = auth.getAccessToken();

    let url = `/users/me/competitions/history?access_token=${ accessToken }`;
    if (rel) {
        const state = getState();
        const { data } = state.competition.competitionHistory;
        const { links } = data;
        url = `${ links[rel] }&access_token=${ accessToken }`;
    }

    dispatch(fetchMyCompetitionHistoryRequest());
    return axios.get(url).then(response => {
        dispatch(fetchMyCompetitionHistorySuccess(response.data));
    }, e => {
        dispatch(fetchMyCompetitionHistoryFailure());
    });
}