import React from 'react';
import { withRouter, Link } from 'react-router-dom'
import { format, getConfigurationSection } from '../../common/utils'

import { ExternalLink, HorizontalMenu, VerticalMenu, MenuItem } from '../../components'


import GameContext from '../GameContext';
import LocalizeText from '../LocalizeText'


const GameMenu = ({ match, vertical, themeName }) => (

    

    <GameContext.Consumer>
        { ({ game, gameConfiguration}) => {

            if (!gameConfiguration || !game)
                return;

            
            const menu = getConfigurationSection(gameConfiguration, 'menu', window.swush.profile);
            const Menu = vertical ? VerticalMenu : HorizontalMenu;
            
            return (
                <Menu>
                    {
                        menu && menu.items.map((i, index) => {
                            if (i.external || window.swush.routing == 'static') {
                                const url = format(i.url, { language: match.params.language, gameSlug: game.slug, gameId : game.id });

                                
                                return (
                                    <MenuItem as={ ExternalLink } key={ index }  href={ url }>
                                        <LocalizeText text={ i.label } />
                                    </MenuItem>        
                                )
                            }
                            else {
                                const route = format(i.url, { language: match.params.language, gameSlug: game.slug, gameId : game.id });
                                
                                return (
                                    <MenuItem key={ index } as={ Link } to={ route }>
                                            <LocalizeText text={ i.label } />
                                    </MenuItem>        
                                )
                            }
                        })
                    }
                </Menu>
            )
        }}
    </GameContext.Consumer>
)

GameMenu.defaultProps = {
    vertical : false
}

export default withRouter(GameMenu);