import React from 'react'
import PropTypes from 'prop-types'

import { number } from 'utils';

import * as styles from './Typography.scss'

const Number = ({ value, treatZeroAsMissing, postfix }) => {
    if (typeof value !== 'number' || (treatZeroAsMissing && value === 0)) {
        return (<span className={ styles.meta }>-</span>)
    }
    else if (value > 0) {
        return number(value) + postfix;
    }
    else if (value < 0) {
        return <span className={ styles.negative}>{ number(value) }{ postfix}</span>;
    }
    else { 
        return '0' + postfix 
    }
}

Number.propTypes = {
    value : PropTypes.number,
    postfix : PropTypes.string,
    treatZeroAsMissing : PropTypes.bool.isRequired
}

Number.defaultProps = {
    postfix : '',
    treatZeroAsMissing : false
}

export default Number;