import React from 'react';

import FormContext from '../../FormContext'

import { RoundDropDownList } from 'components'

const RoundDropdownListWidget = ({ editMode, property }) => {
    if (editMode) {
        return <div>RoundDropdownList</div>
    }


    return (
        <FormContext.Consumer>{ ({ data, setElement }) => {
            const value = data[property];
            
            return (
                <RoundDropDownList  type="currentAndPrevious" 
                                    selected={ value } 
                                    onSelect={ (value) => setElement(property, value) } 
                                    inline 
                                    appearance="transparent" 
                                    />
            )
            
        }}</FormContext.Consumer>
    )
}

export default RoundDropdownListWidget;