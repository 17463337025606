import React from 'react';
import PropTypes from 'prop-types'

import { TabBar, TabBarItem,ErrorBoundary } from 'components'
import LocalizeText from '../../LocalizeText';


export const TabBarItemWidget = () => null;

TabBarItemWidget.propTypes = {
    title : PropTypes.string.isRequired
}

export const TabBarWidget = ({ children, ...otherProps }) => {
    
    const items = React.Children.map(children, (child, index) => { 
        const { title, children, ...otherProps } = child.props;

        return <TabBarItem key={ index } title={ <LocalizeText text={ child.props.title } /> } { ...otherProps }>
                    <ErrorBoundary>
                        { children }
                    </ErrorBoundary>
                </TabBarItem>
    });

    return <TabBar { ...otherProps} themeName="_current">
                { items }
           </TabBar>

}