import React from 'react';
import PropTypes from 'prop-types';
import { XButton } from 'components'

import FormContext from '../../FormContext'
import LocalizeText from '../../LocalizeText'

export const ResetButtonWidget = ({ caption }) => {
    return (
        <FormContext.Consumer>{ ({ clear }) => {
            return (
                <XButton onClick={ () => clear() } variant="transparent" textTransform="uppercase"><LocalizeText text={ caption } /></XButton>
            )
            
        }}</FormContext.Consumer>
    )
}

ResetButtonWidget.propTypes = {
    caption : PropTypes.string.isRequired
}

export default ResetButtonWidget;