
import { useCallback } from 'react'
import { useDispatch } from 'react-redux';

import { fetchShareUrl } from '../redux/competition/actions'

import useModal from '../containers/useModal';
import { useTranslator } from '../hooks/useTranslator'

const useCompetitionShareDialog = () => {
    const [showModal] = useModal();
    const dispatch = useDispatch();
    const t = useTranslator();

    const show = useCallback((competition, language) => {

        dispatch(fetchShareUrl(competition.id, language)).then(res => {
            showModal('share', { 
                competition,
                title : t('common.share_item', { item : competition.name }), 
                text : '',
                description : t('competitions.share_mini_league_description'),
                subject : t('competitions.share_mini_league_cta'), 
                url : res.url
            });
        }, (err) => {
            alert(t('common.an_error_occurred'));
        })

        
    })

    return [show];
}

export default useCompetitionShareDialog;