import React from 'react';

import * as fromComponents  from 'components'


export const PageContentContainerEditor = ({ children }) => children;

export const PageContentContainer = ({ children, ...otherProps }) => {

    return <fromComponents.PageContentContainer { ...otherProps}>
                { children }
           </fromComponents.PageContentContainer>

}
