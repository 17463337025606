import React from 'react'
import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

const ItemStyle = css`
    display: block;
    line-height: 24px;
`


const ExternalItem = styled.a`
    ${ ItemStyle }
`

const Item = styled(Link)`
    ${ ItemStyle }
`

const Container = styled.div`

`


const NavigationMenu  = ({ items }) => {
    
    return (
        <Container>
            { 
            items.map((i, index) => {
            
                if (i.external || window.swush.routing == 'static') {
                    return <ExternalItem key={ index } href={ i.href }>{ i.text }</ExternalItem> 
                }
                else {
                    return <Item key={ index } to={ i.href }>{ i.text }</Item> 
                }
            
            } )}
        </Container>
    )
}


export default NavigationMenu;