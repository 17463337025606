import axios from 'axios'


import { normalize } from 'normalizr';
import { injurySchema } from '../../schemas';

import * as types from './types';

/* Fetch User Competition Leaderboards */

const fetchTournamentInjuriesRequest = (tournament) => ({ type: types.FETCH_TOURNAMENT_INJURIES_REQUEST, tournament });
const fetchTournamentInjuriesSuccess = (tournament, payload) => ({ type: types.FETCH_TOURNAMENT_INJURIES_SUCCESS, tournament, payload });
const fetchTournamentInjuriesFailure = (tournament) => ({ type: types.FETCH_TOURNAMENT_INJURIES_FAIL, tournament });

const fetchTournamentInjuries = (tournament) => (dispatch) => { 
    dispatch(fetchTournamentInjuriesRequest(tournament));

    return axios.get(`/tournaments/${ tournament }/injuries?culture=${ window.swush.apiCulture }`).then(response => {
        dispatch(fetchTournamentInjuriesSuccess(tournament, normalize(response.data, [injurySchema])));
    }, e => {
        dispatch(fetchTournamentInjuriesFailure(tournament));
    });
}

const shouldFetchTournamentInjuries = (state, tournament) => {
    const { isFetching, didInvalidate, items } = state.injury.byTournament[tournament] || { isFetching : false, didInvalidate : false, items : null };

    if (isFetching) {
        return false;
    }
    else if (!items) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const fetchTournamentInjuriesIfNeeded = (tournament) => (dispatch, getState) => {
    if (shouldFetchTournamentInjuries(getState(), tournament)) {
        dispatch(fetchTournamentInjuries(tournament));
    }
}