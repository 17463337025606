import React from 'react'
import PropTypes from 'prop-types'

import { number } from 'utils'

const TableValue = ({ format, value }) => {
    return number(value);
}

TableValue.propTypes = {
    format : PropTypes.oneOf([ 'number' ]).isRequired,
    value : PropTypes.any
}



export default TableValue;