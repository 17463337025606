import {  useMemo } from 'react'
import { useSelector } from 'react-redux'

import { makeGetUnreadMessageBoardPosts  } from '../../redux/messageBoard/selectors';

const useUnread = (messageBoardId) => {
    const getUnreadMessageBoardPosts = useMemo(() => makeGetUnreadMessageBoardPosts(), []);

    const unread = useSelector(state => {
        return messageBoardId && getUnreadMessageBoardPosts(state, messageBoardId) || 0
    });

    return [unread];
}


export default useUnread;