import React from 'react'

import { Table, EventDate, Round } from '../../components'

import MatchStatus from '../MatchStatus'

import translatable from '../../hocs/translatable'

const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}


const CyclingScheduleTableRow = ({ item, onClick }) => {
    const properties = item.properties || {};

    return (
        <Table.Row onClick={ onClick } active={ item.current }>
            <Table.Cell center><Round post={ item.status === 'completed' }>{ item.round }</Round></Table.Cell>
            <Table.Cell><EventDate date={ new Date(item.start) } /></Table.Cell>
            <Table.Cell><span className="a">{ item.name }</span></Table.Cell>
            <Table.Cell right><Kilometers value={ properties.stageDistance } /></Table.Cell>
            <Table.Cell right>{ properties.stageType }</Table.Cell>
            <Table.Cell right><MatchStatus status={ item.status } /></Table.Cell>
        </Table.Row>
    )
}

const CyclingScheduleTable = ({ t, schedule, onSelectMatch }) => {
    return (
        <Table>
            <Table.Head>
                <Table.Row>
                    <Table.Header center>{ t('common.round') }</Table.Header>
                    <Table.Header>{ t('common.start') }</Table.Header>
                    
                    <Table.Header>{ t('cycling.stage') }</Table.Header>
                    <Table.Header right>{ t('cycling.distance') }</Table.Header>
                    <Table.Header right>{ t('cycling.type') }</Table.Header>
                      
                    <Table.Header right>{ t('common.status') }</Table.Header>
                </Table.Row>
            </Table.Head>
            <Table.Body>
                { schedule.items.map(item => <CyclingScheduleTableRow key={ item.id } item={ item } onClick={ () => onSelectMatch(item) } ></CyclingScheduleTableRow>) }
            </Table.Body>
        </Table>
    )
}

export default translatable(CyclingScheduleTable);