import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { subscribe, unsubscribe } from '../../redux/messageBoard/actions'

const MessageBoardProvider = ({ messageBoardId, children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (messageBoardId) {
            dispatch(subscribe(messageBoardId));

            return () => {
                dispatch(unsubscribe(messageBoardId));
            }
        }
    }, [messageBoardId]);

    return children;
}

export default MessageBoardProvider;