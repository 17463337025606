import { combineReducers } from 'redux'

import gameReducer from './game';
import highscoreReducer from './highscore';
import authReducer from './auth';
import userReducer from './user';
import configReducer from './config';
import groupReducer from './group';
import playerReducer from './player';
import watchlistReducer from './watchlist';
import uiReducer from './ui';
import competitionReducer from './competition';
import recruitsReducer from './recruits';
import matchReducer from './match';
import viewReducer from './view';
import rulesetReducer from './ruleset';
import tournamentReducer from './tournament';
import scheduleReducer from './schedule';
import entityReducer from './entity'
import jsonReducer from './json'
import fantasyTeamReducer from './fantasyTeam'
import notificationReducer from './notification'
import injuryReducer from './injury'
import suspensionReducer from './suspension'
import permissionReducer from './permission';
import invitationReducer from './invitation';
import themeReducer from './theme';
import messageBoardReducer from './messageBoard';
import friendsReducer from './friends';
import archiveReducer from './archive';

const rootReducer = combineReducers({
    entities : entityReducer,
    games : gameReducer, 
    highscore : highscoreReducer,
    auth : authReducer,
    user : userReducer,
    configs : configReducer,
    ui : uiReducer,
    group : groupReducer,
    player : playerReducer,
    watchlist : watchlistReducer,
    match : matchReducer,
    competition : competitionReducer,
    views : viewReducer,
    rulesets : rulesetReducer,
    tournaments : tournamentReducer,
    schedule : scheduleReducer,
    json : jsonReducer,
    fantasyTeam : fantasyTeamReducer,
    notification : notificationReducer,
    injury : injuryReducer,
    suspension : suspensionReducer,
    permission : permissionReducer,
    invitation : invitationReducer,
    theme : themeReducer,
    messageBoard : messageBoardReducer,
    friends : friendsReducer,
    recruits : recruitsReducer,
    archive : archiveReducer
    
})

export default rootReducer
