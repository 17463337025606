import React from 'react'
import PropTypes from 'prop-types'

import serverTime from '../common/serverTime';

class CountdownBase extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            round: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            rounds: 0,
            show: true
        };

        this.timer = null;
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(() => { this.update(this.props) }, 1000);
        this.update(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.update(nextProps);
    }

    componentWillUnmount() {
       clearInterval(this.timer);
    }

    update() {
        const { to } = this.props;
        
        let time = to - serverTime.now();

        if (time < 0)
            time = 0;

        let tsec = parseInt(time / 1000);

        let days = Math.floor(tsec / (60 * 60 * 24));
        tsec -= days * (60 * 60 * 24)

        let hours = Math.floor(tsec / (60 * 60));
        tsec -= hours * (60 * 60);

        let minutes = Math.floor(tsec / (60));
        tsec -= minutes * (60);

        let seconds = tsec;

        this.setState({
            days,
            hours,
            minutes,
            seconds
        });


    }

    formatPart(value) {
        return ('0' + value).slice(-2);
    }

    render() {
        return this.props.children(this.state)
    }
}

CountdownBase.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
    children : PropTypes.func.isRequired
}

export default CountdownBase;
