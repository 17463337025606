export const SELECT_RECRUITMENT_OPTION      = 'SELECT_RECRUITMENT_OPTION';

export const FETCH_RECRUITS_REQUEST         = 'FETCH_RECRUITS_REQUEST';
export const FETCH_RECRUITS_SUCCESS         = 'FETCH_RECRUITS_SUCCESS';
export const FETCH_RECRUITS_FAIL            = 'FETCH_RECRUITS_FAIL';

export const FETCH_MORE_RECRUITS_REQUEST    = 'FETCH_MORE_RECRUITS_REQUEST';
export const FETCH_MORE_RECRUITS_SUCCESS    = 'FETCH_MORE_RECRUITS_SUCCESS';
export const FETCH_MORE_RECRUITS_FAIL       = 'FETCH_MORE_RECRUITS_FAIL';

