import React from 'react'
import PropTypes from 'prop-types'
import CountdownBase from './CountdownBase'

const TextCountdown = ({ to, children }) => {
    const formatPart = (value) => {
        return ('0' + value).slice(-2);
    }

    return (
        <CountdownBase to={ to }>
            {
                values => {
                    const text = `${ values.days } ${ formatPart(values.hours) }:${ formatPart(values.minutes) }:${ formatPart(values.seconds) }` 
                    return  children(text);
                }
            }
        </CountdownBase>
    )
}

TextCountdown.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
}

export default TextCountdown;
