import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { createSelector } from 'reselect'

import { fetchScheduleIfNeeded } from '../redux/schedule/actions'
import { fetchJsonIfNeeded } from '../redux/json/actions'
import dayjs from 'dayjs'


class WithVersusSchedule extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetch(this.props.id);
        this.props.fetchOdds(this.props.oddsFeed);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.props.fetch(nextProps.id);
        }

        if (nextProps.oddsFeed !== this.props.oddsFeed) {
            this.props.fetchOdds(nextProps.oddsFeed);
        }
    }

    render() {
        const { schedule, children } = this.props;
        return children({ schedule });
    }
}

export const makeGetSchedule = () => (
    createSelector([
        (state, props) => props.id,
        (state, props) => props.gameId,
        (state, props) => props.round,
        (state, props) => state.json.byUrl[props.oddsFeed],
        (state) => state.schedule.byTournament,
        (state) => state.entities.games,
        (state) => state.entities.matches,
        (state) => state.entities.locations,
        (state) => state.entities.matchGroups,
        (state) => state.entities.teams

    ],
    (id, gameId, round, feed, scheduleByTournament, games, matchEntities, locationEntities, matchGroupEntities, teamEntities) => {
            const schedule = scheduleByTournament[id] || { items : [] };
            const game = games[gameId];
            const r = game.rounds[round - 1];


            
            const oddsMap = feed && feed.item && feed.item.bets.reduce((agg, b) => {
                const day = dayjs(b.betEvent.startTime).format('YYYYMMDD');
        
                const data = b.odds.reduce((ao, o) => {
        
                    ao[o.type] = o;
                    return ao;
                }, { });
        
                if (data['1'] && data['x'] && data['2']) {
                    const key = (day + '+' + data['1'].name + '+' + data['2'].name).toLowerCase();
                    agg[key] = data;
                }
                
                return agg;
            }, {}) || {};
            
            

            
            const items = (schedule.items || []).map(id => {
                const match = matchEntities[id];
                const participants = match.participants.reduce((acc, p) => {
                    acc[p.type] = teamEntities[p.team]
                    return acc;
                }, {});


                const home = participants['home'];
                const away = participants['away'];
                const day = dayjs(match.start).format('YYYYMMDD');
                const key = (day + '+' + (home && home.name || '_') + '+' + (away && away.name || '_')).toLowerCase();
                const odds = oddsMap[key];
                
                return {
                    ...match,
                    location : locationEntities[match.location],
                    group : matchGroupEntities[match.group],
                    participants,
                    odds
                }
            }).filter(item => new Date(item.start) >= new Date(r.start) && new Date(item.start) <= new Date(r.end));

            const groups = items.reduce((acc, item) => {
                const start = new Date(item.start);
                const group = `${ start.getYear() }-${ start.getMonth() }-${ start.getDate() } }`;

                acc[group] = acc[group] || [];
                acc[group].push(item);

                return acc;
            }, [])


            return {
                ...schedule,
                groups
            }
        }
    )
)

const makeMapStateToProps = () => {
    const getSchedule = makeGetSchedule();
    
    return (state, ownProps) => ({
        schedule : getSchedule(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id) {
            if (id) {
                dispatch(fetchScheduleIfNeeded(id));
            }
        },

        fetchOdds(url)  {
            if (url) {
                dispatch(fetchJsonIfNeeded(url))
            }
        }

    }
}

WithVersusSchedule.propTypes = {
    id : PropTypes.any,
    gameId : PropTypes.any,
    oddsFeed : PropTypes.string,

    fetch : PropTypes.func,
    fetchOdds : PropTypes.func,
    
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithVersusSchedule);

