import React from 'react'
import PropTypes from 'prop-types'

import ScrollContainer from './ScrollContainer'

import * as styles from './Highlight.scss'

import styled from 'styled-components'

const StyledWrapper = styled.div`
	--highlight-background-color: ${ props => props.theme.colors.cardBackground };
	--highlight-meta-color:  ${ props => props.theme.colors.cardForeground };
	--highlight-text-color:  ${ props => props.theme.colors.cardForeground };
`

class HighlightItem extends React.PureComponent {

    render() {
        return (
            <div className={ styles.item }>
                <div className={ styles.content }>
                    <div className={ styles.heading }>{ this.props.heading }</div>
                    <div className={ styles.value }>{ this.props.value }</div>
                    <div className={ styles.children }>
                        { this.props.children }
                    </div>
                </div>
            </div>
        )     
    }
}


HighlightItem.propTypes = {
    media : PropTypes.any,
    header : PropTypes.any,
    value : PropTypes.any
}


class Highlight extends React.PureComponent {

    static Item = HighlightItem;

    render() {
        return (
            <StyledWrapper>
                <ScrollContainer>
                    <div className={ styles.container }>
                        { this.props.children }
                    </div>
                </ScrollContainer>
            </StyledWrapper>
        )     
    }
}


Highlight.propTypes = {
}


export default Highlight;