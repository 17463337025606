import React from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types'

class SidebarContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }


    onKeyDown(e) {
        if (e.which === 27 && this.props.open) {
            this.props.onClose();
        }
    }

  



    render() {

        const classes = classNames(
            'sidebar',
            { 'sidebar--open' : this.props.open },
            { 'sidebar--locked' : this.props.locked }
        )


        if (this.props.open) {
            document.body.style.overflow = 'hidden';
        }
        else 
            document.body.style.overflow = 'auto';

        
        const children = React.Children.map(this.props.children, (child) => {
            return React.cloneElement(child, { onClose : this.props.onClose });
        })


        return (
            <div className={ classes }>
                { children }

            </div>
        )
    }
}

/*
                <div className="sidebar__main">
                    { main }
                    
                </div>
                <div className="sidebar__side">
                    <div className="sidebar__head">
                        { this.props.title }

                        <a className="sidebar__close" onClick={this.props.onClose}>
                            <Icon type="close"/>
                        </a>
                    </div>

                    { side }
                </div>*/

SidebarContainer.propTypes = {
    open : PropTypes.bool,
    onClose : PropTypes.func
}

SidebarContainer.defaultProps = {
    open : false
}

export default SidebarContainer;