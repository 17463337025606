import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Label, Email, Form, FormField,  LoginButton, AuthenticationHeader, Fieldset } from 'components'
import { BusyButton } from '../../molecules'

import { checkAccount, facebookLogin } from '../../redux/auth/actions'

import { useTranslator } from '../../hooks/useTranslator'

import styled from 'styled-components'


const SpacerText = styled.div`
    line-height: 24px;
    margin: 24px 0;
    color: var(--meta-text-color);
    font-size: var(--font-size-s);
    text-align: center;
    
`

const FacebookLogin = () => {
    const dispatch = useDispatch();
    const t = useTranslator();

    const onAuthorize = (authResponse) => {
        dispatch(facebookLogin(authResponse.accessToken));
    }

    return (
        <LoginButton small inline onAuthorize={ onAuthorize } scopes={ ['email'] }>{ t('authentication.continueWithFacebookButtonCaption') }</LoginButton>
    )
}

export const Start = () => {
    const dispatch = useDispatch();
    const { problems, isBusy } = useSelector(state => state.auth.check);
    const [email, setEmail] = useState('');
    const t = useTranslator();
    const ref = useRef()

    const submittable = email && email.length > 0;

    useEffect(() => {
        ref.current.focus();

        if (window.localStorage && window.localStorage.email) {
            setEmail(window.localStorage['email']);
        }

    }, [])

    const onSubmitInternal = (e) => {
        e.preventDefault();
        if (submittable) {
            dispatch(checkAccount(email));
        }
    }

    const onClear = () => {
        setEmail('');
        if (window.localStorage) {
            window.localStorage.removeItem('email');
        }
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.signInOrCreateHeading') }</AuthenticationHeader>

            <Form problems={ problems } onSubmit={ e => onSubmitInternal(e) }>

                <Fieldset>
                    <FormField name="email">
                        <Label>{ t('authentication.emailLabel' )}</Label>
                        <Email value={ email } ref={ ref } onChange={ (e) => setEmail(e.target.value)} onClear={ onClear } />
                    </FormField>
                </Fieldset>

                <BusyButton type="submit" disabled={ !submittable } busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ t('authentication.continueButtonCaption' )}</BusyButton>

                <SpacerText>{ t('authentication.or') }</SpacerText>

                <FacebookLogin />
            </Form>
        </React.Fragment>
    )
}


