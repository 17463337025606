import React, { useMemo } from 'react'
import styled from 'styled-components'

import DynamicComponent from './DynamicComponent'
import useTheme from './useTheme';

const theme = {
    textStyles : {
        tiny : {
            fontSize : '10px',
            lineHeight: '14px'
        },
        small : {
            fontSize : '11px',
            lineHeight : '13px'
        },
        regular : {
            fontSize: ['12px', '14px'],
            lineHeight: ['20px', '22px']
        },
        large : {
            fontSize : '20px',
            lineHeight : '24px',
            fontWeight: 'bold'
        },
        headline : {
            fontSize : '18px',
            lineHeight : '16px',
            fontWeight: 'bold',
            textTransform : 'uppercase'
        },
        canon : {
            as : 'h1',
            fontSize: ['32px', '44px'],
            lineHeight: ['36px', '48px']
        },
        trafalgar : {
            fontSize: ['24px', '32px'],
            lineHeight: [28, 36]
        },
        doublePica : {
            fontSize: [20, 24],
            lineHeight: [24, 28]
        },
        greatPrimer : {
            fontSize: [18, 20],
            lineHeight: [22, 24]
        },
        bodyCopy : {
            fontSize: ['12px', '14px'],
            lineHeight: ['20px', '22px']
        },
        pica : {
            fontSize: '16px',
            lineHeight: '20px'
        },
        longPrimer : {
            fontSize: '14px',
            lineHeight: '18px'
        },
        brevier : {
            fontSize: ['11px', '12px'],
            lineHeight: ['16px', '16px']
        },
        minion : {
            fontSize: [10, 12],
            lineHeight: [14, 16],
            textTransform : 'uppercase'
        }
    }
}

const { tiny, small, regular, large, headline } = theme.textStyles;

export const LargeText = props => (
    

    <DynamicComponent {...large} {...props}>
        { props.children }
    </DynamicComponent>
)

export const RegularText = props => (
    <DynamicComponent {...regular} {...props}>
        { props.children }
    </DynamicComponent>
)

export const SmallText = props => (
    <DynamicComponent {...small} {...props}>
        { props.children }
    </DynamicComponent>
)

export const TinyText = props => (
    <DynamicComponent {...tiny} {...props}>
        { props.children }
    </DynamicComponent>
)


export const Headline = props => (
    <DynamicComponent {...headline} {...props}>
        { props.children }
    </DynamicComponent>
)

export const Text = ({ size, children, ...otherProps }) => {
    var theme = useTheme();

    const textStyle = useMemo(() => {
        switch (size) {
            case 'xs':
                return theme.textStyles.textxSmall;
            case 's':
                return theme.textStyles.textSmall
            case 'm':
                return theme.textStyles.textMedium;
            case 'l':
                return theme.textStyles.textLarge;
        }
    }, [size, theme]);

    return (
        <DynamicComponent {...textStyle } {...otherProps}>
            { children }
        </DynamicComponent>
    )
}




Text.defaultProps = {
    size : 'm'
}


export const OnelineText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`


export const TextSmall = props => {
    var theme = useTheme();

    return (
    <DynamicComponent {...theme.textStyles.textSmall } {...props}>
        { props.children }
    </DynamicComponent>
    )
}


export const TextMedium = props => {
    var theme = useTheme();

    return (
    <DynamicComponent {...theme.textStyles.textMedium } {...props}>
        { props.children }
    </DynamicComponent>
    )
}

export const TextLarge = props => {
    var theme = useTheme();

    return (
    <DynamicComponent {...theme.textStyles.textLarge } {...props}>
        { props.children }
    </DynamicComponent>
    )
}



/* BBC Gel */

export const Canon = props => (
    <DynamicComponent {...theme.textStyles.canon} {...props}>
        { props.children }
    </DynamicComponent>
)

export const Trafalgar = props => (
    <DynamicComponent {...theme.textStyles.trafalgar } {...props}>
        { props.children }
    </DynamicComponent>
)

export const DoublePica = props => (
    <DynamicComponent {...theme.textStyles.doublePica } {...props}>
        { props.children }
    </DynamicComponent>
)

export const GreatPrimer = props => (
    <DynamicComponent {...theme.textStyles.greatPrimer } {...props}>
        { props.children }
    </DynamicComponent>
)

export const BodyCopy = props => (
    <DynamicComponent {...theme.textStyles.bodyCopy } {...props}>
        { props.children }
    </DynamicComponent>
)

export const Pica = props => {
    var theme = useTheme();

    return (
    <DynamicComponent {...theme.textStyles.pica } {...props}>
        { props.children }
    </DynamicComponent>
    )
}

export const LongPrimer = props => (
    <DynamicComponent {...theme.textStyles.longPrimer } {...props}>
        { props.children }
    </DynamicComponent>
)

export const Brevier = props => (
    <DynamicComponent {...theme.textStyles.brevier } {...props}>
        { props.children }
    </DynamicComponent>
)

export const Minion = props => (
    <DynamicComponent {...theme.textStyles.minion } {...props}>
        { props.children }
    </DynamicComponent>
)



export const Meta = styled.span`
    color: var(--meta-text-color);
`



export const Excerpt = ({ text, maximumLength, ...otherProps }) => {
    return (
        <Pica {...otherProps}>
            { text.substring(0, maximumLength) }
            { text.length > maximumLength && '...' }
        </Pica>
    )
}