export const INVALIDATE_JOBS            = 'job/invalidate';

export const FETCH_JOBS_REQUEST         = 'jobs/fetch/request';
export const FETCH_JOBS_SUCCESS         = 'jobs/fetch/success';
export const FETCH_JOBS_FAILURE         = 'jobs/fetch/failure';

export const FETCH_JOB_REQUEST          = 'job/fetch/request';
export const FETCH_JOB_SUCCESS          = 'job/fetch/success';
export const FETCH_JOB_FAILURE          = 'job/fetch/failure';

export const UPDATE_JOB_REQUEST         = 'job/update/request';
export const UPDATE_JOB_SUCCESS         = 'job/update/success';
export const UPDATE_JOB_FAILURE         = 'job/update/failure';

export const INSERT_JOB_REQUEST         = 'job/insert/request';
export const INSERT_JOB_SUCCESS         = 'job/insert/success';
export const INSERT_JOB_FAILURE         = 'job/insert/failure';

export const DELETE_JOB_REQUEST         = 'job/delete/request';
export const DELETE_JOB_SUCCESS         = 'job/delete/success';
export const DELETE_JOB_FAILURE         = 'job/delete/failure';

export const INVALIDATE_JOB_HISTORY     = 'job-history/invalidate';

export const FETCH_JOB_HISTORY_REQUEST  = 'job-history/fetch/request';
export const FETCH_JOB_HISTORY_SUCCESS  = 'job-history/fetch/success';
export const FETCH_JOB_HISTORY_FAILURE  = 'job-history/fetch/failure';

export const INVALIDATE_JOB_SCHEDULE    = 'job-schedule/invalidate';

export const FETCH_JOB_SCHEDULE_REQUEST = 'job-schedule/fetch/request';
export const FETCH_JOB_SCHEDULE_SUCCESS = 'job-schedule/fetch/success';
export const FETCH_JOB_SCHEDULE_FAILURE = 'job-schedule/fetch/failure';


export const DELETE_JOB_SCHEDULE_REQUEST = 'job-schedule/delete/request';
export const DELETE_JOB_SCHEDULE_SUCCESS = 'job-schedule/delete/success';
export const DELETE_JOB_SCHEDULE_FAILURE = 'job-schedule/delete/failure';

export const UPDATE_JOB_SCHEDULE_REQUEST = 'job-schedule/update/request';
export const UPDATE_JOB_SCHEDULE_SUCCESS = 'job-schedule/update/success';
export const UPDATE_JOB_SCHEDULE_FAILURE = 'job-schedule/update/failure';

export const INSERT_JOB_SCHEDULE_REQUEST = 'job-schedule/insert/request';
export const INSERT_JOB_SCHEDULE_SUCCESS = 'job-schedule/insert/success';
export const INSERT_JOB_SCHEDULE_FAILURE = 'job-schedule/insert/failure';

export const FETCH_JOB_TYPES_REQUEST  = 'job-types/fetch/request';
export const FETCH_JOB_TYPES_SUCCESS  = 'job-types/fetch/success';
export const FETCH_JOB_TYPES_FAILURE  = 'job-types/fetch/failure';