import axios from 'axios'

import * as types from './types';

const fetchJsonRequest = (url) => ({ type: types.FETCH_JSON_REQUEST, url })
const fetchJsonSuccess = (url, payload) => ({ type: types.FETCH_JSON_SUCCESS, url, payload })
const fetchJsonFailure = (url) => ({ type: types.FETCH_JSON_FAILURE, url })

export const fetchJson = (url) => (dispatch) => {

    if (!url || url.length == 0)
        return;

    dispatch(fetchJsonRequest(url))


    const sep = url.indexOf('?') >= 0 ? '&' : '?'
    const bustedUrl = url + sep + 'r=' + Math.random();

    axios.get(bustedUrl).then(response => {
        dispatch(fetchJsonSuccess(url, response.data));
    }, () => {
        dispatch(fetchJsonFailure(url));
    });
}

const shouldFetchJson = (state, url) => {
    if (!state.json.byUrl[url]) {
        return true;
    }
    else if (state.json.byUrl[url].isFetching) {
        return false;
    }
    else {
        return state.json.byUrl[url].didInvalidate;
    }
}
  
export const fetchJsonIfNeeded = (url) => (dispatch, getState) => {
    if (shouldFetchJson(getState(), url)) {
        dispatch(fetchJson(url));
    }
}
