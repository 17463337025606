import * as types from './types'

const defaultState = {
    resources : {
    }
};



const permissionReducer = (state = defaultState, action) =>
{
    switch (action.type) {

        case types.FETCH_RESOURCE_PERMISSIONS_SUCCESS:
                return  { 
                    ...state,
                    resources : {
                        ...state.resources,
                        [action.resource] : action.payload
                    }
                };
            default:
            return state
    }
}

export default permissionReducer;
