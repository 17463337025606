import React from 'react'
import PropTypes from 'prop-types'

import GameContext from './GameContext'
import WithPlayerFantasyEvents from './WithPlayerFantasyEvents';
import WithPlayerEvents from './WithPlayerEvents';
import WithPlayerValues from './WithPlayerValues';



import { camelize } from 'utils';

class WithPlayerStatistics extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.shape = this.shape.bind(this);
    }


    shape(props) {
        const { values, fantasyEvents, game } = props;
        
        var eventsByRound = fantasyEvents.filter(e => typeof e.type !== 'undefined').reduce((agg, e) => {

            let r = agg[e.round + ''];
            if (!r) {
                r = {};
                agg[e.round + ''] = r;
            }
            
            const cName = camelize(e.type.name);
            r[cName] = typeof r[cName] === 'undefined' ? e.amount : r[cName] + e.amount;
            
            return agg;

        }, {});

        const items = values.map((values, index) => {
            //const rValues = index < values.length ? values[index] : null;
            const rEvents = eventsByRound[(index + 1) + ''];

            return {
                round : index + 1,
                values : values,
                events : rEvents || {}
            }
        })

        return items;
    }

    componentDidMount() {
        const items = this.shape(this.props);
        this.setState({ items });
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        const items = this.shape(nextProps);

        this.setState({ items })
    }

    render() {
        return this.props.children({ 
            items : this.state.items
        });
    }
}

WithPlayerStatistics.propTypes = {
    game : PropTypes.object.isRequired,
    values : PropTypes.array.isRequired,
    fantasyEvents : PropTypes.array.isRequired
}

WithPlayerStatistics.defaultProps = {
    values : [],
    fantasyEvents : []
}

const WithPlayerStatisticsWrapper = ({ playerId, children }) => (
    <GameContext.Consumer>{ ({ game }) => (
        <WithPlayerValues gameId={ game.id } playerId={ playerId }>{ ({values}) => (
            <WithPlayerFantasyEvents gameId={ game.id } playerId={ playerId }>{ ({fantasyEvents}) => (
                <WithPlayerStatistics game={ game } values={ values }  fantasyEvents={ fantasyEvents }>
                    { children }
                </WithPlayerStatistics>
            )}</WithPlayerFantasyEvents>
        )}</WithPlayerValues>
    )}</GameContext.Consumer>
)

WithPlayerStatisticsWrapper.propTypes = {
    playerId : PropTypes.number.isRequired
}

export default WithPlayerStatisticsWrapper;


