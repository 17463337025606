
import { createSelector } from 'reselect'

import { safeget } from 'utils'

export const makeGetMessageBoard = () => createSelector(
    (state, messageBoardId) => state.messageBoard.byId[messageBoardId],
    state => state.entities.messageBoards,
    (messageBoard, messageBoardEntities) => {
        return {
            ...messageBoard,
            item : messageBoard.item && messageBoardEntities[messageBoard.item]
        }
    }
)



export const makeGetHasPendingPosts = () => createSelector(
    (state, messageBoardId) => safeget(state.messageBoard, ['byMessageBoard', messageBoardId, 'pendingPosts']),
    (pendingPosts) => {
        return pendingPosts && pendingPosts.length > 0;
    }
)


export const makeGetNumberOfPendingReplies = () => createSelector(
    (state, messageBoardId, postId) => safeget(state.messageBoard, ['byMessageBoard', messageBoardId, 'pendingRepliesByPost', postId]),
    (pendingReplies) => {
        return (pendingReplies && pendingReplies.length) || 0
    }
)



export const makeGetPosts = () => createSelector(
    (state, messageBoardId) => {
        const { posts } = state.messageBoard.byMessageBoard[messageBoardId] ||{ posts : null }
        return posts;
    },
    (state, messageBoardId) => safeget(state.messageBoard, ['byMessageBoard', messageBoardId, 'replyCountByPost']),
    state => state.entities.messageBoardPosts,
    state => state.entities.users,
    (posts, replyCountByPost, messageBoardPostEntities, userEntities) => {
        var state = posts || { isFetching : false, didInvalidate : false, items : [], links : {} }

        const items =  (state.items || []).map(id => {
            const post = messageBoardPostEntities[id];
            const user = userEntities[post.user];
            const replyCount = replyCountByPost[id] || 0;

            return {
                ...post,
                user,
                clientReplyCount : replyCount
            }
        });

        return {
            ...state,
            items
        }
    }
)




export const makeGetReplies = () => createSelector(
    (state, messageBoardId, postId) => {
        const { repliesByPost } =  state.messageBoard.byMessageBoard[messageBoardId] || { repliesByPost : null };
        return repliesByPost[postId];
    },
    state => state.entities.messageBoardPosts,
    state => state.entities.users,
    (repliesByPost, messageBoardPostEntities, userEntities) => {
        var replies = repliesByPost || { isFetching : false, didInvalidate : false, items : []}

        const items =  (replies.items || []).map(id => {
            const post = messageBoardPostEntities[id];
            const user = userEntities[post.user];

            return {
                ...post,
                user
            }
        });

        return {
            ...repliesByPost,
            items
        }
    }
)



export const makeGetUnreadMessageBoardPosts = () => createSelector(
    (state, messageBoardId) => state.messageBoard.unreadByMessageBoard[messageBoardId] || 0,
    unread => unread
)