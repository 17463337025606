import React from 'react';
import { Switch, Route } from "react-router-dom";


import RoutedDesktopSiteHeader from './RoutedDesktopSiteHeader'
import RoutedMobileSiteHeader from './RoutedMobileSiteHeader'
import RoutedDesktopGameHeader from './RoutedDesktopGameHeader'
import RoutedMobileGameHeader from './RoutedMobileGameHeader'
import AccountHeader from './AccountHeader'

import { Media, ErrorBoundary } from '../../../components'
import FlyoutProxy from '../../FlyoutProxy';


const RoutedHeader = ({ themeName, onShowMenu }) =>  {
    
    
    return (
        <ErrorBoundary>
            <Media query="(min-width: 1024px)">
                <Switch>
                    <Route path="/:language/games/:gameSlug/:gameId/:view?" component={ () => <RoutedDesktopGameHeader onShowMenu={ onShowMenu } /> } />
                    <Route path="/:language/account/:view?" component={ () => <AccountHeader onShowMenu={ onShowMenu } /> } />
                    <Route component={ () => <RoutedDesktopSiteHeader onShowMenu={ onShowMenu } /> } />
                </Switch>
            </Media>

            <Media query="(max-width: 1023px)">
                <Switch>
                    <Route path="/:language/games/:gameSlug/:gameId/:view?" component={ () => <RoutedMobileGameHeader themeName={ themeName } onShowMenu={ onShowMenu } /> } />
                    <Route component={ () => <RoutedMobileSiteHeader themeName={ themeName } onShowMenu={ onShowMenu } /> } />
                </Switch>
            </Media>

            <FlyoutProxy></FlyoutProxy>
        </ErrorBoundary>
    )
};



export default RoutedHeader;