import React from 'react';
import { useDispatch } from 'react-redux'

import { XButton, LinkButton } from 'components'

import { start } from '../redux/auth/actions'
import { useAppConfig } from './AppConfigProvider'

import useAuthenticator from './useAuthenticator';


const AuthenticationButton = ({ children, ...otherProps }) => {
    const [tryAuthenticate] = useAuthenticator();

    return <XButton onClick={ () => tryAuthenticate() } {...otherProps } textTransform="uppercase">{ children }</XButton>

}

export default AuthenticationButton;