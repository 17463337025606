import React from 'react';

import { CheckBoxButton } from 'components'

import FormContext from '../../FormContext'

export const CheckboxButtonWidget = ({ property, label }) => {
    return (
        <FormContext.Consumer>{ ({ data, setElement }) => {
            const value = data[property];

            return (
                <CheckBoxButton   checked={ value }
                            onChange={ (value) => setElement(property, value, { label : label }) }>
                            { label }
                </CheckBoxButton>
            )
        }}</FormContext.Consumer>
    )
}

export default CheckboxButtonWidget;