import React from 'react'
import PropTypes from 'prop-types'


import Icon from './Icon'

import * as styles from './Odds.scss'



const OutcomeWrapper = ({ className, url, children }) => {
    if (url) {
        return <a className={ className } href={ url } target="_blank" onClick={ (e) => e.stopPropagation() }>{ children }</a>
    }
    else {
        return <span className={ className } >{ children }</span>
    }

}

const Outcome = ({ type, odds, url, icon }) => {
    return <OutcomeWrapper className={ styles.outcome } url={ url }>
                <span className={ styles.type }>{ type }:</span>
                { odds.toFixed(2) }
                { icon && <span className={ styles.hot }><Icon type={ icon } size="1-2x" /></span> }
            </OutcomeWrapper>
}

Outcome.propTypes = {
    type : PropTypes.string.isRequired,
    odds : PropTypes.number.isRequired
}


class Odds extends React.PureComponent {
    static Outcome = Outcome
    

    render() {
        const children = (this.props.children || []).filter(c => c && c.type === Outcome);

        return (
            <span className={ styles.odds } title={ this.props.title }>
                { 
                    React.Children.map(children, (child) => {
                        const icon = child.props.odds <= this.props.superhotThreshold ?
                            'flameDouble' : child.props.odds <= this.props.hotThreshold ? 'flameSingle' : null

                        return React.cloneElement(child, { icon });
                    })
                }
            </span>
        )
    }
}

Odds.propTypes = {
    title : PropTypes.string,
    hotThreshold : PropTypes.number.isRequired,
    superhotThreshold : PropTypes.number.isRequired
}

Odds.defaultProps = {
    hotThreshold : 1.5,
    superhotThreshold : 1.2,
}

export default Odds