import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledNotificationWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledNotificationIcon = styled.div`
    width : 15px;
    height : 15px;
    line-height : 15px;
    
    font-size: 10px;
    
    border-radius: 4px;
        
    overflow: hidden;
    font-weight: bold;
    text-align: center;

    letter-spacing: 0;
    
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    pointer-events: none;

    background-color: ${ props => props.theme.colors.utilityYellow };
    color:  ${ props => props.theme.colors.utilityBlack };
`;


const NotificationIcon = ({ count, top, right, ...props }) => {
    return (
        <StyledNotificationWrapper {...props}>
            <StyledNotificationIcon boxShadow={ 1 }  top={ top } right={ right }>
                { count }
            </StyledNotificationIcon>
        </StyledNotificationWrapper>
    )
}

NotificationIcon.propTypes = {
    count : PropTypes.number
}

NotificationIcon.defaultProps = {
}

export default NotificationIcon;