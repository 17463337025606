import React, { useContext } from 'react';
import PropTypes from 'prop-types'

import { FactList, FactListItem, ErrorBoundary } from 'components'
import LocalizeText from '../../LocalizeText'
import { DataContext, Number } from '../../../components';
import { useTranslator } from '../../../hooks/useTranslator';

export const FactListItemWidget = ({ }) => {
    return null;
}

const HackEarnings = () => {
    const context = useContext(DataContext);
    const t = useTranslator();
    
    const earnings = context?.current?.player?.earnings;

    if (!earnings)
        return null;

    return (
        <FactListItem size="l" label={ t('common.earnings') }>
            <Number value={ earnings } />
        </FactListItem>
    )
}

export const FactListWidget = ({ large, shadow, children }) => (
    
    <ErrorBoundary>
        <FactList size={ large ? 'l' : 'm' } shadow={ shadow }>
            {
                React.Children.map(children, (child, index) => {
                    let valueToRender = null;

                    if (typeof child.props.children === 'string') {
                        valueToRender = child.props.children
                    } else {
                        valueToRender = child.props.children;
                    }

                    return (
                        <FactListItem key={ index } label={ child.props.label }>
                            { valueToRender}
                        </FactListItem>
                    )
                })
            }

            { large && <HackEarnings /> }


        </FactList>
    </ErrorBoundary>

)

FactListWidget.propTypes = {
}

FactListWidget.defaultProps = {
}