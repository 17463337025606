import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './AccordionVersusHeader.scss'

export const AccordionVersusHeader = ({ team, score, growth }) => {

    return (
        <div className={ styles.container }>
            <div className={ styles.left}>
                <div className={ styles.team }>{ team }</div>
                <div className={ styles.score }>{ score }</div>
            </div>
            <div className={ styles.growth }>{ growth }</div>
        </div>
    )
}


AccordionVersusHeader.propTypes = {
    team : PropTypes.node,
    score : PropTypes.node,
    growth : PropTypes.node
}

AccordionVersusHeader.defaultProps = {
    
}

