// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lNYXTk95fFcItffESUmq{color:#ff4040}.WjRMEbbP2tc_jENOh0b1{color:#8a8a8a}`, "",{"version":3,"sources":["webpack://./src/components/Typography.scss"],"names":[],"mappings":"AAAA,sBAAA,aAAA,CACA,sBAAA,aAAA","sourcesContent":[".negative { color: #ff4040; }\r\n.meta { color: #8a8a8a; }"],"sourceRoot":""}]);
// Exports
export var negative = `lNYXTk95fFcItffESUmq`;
export var meta = `WjRMEbbP2tc_jENOh0b1`;
export default ___CSS_LOADER_EXPORT___;
