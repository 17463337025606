import * as types from './types'


const HIGHSCORE_TYPES = [
    { value : 'overall', i18nText : 'common.overall' },
    { value : 'round', i18nText : 'common.round' }
];

const HIGHSCORE_SCOPES = [
    { value : 'everyone', i18nText : 'common.everyone', requiresAuthentication : false },
    { value : 'friends', i18nText : 'common.myselfAndFriends', requiresAuthentication : true }
];


const defaultState = {
    isFetching: false,
    didInvalidate: false,
    rows: [],
    
    round: 1,
    gameId: 0,
    page : 1,
    type : 'overall',
    scope : 'everyone',

    types : HIGHSCORE_TYPES,
    scopes : HIGHSCORE_SCOPES,
    
    count: 0
}


export default function highscore (state = defaultState, action) 
{
    switch (action.type) {
        case types.SELECT_HIGHSCORE:
            return {
                ...state,
                ...action.parameters,
                didInvalidate: true
            };
        case types.REQUEST_HIGHSCORE:
            return {
                ...state,
                //rows : [],
                //count : 0,
                isFetching: true,
                didInvalidate: false
            };  
        case types.RECEIVE_HIGHSCORE:
        
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                rows: action.payload.result,
                count : action.count
            }
        default:
            return state
    }
}