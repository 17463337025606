import React from 'react'

import { ErrorBoundary, Media } from '../../components'

import SimpleSchedule from '../SimpleSchedule'
import MotorsportScheduleCardView from './MotorsportScheduleCardView';
import MotorsportScheduleTableView from './MotorsportScheduleTableView'
import WithFlyout from '../WithFlyout'

import GameNavigationService from '../GameNavigationService'

class MotorsportSchedule extends React.PureComponent
{
    render() {
        const { onSelectMatch } = this.props;

        return (
            <ErrorBoundary>
                <GameNavigationService>{ ({ showMatch }) => ( 
                        <SimpleSchedule>
                            { 
                                ({ schedule }) => (
                                    <div>
                                        <Media query="(min-width: 1024px)">
                                            <MotorsportScheduleTableView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   } />
                                        </Media>

                                        <Media query="(max-width: 1023px)">
                                            <MotorsportScheduleCardView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   } />
                                        </Media>
                                    </div>
                                )
                            }
                        </SimpleSchedule>
                    )}
                </GameNavigationService>
            </ErrorBoundary>
        )
    }
}

export default MotorsportSchedule;