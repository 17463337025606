import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const DocumentTitle = ({ title, children }) => {
    const [savedTitle, setSavedTitle] = useState(document.title);

    useEffect(() => {
        document.title = title;

        return () => {
            document.title = savedTitle;
        }
        
    }, [title])

    return children || null;

}

DocumentTitle.propTypes = {
    title : PropTypes.string.isRequired
}



export default DocumentTitle;