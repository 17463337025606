import React from 'react'

const GridItem = ({ style, children }) => {
   
        return (
            <div style={ style }>
                { children }
            </div>
        )
}

GridItem.propTypes = {

}

GridItem.defaultProps = {
};


class Grid extends React.PureComponent {
    static Item = GridItem;

    render() {
        
        const style = {
            display : 'grid',
            width : '100%',
            ...this.props.style
        }


        return (
            <div style={ style }>
                { this.props.children }
            </div>
        )
    }  
}

Grid.propTypes = {
    
}

Grid.defaultProps = {
    
};

export default Grid;