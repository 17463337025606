import React from 'react';

import PlayerContext from '../../PlayerContext'
import { number } from 'utils'


const TotalGrowth = ({ }) => (
    <PlayerContext.Consumer>{ ({ values }) => {
        if (!values || values.length == 0)
            return "-"
    
        return number(values[values.length - 1].totalGrowth);
    }}</PlayerContext.Consumer>
)

TotalGrowth.defaultProps = {
}

export default TotalGrowth;