import * as types from './types'

const defaultState = {
    byId : {},
   
};

const themeReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.FETCH_THEME_REQUEST:
            return  { 
                ...state,
                byId : {
                    [action.id] : {
                        ...state.byId[action.id],
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_THEME_SUCCESS:
                return  { 
                    ...state,
                    byId : {
                        [action.id] : {
                            ...state.byId[action.id],
                            isFetching : false,
                            didInvalidate : false,
                            item : action.payload.result
                        }
                    }
                };
        case types.FETCH_THEME_FAIL:
            return  { 
                ...state,
                byId : {
                    [action.id] : {
                        ...state.byId[action.id],
                        isFetching :  false,
                        didInvalidate : false
                    }
                }
            };                
         
        default:
            return state
    }
}

export default themeReducer;
