import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, FormField, OnetimePasswordInput, Label, Password, Button, HelpLabel, AuthenticationHeader } from 'components'
import { BusyButton } from '../../molecules'
import { requestReset, goto } from '../../redux/auth/actions'
import { useTranslator } from '../../hooks/useTranslator'

export const ResetConfirm = () => {
    const dispatch = useDispatch()
    const translator = useTranslator();
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.reset)

    const submittable = code && code.length > 0 && password && password.length > 0;

    const onSubmitInternal = (e) => {
        e.preventDefault();
        if (submittable) {
            dispatch(requestReset(email));
        }
    }

    const onRetry = ()=> {
        dispatch(goto('reset'));
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>Nulstil adgangskode</AuthenticationHeader>

            <p>Vi har sendt dig en kode du skal oplyse herunder</p>

            <Form problems={ problems } onSubmit={ e => onSubmitInternal(e) }>

                <FormField name="code">
                    <HelpLabel text={ translator('authentication.codeLabel' )}>
                        <Button tiny transparent onClick={ onRetry }>Send igen</Button>
                    </HelpLabel>
                    <OnetimePasswordInput value={ code } onChange={ (e) => setCode(e.target.value)} />
                </FormField>

                <FormField name="code">
                    <Label>Nyt kodeord</Label>
                    <Password value={ password } onChange={ (e) => setPassword(e.target.value)} />
                </FormField>

                <BusyButton type="submit" disabled={ !submittable } busy={ isBusy } label={ translator('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ translator('authentication.continueButtonCaption' )}</BusyButton>
            </Form>
        </React.Fragment>
    )
}



