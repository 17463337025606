import React from 'react'

import styled, { css } from 'styled-components'

import { space } from 'styled-system'

const StyledWrapper = styled.div`
    padding: ${ props => props.theme.space.s200 };
    border-radius: ${ props => props.theme.radii.r400 };
    transition: background-color 0.2s linear;
    will-change: background-color;
    background-color : ${ props => props.selected ? props => props.theme.colors.borderDown : 'transparent' };
    text-transform: uppercase;
    letter-spacing: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
`

export const StyledToolbarButton = styled.div`
    cursor: pointer;

    height: ${ props => props.theme.sizes.s400 };
    display: flex;
    align-items: center;

    font-size: 12px;    

    white-space: nowrap;
    
    
    ${ props => props.themeName && css`
        
        color: ${ props => props.theme.colors.themes[props.themeName].primaryForeground };
    `}

    &:hover ${ StyledWrapper } {
        background-color : ${ props => props.theme.colors.borderUp };    
    }

    ${ space }
`

const StyledToolbarButtonGroup = styled.div`
    display: flex;

    ${StyledToolbarButton} {
        margin-right: 6px;
    }


    ${StyledToolbarButton}:last-child {
        padding-right: 6px;
        margin-right: 0;
    }
`

export const ToolbarButton = ({ selected, onClick, children, ...props }) => {
    return (
        <StyledToolbarButton onClick={ onClick } { ...props }>
            <StyledWrapper selected={ selected }>
                { children }
            </StyledWrapper>
        </StyledToolbarButton>
    )
}

export const ToolbarButtonGroup = ({ children }) => {
    return (
        <StyledToolbarButtonGroup>
            { children }
        </StyledToolbarButtonGroup>
    )
}
