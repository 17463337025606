import { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import { fetchThemeIfNeeded } from '../redux/theme/actions'
import { makeGetTheme } from '../redux/theme/selectors'

export const useTheme = (id) => {

    const dispatch = useDispatch();
    
    const getTheme = useMemo(() => makeGetTheme(), [id])

    useEffect(() => {
        dispatch(fetchThemeIfNeeded(id));
    }, [id]);

    return useSelector(state => getTheme(state, id));


}


