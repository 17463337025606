import * as types from './types'

export default function user (
        state = {
            byId : {},
            me : 0
        },
        action
    ) {
    
    switch (action.type) {
        case types.CURRENT_USER_RECEIVE:
            return {
                byId : {
                    ...state,
                    [action.user.id] : action.user
                },
                me : action.user.id
            }
        case types.FETCH_USER_SUCCESS:
            return {
                byId : {
                    ...state,
                    [action.payload.id] : action.payload
                }
            }
        default:
            return state
    }
}