// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wGMYtaZzxwu2ufJ6hNLQ {
	border: 1px dashed;
	background-color: lightgrey;
	justify-content: center;

	display: grid;
	place-items: center;

	margin : 1rem;

  }
  
  .wGMYtaZzxwu2ufJ6hNLQ:empty::before {
	background-color: dimgrey;
	border-radius: 5px;
	color: lightgrey;
	content: 'Ad';
	font: 12px sans-serif;
	padding: 3px;
	text-align: center;
	width: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/containers/google-publisher-tag.module.css"],"names":[],"mappings":";AACA;CACC,kBAAkB;CAClB,2BAA2B;CAC3B,uBAAuB;;CAEvB,aAAa;CACb,mBAAmB;;CAEnB,aAAa;;EAEZ;;EAEA;CACD,yBAAyB;CACzB,kBAAkB;CAClB,gBAAgB;CAChB,aAAa;CACb,qBAAqB;CACrB,YAAY;CACZ,kBAAkB;CAClB,WAAW;EACV","sourcesContent":["\r\n.adSlot {\r\n\tborder: 1px dashed;\r\n\tbackground-color: lightgrey;\r\n\tjustify-content: center;\r\n\r\n\tdisplay: grid;\r\n\tplace-items: center;\r\n\r\n\tmargin : 1rem;\r\n\r\n  }\r\n  \r\n  .adSlot:empty::before {\r\n\tbackground-color: dimgrey;\r\n\tborder-radius: 5px;\r\n\tcolor: lightgrey;\r\n\tcontent: 'Ad';\r\n\tfont: 12px sans-serif;\r\n\tpadding: 3px;\r\n\ttext-align: center;\r\n\twidth: 20px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export var adSlot = `wGMYtaZzxwu2ufJ6hNLQ`;
export default ___CSS_LOADER_EXPORT___;
