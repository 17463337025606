import React from 'react'

import { TopBar, Icon, Box, ContextMenuContainer, ToolbarButton } from '../../components'
import ConnectedNotificationBadge from '../ConnectedNotificationBadge'

import styled  from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;
    -webkit-transform: translateZ(0);

`


class MobileSiteHeader extends React.PureComponent
{
    constructor(props) {
        super(props);
    }

    render() {
        const { children, onShowMenu } = this.props;

        return (
            <Container>
                <TopBar themeName={ this.props.themeName } display="flex">
                    <Box>
                        <ConnectedNotificationBadge>
                            <ToolbarButton onClick={ onShowMenu } >
                                <Icon type="burgermenu" />
                            </ToolbarButton>
                        </ConnectedNotificationBadge>
                    </Box>
                    <Box flex="1">
                        { children }
                    </Box>
                    <Box>
                        <ContextMenuContainer></ContextMenuContainer>
                    </Box>
                </TopBar>
            </Container>
        )
    }
}

export default MobileSiteHeader;
