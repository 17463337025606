import React from 'react';
import { Table, Number, Panel } from 'components'

export const PointsystemItem = () => null;

export const Pointsystem = ({ heading, children }) => {
    return (
        <Panel>
            <h3>{ heading }</h3>
            <Table compact>
                <Table.Body>
                    {
                        React.Children.map(children, (child, index) => {

                            return (
                                <Table.Row key={ index }>
                                    <Table.Cell>
                                        { child.props.title }
                                        { child.props.footnote && <React.Fragment> <sup>{ child.props.footnote }</sup></React.Fragment> }
                                    </Table.Cell>
                                    <Table.Cell right>
                                        <Number value={ child.props.value } />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        </Panel>
    )

}

