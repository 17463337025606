import React from 'react';
import ReactMarkdown from 'react-markdown'



const Prize = ({ reason, prize, description, imageUrl, link, legalese, children, align = 'left', size = 'default'}) => {




    return (
        <div style={{ textAlign : align }}>

            {
                size == 'large' &&
                    <h1>
                        { reason && <span className="text-muted">{ reason }: </span> }
                        { prize }
                    </h1> ||
                    <h3>
                        { reason && <React.Fragment><span className="text-muted">{ reason }</span><br /></React.Fragment> }
                        { prize }
                    </h3>
            }
            

            <div>
                {
                    link && 
                    <a href={ link }>
                        <img style={{ maxWidth: '100%'}} src={ imageUrl } />
                    </a> ||
                    <img style={{ maxWidth: '100%'}} src={ imageUrl } />
                }
            </div>

            <ReactMarkdown source={ description } />

            { legalese && <small className="text-muted">{ legalese }</small> }
        </div>
    )

}



export default Prize;