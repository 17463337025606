import React from 'react'

import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import Spinner from './Spinner'

const FlatList = React.forwardRef(({  totalCount, isFetching, items, itemSize, children, onLoadMore }, ref) => {
    const isItemLoaded = (index) => index < items.length;

    return (
        <AutoSizer>{ ({ width, height }) => (
            <React.Fragment>
               
                <InfiniteLoader ref={ ref } isItemLoaded={ isItemLoaded } itemCount={ totalCount } loadMoreItems={ onLoadMore }>{ ({ onItemsRendered, ref }) => {
                    return (
                        <List onItemsRendered={ onItemsRendered } height={ height } itemCount={ items.length } ref={ ref } itemSize={ itemSize } width={ width }>
                            { children }
                        </List>
                    )
                }}</InfiniteLoader>

                {
                    isFetching && 
                    <div style={{ position: 'absolute', left: 0, right: 0, top: 0, height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Spinner/>
                    </div>
                }

            </React.Fragment>
        )}</AutoSizer>
        

    )
})

FlatList.defaultProps = {
    itemSize : 48
}

export default FlatList