import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Search } from '../components/Forms';

import KickDialog from './KickDialog'



import useEnrollments from './useEnrollments'
import auth from '../common/auth'
import { FlatList, ListViewItem, FantasyTeamIdentity, XButton, Box } from 'components'
import useCurrentGame from '../containers/useCurrentGame'

import { useTranslator } from '../hooks/useTranslator'

import { fetchEnrollmentsIfNeeded } from '../redux/competition/actions'

const initialKickDialogState = {
    open : false, 
    fantasyTeam : null
}

const Enrollments = ({ competition, canKick, ...otherProps }) => {
    const [kickDialog, setKickDialog] = useState(initialKickDialogState);
    const [query, setQuery] = useState('');
    const [enrollments, fetchMore] = useEnrollments(competition.id, query);
    const userId = auth.getUserId();
    const game = useCurrentGame();
    const t = useTranslator();
    const dispatch = useDispatch();

    const handleKick = (fantasyTeam) => {
        if (competition.type == 'cup') {
            const firstRound = game.rounds[competition.firstRound - 1];
            if (new Date() > new Date(firstRound.close)) {
                alert(t('competitions.cannot_kick_when_cup_started_error'));
                return;
            }
        }

        setKickDialog({ open : true, fantasyTeam : fantasyTeam  });
    }

    const onKickComplete = (success) => {
        if (success) {
            dispatch(fetchEnrollmentsIfNeeded(competition.id, 1, 25));
        }

        setKickDialog(initialKickDialogState);
    }

    const Item = ({ index, style}) => {
        const item = (enrollments.item && enrollments.item.items || [])[index];

        if (!item) {
            return (<ListViewItem style={ style }></ListViewItem>)
        }
        else {
            return (
                <ListViewItem display="flex" alignItems="center" justifyContent="space-between" px="s" style={ style }>
                    <FantasyTeamIdentity fantasyTeam={ item.fantasyTeam } />

                    { canKick && item.fantasyTeam.user.id !== userId && <XButton variant="outline" size="xs" textTransform="uppercase" onClick={ () => handleKick(item.fantasyTeam) }>{ t('competitions.resign_caption')}</XButton> }
                </ListViewItem>
            )
        }
    }

    return (
        <React.Fragment>
            <Box flex={ 1 } display="flex" flexDirection="column" { ...otherProps }>
                <Box mb="m">
                    <Search value={ query } onSearch={ value => setQuery(value) } buttonCaption={ t('common.search_caption') } />
                </Box>
                <Box flex={ 1 } minHeight="320px">
                    {
                        !enrollments.isFetching && enrollments.item && enrollments.item.items.length == 0 && 
                        (
                            <Box textAlign="center" py="m">
                                <em>{ t('competitions.no_matching_teams_found') }</em>
                            </Box>
                        )
                    }

                    <FlatList key={ query } totalCount={ enrollments.item && enrollments.item.count || 0 } items={ enrollments.item && enrollments.item.items || [] } onLoadMore={ fetchMore }>{ Item }</FlatList>
                </Box>
            </Box>

            <KickDialog competition={ competition } open={ kickDialog.open } onClose={ onKickComplete } fantasyTeam={ kickDialog.fantasyTeam } />
        </React.Fragment>
    )
}

export default Enrollments;