import React from 'react';
import PropTypes from 'prop-types'

import { Overlay } from 'components'



const OverlayWidget = ({ children, ...otherProps }) => {
    return <Overlay {...otherProps}>
                { children }
           </Overlay>

}



export default OverlayWidget