import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Underlay.scss'

const Underlay = ({ underlay, children }) => {

    return (
        <div className={ styles.container }>
            <div className={ styles.underlay }>
                { underlay }
            </div>
            <div className={ styles.content }>
                { children }
            </div>
        </div>
    )
}


Underlay.propTypes = {
}

Underlay.defaultProps = {
}

export default Underlay;