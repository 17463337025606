import React from 'react';
import PropTypes from 'prop-types'

import PlayerContext from '../../PlayerContext'
import WithPlayer from '../../WithPlayer'

import PersonInjuryStatus from '../../PersonInjuryStatus'
import { AlertBox } from '../../../components';

const InjuryStatusWidget = ({ editMode, children, ...otherProps }) => {
    if (editMode) {
        return <AlertBox type="warning">(Injury Status)</AlertBox>
    }

    return <PlayerContext.Consumer>{ ({ id }) => (
                <WithPlayer id={ id }>{ (player) => (
                    <PersonInjuryStatus personId={ player.person.id } />
                )}</WithPlayer>
            )}</PlayerContext.Consumer>

}

export default InjuryStatusWidget;