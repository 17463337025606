import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { HorizontalMenu, MenuItem } from './Menu'

import styled, { css } from 'styled-components'

/* Styled Components */

const StyledMenu = styled.div`
    font-size: 14px;

    height: var(--vertical-rythm);

    line-height: calc(var(--vertical-rythm) / 3);

    z-index: 1000;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color : ${ props => props.theme.colors.themes[props.themeName].primary };
    color : ${ props => props.theme.colors.themes[props.themeName].primaryForeground };

    ${ props => props.sticky && css`
		position: sticky;
		top: ${ props => props.stickyOffset ? props.stickyOffset + 'px' : '0' };
    `}
`

const StyledContent = styled.div`
    flex: 1;
	flex-direction: column;
    min-height: 320px;
    position: relative;
    display: flex;
`


const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
    flex: 1;
    
    ${
        props => props.fill && css`
        	position: absolute;
	        top: 0; left: 0; bottom: 0; right: 0;
        `
    }
`

/**/


export const TabBarItem = ({ title, count, children }) => {
    return children;
}

TabBarItem.propTypes = {
    title : PropTypes.any.isRequired
}


export const TabBar = React.memo(({ initialTabIndex,  fill, transparent, identity, renderMenu, sticky, stickyOffset, children, themeName  }) => {
    const [tabIndex, setTabIndex] = useState(initialTabIndex || 0 )

    const select = (index) => {
        setTabIndex(index);
    }

    const cArr = React.Children.toArray(children);
    const item = cArr[tabIndex];

    const renderMenuFunc = renderMenu ? renderMenu : (component) => component;


    return (
        <StyledContainer fill={ fill }>

            {
                renderMenuFunc(
                    <StyledMenu identity={ identity } transparent={ transparent }  themeName={ themeName }>
                            <HorizontalMenu themeName={ themeName }>
                                { 
                                    cArr.map((c, index) => 
                                        <MenuItem key={ index } active={ tabIndex == index } onClick={ () => select(index) } active={ tabIndex === index }>
                                            { c.props.title }
                                        </MenuItem>
                                    ) 
                                }
                            </HorizontalMenu> 
                    </StyledMenu>
                )

            }

            <StyledContent>{ item }</StyledContent>
        </StyledContainer>
    )
})

TabBar.defaultProps = {
    themeName : 'default'
}


TabBar.propTypes = {
    fill : PropTypes.bool,
    activeIndex : PropTypes.number,
    transparent : PropTypes.bool,
    identity : PropTypes.bool,
    children: function (props, propName, componentName) {
        const prop = props[propName]
    
        let error = null
        React.Children.forEach(prop, function (child) {
          if (child.type !== TabBarItem) {
            error = new Error('`' + componentName + '` children should be of type `TabBarItem`.');
          }
        })
        return error
    }
}




