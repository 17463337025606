import React, { useContext } from 'react';
import { exp } from 'utils';

import { Connect, DataContext } from 'components';

const ConnectWidget = ({ props, children }) => {
    return (
        <Connect {...props}>{ children }</Connect>
    )
}
    
ConnectWidget.defaultProps = {
    props : {}
}

export default ConnectWidget;