import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types'

import { exp } from 'utils';
import { DataContext, ErrorBoundary } from 'components';

const Script = ({ context, src, onInit, onLoad }) => {
    const ref = useRef();
    const ctx = useContext(DataContext);
    

    const scriptContext = exp(context, ctx);


    
    useEffect(() => {
        let script = document.querySelector('script[src="' + src + '"]');
        
        const onInitFunc = onInit ? new Function(['el', 'context'], onInit) : () => {};
        onInitFunc(ref.current);
        
        const onLoadFunc = onLoad ? new Function(['el', 'context'], onLoad) : () => {};

        if (script) {
            // Script is already present
            onLoadFunc(ref.current, scriptContext);
        }
        else {
            const script = document.createElement("script");
            
            script.onload = () => {
                onLoadFunc(ref.current, scriptContext);
            }

            script.src = src;
            script.async = true;

            document.body.appendChild(script);
        }
    }, []);

    
    return <ErrorBoundary><div ref={ ref }></div></ErrorBoundary>;

}

export default Script;