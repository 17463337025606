import React from 'react'
import PropTypes from 'prop-types'

import ScrollTable from './ScrollTable';
import Table from './Table';
import DataContextProvider from './DataContextProvider';

const getAlignmentProperties = (alignment) => {
    switch(alignment) {
        case 'left' : 
            return { left : true };
        case 'right' : 
            return { right : true };
        case 'center' : 
            return { center : true };
        default:
            return null;
    }
}

const DataGridTable = ({ items, disabledProperty, activeColumn, columns, onSort }) => {
    return (
        <Table>
            <Table.Head>
                <Table.Row>
                    { 
                        columns.map((column, index) => {
                            const alignmentProperties = getAlignmentProperties(column.props.alignment);

                            const element = column.props.sort ?
                                <a onClick={ () => onSort(column) }>{ column.props.heading }</a> :
                                <span>{ column.props.heading }</span>
                        
                            return (
                                <Table.Header key={ index } {...alignmentProperties}>{ element }
                                
                                </Table.Header>
                            )
                        }) 
                    }
                </Table.Row>
            </Table.Head>
            <Table.Body>
                { 
                    items.map((item, index) => (
                        <Table.Row key={ index } disabled={ item[disabledProperty] } >
                            <DataContextProvider value={ item }>
                                { 
                                    
                                    

                                    columns.map((column, index) => { 
                                        const alignmentProperties = getAlignmentProperties(column.props.alignment);
                                        const child = React.Children.count(column.props.children) === 0 ?
                                            null :
                                            React.Children.only(column.props.children);
                                        
                                        const isActive = column === activeColumn;

                                        return (
                                            <Table.Cell key={ index } {...alignmentProperties} highlight={ isActive }>
                                                { child && React.cloneElement(child) }
                                            </Table.Cell>
                                        )
                                    })
                                }
                            </DataContextProvider>
                        </Table.Row>

                    )) 
                }

            </Table.Body>
        </Table>
    )
}


export class DataGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            items : props.items
        }
    }


    render() {
        const { items, disabledProperty, children } = this.props;
        

        const cArr = React.Children.toArray(children);

        const activeColumn = this.props.activeColumnIndex > 0 && cArr[this.props.activeColumnIndex];
        const fixedChildren = cArr.filter(c => c.props.fixed);
        const otherChildren = cArr.filter(c => !c.props.fixed);
        
        return (
            <ScrollTable>
                <ScrollTable.Left>
                    <DataGridTable items={ items } disabledProperty={ disabledProperty } activeColumn={ activeColumn } columns={ fixedChildren } onSort={ (column) => this.props.onSort(column.props.sort, cArr.indexOf(column)) } />
                </ScrollTable.Left>
                <ScrollTable.Right>
                    <DataGridTable items={ items } disabledProperty={ disabledProperty } activeColumn={ activeColumn }  columns={ otherChildren } onSort={ (column) => this.props.onSort(column.props.sort, cArr.indexOf(column)) }  />
                </ScrollTable.Right>
            </ScrollTable>
        )
    }
}


DataGrid.propTypes = {
    items: PropTypes.array.isRequired,
    disabledProperty : PropTypes.string.isRequired
}

DataGrid.defaultProps = {
    disabledProperty : 'disabled'
}


export const DataGridColumn = () => {

}

