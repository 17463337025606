
import React from 'react';

import WithMatchEvents from '../WithMatchEvents'

import { MatchHead,  Progress, EventDate, PersonName, SoccerClock } from 'components';
import { lo } from 'utils';

import SoccerPeriodStatus from './SoccerPeriodStatus'
import MatchContext from '../MatchContext'

import configuration from './configuration';

const EventList = ({ names, events, team }) => {

    const sigEvents = Object.values(events.filter(e => 
            names.indexOf(e.type.name) >= 0 && 
            (
                (e.player.team === team && !e.type.disadvantage) || 
                (e.player.team !== team && e.type.disadvantage)
            )
            
        ).reduce((agg, e) => {
        const pid = e.player.id;
        const minute = parseInt(e.time / (60 * 1000));

        if (!agg[pid]) {
            agg[pid] = {
                minutes : [{
                    minute : minute,
                    postfix : e.type.disadvantage && e.type.abbreviation
                }],
                player : e.player,
                disadvantage : e.type.disadvantage
            }
        }
        else {
            agg[pid].minutes.push({
                minute : minute,
                postfix : e.type.disadvantage && e.type.abbreviation
            });
        }

        return agg;
    }, {}));

    sigEvents.sort((a, b) => a.minutes[0].minute - b.minutes[0].minute);




    return (
        <React.Fragment>
        { 
            sigEvents.map((e, index) => {
                const minutes = e.minutes.map(m => {
                    if (m.postfix)
                        return `${ m.minute }\' (${ m.postfix })`
                    else
                        return `${ m.minute }\'`
                }).join(', ');
                return (
                    <div key={ index }>
                        <strong>{ minutes }</strong> 
                        { ' ' }
                        <PersonName person={ e.player.person } />
                    </div>
                )
            }) 
        }
        </React.Fragment>
    )
}


const SoccerMatchHead = ({ editMode, children, showGroup }) => {

    if (editMode) {
        return "(SoccerMatchHead)";
    }

    const sigNames = configuration.significantEvents.map(e => e.name);

    return (
        <MatchContext.Consumer>{ (match) => (
            <SoccerClock match={ match }>{ ({ stops, minutes, seconds, paused, period, nominalMinutes }) => (
                <WithMatchEvents id={ match.id }>{ ({ isLoaded, events }) => {

                    const home = match.participants.find(p => p.type === 'home');
                    const away = match.participants.find(p => p.type === 'away');

                    const homeScore = match.properties && match.properties.homeScore || 0;
                    const awayScore = match.properties && match.properties.awayScore || 0;

                    const significantEvents = Object.values(events.filter(e => sigNames.indexOf(e.type.name) >= 0).reduce((agg, e) => {
                        const pid = e.player.id;

                        const minute = parseInt(e.time / (60 * 1000));

                        if (!agg[pid]) {
                            agg[pid] = {
                                minutes : [{
                                    minute : minute,
                                    postfix : e.type.disadvantage && e.type.abbreviation
                                }],
                                player : e.player,
                                type : e.player.team === home.team ? 'home' : 'away',
                                disadvantage : e.type.disadvantage
                            }
                        }
                        else {
                            agg[pid].minutes.push({
                                minute : minute,
                                postfix : e.type.disadvantage && e.type.abbreviation
                            });
                        }

                        return agg;
                    }, {}));

                    significantEvents.sort((a, b) => a.minutes[0].minute - b.minutes[0].minute);


                    const score = match.status === 'pending' ? '-' : `${ homeScore || '0' }-${ awayScore || '0' }`;


                    return (
                            <MatchHead>
                                <MatchHead.Versus
                                    group={ showGroup && lo(match.matchGroup, 'name') }
                                    date={ <EventDate date={ match.start } /> }
                                
                                    country={ match.location?.place }
                                    location={ match.location?.name }

                                    progress={ <Progress stops={ stops } value={ nominalMinutes } disabled={ match.status !== 'live' } /> }

                                    home={ home ? lo(home.team, 'name') : '-'  }
                                    away={ away ? lo(away.team, 'name') : '-'  }

                                    homeScore={ homeScore }
                                    awayScore={ awayScore }
                                    score={ score }

                                    time={ match.status === 'live' &&  <SoccerPeriodStatus paused={ paused } period={ period } minutes={ minutes } seconds={ seconds } />  }

                                    live={ match.status === 'live' }

                                    homeEvents={ <EventList names={ ['Goal', 'OwnGoal'] } events={ events } team={ home && home.team } /> }
                                    awayEvents={ <EventList names={ ['Goal', 'OwnGoal'] } events={ events } team={ away && away.team } /> }
                                >
                                    { children }
                                </MatchHead.Versus>
                            </MatchHead>
                    )
                }}</WithMatchEvents>
            )}</SoccerClock>
        )}</MatchContext.Consumer>
    )
}


SoccerMatchHead.defaultProps = {
    showGroup : true
}

export default SoccerMatchHead;