import React from 'react'
import PropTypes from 'prop-types'

import serverTime from '../common/serverTime';
import translatable from '../hocs/translatable';

import { XButton, XButtonGroup, HoverWithOpacity } from './XButton'

import { Dropdown } from './atoms/Dropdown'
import Icon from './Icon';



import GameContext from '../containers/GameContext'

class RoundDropDownList extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            rounds : [],
        };

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }


    filterRounds(rounds) {
        const now = serverTime.now();
        
        switch(this.props.type) {
            case 'all':
                return rounds;
            case 'previous':
                return rounds.filter(r => new Date(r.end) < now)
            case 'currentAndPrevious':
                return rounds.filter((r, index) => index === 0 || new Date(r.start) < now)
        }
    }

    next() {
        
        if (this.props.selected <= this.props.game.rounds.length - 1)
        {
            this.props.onSelect(this.props.selected + 1);
        }
    }

    prev() {
        if (this.props.selected > 1)
        {
            this.props.onSelect(this.props.selected - 1);
        }
    }



    render() {
        const { t } = this.props;
        
        const allRounds = this.props.game.rounds || [];
        const rounds = this.filterRounds(allRounds);
               
        const items = rounds.map((r, idx) => {
            const number = rounds.length - idx;
            return {
                value : number,
                label : t('common.roundFormat', { round : number }),
                selected : number == this.props.selected
            }
        });


        const selectedItem = items.find(i => i.value === this.props.selected) || items[0];
        const selectedIndex = this.props.selected - 1;
        const nextFunc = selectedIndex < items.length - 1 ? this.next : () => {} ;

        return (
            <Dropdown items={ items } align="left" onSelect={ value => this.props.onSelect(value) }>{ ({ open }) => (
                <XButtonGroup size="s" textTransform="uppercase" variant="transparent">
                    <XButton  onClick={ this.prev } disabled={ this.props.selected === 1 } pl="0">
                        <Icon type="chevronLeft"/>
                    </XButton>
                    
                    <XButton onClick={ open }>
                        { selectedItem.label }
                        <HoverWithOpacity ml="s300">
                            <Icon type="chevronDown"/>
                        </HoverWithOpacity>
                    </XButton>

                    <XButton onClick={ nextFunc }  disabled={ this.props.selected >= rounds.length } pr="0">
                        <Icon type="chevronRight"/>
                    </XButton>
                </XButtonGroup>
            )}</Dropdown>
        )
    }  
}

RoundDropDownList.propTypes = {
    game : PropTypes.object.isRequired,
    type : PropTypes.string.isRequired,
    selected : PropTypes.number.isRequired,
    default : PropTypes.string.isRequired,
    onSelect : PropTypes.func.isRequired,
}

RoundDropDownList.defaultProps = {
    type : 'all',
    default : 'last'
}


const Wrapper = ({ t, ...otherProps }) => (
    <GameContext.Consumer>{ ({ game }) => (
        <RoundDropDownList game={ game } t={t} {...otherProps } />
    )}</GameContext.Consumer>
)

export default translatable(Wrapper);
