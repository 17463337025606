import React, { useEffect } from 'react'
import TopBanner from './TopBanner'
import { RequestAds } from '../components/ads'

export default function Competitions({ children }) {


    return (
        <div>
            <TopBanner />
            
            { children }
            
            <RequestAds />
        </div>
    )

}