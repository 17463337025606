import React, { useEffect, useState } from 'react'

function getCookie(name) {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name + '=') === 0) {
            return cookie.substring(name.length + 1, cookie.length);
        }
    }
    return null;
}

function hasConsent() {
    var cookie = getCookie('CookieConsent');
    
    if (cookie) {
        
        const data = decodeURI(cookie);
        return data.indexOf('marketing:true') >= 0; // haaaaaaaaaaaaack
    }
    else {
        return false;
    }
}


export default function useAdConsent() {
    const [consent, setConsent] = useState(hasConsent());
   

    return consent;
}