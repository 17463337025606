import React, { useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { number, getCupStructure } from '../common/utils';
import { useTranslator } from '../hooks/useTranslator'

import useMessageBoard from '../containers/messageBoard/useMessageBoard'
import useFlyout from '../hooks/useFlyout'


import { MemberCompetitionCard } from './components/CompetitionCard'


const CompetitionCard = React.memo( ({ competition, teams, isOwner }) => {
    const t = useTranslator();

    const [unread] = useMessageBoard(competition.messageBoard && competition.messageBoard.id);
    const [showFlyout] = useFlyout();

    let cup = competition.type == 'cup' && getCupStructure(t, competition.rounds, competition.maximumEliminationRounds, competition.enrollmentCount);
    const maximumRank = cup && cup.finalists || 0;
    const stage = null;

    const props = {
        ...competition,
        enrollmentCountText : t(`competitions.n_teams`, { count : number(competition.enrollmentCount, '', 0) }),
        typeName : t(`competitions.format_${ competition.type }`)
    }

    let params = useParams();

    function handleClick() {
        if (competition.type == 'leaderboard') {
            location.href = `/${ params.language }/${ params.gameSlug }/leaderboards/${ competition.slug }`;
        }
        else {
            location.href = `/${ params.language }/${ params.gameSlug }/cups/${ competition.slug }`;
        }
    }

    const teamItems = useMemo(() => (teams ||[]).map(team => {
        let rank = team.standings && team.standings.length > 0 && team.standings[0].rank || 0;
        if (team.qualification)
            rank = t.qualification && t.qualification.rank;
        
        const color = rank > maximumRank && maximumRank > 0 ? "base300" : null;
        
        let right = number(rank);

        if (competition.type === 'leaderboard' && stage == 'pending')
            right = '-'
        if (t.qualification && t.qualification.isEliminated)
            right = t('competitions.eliminated');
        else if (competition.type === 'cup')
            right = t('competitions.participates');

        return {
            id : team.id,
            text : team.name,
            color : color,
            status : right
        }
    }), [teams]);


    const handleShowInfo = (e) => {
        e.stopPropagation();
        showFlyout('competition', { competitionId : competition.id, title : competition.name });        
    };

    const handleShowMessageBoard = (e) => {
        e.stopPropagation();
        showFlyout('competition', { competitionId : competition.id, title : competition.name, initialTab : 'messageBoard' });        
    };
  
    return <MemberCompetitionCard 
                teams={ teamItems }
                unread={ unread }
                competition={ competition } 
                onShowMore={ handleShowInfo } 
                onShowMessageBoard={ handleShowMessageBoard } 
                onClick={ handleClick }
                />;


})



export default CompetitionCard;