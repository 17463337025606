import React from 'react'

import { AdSlot, useAdConsent } from '../components/ads';
import useGameConfig from './useGameConfig';
import { useBreakpoint } from '../components';




export default function TopBanner(){
    const gameConfig = useGameConfig();
    const { breakpoint } = useBreakpoint();
    const consent = useAdConsent();

    if (window.swush.appId != 'holdet')
        return null;

    if (typeof consent == 'undefined')
        return null;

    if (consent) {
        if (breakpoint == 'desktop') {
            return <div style={{ marginTop: '12px' }}><AdSlot adUnit="/83172459/holdet/holdet_megaboard_top" size={ [[728, 90], [930, 180]] } targeting={ gameConfig.targeting } /></div>
        }
        else {
            return <AdSlot adUnit="/83172459/holdet/holdet_swedishhalftakeover" size={ [[320, 160]] } targeting={ gameConfig.targeting }/>
        }
    } else {
        if (breakpoint == 'desktop') {
            return <div style={{ marginTop: '12px' }}><AdSlot adUnit="/83172459/holdet/holdet_noconsent_megaboard_top" size={ [[728, 90], [930, 180]] } targeting={ gameConfig.targeting } /></div>
        }
        else {
            return <AdSlot adUnit="/83172459/holdet/holdet_noconsent_swedishhalftakeover" size={ [[320, 160]] } targeting={ gameConfig.targeting }/>
        }
    }
}


