// Libraries
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { animated, useTransition, config } from 'react-spring'

// Hooks
import useFlyout from '../hooks/useFlyout'

// UI
import { usePageTitle, Heading, Spinner } from '../components';

import { ResponsiveMasonry } from '../components/NewMasonry'

import { Text, Box, ContextMenu, Icon, ContextMenuPortal, XButton, HoverWithOpacity } from 'components'
import CompetitionCard from './CompetitionCard';

import AuthenticationButton from '../containers/AuthenticationButton'

import CreateCompetitionWizard from './CreateCompetitionWizard'

import { useTranslator } from '../hooks/useTranslator'

import useCompetitionList from './useCompetitionList'
import useCompetitionShareDialog from './useCompetitionShareDialog'
import useCurrentGame from '../containers/useCurrentGame';



// No competitions component

const NoCompetitions = React.memo(({ onCreate }) => {
    const t = useTranslator();
    const [push] = useFlyout();

    const items = [
        { command : 'create', label : t('competitions.create_mini_league'), icon : 'plus' },
        { command : 'find', label : t('competitions.find_mini_league'), icon : 'magnify' },
    ]

    const handleClickFind = () => {
        push('find_competition');
    }

    return (
        <Box display="flex" justifyContent="center" textAlign="center" mt="s600" mb="s800">
            <Box width={ 283 }>
                <Heading mb="s500">{ t('competitions.mini_leagues_heading') }</Heading>
                <Text size="s" mb="s500">
                    
                    { t('competitions.league_description') }
                </Text>
                
                <Box mb="s500">
                    <XButton textTransform="uppercase" onClick={ onCreate }>
                        <HoverWithOpacity>
                            <Icon type="plus" mr="s200" />
                        </HoverWithOpacity>
                        { t('competitions.create_mini_league') }
                    </XButton>
                </Box>

                <Box>
                    <XButton textTransform="uppercase" onClick={ handleClickFind }>
                        <HoverWithOpacity>
                            <Icon type="magnify" mr="s200" />
                        </HoverWithOpacity>
                        { t('competitions.find_mini_league') }
                    </XButton>
                </Box>
            </Box>
        </Box>


    )
})


// Component for unauthenticated users

const Unauthenticated = React.memo(() => {

    const t = useTranslator();

    return (
        
        <Box display="flex" justifyContent="center" textAlign="center" mt="s600" mb="s800">
            <Box width={ 283 }>
                <Heading mb="s500">{ t('competitions.mini_leagues_heading') }</Heading>
                <Text size="s" mb="s500">{ t('competitions.league_description') }</Text>
                <AuthenticationButton variant="primary" external>{ t('competitions.login') }</AuthenticationButton>
            </Box>
        </Box>
    )
})

const Header = React.memo(({ onCreate }) => {
    const t = useTranslator();
    const [push] = useFlyout();

    const items = [
        { command : 'create', label : t('competitions.create_mini_league'), icon : 'plus' },
        { command : 'find', label : t('competitions.find_mini_league'), icon : 'magnify' },
    ]

    const handleCommand = (command) => {
        switch(command) {
            case 'create':
                onCreate();
                break;
            case 'find':
                push('find_competition');
                break;
        }
    } 

    return (
        <React.Fragment>
            <ContextMenuPortal>
                <ContextMenu items={ items } onCommand={ handleCommand } showLabel={ false }/>
            </ContextMenuPortal>

            <Box display={ ['none', 'none', 'flex'] } px="s400" height="s500" alignItems="center">
                <Box flex="1">
                    <Text style={{ fontWeight : 'bold' }}>{ t('competitions.mini_leagues_heading') }</Text>
                </Box>

                <ContextMenu items={ items } showLabel={ false } onCommand={ handleCommand }/>
            </Box>

            <div className="native-section">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <ContextMenu items={ items } showLabel={ false } onCommand={ handleCommand }/>
                </div>
            </div>
        </React.Fragment>
    )
})

// Renders the list of competitions

const CompetitionList = React.memo(({ items, onCreate }) => {
    const t = useTranslator();
    const [push] = useFlyout();

    const transitions = useTransition(items, item => item.id, {
        from: { 
            transform: 'translate3d(0, 24px, 0)', 
            opacity: 0 
        },
        enter: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        leave: { transform: 'translate3d(0, 24px, 0)', opacity: 0 },
        config : config.stiff
    });

    const handleClickFind = () => {
        push('find_competition');
    }

    return (
        <Box mb="s500">
            <ResponsiveMasonry gap={ 24 } columns={ [1, 2, 3 ]}>
                {
                    transitions.map(({ item, props, key }) => {
                        if (!item)
                            return null;

                        return (
                            <animated.div key={ key } style={ props }>
                                <CompetitionCard 
                                    name={ item.name} 
                                    description={ item.description }
                                    type={ item.type } 
                                    count={ item.enrollmentCount } 
                                    
                                    competition={ item } 
                                    teams={ item.teams }
                                    
                                    >
                                        
                                    </CompetitionCard>
                            </animated.div>
                        )

                    })
                
                }

                <Box display="flex" justifyContent="center" textAlign="center">
                    <Box width={ 283 }>
                        
                        <Text size="s" mb="s500">
                            
                            { t('competitions.league_description') }
                        </Text>
                        
                        <Box mb="s500">
                            <XButton textTransform="uppercase"  onClick={ onCreate }>
                                <HoverWithOpacity>
                                    <Icon type="plus" mr="s200" />
                                </HoverWithOpacity>
                                { t('competitions.create_mini_league') }
                            </XButton>
                        </Box>

                        <Box>
                            <XButton textTransform="uppercase" onClick={ handleClickFind }>
                                <HoverWithOpacity>
                                    <Icon type="magnify" mr="s200" />
                                </HoverWithOpacity>
                                { t('competitions.find_mini_league') }
                            </XButton>
                        </Box>
                    </Box>
                </Box>
            </ResponsiveMasonry>
        </Box>
    )
})


const ViewConductor = ({ isAuthenticated, didFetch, isFetching, items, onCreate }) => {



    if (!isAuthenticated) {
        return <Unauthenticated />;
    }
    else if (!didFetch || isFetching) {
        return <Box my="s700"><Spinner /></Box>;
    }
    else if (items.length == 0) {
        return <React.Fragment>
               
            <NoCompetitions  onCreate={ onCreate } />
        </React.Fragment>
    }
    else 
        return (
            <React.Fragment>
                
                <Header onCreate={ onCreate}></Header>
                <CompetitionList items={ items }  onCreate={ onCreate}/>

            </React.Fragment>
        )
}


// Main component
const Competitions = React.memo(({ editMode }) => {
    
    const [showCreateCompetitionWizard, setShowCreateCompetitionWizard] = useState(false);
    const params = useParams();
    const [share] = useCompetitionShareDialog();
    const { isAuthenticated } = useSelector((state) => state.auth || { isAuthenticated : false });
    const [items, isFetching, didFetch] = useCompetitionList(isAuthenticated, params.gameId);
    const t = useTranslator();
    const game = useCurrentGame();

    usePageTitle(`${ t('competitions.overview_page_title') } - ${ game.name } - ${ window.swush.site.name } }`);

    if (editMode)
        return "(Competitions)";

    const onCreateCompetitionFinished = (item) => {
        setShowCreateCompetitionWizard(false);

        if (item) {
            share(item.competition, params.language);
       }
    }

    console.log(isAuthenticated, items, isFetching, didFetch);

    return (
        <React.Fragment>
            
            <ViewConductor
                isAuthenticated={ isAuthenticated } 
                onCreate = { () => setShowCreateCompetitionWizard(true) }
                didFetch={ didFetch }
                isFetching={ isFetching }
                items={ items }
                />

            { showCreateCompetitionWizard && <CreateCompetitionWizard onFinish={ onCreateCompetitionFinished } onCancel={ () => setShowCreateCompetitionWizard(false) }/> }
        </React.Fragment>
    )


})

export default Competitions;