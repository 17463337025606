import React from 'react'
import PropTypes from 'prop-types'

class TabPane extends React.PureComponent{
    render() {
        return (
            <div>{ this.props.children }</div>
        )
    }  
}

TabPane.propTypes = {
    title : PropTypes.string.isRequired
}

TabPane.defaultProps = {
}


export default TabPane;