import React from 'react'
import { Box } from 'components'
import { generatePath, useParams } from "react-router";

import { useTranslator } from '../hooks/useTranslator'


const Contests = () => {
    const params = useParams();
    const t = useTranslator();
    
    const competitionsUrl = generatePath("/:language/games/:gameSlug/:gameId/competitions", params);
    const prizeUrl = generatePath("/:language/games/:gameSlug/:gameId/prizes", params);
    

    return (
        <Box textAlign="center" my="s500">
            <h1>{ t('contests.heading') }</h1>
            <p dangerouslySetInnerHTML={{__html: t('contests.content', { competitionsUrl, prizeUrl }) }  }></p>
        </Box>
    )
}

export default Contests;