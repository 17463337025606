import React from 'react'

import { Flyout, DataContextProvider } from '../components'

import GameContext from './GameContext';
import PlayerContext from './PlayerContext';
import WithMatch from './WithMatch'

import { getConfigurationSection } from 'utils'

import ViewContainer from './ViewContainer'

import WithPlayer from './WithPlayer'


class PlayerFlyout extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { id, earnings, onClose } = this.props;

        return (
            <GameContext.Consumer>{ ({ game, gameConfiguration }) => { 

                const flyouts = getConfigurationSection(gameConfiguration, 'flyouts', window.swush.profile);

                return (
                    <WithPlayer id={ id } extra={{ earnings }}>{ (player) => (
                        <PlayerContext.Provider value={ player }>
                      
                            <ViewContainer name={ flyouts.player.view }/>
                     
                        </PlayerContext.Provider>
                    )}</WithPlayer>
                )
            }}</GameContext.Consumer>
                
            
        )
    }
}

export default PlayerFlyout;