import {  useEffect } from 'react';

const useOnEscape = (handler) => {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode == 27) {
                handler();
            }
        };
        
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [handler]);
}

export default useOnEscape;