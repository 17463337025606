export const FETCH_MATCH_REQUEST                = 'FETCH_MATCH_REQUEST';
export const FETCH_MATCH_SUCCESS                = 'FETCH_MATCH_SUCCESS';
export const FETCH_MATCH_FAILURE                = 'FETCH_MATCH_FAILURE';

export const INVALIDATE_MATCH_EVENTS            = 'INVALIDATE_MATCH_EVENTS';
export const FETCH_MATCH_EVENTS_REQUEST         = 'FETCH_MATCH_EVENTS_REQUEST';
export const FETCH_MATCH_EVENTS_SUCCESS         = 'FETCH_MATCH_EVENTS_SUCCESS';
export const FETCH_MATCH_EVENTS_FAILURE         = 'FETCH_MATCH_EVENTS_FAILURE';

export const INVALIDATE_MATCH_FANTASY_EVENTS    = 'INVALIDATE_MATCH_FANTASY_EVENTS';
export const FETCH_MATCH_FANTASY_EVENTS_REQUEST = 'FETCH_MATCH_FANTASY_EVENTS_REQUEST';
export const FETCH_MATCH_FANTASY_EVENTS_SUCCESS = 'FETCH_MATCH_FANTASY_EVENTS_SUCCESS';
export const FETCH_MATCH_FANTASY_EVENTS_FAILURE = 'FETCH_MATCH_FANTASY_EVENTS_FAILURE';

export const DISPLAY_MATCH_FANTASY_EVENT_NOTIFICATION   = 'MATCH/DISPLAY_MATCH_FANTASY_EVENT_NOTIFICATION';
export const HIDE_MATCH_FANTASY_EVENT_NOTIFICATION      = 'MATCH/HIDE_MATCH_FANTASY_EVENT_NOTIFICATION';