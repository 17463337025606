import React from 'react'

import { XButton } from './XButton'

import * as styles from './EmailIdentity.module.css'

export const EmailIdentity = ({ email, rejectCaption, onReject }) => {
    return (
        <div className={ styles.container }>
            <div className={ styles.email }>{ email }</div>
            { rejectCaption && <div><XButton variant="transparent" size="xs" textTransform="uppercase" onClick={ onReject }>{ rejectCaption }</XButton></div> }
        </div>
    )

}
