import React from 'react'
import PropTypes from 'prop-types'

import FormContext from '../../FormContext'
import MapToProps from '../MapToProps'

const ApplyWidget = ({ children, ...otherProps }) => {

    return(
        <FormContext.Consumer>{ ({ data }) => {

            return <MapToProps object={ data } {...otherProps}>{ children }</MapToProps>
        }}</FormContext.Consumer>
    )
}

export default ApplyWidget;