import React, { useRef,useEffect, useState, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import GameContext from '../containers/GameContext'
import { createCompetition } from '../redux/competition/actions';


import { useTranslator } from '../hooks/useTranslator'


import { 
    Dialog, DialogHeader, DialogContent, DialogFooter,
    XButton, HoverWithOpacity,
    Form,
    FormField,
    Textbox,
    FauxPasswordInput,
    Label,
    Textarea,
    Box,
    Icon
} from 'components'

import CompetitionSelectList from './CompetitionSelectList'
import CompetitionTypeSelectList from './CompetitionTypeSelectList'

const initialState = {
    name : '',
    type : 'leaderboard',
    useDescription : false,
    description : '',
    allowMultipleEntries : true,
    usePassword : false,
    password : ''
};


const UIToggle = ({ label, show, text, children, onChangeDisplay }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            { 
                !show && 

                <XButton type="button" size="s" variant="transparent" textTransform="uppercase" onClick={ (e) => onChangeDisplay(!show) } collapse>
                    <HoverWithOpacity mr="s200">
                        <Icon type="plus"></Icon>
                    </HoverWithOpacity>
                    { text }
                </XButton>
            }
            {
                show &&
                <Box>
                    <Box display="flex">
                        <Box flex={ 1 }>
                            <Label>{ label }</Label>
                        </Box>
                        <XButton type="button" size="xs" variant="transparent" textTransform="uppercase" onClick={ () => onChangeDisplay(false) }>{ t('common.remove_caption')}</XButton>
                    </Box>
                    { children }
                </Box>

            }
        </React.Fragment>
    )
}


const CreateCompetitionDialog = ({ open, onChange, onComplete, onCancel }) => {
    const nameRef = useRef();
    const [problems, setProblems] = useState(null);
    const dispatch = useDispatch();
    const gameContext = useContext(GameContext);
    const t = useTranslator();

    useEffect(() => {
        if (nameRef.current)
            nameRef.current.focus();

    }, [nameRef.current])

    const [item, setItem] = useState({
        name : '',
        type : 'leaderboard',
        useDescription : false,
        description : '',
        allowMultipleEntries : true,
        usePassword : false,
        password : '',
        inviteFrom : null,
        useInviteFrom : false
    });


    const onSubmit = () => {
        const data = {
            name : item.name,
            type : item.type,
            allowMultipleEntries : item.allowMultipleEntries,
            description : item.useDescription ? item.description : '',
            password : item.usePassword ? item.password : '',
            inviteFrom : item.useInviteFrom ? { id : item.inviteFrom } : null
        }

        dispatch(createCompetition(gameContext.game.id, data)).then(response => {
            setProblems(null);
            onComplete({ competition : response.data });
        }, e => {
            //const title = e.response.data.title || 'Der opstod en fejl under oprettelsen.'
            //showSnackbar(title, 'error');
            setProblems(e.response.data.errors);
        });
    }

    const handleCancel = () => {
        setProblems(null);
        onCancel();
    }

    return (
        <Dialog open={ open } onClose={ handleCancel }>
        <DialogHeader px="m">{ t('competitions.create_mini_league_heading') }</DialogHeader>
            <DialogContent p="m">
                    <Form problems={ problems } onSubmit={ onSubmit }>
                    <FormField name="name">
                        <Label>{ t('common.name_label') }</Label>
                        <Textbox ref={ nameRef } value={ item.name } onChange={ e => setItem({ ...item, name : e.target.value })} />
                    </FormField>

                    <FormField name="type">
                        <Label>{ t('competitions.format_label') }</Label>
                        <CompetitionTypeSelectList 
                            value={ item.type } 
                            onChange={ value => setItem({ ...item, type : value }) } 
                            />
                    </FormField>


                    <FormField name="description">
                        <UIToggle show={ item.useDescription } label={ t('competitions.description_label') } text={ t('competitions.add_description_caption') } icon="pen" onChangeDisplay={ value => setItem({ ...item, useDescription : value } ) }>
                            <Textarea 
                                value={ item.description } 
                                onChange={ (e) => setItem({ ...item, description : e.target.value })}
                            />
                        </UIToggle>
                    </FormField>

                    <FormField name="password">
                        <UIToggle show={ item.usePassword } label={ t('competitions.password_label') } text={ t('competitions.add_password_caption') } icon="padlock" onChangeDisplay={ value => setItem({ ...item, usePassword : value })}>
                            <FauxPasswordInput value={ item.password } onChange={ e => setItem({ ...item, password : e.target.value }) } />
                        </UIToggle>
                    </FormField>
                    
                    

                </Form>
            </DialogContent>
            <DialogFooter>
                <XButton size="s" variant="transparent" textTransform="uppercase" onClick={ handleCancel }>{ t('common.cancel_caption') }</XButton>
                <XButton variant="primary" size="s" ml="s300" textTransform="uppercase" onClick={ onSubmit }>{ t('common.create_caption') }</XButton>
            </DialogFooter>
        </Dialog>
    )
}

export default CreateCompetitionDialog;