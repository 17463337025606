import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import config from 'config'
import { withRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import WithConfiguration from './WithConfiguration'

import GameContext from './GameContext'
import { currentRound } from 'utils'

import { selectGame, fetchGameIfNeeded } from '../redux/game/actions';
import { getSelectedGame } from '../redux/game/selectors';

import { fetchTournamentIfNeeded } from '../redux/tournament/actions';
import { makeGetTournament } from '../redux/tournament/selectors';

import { fetchRulesetIfNeeded } from '../redux/ruleset/actions'
import { makeGetRuleset } from '../redux/ruleset/selectors';

import { fetchTournamentInjuriesIfNeeded } from '../redux/injury/actions'
import { makeGetTournamentInjuries } from '../redux/injury/selectors';

import { fetchTournamentSuspensionsIfNeeded } from '../redux/suspension/actions'
import { makeGetTournamentSuspensions } from '../redux/suspension/selectors';

import { fetchResourcePermissionsIfNeeded } from '../redux/permission/actions'
import { makeGetResourcePermissions } from '../redux/permission/selectors';

import { fetchScheduleIfNeeded } from '../redux/schedule/actions'
import { makeGetSchedule } from '../redux/schedule/selectors'

import auth from '../common/auth'
import useTheme from '../components/useTheme';


const useGame = (id) => {
    const dispatch = useDispatch();
    const game = useSelector(getSelectedGame);

    useEffect(() => {
        if (id) {
            dispatch(selectGame(id));
            dispatch(fetchGameIfNeeded(id));
        }
    }, [id])

    return game;
}

const useTournament = (id) => {
    const dispatch = useDispatch();
    const getTournament = useMemo(makeGetTournament, []);
    const tournament = useSelector(state => getTournament(state, { id }));

    useEffect(() => {
        if (id) {
            dispatch(fetchTournamentIfNeeded(id));
        }
    }, [id])

    return tournament;
}

const useRuleset = (id) => {
    const dispatch = useDispatch();
    const getRuleset = useMemo(makeGetRuleset, []);
    const ruleset = useSelector(state => getRuleset(state, { id }));

    useEffect(() => {
        if (id) {
            dispatch(fetchRulesetIfNeeded(id));
        }
    }, [id])

    return ruleset;
}


const useTournamentInjuries = (id) => {
    const dispatch = useDispatch();
    const getTournamentInjuries = useMemo(makeGetTournamentInjuries, []);
    const injuries = useSelector(state => getTournamentInjuries(state, { id }));

    useEffect(() => {
        
        if (id) {
            dispatch(fetchTournamentInjuriesIfNeeded(id));
        }
    }, [id])

    return injuries;
}




const useTournamentSuspensions = (id) => {
    const dispatch = useDispatch();
    const getTournamentSuspensions = useMemo(makeGetTournamentSuspensions, []);
    const suspensions = useSelector(state => getTournamentSuspensions(state, { id }));



    useEffect(() => {
        
        if (id) {
            dispatch(fetchTournamentSuspensionsIfNeeded(id));
        }
    }, [id])

    return suspensions;
}



const useSchedule = (id) => {
    const dispatch = useDispatch();
    const getSchedule = useMemo(makeGetSchedule, []);
    const schedule = useSelector(state => getSchedule(state, { id }));


    useEffect(() => {
        
        if (id) {
            dispatch(fetchScheduleIfNeeded(id));
        }
    }, [id])

    return schedule;
}





const useResourcePermissionManager = (resource) => {
    const dispatch = useDispatch();
    const getResourcePermissions = useMemo(makeGetResourcePermissions, []);
    const permissions = useSelector(state => getResourcePermissions(state, resource));
    const authenticated = auth.authenticated;

    const resourceManager = useMemo(() => {
        return {
            permissions,
            hasPermission : function (permission) {
                return this.permissions.indexOf(permission) >= 0
            }
        }
    },[permissions]);

    useEffect(() => {
        if (resource && authenticated) {
            dispatch(fetchResourcePermissionsIfNeeded(resource));
        }
    }, [authenticated, resource])

    return resourceManager;
}

const GameContextProvider = React.memo(({ match, children }) => {
        const { gameId, gameSlug } = match.params;
        const theme = useTheme();
        

        const permissionManager = useResourcePermissionManager(`game:${ gameId }`);
        const game = useGame(gameId);
        const tournament = useTournament(game && game.tournament);
        const ruleset = useRuleset(game && game.ruleset);
        const injuries = useTournamentInjuries(game && game.tournament);
        const suspensions = useTournamentSuspensions(game && game.tournament);
        const schedule = useSchedule(game && game.tournament);

        let th = game && game.theme || '';
        
        if (typeof th !== 'string')
            th = '';

        var themeParts = th.length > 0 ? th.split(' ') : [];
        const themeName = themeParts && themeParts.length > 0 ? themeParts[themeParts.length - 1] : 'default';

        let currentTheme = theme.colors.themes[themeName];
        if (!currentTheme) {
            console.error(`Theme '${ themeName }' does not exist.`);
            currentTheme = theme.colors.themes['default'];
        }

        const nTheme = {
            ...theme,
            colors : {
                ...theme.colors,
                themes : {
                    ...theme.colors.themes,
                    '_current' : currentTheme
                }
            }
        }
        
        
        const configUrl = `${ config.configBaseUrl }/season/games/${ window.swush.appId }/${ gameSlug }.json`;


        return (
            <ThemeProvider theme={ nTheme }>
            <WithConfiguration url={ configUrl }>{ ( gameConfiguration ) => {
                const cRound = game && currentRound(game.rounds);
                const currentRoundNumber = (game && game.rounds.indexOf(cRound) + 1) || 1;

                return (
                        <GameContext.Provider value={{ game, tournament, schedule, ruleset, gameConfiguration, currentRoundNumber, injuries, suspensions, permissionManager }} >
                            { children }
                        </GameContext.Provider>
                )
            }}</WithConfiguration>
            </ThemeProvider>
        )
    }
)

export default withRouter(GameContextProvider);
