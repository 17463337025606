import React from 'react'
import PropTypes from 'prop-types'

import { number } from 'utils';

import * as styles from './Typography.scss'

const Growth = ({ value, treatZeroAsMissing }) => {
    if (value === 0 && treatZeroAsMissing) {
        return '-';
    }
    else if (value > 0) {
        return '+' + number(value);
    }
    else if (value < 0) {
        return <span className={ styles.negative}>{ number(value) }</span>;
    }
    else { 
        return '0'
    }
}

Growth.propTypes = {
    value : PropTypes.number
}

export default Growth;