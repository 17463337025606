import React from 'react'

// Components
import { Icon, Box, Pill, Avatar, Card, Text, ListView, ListViewItem, CommentCountIcon } from 'components' 
import { useTranslator } from '../../hooks/useTranslator'
import { number } from 'utils'
import { CardInset } from '../../components';
import auth from '../../common/auth'


const PreviewCompetitionCardHeader = ({ name, typeName, passwordProtected, imageUrl }) => {
    return (
        <Box flex="1" display="flex" height="s500" px="s400" alignItems="center">
            <Box mr="s400">
                <Avatar variant="rounded" name={ name } src={ imageUrl }/>
            </Box>
            <Box flex="1" overflow="hidden">
                <Text size="m" fontWeight="bold">{ name }</Text>
                <Text size="xs" color="siteForegroundMeta">{ typeName }</Text>
            </Box>
            <Box>
                { passwordProtected && <Icon type="padlock" color="siteForegroundMeta" />} 
            </Box>
        </Box>
    )
}


const MemberCompetitionCardHeader = React.memo(({ name, typeName, unread, imageUrl }) => {
    return (
        <Box flex="1" display="flex" height="s500" px="s400"  alignItems="center">
            <Box mr="s400">
                <Avatar variant="rounded" name={ name } src={ imageUrl }/>
            </Box>
            <Box flex="1">
                <Text fontWeight="bold">{ name }</Text>
                <Text size="xs" color="siteForegroundMeta">{ typeName }</Text>
            </Box>
            { !!unread &&  <Box><CommentCountIcon count={ unread }/></Box> }
        </Box>
    )
})

const CompetitionCardFooter = ({ enrollmentCount, owner, isOwner }) => {
    const t = useTranslator();

    return (
        <Box height="s300" px="s400" display="flex" alignItems="center" color="siteForegroundMeta">

            <Icon type="group" size="1-2x" mr="s100"  />
            <Text size="xs" mr="s300">{ number(enrollmentCount, '', 0) }</Text>

            {
                isOwner ?
                <Pill>
                    <Icon type="person" size="1-2x" mr="s100" />
                    { t('competitions.administrator') }

                </Pill> :
                
                owner ?
                
                <React.Fragment>
                    <Icon type="person" size="1-2x" mr="s100" />
                    <Text size="xs" mr="s300">{ owner }</Text>
                </React.Fragment> : null
            }
        </Box>
    )

}

const CompetitionCardTeamList = React.memo(({ teams, ...otherProps }) => {
    return (
        <CardInset {...otherProps }>
            <ListView>
                {
                    teams.map(item => (
                        <ListViewItem key={ item.id } px="s200">
                            <Box display="flex" height="xl" color={ item.color } alignItems="center" justifyContent="space-between" px="s">
                                <Text color="siteForeground">{ item.text }</Text>
                                <Text color="siteForegroundMeta">{ item.status}</Text>
                            </Box>
                        </ListViewItem>
                    ))  
                }
            </ListView>
        </CardInset>
    )
})




export const PreviewCompetitionCard = React.memo(({ competition, onClick }) => {
    const t = useTranslator();

    const typeName = t(`competitions.format_${ competition.type }`);

    const { name, passwordProtected, description, enrollmentCount } = competition;
    const owner = competition && competition.owner && competition.owner.username;

    return (
        <Card onClick={ onClick } className="mb-3 mx-3">
            <PreviewCompetitionCardHeader name={ name } typeName={ typeName } description={ description }  passwordProtected={ passwordProtected } imageUrl={ competition && competition.imageUrl } />
            <CompetitionCardFooter enrollmentCount={ enrollmentCount } owner={ owner } type={ typeName } />
        </Card>
    )
})



export const MemberCompetitionCard = React.memo(({ competition, teams, unread, onClick, onShowMore, onShowMessageBoard }) => {
    const t = useTranslator();

    const typeName = t(`competitions.format_${ competition.type }`);

    const { name, enrollmentCount } = competition;
    const owner = competition && competition.owner && competition.owner.username;
    const userId = auth.getUserId();
    const isOwner = competition && competition.owner && competition && competition.owner.id == userId;

    return (
        <Card onClick={ onClick }>
            <MemberCompetitionCardHeader name={ name } typeName={ typeName }  unread={ unread } imageUrl={ competition.imageUrl} onShowMore={ onShowMore } onShowMessageBoard={ onShowMessageBoard } />
            { teams && teams.length > 0 && <CompetitionCardTeamList className="mb-2" teams={ teams } /> }
            <CompetitionCardFooter  enrollmentCount={ enrollmentCount } owner={ owner } isOwner={ isOwner } />
        </Card>
    )
})
