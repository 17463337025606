import React from 'react'

import { Media } from '../../components'

import SimpleSchedule from '../SimpleSchedule'
import CyclingScheduleCardView from './CyclingScheduleCardView';
import CyclingScheduleTableView from './CyclingScheduleTableView'

import GameNavigationService from '../GameNavigationService'

class CyclingSchedule extends React.PureComponent
{
    render() {
        return (
            <GameNavigationService>{ ({ showMatch }) => ( 
                <SimpleSchedule>
                    { 
                        ({ schedule }) => (
                            <div>
                                <Media query="(min-width: 1024px)">
                                    <CyclingScheduleTableView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   }/>
                                </Media>

                                <Media query="(max-width: 1023px)">
                                    <CyclingScheduleCardView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   } />
                                </Media>
                            </div>
                        )
                    }
                </SimpleSchedule>
            )}</GameNavigationService>
        )
    }
}

export default CyclingSchedule;