import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';


const TabMenuItem = ({ children, onSelect }) => {
    return (
        <div className="tabmenu__item" onClick={ onSelect }>{ children }</div>
    )
}

class TabMenu extends React.PureComponent{
    constructor(props) {
        super(props);

        this.updateSelected = this.updateSelected.bind(this);
        this.containerRef = React.createRef();
        this.trackerRef = React.createRef();
    }

    updateSelected() {
        const containerEl = this.containerRef.current;
        const selectedEl = containerEl.children[this.props.selectedIndex];
                
        const cRect = containerEl.getBoundingClientRect();
        const sRect = selectedEl.getBoundingClientRect();


        const offset = {
            x : sRect.x - cRect.x,
            y : sRect.y - cRect.y,
            width : sRect.width,
            height : sRect.height
        };

        const trackerEl = this.trackerRef.current;

        trackerEl.style.width = offset.width + 'px';
        trackerEl.style.transform = 'translate3d(' + offset.x + 'px, 0, 0)' 
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateSelected);
        setTimeout(this.updateSelected, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSelected);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selectedIndex !== this.props.selectedIndex) {
            setTimeout(this.updateSelected, 0);
        }
    }
    
    render() {
        const children = this.props.items.map((item, index) => {
            return <TabMenuItem key={ index } onSelect={ () => this.props.onSelect(index) }>{ item }</TabMenuItem>
        });

        const className = classNames(
            'tabmenu',
        );

        
        setTimeout(this.updateSelected, 0);

        return (
            <div ref={ this.containerRef } className={ className }>
                { children }
                <div ref={ this.trackerRef } className="tabmenu__tracker"></div>
            </div>
        )
    }  
}

TabMenu.propTypes = {
    selectedIndex : PropTypes.number.isRequired,
    items : PropTypes.array.isRequired,
    onSelect  : PropTypes.func.isRequired

}


TabMenu.defaultProps = {
    selectedIndex : 0
}

export default TabMenu;