import React, { useMemo } from 'react'

import { useDispatch } from 'react-redux';

import { resign } from '../redux/competition/actions'
import { useSnackbar } from '../hooks/useSnackbar'

const useResign = (competitionId) => {
    const dispatch = useDispatch();
    const [showSnackbar] = useSnackbar();

    const _resign = useMemo(() => (fantasyTeamId) => new Promise((resolve, reject) => {
        dispatch(resign(competitionId, fantasyTeamId)).then(() => {
            showSnackbar('Holdet blev afmeldt', 'success');
            resolve();
        }, () => {
            showSnackbar('Der opstod en fejl', 'error');
            reject();
        });
    }));

    return [_resign];
}

export default useResign;