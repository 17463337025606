import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import Button from './Button'
import Stack from './Stack'

const Confirm = ({ open, title, children, onCancel, onConfirm }) =>  {
    const footer = (
        <Stack padding align="middle">
            <Stack.Item auto></Stack.Item>
            <Stack.Item small={ 4 } medium={ 3 }>
                <Button appearance="transparent" size="small" onClick={ onCancel }>Cancel</Button>
            </Stack.Item>
            <Stack.Item small={ 4 } medium={ 3 }>
                <Button appearance="primary" size="small" onClick={ onConfirm }>Confirm</Button>
            </Stack.Item>
        </Stack>
    )

    return (
        <Modal open={ open } heading={ title } footer={ footer } onClose={ onCancel }>
            { children }
        </Modal>
    )     
}

Confirm.propTypes = {
    open : PropTypes.bool.isRequired,
    title : PropTypes.string.isRequired,
    onConfirm : PropTypes.func,
    onCancel : PropTypes.func
}

Confirm.defaultProps = {
    open : false
}

export default Confirm;