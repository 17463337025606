import React, { useEffect } from 'react'
import propTypes from 'prop-types'

import styled from 'styled-components'
import { space } from 'styled-system'

export const SplitViewTopPane = styled.div`
    border-bottom: 1px solid rgba(255,255,255, 0.1);
`

export const SplitViewPane = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    ${ space }
`



export const SplitView = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${ props => props.orientation == 'horizontal' ? 'row' : 'column' };
`

SplitView.defaultProps = {
    orientation : 'vertical'
}