import React from 'react'
import PropTypes from 'prop-types'

import { ErrorBoundary,  Toolbar, ToolbarButton, MatchCardGroup, MatchCardList, RoundDropDownList, EventDate, ContextMenuPortal, Box, HoverWithOpacity, Icon } from '../components';

import WithFlyout from './WithFlyout'
import WithVersusSchedule  from './WithVersusSchedule'
import { currentRound } from '../common/utils'
import translatable from '../hocs/translatable'

class VersusSchedule extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            round : null
        }
    }

    componentDidMount() {
        const round = currentRound(this.props.game.rounds);
        const roundIndex = this.props.game.rounds.indexOf(round);
        this.setState({
            round : roundIndex + 1
        })
    }


    render() {
        const { t, children, game, gameConfiguration } = this.props;


        if (!this.state.round)
            return null;

        
        return (
            <ErrorBoundary>
                <WithFlyout>{ ({ push }) => ( 
                    <div>

                        <Toolbar>
                            <Toolbar.Item>
                                <RoundDropDownList game={ game } selected={ this.state.round } onSelect={ (round) => this.setState({ round }) } includeAll={true} appearance="transparent" />
                            </Toolbar.Item>

                            
                            {
                                gameConfiguration.standings &&

                                <Toolbar.Item align="right" style={{ marginLeft : 'auto' }} className="show-for-large">
                                    <ToolbarButton onClick={ () => push('standings', {})}>
                                        <Box mr="s200">
                                            <Icon type="table" />
                                        </Box>
                                        { t('common.standings')}
                                        
                                    </ToolbarButton>
                                </Toolbar.Item>
                            }

                                
                            {
                                gameConfiguration.standings &&
                                <Toolbar.Item align="right" style={{ marginLeft : 'auto' }} className="native-section">
                                    <ToolbarButton onClick={ () => push('standings', {})}>
                                        <Box mr="s200">
                                            <Icon type="table" />
                                        </Box>
                                    </ToolbarButton>
                                </Toolbar.Item>
                            }
                            

                        </Toolbar>

                <ContextMenuPortal>
                    <ToolbarButton onClick={ () => push('standings', {})  }>
                        <Icon type="table"/>
                    </ToolbarButton>
                </ContextMenuPortal>

                

                <WithVersusSchedule id={ game.tournament } gameId={ game.id } round={ this.state.round } oddsFeed={ gameConfiguration.odds && gameConfiguration.odds.feed  }>
                    {
                        ({ schedule }) => (
                            <div>
                                {
                                    Object.values(schedule.groups).map((items, index) => {
                                        return (
                                        <MatchCardGroup key={ index } heading={ <EventDate date={ items[0].start } displayTime={ false }/> }> 
                                            <MatchCardList style={{ position: 'relative', zIndex : 1000 - index }}>
                                                {
                                                    items.map((s, index) => {
                                                        
                                                        return <div key={ s.id } style={{ position: 'relative', zIndex : 1000 - index }}>
                                                                    { children({ item : s }) }
                                                                </div>
                                                    })
                                                }
                                            </MatchCardList>
                                        </MatchCardGroup>
                                        )}
                                    )
                                }
                            </div>
                        
                        )  
                    }
                </WithVersusSchedule>
                </div>
                )}
                </WithFlyout>
            
            </ErrorBoundary>
        )

    }
}

VersusSchedule.propTypes = {
    game : PropTypes.object.isRequired,
    gameConfiguration : PropTypes.object.isRequired
}

export default translatable(VersusSchedule);