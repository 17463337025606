import React from 'react'
import PropTypes from 'prop-types'


class WithMatchReport extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            players : []
        }
        this.updateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.updateState(this.props);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            (nextProps.match !== this.props.match) ||
            (nextProps.events !== this.props.events) ||
            (nextProps.fantasyEvents !== this.props.fantasyEvents)
        )
        {
            this.updateState(nextProps);
        }
    }

    updateState(props) {

        const growths = props.fantasyEvents.reduce((agg, ev) => {
            if (!ev.player || !ev.type)
                return agg;
                
            const pid = ev.player.id;
            
            agg[pid] = agg[pid] ? agg[pid] + ev.amount * ev.type.value : ev.amount * ev.type.value;
            return agg;
        }, {});

        
        const players = props.events.reduce((agg, ev) => {
            const pid = ev.player.id;
            const typeName = ev.type.name[0].toLowerCase() + ev.type.name.substring(1);

            if (!agg[pid]) {
                
                const participant = (props.match.participants || []).find(pa => pa.id === ev.participant.id);

                agg[pid] = {
                    player : ev.player || {},
                    participant : participant || {},
                    stats : {},
                    fantasyEvents : {},
                    events : [],
                    growth : growths[pid] || 0,
                    match : props.match || {} ,
                }
            }

            agg[pid].stats[typeName]  = agg[pid].stats[typeName] ? agg[pid].stats[typeName] + ev.amount : ev.amount;
            agg[pid].events.push(ev);
    
            return agg;
        }, {});
    
    
        this.setState({ 
            players : Object.values(players)
        })
    }


    render() {
        const { children } = this.props;
        const { players } = this.state;
        return children(players);
    }
}





WithMatchReport.propTypes = {
    fantasyEvents : PropTypes.array,
    events : PropTypes.array,
    match : PropTypes.object
}

export default WithMatchReport;

