    import React from 'react'

import WithPlayerStatistics from '../../WithPlayerStatistics';
import PlayerContext from '../../PlayerContext';
import ContentBanner from '../../ContentBanner';

const Statistics = ({ editMode, children }) =>  {
    const child = React.Children.only(children);

    if (editMode) {
        return React.cloneElement(child, { items : [{}, {}, {}, {}, {}] });
    }

    return (
        <PlayerContext.Consumer>{ ({ id }) => (
            <>
                <ContentBanner />
                <WithPlayerStatistics playerId={ id }>{ ({ items }) => {
                    return React.cloneElement(child, { datasource : items });
                }}</WithPlayerStatistics>
            </>
        )}</PlayerContext.Consumer>
    )
}


export default Statistics;