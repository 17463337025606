import React from 'react'
import PropTypes from 'prop-types'

import { MatchCardList, MatchCard, PrettyDate } from '../components';

import WithSchedule from './WithSchedule'

import GameContext from './GameContext';

const SimpleSchedule = ({ children }) => (
    <GameContext.Consumer>
        {({ game }) => (
                <WithSchedule id={ game.tournament } gameId={ game.id }>
                    { children }
                </WithSchedule>
        )}
    </GameContext.Consumer>
)

SimpleSchedule.propTypes = {
}

export default SimpleSchedule;