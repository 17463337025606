import React from 'react'

import { Block } from 'components'

import ReactMarkdown from 'react-markdown';

import styled from 'styled-components'


export const ContentEditor = ({ text, ...otherProps }) => {

    
    return (
            <Block {...otherProps }>
                <ReactMarkdown source={ text } />
            </Block>
    )
}


const StyledContainer = styled.div`
    table {
        width: 100%;
    }
    
    th, td {
        padding: 0 6px;
        line-height: 12px;
        box-sizing: border-box;
        height: 36px;
        white-space: nowrap;
    }

    th {
        border-bottom: 1px solid rgba(#000, 0.1);
        text-align: left;
        color: #8A8A8A;
        font-weight: normal;
        font-size: 11px;
        vertical-align: bottom;
        padding: 6px;
        
    }

    td {
        border-top: 1px solid rgba(255,255,255, 0.1);
        border-bottom: 1px solid rgba(0,0,0, 0.1);
    }
` 


export const Content = ({ text, ...otherProps }) => {

    
    return (
        <StyledContainer>
            <Block {...otherProps }>
                <ReactMarkdown source={ text } />
            </Block>
        </StyledContainer>
    )
}

