import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchRepliesIfNeeded, fetchMoreReplies, createReply, applyPendingReplies } from '../../redux/messageBoard/actions'
import { makeGetReplies, makeGetNumberOfPendingReplies } from '../../redux/messageBoard/selectors'

import CreateReply from './CreateReply'

import auth from '../../common/auth'

import styled from 'styled-components'

import { 
    Avatar,
    Dropdown,
    ListViewItem,
    XButton,
    RelativeTime,
    More,
    Spinner,
    ToolbarButton,
    PostText,
    Box,
    Icon,

    Card,
    ListView,
    Text,
    Brevier,
    BodyCopy
} from 'components'

import { AsyncButton } from '../../molecules';
import { useTranslator } from '../../hooks/useTranslator'

const UserIdentity = ({ user }) => {

    if (!user)
        return null;

    return (
        <Box display="flex" alignItems="center">
            <Box mr="s200">
                <Avatar size="tiny" src={ user.imageUrl } name={ user.username } />
            </Box>
            <Text size="m" fontWeight="bold">{ user.username }</Text>
        </Box>
    )
}



const PostCommandDropdown = ({ post, onCommand, rights }) => {
    const t = useTranslator();

    const items = rights.indexOf('d') >= 0 ?
        [
            { value : 'delete', label : t('messageboard.delete_label') },
            { value : 'report', label : t('messageboard.report_label') }
        ] : [{ value : 'report', label : t('messageboard.report_label') }]

    return (
        <Dropdown items={ items } alignment="right" onSelect={ (value) => onCommand(value, post) } >{ ({ open, isOpen }) => (
            <ToolbarButton selected={ isOpen } onClick={ open }>
                <Icon type="kebabMenu" />
            </ToolbarButton>
        )}</Dropdown>
    )
}


const Replies = ({ numberOfPendingReplies, didFetch, replies, clientReplyCount, onApplyPending, onFetch }) => {
    const t = useTranslator();

    if (numberOfPendingReplies > 0) {
        return <XButton size="xs" variant="outline" textTransform="uppercase" onClick={ onApplyPending }>{ t('messageboard.show_n_new_caption', { count : numberOfPendingReplies}) }</XButton>
    }
    else if (!didFetch && replies > 0) {
        return <a href="#" onClick={ onFetch }><Text size="s" textTransform="uppercase">{ t('messageboard.n_replies_caption', { count : clientReplyCount}) }</Text></a>    
    }
    else {
        return <Text size="s" color="siteForegroundMeta" textTransform="uppercase">{ t('messageboard.n_replies_caption', { count : clientReplyCount}) }</Text>
    }
}



const PostFooter = ({ replies, clientReplyCount, numberOfPendingReplies, didFetch, onReply, onApplyPending, onShowReplies }) => {
    const t = useTranslator();
    return (
        <Box px="s400" height="s300">
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box color="metaForeground" display="flex" alignItems="center">
                    <Box style={{ whiteSpace :  'nowrap'}} mr="m">
                        <Replies numberOfPendingReplies={ numberOfPendingReplies } didFetch={ didFetch} replies={ replies } clientReplyCount={ clientReplyCount } onApplyPending={ onApplyPending } onFetch={ onShowReplies } />
                     
                    </Box>
                </Box>
        
                <Box>
                    <XButton size="xs" textTransform="uppercase"  onClick={ onReply }>{ t('messageboard.reply_caption')}</XButton>
                </Box>
            </Box>
        </Box>
    )
}



const PostHeader = ({ post, onCommand, role, userId, ...otherProps}) => {
    const { user } = post;

    const rights = role == 'administrator' || user.id == userId ? 'rwcd' : 'r';

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" height="s400" {...otherProps}>
            <Box display="flex" alignItems="center">
                <UserIdentity user={ user } />
                <Text ml="s200" size="s" color="siteForegroundMeta"><RelativeTime date={ post.created } /></Text>
            </Box>
            <Box color="metaForeground" display="flex" alignItems="center">
                <PostCommandDropdown post={ post } onCommand={ onCommand } rights={ rights } />
            </Box>
        </Box>
    )
}



const StyledReply = styled(Box)`
    border-top: 1px solid ${ props => props.theme.colors.borderUp};
`



const Reply = ({ reply, number, onCommand, role, userId, ...otherProps }) => {
    return (
        <StyledReply display="flex" mx="s" pb="m" {...otherProps}>
            <Box width="xl" height="xl" color="metaForeground" display="flex" alignItems="center" justifyContent="center">
                <Brevier>#{ number }</Brevier>
            </Box>
            <Box flex="5">
                <PostHeader post={ reply } role={ role }  userId={ userId } onCommand={ onCommand } />
                <BodyCopy>
                    <More maximumHeight={ 48 * 4.5 }>
                        <PostText text={ reply.text } />
                    </More>
                </BodyCopy>
            </Box>
        </StyledReply>
    )
}



const PostItem = ({ messageBoardId, role, post, onCommand }) => {
    

    const dispatch = useDispatch();
    const [showReply, setShowReply] = useState(false);
    const getReplies = useMemo(() => makeGetReplies(), []);
    const getNumberOfPendingReplies = useMemo(() => makeGetNumberOfPendingReplies(), [])
    const replies = useSelector(state => getReplies(state, messageBoardId, post.id));
    const numberOfPendingReplies = useSelector(state => getNumberOfPendingReplies(state, messageBoardId, post.id));

    const onFetch = () => dispatch(fetchRepliesIfNeeded(messageBoardId, post.id));
    const onMore = () => dispatch(fetchMoreReplies(messageBoardId, post.id));
    const handleCreateReply = data => {
        dispatch(createReply(messageBoardId, post.id, data))
        setShowReply(false);
    };
    const applyPending = () => dispatch(applyPendingReplies(messageBoardId, post.id));

    const userId = auth.getUserId();

    return (
        <Card mb="s400">
            <Box pl="s400" pr="s200">
                <PostHeader post={ post } onCommand={ onCommand } role={ role } userId={ userId }/>
            </Box>

                <BodyCopy mb="s400" px="s400">
                    
                        <PostText text={ post.text } />
                    
                </BodyCopy>

                { !showReply && <PostFooter replies={ post.replies } clientReplyCount={ post.clientReplyCount } didFetch={ replies.didFetch } numberOfPendingReplies={ numberOfPendingReplies } onApplyPending={ applyPending } onShowReplies={ onFetch } onReply={ () => setShowReply(!showReply) } />}

                { showReply && <CreateReply  onCreate={ handleCreateReply } onCancel={ () => setShowReply(false) } /> }

                {
                    replies.items && replies.items.length > 0 && 
                        <Box>
                            { 
                                replies.items.map((reply, index) => (
                                    <Box key={ reply.id }>
                                        <Reply number={ post.clientReplyCount - index + 1  } role={ role } userId={ userId } reply={ reply } onCommand={ onCommand }  /> 
                                    </Box>
                                ))
                            }
                        </Box>
                }


                { replies.isFetching && <Box py="m"><Spinner/></Box> }

                { !replies.isFetching && replies.links && replies.links.next && <Box height="s400" display="flex" alignItems="center" justifyContent="center"><AsyncButton variant="transparent" size="xs" onClick={ onMore }>Vis ældre</AsyncButton></Box> }

        </Card>
    )
}

export default PostItem;