import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './RoundBadge.scss'

const RoundBadge = ({ children }) => {
    return (
        <div className={ styles.badge }>{ children }</div>
    )
}

RoundBadge.propTypes = {
    children : PropTypes.any
}

export default RoundBadge;