import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { connect } from 'react-redux';

import { safeget } from 'utils';

import { fetchPlayerFantasyEventsIfNeeded } from '../redux/player/actions'

class WithPlayerFantasyEvents extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.fetch(this.props.gameId, this.props.playerId);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.gameId !== this.props.gameId || nextProps.playerId !== this.props.playerId) 
        {
            this.props.fetch(nextProps.gameId, nextProps.playerId);
        }
    }

    render() {
        const { fantasyEvents } = this.props;
        return this.props.children({ fantasyEvents });
    }
}

WithPlayerFantasyEvents.propTypes = {
    gameId : PropTypes.number.isRequired,
    playerId : PropTypes.number.isRequired
}

WithPlayerFantasyEvents.defaultProps = {
}



export const makeGetFantasyEvents = () => (
    createSelector([
        (state, props) => props.playerId,
        (state) => state.player.fantasyEvents,
        (state) => state.entities.fantasyEvents,
        (state) => state.entities.fantasyEventTypes
    ],
    (playerId, playerFantasyEvents, fantasyEventEntities, fantasyEventTypeEntities) => {
        
        return ((safeget(playerFantasyEvents, [playerId]) || { items : []}).items || []).map(pid => {
            const ev = fantasyEventEntities[pid];
            const type = ev && fantasyEventTypeEntities[ev.type];

            return {
                ...ev,
                type
            }

        })
    })
)

const makeMapStateToProps = () => {
    const getFantasyEvents = makeGetFantasyEvents();
    
    return (state, ownProps) => ({
        fantasyEvents : getFantasyEvents(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(gameId, playerId) {
            dispatch(fetchPlayerFantasyEventsIfNeeded(gameId, playerId));
        }
    }
}


export default connect(makeMapStateToProps, mapDispatchToProps)(WithPlayerFantasyEvents);


