import React from 'react';
import { withRouter, Switch, Route, useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { TopBar, VerticalMenu, MenuItem, Box, Icon, CSSVariables, Gamebox, Phase, PhaseText } from 'components'

import translatable from '../../../hocs/translatable'
import { useAppConfig } from '../../AppConfigProvider'

import { lo, format } from 'utils'
import GameMenu from '../../header/GameMenu'
import GameContext from '../../GameContext';
import styled from 'styled-components'
import UserMenu from '../../UserMenu'


const InGameMenu = translatable(withRouter( ({ match, t }) => {
    return (
        <GameContext.Consumer>{ ({ game, gameConfiguration}) => {
                if (!game || !gameConfiguration)
                    return null;

                return (

                <CSSVariables vars={ gameConfiguration ? gameConfiguration.theme : {} }>
                    <Phase game={ game } configuration={ gameConfiguration }>{(phase) => (
                        <Gamebox>
                            <Gamebox.Header title={ lo(game, 'shortName' ) }  
                                            subtitle={ lo(game, 'edition' ) } 
                                            sponsorImageUrl={  gameConfiguration.sponsor && gameConfiguration.sponsor.imageUrl }
                                            status={ <PhaseText phase={ phase } text={ phase.text } /> }
                                            />
                            <Gamebox.Content>
                                <GameMenu vertical themeName={ game && game.theme } />
                            </Gamebox.Content>
                        </Gamebox>
                    )}</Phase>
                </CSSVariables>
                )
        }}</GameContext.Consumer>
    )
}))



const Footer = styled.div`
    padding: 24px 12px;

    
    color : var(--meta-text-color);
    font-size: 12px;

    
    p { 
        font-size: inherit; 
        line-height: 2; 
    }
    a { 
        color : var(--meta-text-color);
        text-decoration: underline;
    }

    a img { opacity: 0.5; transition: 0.1s linear opacity; will-change: opacity; }
    a:hover img { opacity: 1; }

    @media screen and (min-width: 1024px) {
        display: block;
    }
`


const Sidebar = ({ t, logoImageUrl, menu,  children }) => {
    const cfg = useAppConfig();
    const params = useParams();

        
    
        const menuItems = menu && cfg && cfg.menus && cfg.menus[menu] || [];
    
        const mappedItems = menuItems.map(i => {
            return {
                text : i.text,
                href : format(i.url, { language : window.swush.locale, ...params })
            };
        })
        


    return (
        <div>
            <TopBar px="m">
                <Box flex={ 1 }>
                    <a href="/">
                        <img src={ logoImageUrl } style={{ height: '24px' }} alt="Logo" />
                    </a>
                </Box>
                <Box display="flex" alignItems="center">
                    <UserMenu hideName={ true }/>
                </Box>
            </TopBar>


            <Switch>
                    <Route path="/:language/games/:gameSlug/:gameId" component={ InGameMenu } />
                </Switch>




            <VerticalMenu>
                <MenuItem as="a" href='/'>
                    { t('common.allGames') }
                </MenuItem>

                { 
                    mappedItems.map((l, index) => {
                        const icon = l.type === 'external' ? 'externalLink' : null;

                        return (
                            <MenuItem as="a" key={ index } href={ l.href }>
                                { l.text }
                                { icon && <Icon type={ icon } opacity={ 0.5 } />}
                            </MenuItem>
                        )
                    })
                }
            </VerticalMenu>

            <Footer>{ children }</Footer>
        </div>
    )
}


const mapStateToProps = (state, props) => {
    const { isAuthenticated, isAuthenticating, username } = state.auth || { isAuthenticated : false };

    const { usersById , me } = {
        usersById : state.user.byId,
        me : state.user.me,
    };
   
    return {
        me : usersById[me] || {},
        isAuthenticated,
        isAuthenticating, 
        username
    }
}


export default translatable(withRouter(connect(mapStateToProps)(Sidebar)));