import { createSelector } from 'reselect'

import * as types from './types'

const defaultState = {
    catalog: [],
    selected : null,
    byId : {},
    
   
    


};

export default function games (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_GAME_REQUEST:
            return { 
                ...state, 
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_GAME_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.payload.result] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        case types .SELECT_GAME:
            return { 
                ...state,
                selected : action.id
            };
        case types .FETCH_CATALOG_REQUEST:
            return {
                ...state,
                catalog : []
            };
        case types .FETCH_CATALOG_SUCCESS:
            const items = action.payload.result.reduce((acc, id) => {
                
                acc[id] = {
                    isFetching : false,
                    didInvalidate : false
                };
                return acc;
            }, {});

            return {
                ...state,
                catalog : action.payload.result,
                byId : {
                    ...state.byId,
                    ...items
                }
            };
        default:
            return state
    }
}


