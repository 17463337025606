import React, { useContext } from 'react'
import { camelize } from 'utils'

import PlayerContext from '../../PlayerContext'
import GameContext from '../../GameContext'
import DataContext from '../../../components/DataContext'

import WithPlayerValues from '../../WithPlayerValues'
import WithPlayerFantasyEvents from '../../WithPlayerFantasyEvents'


const PlayerProfile = ({ editMode, children }) => {
    if (editMode) {
        return (
            <DataContext.Provider value={{ current : {} }}>
                {children }
            </DataContext.Provider> 
        )
    }
    else {
        const player = useContext(PlayerContext)
        const { game } = useContext(GameContext);

        if (!player || !game)
            return null;

        return (
            <WithPlayerFantasyEvents gameId={ game.id } playerId={ player.id }>{ ({ fantasyEvents }) => (
                <WithPlayerValues gameId={ game.id } playerId={ player.id }>{ ({ values }) => {
                    const eventTotals = fantasyEvents.reduce((agg, e) => {
                        if (!e.type)
                            return agg;
            
                        const name = camelize(e.type.name);
            
                        agg[name] = typeof agg[name] === 'undefined' ? e.amount : agg[name] + e.amount;
                        return agg;
                    }, {})
            
                    const profile =  {
                        
                        ...values[values.length - 1],
                        player,

                        events : eventTotals,
                        disabled : !player.active || player.eliminated || !player.team.active || player.team.eliminated
                    }

                    return (
                        <DataContext.Provider value={{ current : profile }}>
                            {children }
                        </DataContext.Provider>
                    )
                }}</WithPlayerValues>
            )}</WithPlayerFantasyEvents>
        )
    }
}

export default PlayerProfile;

