// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kgRQ8iMG5VQJBARlaqn3{display:flex;width:100%}.n8TwVD7xMLExij0oOJT0{flex-direction:row}.bZ4CXk0KqYkBPnQc47uw{flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/components/Stack.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,UAAA,CAGJ,sBAAA,kBAAA,CACA,sBAAA,qBAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    width: 100%;\r\n}\r\n\r\n.horizontal { flex-direction: row; }\r\n.vertical { flex-direction: column; }"],"sourceRoot":""}]);
// Exports
export var container = `kgRQ8iMG5VQJBARlaqn3`;
export var horizontal = `n8TwVD7xMLExij0oOJT0`;
export var vertical = `bZ4CXk0KqYkBPnQc47uw`;
export default ___CSS_LOADER_EXPORT___;
