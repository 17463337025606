
import { createSelector } from 'reselect'

export const makeGetTheme = () => createSelector(
    (state, id) => state.theme.byId[id],
    state => state.entities.themes,
    (theme, themeEntities) => {

        const { isFetching, didInvalidate, item } = theme || { isFetching : false, didInvalidate : false, item : null };

        return {
            isFetching,
            didInvalidate,
            item : item && themeEntities[item]
        }
    }
)

