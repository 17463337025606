import React from 'react';
import { useDispatch } from 'react-redux';

import { acknowledgeNotifications } from '../redux/notification/actions'
import useFlyout from '../hooks/useFlyout';
import { useAuthentication } from '../hooks/useAuthentication';

import { Icon, ToolbarButton } from 'components'
import ConnectedNotificationBadge from './ConnectedNotificationBadge'

const NotificationBell = () => {
    const dispatch = useDispatch();
    const [push] = useFlyout();
    const auth = useAuthentication();
    
    const handleShowNotifications = () => {
        dispatch(acknowledgeNotifications());
        push('notifications', {} )
    }

    if (!auth.isAuthenticated)
        return null;
    
    return (
        <ConnectedNotificationBadge>
            <ToolbarButton onClick={ handleShowNotifications }>
                <Icon type="bell" />
            </ToolbarButton>
        </ConnectedNotificationBadge>
    )
}

export default NotificationBell;