import React, { useMemo, useEffect } from 'react'

import styled, { css } from 'styled-components'
import { color, layout, flexbox, space } from 'styled-system'
import { animated, useTransition, config } from 'react-spring'
import { selectTournament } from '../redux/ui/actions';

import Spinner from './Spinner'




export const ListView = styled.div`
    ${ color }
    ${ layout }
    ${ flexbox }
    ${ space }
`

export const ListViewItem = styled.div`
    border-bottom: 1px solid ${ props => props.theme.colors.borderUp };
    border-top: 1px solid ${ props => props.theme.colors.borderDown };
    transition: 0.2s ease-in-out background-color;

    min-height: 48px;
    
    ${ color }
    ${ layout }
    ${ flexbox }
    ${ space }

    ${ 
        props => props.onClick && css`
            cursor: pointer;
            &:hover {
                background-color: rgba(255,255,255,0.1);
            }
        `
    }

    ${ 
        props => props.disabled && css`
            opacity: 0.5
        `
    }
`


export const ListViewHeader = styled.div`
	border-bottom: ${ props => `1px solid ${  props.theme.colors.borderBottom}` };
    
    ${ color }
    ${ layout }
    ${ flexbox }
    ${ space }
`

const StyledAnimatedListView = styled.div`
    position: relative;

    ${ ListViewItem } {
        
    }
`


const SpinnerContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 48px;
`



function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

export const AnimatedListView = React.memo(({ items, isFetching, itemHeight, keySelector, children }) => {
    const rows = useMemo(() => {
        return items && items.map((item, index) => ({ ...item, index, _y : index * itemHeight }))
    }, [items]);


    const transitions = useTransition(rows, keySelector, {
        from: item => ({ position: 'absolute', top : item._y + 'px', height : itemHeight + 'px',  width: '100%', opacity: 0, transform: 'translate3d(0, 24px, 0)' }),
        enter: item => async (next, cancel) => {
            await sleep(item.index * 25)
            await next({ opacity: 1, transform: 'translate3d(0, 0px, 0)' })
        },
        leave: { opacity: 0 , transform: 'translate3d(0, 0px, 0)' }
    });


    return (
        <StyledAnimatedListView>
            { isFetching && <SpinnerContainer><Spinner /></SpinnerContainer> }

            { 
                transitions.map(({ item, props, key }) => {
                    return <animated.div key={ key } style={ props }>{ children(item) }</animated.div>
                }) 
            }

            
        </StyledAnimatedListView>
    )

})

