// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.GzIHxHsSM68rrYBMKUlo {
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0 48px 0 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 72px;    
}

.u7bhaN2rGUtUVAvs3PiS {
    margin: 0 24px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 24px;
}


.hSnnZM4IpbYA4XD2sXjY {
    padding: 12px 24px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 72px;
}

.hSnnZM4IpbYA4XD2sXjY > * {
    margin-left: 12px;
}
    `, "",{"version":3,"sources":["webpack://./src/components/Dialog.module.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,OAAO;IACP,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;AACxB;;;AAGA;IACI,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["\r\n.header {\r\n    min-height: 72px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0 48px 0 24px;\r\n    text-overflow: ellipsis;\r\n    overflow: hidden;\r\n    white-space: nowrap;\r\n    margin-right: 72px;    \r\n}\r\n\r\n.content {\r\n    margin: 0 24px;\r\n    flex: 1;\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n    padding-bottom: 24px;\r\n}\r\n\r\n\r\n.footer {\r\n    padding: 12px 24px 24px 24px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n    height: 72px;\r\n}\r\n\r\n.footer > * {\r\n    margin-left: 12px;\r\n}\r\n    "],"sourceRoot":""}]);
// Exports
export var header = `GzIHxHsSM68rrYBMKUlo`;
export var content = `u7bhaN2rGUtUVAvs3PiS`;
export var footer = `hSnnZM4IpbYA4XD2sXjY`;
export default ___CSS_LOADER_EXPORT___;
