import React from 'react'
import PropTypes from 'prop-types'

import { Datasource } from 'components'


import WithTeamPlayers from '../../WithTeamPlayers'

const TeamPlayers = ({ teamId, position, name, editMode, children }) => {
    if (editMode) {
        return (
            <Datasource value={{ [name] : [{}, {}, {}] }}>
                {children }
            </Datasource> 
        )
    }
    else {
        return (
            <WithTeamPlayers id={ teamId } position={ position }>{ (players) => (
                <Datasource value={{ [name] : players }}>
                    { children }
                </Datasource>
            )}</WithTeamPlayers>
        )
    }
}

TeamPlayers.propTypes = {
    teamId : PropTypes.number,
    name : PropTypes.string,
    position : PropTypes.string
}

export default TeamPlayers;

