import React from 'react'
import PropTypes from 'prop-types'

import { Text } from './Typography'
import Icon from './Icon'
import Box from './Box'

import * as styles from './FactList.module.css'

export const FactListItem = ({ size, icon, children, label }) => {
    return (
        <Box mr="s500">
            <Text size={ size == 'l' ? 'l' : 'm' } fontWeight="bold">{ children }</Text>
            
            <Box display="flex" alignItems="center">
                { icon && <Icon type={ icon } size="1-2x" mr="s100"></Icon> }
                <Text size={ size == 'l' ? 's' : 'xs' }>{ label }</Text>
            </Box>
        </Box>
    )
}

export const FactList = ({ size, children }) => {
    return (
        <div className={ styles.container }>
            { React.Children.map(children, child => child ? React.cloneElement(child, { size }) : null ) }
        </div>
    )
}

FactList.propTypes = {
    size : PropTypes.string,
    shadow : PropTypes.bool
}

FactList.defaultProps = {
    size : 'm'
}