import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, EmailIdentity,AuthenticationHeader } from 'components'
import { BusyButton } from '../../molecules'
import { signupSubmitEmail, goto } from '../../redux/auth/actions'
import { useTranslator } from '../../hooks/useTranslator'

export const SignupEmail = () => {
    const dispatch = useDispatch()
    const t = useTranslator();
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.signup)

    const onSubmitInternal = (e) => {
        e.preventDefault();
        dispatch(signupSubmitEmail(email));
    }

    const onReject = ()=> {
        dispatch(goto('start'));
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.createAccountHeader') }</AuthenticationHeader>

            <Form problems={ problems } onSubmit={ e => onSubmitInternal(e) }>
                <p>{ t('authentication.creatingAccountMessage') }</p>
                
                <EmailIdentity email={ email } rejectCaption={ t('authentication.notMeButtonCaption') } onReject={ onReject }/>

                <BusyButton type="submit" busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ t('authentication.continueCreationButtonCaption') }</BusyButton>
            </Form>
        </React.Fragment>

    )
}



