import React from 'react';
import PropTypes from 'prop-types'

const Sticky = ({ offset, children }) => {
    return <div style={{ position: 'sticky', top : `${ offset }px` }} >
                { children }
           </div>
}

Sticky.defaultProps = {
    offset : 0
}

export default Sticky;