import * as types from './types'
  
const defaultState = {
    flow : 'start',
    showModal : false,
    check : {
    },
    facebook : {
    },
    signup : {
        step: 0,
        form : {
        }
    },
    reset : {},
    signin : {},
    isAuthenticating : false,
    isAuthenticated : false,
    username : '',
    accessToken : '',

    data: {
        email : ''
    }
};


const facebookReducer = (state, action) => {
    switch(action.type) {
        case types.FACEBOOK_LOGIN_REQUEST:
            return {
                ...state,
                accessToken : action.accessToken
            }            
        case types.FACEBOOK_SIGNUP_REQUEST:
            return {
                ...state,
                isBusy : true
            }
        case types.FACEBOOK_SIGNUP_SUCCESS:
            return {
                ...state.facebook,
                isBusy : false
            }   
        case types.FACEBOOK_SIGNUP_FAIL:
            return {
                ...state,
                problems: action.problems,
                isBusy : false
            }
        default:
            return state;
    }
}


const signupReducer = (state, action) => {
    switch(action.type) {
        case types.SIGNUP_SUBMIT_EMAIL_REQUEST:
        case types.SIGNUP_SUBMIT_CODE_REQUEST:          
        case types.SIGNUP_SUBMIT_REQUEST:          
            return {
                ...state,
                isBusy : true
            }
        case types.SIGNUP_SUBMIT_EMAIL_SUCCESS:
        case types.SIGNUP_SUBMIT_SUCCESS:            
            return {
                ...state,
                isBusy : false
            }
        case types.SIGNUP_SUBMIT_EMAIL_FAIL:
        case types.SIGNUP_SUBMIT_CODE_FAIL:
        case types.SIGNUP_SUBMIT_FAIL:
            return {
                ...state,
                problems: action.problems,
                isBusy : false
            }
        case types.SIGNUP_SUBMIT_CODE_SUCCESS:
            return {
                ...state,
                isBusy : false,
                code : action.code
            }   
        default:
            return state;
    }
}



const resetReducer = (state, action) => {
    switch(action.type) {
        case types.REQUEST_RESET_REQUEST:
        case types.RESET_REQUEST:          
            return {
                ...state,
                isBusy : true
            }
        case types.REQUEST_RESET_SUCCESS:
        case types.RESET_SUCCESS:            
            return {
                ...state,
                isBusy : false
            }
        case types.REQUEST_RESET_FAIL:
        case types.RESET_FAIL:
            return {
                ...state,
                problems: action.problems,
                isBusy : false
            }
        default:
            return state;
    }
}


const checkReducer = (state, action) => {
    switch(action.type) {
        case types.CHECK_ACCOUNT_REQUEST:
            return {
                ...state,
                problems : null,
                isBusy : true
            }
        case types.CHECK_ACCOUNT_SUCCESS:
            return {
                ...state,
                problems : null,
                isBusy : false
            }            
        case types.CHECK_ACCOUNT_FAIL:
            return {
                ...state,
                problems : action.problems,
                isBusy : false
            }          
        default:
    }
}


const signinReducer = (state, action) => {
    switch(action.type) {
        case types.SIGNIN_REQUEST:
            return {
                ...state,
                isBusy : true
            }
        case types.SIGNIN_SUCCESS:
            return {
                ...state,
                isBusy : false
            }
        case types.SIGNIN_FAIL:
            return {
                ...state,
                problems: action.problems,
                isBusy : false
            }
        default:
            return state;
    }
}


export default function auth (state = defaultState, action) 
{
    switch (action.type) {
        case types.SET:
                return {
                    ...state,
                    data : {
                        ...state.data,
                        ...action.data
                    }
                }
        case types.START:
            return {
                ...state,
                flow : 'start',
                showModal : true,
                data : {}
            }
        case types.CANCEL:
        case types.COMPLETE:
            return {
                ...state,
                flow : 'start',
                showModal : false
            }
        case types.GOTO:
            return {
                ...state,
                flow : action.name,
                showModal : true
            }
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticating : true
            }
        case types.AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isAuthenticating : false,
                isAuthenticated : true,
                accessToken : action.payload.accessToken,
                username : action.payload.username
            }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticating : false,
                isAuthenticated : true,
                accessToken : action.accessToken,
                username : action.username
            }
        case types.LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticating : false,
                isAuthenticated : false,
                accessToken : ''
            }


            case types.REQUEST_RESET_REQUEST:
            case types.REQUEST_RESET_SUCCESS:
            case types.REQUEST_RESET_FAIL:
            case types.RESET_REQUEST:
            case types.RESET_SUCCESS:
            case types.RESET_FAIL:
                return { ...state, reset : resetReducer(state.signin, action) };

            case types.SIGNIN_REQUEST:
            case types.SIGNIN_SUCCESS:
            case types.SIGNIN_FAIL:
                return { ...state, signin : signinReducer(state.signin, action) };
            case types.CHECK_ACCOUNT_REQUEST:
            case types.CHECK_ACCOUNT_SUCCESS:
            case types.CHECK_ACCOUNT_FAIL:
                return { ...state, check : checkReducer(state.check, action) };
            case types.FACEBOOK_LOGIN_REQUEST:
            case types.FACEBOOK_LOGIN_SUCCESS:
            case types.FACEBOOK_SIGNUP_REQUEST:
            case types.FACEBOOK_SIGNUP_SUCCESS:
            case types.FACEBOOK_SIGNUP_FAIL:
                return { ...state, facebook : facebookReducer(state.facebook, action) };
            case types.SIGNUP_SUBMIT_EMAIL_REQUEST:
            case types.SIGNUP_SUBMIT_CODE_REQUEST:          
            case types.SIGNUP_SUBMIT_REQUEST:          
            case types.SIGNUP_SUBMIT_EMAIL_SUCCESS:
            case types.SIGNUP_SUBMIT_SUCCESS:            
            case types.SIGNUP_SUBMIT_EMAIL_FAIL:
            case types.SIGNUP_SUBMIT_CODE_FAIL:
            case types.SIGNUP_SUBMIT_FAIL:
            case types.SIGNUP_SUBMIT_CODE_SUCCESS:
                return { ...state, signup : signupReducer(state.signup, action) };
        default:
            return state
    }
}