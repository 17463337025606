
import { createSelector } from 'reselect'


export const makeGetResourcePermissions = () => createSelector(
    (state, resource) => { return state.permission.resources[resource] },
    (permissions) => {
        return permissions || [];
    }
)
