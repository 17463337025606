const translationContext = require.context('./translations/', true, /\.json$/);
const translations = translationContext.keys().reduce((acc, k) => {
    const parts = k.split('/');
    const culture = parts[1];
    const filename = parts[2];
    const filenameParts = filename.split('.');

    if (!acc[culture]) {
        acc[culture] = [];
    }
    acc[culture][filenameParts[0]] = translationContext(k);

    return acc;
}, {});

export default translations;