// Fetch my competitions

export const FETCH_USER_COMPETITIONS_REQUEST        = 'FETCH_USER_COMPETITIONS_REQUEST';
export const FETCH_USER_COMPETITIONS_SUCCESS        = 'FETCH_USER_COMPETITIONS_SUCCESS';
export const FETCH_USER_COMPETITIONS_FAIL           = 'FETCH_USER_COMPETITIONS_FAIL';

export const INVALIDATE_USER_COMPETITIONS           = 'INVALIDATE_USER_COMPETITIONS';

// Fetch competition

export const FETCH_COMPETITION_REQUEST              = 'FETCH_COMPETITION_REQUEST';
export const FETCH_COMPETITION_SUCCESS              = 'FETCH_COMPETITION_SUCCESS';
export const FETCH_COMPETITION_FAIL                 = 'FETCH_COMPETITION_FAIL';

// Fetch Leaderboards

export const FETCH_USER_LEADERBOARDS_REQUEST        = 'FETCH_USER_LEADERBOARDS_REQUEST';
export const FETCH_USER_LEADERBOARDS_SUCCESS        = 'FETCH_USER_LEADERBOARDS_SUCCESS';
export const FETCH_USER_LEADERBOARDS_FAILURE        = 'FETCH_USER_LEADERBOARDS_FAILURE';

// Search Competitions

export const FIND_COMPETITIONS_REQUEST              = 'FIND_COMPETITIONS_REQUEST';
export const FIND_COMPETITIONS_SUCCESS              = 'FIND_COMPETITIONS_SUCCESS';
export const FIND_COMPETITIONS_FAIL                 = 'FIND_COMPETITIONS_FAIL';

export const FIND_MORE_COMPETITIONS_REQUEST         = 'FIND_MORE_COMPETITIONS_REQUEST';
export const FIND_MORE_COMPETITIONS_SUCCESS         = 'FIND_MORE_COMPETITIONS_SUCCESS';
export const FIND_MORE_COMPETITIONS_FAIL            = 'FIND_MORE_COMPETITIONS_FAIL';

// Fetch Standings

export const FETCH_STANDINGS_REQUEST                = 'FETCH_STANDINGS_REQUEST';
export const FETCH_STANDINGS_SUCCESS                = 'FETCH_STANDINGS_SUCCESS';
export const FETCH_STANDINGS_FAIL                   = 'FETCH_STANDINGS_FAIL';


// Fetch My Enrollments

export const FETCH_MY_ENROLLMENTS_REQUEST           = 'FETCH_MY_ENROLLMENTS_REQUEST';
export const FETCH_MY_ENROLLMENTS_SUCCESS           = 'FETCH_MY_ENROLLMENTS_SUCCESS';
export const FETCH_MY_ENROLLMENTS_FAIL              = 'FETCH_MY_ENROLLMENTS_FAIL';

// Fetch Qualifications

export const FETCH_QUALIFICATIONS_REQUEST           = 'FETCH_QUALIFICATIONS_REQUEST';
export const FETCH_QUALIFICATIONS_SUCCESS           = 'FETCH_QUALIFICATIONS_SUCCESS';
export const FETCH_QUALIFICATIONS_FAIL              = 'FETCH_QUALIFICATIONS_FAIL';


// Create Competition

export const CREATE_COMPETITION_REQUEST             = 'CREATE_COMPETITION_REQUEST';
export const CREATE_COMPETITION_SUCCESS             = 'CREATE_COMPETITION_SUCCESS';
export const CREATE_COMPETITION_FAIL                = 'CREATE_COMPETITION_FAIL';

// Delete Competition

export const DELETE_COMPETITION_REQUEST             = 'DELETE_COMPETITION_REQUEST';
export const DELETE_COMPETITION_SUCCESS             = 'DELETE_COMPETITION_SUCCESS';
export const DELETE_COMPETITION_FAIL                = 'DELETE_COMPETITION_FAIL';


// Fetch Enrollment Options

export const FETCH_ENROLLMENT_OPTIONS_REQUEST       = 'FETCH_ENROLLMENT_OPTIONS_REQUEST';
export const FETCH_ENROLLMENT_OPTIONS_SUCCESS       = 'FETCH_ENROLLMENT_OPTIONS_SUCCESS';
export const FETCH_ENROLLMENT_OPTIONS_FAIL          = 'FETCH_ENROLLMENT_OPTIONS_FAIL';

export const INVALIDATE_ENROLLMENT_OPTIONS          = 'INVALIDATE_ENROLLMENT_OPTIONS';

// On enrollments change

export const ENROLLMENT_CHANGE                      = 'ENROLLMENT_CHANGE';

// Update Competitions

export const UPDATE_COMPETITION_REQUEST             = 'UPDATE_COMPETITION_REQUEST';
export const UPDATE_COMPETITION_SUCCESS             = 'UPDATE_COMPETITION_SUCCESS';
export const UPDATE_COMPETITION_FAIL                = 'UPDATE_COMPETITION_FAIL';

// Fetch Enrollments

export const INVALIDATE_ENROLLMENTS                 = 'INVALIDATE_ENROLLMENTS';

export const FETCH_ENROLLMENTS_REQUEST              = 'FETCH_ENROLLMENTS_REQUEST';
export const FETCH_ENROLLMENTS_SUCCESS              = 'FETCH_ENROLLMENTS_SUCCESS';
export const FETCH_ENROLLMENTS_FAIL                 = 'FETCH_ENROLLMENTS_FAIL';

export const FETCH_MORE_ENROLLMENTS_REQUEST         = 'FETCH_MORE_ENROLLMENTS_REQUEST';
export const FETCH_MORE_ENROLLMENTS_SUCCESS         = 'FETCH_MORE_ENROLLMENTS_SUCCESS';
export const FETCH_MORE_ENROLLMENTS_FAIL            = 'FETCH_MORE_ENROLLMENTS_FAIL';

// Invite

export const INVITE_REQUEST                         = 'INVITE_REQUEST';
export const INVITE_SUCCESS                         = 'INVITE_SUCCESS';
export const INVITE_FAIL                            = 'INVITE_FAIL';


export const INVITE_ALL_REQUEST                         = 'INVITE_ALL_REQUEST';
export const INVITE_ALL_SUCCESS                         = 'INVITE_ALL_SUCCESS';
export const INVITE_ALL_FAIL                            = 'INVITE_ALL_FAIL';



// Fetch relevant users

export const FETCH_RELEVANT_USERS_REQUEST            = 'FETCH_RELEVANT_USERS_REQUEST';
export const FETCH_RELEVANT_USERS_SUCCESS            = 'FETCH_RELEVANT_USERS_SUCCESS';
export const FETCH_RELEVANT_USERS_FAIL               = 'FETCH_RELEVANT_USERS_FAIL';
export const FETCH_MORE_RELEVANT_USERS_REQUEST       = 'FETCH_MORE_RELEVANT_USERS_REQUEST';
export const FETCH_MORE_RELEVANT_USERS_SUCCESS       = 'FETCH_MORE_RELEVANT_USERS_SUCCESS';
export const FETCH_MORE_RELEVANT_USERS_FAIL          = 'FETCH_MORE_RELEVANT_USERS_FAIL';


// Resignation

export const RESIGN_SUCCESS                         = 'RESIGN_SUCCESS';



export const FETCH_MY_COMPETITION_HISTORY_REQUEST = 'FETCH_MY_COMPETITION_HISTORY_REQUEST';
export const FETCH_MY_COMPETITION_HISTORY_SUCCESS = 'FETCH_MY_COMPETITION_HISTORY_SUCCESS';
export const FETCH_MY_COMPETITION_HISTORY_FAIL    = 'FETCH_MY_COMPETITION_HISTORY_FAIL';
