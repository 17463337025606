import axios from 'axios'
import { normalize } from 'normalizr'

import { eventSchema, fantasyEventSchema, playerValueSchema, playerProfileSchema, playerStatisticsSchema, playerGameStatisticsSchema, playerRoundStatisticsSchema  } from '../../schemas';

import { safeget } from 'utils'

import * as types from './types';

/* Fetch Player Events */

const fetchPlayerEventsRequest = (playerId) => ({ type: types.FETCH_PLAYER_EVENTS_REQUEST, playerId });
const fetchPlayerEventsSuccess = (playerId, payload) => ({ type: types.FETCH_PLAYER_EVENTS_SUCCESS, playerId, payload });
const fetchPlayerEventsFailure = (playerId) => ({ type: types.FETCH_PLAYER_EVENTS_FAILURE, playerId });

const fetchPlayerEvents = (playerId) => (dispatch) => {
    dispatch(fetchPlayerEventsRequest(playerId));

    axios.get(`/players/${ playerId }/events`).then(response => {
        dispatch(fetchPlayerEventsSuccess(playerId, normalize(response.data, [eventSchema])));
    }, error => {
        dispatch(fetchPlayerEventsFailure(playerId));
    });
}



const shouldFetchPlayerEvents = (state, playerId) => {
    const item = state.player.events[playerId];
  
    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
  }
  
  
  export const fetchPlayerEventsIfNeeded = (playerId) => (dispatch, getState) => {
    if (shouldFetchPlayerEvents(getState(), playerId)) {
        dispatch(fetchPlayerEvents(playerId));
    }
  }
  


/* Fetch Player FantasyEvents */

const fetchPlayerFantasyEventsRequest = (gameId, playerId) => ({ type: types.FETCH_PLAYER_FANTASY_EVENTS_REQUEST, gameId, playerId });
const fetchPlayerFantasyEventsSuccess = (gameId, playerId, payload) => ({ type: types.FETCH_PLAYER_FANTASY_EVENTS_SUCCESS, gameId, playerId, payload });
const fetchPlayerFantasyEventsFailure = (gameId, playerId) => ({ type: types.FETCH_PLAYER_FANTASY_EVENTS_FAILURE, gameId, playerId });

const fetchPlayerFantasyEvents = (gameId, playerId) => (dispatch) => {
    dispatch(fetchPlayerFantasyEventsRequest(gameId, playerId));

    axios.get(`/games/${ gameId }/players/${ playerId }/fantasyevents`).then(response => {
        dispatch(fetchPlayerFantasyEventsSuccess(gameId, playerId, normalize(response.data, [fantasyEventSchema])));
    }, error => {
        dispatch(fetchPlayerFantasyEventsFailure(gameId, playerId));
    });
}



const shouldFetchPlayerFantasyEvents = (state, gameId, playerId) => {
    const { isFetching, items, didInvalidate } = state.player.fantasyEvents[playerId] && state.player.fantasyEvents[playerId][gameId] || { items : null, isFetching : false, didInvalidate : false };

     if (isFetching) {
        return false;
    }
    else if (!items) {
        return true;
    }
    else {
        return didInvalidate;
    }
  }
  
  
  export const fetchPlayerFantasyEventsIfNeeded = (gameId, playerId) => (dispatch, getState) => {
    if (shouldFetchPlayerFantasyEvents(getState(), gameId, playerId)) {
        dispatch(fetchPlayerFantasyEvents(gameId, playerId));
    }
  }

/* Fetch Player Values */

const fetchPlayerValuesRequest = (gameId, playerId) => ({ type: types.FETCH_PLAYER_VALUES_REQUEST, gameId, playerId });
const fetchPlayerValuesSuccess = (gameId, playerId, payload) => ({ type: types.FETCH_PLAYER_VALUES_SUCCESS, gameId, playerId, payload });
const fetchPlayerValuesFailure = (gameId, playerId) => ({ type: types.FETCH_PLAYER_VALUES_FAILURE, gameId, playerId });

const fetchPlayerValues = (gameId, playerId) => (dispatch) => {
    dispatch(fetchPlayerValuesRequest(gameId, playerId));

    axios.get(`/games/${ gameId }/players/${ playerId }/values`).then(response => {
        dispatch(fetchPlayerValuesSuccess(gameId, playerId, normalize(response.data, [playerValueSchema])));
    }, error => {
        dispatch(fetchPlayerValuesFailure(gameId, playerId));
    });
}

const shouldFetchPlayerValues = (state, gameId, playerId) => {
    const item = safeget(state.player.values, [playerId, gameId]);
  
    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
  }
  
  
  export const fetchPlayerValuesIfNeeded = (gameId, playerId) => (dispatch, getState) => {
    if (shouldFetchPlayerValues(getState(), gameId, playerId)) {
        dispatch(fetchPlayerValues(gameId, playerId));
    }
  }


/* Fetch Player Profile */

const fetchPlayerProfileRequest = (gameId, playerId) => ({ type: types.FETCH_PLAYER_PROFILE_REQUEST, gameId, playerId });
const fetchPlayerProfileSuccess = (gameId, playerId, payload) => ({ type: types.FETCH_PLAYER_PROFILE_SUCCESS, gameId, playerId, payload });
const fetchPlayerProfileFailure = (gameId, playerId) => ({ type: types.FETCH_PLAYER_PROFILE_FAILURE, gameId, playerId });

export const fetchPlayerProfile = (gameId, playerId) => (dispatch) => {
    dispatch(fetchPlayerProfileRequest(gameId, playerId));

    axios.get(`/games/${ gameId }/players/${ playerId }/profile`).then(response => {
        dispatch(fetchPlayerProfileSuccess(gameId, playerId, normalize(response.data, playerProfileSchema)));
    }, error => {
        dispatch(fetchPlayerProfileFailure(gameId, playerId));
    });
}



/* Fetch Player Fantasy Statistics */

const fetchPlayerGameStatisticsRequest = (gameId, playerId) => ({ type: types.FETCH_PLAYER_GAME_STATISTICS_REQUEST, gameId, playerId });
const fetchPlayerGameStatisticsSuccess = (gameId, playerId, payload) => ({ type: types.FETCH_PLAYER_GAME_STATISTICS_SUCCESS, gameId, playerId, payload });
const fetchPlayerGameStatisticsFailure = (gameId, playerId) => ({ type: types.FETCH_PLAYER_GAME_STATISTICS_FAILURE, gameId, playerId });

export const fetchPlayerGameStatistics = (gameId, playerId) => (dispatch) => {
    dispatch(fetchPlayerGameStatisticsRequest(gameId, playerId));

    axios.get(`/games/${ gameId }/players/${ playerId }/statistics`).then(response => {
        dispatch(fetchPlayerGameStatisticsSuccess(gameId, playerId, normalize(response.data, [playerGameStatisticsSchema])));
    }, error => {
        dispatch(fetchPlayerGameStatisticsFailure(gameId, playerId));
    });
}



/* Fetch Statistics By Round */

const fetchStatisticsByRoundRequest = (gameId, round) => ({ type: types.FETCH_STATISTICS_BY_ROUND_REQUEST, gameId, round });
const fetchStatisticsByRoundSuccess = (gameId, round, payload) => ({ type: types.FETCH_STATISTICS_BY_ROUND_SUCCESS, gameId, round, payload });
const fetchStatisticsByRoundFailure = (gameId, round) => ({ type: types.FETCH_STATISTICS_BY_ROUND_FAILURE, gameId, round });

const fetchStatisticsByRound = (gameId, round) => (dispatch) => {

    dispatch(fetchStatisticsByRoundRequest(gameId, round));
    
    axios.get(`/games/${ gameId }/rounds/${ round }/statistics`).then(response => {
        dispatch(fetchStatisticsByRoundSuccess(gameId, round, normalize(response.data, [playerRoundStatisticsSchema])));
    }, error => {
        dispatch(fetchStatisticsByRoundFailure(gameId, round));
    });
}




const shouldFetchStatisticsByRound = (state, gameId, round) => {
    
    const { isFetching, items, didInvalidate } = state.player.statisticsByRound[gameId] && state.player.statisticsByRound[gameId][round] || { items : null, isFetching: false, didInvalidate : false }
    


    if (isFetching) {
        return false;
    }    
    else if (!items) {
        return true;
    }
    
    else {
        return didInvalidate;
    }
  }
  
  
  export const fetchStatisticsByRoundIfNeeded = (gameId, round) => (dispatch, getState) => {
    if (shouldFetchStatisticsByRound(getState(), gameId, round)) {
        
        
        dispatch(fetchStatisticsByRound(gameId, round));
    }
  }


