import React, { useMemo } from 'react'

import { Datasource } from 'components'

import { matchesConditions } from 'utils';

const Filter = ({ editMode, input, filter, output, children }) => {


    if (editMode)
    {
        return (
            <Datasource value={{ [output || 'current'] : [{}, {}, {}, {}, {} ] }}>
                { children  }
            </Datasource>
        )
    }


    const items = useMemo(() => {
        console.debug(input);
        return (input || []).filter(i => matchesConditions(i, filter || []));
        
    }, [input, filter, output])


    return (
        <Datasource value={{ [output || 'current'] : items }}>
            { children  }
        </Datasource>
    )
}

export default Filter;