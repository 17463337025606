import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { exp } from 'utils';

import DataContext from '../../../components/DataContext'
import DataContextProvider from '../../../components/DataContextProvider'

const SetCurrentContext = ({ property, children }) => {
    const context = useContext(DataContext)

    const value = exp(property, context);

    return <DataContextProvider value={ value }>{ children }</DataContextProvider>

}
    
SetCurrentContext.defaultProps = {
    property : PropTypes.string.isRequired
}

export default SetCurrentContext;