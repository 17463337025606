import React from 'react';

import { ErrorBoundary, Stack } from 'components'

export const StackItemWidget = ({ children, ...otherProps }) => (
    <Stack.Item {...otherProps}>
        <ErrorBoundary>
            { children }
        </ErrorBoundary>
    </Stack.Item>

)


export const StackWidget = ({ children, ...otherProps }) => (
    <Stack {...otherProps}>
        { children }
    </Stack>

)
