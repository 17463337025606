import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from './atoms/Dropdown'
import { XButton } from './XButton'
import Icon from './Icon'
import Box from './Box'

import styled from 'styled-components'
import { space, layout, typography, border } from 'styled-system'



const defaultRenderer = (item) => {
    return (
        <Box pl="s400">{ item.label }</Box>
    )
}


const SelectList = ({ items, size, placeholder, value, onSelect, align, verticalAlign, expand, onRenderItem, onRenderSelectedItem,  ...otherProps }) => {
    const selectedItem = value && items.find(i => i.value === value);

    const mItems = useMemo(() => {
        return items.map(i => ({ ...i, selected : i.value === value }));
    }, [items, value])

    

    return (
        <Dropdown items={ mItems } align={ align } verticalAlign={ verticalAlign } selectable onSelect={ onSelect } expand={ expand } onRenderItem={ onRenderItem }>{ ({ open }) => (
            <XButton onClick={ open } size={ size } block={ expand } {...otherProps}>
                <Box display="flex" alignItems="center" width="100%">
                    <Box flex="1">
                        { selectedItem ? (onRenderSelectedItem ? onRenderSelectedItem(selectedItem) : defaultRenderer(selectedItem)) : <Box pl="s400">{ placeholder }</Box> }
                    </Box> 

                    <Icon type="triangleDown" color="siteForegroundMeta"></Icon>
                </Box>
            </XButton>
        )}</Dropdown>
    )     
}

SelectList.propTypes = {
    items : PropTypes.array.isRequired,
    value : PropTypes.any,
    placeholder : PropTypes.any,
    onSelect : PropTypes.func,
    onRenderItem : PropTypes.func,
    onRenderSelectedItem : PropTypes.func
}

SelectList.defaultProps = {
    items : [],
    onSelect : () => { },
    size : 'm'
}

export default SelectList;
