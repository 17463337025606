
import React, { useMemo } from 'react'
import { useTransition, animated, useSpring } from 'react-spring'

import * as styles from './CardToast.module.css'

const CardToast = ({ keySelector, toasts, render, children }) => {
    const items = useMemo(() => {
        return toasts.map((item, idx) => ({ ...item, y : idx * 36 }))
    }, [toasts]);

    const transition = useTransition(items, item => item.key , {
        from: { opacity: 0, transform: 'translate3d(0, -36px, 0)' },
        enter: ({ y }) => ({ opacity: 1,transform: 'translate3d(0, ' + y + 'px, 0)'}),
        leave: ({ y }) => ({ opacity: 0, transform: 'translate3d(0, ' + y + ', 0)' }),
        update: ({ y }) => ({ transform: 'translate3d(0, ' + y + 'px, 0)'})
    })

    const style = useSpring({
        opacity: items.length > 0 ? 1 : 0,
        height: (items.length * 36 + 8) + 'px'
    })
    
    return (
        <div className={ styles.container }>
            <div className={ styles.content }>
                { children }
            </div>
       
            <animated.div className={ styles.menu } style={ style }>
                {
                    transition.map(
                        ({ item, key, props })  => {
                            return (
                                <animated.div key={ keySelector(item) } className={ styles.item } style={ props }>
                                    {  render ? render(item) : item.label }
                                 
                                </animated.div>
                            )
                        }
                    )
                }
            </animated.div>
        </div>
    )
}




export default CardToast;