import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { hideModal } from '../redux/ui/actions';

import EnrollmentDialog from '../competitions/EnrollmentDialog'
import ResignDialog from '../competitions/ResignDialog'
//import ShareDialog from '../components/ShareDialog'
import ShareInviteDialog from '../competitions/ShareInviteDialog'

const ModalConductor = () => {

    const dispatch = useDispatch();
    const modal = useSelector(state => state.ui.modal);

    const close = () => {
        dispatch(hideModal());
    }

    if (modal == null) {

        return null;
    }

    switch(modal.name) {
        case 'share':
            return <ShareInviteDialog open={ true } { ...modal.props } onClose={ () => close() } />
        case 'enrollment':
            return <EnrollmentDialog open={ true } { ...modal.props } onClose={ () => close() } />
        case 'resign':
            return <ResignDialog open={ true } { ...modal.props } onClose={ () => close() } />            
        default:
            return null;
    }
    
    


};


export default ModalConductor;