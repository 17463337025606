// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wY5JoGzc21w2L_QjBZL4 {
    display: grid;
    
    grid-gap: 24px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ColumnLayout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;IAEb,cAAc;IACd,WAAW;AACf","sourcesContent":[".container {\r\n    display: grid;\r\n    \r\n    grid-gap: 24px;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `wY5JoGzc21w2L_QjBZL4`;
export default ___CSS_LOADER_EXPORT___;
