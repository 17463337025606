
import { createSelector } from 'reselect'

export const getNotifications = createSelector(
    state => state.notification.notifications,
    state => state.entities.notifications,
    (notifications,  notificationEntities) => {


        const items =  (notifications.items || []).map(item => {
            const notification = notificationEntities[item];

            return notification;
        });

        return {
            ...notifications,
            items
        }
    }
)
