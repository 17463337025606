import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Vignette.scss'

const Vignette = ({  size, strength, children }) => {

    const vignetteColor = `rgba(0, 0, 0, ${ strength })`;
    
    let gradients = [];
    
    if (size[0] >= 0) {
        gradients.push(`linear-gradient(to bottom, ${ vignetteColor }, transparent ${ size[0] * 100 }%)`);
    }

    if (size[1] >= 0) {
        gradients.push(`linear-gradient(to left, ${ vignetteColor }, transparent ${ size[1] * 100 }%)`);
    }

    if (size[2] >= 0) {
        gradients.push(`linear-gradient(to top, ${ vignetteColor }, transparent ${ size[2] * 100 }%)`);
    }

    if (size[3] >= 0) {
        gradients.push(`linear-gradient(to right, ${ vignetteColor }, transparent ${ size[3] * 100 }%)`);
    }

    const style = {
        backgroundImage : `${ gradients.join(',') }`
    }

    return (
        <div className={ styles.container }>
            { children }
            <div style={ style } className={ styles.vignette }></div>
        </div>
    )
}


Vignette.propTypes = {
    size : PropTypes.array.isRequired,
    strength : PropTypes.number.isRequired
}

Vignette.defaultProps = {
    size : [0.25, 0.25, 0.25, 0.25],
    strength : 0.5
}

export default Vignette;