import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as styles from './Scorebox.module.css'
import { useRef } from 'react'
import classNames from 'classnames'

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

const useDebounce = (value, delay) => {
    const time = useRef((new Date()).getTime());
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        let nTime = (new Date()).getTime();

        if ((nTime - time.current) > delay)
        {
            setDebouncedValue(value)
            time.current = (new Date()).getTime();
        }
        else  {


            let timeout = setTimeout(() => {
                setDebouncedValue(value);
                time.current = (new Date()).getTime();
            }, delay);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, [value]);

    return debouncedValue
}

const Digit = ({ value }) => {
    const ref = useRef();
    const _value = useDebounce(value, 2000);
    const previous = usePrevious(_value);

    useEffect(() => {
        if (ref.current) {
            const handleTransitionEnd = () => {
                ref.current.classList.remove(styles.transitioning);
                ref.current.style.transform = 'translate3d(0, -' + (_value * 100) + '%, 0)'
            }

            ref.current.addEventListener('transitionend', handleTransitionEnd);

            return () => {
                ref.current.removeEventListener('transitionend', handleTransitionEnd);
            }
        }
    }, [ref, _value]);

    
    useEffect(() => {
        ref.current.style.visibility = 'visible';

        if (typeof previous === 'undefined') {
            ref.current.style.transform = 'translate3d(0, -' + (_value * 100) + '%, 0)'            
        } else {

            if (_value < previous) {
                ref.current.style.transform = 'translate3d(0, -' + ((_value + 10) * 100) + '%, 0)' // rollover
            }
            else {
                ref.current.style.transform = 'translate3d(0, -' + (_value * 100) + '%, 0)'
            }

            ref.current.classList.add(styles.transitioning);
        }
    }, [_value])

    return (
        <div ref={ ref } className={ styles.digit }>
            <span>0</span>
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <span>6</span>
            <span>7</span>
            <span>8</span>
            <span>9</span>
            <span>0</span>
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <span>6</span>
            <span>7</span>
            <span>8</span>
            <span>9</span>
        </div>
    )
}


const Scorebox = React.memo(({ size, value }) => {
    const previousValue = useRef(value);

    const sArr = (value + '').split('');
    const [isHighlighted, setIsHightlighted] = useState(false);


    useEffect(() => {
        if (value !== previousValue.current && typeof value !== 'undefined' && typeof previousValue !== 'undefined') {
            previousValue.current = value;

            setIsHightlighted(true);

            const timeout = setTimeout(() => {
                setIsHightlighted(false);
            }, 3000);

            return () => {
                clearTimeout(timeout);
            }
        }

    }, [value]);

    const className = classNames(
        styles.container,
        {
            [styles.highlight] : isHighlighted,
            [styles.large] : size === 'l'
        }
    )


    return (
        <div className={ className }>
            {
                sArr.map((s, index) => {
                    return (<Digit key={ index } value={ parseInt(s) }/>)
                })
            }
            
        </div>
    )
})

Scorebox.propTypes = {
    type: PropTypes.string
}

export default Scorebox