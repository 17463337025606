import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import {  exp } from 'utils';
import DataContext from '../../../components/DataContext'
import * as Components from 'components';

const EventDate = ({ editMode, value, defaultValue, ...otherProps }) => {
    if (editMode) {
        return "Event Date";
    }


    return (value && <Components.EventDate date={ value } { ...otherProps }/>) || defaultValue;
}

EventDate.propTypes = {
    value : PropTypes.string.isRequired,
    defaultValue : PropTypes.string.isRequired
}

EventDate.defaultProps = {
    value : '',
    defaultValue : '-'
}

export default EventDate;