import React from 'react'
import PropTypes from 'prop-types'


import classNames from 'classnames'

import * as styles from './ValueChangeNotification.scss'


class ValueChangeNotification extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            nValue : props.value,
            pValue : props.value,
            isChanging : false
        }

        this.timer = null;
        this.reset = this.reset.bind(this);
    }


    componentDidMount() {
        this.setState({ 
            nValue : this.props.value,
            pValue : this.props.value,
            isChanging : false
        })
    }

    compare(a, b) {
        if (this.props.compare) {
            return this.props.compare(a, b);
        }
        else {
            return a === b;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (typeof(this.props.value) === 'undefined') {
            this.setState({ nValue : nextProps.value, pValue : nextProps.value, isChanging : false });
        }
        
        else if (!this.compare(nextProps.value, this.props.value)) {

            if (this.state.isChanging) {
                this.setState({ nValue : nextProps.value });
            }
            else {
                this.setState({ pValue : this.props.value, nValue : nextProps.value, isChanging : true });
            }

            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(this.reset, this.props.time);
        }
    }

    reset() {
        this.setState({ isChanging : false });
        this.timer = null;
    }


    render() {

        const className = classNames(
            styles.container,
            styles.wipe
        )
    
        return (
            <div className={ className }>
       
                    <div className={ styles.changeContainer }>
                        <div className={ styles.effect0 }></div>
                        <div className={ styles.change }>
                            { this.props.render(this.state.nValue, this.state.pValue) }
                        </div>
                    </div>
                
            </div>
        )
    }

}


ValueChangeNotification.propTypes = {
    value : PropTypes.any.isRequired,
    time : PropTypes.number.isRequired,
    render : PropTypes.func.isRequired,
    compare : PropTypes.func
}

ValueChangeNotification.defaultProps = {
    time : 3000
}
  

export default ValueChangeNotification;