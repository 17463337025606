import { createSelector } from 'reselect'

import * as types from './types'

const defaultState = { 
    byTournament : {},
};


export default function schedule (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_SCHEDULE_REQUEST:
            return { 
                ...state,
                byTournament : {
                    ...state.byTournament,
                    [action.tournament] : {
                        ...state.byTournament[action.tournament],
                        isFetching : true,
                        isLoaded : false
                    }
                }
            };
        case types.FETCH_SCHEDULE_SUCCESS:
            return { 
                ...state,
                byTournament : {
                    ...state.byTournament,
                    [action.tournament] : {
                        ...state.byTournament[action.tournament],
                        isFetching : false,
                        isLoaded : true,
                        items : action.payload.result
                    }
                }
            };
        default:
            return state
    }
}

/* Selectors */