import React from 'react'
import PropTypes from 'prop-types'

import VersusSchedule from '../VersusSchedule'
import GameContext from '../GameContext'
import SoccerMatchCard from './SoccerMatchCard'
import GameNavigationService from '../GameNavigationService'

const SoccerSchedule = ({ hideGroup }) => {
    return (
        <GameNavigationService>{ ({ showMatch }) => (
            <GameContext.Consumer>{ ({  game, gameConfiguration }) => (
                <VersusSchedule game={ game } gameConfiguration={ gameConfiguration }>
                    { 
                        ({ item }) => <SoccerMatchCard key={ item.id } item={ item }   hideGroup={ hideGroup } onClick={ () => showMatch(item.id) } /> 
                    }
                </VersusSchedule>
            )}</GameContext.Consumer>
        )}</GameNavigationService>
    )
}

SoccerSchedule.propTypes = {
    hideGroup : PropTypes.bool
}

SoccerSchedule.defaultProps = {
    hideGroup : false
}

export default SoccerSchedule;