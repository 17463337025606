// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("geomanist-light-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2016 */



@font-face {
    font-family: "geomanist";
    src: local('Geomanist Light'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
    font-weight: 300;
    font-style: normal; 
    font-display: swap;
}`, "",{"version":3,"sources":["webpack://./src/fonts/Geomanist-Light-Webfont/stylesheet.css"],"names":[],"mappings":"AAAA,iFAAiF;;;;AAIjF;IACI,wBAAwB;IACxB,qFAAiF;IACjF,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["/* Generated by Font Squirrel (https://www.fontsquirrel.com) on October 7, 2016 */\r\n\r\n\r\n\r\n@font-face {\r\n    font-family: \"geomanist\";\r\n    src: local('Geomanist Light'), url('geomanist-light-webfont.woff') format(\"woff\");\r\n    font-weight: 300;\r\n    font-style: normal; \r\n    font-display: swap;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
