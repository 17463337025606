import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { contextMatchesConditions, exp } from 'utils';
import DataContext from '../../../components/DataContext'

const If = ({ conditions, children }) => {
    const context = useContext(DataContext)
    

    if (contextMatchesConditions(context, conditions)) {
        return children;
    }
    else 
        return null;
}

If.propTypes = {
    conditions : PropTypes.array.isRequired
}

If.defaultProps = {
}

export default If;