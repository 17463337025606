import React, { useEffect } from 'react'

import { getConfigurationSection } from 'utils'
import GameContext from './GameContext'
import WithFlyout from './WithFlyout'

const LinkHijacker = ({ gameConfiguration, push }) => {
    const clickHandler = (e) => {
        e.preventDefault();
        onShowMatch(e.target.dataset.matchId);
    }


    const hijack = () => {
        const hijackSection = getConfigurationSection(gameConfiguration, 'hijack', window.swush.profile);
        if (!hijackSection)
            return;

        const links = document.getElementsByTagName("a");
        
        for(let i = 0; i < links.length; i++) {
            var link = links[i];
            const href = link.getAttribute("href");
            if (!href)
                continue;

            for(let j = 0; j < hijackSection.links.length; j++) {
                const hijack = hijackSection.links[j];
                const match = new RegExp(hijack.href).exec(href);

                if (match) {

                    if (link.dataset['hijacked'])
                        continue;

                    if (hijack.flyout) {
                        const props = Object.keys(hijack.flyout.props || {}).reduce((agg, key) => {
                            const pVal = hijack.flyout.props[key];
                            
                            if (typeof pVal == "number") {
                                // A number means a capturing group
                                agg[key] = match[pVal];
                            }
                            else if (link.dataset[pVal + '']) {
                                // A string/other means data attribute
                                agg[key] = parseInt(link.dataset[pVal + '']);

                            }
                            
                            return agg;
                        }, {});
                        
                        link.onclick = (e) => {
                            e.preventDefault();
                            push(hijack.flyout.name, props);
                        }

                        
                        link.dataset['hijacked'] = true;

                    }

                    
                }
                
                
            }
            
        }
    }

    useEffect(() => {
        hijack();

        window.addEventListener('UIChange', hijack);

        return () => {
            window.removeEventListener('UIChange', hijack);
        }
    }, []);

    

    return null;
}


const WrappedLinkHijacker = () => (
        <WithFlyout>{ ({ push }) => (
            <GameContext.Consumer>{ ({ gameConfiguration }) => {
                if (!gameConfiguration)
                    return null;

                return (<LinkHijacker gameConfiguration={ gameConfiguration } push={ push }  />)
            }}</GameContext.Consumer>
        )}</WithFlyout>
)


export default WrappedLinkHijacker;