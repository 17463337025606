import React from 'react'
import ReactDOM from 'react-dom'


class Portal extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
    }
    
    componentWillUnmount() {
    }
    
    
    render() {
        
        
        const el = document.querySelector(this.props.selector);

        if (el)
            return ReactDOM.createPortal(this.props.children, el);
        else
            return null;
    }

}


export default Portal;