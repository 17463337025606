import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './PersonName.scss'

const ShortPersonName = ({ person }) => (
    ((person.lastname + '').length > 0) ? `${ person.firstname[0] }. ${ person.lastname }` : person.firstname
)

const FullPersonName = ({ person }) => (
    ((person.lastname + '').length > 0) ? `${ person.firstname } ${ person.lastname }` : person.firstname
)

const AutoPersonName = ({ person }) => (
    <span>
        <span className={ styles.abbreviated }>
            <ShortPersonName person={ person } />
        </span>
        <span className={ styles.full }>
            <FullPersonName person={ person } />
        </span>
    </span>
)

class PersonName extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { form, person } = this.props;

        if (!person)
            return <span>&hellip;</span>;
        else if (form === 'auto') {
            return <AutoPersonName person={ person }/>
        }
        else if (form === 'short') {
            return <ShortPersonName person={ person }/>
        }
        else if (form === 'full') {
            return <FullPersonName person={ person }/>
        }
        else {
            return <FullPersonName person={ person }/>;
        }
    }  
}

PersonName.propTypes = {
    person: PropTypes.object,
    form: PropTypes.string.isRequired,
}

PersonName.defaultProps = {
    form : 'auto'
}

export default PersonName;
