import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { HorizontalMenu, MenuItem } from './Menu'

import * as styles from './SiteFooter.scss'



const SiteFooter = ({ content, menuItems, flair }) => { 

    if (window.swush.views && window.swush.views.footer) {
        return <ViewContainer name={ window.swush.views.footer } />
    }


    return (
        <div className={ styles.siteFooter }>
            {
                menuItems && 
                <HorizontalMenu>
                    {
                        menuItems.filter(m => m.type === 'secondary').map((i, index) => {
                            return <MenuItem key={ index }><a href={ i.url }>{ i.label }</a></MenuItem>
                        })
                    }
                </HorizontalMenu>
            }

            <div className={ styles.container }>
                <div className={ styles.copyright }  dangerouslySetInnerHTML={{__html: content }} ></div>
                <div className={ styles.flair }>
                    { 
                        flair.map((f, i) => { return (
                            <a key={ i } href={ f.url} target="_new">
                                <img src={ f.imageUrl } />
                            </a>
                        )})
                    }
                </div>
            </div>
        </div>
        )
}


SiteFooter.propTypes = {
    content : PropTypes.string,
    menuItems : PropTypes.array,
    flair : PropTypes.array
}

SiteFooter.defaultProps = {
}

export default SiteFooter;