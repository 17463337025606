import React, { useState } from 'react'

import { 
    XButton,
    Dialog, DialogHeader, DialogContent, DialogFooter,
    RadioButtonList
} from 'components'
import { useTranslator } from '../../hooks/useTranslator'


const ReportDialog = ({ open, title, text, onClose, onCancel, onConfirm }) => {
    const [value, setValue] = useState('other')
    const t = useTranslator();

    const items = [
        { value : 'spam', text : t('messageboard.report_type_spam') },
        { value : 'harrasment', text : t('messageboard.report_type_harrasment') },
        { value : 'violence', text : t('messageboard.report_type_violence') },
        { value : 'unauthorized_sales', text :t('messageboard.report_type_unauthorized_sales') },
        { value : 'other', text : t('messageboard.report_type_other') }
    ]

    const handleCancel = () => {
        if (onCancel)
            onCancel();
        if (onClose)
            onClose();
    }

    const handleConfirm = () => {
        if (onConfirm)
            onConfirm(value);
        if (onClose)
            onClose();
    }

    return (
        <Dialog open={ open } onClose={ handleCancel }>
            <DialogHeader>{ title }</DialogHeader>
            <DialogContent>
                <RadioButtonList value={ value } items={ items } onChange={ value => setValue(value) } />
            </DialogContent>
            <DialogFooter>
                <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ handleCancel }>{ t('common.cancel_caption') }</XButton>
                <XButton variant="primary" size="s" textTransform="uppercase" onClick={ handleConfirm }>{ t('common.ok_caption') }</XButton>
            </DialogFooter>
        </Dialog>
    )    
}


export default ReportDialog;