import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './Stack.scss'


const StackItem = ({ style, children }) => {

        return (
            <div style={ style }>
                { children }
            </div>
        )
}

StackItem.propTypes = {


}

StackItem.defaultProps = {
};


class Stack extends React.PureComponent {
    static Item = StackItem;

    render() {
        
        const style = {
            display : 'flex',
            flexDirection : this.props.orientation === 'horizontal' ? 'row' : 'column'
        }

        return (
            <div  style={ style }>
                { this.props.children }
            </div>
        )
    }  
}

Stack.propTypes = {
    orientation : PropTypes.oneOf([ 'horizontal', 'vertical' ]).isRequired
}

Stack.defaultProps = {
    orientation : 'horizontal'
};

export default Stack;