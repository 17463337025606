import React from 'react';

import { Modal, AlertBox } from 'components'

import * as styles from './AuthenticationModal.module.css'


export const AuthenticationHeader = ({ children }) => {
    return <h1 className={ styles.header }>{ children }</h1>
}

export const AuthenticationModal = ({ coverImageUrl, open, onClose, error, children, canClose = true }) => {
    return (
        <Modal open={ open } onClose={ onClose } canClose={ canClose } canCloseOnBackgroundClick={ false }>
            <div className={ styles.container }>
                <div className={ styles.cover } style={{ backgroundImage : `url(${ coverImageUrl })` }}></div>
                <div>
                    { error && <AlertBox>{ error }</AlertBox> }

                    <div className={ styles.content }>
                        <div className={ styles['content-inner'] }>
                            { children }
                       </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}