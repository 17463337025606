import React from 'react'

class SidebarMain extends React.PureComponent {

    render() {
        

        return (
            <div className="sidebar__main">
                { this.props.children }
                <div className="sidebar__cover" onClick={this.props.onClose}></div>
            </div>
        )     
    }
}


SidebarMain.propTypes = {
}

SidebarMain.defaultProps = {
}

export default SidebarMain;