import React from 'react'
import PropTypes from 'prop-types'
import {  number } from '../common/utils';

import ScrollContainer from './ScrollContainer';
import EmptyResultsetPlaceholder from './EmptyResultsetPlaceholder'

class PlayerProfileStatisticsPane extends React.PureComponent {
    renderRow(stat, index) {
        const eventMap = stat.events.reduce((acc, evt) => { acc[evt.type.name] = evt.amount; return acc; }, {});



        return (
            <tr key={ index }>
                
                { this.props.columns.map((c, idx) => <td key={ idx } className="text-right">{ number(eventMap[c.type]) }</td>) }
            </tr>
        )
    }

    render() {

        
        return (
            <div className="mt">
                <EmptyResultsetPlaceholder showPlaceholder={ this.props.statistics && this.props.statistics.length === 0 } icon="magnify" placeholder="Der er ingen statistik for dette spil endnu." >
                    <ScrollContainer>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    { this.props.columns.map((column, index) => <th className="text-right" key={ index }>{ column.header }</th>) }
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.statistics.map((s, idx) =>this.renderRow(s, idx) )}
                            </tbody>
                        </table>
                    </ScrollContainer>
                </EmptyResultsetPlaceholder>
            </div>
        )     
    }
}


PlayerProfileStatisticsPane.propTypes = {
    statistics : PropTypes.array.isRequired,
    columns : PropTypes.array.isRequired,
    config : PropTypes.object.isRequired
}


PlayerProfileStatisticsPane.defaultProps = {
    statistics : [],
    columns : []
}

export default PlayerProfileStatisticsPane;