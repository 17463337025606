import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { exp } from 'utils';
import DataContext from '../../../components/DataContext'

const Json = ({  property }) => {
    const context = useContext(DataContext)
    const value = property.length > 0 ? exp(property, context) : context;
    return typeof value === 'object' ? JSON.stringify(value) : value + '';
}

Json.propTypes = {
    property : PropTypes.string.isRequired
}

Json.defaultProps = {
    property : ''
}

export default Json;