import axios from 'axios'
import { normalize } from 'normalizr';

import * as types  from './types';

import { matchSchema, eventSchema, fantasyEventSchema } from '../../schemas';

/* Fetch Match */

const fetchMatchRequest = (id) => ({ type: types.FETCH_MATCH_REQUEST, id });
const fetchMatchSuccess = (id, payload) => ({ type: types.FETCH_MATCH_SUCCESS, id, payload });
const fetchMatchFailure = (id) => ({ type: types.FETCH_MATCH_FAILURE, id });

const fetchMatch = (id) => (dispatch) => {
    dispatch(fetchMatchRequest(id))

    axios.get(`matches/${ id }`).then(response => {
        dispatch(fetchMatchSuccess(id, normalize(response.data, matchSchema)));
    }, error => {
        dispatch(fetchMatchFailure(id));
    });
}

const shouldFetchMatch = (state, id) => {
    const item = state.match.byId[id];
  
    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
  }
  
  
export const fetchMatchIfNeeded = (id) => (dispatch, getState) => {
    if (shouldFetchMatch(getState(), id)) {
        dispatch(fetchMatch(id));
    }
}


/* Fetch Match Events */

export const invalidateMatchEvents = (match) => ({ type: types.INVALIDATE_MATCH_EVENTS, match });
const fetchMatchEventsRequest = (match) => ({ type: types.FETCH_MATCH_EVENTS_REQUEST, match });
const fetchMatchEventsSuccess = (match, payload) => ({ type: types.FETCH_MATCH_EVENTS_SUCCESS, match, payload });
const fetchMatchEventsFailure = (match) => ({ type: types.FETCH_MATCH_FANTASY_EVENTS_FAILURE, match });



const fetchMatchEvents = (match) => (dispatch) => {
    dispatch(fetchMatchEventsRequest(match))

    axios.get(`/matches/${ match }/events`).then(response => {
        dispatch(fetchMatchEventsSuccess(match, normalize(response.data, [eventSchema])));
    }, error => {
        dispatch(fetchMatchEventsFailure(match));
    });
}

const shouldFetchMatchEvents = (state, id) => {
    const item = state.match.events[id];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else if (typeof item.isFetching == 'undefined') {
        return true;
    }
    else {
        return item.didInvalidate;
    }
  }
  
  
  export const fetchMatchEventsIfNeeded = (id) => (dispatch, getState) => {
    
    
    if (shouldFetchMatchEvents(getState(), id)) {
        dispatch(fetchMatchEvents(id));
    }
  }




/* Fetch Match Fantasy Events */

export const invalidateMatchFantasyEvents = (match) => ({ type: types.INVALIDATE_MATCH_FANTASY_EVENTS, match });
const fetchMatchFantasyEventsRequest = (match) => ({ type: types.FETCH_MATCH_FANTASY_EVENTS_REQUEST, match });
const fetchMatchFantasyEventsSuccess = (match, payload) => ({ type: types.FETCH_MATCH_FANTASY_EVENTS_SUCCESS, match, payload });
const fetchMatchFantasyEventsFailure = (match) => ({ type: types.FETCH_MATCH_FANTASY_EVENTS_FAILURE, match });

const fetchMatchFantasyEvents = (match) => (dispatch) => {
    dispatch(fetchMatchFantasyEventsRequest(match))

    axios.get(`matches/${ match }/fantasyevents`).then(response => {
        dispatch(fetchMatchFantasyEventsSuccess(match, normalize(response.data, [fantasyEventSchema])));
    }, error => {
        dispatch(fetchMatchFantasyEventsFailure(match));
    });

}

const shouldFetchMatchFantasyEvents = (state, id) => {
    const item = state.match.fantasyEvents[id];
  
    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
  }
  
  
  export const fetchMatchFantasyEventsIfNeeded = (id) => (dispatch, getState) => {
    if (shouldFetchMatchFantasyEvents(getState(), id)) {
        dispatch(fetchMatchFantasyEvents(id));
    }
  }
