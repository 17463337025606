import React from 'react';

import { Grid } from 'components'

export const GridItemWidget = ({ children, ...otherProps }) => (
    <Grid.Item {...otherProps}>
        { children }
    </Grid.Item>

)


export const GridWidget = ({ children, ...otherProps }) => (
    <Grid {...otherProps}>
        { children }
    </Grid>

)
