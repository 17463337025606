import React from 'react'
import PropTypes from 'prop-types'

class GridView extends React.PureComponent {

    render() {
        const style = {
            display : 'grid',
            gridTemplateColumns : this.props.columns.join(' ')
        }

        if (this.props.gap) {
            style.gridGap = this.props.gap;
        }


        return (
            <div style={ style }>
                { this.props.children }
            </div>
        )     
    }
}


GridView.propTypes = {
    columns : PropTypes.array,
    gap : PropTypes.string
}

GridView.defaultProps = {
    columns : []
}

export default GridView;