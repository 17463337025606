import React, { useContext } from 'react'

import { TopBar, Icon } from 'components'
import { ToolbarButton } from '../../../components/ToolbarButton'

import PlayerContext from '../../PlayerContext'
import WithPlayer from '../../WithPlayer'

import { fullName, lo } from 'utils'

import WithWatchlist from '../../WithWatchlist'

const WatchlistButton = ({ isAuthenticated, onWatchlist, isChanging, onAdd, onRemove }) => {

    const handler = isChanging ? () => {} : onWatchlist ? onRemove : onAdd;
    const icon = isChanging ? 'waitAnimated' : onWatchlist ? 'heart' : 'heartOutline'

    if (!isAuthenticated)
        return null;
    else {
        return <ToolbarButton mx="s" onClick={ handler }><Icon type={ icon } /></ToolbarButton>
    }
}

const IndentityTopbar = ({ editMode }) => {
    const { id } = useContext(PlayerContext);
    
    if (editMode) {
        return  <TopBar>
                    <div>
                        <div>Player Name</div>
                        <div><small>Position - Team</small></div>
                    </div>
                </TopBar>
    }



        
        return (
            <TopBar>
                
                    <WithWatchlist>{ ({ watchlist, addToWatchlist, removeFromWatchlist }) => (
                        <WithPlayer id={ id }>{ (player) => {
                            if (!player)
                                return null;

                            const onWatchlist = watchlist.indexOf(id) >=0;

                            const position = player && player.position && lo(player.position, 'name');
                            const team = player && player.team && lo(player.team, 'name');

                            
                            return (
                                <div style={{ display: 'flex', flexDirection : 'row', alignItems : 'center' }}>
                                    <WatchlistButton 
                                        isAuthenticated={ true } 
                                        onWatchlist={ onWatchlist }
                                        onAdd={ () => addToWatchlist(id) }
                                        onRemove={ () => removeFromWatchlist(id) }
                                    />
                                    <div>
                                        <div>{ fullName(player.person) }</div>
                                        <div><small>{ `${position} - ${team}` }</small></div>
                                    </div>
                                </div>
                            )
                        }}</WithPlayer>
                    )}</WithWatchlist>
                
            </TopBar>
        )
    
}


export default IndentityTopbar;