import React from 'react';

import MatchContext from '../../MatchContext'

import { CoverImage } from 'components'

const LocationCoverImage = ({ children, ...otherProps }) => (

    <MatchContext.Consumer>{ (match) => {

        return (
            <CoverImage url={ match.location.imageUrl  } {...otherProps}>
                { children }
            </CoverImage>
        )
        
    }}</MatchContext.Consumer>

)

export default LocationCoverImage;