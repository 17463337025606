import React from 'react';
import PropTypes from 'prop-types'

import * as styles from './Banner.scss'

const Banner = ({ children }) => (
    <div className={ styles.banner }>{ children }</div>
)

Banner.propTypes = {
    children : PropTypes.node
}

export default Banner;