import React from 'react'
import PropTypes from 'prop-types'

import GameContext from './GameContext'
import WithPlayerRoundStatistics from './WithPlayerRoundStatistics';
import WithWatchlist from './WithWatchlist'

import { fullName } from 'utils';

class WithTournamentStatistics extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.shape = this.shape.bind(this);
    }


    shape(props) {
        const players = props.tournament.players.filter(p => p.active && p.team.active ).map(player => {
            const statistics = props.statistics[player.id] || {};

            return {
                player : {
                    ...player,
                    person : {
                        ...player.person,
                        fullName : fullName(player.person)
                    }
                },
                watchlist : (props.watchlist ||[]).indexOf(player.id) >= 0,
                ...statistics,
                disabled : player.eliminated || player.team.eliminated || !player.active || !player.team.active
            }
        });

        return players;        
    }

    componentDidMount() {
        const items = this.shape(this.props);
        this.setState({ items });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.game !== this.props.game || nextProps.tournament !== this.props.tournament || 
            nextProps.statistics !== this.props.statistics || nextProps.watchlist !== this.props.watchlist)
        {

            const items = this.shape(nextProps);
            this.setState({ items })
        }
    }

    render() {
        return this.props.children({ 
            items : this.state.items
        });
    }
}

WithTournamentStatistics.propTypes = {
    game : PropTypes.object.isRequired,
    tournament : PropTypes.object.isRequired,
    statistics : PropTypes.object.isRequired,
    watchlist : PropTypes.array.isRequired
}

WithTournamentStatistics.defaultProps = {
    watchlist : []
}

const WithTournamentStatisticsWrapper = ({ round, ...otherProps }) => (
    <GameContext.Consumer>{ ({ tournament, game, ruleset }) => (
        <WithWatchlist>{ ({ watchlist }) => (
            <WithPlayerRoundStatistics gameId={ game.id } round={ round }>{ (statistics) => {
                if (!tournament)
                    return null;

                return (
                    <WithTournamentStatistics  tournament={ tournament } game={ game }  ruleset={ ruleset }  statistics={ statistics } watchlist={ watchlist } { ...otherProps } />
                )
            }}</WithPlayerRoundStatistics>
        )}</WithWatchlist>
    )}</GameContext.Consumer>
)

WithTournamentStatisticsWrapper.propTypes = {
    round : PropTypes.number.isRequired
}

export default WithTournamentStatisticsWrapper;


