import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Switch, Route, withRouter, useLocation } from 'react-router-dom'
import { Slot, ErrorBoundary, CSSVariables, useBreakpoint } from '../components';
import { ViewMappingProvider  } from './ViewContainer'
import ViewContainer from './ViewContainer'
import { selectGame } from '../redux/game/actions'
import GameContext from './GameContext'
import Competition from '../competitions/Competition'
import Contests from '../competitions/Contests'
import useMediaQuery from '../hooks/useMediaQuery';

import MyTeams from './MyTeams';
import Highscore from './Highscore';
import Competitions from './Competitions';
import Schedule from './Schedule';
import Statistics from './Statistics';
import TopBanner from './TopBanner';
import { RequestAds, useAdConsent } from '../components/ads';
import ReactDOM from 'react-dom'

import { AdSlot } from '../components/ads';
import useGameConfig from './useGameConfig';

function ContentBanner() {
    const gameConfig = useGameConfig();
    const { breakpoint } = useBreakpoint();
    const consent = useAdConsent();

    if (window.swush.appId != 'holdet')
        return null;

    if (typeof consent == 'undefined')
        return null;

        const el = document.getElementById('content-ad');

        if (consent) {
            if (breakpoint == 'desktop') {
                return (
                    
                    ReactDOM.createPortal(
                        <AdSlot adUnit="/83172459/holdet/holdet_mediumrectangle" size={ [[300, 250]] } targeting={ gameConfig.targeting } />
                    , el)
                )
            }
            else {
                return (
                    
                    ReactDOM.createPortal(
                        <AdSlot adUnit="/83172459/holdet/holdet_swedishtakeover1" size={ [[300, 250], [320, 320], [320, 160]] } targeting={ gameConfig.targeting } />
                    , el)
                )
            }
        } else {
            if (breakpoint == 'desktop') {
                return (
                    ReactDOM.createPortal(
                        <AdSlot adUnit="/83172459/holdet/holdet_noconsent_mediumrectangle" size={ [[300, 250]] } targeting={ gameConfig.targeting } />
                    , el)
                )
            }
            else {
                return (
                    
                    ReactDOM.createPortal(
                        <AdSlot adUnit="/83172459/holdet/holdet_noconsent_swedishtakeover1" size={ [[300, 250], [320, 320], [320, 160]] } targeting={ gameConfig.targeting } />
                    , el)
                )
            }
        }
}







const ViewFantasyTeam = () => {
    const matches = useMediaQuery('screen and (min-width: 1024px)');

    if (typeof matches !== 'boolean')
        return null;

    const viewName = matches ? 'fantasyteam' : 'fantasyteam.mobile';

    return (
        <>
            <TopBanner/>

            <ViewContainer key={ viewName } name={ viewName } /> 
            <ContentBanner />
            <RequestAds />
        </>
    );
}

export function Game({ match, children }){
   
    const dispatch = useDispatch();

    useEffect(() => {


        dispatch(selectGame(match.params.gameId));
        //selectGame(parseInt(match.params.gameId));
    }, [match.params.gameId])

        return (
            
            <GameContext.Consumer>
                
                {
                    ({ game, gameConfiguration }) => (
                            <CSSVariables className="game-wallpaper" vars={ (gameConfiguration && gameConfiguration.theme) || {} }>
                                { 
        

                                    game && gameConfiguration && 
                                        <div style={{ maxWidth : '1200px', margin : '0 auto' }}>
                                            
                                            
                                            
                                            <ErrorBoundary>
                                                <ViewMappingProvider map={ gameConfiguration.viewMapping }>
                                                
                                                    <Switch>
                                                        <Route path="/:language/games/:gameSlug/:gameId/competitions/:competitionSlug/:competitionId" render={ () => <Competition /> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/competitions" exact={ true } render={ () => <Competitions><ViewContainer name="competitions" /></Competitions> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/contests" exact={ true } render={ () => <Contests /> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/fantasyteams/:fantasyTeamSlug-:fantasyteamId" render={ () => <ViewFantasyTeam /> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/highscore" exact={ true } render={ () => <Highscore>{ children }</Highscore> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/teams" exact={ true } render={ () => <MyTeams>{ children }</MyTeams> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/schedule" exact={ true } render={ () => <Schedule>{ children }</Schedule> } />
                                                        <Route path="/:language/games/:gameSlug/:gameId/statistics" exact={ true } render={ () => <Statistics>{ children }</Statistics> } />
                                                        <Route render={ () => children } />
                                                    </Switch>

                                                </ViewMappingProvider>
                                            </ErrorBoundary>
                                            

                                        </div>
                                }
                            </CSSVariables>
                    )
                }
                
            </GameContext.Consumer>
        )
    
}


export default 
    withRouter(Game);