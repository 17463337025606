import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'


import  styled from 'styled-components'
import { useTransition, animated } from 'react-spring'

const Container = styled.div`
    display: flex;

    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    align-items: center;
    justify-content: center;

    z-index: 3000;
`

const Background = styled(animated.div)`
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: var(--modal-background-color, rgba(0, 0, 0, 0.6));
    z-index: 3001;
`

const Window = styled(animated.div)`
    background-color: ${ props => props.theme.colors.siteBackground };
    color: ${ props => props.theme.colors.siteForeground };

    box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
    
    will-change: transform, opacity;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    width: 100%;
    height: 100%;

    z-index: 3002;

    &:focus {
        outline: none;
    }

    @media screen and (min-width: 768px) {
        width: auto;
        height: auto;
        
        max-height: 80vh;
        max-width: 640px;
        min-width: 480px;
        min-height: 240px;
        
        border-radius: var(--border-radius);
    }
`

const Close = styled.a`
    font-size: 24px;
    font-weight: bold;
    position: absolute;
    right: 24px;
    top: 24px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    color: var(--modal-window-text-color);
    z-index: 3001;
    cursor: pointer;
`

export const ModalHeader = styled.div`
    height: calc(var(--vertical-rythm) -1px);
    min-height: var(--vertical-rythm);
    line-height: 47px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding: 0 var(--padding);
`

const Footer = styled.div`
    height: var(--vertical-rythm);
    min-height: var(--vertical-rythm);
    padding: 0 var(--padding);
    
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`


export const ModalWindow = ({ open, heading, footer, children, preventKeyboard, onClose, canClose = true, canCloseOnBackgroundClick = true }) => {

    const handleKeyDown = useCallback((e) => {
        if (e.code === 'Escape' && !preventKeyboard) {
            onClose();
        }
    })


    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [handleKeyDown])



    const transition = useTransition(open, null, {
        from: { opacity: 0, transform: 'translate3d(0, 24px, 0)' },
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -24px, 0)' },
        config : { mass : 0.1, tension: 250, friction: 20 }
    });

    const internalOnClose = () => {
        if (canClose)    {
            onClose();
        }
    }

    const handleBackgroundClick = () => {
        if (canClose && canCloseOnBackgroundClick)  {
            onClose();
        }
    }


    return (
        <React.Fragment>
            {
                transition.map(({ item, key, props }) => item && (
                    <Container key={ key }>
                        <Background style={{ opacity : props.opacity }} onClick={ handleBackgroundClick }></Background>
                        <Window style={{ opacity : props.opacity, transform : props.transform }} >
                            { canClose && <Close onClick={ internalOnClose }>&times;</Close> }
                            { heading && <ModalHeader>{ heading }</ModalHeader>}
                            { children }
                            { footer && <Footer>{ footer }</Footer> }
                        </Window>
                    </Container>
                ))
            }
        </React.Fragment>
    )
}

ModalWindow.propTypes = {
    open : PropTypes.bool,
    onClose : PropTypes.func
}

ModalWindow.defaultProps = {
    open : false,
    preventKeyboard : false
}

