import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'

import { invalidateMatchEvents, fetchMatchEventsIfNeeded } from '../redux/match/actions';

class WithMatchEvents extends React.PureComponent {

    constructor(props) {
        super(props);
        
    }



    componentDidMount() {
        if (this.props.enabled) {
            this.props.fetch(this.props.id);
        }


    }



    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id && nextProps.enabled) {
            this.props.fetch(nextProps.id);
        }
    }

    render() {
        const { matchEvents, children } = this.props;


        return children({ isLoaded : matchEvents && matchEvents.isLoaded, events : (matchEvents && matchEvents.items) || [] });
    }
}

export const makeGetMatchEvents = () => createSelector([
        (state, props) => props.id,
        (state) => state.match.events,
        (state) => state.entities.events,
        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.teams,
        (state) => state.entities.positions,
        (state) => state.entities.eventTypes
    ],
    (id, matchEvents, eventEntities, playerEntities, personEntities, teamEntities, positionEntities, eventTypeEntities) => {
        const list = (matchEvents[id] || {}).items || [];

        let events = list.map(eventId => {
            const e = eventEntities[eventId];
            const player = playerEntities[e.player];
            const person = player && personEntities[player.person];
            const team = player && teamEntities[player.team];
            const position = player && positionEntities[player.position];

            return {
                ...e,
                type : eventTypeEntities[e.type],
                player : {
                    ...player,
                    person : person,
                    team : team,
                    position : position
                }
            }
        });

        return {
            ...matchEvents[id],
            items : events
        }
    }
)



const makeMapStateToProps = () => {
    const getMatchEvents = makeGetMatchEvents();
    return (state, ownProps) => ({
        matchEvents : getMatchEvents(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id, force) {
            if (force) {
                dispatch(invalidateMatchEvents(id));
            }
            dispatch(fetchMatchEventsIfNeeded(id));
        }
    }
}

WithMatchEvents.propTypes = {
    id : PropTypes.any.isRequired,
    enabled : PropTypes.bool
}

WithMatchEvents.defaultProps = {
    enabled : true
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithMatchEvents);

