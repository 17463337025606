import { createSelector } from 'reselect'

export const getMe = createSelector(
    state => state.user.me,
    state => state.user.byId,
    (me, userEntities) => {



        return me && userEntities[me]
    }
);