import React from 'react';

import * as styles from './Spinner.scss'

import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    0% { 
        transform: perspective(120px) rotateX(0deg) rotateY(0deg); 
    } 
    50% { 
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    } 
    100% { 
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
`


const StyledContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledSpinner = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${ props => props.theme.colors.siteForeground };
  
    animation: ${ rotate } 1.2s infinite ease-in-out;
`


const Spinner = () => (
    <StyledContainer>
        <StyledSpinner></StyledSpinner>
    </StyledContainer>
)

export default Spinner;