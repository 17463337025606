import React from 'react'
import PropTypes from 'prop-types'

import { number, percent } from 'utils'

const FormattedValue = ({ value, format }) => {
    switch (format) {
        case 'number':
            return number(value);
        case 'percent':
            return percent(value);
        default:
            return value + '';

    }

}


FormattedValue.propTypes = {
    value : PropTypes.any,
    format : PropTypes.string.isRequired
}

FormattedValue.defaultProps = {
    format : 'text'
}

export default FormattedValue;