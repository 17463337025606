import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'


import {  updateCompetition } from '../redux/competition/actions';
import { useSnackbar } from '../hooks/useSnackbar'

import { 
    XButton,
    Form,
    FormField,
    Textbox,
    Icon,
    CheckBoxButton,
    PasswordInput,
    Textarea,
    Box,
    HoverWithOpacity


} from 'components'
import { Label } from '../components';

import DeleteCompetitionDialog from './DeleteCompetitionDialog'
import { useTranslator } from '../hooks/useTranslator'


const UIToggle = ({ label, show, text, children, onChangeDisplay }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            
            { 
                !show && 
                <XButton size="s" type="button" px="0" variant="transparent" textTransform="uppercase"  onClick={ () => onChangeDisplay(true) }>
                    <HoverWithOpacity>
                        <Icon type="plus"></Icon>
                    </HoverWithOpacity>
                    { text }
                </XButton>
            }

            {
                show &&
                <Box>
                    <Box display="flex">
                        <Box flex={ 1 }>
                            <Label>{ label }</Label>
                        </Box>
                        <XButton size="xs" variant="transparent" textTransform="uppercase"  onClick={ () => onChangeDisplay(false) }>{ t('common.remove_caption')}</XButton>
                    </Box>

                    { children }
                </Box>

            }

        </React.Fragment>
    )
}

const Settings = ({ open, competition, onDelete }) => {
    const dispatch = useDispatch();
    const [problems, setProblems] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showSnackbar] = useSnackbar();
    const t = useTranslator();

    const [item, setItem] = useState({
        name : '',
        type : 'leaderboard',
        useDescription : false,
        description : '',
        allowMultipleEntries : false,
        enforceDeadline : false,
        usePassword : false,
        password : ''
    })

    useEffect(() => {
        if (competition) {
            const password = typeof(competition.password) == 'undefined' ? '': competition.password + '';
            setItem({
                name : competition.name,
                type : competition.type,
                useDescription : competition.description.length > 0,
                description : competition.description + '',
                enforceDeadline : competition.enforceDeadline,
                allowMultipleEntries : competition.allowMultipleEntries,
                usePassword : password.length > 0,
                password : password

            })
        }
    }, [competition]);


    const onSubmit = () => {
        const data = {
            name : item.name,
            type : item.type,
            allowMultipleEntries : item.allowMultipleEntries,
            enforceDeadline : item.enforceDeadline,
            description : item.useDescription ?  item.description : '',
            password : item.usePassword && item.password || ''
        }

        dispatch(updateCompetition(competition.id, data)).then(response => {
            showSnackbar('Miniligaen blev opdateret', 'success');
        }, e => {
            const title = e.response.data.title || 'Der opstod en fejl under opdateringen.'
            showSnackbar(title, 'error');
            setProblems(e.response.data.errors);
        });
    }

    const handleDelete = () => {
        onDelete();
    }

    return (
        <React.Fragment>

            <Form problems={ problems } onSubmit={ onSubmit }>
                <FormField name="name">
                    <Label>{ t('competitions.mini_league_name_label') }</Label>
                    <Textbox value={ item.name } onChange={ e => setItem({ ...item, name : e.target.value })} />
                </FormField>

                <FormField name="description">
                    <UIToggle show={ item.useDescription } label={ t('competitions.description_label') } text={ t('competitions.add_description_caption') } onChangeDisplay={ value => setItem({ ...item, useDescription : value })}>
                        <Textarea value={ item.description } 
                                placeholder={ t('competitions.description_placeholder') }
                                onChange={ (e) => setItem({ ...item, description : e.target.value })}
                            />
                    </UIToggle>
                </FormField>

                <FormField name="password">
                    <UIToggle show={ item.usePassword } label={ t('competitions.password_label') } text={ t('competitions.add_password_caption') } onChangeDisplay={ value => setItem({ ...item, usePassword : value })}>
                        <PasswordInput value={ item.password }  placeholder={ t('competitions.password_placeholder') } onChange={ e => setItem({ ...item, password : e.target.value }) } />
                    </UIToggle>
                </FormField>

                <FormField name="multiTeam">
                    <CheckBoxButton checked={ item.allowMultipleEntries } onChange={ value => setItem({ ...item, allowMultipleEntries : value }) }>
                        { t('competitions.allow_multiple_teams_per_user_label') }
                        
                    </CheckBoxButton>
                </FormField>

                {
                    competition.type !== 'cup' &&
                    <FormField name="enforceDeadline">
                        <CheckBoxButton checked={ item.enforceDeadline } onChange={ value => setItem({ ...item, enforceDeadline : value }) }>
                            { t('competitions.close_enrollment_on_start_label') }
                        </CheckBoxButton>
                    </FormField>
                }
            </Form>
            

            <Box display="flex" justifyContent="space-between">
                <XButton size="s"  variant="outline" textTransform="uppercase" onClick={ () => setShowDeleteDialog(true) }>{ t('competitions.delete_mini_league_caption') }</XButton>
                <XButton size="s" variant="primary" textTransform="uppercase" onClick={ onSubmit }>{ t('common.save_caption')}</XButton>
            </Box>

            <DeleteCompetitionDialog competitionId={ competition.id } open={ showDeleteDialog } onClose={ () => setShowDeleteDialog(false) }  onDelete={ handleDelete }/>
        </React.Fragment>
    )
}

export default Settings;