import React from 'react'

import * as styles from './ContentContainer.scss'

const ContentContainer = ({ children }) => {
    return (
        <div className={ styles.contentContainer }>{ children }</div>
    )
}

export default ContentContainer;