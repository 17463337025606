import React from 'react';
import PropTypes from 'prop-types'
import { TemplateContentRegion } from '../../TemplateContentRegion'

 const TemplateContentRegionWidget = ({ editMode }) => {

    if (editMode)
        return '(TemplateContentRegionWidget)';

    return <TemplateContentRegion />
}

export default TemplateContentRegionWidget;