import React from 'react'
import PropTypes from 'prop-types'

import { MediaObject, PlayerAvatar, PlayerByline, Icon, WithSticker, InjurySuspensionIcon } from '../components'
import PlayerLink from './PlayerLink'

import GameContext from './GameContext'


const PlayerIdentityAvatar = ({ injured, suspensionDays, game, player}) => {

    if (injured || (suspensionDays && suspensionDays > 0)) {
        return (
            <WithSticker sticker={ <InjurySuspensionIcon injured={ injured } suspensionDays={ suspensionDays } size="1-2x" /> }>
                <PlayerAvatar game={ game } player={ player } />
            </WithSticker>
        )
    }
    else {
        return (
            <PlayerAvatar game={ game } player={ player } />
        )
    }

}


const PlayerIdentity = ({ editMode, player, captain, hideTeam, hidePosition, form, watchlist }) => {
    
    if (editMode)
        return "(PlayerIdentity)"
    
    if (!player)
        return "-";

    

    return (
        <GameContext.Consumer>{ ({ game, gameConfiguration, injuries, suspensions, permissionManager }) => {
            const injury = permissionManager.hasPermission('ListInjuries') && gameConfiguration && gameConfiguration.injuries && injuries && injuries.map[player.person.id + ''];
            const suspension = permissionManager.hasPermission('ListSuspensions') && gameConfiguration && gameConfiguration.suspensions && suspensions && suspensions.map[player.person.id + ''];
            const suspensionDays = (suspension || []).reduce((acc, s) => acc += s.remainingMatches, 0);

            return (
                <MediaObject>
                    { 
                        player && 
                        <MediaObject.Media>
                            <PlayerIdentityAvatar game={ game } player={ player } injured={ !!injury } suspensionDays={ suspensionDays } />
                        </MediaObject.Media>
                    }
                    <MediaObject.Content>
                        <div>
                            { watchlist && <Icon type="heartSmall" color="red" size="1-2x" />}
                            { player && <strong><PlayerLink form={ form } game={ game } player={ player } /></strong> }
                        </div>
                        <PlayerByline star={ captain } team={ !hideTeam && player.team } position={ !hidePosition && player.position }  />
                    </MediaObject.Content>
                </MediaObject>
            )
        }}</GameContext.Consumer>
    )
}


PlayerIdentity.propTypes = {
    player: PropTypes.object,
    form : PropTypes.oneOf(['default', 'compact', 'auto'])
}

PlayerIdentity.defaultProps = {
    form : 'auto'
}

export default PlayerIdentity;
