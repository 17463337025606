import React from 'react'
import PropTypes from 'prop-types'

import ViewContainer from '../../ViewContainer'

const ViewContainerWidget = ({ name }) => {
    return <ViewContainer name={ name } />
}

ViewContainerWidget.propTypes = {
    name : PropTypes.string
}

export default ViewContainerWidget;

