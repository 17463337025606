let queue = [];
let queueTimeout = null;

import * as wsTypes from '../redux/ws/types'
import * as matchTypes from '../redux/match/types'

export default ({ getState, dispatch }) => next => action => {
    const dispatchBulkMessages = () => {
        const q = queue.slice();
        queue = [];

        dispatch({ type : wsTypes.WS_MESSAGE_BULK, queue : q });

        for(let i = 0; i < q.length; i++) {
            let jobj = q[i];

            if (jobj.message == 'FANTASYEVENT_INSERT') {
                dispatch({ type : matchTypes.DISPLAY_MATCH_FANTASY_EVENT_NOTIFICATION, match : jobj.payload.match, id : jobj.payload.id })
                setTimeout(() => {
                    dispatch({ type : matchTypes.HIDE_MATCH_FANTASY_EVENT_NOTIFICATION, match : jobj.payload.match, id : jobj.payload.id })
                }, 10000);
            }
        }


        queueTimeout = null;   
    }
    
    if (action.type === wsTypes.WS_MESSAGE) {
    

        queue.push(action);
        if (queueTimeout === null) {
            queueTimeout = setTimeout(dispatchBulkMessages, 250);        
        }
    }
    else {
       return next(action);
    }
}