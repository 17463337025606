import React from 'react'
import PropTypes from 'prop-types'

import FormContextProvider from '../../FormContextProvider'

const FormWidget = ({ name, defaults, children }) => {
    return (
        <FormContextProvider name={ name } defaults={ defaults }>
            { children }
        </FormContextProvider>
    )
}

export default FormWidget;