import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as styles from './Progress.scss'

const left = (value, maximum) => {
    const p = ((value / parseFloat(maximum)) * 100);
    return p === 100.0 ? 'calc(100% - 1px)' : p + '%';
}


const Stop = ({ stop, value, maximum }) => {

    const className = classNames(
        styles.stop,
        {
            [styles.active] : stop <= value
        }
    );

    
    return (
        <div className={ className } style={{ left: left(stop, maximum) }}></div>
    )
}

const Progress = ({ stops, value, maximum, disabled }) => {
    if (disabled)
        return <div className={ styles.progress }></div>


    let v = value > maximum ? maximum : value;

    return (
        <div className={ styles.progress }>
            { stops.map((s, index) => <Stop key={ index } stop={ s } value={ value } maximum={ maximum } /> ) }
            <div className={ styles.value } style={{ width : left(v, maximum) }}></div>
        </div>
    )
}

Progress.propTypes = {
    stops : PropTypes.array.isRequired,
    value : PropTypes.number.isRequired,
    maximum : PropTypes.number.isRequired,
    disabled : PropTypes.bool.isRequired
}

Progress.defaultProps = {
    stops : [],
    value: 0,
    maximum : 100,
    disabled : false
}



export default Progress;