import React, { useState, useEffect } from 'react'

const usePageTitle = (title) => {
    const [savedTitle] = useState(document.title);

    useEffect(() => {
        document.title = title;

        return () => {
            document.title = savedTitle;
        }
        
    }, [title])
}

export default usePageTitle;