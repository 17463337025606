import React from 'react'
        
import classNames from 'classnames'

import * as styles from './Timeline.scss';

import styled from 'styled-components'


export const TimelineEvent = ({ imageUrl, children }) => {
    return (
        <div className={ styles.event }>
            { imageUrl && <img src={ imageUrl } /> }
            <div>
                { children }
            </div>
        </div>
    )
}

export const TimelineTime = ({ children }) => {
    return (
        <div className={ styles.time }>{ children }</div>
    )
}

class TimelineRow extends React.PureComponent {

    static Event = TimelineEvent;
    static Time = TimelineTime;


    render() {
        const { children, align } = this.props;
    

        const className = classNames(
            styles.row,
            {
                [styles.left] : align === 'left',
                [styles.right] : align === 'right',
                [styles.both] : align === 'both'
            }
        )

        
        return (
            <div className={ className }>
                { children }
            </div>
        )
    }
}


class TimelineSeparator extends React.PureComponent {

    render() {
        const { children } = this.props;
    

        
        return (
            <div className={ styles.separator }>
                <div>
                    { children }
                </div>
            </div>
        )
    }
}

const StyledWrapper = styled.div`
    --timeline-background-color: ${ props => props.theme.colors.cardBackground };
    --timeline-separator-background-color: ${ props => props.theme.colors.cardBackground };
    --timeline-line-color: ${ props => props.theme.colors.siteForegroundMeta };
    --timeline-link-color: ${ props => props.theme.colors.siteForeground };
    --timeline-text-color: ${ props => props.theme.colors.cardForeground };
`


class Timeline extends React.PureComponent {
    static Row = TimelineRow;
    static Separator = TimelineSeparator;

    constructor(props) {
        super(props);
    }


    render() {

        return <StyledWrapper> <div  className={ styles.container }>
            {this.props.children}
        </div>
        </StyledWrapper>
        
        
    }
}


export default Timeline;