import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { start } from '../../../redux/auth/actions'

export const Signup = () => {
    const dispatch = useDispatch();


    return (
        <React.Fragment>
            <a onClick={ () => dispatch(start()) }>SHOW</a>
        </React.Fragment>
    )
}

Signup.propTypes = {

}


Signup.defaultProps = {
   
}