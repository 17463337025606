import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux';

import { joinCompetition } from '../redux/competition/actions'
import { useSnackbar } from '../hooks/useSnackbar'

const useJoinCompetition = (competitionId) => {
    const dispatch = useDispatch();
    const [showSnackbar] = useSnackbar();

    const join = useCallback((fantasyTeamId, password) => new Promise((resolve, reject) => {
        dispatch(joinCompetition(competitionId, fantasyTeamId, password)).then(
            () => {
                showSnackbar('Din til/af-melding blev registreret');
                resolve();
            },
            e => { 
                reject(e.response.data);
            }
        );
    }), []);

    return [join];
}

export default useJoinCompetition;