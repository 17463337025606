import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { useSpring, a, config } from 'react-spring'
import { useMeasure, usePrevious } from '../hooks/useMeasure'
import styled from 'styled-components';

const Plus = styled.div`
    opacity: 0.6;
    transition: opacity 0.2s linear;
    will-change: opacity;
    color: var(--link-color);
`


const ItemHeader = styled.div`
    min-height: var(--vertical-rythm);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    
    padding: 12px 0;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover ${Plus} {
        opacity: 1;
    }
`

const ItemContent = styled.div`
    padding: 0px 0px 12px 0px;

`


const FaqItem = ({ heading,  children }) => {

    const [open, setOpen] = useState(false);
    const previous = usePrevious(open);

    const [bind, { height: viewHeight }] = useMeasure();

    const { height } = useSpring({
        from: { height: 0 },
        to: { height: open ? viewHeight : 0 },
        config : { ...config.stiff, clamp : true }
    });

    const { opacity } = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        config : { ...config.stiff, clamp : true },
        delay : 200 
    });

    return  (
        <div>
            <ItemHeader onClick={ () => setOpen(!open) }>
                { heading }
                <Plus>
                    <Icon type="plus" />
                </Plus>
            </ItemHeader>
            <a.div style={{ opacity,  height: open && previous === open ? 'auto' : height, overflow: 'hidden' }} >
                <div {...bind}>
                    <ItemContent>
                        { children }
                    </ItemContent>
                </div>
            </a.div>
        </div>
    )
}


const Faq = ({ children }) => {
    return (
        <div>
            { children }
        </div>
    )
}

Faq.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName]
    
        let error = null
        React.Children.forEach(prop, function (child) {
          if (child.type !== FaqItem) {
            error = new Error('`' + componentName + '` children should be of type `FaqItem`.');
          }
        });
        return error
    }
}

Faq.defaultProps = {
}

export { Faq, FaqItem }

