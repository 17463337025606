import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space, layout, shadow } from 'styled-system'

const tiny = css`
    line-height: 24px;
    width: 24px;
    height: 24px;

    border-radius: ${ props => props.variant == 'round' ? '12px' : props.theme.radii.r400 };
    
    font-size: 11px;
`

const small = css`
    line-height: 36px;
    width: 36px;
    height: 36px;
    
    border-radius: ${ props => props.variant == 'round' ? '18px' : props.theme.radii.r600 };

    font-size: 18px;
`

const medium = css`
    line-height: 48px;
    width: 48px;
    height: 48px;
    
    border-radius: ${ props => props.variant == 'round' ? '18px' : props.theme.radii.r600 };
    
    font-size: 18px;
`

const large = css`
    line-height: calc(var(--vertical-rythm) * 2);
    width: calc(var(--vertical-rythm) * 2);
    height: calc(var(--vertical-rythm) * 2);
    border-radius: var(--vertical-rythm);

    font-size: 48px;
`

const huge = css`
    line-height: 100px;
    width: 100px;
    height: 100px;
    
    border-radius: ${ props => props.variant == 'round' ? '46px' : props.theme.radii.r800 };

    font-size: 48px;
`

const StyledAvatar = styled.div`
    display: inline-block;
    text-align: center;
    background-color: ${ props => props.theme.colors.cardBackgroundSecondary };
    color:  ${ props => props.theme.colors.cardForeground };
    font-weight: bold;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    text-transform: uppercase;
    
    border-radius: 3px;

    img { 
        width: 100; 
        height: 100%; 
        display: block; 
        border: none; 
    }

    ${
        props => 
        ({
            tiny,
            small,
            medium,
            large,
            huge
        })[props.size]
    }

    ${space}
    ${layout}
    ${shadow}
`

const Avatar = React.memo(({ size, variant, src, name, ...otherProps }) => {
    const normalizedName = useMemo(() => name && name.replace(/[^0-9a-z]/gi, '') ||'', [name]);
    const content = src ?<img src={ src } alt={ name } /> : <span>{ (normalizedName || '?' ).substring(0, 2) }</span>;

   
    return <StyledAvatar size={ size } variant={ variant } { ...otherProps }>{ content }</StyledAvatar>
})

Avatar.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.string.isRequired,
    variant : PropTypes.oneOf([ 'round', 'rounded' ])
}

Avatar.defaultProps = {
    size : 'small',
    variant : 'round'
}

export default Avatar