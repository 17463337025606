import React from 'react'
import PropTypes from 'prop-types'
import RichCheckable from './RichCheckable'

import styled from 'styled-components'

const StyledItem = styled.div`

    margin-bottom: 12px;
`


export const RichRadioButtonList = ({ value, items, onChange }) => {
    const onClick = (item) => {
        if (!item.disabled) {
            onChange(item.value);
        }
    }

    return (
        <React.Fragment>
            {
                items.map((item, index) => {
                    const { disabled, value : itemValue, label, content } = item;
                    const checked = value === itemValue;

                    return (
                        <StyledItem key={ item.value }>
                            <RichCheckable key={ index } checkedIcon="radiomarkChecked" uncheckedIcon="radiomark" label={ label } checked={ checked } disabled={ disabled } onClick={ () => onClick(item) }>
                                { content }
                            </RichCheckable>
                        </StyledItem>
                    )
                })
            }
        </React.Fragment>
    )     
    
}


RichRadioButtonList.propTypes = {
    items : PropTypes.array.isRequired,
    onChange : PropTypes.func.isRequired
}

RichRadioButtonList.defaultProps = {
    items : []
}

