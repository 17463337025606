import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { number, exp } from 'utils';
import DataContext from '../../../components/DataContext'

const Text = ({ editMode, property }) => {

    if (editMode) {
        return property;
    }

    const context = useContext(DataContext)

    const value = exp(property, context);
    return value + '';
}

Text.propTypes = {
    property : PropTypes.string.isRequired
}

Text.defaultProps = {
    property : ''
}

export default Text;