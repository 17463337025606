import * as types from './types'

const defaultState = {
    byGame : {
    },
    lineups : {

    }
};



const fantasyTeamReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.FETCH_GAME_FANTASYTEAMS_REQUEST:
            return  { 
                ...state,
                byGame : {
                    [action.gameId] : {
                        ...state.byGame[action.gameId],
                        isFetching :  true,
                        didInvalidate : false,
                        items : null
                    }
                }
            };
        case types.FETCH_GAME_FANTASYTEAMS_SUCCESS:
                return  { 
                    ...state,
                    byGame : {
                        [action.gameId] : {
                            ...state.byGame[action.gameId],
                            didFetch : true,
                            isFetching :  false,
                            didInvalidate : false,
                            items : action.payload.result
                        }
                    }
                };
        case types.FETCH_GAME_FANTASYTEAMS_FAIL:
            return  { 
                ...state,
                byGame : {
                    [action.gameId] : {
                        ...state.byGame[action.gameId],
                        isFetching :  false,
                        didInvalidate : false,
                        items : null
                    }
                }
            };      

            case types.FETCH_LINEUP_REQUEST:
                return  { 
                    ...state,
                    lineups : {
                        [action.fantasyTeamId] : {
                            ...state.lineups[action.fantasyTeamId],
                            isFetching :  true,
                            didInvalidate : false,
                            lineup : null
                        }
                    }
                };
        case types.FETCH_LINEUP_SUCCESS:
                return  { 
                    ...state,
                    lineups : {
                        [action.fantasyTeamId] : {
                            ...state.lineups[action.fantasyTeamId],
                            didFetch : true,
                            isFetching :  false,
                            didInvalidate : false,
                            lineup : action.payload.lineup
                        }
                    }
                };
        case types.FETCH_LINEUP_FAIL:
            return  { 
                ...state,
                lineups : {
                    [action.fantasyTeamId] : {
                        ...state.lineups[action.fantasyTeamId],
                        isFetching :  false,
                        didInvalidate : false,
                        lineup : null
                    }
                }
            };                            
        default:
            return state
    }
}

export default fantasyTeamReducer;
