import React from 'react'

import MobileSiteHeader from '../MobileSiteHeader'
import TitleConductor from './TitleConductor'

const RoutedMobileHeader = ({ themeName, onShowMenu }) =>  {
    
    return (
        <MobileSiteHeader themeName={ themeName } onShowMenu={ onShowMenu }>
            <TitleConductor />
        </MobileSiteHeader>
    )
}

export default RoutedMobileHeader;
