import * as types from './types'

const defaultState = {
    byUrl : {}
};

export default function json (state = defaultState, action) 
{
    switch (action.type) {

        case types.FETCH_JSON_REQUEST:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_JSON_SUCCESS:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        isFetching : false,
                        didInvalidate : false,
                        item : action.payload
                    }
                }
            };
        case types.FETCH_JSON_FAILURE:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        didInvalidate : false,
                        isFetching : false
                    }
                }
            };            
        default:
            return state
    }
}

