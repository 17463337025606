import React from 'react'

import { useDispatch } from 'react-redux';

import { deleteCompetition } from '../redux/competition/actions'

import { 
    Box,
    XButton,
    Dialog, DialogHeader, DialogContent, DialogFooter,
    BodyCopy
} from 'components'
import { useTranslator } from '../hooks/useTranslator'

import { useSnackbar } from '../hooks/useSnackbar'

const DeleteCompetitionDialog = ({ open, competitionId, onClose, onDelete }) => {
    const dispatch = useDispatch();
    const [showSnackbar] = useSnackbar();
    const t = useTranslator();

    const handleDelete  = () => {
        dispatch(deleteCompetition(competitionId)).then(
            () => {
                showSnackbar(t('competitions.mini_league_deleted_confirmation'), 'success');
                onDelete();
            },
            () => {
                showSnackbar(t('common.an_error_occurred'), 'error');
            }
        );
    }

    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogHeader>{ t('competitions.delete_mini_league_heading') }</DialogHeader>
            <DialogContent>
                <BodyCopy>{ t('competitions.delete_mini_league_prompt') }</BodyCopy>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="outline" mr="s200" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.cancel_caption') }</XButton>
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ handleDelete }>{ t('common.ok_caption') }</XButton>
                </Box>
            </DialogFooter>
        </Dialog>
    )
}

export default DeleteCompetitionDialog;
