import React from 'react'
import PropTypes from 'prop-types'

import { 
    Highlight,
    FormattedValue

} from 'components';

import WithTournamentStatistics from '../../WithTournamentStatistics';
import LocalizeText from '../../LocalizeText';
import PlayerLink from '../../PlayerLink'

import { matchesConditions, resolve } from 'utils'

class Filter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.filter(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.items !== this.props.items) || (nextProps.conditions !== this.props.conditions)) {
            this.filter(nextProps);
        }
    }

    filter(props) {
        this.setState({
            items : (props.items || []).filter(i => matchesConditions(i, props.conditions))
        })
    }

    render() {
        return this.props.children({ items : this.state.items });
    }
}


class Sort extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.sort = this.sort.bind(this);
    }

    componentDidMount() {
        this.sort(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.items !== this.props.items) ||
            (nextProps.sort !== this.props.sort)) {
                this.sort(nextProps);
            }
    }

    sort(props) {
        const sorted = props.items.slice().sort((a, b) => {
            for(var i = 0; i < props.sort.length; i++) {
                const si = props.sort[i];
                const av = resolve(si.property, a) || si.default;
                const bv = resolve(si.property, b) || si.default;
    
                if (av > bv)
                    return si.direction === 'asc' ? 1 : -1;
                else if (bv > av)
                    return si.direction === 'asc' ? -1 : 1;
            }
    
            return 0;
        });

        this.setState({
            items : sorted
        })
    }

    render() {
        return this.props.children({ items : this.state.items });
    }
}



class TournamentPlayerHighlightList extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            filters : []
        }

        this.updateFilters = this.updateFilters.bind(this);
        this.sortByColumn = this.sortByColumn.bind(this);
    }

    
    updateFilters(props) {
        const conditions = [];
        conditions.push({ property : 'player.active', op : 'eq', value : true });

        if (props.positions && props.positions.length > 0)
            conditions.push({ property : 'player.position.slug', op : 'in', value : props.positions });

        if (props.team)
            conditions.push({ property : 'player.team.slug', op : 'eq', value : props.team });

        this.setState({
            filters : conditions
        })
    }

    componentDidMount() {
        this.updateFilters(this.props);
   
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (
            (nextProps.positions !== this.props.positions) || 
            (nextProps.team !== this.props.team)
        ) {
            this.updateFilters(nextProps);
            this.setState({ page: 1})
        }

        if (nextProps.columns !== this.props.columns) {
            const sortColumn = nextProps.columns.find(c => c.sort && c.sort.default);
            if (sortColumn) {
                this.setState({
                    activeColumnIndex : nextProps.columns.indexOf(sortColumn)
                });
            }
        }
    }

    sortByColumn(index) {
        const column = this.props.columns[index];

        if (index === this.state.activeColumnIndex) {
            this.setState({
                sort : this.state.sort.map(s => {
                    return {
                        ...s,
                        direction : s.direction === 'asc' ? 'desc' : 'asc'
                    }
                })
            })
        }
        else {
            this.setState({
                activeColumnIndex : index,
                sort : column.sort.by || []
            })
        }

        this.setState({ page: 1})
    }

    render() {

        const { highlights, round } = this.props;

        if (this.props.editMode) {
            return (
                <Highlight>
                    {
                        highlights.map((highlight, index) => {
                            return (
                                <Highlight.Item key={ index } heading={ highlight.heading } value={ "Value" }>
                                    Player Identity
                                </Highlight.Item>
                            )
                        })
                    }
                </Highlight>
            )
        }


        

        return (
            <WithTournamentStatistics round={ round }>{ ({ items }) => (
                <Filter items={ items } conditions={ this.state.filters }>{ ({ items : filteredItems }) => (
                    
                        <Highlight>
                            {
                                highlights.map((highlight, index) => {
                                    return (
                                        <Sort key={ index } items={ filteredItems } sort={ highlight.sort }>{ ({ items }) => {

                                            const item = items[0];
                                            if (!item)
                                                return null;

                                            const value = resolve(highlight.property, item);


                                            return (
                                                <Highlight.Item  heading={ highlight.heading } value={ <FormattedValue value={ value } format={highlight.format } /> }>
                                                    <PlayerLink player={ item.player } />
                                                </Highlight.Item>
                                            )
                                        }}</Sort>
                                    )
                                })
                            }
                            

                        </Highlight>
                    
                )}</Filter>
            )}</WithTournamentStatistics>
        )
    }
}

TournamentPlayerHighlightList.propTypes = {
    round : PropTypes.number,
    team : PropTypes.string,
    positions : PropTypes.array,
    highlights : PropTypes.array,
}
  

export default TournamentPlayerHighlightList;