import React from 'react'

import styled from 'styled-components'
import { flexbox, layout } from 'styled-system'

const Flex = styled.div`
  display: flex;

  ${ flexbox }
  ${ layout }
`
export default Flex;