import React from 'react';
import PropTypes from 'prop-types'

import { Underlay } from 'components'


export const UnderlayUnderlayWidget = ({ children, ...otherProps }) => {
    return children;

}

export const UnderlayContentWidget = ({ children }) => {
    return children;

}


export const UnderlayWidget = ({ children  }) => {
    const underlay = children.find(c => c.type === UnderlayUnderlayWidget);
    const content = children.find(c => c.type === UnderlayContentWidget);

    return <Underlay underlay={ underlay.props.children }>
                { content.props.children }
           </Underlay>

}

