import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { number } from '../common/utils'

class Jump extends React.PureComponent{

    renderIcon() {
        if (this.props.value < 0)
            return '▼';
        else if (this.props.value > 0)
            return '▲';
        else
            return null;
    }

    renderValue() {
        const absValue = Math.abs(this.props.value || 0);
        if (absValue !== 0)
            return number(absValue);
        else
            return '-'
    }

    render() {
        const classes = classNames(
            'nowrap',
            { 
                red : this.props.value < 0,
                green : this.props.value > 0 
            }
        );


        

        return (
            <span className={ classes }>
                { this.renderIcon() }
                { this.renderValue() }
            </span>
        )
    }  
}

Jump.propTypes = {
    value : PropTypes.number
}

export default Jump;
