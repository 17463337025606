import axios from 'axios'
import { normalize } from 'normalizr';

import { rulesetSchema } from '../../schemas';

import * as types from './types';

const fetchRulesetRequest = (id) => ({ type: types.FETCH_RULESET_REQUEST, id })
const fetchRulesetSuccess = (id, payload) => ({ type: types.FETCH_RULESET_SUCCESS, id, payload })
const fetchRulesetFailure = (id) => ({ type: types.FETCH_RULESET_FAILURE, id })

const fetchRuleset = (id) => (dispatch) => {
    dispatch(fetchRulesetRequest(id))

    axios.get(`rulesets/${ id }`).then(response => {
        dispatch(fetchRulesetSuccess(id, normalize(response.data, rulesetSchema)));
    }, error => {
        dispatch(fetchRulesetFailure(id));
    });
}


const shouldFetchRuleset = (state, id) => {
    const { byId } = state.rulesets || { byId : {} };
    const item = byId[id];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
}
  
export const fetchRulesetIfNeeded = (id) => (dispatch, getState) => {
    if (shouldFetchRuleset(getState(), id)) {
        dispatch(fetchRuleset(id));
    }
}
