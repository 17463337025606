import * as types from './types'

const defaultState = {
    categories : [],
    
    groupMemberships : {
        isFetching : false,
        didInvalidate : false,
        items : null
    },

    members : {
        isFetching : false,
        didInvalidate : false,
        rows : [],
        page : 1,
        pageSize : 10,
        query : ''
    },

    competitions: {
        isFetching : false,
        didInvalidate : false,
        rows: []
    },

    selectedGroup : null
};


const groupReducer = (state = defaultState, action) =>
{
    switch (action.type) {

        case types.FETCH_USER_GROUP_MEMBERSHIPS_REQUEST:
                return  { 
                    ...state,
                    groupMemberships : {
                        isFetching : true,
                        didInvalidate : false,
                        items : []
                    }
                };

        case types.FETCH_USER_GROUP_MEMBERSHIPS_SUCCESS:
                return  { 
                    ...state,
                    groupMemberships : {
                        isFetching : false,
                        didInvalidate : false,
                        items : action.payload.result
                    }
                };
        case types.FETCH_USER_GROUP_MEMBERSHIPS_FAIL:
            return  { 
                ...state,
                groupMemberships : {
                    isFetching : false,
                    didInvalidate : false,
                    items : []
                }
            };
        case types.FETCH_GROUP_CATEGORIES_SUCCESS:
            return  { 
                ...state,
                categories : action.payload.result
            };
      

        case types.FETCH_GROUP_MEMBERS_REQUEST:
            return  { 
                ...state,
                members : {
                    ...state.members,
                    didInvalidate : false,
                    isFetching : true
                }
            };

        case types.FETCH_GROUP_MEMBERS_SUCCESS:
            return  { 
                ...state,
                members : {
                    ...state.members,
                    rows : action.payload.result,
                    count : action.count,
                    didInvalidate : false,
                    isFetching : false
                }
            };

        case types.FETCH_GROUP_COMPETITIONS_REQUEST:
            return  { 
                ...state,
                competitions : {
                    ...state.competitions,
                    didInvalidate : false,
                    isFetching : true
                }
            };
        case types.FETCH_GROUP_COMPETITIONS_SUCCESS:
        
            return  { 
                ...state,
                competitions : {
                    ...state.competitions,
                    didInvalidate : false,
                    isFetching : false,
                    rows: action.payload.result
                }
            };
        case types.SELECT_GROUP:
            return { 
                ...state,
                selectedGroup : action.groupId
            };
        case types.INVALIDATE_GROUP_MEMBERS:
            return { 
                ...state,
                members :  {
                    ...state.members,
                    rows: [],
                    count: 0,
                    didInvalidate : true
                }
            };
        case types.FILTER_GROUP_MEMBERS:
            return { 
                ...state,
                members :  {
                    ...state.members,
                    ...action.filter,
                    didInvalidate : true
                }
            };        
        default:
            return state
    }
}

export default groupReducer;
