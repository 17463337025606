import React from 'react'

import Box from './Box'

const Panel = ({ children, ...otherProps }) => {

    return (
        <Box bg="panelBackground" p="s400" { ...otherProps }>
            { children }
        </Box>
    )
}

export default Panel;