import axios from 'axios';

export default {
    authenticated : false,
    auth : null,
    isAuthenticating : false,

    signin() {
        this.isAuthenticating = true;
        
        return new Promise((resolve, reject) => {
            axios({ method: 'post', url: window.swush.tokenEndpoint, withCredentials: true }).then(response => {
                localStorage.setItem('auth', JSON.stringify(response.data));
                this.authenticated = true;
                this.auth = response.data;
                this.isAuthenticating = false;

                resolve(response.data);
            }, () => {
                localStorage.removeItem('auth');
                this.authenticated = false;
                this.auth = null;
                this.isAuthenticating = false;

                reject();
            });
        })
    },

    signout : function () {
        localStorage.removeItem('auth');
        this.authenticated = false
        this.auth = null;
    },

    loadAuth : function() {
        var auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
            this.authenticated = true;
            this.auth = auth;
        }
        else {
            this.authenticated = false;   
            this.auth = null; 
        }
    },

    setAuth : function(auth) {
        localStorage.setItem('auth', JSON.stringify(auth));
        this.authenticated = true;
        this.auth = auth;
        this.isAuthenticating = false;
    },

    getAccessToken() {
        return this.auth ? this.auth.token : null
    },

    getUserId () {
        return this.auth ? this.auth.userId : null
    }
}
