import * as scheduleTypes from '../schedule/types'
import * as wsTypes from '../ws/types'
import * as types from './types'

const defaultState = { 
    byId : {},
    fantasyEvents : {},
    events : {},
    eventNotifications : {},
    fantasyEventNotifications : {}
};

const fantasyEventReducer = (state, action) => {
    let matchState = null;
    let existing = null;

    switch (action.message) {

        case 'FANTASYEVENT_INSERT':
        

            matchState = state[action.payload.match] || {};
            existing = matchState.items || [];
            if (existing.indexOf(action.payload.id) >= 0)
                return state;

            return { 
                ...state,

                [action.payload.match] : {
                    ...matchState,
                    items : [
                        ...existing, 
                        action.payload.id
                    ]
                }
            };
        case 'FANTASYEVENT_DELETE':
            matchState = state[action.payload.match] || {};

            existing = matchState.items || [];
            if (!existing)
                return state;

            const index = existing.indexOf(action.payload.id);
            if (index < 0)
                return state;

            return { 
                ...state,
                [action.payload.match] : {
                    ...matchState,
                    items : [...existing.slice(0, index), ...existing.slice(index + 1)]
                }
            };
        default:
            return state;
    }
}


const wsMessageReducer = (state, action) => {
    let existing = null;
    let index = 0;
    

    switch (action.message) {
        case 'EVENT_INSERT': {
            const mState = state.events[action.payload.match]; 
            const { items} = mState || { items : [] };

            return { 
                ...state,
                events : {
                    ...state.events,
                    [action.payload.match] : {
                        ...mState,
                        items : [
                            ...items, 
                            action.payload.id
                        ]
                    }
                }
            };
        }
        case 'EVENT_DELETE':
            
            const eventList = (state.events[action.payload.match] || { }).items || [];
            index = eventList.indexOf(action.payload.id);
            if (index < 0)
                return state;

            return { 
                ...state,
                events : {
                    ...state.events,
                    [action.payload.match] : {
                        ...state.events[action.payload.match],
                        items : [...eventList.slice(0, index), ...eventList.slice(index + 1)]
                    } 
                }
            };
     
     
     
         case 'FANTASYEVENT_INSERT':
        case 'FANTASYEVENT_DELETE':
            return {
                ...state,
                fantasyEvents : fantasyEventReducer(state.fantasyEvents, action)
            }
        default:
            return state;
    }
}



export default function match (state = defaultState, action) 
{
    switch (action.type) {
        case types.INVALIDATE_MATCH_FANTASY_EVENTS:
            return { 
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.match] : {
                        didInvalidate : true
                    }
                }
            };        
        case types.FETCH_MATCH_FANTASY_EVENTS_REQUEST:
            return { 
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.match] : {
                        ...state.fantasyEvents[action.match],
                        didInvalidate : false,
                        isFetching : true
                    }
                }
            };
        case types.FETCH_MATCH_FANTASY_EVENTS_SUCCESS:
            return { 
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.match] : {
                        ...state.fantasyEvents[action.match],
                        isFetching : false,
                        didInvalidate : false,
                        items : action.payload.result
                    }
                }
            };
        case types.INVALIDATE_MATCH_EVENTS:
            return { 
                ...state,
                events : {
                    ...state.events,
                    [action.match] : {
                        didInvalidate : true
                    }
                }
            };        
        case types.FETCH_MATCH_EVENTS_REQUEST:
            return { 
                ...state,
                events : {
                    ...state.events,
                    [action.match] : {
                        ...state.fantasyEvents[action.match],
                        isFetching : true,
                        isLoaded : false,
                        didInvalidate : false,
                        items : []
                    }
                }
            };        
        case types.FETCH_MATCH_EVENTS_SUCCESS:
        
            return { 
                ...state,
                events : {
                    ...state.events,
                    [action.match] : {
                        ...state.events[action.match],
                        isFetching: false,
                        isLoaded : true,
                        didInvalidate : false,
                        items : action.payload.result
                    }
                }
            };

        case types.FETCH_MATCH_REQUEST:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false,
                        item : null
                    }
                }
            };
        case scheduleTypes.FETCH_SCHEDULE_SUCCESS:
        
            const byId = action.payload.result.reduce((agg, r) => {
                agg[r] = {
                    ...state.byId[action.id],
                    isFetching : false,
                    didInvalidate : false,
                    item : r
                }

                return agg;
            }, {});

            
            return { 
                ...state,
                byId : {
                    ...state.byId,
                   ...byId
                }
            };
        case types.FETCH_MATCH_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        ...state.byId[action.id],
                        isFetching : false,
                        didInvalidate : false,
                        items : action.payload.result
                    }
                }
            };
        case types.FETCH_MATCH_FAILURE:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        ...state.byId[action.id],
                        isFetching : false,
                        didInvalidate : false,
                        item : null
                    }
                }
            };

        case types.DISPLAY_MATCH_FANTASY_EVENT_NOTIFICATION: {
            const list = state.fantasyEventNotifications[action.match] || [];

            return { 
                ...state,
                fantasyEventNotifications : {
                    ...state.fantasyEventNotifications,
                    [action.match] : [
                        action.id,
                        ...list
                        
                    ]
                }
            };
        }
        case types.HIDE_MATCH_FANTASY_EVENT_NOTIFICATION: {

            const list = state.fantasyEventNotifications[action.match] || [];
            const index = list.indexOf(action.id);
            if (index < 0)
                return state;

            return { 
                ...state,
                fantasyEventNotifications : {
                    ...state.fantasyEventNotifications,
                    [action.match] : [
                        ...list.slice(0, index),
                        ...list.slice(index + 1)
                    ]
                }
            };
        }

        case wsTypes.WS_MESSAGE:
            return wsMessageReducer(state, action);
        case wsTypes.WS_MESSAGE_BULK:
            let nState = state;
            for(let i = 0; i < action.queue.length; i++) {
                nState = wsMessageReducer(nState, action.queue[i]);
            }
            return nState;
        default:
            return state
    }
}
