import React, { useEffect, useRef } from "react";

import * as styles from './google-publisher-tag.module.css'

// Official GPT sources.
const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
const GPT_LIMITED_ADS_URL = 'https://pagead2.googlesyndication.com/tag/js/gpt.js';

// Keep track of defined ad slots.
let adSlots = {};
let adSlotCount = 0;

if (typeof window !== 'undefined') {
	window.googletag = window.googletag || { cmd: [] };
  	googletag.cmd.push(() => {
    	googletag.pubads().disableInitialLoad();
    	googletag.pubads().enableSingleRequest();
    	googletag.enableServices();
  	});
}


export function InitializeGPT({ limitedAds }) {
	// Reset tracking variables.
	adSlots = {};
	adSlotCount = 0;
  
	useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = limitedAds ? GPT_LIMITED_ADS_URL : GPT_STANDARD_URL;
		
		document.head.appendChild(script);

		return () => {
			document.head.removeChild(script);
		}
	}, [limitedAds])
	
	return null;
}



export function DefineAdSlot({ adUnit, size }) {
	const slotId = useRef(`slot-${ adSlotCount++ }`).current;
	
  
	useEffect(() => {
		// Register the slot with GPT when the component is loaded.
		googletag.cmd.push(() => {
			
			const slot = googletag.defineSlot(adUnit, size, slotId);
			if (slot) {
		  		slot.addService(googletag.pubads());
		  		googletag.display(slot);
		  		adSlots[slotId] = slot;
			}
	  	});
  
		// Clean up the slot when the component is unloaded.
	  	return () => {
			googletag.cmd.push(() => {
		  		if (adSlots[slotId]) {
					googletag.destroySlots([adSlots[slotId]]);
					delete adSlots[slotId];
		  		}
			});
	  	};
	}, []);
  
	// Create the ad slot container.
	return (
		<div className={ styles.adSlot } style={ getMaximumSlotSize(size) } id={slotId}></div>
	);
  }



export function RequestAds() {
	useEffect(() => {
		googletag.cmd.push(() => {
			// Request ads for all ad slots defined up to this point.
			//
			// In many real world scenarios, requesting ads for *all*
			// slots is not optimal. Instead, care should be taken to
			// only refresh newly added/updated slots.
			const slots = Object.values(adSlots);
			googletag.pubads().refresh(slots);
	  	});
	}, []);

	return null;
}


/**
 * Determine minimum width and height values for an ad slot container
 * based on the configured slot sizes.
 *
 * This function is only provided for example purposes. See
 * [Minimize layout shift](https://developers.google.com/publisher-tag/guides/minimize-layout-shift)
 * to learn more about strategies for sizing ad slot containers.
 */
function getMinimumSlotSize(size) {
	const maxValue = Number.MAX_VALUE;
  
	let minW = Number.MAX_VALUE;
	let minH = Number.MAX_VALUE;
  
	if (Array.isArray(size)) {
	  // Convert googletag.SingleSize to googletag.MultiSize for convenience.
	  const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;
  
	  for (const size of sizes) {
		if (Array.isArray(size) && size[0] !== 'fluid') {
		  minW = Math.min(size[0], minW);
		  minH = Math.min(size[1], minH);
		}
	  }
	}
  
	return minW < maxValue && minH < maxValue
	  ? // Static ad slot.
		{ minWidth: `${minW}px`, minHeight: `${minH}px` }
	  : // Fluid ad slot.
		{ minWidth: '50%' };
  }


  /**
 * Determine minimum width and height values for an ad slot container
 * based on the configured slot sizes.
 *
 * This function is only provided for example purposes. See
 * [Minimize layout shift](https://developers.google.com/publisher-tag/guides/minimize-layout-shift)
 * to learn more about strategies for sizing ad slot containers.
 */
function getMaximumSlotSize(size) {
	const minValue = Number.MIN_VALUE;  

	let maxW = Number.MIN_VALUE;
	let maxH = Number.MIN_VALUE;
  
	if (Array.isArray(size)) {
	  // Convert googletag.SingleSize to googletag.MultiSize for convenience.
	  const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;
  
	  for (const size of sizes)  {
		if (Array.isArray(size) && size[0] !== 'fluid') {
		  maxW = Math.max(size[0], maxW);
		  maxH = Math.max(size[1], maxH);
		}
	  }
	}
  
	return maxW > minValue && maxH > minValue
	  ? // Static ad slot.
		{ minWidth: `${maxW}px`, minHeight: `${maxH}px` }
	  : // Fluid ad slot.
		{ minWidth: '50%' };
  }

