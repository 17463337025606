import React, { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { fetchMyEnrollments } from '../redux/competition/actions'
import { makeGetMyEnrollments } from '../redux/competition/selectors'

const useMyEnrollments = (competitionId) => {
    const dispatch = useDispatch();
    const getMyEnrollments  = useMemo(() => makeGetMyEnrollments(), []);
    const myEnrollments = useSelector(state => getMyEnrollments(state, competitionId));

    useEffect(() => {
        if (competitionId) {
            dispatch(fetchMyEnrollments(competitionId));
        }
    }, [competitionId])

    return useMemo(() => [myEnrollments.items, myEnrollments.isFetching], [myEnrollments]);
}

export default useMyEnrollments;