import React from 'react'

import Icon from './Icon'

import * as styles from './Checkbox.module.css'
import classNames from 'classnames'

export const Checkbox = ({ checked, disabled, className, label, onChange }) => {

    const _className = classNames(
        styles.container,
        className
    )

    return (
        <label className={ _className } onMouseDown={() => set(true)} onMouseUp={() => set(false)}>
            <input type="checkbox" checked={ checked } disabled={ disabled } onChange={ onChange }/>

            <div className={ styles.faux }>
                <Icon type={ checked ? 'checkboxChecked' : 'checkbox' } />
            </div>
            
            <div className={ styles.label }>{ label }</div>
        </label>
    )
}

Checkbox.defaultProps = {
 
}