import React, { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { fetchEnrollmentOptionsIfNeeded } from '../redux/competition/actions'
import { makeGetEnrollmentOptions } from '../redux/competition/selectors'

const useEnrollmentOptions = (competitionId) => {
    const dispatch = useDispatch();
    const getEnrollmentOptions  = useMemo(() => makeGetEnrollmentOptions(), []);
    const enrollmentOptions = useSelector(state => getEnrollmentOptions(state, competitionId));

    useEffect(() => {
        if (competitionId) {
            dispatch(fetchEnrollmentOptionsIfNeeded(competitionId));
        }
    }, [competitionId])

    
    return useMemo(() => [enrollmentOptions], [enrollmentOptions]);
}

export default useEnrollmentOptions;