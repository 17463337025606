import React from 'react'

import * as styles from './GameHeader.scss'

const GlobalHeader = ({ backgroundImageUrl, children }) =>{
    return (
        <div className={ styles.global } style={{ backgroundImage : `url('${ backgroundImageUrl }')`}}>
            <div className={ styles.content}>
                { children }
            </div>
        </div>
    )
}

export default GlobalHeader;