import React, { useMemo } from 'react'
import { Box } from 'components'

import { 
    XButton,
    Dialog, DialogHeader, DialogContent, DialogFooter,
    RegularText
} from 'components'

import GameClosedDialog from './GameClosedDialog'

import useResign from './useResign'
import useCurrentGame from '../containers/useCurrentGame'
import { useTranslator } from '../hooks/useTranslator'


const ResignDialog = ({ competitionId, fantasyTeamId, open, onClose }) => {
    const [resign] = useResign(competitionId);
    const game = useCurrentGame();
    const t = useTranslator();


    const isClosed = useMemo(() => {
        const lastRound = game.rounds[game.rounds.length - 1];
        return new Date() > new Date(lastRound.end);
    })
    
    

    const handleOk = () => {
        resign(fantasyTeamId).then(onClose);
    }

    const handleCancel = () => {
        onClose();
    }

    if (isClosed) {
            
        return <GameClosedDialog open={ open } onClose={ onClose } />
    }

    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogHeader>{ t('competitions.resign_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.resign_confirmation_prompt') }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton size="s" variant="outline" textTransform="uppercase" onClick={ handleCancel }>{ t('common.cancel_caption') }</XButton>
                    <Box ml="m" display="inline-block">
                        <XButton variant="primary" size="s" textTransform="uppercase" onClick={ handleOk }>{ t('common.ok_caption') }</XButton>
                    </Box>
                </Box>
            </DialogFooter>
        </Dialog>
    )
}

export default ResignDialog;
