import { useDispatch, useSelector } from 'react-redux';

import { showModal } from '../redux/ui/actions'

const useModal = () => {
    const dispatch = useDispatch();

    const show = (name, props, callback) => {
        dispatch(showModal(name, props, callback));
    }

    const modal = useSelector(state => state.ui.modal);

    return [show, modal];
}

export default useModal;