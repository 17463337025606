import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { number, exp } from 'utils';
import DataContext from '../../../components/DataContext'
import * as Components from 'components';

const Number = ({ property, ...otherProps }) => {
    const context = useContext(DataContext)

    const value = exp(property, context);
    return <Components.Number value={ value } { ...otherProps }/>
}

Number.propTypes = {
    property : PropTypes.string.isRequired
}

Number.defaultProps = {
    property : ''
}

export default Number;