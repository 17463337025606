import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ className, renderAs, button, href, children }) => {
    const style = button ? { textTransform : 'uppercase', height: '48px', lineHeight : '48px' } : null;

    let actualHref = href || '';
    let fullHref = (actualHref.indexOf('/') == 0) ? window.swush.externalBaseUrl + actualHref : window.swush.externalBaseUrl + '/' + actualHref;
    
    if (renderAs === 'div') {
      return <div onClick={ () => location.href = href }>{ children }</div>  
    }
    
    
    return <a className={ className } href={ href } style={ style }>{ children }</a>  
}

ExternalLink.propTypes = {
  href: PropTypes.string,
  button : PropTypes.bool,
  renderAs : PropTypes.string
}

export default ExternalLink;






