import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { 
    invalidatePosts, 
    invalidateMessageBoard, 
    fetchPostsIfNeeded, 
    createPost, 
    fetchMorePosts, 
    deletePost, reportPost, markMessageBoardRead,
    applyPendingPosts,
    fetchMessageBoardIfNeeded 
} from '../../redux/messageBoard/actions'

import { makeGetPosts, makeGetHasPendingPosts, makeGetMessageBoard } from '../../redux/messageBoard/selectors'

import { useSnackbar } from '../../hooks/useSnackbar'

import useUnread from './useUnread'

import ReportDialog from './ReportDialog'
import ConfirmationDialog from './ConfirmationDialog'
import CreatePost from './CreatePost'
import { useTranslator } from '../../hooks/useTranslator'
import { 
    Icon,
    ChangeNotification,
    Spinner,
    ToolbarButton,
    BodyCopy,
    Box,
    ListView
    
} from 'components'
import { AsyncButton } from '../../molecules'

import PostItem from './PostItem'


const DialogConductor = ({ dialog, onClose }) => {
    if (!dialog)
        return null;

    switch(dialog.type) {
        case 'confirm':
            return <ConfirmationDialog open={ true }  { ...dialog.props } onClose={ onClose } />
        case 'report':
            return <ReportDialog open={ true }  { ...dialog.props } onClose={ onClose } />            
        default:
            return null;        
    }
}



/* Post Header */

const MessageBoard = ({ messageBoardId }) => {
    const dispatch = useDispatch();
    const getPosts = useMemo(() => makeGetPosts(), []);
    const getHasPendingPosts = useMemo(() => makeGetHasPendingPosts(), [])
    const getMessageBoard = useMemo(() => makeGetMessageBoard(), [])
    const posts = useSelector(state => getPosts(state, messageBoardId));
    const hasPendingPosts = useSelector(state => getHasPendingPosts(state, messageBoardId));
    const messageBoard = useSelector(state => getMessageBoard(state, messageBoardId));
    const [showSnackbar] = useSnackbar();
    const [dialog, setDialog]  = useState();
    const [unread] = useUnread(messageBoardId);
    const t = useTranslator();

    const [showCreate, setShowCreate]  = useState(false);

    const role = (messageBoard && messageBoard.item && messageBoard.item.permissions && messageBoard.item.permissions.role) || 'none';

    const onRefresh = () => {
        dispatch(invalidatePosts(messageBoardId));
        dispatch(fetchPostsIfNeeded(messageBoardId));
    }

    useEffect(() => {
        dispatch(invalidateMessageBoard(messageBoardId));
        dispatch(fetchMessageBoardIfNeeded(messageBoardId));
        dispatch(fetchPostsIfNeeded(messageBoardId));
        dispatch(markMessageBoardRead(messageBoardId));

        return () => {
            dispatch(invalidateMessageBoard(messageBoardId));
        }
    }, [])


    const onCreate = (data) => {
        dispatch(createPost(messageBoardId, data));
        setShowCreate(false);
    }

    const onMore = () => {
        return dispatch(fetchMorePosts(messageBoardId));
    }

    const getFresh = () => dispatch(applyPendingPosts(messageBoardId));

    const onCommandConfirm = (command, post) => {
        switch(command) {
            case 'delete':
                
                dispatch(deletePost(messageBoardId, post.id, post.parent && post.parent.id)).then(
                    () => showSnackbar(t('messageboard.post_deleted'), 'success'),
                    () => showSnackbar(t('common.an_error_occurred'), 'error')
                );
                break;
        }
    }

    const onCommand = (command, post) => {
        switch(command) {
            case 'delete':
                setDialog({
                    type : 'confirm',
                    props : {
                        title : t('messageboard.delete_post_prompt_heading'),
                        text : t('messageboard.delete_post_prompt_message'),
                        onConfirm : () => {
                            dispatch(deletePost(messageBoardId, post.id, post.parent)).then(
                                () => showSnackbar(t('messageboard.post_deleted'), 'success'),
                                () => showSnackbar(t('common.an_error_occurred'), 'error')
                            );
                        }
                    }
                })
                break;
                case 'report':
                        setDialog({
                            type : 'report',
                            props : {
                                title : 'Report post',
                                onConfirm : (reason) => {
                                    dispatch(reportPost(messageBoardId, post.id, { reason })).then(
                                        () => showSnackbar(t('messageboard.post_reported'), 'success'),
                                        () => showSnackbar(t('common.an_error_occurred'), 'error')
                                    );
                                }
                            }
                        })
                        break;                
        }
    }

    return (
        <>
            <Box height="s500" display="flex" justifyContent="flex-end" alignItems="center">
                <ToolbarButton selected={ showCreate } onClick={ () => setShowCreate(!showCreate) } >
                    <Icon type="pen" />
                </ToolbarButton>
            </Box>
                
            <CreatePost show={ showCreate } onCreate={ onCreate } onCancel={ () => setShowCreate(false) } />

            <Box px="m">
                <ChangeNotification show={ hasPendingPosts } text={ t('messageboard.new_posts_click_to_refresh') } onClick={ getFresh }>
                    <ListView>
                        {
                            posts.items.map(item => (
                                <PostItem key={ item.id } role={ role } messageBoardId={ messageBoardId } post={ item } onCommand={ onCommand } />
                            ))
                        }
                    </ListView>
                </ChangeNotification>
                  
                { posts.isFetching && <Spinner /> }

                { !posts.isFetching && posts.links && posts.links.next && 
                    <Box height="s400" display="flex" alignItems="center" justifyContent="center">
                        <AsyncButton onClick={ onMore } size="xs">{  t('messageboard.more_comments_caption') }</AsyncButton> 
                    </Box>
                }

                {
                    posts.didFetch && posts.items.length == 0 &&
                    <BodyCopy textAlign="center" py="l">
                        <em>{  t('messageboard.no_posts_message') }</em>
                    </BodyCopy>
                }
            </Box>

            <DialogConductor dialog={ dialog } onClose={ () => setDialog() } />
        </>
    )
}

export default MessageBoard;