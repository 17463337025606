import React from 'react'
import PropTypes from 'prop-types'

import { lo } from 'utils'

import * as styles from './PlayerByline.scss'
import { Icon } from '.';

const DefaultByLine = ({ star, team, position }) => {
    return  <div className={ styles.container }>
                { star && <Icon type="star" color="utilityYellow" size="1-2x" /> }

                { team && lo(team, 'name') } 
                { team && position && ' - ' }
                { position && lo(position, 'name') }
            </div>
}

const CompactByLine = ({ star,  team, position }) => {
    return  <div className={ styles.container }>
                { star && <Icon type="star" color="utilityYellow" size="1-2x" /> }

                { team && lo(team, 'abbreviation') } 
                { team && position && ' - ' }
                { position && lo(position, 'abbreviation') }
            </div>
}

const AutoByLine = ({ star, team, position }) => {
    return  <div className={ styles.container }>
                { star && <Icon type="star" color="utilityYellow" size="1-2x" /> }

                <span className={ styles.abbr }>{ team && lo(team, 'abbreviation') }</span>
                <span className={ styles.full }>{ team && lo(team, 'name') }</span>
                { team && position && ' - ' }
                <span className={ styles.abbr }>{ position && lo(position, 'abbreviation') }</span>
                <span className={ styles.full }>{ position && lo(position, 'name') }</span>
            </div>
}


const PlayerByline = ({ star, team, position, form }) => {
    switch(form) {
        case 'default':
            return <DefaultByLine star={ star } team={ team } position={ position } />
        case 'compact':
            return <CompactByLine star={ star } team={ team } position={ position } />
        case 'auto':
            return <AutoByLine star={ star } team={ team } position={ position } />
    }
}

PlayerByline.propTypes = {
    team: PropTypes.object,
    position: PropTypes.object,
    form: PropTypes.string.isRequired,
    warning: PropTypes.bool,
    star: PropTypes.bool
}

PlayerByline.defaultProps = {
    form : 'auto'
}

export default PlayerByline;
