import React from 'react'
import RoutedDesktopSiteHeader from './RoutedDesktopSiteHeader'

import GlobalHeader from '../../../components/GlobalHeader'
import GlobalIdentity from '../../../components/GlobalIdentity'
import { HorizontalMenu, MenuItem } from '../../../components/Menu'

const AccountHeader = ({ children }) => {

    return (
        <>
        <GlobalHeader backgroundImageUrl="https://staging-cdn-fs-swush-com.s3.amazonaws.com/img/holdet/header-background.jpg" >
            <RoutedDesktopSiteHeader></RoutedDesktopSiteHeader>

            <GlobalIdentity title="Konto"/>

           
        </GlobalHeader>

        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
            <HorizontalMenu>
                <MenuItem as="a" href={ "/da/account" }>Konto</MenuItem>        
                <MenuItem as="a" href={ "/da/account/friends" }>Venner</MenuItem>        
                <MenuItem as="a" href={ "/da/account/notifications" }>Meddelelser</MenuItem>        
                <MenuItem as="a" href={ "/da/account/prizes" }>Præmier</MenuItem>        
                <MenuItem as="a" href={ "/da/account/archive"  }>Arkiv</MenuItem>        
            </HorizontalMenu>
            </div>
        </>
    )
}

AccountHeader.propTypes = {
}

export default AccountHeader;
