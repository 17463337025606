import React from 'react'

import styled from 'styled-components'

export const styledHeading = (tag) => styled(tag)`
    text-align : ${ props => props.alignment };
    ${ props => props.margin && `margin: ${ props.margin}` };
`;

const H1 = styledHeading('h1');
const H2 = styledHeading('h2');
const H3 = styledHeading('h3');
const H4 = styledHeading('h4');
const H5 = styledHeading('h5');
const H6 = styledHeading('h6');

const getComponent = (size) => {
    switch(size) {
        case 1: return H1;
        case 2: return H2;
        case 3: return H3;
        case 4: return H4;
        case 5: return H5;
        case 6: return H6;
    }
}

const Heading = ({ size, alignment, margin, children }) =>{
    const Component = getComponent(size);
    return <Component alignment={ alignment } margin={ margin }>{ children }</Component>
}

Heading.propTypes = {
}

Heading.defaultProps = {
    size : 1
}

export default Heading;

