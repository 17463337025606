import React from 'react'

import { 
    Dialog, DialogHeader, DialogContent, DialogFooter,
    Button,
    RegularText,
    Box
} from 'components'

import { useTranslator } from '../hooks/useTranslator'
import { useParams } from 'react-router-dom';

const GameClosedDialog = ({ open, onClose }) => {
    const t = useTranslator();

    //

    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogHeader>{ t('common.game_closed_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('common.game_closed_description') }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                
                    <Button inline primary small onClick={ onClose }>{ t('common.ok_caption') }</Button>
                </Box>
            </DialogFooter>
        </Dialog>
    )
}

export default GameClosedDialog;