import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const  WindowsResizeObserver  = ({ onChange }) => {
    
    const onResize = useCallback(() => { onChange() });


    useEffect(() => {

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    })

    return null;
}

WindowsResizeObserver.propTypes = {
    onChange : PropTypes.func.isRequired
}

WindowsResizeObserver.defaultProps = {
}


export default WindowsResizeObserver;