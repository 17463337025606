import React from 'react'
import PropTypes from 'prop-types'

class CSSVariables extends React.PureComponent{
    constructor(props) {
        super(props);
        this.el = null;

        this.setVariables = this.setVariables.bind(this);
    }

    setVariables(vars, nextVars) {
        vars = vars || {};
        nextVars = nextVars || {};

        var e =  (this.props.root) ? document.querySelector(':root') : this.el;

        Object.keys(vars).forEach(k => {
            var name = '--' + k.split(/(?=[A-Z])/).map(k => k.toLowerCase()).join('-');
            e.style.removeProperty(name);
        });

        Object.keys(nextVars).forEach(k => {
            const value = nextVars[k];
            if (typeof value !== 'string')
                return;

            var name = '--' + k.split(/(?=[A-Z])/).map(k => k.toLowerCase()).join('-');
            e.style.setProperty(name, nextVars[k]);
        });
    }


    componentDidMount() {
        this.setVariables({}, this.props.vars);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setVariables(this.props.vars, nextProps.vars);
    }

    render() {
        return <div className={ this.props.className } ref={ (el) => this.el = el }>{ this.props.children }</div>
    }  
}


CSSVariables.propTypes = {
    vars : PropTypes.object,
    root : PropTypes.bool
}

export default CSSVariables;