
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { fetchGameFantasyTeamsIfNeeded } from '../redux/fantasyTeam/actions'
import { makeGetGameFantasyTeams } from '../redux/fantasyTeam/selectors'

const useFantasyTeams = (gameId) => {
    const dispatch = useDispatch();
    const getFantasyTeams = useMemo(() => makeGetGameFantasyTeams(), []);
    const fantasyTeams = useSelector(state => getFantasyTeams(state, gameId));

    useEffect(() => {
        if (gameId) {
            dispatch(fetchGameFantasyTeamsIfNeeded(gameId))
        }
    }, [gameId]);

    return [fantasyTeams.items, fantasyTeams.isFetching, fantasyTeams.didFetch];
}

export default useFantasyTeams;