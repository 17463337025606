import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmailIdentity, OnetimePasswordInput, Form, FormField,HelpLabel, Button, AuthenticationHeader, Fieldset } from 'components'
import { BusyButton } from '../../molecules'
import { signupSubmitCode, goto } from '../../redux/auth/actions'
import { useTranslator } from '../../hooks/useTranslator'

export const ResetCode = ({  }) => {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const translator = useTranslator();
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.signup);
    const inputRef = useRef()

    const submitEnabled = code && code.length > 0;
   
    // Focus input on start.
    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef.current])


    const onSubmitInternal = e => {
        e.preventDefault();
        if (submitEnabled) {
            dispatch(signupSubmitCode(email, code));
        }
    }

    const onRetry = () => {
        dispatch(goto('reset_confirm'))
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>Nulstil adgangskode</AuthenticationHeader>
            
            <p>Vi har sendt en kode til dig for at verificere din e-mail. Tjek evt. spam/uønsket mail.</p>

            <EmailIdentity email={ email } />

            <Form problems={ problems } onSubmit={ onSubmitInternal }>
                <Fieldset>
                    <FormField name="code">
                        <HelpLabel text={ translator('authentication.codeLabel' )}>
                            <Button type="button" tiny transparent onClick={ onRetry }>Prøv igen</Button>
                        </HelpLabel>
                        <OnetimePasswordInput ref={ inputRef } value={ code } onChange={ (e) => setCode(e.target.value)} />
                    </FormField>
                </Fieldset>

                <BusyButton type="submit" disabled={ !submitEnabled } busy={ isBusy } label={ translator('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ translator('authentication.continueButtonCaption') }</BusyButton>
            </Form>
        </React.Fragment>
    )
}
