import React, { useEffect, useCallback, useMemo } from 'react'

import { useParams, Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import  RemountElement from '../containers/RemountElement'
import { Box, PageContentContainer,  Icon, ContextMenuPortal, ContextMenu,  NotificationIcon, Text, Avatar } from 'components'
import useFlyout from '../hooks/useFlyout'

import auth from '../common/auth';

import MessageBoardProvider from '../containers/messageBoard/MessageBoardProvider'


import { fetchMessageBoardIfNeeded, markMessageBoardRead } from '../redux/messageBoard/actions';

import { makeGetUnreadMessageBoardPosts  } from '../redux/messageBoard/selectors';

import useCompetition from './useCompetition'
import { useTranslator } from '../hooks/useTranslator'

import useCompetitionShareDialog from './useCompetitionShareDialog'
import Share from './Share'
import TopBanner from '../containers/TopBanner'
import { RequestAds } from '../components/ads'




const useMessageBoard = (messageBoardId) => {
    const dispatch = useDispatch();

    const getUnreadMessageBoardPosts = useMemo(() => makeGetUnreadMessageBoardPosts(), []);
    const unread = useSelector(state => {
        return messageBoardId && getUnreadMessageBoardPosts(state, messageBoardId) || 0
    });

    useEffect(() => {
        if (messageBoardId) {
            dispatch(fetchMessageBoardIfNeeded(messageBoardId));
        }
    }, [messageBoardId])

    const markRead = () => {
        dispatch(markMessageBoardRead(messageBoardId));
    }



    return [unread, markRead];
}

const useCommandHandler = (competition) => {
    const [showFlyout] = useFlyout();
    const params = useParams();
    const [share] = useCompetitionShareDialog();

    const handleCommand = useCallback((command) => {
        switch(command) {
            case 'messageBoard':
                showFlyout('competition', { competitionId : competition.id, initialTab : 'messageBoard' });
                break;
            case 'share':
                share(competition, params.language);
                break;
            case 'more':
                showFlyout('competition', { competitionId : competition.id });
                break;
        }
        
    });

    return [handleCommand]
}


const Header = ({ competition }) => {
    const [showFlyout] = useFlyout();
    const [unread] = useMessageBoard(competition && competition.messageBoard && competition.messageBoard.id);
    
    const userId = auth.getUserId();
    const isOwner = competition && competition.owner && userId == competition.owner.id;
    const t = useTranslator();

    const typeName = competition && t(`competitions.format_${ competition.type }`);
    const [handleCommand] = useCommandHandler(competition);


    const props = competition && competition.messageBoard ? { messageBoardId : competition.messageBoard.id } : {}

    const items = useMemo(() => {
        let items = [];

        if (competition.kind == 'groupGame')
            items.push({ command : 'share', label : t('common.share_invite_caption'), icon : 'share' });
        
        if (competition.messageBoard)
            items.push({ command : 'messageBoard', label : t('common.messageboard'), icon : 'speechBubbleOutline', renderIcon : item => unread ? <NotificationIcon count={ unread } /> : <Icon type={ item.icon } /> });
        
        if (competition.kind == 'groupGame')
            items.push({ command : 'more', icon : 'meatballs' });

        return items;
    }, [competition, unread]);


    return (
        <MessageBoardProvider { ...props }>
        

            <Box display="flex" height="s500" alignItems="center" px="m">
                <Avatar variant="rounded" size="small" mr="s200" name={ competition && competition.name }  src={ competition && competition.imageUrl }/>
                
                <Box flex="1">
                    <Text style={{ fontWeight: "bold" }}>{ competition && competition.name }</Text>
                    <Text size="xs" color="siteForegroundMeta">{ typeName }</Text>
                </Box>

                <ContextMenu items={ items } showLabel={ false } onCommand={ handleCommand }/>
            </Box>

        </MessageBoardProvider>
    )
}


const Competition = () => {
    const params = useParams();
    const [competition] = useCompetition(params.competitionId);

    return (
        <React.Fragment>
            <PageContentContainer>
                <TopBanner />
                <Header competition={ competition }></Header>
                    <Switch>
                        <Route path="/:language/games/:gameSlug/:gameId/competitions/:competitionSlug/:competitionId/share" component={ Share }/>
                        <Route>
                            <div className="legacy-competition">
                                <RemountElement targetId="main" />
                            </div>
                        </Route>
                    </Switch>

                <RequestAds/>
            </PageContentContainer>
        </React.Fragment>
    )
}

export default Competition;