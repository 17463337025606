import React from 'react';
import PropTypes from 'prop-types'

import SoccerMatchCard from '../../soccer/SoccerMatchCard'

import GameNavigationService from '../../GameNavigationService'

export const SoccerMatchCardWidget = ({ editMode, game, hideGroup, match }) => {
    if (editMode) {
        return <div style={{ width : '100%', height: '192px', background: '#ccc'}}>
                    SoccerMatchView
            </div>
    }



    if (!match)
        return null;

    
    const item = {
        ...match,
        participants : match.participants.reduce((agg, p) => {
            agg[p.type] = p.team;
            return agg;
        }, {})
    }


    return (
        <GameNavigationService>{ ({ showMatch }) => (
            <SoccerMatchCard item={ item }  hideGroup={ hideGroup } onClick={ () => showMatch(item.id) }></SoccerMatchCard>

        )}</GameNavigationService>
    )
}

