import React from 'react'
import styled from 'styled-components'

import {
    space, 
    lineHeight,
    fontSize,
    fontStyle,
    size, 
    color,
    typography,
    border,
    flexbox,
    system
} from 'styled-system'

const StyledDynamicComponent = styled.div`
    ${ border }
    ${ space }
    ${ lineHeight }
    ${ fontSize }
    ${ fontStyle }
    ${ size }
    ${ color }
    ${ typography }
    ${ flexbox }
    ${system({ textTransform: true })}
`;

const DynamicComponent = React.memo(({ tag = "div", children, ...props }) => {
    const WithComponent = StyledDynamicComponent.withComponent(tag);
    return <WithComponent { ...props }>{ children }</WithComponent>
})


export default DynamicComponent;

