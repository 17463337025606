import { useState, useEffect } from 'react';

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(null);

    useEffect(() => {
        const media = window.matchMedia(query);

        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        
        const listener = () => {
            setMatches(media.matches);
        };
        
        media.addListener(listener);
        
        return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
}


export default useMediaQuery;