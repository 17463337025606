import React, { useContext, useState, useRef, useEffect, useCallback } from 'react'

import Box from './Box'

import styled, { css } from 'styled-components'
import Icon from './Icon'

import { space } from 'styled-system';

const InputStyle = css`
    width: 100%;
    border-radius: 3px;
    border: 3px solid transparent;
    font-size: 14px;
    color: ${ props => props.theme.colors.inputForeground };
    
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    transition: border-color 0.2s linear;
    will-change: border-color;
    margin: 0 !important;

    border: 1px solid #ccc;
    box-sizing: border-box;

    outline: 0;

    :focus {
        border-color: ${ props => props.theme.colors.utilityBlue };
    }

    ${ space };
`

const Input = styled.input`
    height: 48px; 
    padding: 0 12px;
    ${ InputStyle }
`

export const Textbox = styled(Input).attrs(props => ({
    type : 'text',
    autocomplete : props.autocomplete
}))`
`

export const Textarea = styled.textarea`
    min-height: calc(var(--vertical-rythm) * 2);
    padding: 12px;
    ${ InputStyle }
`



export const Password = styled(Input).attrs(props => ({
    type : 'password',
    autoComplete : props.autoComplete
}))`
`

export const FauxPassword = styled(Input).attrs(props => ({
    type : 'text'
}))`
    -webkit-text-security: disc;
`

export const OnetimePasswordInput = styled(Input).attrs(props => ({
    type : 'number'
}))`
    text-align: center;
    font-family: monospace;
    font-size: 24px;

    ::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`


const StyledInputWithIconContainer = styled.div`
    position: relative;
    ${Input} {
        padding-right: 48px;
    }
` 

const StyledInputWithIconIcon = styled.div`
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--meta-text-color);
    cursor: pointer;
    user-select: none;
    
`

const StyledEmail = styled(Input).attrs(props => ({
    type : 'email',
    name : 'email',
    autoComplete: 'username',
    placeholder : 'name@example.com'
}))`
`


export const Email = React.forwardRef(
    ({  onClear, ...otherProps }, ref) => {
        

        return(
            <StyledInputWithIconContainer>
                <StyledEmail ref={ ref } {...otherProps }/>
                
                <StyledInputWithIconIcon onClick={ onClear }>
                    <Icon type="close" />
                </StyledInputWithIconIcon>
            </StyledInputWithIconContainer>
        )
    }
)


export const PasswordInput = React.forwardRef(
    ({ ...otherProps }, ref) => {
        const [showCharacters, setShowCharacter] = useState(false);

        return(
            <StyledInputWithIconContainer>
                { showCharacters && <Textbox ref={ ref } {...otherProps }/> || <Password ref={ ref } {...otherProps } /> }
                
                <StyledInputWithIconIcon  onClick={ () => setShowCharacter(!showCharacters) }>
                    <Icon type={ showCharacters ? 'eyeOutline' : 'eyeOffOutline' } />
                </StyledInputWithIconIcon>
            </StyledInputWithIconContainer>
        )
    }
)

export const FauxPasswordInput = React.forwardRef(
    ({ ...otherProps }, ref) => {
        const [showCharacters, setShowCharacter] = useState(false);

        return(
            <StyledInputWithIconContainer>
                { showCharacters && <Textbox ref={ ref } {...otherProps }/> || <FauxPassword ref={ ref } {...otherProps } /> }
                
                <StyledInputWithIconIcon  onClick={ () => setShowCharacter(!showCharacters) }>
                    <Icon type={ showCharacters ? 'eyeOutline' : 'eyeOffOutline' } />
                </StyledInputWithIconIcon>
            </StyledInputWithIconContainer>
        )
    }
)


export const Label = styled.label`
    display: block;
    min-height: 24px;
    line-height: 24px;
    color: #8A8A8A;
    font-size: 11px;

    transition: color 0.2s linear;
    will-change: color;

`

const StyledHelpLabel = styled.div`
    display: flex;

    > *:first-child {
        flex: 1;
    }
`

export const HelpLabel = ({ text, children }) => {
    return (
        <StyledHelpLabel>
            <Label>{ text }</Label>
            <span>{ children }</span>
        </StyledHelpLabel>
    )

}

const StyledInputGroup = styled.div`
    position: relative;
`

export const InputGroupButton = styled.div`
    width: 48px;
    height: 48px;    

    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;    
    justify-content: center;

    cursor: pointer;
`

export const InputGroupTextbox = styled(Textbox)`
    padding-right: 48px !important;
    box-sizing: border-box;

`


export const InputGroup = ({ children }) => {
    return (
        <StyledInputGroup>
            { children }
        </StyledInputGroup>
    )
}


export const FormGroup = styled.div`
    margin-bottom: 12px;

    ${
        props => props.state == 'error' && css`
            
        
            ${FieldError} {
                color: ${ props => props.theme.colors.utilityRedContrast };
            }

            ${Input} {
                border-color: ${ props => props.theme.colors.utilityRed };
            }
        `
    };
    

`

export const FieldError = styled.label`
    display: block;

    margin-top: 6px;
    line-height: 18px;
    color: var(--utility-red-foreground);
    font-size: 11px;

    transition: color 0.2s linear;
    will-change: color;
`

export const FieldHelpText = styled.label`
    display: block;

    margin-top: 6px;
    line-height: 18px;
    color: #8A8A8A;
    font-size: 11px;

    transition: color 0.2s linear;
    will-change: color;
`

export const Fieldset = styled.div`
    margin-bottom: 24px;
`



const FormContext = React.createContext();

export const Form = ({ problems, children, ...otherProps }) => {
    return (
        
        <FormContext.Provider value={{ problems }}>
            <form { ...otherProps }>
                { children }
            </form>
        </FormContext.Provider>
    )
}

export const FormField = ({ name, children }) => {
    const context = useContext(FormContext);

    return (
        <FormGroup state={ context.problems && context.problems[name] && 'error' }>
            { children }
            { 
                context.problems && 
                context.problems[name] &&
                context.problems[name].map((err, index) => {
                    return (
                        <FieldError key={ index }>{ err }</FieldError>
                    )
                })
            }
        </FormGroup>
    )

}






const StyledSearch = styled.div`
    height: 48px;

    border-radius: 3px;
    border: none;
    font-size: 14px;
    
    padding: 0 0 0 12px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex: 1;

    background: #fff;
`;

const StyledInput = styled.input`
    height: 48px;
    border: none;
    flex: 1;
    color: #8A8A8A;
    min-width: 0;
    width: 0;

    :focus {
        outline: none;
    }
`;


export const Search = ({ value, autofocus, placeholder, onSearch }) => {
    const inputRef = useRef();
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value || '');
    }, [value])

    useEffect(() => {
        if (autofocus)
            inputRef.current.focus();
    }, [autofocus, inputRef])

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter') {
            onSearch(text);
            inputRef.current.select();
        }
    })

    const handleFocus = useCallback((e) => {
        e.target.select();
    })

    const handleReset = () => {
        setText('');
        onSearch('');
    }

    return (
        <InputGroup>
            <InputGroupTextbox ref={ inputRef } value={ text } onFocus={ handleFocus } onKeyPress={ handleKeyPress } placeholder={ placeholder } onChange={ (event) => setText(event.target.value) } />
            <InputGroupButton onClick={ () => handleReset() }>
                <Icon type="close" />
            </InputGroupButton>
        </InputGroup>
    )
}
