import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

import { display, space } from 'styled-system';

const StyledTopbar = styled.div`
    font-size: 14px;

    height: 48px;

    z-index: 1000;

    display: flex;
    align-items: center;
    box-sizing: border-box;

    width: 100%;

    ${ props => props.themeName && css`
        background-color: ${ props => props.theme.colors.themes[props.themeName].primary };
        color: ${ props => props.theme.colors.themes[props.themeName].primaryForeground };
    `}

    ${ props => props.sticky && css`
    
        position: sticky;
        top: 0;
    `}

    ${ display }
    ${ space }
`

const TopBar = ({ children, ...props })  => {

    
    return (
        <StyledTopbar { ...props }>
            { children }
        </StyledTopbar>
    )
}

TopBar.propTypes = {
    backgroundColor : PropTypes.string,
    sticky : PropTypes.bool,
    stickyOffsetTop : PropTypes.number
}

TopBar.defaultProps = {
    stickyOffsetTop : 0
}


export default TopBar;