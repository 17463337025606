import axios from 'axios'
import { normalize } from 'normalizr'

import { watchlistSchema } from '../../schemas'
import auth from '../../common/auth'

import * as types from './types';

/* Fetch Statistics By Round */

const fetchWatchlistRequest = (gameId) => ({ type: types.FETCH_WATCHLIST_REQUEST, gameId });
const fetchWatchlistSuccess = (gameId, payload) => ({ type: types.FETCH_WATCHLIST_SUCCESS, gameId, payload });
const fetchWatchlistFailure = (gameId) => ({ type: types.FETCH_WATCHLIST_FAILURE, gameId });

 const fetchWatchlist = (gameId) => (dispatch) => {
    if (!auth.authenticated)
        return;

    dispatch(fetchWatchlistRequest(gameId));
    
    const accessToken = auth.getAccessToken();
    axios.get(`/games/${ gameId }/watchlist?access_token=${ accessToken }`).then(response => {
        dispatch(fetchWatchlistSuccess(gameId, normalize(response.data, [watchlistSchema])));
    }, error => {
        dispatch(fetchWatchlistFailure(gameId));
    });
}

const shouldFetchWatchlist = (state, gameId) => {
    const { watchlist } = state;

    if (!watchlist.byGame[gameId + '']) {
        return true;
    } else if (watchlist.byGame[gameId + ''].isFetching) {
        return false;
    } else {
        return watchlist.byGame[gameId + ''].didInvalidate;
    }
}

export const fetchWatchlistIfNeeded = (gameId) => {
    return (dispatch, getState) => {
        if (shouldFetchWatchlist(getState(), gameId)) {
            return dispatch(fetchWatchlist(gameId))
        }
    }
}



/* Add to watchlist */

const addToWatchlistRequest = (gameId, playerId) => ({ type: types.ADD_TO_WATCHLIST_REQUEST, gameId, playerId });
const addToWatchlistSuccess = (gameId, playerId, payload) => ({ type: types.ADD_TO_WATCHLIST_SUCCESS, gameId, playerId, payload });
const addToWatchlistFailure = (gameId, playerId) => ({ type: types.ADD_TO_WATCHLIST_FAILURE, gameId, playerId });

export const addToWatchlist = (gameId, playerId) => (dispatch) => {

    if (!auth.authenticated)
        throw 'User is not authenticated.';

    dispatch(addToWatchlistRequest(gameId, playerId));

    const accessToken = auth.getAccessToken();
    return axios.post(`/games/${ gameId }/players/${ playerId }/watchlist?access_token=${ accessToken }`, {}).then(response => {
        dispatch(addToWatchlistSuccess(gameId, playerId, normalize(response.data, watchlistSchema) ));
    }, error => {
        dispatch(addToWatchlistFailure(gameId, playerId));
    })
}


/* Remove from watchlist */

const removeFromWatchlistRequest = (gameId, playerId) => ({ type: types.REMOVE_FROM_WATCHLIST_REQUEST, gameId, playerId });
const removeFromWatchlistSuccess = (gameId, playerId, payload) => ({ type: types.REMOVE_FROM_WATCHLIST_SUCCESS, gameId, playerId, payload });
const removeFromWatchlistFailure = (gameId, playerId) => ({ type: types.REMOVE_FROM_WATCHLIST_FAILURE, gameId, playerId });

export const removeFromWatchlist = (gameId, playerId) => (dispatch) => {
    if (!auth.authenticated)
        throw 'User is not authenticated.';

    dispatch(removeFromWatchlistRequest(gameId, playerId));
    const accessToken = auth.getAccessToken();
    return axios.delete(`/games/${ gameId }/players/${ playerId }/watchlist?access_token=${ accessToken }`).then(response => {
        dispatch(removeFromWatchlistSuccess(gameId, playerId, {}));
    }, error => {
        dispatch(removeFromWatchlistFailure(gameId, playerId));
    });
}