import React from 'react';
import PropTypes from 'prop-types'

import { FlyoutSummary, FlyoutSummaryItem, FlyoutSummaryMedia } from 'components'

export const FlyoutSummaryMediaWidget = ({ children }) => (
    <FlyoutSummaryMedia>
            { children }
    </FlyoutSummaryMedia>
)


export const FlyoutSummaryItemWidget = ({ children }) => (
    <FlyoutSummaryItem>
            { children }
    </FlyoutSummaryItem>
)

export const FlyoutSummaryWidget = ({ children }) => (
    <FlyoutSummary>
        { children }
    </FlyoutSummary>

)
