// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kwFm93YsaLkfCbHvwyi9{display:flex;align-items:center;width:100%}.fSydx7PEIEzpfWLW9wKz{min-width:32px;position:relative;text-align:center;margin-right:12px}.zrjrVS6KWwIJCd2gcJt_{line-height:16px;font-size:14px;flex:1}`, "",{"version":3,"sources":["webpack://./src/components/MediaObject.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,UAAA,CAGJ,sBACI,cAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,sBACI,gBAAA,CACA,cAAA,CACA,MAAA","sourcesContent":[".container {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n}\r\n\r\n.media {\r\n    min-width: 32px;\r\n    position: relative;\r\n    text-align: center;\r\n    margin-right: 12px;\r\n}\r\n\r\n.content {\r\n    line-height: 16px;\r\n    font-size: 14px;\r\n    flex: 1;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `kwFm93YsaLkfCbHvwyi9`;
export var media = `fSydx7PEIEzpfWLW9wKz`;
export var content = `zrjrVS6KWwIJCd2gcJt_`;
export default ___CSS_LOADER_EXPORT___;
