import React, { useEffect } from 'react'
import Button from './Button'
import styled from 'styled-components'

const FacebookContext = React.createContext();

export const FacebookProvider = ({ appId, children }) => {
    useEffect(() => {

        if (appId) {
            window.fbAsyncInit = function() {
                FB.init({
                    appId      : appId,
                    cookie     : true,  // enable cookies to allow the server to access the session
                    xfbml      : true,  // parse social plugins on this page
                    version    : 'v2.5' // use version 2.1
                });
            };
        
            // Load the SDK asynchronously
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }

    }, [appId])
    

    return (
        <FacebookContext.Provider>
            { children }
        </FacebookContext.Provider>
    );
    

}


const useFacebook = () => {
    return FB;
}


const StyledFacebookLoginButton = styled.div`
    height: 36px;
    line-height: 36px;
    letter-spacing: 1px;
    background: #3478F6;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border-radius: var(--border-radius);
    padding: 0 12px;
    font-size: 14px;
    cursor: pointer;
`


export const LoginButton = ({ scopes, children, onAuthorize, onCancel }) => {
    const handleLogin = () => {
        if (!FB) {
            console.error('`FB` is not defined.')
        }
        else {
            FB.login(function(response) {
                if (response.authResponse) {
                    onAuthorize(response.authResponse);
                } else {
                    if (onCancel)
                        onCancel();
                }
            }, { 
                scope: (scopes || []).join(',') 
            });
        }
    }
    
    return <StyledFacebookLoginButton onClick={ handleLogin }>{ children }</StyledFacebookLoginButton>   
}