import React from 'react'

class LoadingIndicator extends React.PureComponent
{
    constructor(props) {

            super(props);
        this.onReady = this.onReady.bind(this);
    }

    componentDidMount() {
        document.getElementsByTagName('body')[0].classList.add('ready');
    }


    onReady()  {
        
    }

    render() {
        return null;
    }
}



export default LoadingIndicator;

