// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nRqPqWn6BYm0Up_EabY8 {
    position: relative;

    box-shadow: 6px 6px 15px rgba(0,0,0,0.15);
    border-radius: 5px;
    background-color: var(--card);
    color: var(--on-card);
}

.NyWSn1YARUMdN3lY2wWe {
    cursor : pointer;
    transition: background-color 0.2s ease-in-out;
}

.NyWSn1YARUMdN3lY2wWe:hover { background-color: var(--card-light); }
.NyWSn1YARUMdN3lY2wWe:hover .hZWVhhAyRfQbpk_lhI_f { background-color: var(--card-secondary-light); }
.NyWSn1YARUMdN3lY2wWe .w4przZEvwvQyani6kJEV { transition: background-color 0.2s ease-in-out; }

.w4przZEvwvQyani6kJEV {
    background-color: var(--card-secondary);

    margin: 0 9px;
    padding: 0 9px; 
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,yCAAyC;IACzC,kBAAkB;IAClB,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,6CAA6C;AACjD;;AAEA,8BAAmB,mCAAmC,EAAE;AACxD,oDAA2B,6CAA6C,EAAE;AAC1E,8CAAoB,6CAA6C,EAAE;;AAEnE;IACI,uCAAuC;;IAEvC,aAAa;IACb,cAAc;IACd,kBAAkB;AACtB","sourcesContent":[".container {\r\n    position: relative;\r\n\r\n    box-shadow: 6px 6px 15px rgba(0,0,0,0.15);\r\n    border-radius: 5px;\r\n    background-color: var(--card);\r\n    color: var(--on-card);\r\n}\r\n\r\n.clickable {\r\n    cursor : pointer;\r\n    transition: background-color 0.2s ease-in-out;\r\n}\r\n\r\n.clickable:hover { background-color: var(--card-light); }\r\n.clickable:hover .insert { background-color: var(--card-secondary-light); }\r\n.clickable .inset { transition: background-color 0.2s ease-in-out; }\r\n\r\n.inset {\r\n    background-color: var(--card-secondary);\r\n\r\n    margin: 0 9px;\r\n    padding: 0 9px; \r\n    border-radius: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `nRqPqWn6BYm0Up_EabY8`;
export var clickable = `NyWSn1YARUMdN3lY2wWe`;
export var insert = `hZWVhhAyRfQbpk_lhI_f`;
export var inset = `w4przZEvwvQyani6kJEV`;
export default ___CSS_LOADER_EXPORT___;
