import React from 'react';
import PropTypes from 'prop-types'

import MatchContext from '../../MatchContext'

import { number } from 'utils'


const formatValue = ( format, value ) => {
    switch(format) {
        case 'number':
            return number(value);
        default:
            return value;


    }
}

const Property = ({ format, name }) => (
    <MatchContext.Consumer>{ (match) => {
        return formatValue(format, match.properties[name]);
    }}</MatchContext.Consumer>

)

export default Property;