import React from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from './ResizeObserver'

import styled, { css } from 'styled-components'

import shadow from '../images/scrollwindow-left.png';


const Container = styled.div`
    position: relative;
`


const Window = styled.div`
    position: relative;
    width: 100%;
    overflow-x : auto;
    overflow-y : visible;
    user-select: none;
    -webkit-overflow-scrolling: touch;

    z-index: 99;
`

const Shadow = styled.div`
    position : absolute;
    height : 100%;
    width : 24px;
    background-size:24px;
    top: 0;
    pointer-events: none;
    opacity: 0;
    background-image: url(${ shadow });
    will-change: opacity;
    z-index: 100;

    ${
        props => props.position === 'left' && css`
            left: 0;
        `
    }


    ${
        props => props.position === 'right' && css`
            right: 0;
            // flip img horizontally
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
        `
    }

`



class ScrollContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.containerEl = React.createRef();
        this.leftEl = React.createRef();
        this.rightEl = React.createRef();

        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.update();
        this.containerEl.current.addEventListener('scroll', this.update);
    }

    componentWillUnmount() {
        this.containerEl.current.removeEventListener('scroll', this.update);
    }

    update() {
        const containerEl = this.containerEl.current;
        const rightEl = this.rightEl.current;
        const leftEl = this.leftEl.current;

        const left = containerEl.scrollLeft;
        const right = containerEl.scrollWidth - left - containerEl.clientWidth;

        if (containerEl.scrollWidth > containerEl.clientWidth) {
            let leftOpacity = left / this.props.shadowDistance;
            if (leftOpacity > 1)
                leftOpacity = 1;

            leftEl.style.opacity = leftOpacity;

            let rightOpacity = (right / this.props.shadowDistance);
            if (rightOpacity > 1)
                rightOpacity = 1;
            
            if (rightOpacity < 0)
                rightOpacity = 0;

            rightEl.style.opacity = rightOpacity;

            
        }
        else {
            leftEl.style.opacity = 0;
            rightEl.style.opacity = 0;
        }
       
    }


    render() {
                return (
            <ResizeObserver onDimensionsChanged={ () => this.update() }>
                <Container>
                    <Shadow ref={ this.leftEl } position="left"></Shadow>
                    <Window ref={ this.containerEl }>
                        { this.props.children }
                    </Window>
                    <Shadow ref={ this.rightEl } position="right"></Shadow>
                </Container>
            </ResizeObserver>
        )
    }
}


ScrollContainer.propTypes = {
    continousConstraint : PropTypes.bool.isRequired,
    shadowDistance : PropTypes.number.isRequired
}

ScrollContainer.defaultProps = {
    continousConstraint : true,
    shadowDistance : 128
}

export default ScrollContainer;