import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Datasource } from 'components'


import WithTeamPassport from '../../WithTeamPassport'

const TeamPassport = ({ teamId, name, editMode, children }) => {
    if (editMode) {
        return (
            <Datasource value={{ [name] : {} }}>
                {children }
            </Datasource> 
        )
    }
    else {

        return (
            <WithTeamPassport id={ teamId }>{ (passport) => (
                <Datasource value={{ [name] : passport }}>
                    { children }
                </Datasource>
            )}</WithTeamPassport>
        )
    }
}

TeamPassport.propTypes = {
    teamId : PropTypes.number,
    name : PropTypes.string
}

export default TeamPassport;

