export const AUTHENTICATION_SUCCESS         = 'auth/success';

export const LOGIN_REQUEST                  = 'login/request';
export const LOGIN_SUCCESS                  = 'login/success';
export const LOGIN_FAILURE                  = 'login/fail';

export const FACEBOOK_LOGIN_REQUEST         = 'facebook-login/request';
export const FACEBOOK_LOGIN_SUCCESS         = 'facebook-login/success';
export const FACEBOOK_LOGIN_FAIL            = 'facebook-login/fail';

export const FACEBOOK_SIGNUP_REQUIRED       = 'facebook-login/signup-required';

export const FACEBOOK_SIGNUP_REQUEST        = 'facebook-signup/request';
export const FACEBOOK_SIGNUP_SUCCESS        = 'facebook-signup/success';
export const FACEBOOK_SIGNUP_FAIL           = 'facebook-signup/fail';

export const CHECK_ACCOUNT_REQUEST          = "account/check/request";
export const CHECK_ACCOUNT_SUCCESS          = "account/check/success";
export const CHECK_ACCOUNT_FAIL             = "account/check/fail";

export const SIGNUP_SUBMIT_EMAIL_REQUEST    = 'signup/submit-email/request';
export const SIGNUP_SUBMIT_EMAIL_SUCCESS    = 'signup/submit-email/success';
export const SIGNUP_SUBMIT_EMAIL_FAIL       = 'signup/submit-email/failure';

export const SIGNUP_SUBMIT_CODE_REQUEST     = 'signup/submit-code/request';
export const SIGNUP_SUBMIT_CODE_SUCCESS     = 'signup/submit-code/success';
export const SIGNUP_SUBMIT_CODE_FAIL        = 'signup/submit-code/failure';

export const SIGNUP_SUBMIT_REQUEST          = 'signup/submit/request';
export const SIGNUP_SUBMIT_SUCCESS          = 'signup/submit/success';
export const SIGNUP_SUBMIT_FAIL             = 'signup/submit/failure';

export const SIGNIN_REQUEST                 = 'signin/request';
export const SIGNIN_SUCCESS                 = 'signin/success';
export const SIGNIN_FAIL                    = 'signin/failure';

export const REQUEST_RESET_REQUEST          = 'auth/request-reset/request';
export const REQUEST_RESET_SUCCESS          = 'auth/request-reset/success';
export const REQUEST_RESET_FAIL             = 'auth/request-reset/failure';


export const RESET_REQUEST                  = 'auth/reset/request';
export const RESET_SUCCESS                  = 'auth/reset/success';
export const RESET_FAIL                     = 'auth/reset/failure';

export const START                          = 'auth/start';
export const CANCEL                         = 'auth/cancel';
export const COMPLETE                       = 'auth/complete';
export const GOTO                           = 'auth/goto';

export const SET                            = 'auth/set';