import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import { rulesetSchema } from '../../schemas'



export const makeGetRuleset = () => (createSelector([
    (state, props) => props.id,
    (state) => state.entities.rulesets,
    (state) => state.entities.eventTypes,
    (state) => state.entities.fantasyEventTypes,
    (state) => state.entities.formations,
    (state) => state.entities.positions
], 
(id, rulesets, eventTypes, fantasyEventTypes, formations, positions) => { 
    const entities = {
        rulesets, 
        eventTypes, 
        fantasyEventTypes, 
        formations, 
        positions
    }

    return denormalize(id, rulesetSchema, entities); 

}
));