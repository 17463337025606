import React from 'react';
import PropTypes from 'prop-types'

import MatchContext from '../../MatchContext'

import { lo } from 'utils'

const Location = () => (
    <MatchContext.Consumer>{ (match) => {
        return lo(match.location, 'name')
    }}</MatchContext.Consumer>

)
export default Location;