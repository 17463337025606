import React, { useRef } from 'react'
import IconButton from './IconButton'
import Icon from './Icon'
import { Box } from '.'
import { Brevier, RegularText } from './Typography'
import { InputGroup, InputGroupButton, InputGroupTextbox, FormGroup, Label } from './Forms'
import { useSnackbar } from '../hooks/useSnackbar'
import { useTranslator } from '../hooks/useTranslator'
import { ToolbarButtonGroup, ToolbarButton } from './ToolbarButton'


const mapUrl = (type, subject, text, url) => {
    switch(type) {
        case 'email':
            return `mailto:?subject=${ subject || '' }&body=${ text ||'' }${ url }`;
        case 'sms':
            return `sms:&body=${ text ||'' }${ url }`;
        case 'facebook':
            return `https://www.facebook.com/sharer/sharer.php?u=${ url }`;
        case 'twitter':
            return `https://twitter.com/intent/tweet?text=${subject}&url=${url}`;
        case 'linkedin':
            return `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        default:
            return null;
    }
}

const medias = [
    { name : 'email', label : 'Email', icon : 'email' },
    { name : 'sms', label : 'SMS', icon : 'sms' },
    { name : 'facebook', label : 'Facebook', icon : 'facebook' },
    { name : 'twitter', label : 'Twitter', icon : 'twitter' },
    { name : 'linkedin', label : 'LinkedIn', icon : 'linkedin' }
]

export const Share = ({ subject, text, url, label, ...otherProps }) => {
    const ref = useRef();
    const inputRef = useRef();
    const [showSnackbar] = useSnackbar();
    const t = useTranslator();

    const onShare = (type) => {
        const nUrl = mapUrl(type, subject, text, url);
        window.open(nUrl);
    }

    const handleCopy = () => {
        const el = inputRef.current;

        /* Select the text field */
        el.select();
        el.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        window.getSelection().removeAllRanges();

        /* Alert the copied text */
        showSnackbar(t('common.snackbar_message_copied_link'));
    }



    return (
    
        <Box>

            <Box>
                <Label>{ label }</Label>
                <InputGroup>
                    <InputGroupTextbox ref={ inputRef } readOnly value={ url } />
                    <InputGroupButton onClick={ () => handleCopy() } title="Kopier">
                        <Icon type="copy" />
                    </InputGroupButton>
                </InputGroup>
            </Box>
            

            
                <ToolbarButtonGroup>
                    { 
                        medias.map(m => (
                            <ToolbarButton key={ m.name } onClick={ () => onShare(m.name) }>
                                <Icon type={ m.icon } />
                            </ToolbarButton>
                        ))
                    }
                </ToolbarButtonGroup>
                
            
        </Box>
        
    )     
    
}

export default IconButton;