import React, { useMemo } from 'react'

import { Datasource } from 'components'
import { pick } from 'utils'

const Group = ({ by, value, name, children }) => {

    const data = useMemo(() => {
        const grouped = (value || []).reduce((agg, v) => {
            const key = v[by] + '';

            (agg[key] = agg[key] || []).push(v);

            return agg;

        }, {});

        return Object.keys(grouped).map(g => ({ key : g, items : grouped[g] }));
    }, [by, value])

    return (
        <Datasource value={{ [name] : data }}>
            { children }
        </Datasource>
    )
}

export default Group;