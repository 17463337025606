import axios from 'axios'
import auth from '../../common/auth';
import * as types from './types';
import * as uiTypes from '../ui/types';


const requestCurrentUser = () => ({ type: types.CURRENT_USER_REQUEST })
const receiveCurrentUser = (user) => ({ type: types.CURRENT_USER_RECEIVE, user })
const currentUserFailure = () => ({ type: types.CURRENT_USER_FAILURE })

const fetchCurrentUser = () => (dispatch) => {
    dispatch(requestCurrentUser())

    const accessToken = auth.getAccessToken();

    axios.get(`users/me?access_token=${ accessToken }`).then(response => {
        dispatch(receiveCurrentUser(response.data));
        
    }, error => {
        dispatch(currentUserFailure());
    });
}

const shouldFetchCurrentUser = (state) => {
    const { me } = state.user;
    return me == 0;
}

export const fetchCurrentUserIfNeeded = () =>  (dispatch, getState) => {
    var state = getState();

    if (shouldFetchCurrentUser(state)) {
        return dispatch(fetchCurrentUser())
    }
}


// Fetch User

const fetchUserRequest = () => ({ type: types.FETCH_USER_REQUEST })
const fetchUserSuccess = (user)  => ({ type: types.FETCH_USER_SUCCESS, payload : user })
const fetchUserFailure = () => ({ type: types.FETCH_USER_FAILURE })

export const fetchUser = (userId) => (dispatch) => {
    dispatch(fetchUserRequest())
    
    axios.get(`users/${ userId }`).then(response => {
        dispatch(fetchUserSuccess(response.data));
    }, error => {
        dispatch(fetchUserFailure());
    });
}