import React, { Suspense, useState, useEffect } from 'react';

import { ErrorBoundary, Sidebar, LoadingIndicator, CSSVariables, SnackbarProvider, WindowResizeObserver, BreakpointProvider, TargetingProvider, FacebookProvider } from '../components'

import StaticWidgetRenderer  from './StaticWidgetRenderer'
import StaticComponentRenderer from './StaticComponentRenderer'

import GameContextProvider from './GameContextProvider'
import {
    withRouter,
    Route,
    Switch,
    useLocation
} from "react-router-dom";


//
import FlyoutConductor from './FlyoutConductor'
import ModalConductor from './ModalConductor';

import { TemplateContentRegion, PageRouteProvider } from './TemplateContentRegion'

import Header from './header/Header';

import GameContext from './GameContext'
import { Datasource } from 'components'

import ComponentContext from './ComponentContext'

import LinkHijacker from './LinkHijacker';

import componentMap from '../componentMap'

import { useAppConfig} from './AppConfigProvider';
import ViewContainer from './ViewContainer'
import { Authenticator } from './authentication/Authenticator'
import AuthPage from './AuthPage'
import LegacyHandler from './LegacyHandler'

const ArchivePage = React.lazy(() => import('../pages/ArchivePage'));
const LeaderboardArchivePage = React.lazy(() => import('../pages/LeaderboardArchivePage'));


import GlobalStyles from './GlobalStyles'

const RoutedContextProvider = ({ children }) => {

    return (
        <Switch>
            <Route path="/:language/games/:gameSlug/:gameId" render={ () => <GameContextProvider>{ children }</GameContextProvider> } />
            <Route render={ () => children } />
        </Switch>
    )
}


const AppContent = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const siteConfiguration = useAppConfig();



    useEffect(() => {
        setSidebarOpen(false);
    }, [location])



    return (
        <GameContext.Consumer>{ ({ game, gameConfiguration }) => {
            const theme = gameConfiguration ? { ...window.swush.theme, ...gameConfiguration.theme } : window.swush.theme;

            return (
                <React.Fragment>
                    <Suspense fallback={ <LoadingIndicator /> }>
                        <TargetingProvider value={ gameConfiguration && gameConfiguration.targeting }>
                            <Datasource value={{ game : game, gameConfig : gameConfiguration, siteConfig : window.swush }} root>    
                                <WindowResizeObserver onChange={ () => setSidebarOpen(false) }></WindowResizeObserver>
                                <CSSVariables vars={ theme || {} } >
                                    <Sidebar open={ sidebarOpen } menu={ <ViewContainer name={ siteConfiguration && siteConfiguration.sidebar && siteConfiguration.sidebar.view }  /> } onClose={  () => setSidebarOpen(false) }>
                                    </Sidebar>
                                    
                                    
                                    
                                    <Header themeName={ game ? game.theme : 'default' } onShowMenu={ () => setSidebarOpen(true) } />
                                    <LoadingIndicator />
                                        
                                            <Switch>
                                                <Route path="/:language/account/archive/leaderboards/:slug-:competitionId" component={ LeaderboardArchivePage }></Route>
                                                <Route path="/:language/account/archive" component={ ArchivePage }></Route>
                                            </Switch>
                                        

                                        <TemplateContentRegion  />
					                <ModalConductor/>
                                    <FlyoutConductor />
                                </CSSVariables>
                                <StaticWidgetRenderer />
                                <StaticComponentRenderer />
                            </Datasource>
                        </TargetingProvider>
                    <Authenticator />
                    </Suspense>
                </React.Fragment>
            )
        }}</GameContext.Consumer>
    )
}

const AppRouter = () => {
    return (
        <Switch>

            <Route path="/:language/account/signin"><div><AuthPage /><Authenticator canClose={ false } /></div></Route>
            <Route path="*"><AppContent /></Route>
        </Switch>
    )
}

const App = () => {
    const config = useAppConfig();
    

    const { appId } = config && config.facebook || { appId : null };

    return (
            <PageRouteProvider routes={ config && config.routes || [] }>
                <BreakpointProvider breakpoints={{ mobile : 0, tablet : 768, desktop : 1024 }}>
                    <ComponentContext.Provider value={ componentMap }>
                            <ErrorBoundary>
                                <RoutedContextProvider>
                                        <GlobalStyles></GlobalStyles>
                                        <SnackbarProvider>
                                            <FacebookProvider appId={ appId }>
                                                <AppRouter />
                                            </FacebookProvider>
                                            </SnackbarProvider>
                                    <LinkHijacker/>
                                    <LegacyHandler />
                                </RoutedContextProvider>
                            </ErrorBoundary>
                    </ComponentContext.Provider>
                </BreakpointProvider>
            </PageRouteProvider>
    )
};




export default withRouter(App)