import React, { useContext } from 'react';
import PropTypes from 'prop-types'

const Json = ({ value }) => {
    return typeof value === 'object' ? JSON.stringify(value) : value + '';
}

Json.propTypes = {
    value : PropTypes.object.isRequired
}

Json.defaultProps = {
    value : ''
}

export default Json;