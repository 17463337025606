import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types';

import { 
    Dialog, DialogHeader, DialogContent, 
    Text,
    Box,
    Share,
    UserIdentity,
    FlatList,
    ListViewItem,
    Avatar,
    XButton,
    Icon,
    FormGroup,
    Label
} from 'components'

import { useDispatch, useSelector } from 'react-redux'
import { AsyncButton } from '../molecules';
import { SelectList } from '../components';
import { useTranslator } from '../hooks/useTranslator'
import { useSnackbar } from '../hooks/useSnackbar'

import { invite, inviteAll } from '../redux/competition/actions'
import { fetchRecruits, fetchMoreRecruits, fetchRecruitsFromSelectedOption ,selectRecruitmentOption } from '../redux/recruits/actions'
import { getRecruits, getSelectedOption } from '../redux/recruits/selectors';

const InvitationButton = ({ item, onClick }) => {
    const t = useTranslator();
    
    if (!item) {
        return null;
    }
    if (item.user && item.user.isDeleted) {
        return  null;
    }
    else if (item.status == 'banned') {
        return  (
            <Box  display="flex" alignItems="center">
            <Text size="s" color="siteForegroundMeta">{ t('competitions.banned_caption') }</Text>
            <Icon type="check" size="1-2x" ml="s" color="personCrossed" />
        </Box>
        )
    }
    else if (item.status == 'enrolled') {
        return (
            <Box  display="flex" alignItems="center">
                <Text size="s" color="siteForegroundMeta">{ t('competitions.enrolled_caption') }</Text>
                <Icon type="check" size="1-2x" ml="s" color="utilityGreen" />
            </Box>
        )
     
    }
    else if (item.invited) {
        return (
            <Box  display="flex" alignItems="center" color="siteForegroundMeta">
                <Text size="s">{ t('competitions.invited_caption') }</Text>
                <Icon type="email" size="1-2x" ml="s"/>
            </Box>
        )

    }
    else {
        return <AsyncButton size="xs" minWidth="s700" onClick={ onClick }>{ t('competitions.invite_caption') }</AsyncButton>
    }
}


/*type, competitionId, query, arg, children*/

const InviteeList = ({ option, items, count, fetchMore, onInvite }) => {
    const t = useTranslator();

    const Item = ({ index, style}) => {
        const item = items[index];

        return (
            <ListViewItem style={ style } px="m">
                <Box display="flex" alignItems="center" height="s400">
                    <Box mr="s" flex="1">
                        <UserIdentity user={ item.user } />
                    </Box>
                    <Box>
                        <InvitationButton item={ item } size="xs" onClick={ () => { return onInvite(item.user) } }></InvitationButton>
                    </Box>
                </Box>
            </ListViewItem>
        )
    }

    return (
        <FlatList key={ option } isFetching={ false } totalCount={ count } items={ items } onLoadMore={ fetchMore }>{ Item }</FlatList>
    )
}


const CompetitionItem = ({ item }) => {
    return (
        <Box display="flex" py="s400" px="s400" alignItems="center">
            <Box>
                <Avatar src={ item.imageUrl } name={ item.label } mr="s400" />
            </Box>
                    
            <Box flex="1">
                <Text fontWeight={ item.selected ? 'bold' : 'normal' }>{ item.text }</Text>
                <Text size="s" color="dropdownForegroundMeta">
                    <span style={{ whiteSpace : 'nowrap' }}>{ item.description }</span>
                    <span style={{ whiteSpace : 'nowrap' }}>{ ' ' } <Icon size="1-2x" type="group" /> { item.count }</span>
                </Text>
            </Box>

            <Box display="flex" width="s200" height="s200" mx="s200" alignItems="center" justifyContent="center">
                { item.selected && <Icon type="check" /> }
            </Box>
        </Box>
    )
}

const CanonicalItem = ({ item }) => {
    return (
        <Box display="flex" py="s400" px="s400" alignItems="center">
            <Box flex="1">
                <Text fontWeight={ item.selected ? 'bold' : 'normal' }>{ item.text }</Text>
                <Text size="s" color="dropdownForegroundMeta">
                    <span style={{ whiteSpace : 'nowrap' }}>{ ' ' } <Icon size="1-2x" type="group" /> { item.count }</span>
                </Text>
            </Box>

            <Box display="flex" width="s200" height="s200" mx="s200" alignItems="center" justifyContent="center">
                { item.selected && <Icon type="check" /> }
            </Box>
        </Box>
    )
}


const DefaultItem = ({ item }) => {
    return (
        <Box display="flex" py="s400" px="s400" alignItems="center">
            <Box flex="1">
                <Text fontWeight={ item.selected ? 'bold' : 'normal' }>{ item.text }</Text>
                {
                    <Text size="s" color="dropdownForegroundMeta">
                        <Icon size="1-2x" type="group" /> { item.count }
                    </Text>
                }
            </Box>

            <Box display="flex" width="s200" height="s200" mx="s200"alignItems="center" justifyContent="center">
                { item.selected && <Icon type="check" /> }
            </Box>
        </Box>
    )
}



const renderItem = (t, item) => {

    switch(item.type) {
        case 'canonical':
            return <CanonicalItem item={ item } />;
        case 'competition':
            return <CompetitionItem item={ item } />;
        default:
            return <DefaultItem item={ item } />;
    }

    

}

const groupBy = (arr, property) => {
    return arr.reduce((acc, obj) => {
        const val = obj[property];

        if (acc[val]) {
            acc[val].push(obj);
        }
        else {
            acc[val] = [obj];
        }
        return acc;
    }, {});
}


const ShareInviteDialog = ({ competition, title, description, subject, text, url, open, onClose }) => {
    const [query, setQuery] = useState('');
    const [type, setType] = useState('friends');
    const t = useTranslator();
    const dispatch = useDispatch();
    const [showSnackbar] = useSnackbar();

    const handleInvite = user => new Promise((resolve, reject) => {
         
        dispatch(invite(competition.id, user.id )).then(
            () => {
                
                showSnackbar('Invitation sendt', 'success');
                resolve();
            },
            () => {
                
                showSnackbar('Der opstod en fejl', 'error');
                reject();
            }
        )
    })

    const tParts = type.split(':');

    const { options, items, count } = useSelector(getRecruits);
    const selectedOption = useSelector(getSelectedOption);

    useEffect(() => {
        dispatch(selectRecruitmentOption('friends'));
    }, []);

    useEffect(() => {
        if (competition) {
            dispatch(fetchRecruits(competition.id));
        }
    }, [competition]);

    const fetchMore = () => {
        
        dispatch(fetchMoreRecruits());
        
    }

    const dItems = useMemo(() => {
        
        const groups = groupBy(options, 'type');

        let items = []
        for(const [key, value] of Object.entries(groups)) {
            if (key === 'grouped')
                items.push({ separator : t('competitions.grouped_mini_leagues_heading') });
            else if (key !== 'friends')
                items.push({ separator : t('competitions.previous_mini_leagues_heading') });

            for(let i = 0; i < value.length; i++) {
                const o = value[i];
                items.push({
                    value : o.id, label : o.text, data : o,
                    ...o
                })
            }
        }

        return items;
    
    
    }, [options])

    const handleChangeType = value => {
        setQuery(''); 

        dispatch(selectRecruitmentOption(value));
        dispatch(fetchRecruitsFromSelectedOption ());
        setType(value);
    }

    const handleInviteEveryone = () => {
        dispatch(inviteAll()).then(res => {
            showSnackbar(t('competitions.count_new_invitations_sent', { count : res.data.count }), 'success');
        })
        
    }

    const [coreType, arg] = type.split(':');


    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogHeader>{ title }</DialogHeader>
            <DialogContent>
                <Share subject={ subject } text={ text } url={ url } label={ t('competitions.share_link_label') }/>

                <hr />

                <FormGroup>
                    <Label>{ t('competitions.invite_from_label') }</Label>
                    <SelectList align="left" value={ selectedOption && selectedOption.id } items={ dItems } expand onRenderItem={ item => renderItem(t, item) } onRenderSelectedItem={ item => renderItem(t, item) } onSelect={ handleChangeType } ></SelectList>
                </FormGroup>

                <Box display={ [ "block", "block", "flex" ]  } alignItems="center" mb="m">
                    <Box flex="1">
                    
                    </Box>
                    <Box flex="1"></Box>

                    <Box flex="1" textAlign="right">
                        {
                            selectedOption && selectedOption.links.invite &&
                            <XButton size="xs" minWidth="s700" mt={ ['m', 'm', 0] } variant="primary" textTransform="uppercase" onClick={ handleInviteEveryone }>{ t('competitions.invite_everyone_caption') }</XButton>
                        }
                    </Box>
                </Box>

                <Box minHeight="340px">
                   <InviteeList 
                        option={type }
                        count={ count }
                        items={ items } 
                        fetchMore={ fetchMore }
                        type={ coreType } arg={ arg } competitionId={ competition.id } query={ query } onInvite={ handleInvite }></InviteeList>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

ShareInviteDialog.propTypes = {
    title : PropTypes.string.isRequired,
    description : PropTypes.string,
    subject : PropTypes.string.isRequired,
    text : PropTypes.string.isRequired,
    url : PropTypes.string.isRequired
}

export default ShareInviteDialog;
