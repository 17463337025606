import React from 'react';
import PropTypes from 'prop-types'

import PlayerContext from '../../PlayerContext'

import { number, camelize } from 'utils'

const EventCount = ({ names }) => {

    

    return (
        <PlayerContext.Consumer>{ ({ statistics }) => {
            
            if (!statistics)
                return "-";

            const amount = names.reduce((agg, name) => {
                const propertyName = camelize(name);

                agg += statistics[propertyName] || 0;
                return agg;

            }, 0)

            return number(amount)
        }}</PlayerContext.Consumer>
    )
}

export default EventCount;