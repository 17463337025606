import React from 'react'

import { getConfigurationSection } from 'utils';

import { Flyout, DataContextProvider, TopBar } from '../components'

import GameContext from './GameContext';
import MatchContext from './MatchContext';
import WithMatch from './WithMatch'


import ViewContainer from './ViewContainer'

class MatchReportFlyout extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { id, onClose } = this.props;



        return (
            <WithMatch id={ id }>{ ({ match }) => (
                <MatchContext.Provider value={ match }>
                    <DataContextProvider value={ match }>
                        <GameContext.Consumer>{ ({ gameConfiguration }) => { 
                            if (!gameConfiguration)
                                return null;

                            const flyouts = getConfigurationSection(gameConfiguration, 'flyouts', window.swush.profile);

                            return (
                                <ViewContainer name={ flyouts.match && flyouts.match.view } />
                            )
                        }}</GameContext.Consumer>
                    </DataContextProvider>
                </MatchContext.Provider>
            )}</WithMatch>
        )
    }
}


export default MatchReportFlyout;