import * as types from './types'

const defaultState = {
    byGame : {}
};

const watchlistReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.FETCH_WATCHLIST_REQUEST:
            return  { 
                ...state,
                byGame : {
                    ...state.byGame,
                    [action.gameId] : {
                        ...state.byGame[action.gameId],
                        isFetching : true
                    }
                }
            };
        case types.FETCH_WATCHLIST_SUCCESS:
    
            
            return  { 
                ...state,
                byGame : {
                    ...state.byGame,
                    [action.gameId] : {
                        ...state.byGame[action.gameId],
                        didInvalidate : false,
                        isFetching : false,
                        items : action.payload.result
                    }
                }
            };
        case types.ADD_TO_WATCHLIST_SUCCESS:
        
            return { 
                ...state,
                byGame : {
                    ...state.byGame,
                    [action.gameId] : {
                        ...state.byGame[action.gameId], 
                        items : [...state.byGame[action.gameId].items, action.payload.result]
                    }
                }
            };
        case types.REMOVE_FROM_WATCHLIST_SUCCESS:
            const items = (state.byGame[action.gameId] || { items : [] }).items;
            const existingItem = items.find(i => i.player === action.playerId);
            const existingIndex = items.indexOf(existingItem);
        
            return  { 
                ...state,
                byGame : {
                    ...state.byGame,
                    [action.gameId] : {
                        ...state.byGame[action.gameId],
                        items : [
                            ...items.slice(0, existingIndex),
                            ...items.slice(existingIndex + 1)
                        ]
                    }
                }
            };
      
        default:
            return state
    }
}

export default watchlistReducer;
