import React, { useRef } from 'react'

import { AdSlot, RequestAds, useAdConsent } from '../components/ads';
import useGameConfig from './useGameConfig';

export default function ContentBanner() {
    const gameConfig = useGameConfig();
    const slotId = useRef(`slot-${ parseInt(Math.random() * 1000000) }`).current;
    const consent = useAdConsent();

    if (window.swush.appId != 'holdet')
        return null;

    if (typeof consent == 'undefined')
        return null;

    if (consent) {
        return (
            <>
                <AdSlot id={ slotId } adUnit="/83172459/holdet/holdet_flyout" size={ [[300, 250]] } targeting={ gameConfig.targeting } />
                <RequestAds slot={ slotId } />
            </>
        )
    } else {
        return (
            <>
                <AdSlot id={ slotId } adUnit="/83172459/holdet/holdet_noconsent_flyout" size={ [[300, 250]] } targeting={ gameConfig.targeting } />
                <RequestAds slot={ slotId } />
            </>
        )
    }
}


