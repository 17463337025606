import React from 'react'

import styled, { css } from 'styled-components'


const margin = css`
    margin : ${ props => props.margin };
`

const padding = css`
    padding : ${ props => props.padding };
`

export const Block = styled.div`
    ${ margin }
    ${ padding }
`

export default Block;