export const FETCH_GROUP_CATEGORIES_REQUEST   = 'FETCH_GROUP_CATEGORIES_REQUEST';
export const FETCH_GROUP_CATEGORIES_SUCCESS   = 'FETCH_GROUP_CATEGORIES_SUCCESS';
export const FETCH_GROUP_CATEGORIES_FAILURE   = 'FETCH_GROUP_CATEGORIES_FAILURE';

// User Groups

export const FETCH_USER_GROUP_MEMBERSHIPS_REQUEST        = 'user/groupmemberships/fetch/request';
export const FETCH_USER_GROUP_MEMBERSHIPS_SUCCESS        = 'user/groupmemberships/fetch/success';
export const FETCH_USER_GROUP_MEMBERSHIPS_FAIL           = 'user/groupmemberships/fetch/fail';

// Create Group

export const CREATE_GROUP_REQUEST             = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS             = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE             = 'CREATE_GROUP_FAILURE';


export const UPDATE_GROUP_REQUEST             = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS             = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE             = 'UPDATE_GROUP_FAILURE';

export const FETCH_GROUP_MEMBERS_REQUEST      = 'FETCH_GROUP_MEMBERS_REQUEST';
export const FETCH_GROUP_MEMBERS_SUCCESS      = 'FETCH_GROUP_MEMBERS_SUCCESS';
export const FETCH_GROUP_MEMBERS_FAILURE      = 'FETCH_GROUP_MEMBERS_FAILURE';

export const UPDATE_GROUP_MEMBERSHIP_REQUEST  = 'UPDATE_GROUP_MEMBERSHIP_REQUEST';
export const UPDATE_GROUP_MEMBERSHIP_SUCCESS  = 'UPDATE_GROUP_MEMBERSHIP_SUCCESS';
export const UPDATE_GROUP_MEMBERSHIP_FAILURE  = 'UPDATE_GROUP_MEMBERSHIP_FAILURE';

export const FILTER_GROUP_MEMBERS             = 'FILTER_GROUP_MEMBERS';

export const KICK_GROUP_MEMBER_REQUEST        = 'KICK_GROUP_MEMBER_REQUEST';
export const KICK_GROUP_MEMBER_SUCCESS        = 'KICK_GROUP_MEMBER_SUCCESS';
export const KICK_GROUP_MEMBER_FAILURE        = 'KICK_GROUP_MEMBER_FAILURE';

export const SELECT_GROUP                     = 'SELECT_GROUP';
export const INVALIDATE_GROUP_MEMBERS         = 'INVALIDATE_GROUP_MEMBERS';


export const FETCH_GROUP_COMPETITIONS_REQUEST = 'FETCH_GROUP_COMPETITIONS_REQUEST';
export const FETCH_GROUP_COMPETITIONS_SUCCESS = 'FETCH_GROUP_COMPETITIONS_SUCCESS';
export const FETCH_GROUP_COMPETITIONS_FAILURE = 'FETCH_GROUP_COMPETITIONS_FAILURE';
