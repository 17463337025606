import React, { useEffect } from 'react'

import { MatchCard, EventDate, Icon } from '../../components';

import { lo } from '../../common/utils'

import MatchOdds from '../MatchOdds'

import translatable from '../../hocs/translatable'

const GenericVersusMatchCard = React.memo(({ t, item, hideGroup, onClick }) => {
    const properties = item.properties || {}

    const homeScore = properties.homeScore || '0';
    const awayScore = properties.awayScore || '0';

    return (
        <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
            <MatchCard.VersusEventContent 
                home={ lo(item.participants['home'], 'name') }
                away={ lo(item.participants['away'], 'name') }
                homeScore={ homeScore }
                awayScore={ awayScore }
                group={ !hideGroup && lo(item.group, 'name') }
                status={ item.status }
                >
            </MatchCard.VersusEventContent>

            <MatchCard.Footer>
                { item.status === 'pending' && <MatchOdds odds={ item.odds } /> }
                { (item.status === 'updated' || item.status === 'completed') && <span>{ t('americanFootball.matchCompleted') }</span> }
                { item.status !== 'live' && <span><EventDate date={ item.start } displayDate={ false } /></span> }
            </MatchCard.Footer>
            
            { 
                item.status === 'updated' &&
                <MatchCard.Icon>
                    <Icon type="flag" size="1-2x" />
                </MatchCard.Icon>
            }
        </MatchCard> 
    )
        
})

export default translatable(GenericVersusMatchCard);