
import { normalize } from 'normalizr';

import * as viewTypes from '../view/types'
import * as jobTypes from '../job/types'
import * as playerTypes from '../player/types'
import * as matchTypes from '../match/types'
import * as rulesetTypes from '../ruleset/types'
import * as scheduleTypes from '../schedule/types'
import * as tournamentTypes from '../tournament/types'
import * as groupTypes from '../group/types'
import * as gameTypes from '../game/types'
import * as highscoreTypes from '../highscore/types'
import * as notificationTypes from '../notification/types'
import * as wsTypes from '../ws/types'

import { playerValueSchema, matchSchema, eventSchema, fantasyEventSchema} from '../../schemas';


const mergeEntities = (state, normalized) => {
    
    let mergedState = {};
    const entities = normalized.entities;

    

    if (Object.keys(normalized.entities).length == 0)
        return state;
   

    for(let type in entities) {
        
        let typeEntities = {};

        for(let id in entities[type]) {
            
          

            const entity = entities[type][id];


            if (entity.hasOwnProperty('id') && Object.keys(entity).length == 1) {
                // only has id property
                continue;
            }


            


            const stateEntity = (state[type] || {})[id] || {};

            const mergedEntity = {
                ...stateEntity,
                ...entity
            }
            
            typeEntities[id] = mergedEntity
        }
        
        mergedState[type] = {
            ...state[type],
            ...typeEntities
        }
    }

    

    const newState = {
        ...state,
        ...mergedState
    }

    return  newState;
}


const mergeEntityChanges = (state, id, changes) => {
    return {
        ...state,
        [id] : {
            ...state[id],
            ...changes
        }
    }
}


const defaultState = {
    games : {},
    events: {},
    highscores : {},
    rulesets : {},
    players : {},
    persons : {},
    matches: {},
    teams : {},
    positions: {},
    formations: {},
    tournaments: {},
    playerValues : {},
    groups : {},
    users : {},
    groupMemberships : {},
    competitions : {},
    locations : {},
    matchGroups : {},
    views : {},
    jobs : {},
    jobHistories : {},
    jobSchedules : {},
    jobTypes : {},
    invitations : {},
    fantasyEvents : {}


}



const wsMessageReducer = (state, action) => {


    const deflateEvent = (e) => {
        return {
            id : e.id,
            match : { id : e.match },
            type : { id : e.type },
            player : { id : e.player },
            period : e.period,
            amount : e.amount,
            group : e.group,
            time : e.time
        };
    }

    const deflateFantasyEvent = (e) => {
        return {
            id : e.id,
            match : { id : e.match },
            type : { id : e.type },
            player : { id : e.player },
            period : e.period,
            amount : e.amount,
            group : e.group,
            minute : e.minute
        };
    }

    switch(action.message) {
        case 'MATCH_UPDATE':
            return mergeEntities(state, normalize(action.payload, matchSchema));
        case 'EVENT_INSERT':
        case 'EVENT_UPDATE':
            return mergeEntities(state, normalize(deflateEvent(action.payload), eventSchema));
        case 'FANTASYEVENT_INSERT':
        case 'FANTASYEVENT_UPDATE':
            return mergeEntities(state, normalize(deflateFantasyEvent(action.payload), fantasyEventSchema));
        case 'FP_I':
        case 'FP_U':
            return mergeEntities(state, normalize(action.payload, playerValueSchema));
        case 'FP_U_I':
            return {
                ...state,
                playerValues : mergeEntityChanges(state.playerValues, action.payload.id, action.payload.changes)
            }
      
        default:
            return state;
    }
}


export default function entities (state = defaultState, action) 
{
    switch(action.type) 
    {
        case viewTypes.UPDATE_VIEW_SUCCESS:

            return {
                ...state,
                views : {
                    ...state.views,
                    [action.payload.id] : {
                        ...state.views[action.payload.id],
                        content : action.payload.content
                    }
                }
            }

     
        case playerTypes.FETCH_STATISTICS_BY_ROUND_SUCCESS:
        case groupTypes.FETCH_GROUP_CATEGORIES_SUCCESS:
        case groupTypes.FETCH_USER_GROUP_MEMBERSHIPS_SUCCESS:
        case groupTypes.FETCH_GROUP_MEMBERS_SUCCESS:
        case groupTypes.FETCH_GROUP_COMPETITIONS_SUCCESS:
        case matchTypes.FETCH_MATCH_SUCCESS:
        case matchTypes.FETCH_MATCH_EVENTS_SUCCESS:
        case matchTypes.FETCH_MATCH_FANTASY_EVENTS_SUCCESS:
        case scheduleTypes.FETCH_SCHEDULE_SUCCESS:
        case playerTypes.FETCH_PLAYER_PROFILE_SUCCESS:
        case playerTypes.FETCH_PLAYER_EVENTS_SUCCESS:
        case playerTypes.FETCH_PLAYER_FANTASY_EVENTS_SUCCESS:
        case playerTypes.FETCH_PLAYER_VALUES_SUCCESS:
        case viewTypes.FETCH_VIEW_BY_NAME_SUCCESS:
        case jobTypes.FETCH_JOBS_SUCCESS:
        case jobTypes.FETCH_JOB_HISTORY_SUCCESS:
        case jobTypes.FETCH_JOB_SCHEDULE_SUCCESS:
        case jobTypes.FETCH_JOB_SUCCESS:
        case viewTypes.FETCH_VIEW_SUCCESS:
        
            return mergeEntities(state, action.payload);
        
        case groupTypes.UPDATE_GROUP_MEMBERSHIP_SUCCESS:
            const membership = state.groupMemberships[action.id];
            if (membership) {
                const nMembership = {
                    ...membership,
                    ...action.membership
                };

                return {
                    ...state,
                    groupMemberships : {
                        ...state.groupMemberships,
                        [action.id] : nMembership
                    }
                }
            }
            else {
                return state;
            }
        case rulesetTypes.FETCH_RULESET_SUCCESS:
        case gameTypes.FETCH_CATALOG_SUCCESS:
        case gameTypes.FETCH_GAME_SUCCESS:
        case tournamentTypes.FETCH_TOURNAMENT_SUCCESS:
        case highscoreTypes.RECEIVE_HIGHSCORE:

            return mergeEntities(state, action.payload);
        case wsTypes.WS_MESSAGE:
            return wsMessageReducer(state, action);
        case wsTypes.WS_MESSAGE_BULK:
        
            let nState = state;
            for(let i = 0; i < action.queue.length; i++) {
                nState = wsMessageReducer(nState, action.queue[i]);
            }
            return nState;
        case notificationTypes.MARK_NOTIFICATIONS_READ_SUCCESS:
                return {
                    ...state,
                    notifications: Object.values(state.notifications).reduce((agg, n) => { 
                        agg[n.id] = { ...n, read : true };
                        return agg;
                    }, {})
                }
        default:

            if (action.payload && action.payload.entities) {
                
                return mergeEntities(state, action.payload);
            }
            else 
                return state;
    }
}