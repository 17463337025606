import axios from 'axios'
import { normalize } from 'normalizr'

import { matchSchema } from '../../schemas'
import * as types from './types'

// Schedule

const fetchScheduleRequest = (tournament) => ({ type: types.FETCH_SCHEDULE_REQUEST, tournament})
const fetchScheduleSuccess = (tournament, payload) => ({ type: types.FETCH_SCHEDULE_SUCCESS, tournament, payload })
const fetchScheduleFailure = (tournament) => ({ type: types.FETCH_SCHEDULE_FAILURE, tournament })

const fetchSchedule = (tournament) => (dispatch) => {
    dispatch(fetchScheduleRequest(tournament))

    axios.get(`tournaments/${ tournament }/schedule`).then(response => {
        dispatch(fetchScheduleSuccess(tournament, normalize(response.data, [matchSchema])));
    }, error => {
      dispatch(fetchScheduleFailure(tournament));
    });
}

const shouldFetchSchedule = (state, tournament) => {
  const { items, isFetching, didInvalidate } = state.schedule.byTournament[tournament] || { isFetching : false, items : null, didInvalidate : false };

  if (isFetching) {
    return false;
  }
  else if (!items) {
      return true;
  }
  else {
      return didInvalidate;
  }
}

export const fetchScheduleIfNeeded = (tournament) => (dispatch, getState) => {
  if (shouldFetchSchedule(getState(), tournament)) {
      dispatch(fetchSchedule(tournament));
  }
}
