import React, { useContext } from 'react'
import { Box, Icon, AlertBox, RelativeTime, Text } from 'components'
import GameContext from './GameContext'
import translatable from '../hocs/translatable'




const PersonInjuryStatus = ({ t, personId }) => {
    const { injuries, gameConfiguration, permissionManager } = useContext(GameContext);

    if (!permissionManager.hasPermission('ListInjuries'))
        return null;

    const personInjuries = gameConfiguration && gameConfiguration.injuries && injuries.map[personId + ''];

    if (!personInjuries)
        return null;

    return (
        <AlertBox type="info">
            <Icon type="injury" size="sm" shadow />
            <Box ml="s300" flex={ 1 }>
                {
                    personInjuries.map(i => (
                        <Text size="xs" key={ i.id }>
                            { `${ i.type.name }.  ${ t('common.status') }: ${ i.expectedReturn }.`} 
                            
                            <em>
                                { ` ${ t('common.updated') } ` } 
                                <RelativeTime date={ i.updated } />
                            </em>
                        </Text>
                    ))
                }
            </Box>
        </AlertBox>
    )
}

export default translatable(PersonInjuryStatus);