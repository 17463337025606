import React from 'react';
import PropTypes from 'prop-types'

import { PlayerAvatar } from 'components'
import PlayerContext from '../../PlayerContext'
import WithPlayer from '../../WithPlayer'



const AvatarWidget = ({ editMode, children, ...otherProps }) => {

    if (editMode) {
        return <div style={{ width: '64px', height : '64px', background: '#fff'}}>
        
                </div>
    }


    

    return <PlayerContext.Consumer>{ ({ id }) => (
                <WithPlayer id={ id }>{ (player) => (
                    <PlayerAvatar player={ player } { ...otherProps}></PlayerAvatar>
                )}</WithPlayer>
                )}</PlayerContext.Consumer>

}


export default AvatarWidget;