import React from 'react';
import PropTypes from 'prop-types'
import { Paragraph } from 'components'


const ParagraphWidget = ({ text }) => {
    return <Paragraph>{ text }</Paragraph>

}

export default ParagraphWidget;