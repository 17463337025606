import React from 'react'

import * as styles from './MatchCard.scss'

class MatchCardGroup extends React.PureComponent{
    render () {
        const { children, heading } = this.props;
        return (
            <div className={ styles.group }>
                <div className={ styles.groupHeading}>{ heading }</div>
                { children }
            </div>
        )
    }
}

MatchCardGroup.propTypes = {
}

export default MatchCardGroup;