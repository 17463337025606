import { useMemo } from 'react'
import { useDispatch } from 'react-redux';

import { kick } from '../redux/competition/actions'
import { useSnackbar } from '../hooks/useSnackbar'

const useKick = () => {
    const dispatch = useDispatch();
    const [showSnackbar] = useSnackbar();

    const value = useMemo(() => {
        const _kick = (competitionId, fantasyTeamId, ban) => new Promise((resolve, reject) => {
            dispatch(kick(competitionId, fantasyTeamId, ban)).then(
                () => {

                    if (ban) {
                        showSnackbar('Holdet blev afmeldt, og brugeren bannet', 'success');    
                    }
                    else  {
                        showSnackbar('Holdet blev afmeldt', 'success');
                    }

                    resolve();
                },
                (e) => {
                    const message = e.response.data.title || 'Der opstod en fejl';

                    showSnackbar(message, 'error');
                    reject();
                }
            )
        })

        return [_kick];
    }, []);

    return value;
    
}

export default useKick;