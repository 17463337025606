import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import * as Components from 'components';
import GameContext from '../../GameContext';
import { lo } from 'utils';


export const PageTitle = ({ editMode, title }) => {
    if (editMode)
        return null;

        

    const siteName = window.swush.site && window.swush.site.name;
    return <Components.DocumentTitle title={ `${ siteName } - ${ title }` } />
}

PageTitle.propTypes = {
    title : PropTypes.string.isRequired
}



export const GamePageTitle = ({ editMode, title }) => {
    if (editMode)
        return null;

        
    
    const { game } = useContext(GameContext)
    
    const siteName = window.swush.site && window.swush.site.name;
    
    return <Components.DocumentTitle title={ `${ title } - ${ lo(game, 'name') } - ${ siteName }` } />
}

GamePageTitle.propTypes = {
    title : PropTypes.string.isRequired
}