import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import ThemeProvider from '../components/ThemeProvider'

import { StaticRouter, BrowserRouter as Router } from "react-router-dom";

import App from './App.jsx'

import TranslationProvider from '../components/translation-provider';
import { Async } from 'components'
import { AppConfigProvider } from './AppConfigProvider.jsx';
import { AdProvider, InitializeGpt } from '../components/ads';

const Live = () => <Async load={ import('./Live') } />

const RouterConductor = ({ children }) => {
    const context = {};
  
    if (window.swush.routing === 'static') {
        return <StaticRouter location={ window.swush.location } context={ context }>{ children }</StaticRouter>;
    }
    else {
        return <Router>{ children }</Router>;
    }
}

const Root = ({ store, theme, config, translations }) => {
    return (
        <Provider store={ store }>
            <AdProvider>
            <InitializeGpt />
            <ThemeProvider theme={ theme }>
            <StyledThemeProvider theme={ theme }>
                <AppConfigProvider config={ config}>
                    <Live />
                    <TranslationProvider locale={ window.swush.locale } fallbackLocale="en" translations={translations}>
                        <RouterConductor>
                            <App/>
                            
                        </RouterConductor>
                    </TranslationProvider>
                </AppConfigProvider>
            </StyledThemeProvider>
            </ThemeProvider>
            </AdProvider>
        </Provider>
    )
}

Root.defaultProps = {
  useRouting : false
}

export default Root;