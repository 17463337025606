import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useBreakpoint } from './BreakpointProvider'
import useThrottle from './useThrottle';

const getLowestColumn = (cols) => {
    let index = 0;
    let height = Number.MAX_SAFE_INTEGER;

    for(let i = 0; i < cols.length; i++) {
        if (cols[i] < height) {
            index = i;
            height = cols[i];
        }
    }

    return index;
}


const MasonryItem = React.memo(({ children }) => {
    return <div style={{ position: 'absolute', top: 0, left: 0, zIndex : 0 }}>{ children }</div>
})




export const Masonry = React.memo(({ children, gap, columns }) => {
    const ref = useRef();
    const [layoutTimer, setLayoutTimer] = useState();

    const layout = useCallback(() => {
        const el = ref.current;

        if (!el)
            return;

        let colHeights = Array(columns).fill(0);

        const width = el.offsetWidth;
        const columnWidth = (width - ((columns - 1) * gap)) / columns;
        
        var childs = ref.current.children;

        let maxHeight = 0;
        for(let i = 0; i < childs.length; i++) {
            const cel = childs[i];

            cel.style.width = columnWidth + 'px';

            const index = getLowestColumn(colHeights);
            const height = colHeights[index];

            
            const left = (index * (columnWidth + gap)) + 'px';
            const top = height + 'px';

            cel.style.transform = `translate3d(${ left }, ${ top }, 0)`;

            const elHeight = cel.getBoundingClientRect().height;

            const newHeight = height + elHeight;
            if (newHeight > maxHeight) 
                maxHeight = newHeight;

            colHeights[index] += elHeight + gap;
        }

        el.style.height = maxHeight + 'px';
    })

    useEffect(() => {
        window.addEventListener('resize', layout);

        return () => {
            window.removeEventListener('resize', layout);
        }
    }, [])

    useEffect(() => {
        layout();
    }, [columns, children]);


    const style = {
        position: 'relative',
        transition: 'height 0.2s ease-in-out'
    };
    
    return  <div ref={ ref } style={ style }>
                { 
                      React.Children.map(children, (child, index) => {

                        return <MasonryItem key={ child.key }>{ child }</MasonryItem>
                    }) 
                }
            </div>
  
})

Masonry.defaultProps = {
    columns : 3,
    gap : 12
};


export const ResponsiveMasonry = React.memo(({ columns, children, ...otherProps }) => {
    const breakpoint = useBreakpoint();
    const nColumns = Array.isArray(columns) ? columns[breakpoint.index] : columns;

    

    return  <Masonry { ...otherProps } columns={ nColumns }>
                { children }
            </Masonry>
});

