import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'


import { fetchMatchIfNeeded } from '../redux/match/actions'

class WithMatch extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.fetch(this.props.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id && nextProps.id) {
            this.props.fetch(this.props.id);
        }
    }

    render() {

        

        const { match, children } = this.props;

        
        if (!match)
            return null;

        return children({ match });
    }
}


export const makeGetMatch = () => (
    createSelector([
        (state, props) => props.id,
        (state) => state.entities.matches,
        (state) => state.entities.teams,
        (state) => state.entities.locations,
        (state) => state.entities.matchGroups
    ],
    (id, matches, teams, locations, matchGroups) => {
        const match = matches[id];

        if (!match)
            return null;

        const participants = match && match.participants.map(p => {
            const team = teams[p.team];
            return {
                ...p,
                team
            }
        });

        return {
            ...match,
            location : locations[match.location],
            matchGroup : matchGroups[match.group],
            participants
        }
    })
)




const makeMapStateToProps = () => {
    const getMatch = makeGetMatch();
    return (state, ownProps) => ({
        match : getMatch(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id) {
            dispatch(fetchMatchIfNeeded(id));
        }
    }
}

WithMatch.propTypes = {
    id : PropTypes.any.isRequired
}

WithMatch.defaultProps = {
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithMatch);

