import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { space, layout, typography, border, system } from 'styled-system'

import useTheme from './useTheme'

export const HoverWithOpacity = styled.div`
    ${ space }
`;

const sizes =  {
    xs : {
        height : 's200',
        px: 's300',
        borderRadius: 'r200'
    },

    s : {
        height : ['m', 'l'],
        px: 's400',
        borderRadius: 'r200'
    },

    m : {
        height : 's400',
        px: 's400',
        borderRadius: 'r200'
    },

    l : {
        height : ['s500', 's500', 's600'],
        px: ['s500', 's500', 's600'],
        borderRadius: 'r200'
    }
}

/* Default */

const defaultEnabled = css`
    background-color: ${ props => props.theme.colors.buttonLikeBackground };
    color: ${ props => props.colors.action };

    &:hover {
        color: ${ props => props.colors.actionHover };
        background-color: ${ props => props.theme.colors.buttonLikeBackgroundHover };
    }
`

const defaultDisabled = css`
    background-color: ${ props => props.theme.colors.buttonLikeBackgroundDisabled };
    color: ${ props => props.theme.colors.buttonLikeForegroundDisabled };
`

const _default = css`
    border: none;
    
        
    ${ props => props.disabled ? defaultDisabled : defaultEnabled }
`

/* Primary */

const primaryEnabled = css`
    background-color: ${ props => props.colors.action };
    color: ${ props => props.colors.onAction };

     &:hover {
        color: ${ props => props.colors.onAction };
        background-color:  ${ props => props.colors.actionHover };
     }
`

const primaryDisabled = css`
    background-color: ${ props => props.colors.action };
    color: ${ props => props.colors.onAction };
    opacity: 0.5;
`

const primary = css`
    border: none;
    ${ props => props.disabled ? primaryDisabled : primaryEnabled }
`

/* Outline */

const outlineDisabled = css`
    border: 1px solid ${ props => props.theme.colors.buttonLikeForegroundDisabled } !important;
    color: ${ props => props.theme.colors.buttonLikeForegroundDisabled };
`

const outlineEnabled = css`
    border: 1px solid ${ props => props.colors.action };
    color: ${ props => props.colors.action };

    &:hover {
        border-color: ${ props => props.colors.actionHover };
        color: ${ props => props.colors.actionHover };
    }

`

const outline = css`
    background: none !important;

    ${ props => props.disabled ? outlineDisabled : outlineEnabled }

    &:hover { background: none; }
    &:active { background: none; }
`

/* Transparent */

const transparentEnabled = css`
    color: ${ props => props.colors.action };

    &:hover {
        color: ${ props => props.colors.actionHover };
    }
`

const transparentDisabled = css`
    color: ${ props => props.theme.colors.buttonLikeForegroundDisabled };
`


const transparent = css`
    background: none;
    border: none;

    ${ props => props.disabled ? transparentDisabled : transparentEnabled }

    &:hover { background: none; }
    &:active { background: none; }
`

const variants = { 'default' : _default, primary, outline, transparent }





const enabledStyle = css`
    cursor: pointer;


    ${ HoverWithOpacity } {
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
        will-change: opacity;
    }

    &:hover {
        ${ HoverWithOpacity } {
            opacity: 1;
        }
    }
`

const disabledStyle = css`
    
`

const StyledButtonContent = styled.div`
    
`


const StyledButton = styled.div`
    text-align: left;
    ${ props => variants[props.variant] }    

    ${ props => props.disabled ? disabledStyle : enabledStyle };
    
    display: ${ props => props.block ? 'flex' : 'inline-flex' };
    
    outline: none;
    margin: 0;

    align-items: center;
    

    text-decoration: none;

    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    will-change: transform, color, background-color;

    &:active ${ StyledButtonContent } { transform: translate3d(0, 2px, 0); }

    ${space}
    ${layout}
    ${typography}
    ${border}

    letter-spacing : ${ props => props.textTransform == "uppercase" ? '1px' : 'auto' };
    user-select: none;
    ${system({ textTransform: true })}

`

const textStyles = {
    xs : {
        fontSize: "10px",
		lineHeight: "12px",
    },
    s : {
        fontSize: ["10px", "12px"],
        lineHeight: ["12px", "14px"]
    },
    m : {
        fontSize: ["12px", "14px"],
        lineHeight: ["14px", "16px" ]
    },
    l : {
        fontSize: ["14px", "16px"],
        lineHeight: ["16px", "20px"]
    }
}


export const XButton = ({ themeName, size, children, block, collapse, ...otherProps }) => {
    const _theme = useTheme();
    const colors = _theme.colors.themes[themeName];
    const _textStyles = textStyles[size];



    const bProps = {
        ...otherProps
    };
    if (collapse){
        bProps.px = 0;
    }

    return <StyledButton { ...sizes[size] } {..._textStyles } colors={ colors } block={ block }  {...bProps}>
                    { children }
            </StyledButton>
}


export const LinkButton = ({ to, themeName, size, children, collapse, ...otherProps }) => {
    const _theme = useTheme();
    const colors = _theme.colors.themes[themeName];
    const _textStyles = textStyles[size];


    const bProps = {
        ...otherProps
    };
    if (collapse){
        bProps.px = 0;
    }




    return (
        <StyledButton as={ Link } to={to} { ...sizes[size] } {..._textStyles } colors={ colors } {...bProps}>
            <StyledButtonContent>
                { children }
            </StyledButtonContent>
        </StyledButton> 
    )
}



const StyledButtonGroup = styled.div`
    ${ space }
    display: inline-flex;

    ${ StyledButton }:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    ${ StyledButton }:not(:first-child):not(:last-child) {
        border-radius: 0;
        border-right: none;
    }

    ${ StyledButton }:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        
    }

    ${ StyledButton }:hover { transform: none; }
    ${ StyledButton }:active { transform: none; }
`


export const XButtonGroup = ({ size, variant, theme, textTransform, children, ...otherProps }) => {
    return (
        <StyledButtonGroup {...otherProps }>
            {
                React.Children.map(children, (child, index) => {
                    if (!child)
                        return null;
                    
                    return React.cloneElement(child, { size, variant, textTransform, theme })
                })
            }
        </StyledButtonGroup>
    )
}

XButton.defaultProps = {
    size : 'm',
    variant : 'default',
    themeName : 'default'
}



LinkButton.defaultProps = {
    size : 'm',
    variant : 'default',
    themeName : 'default'
}





