import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import {  exp } from 'utils';
import DataContext from '../../../components/DataContext'
import * as Components from 'components';

const EventDate = ({ property, defaultValue, ...otherProps }) => {
    const context = useContext(DataContext)

    const value = exp(property, context);
    return (value && <Components.EventDate date={ value } { ...otherProps }/>) || defaultValue;
}

EventDate.propTypes = {
    property : PropTypes.string.isRequired,
    defaultValue : PropTypes.string.isRequired
}

EventDate.defaultProps = {
    property : '',
    defaultValue : '-'
}

export default EventDate;