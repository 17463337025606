import * as types from './types'

const defaultState = {
    byId : {}
};

export default function rulesets (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_RULESET_REQUEST:

            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_RULESET_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_RULESET_FAILURE:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        default:
            return state
    }
}

