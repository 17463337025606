import { useEffect } from 'react'
import useAds from "./useAds";
import useAdConsent from './useAdConsent';

export default function RequestAds({ slot }) {
    const { slots } = useAds();
    const consent = useAdConsent();


	useEffect(() => {

        if (typeof consent == 'undefined' )
            return;

            googletag.cmd.push(() => {
                if (slot) {
                    const _slot = slots[slot];
                    if (_slot) {
                        googletag.pubads().refresh([ _slot ]);
                    }
                }
                else {
                    // Request ads for all ad slots defined up to this point.
                    //
                    // In many real world scenarios, requesting ads for *all*
                    // slots is not optimal. Instead, care should be taken to
                    // only refresh newly added/updated slots.
                    const _slots = Object.values(slots);
                    googletag.pubads().refresh(_slots);
                }
            });
	}, [consent]);

	return null;
}
