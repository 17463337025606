import React from 'react'

import Button from '../components/Button'
import Icon from '../components/Icon'
import IconButton from '../components/IconButton'

const BusyButton = ({ busy, label, children, onClick, ...otherProps }) => {
    if (busy) {
        return (
            <IconButton {...otherProps } label={ label }>
                <Icon type="spinner" animation="rotate"></Icon>
            </IconButton>
        )
    }
    
    return (
        <Button {...otherProps } onClick={ onClick }>{ children }</Button>
    )
}

export default BusyButton;