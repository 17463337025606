import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './MatchHead.scss'
import Scorebox from './Scorebox'

import classNames from 'classnames'

const VersusMatchHead = ({ group, date, country, location, progress, home, away, homeScore, awayScore, time, homeEvents, awayEvents, live }) => 
{

    const className = classNames(
        styles.versus,
        {
            [styles.live] : live
        }
    )

    return (
        <div className={ className }>
            <div className={ styles.tl }>
                <div>
                    <small>{ group }</small><br />
                    { date }
                </div>
            </div>

            <div className={ styles.tr}>
                <div>
                    <small>{ country }</small><br />
                    { location }
                </div>
            </div>

             <div className={ styles.progress }>
                { progress }
            </div>

            <div className={ styles.home }>{ home }</div>
            <div className={ styles.away }>{ away }</div>
            <div className={ styles.score }>
                
                <Scorebox size="l" value={ homeScore }/>
                <Scorebox size="l" value={ awayScore  }/>
                
            
            </div>
            <div className={ styles.time }>{ time }</div>

            <div className={ styles.homeEvents }>{ homeEvents }</div>
            <div className={ styles.awayEvents }>{ awayEvents }</div>
        </div>
    )
}


const SimpleMatchHead = ({ children }) => 
{
    return <div className={ styles.simple }>{ children }</div>;
}


class MatchHead extends React.PureComponent {
    static Versus = VersusMatchHead;
    static Simple = SimpleMatchHead;

    render() {
        return (
            <div className={ styles.container }>
                { this.props.children }
            </div>
        )

    }
}



MatchHead.propTypes = {
    backgroundImageUrl : PropTypes.string
}

MatchHead.defaultProps = {
}


export default MatchHead;
