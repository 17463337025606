import { createSelector } from 'reselect'

export const getRecruits = createSelector(
    state => state.recruits,
    (recruits) => {
        return {
            options : recruits.options,
            items : recruits.items,
            count : recruits.count
        }
    }
)


export const getSelectedOption = createSelector(
    state => state.recruits,
    (recruits) => {
        const selectedOption = recruits.selectedOption;
        return recruits.options.find(o => o.id === selectedOption);
    }
)