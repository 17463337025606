import React, { useState } from 'react'
import { Box, Spinner } from 'components'

import { 
    XButton,
    Dialog, DialogHeader, DialogContent, DialogFooter,
    SelectList,
    Form, FormField, Label, FauxPasswordInput,
    RegularText
} from 'components'

import useEnrollmentOptions from './useEnrollmentOptions'
import useJoinCompetition from './useJoinCompetition'
import GameClosedDialog from './GameClosedDialog'

import { useTranslator } from '../hooks/useTranslator'
import { LinkButton } from '../components'

const Closed = ({ onClose }) => {
    const t = useTranslator();
    
    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.enroll_closed_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">


                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const MaxReached = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.enroll_max_teams_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const Filled = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.enroll_mini_league_filled_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const Banned = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.banned_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.banned_mini_league_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const NoMoreTeams = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.enroll_no_more_teams_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}

const NoTeams = ({ onClose }) => {
    const t = useTranslator();
    const parts = location.pathname.split('/');
    const gameSlug = parts[3];


    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>
                        { t('competitions.enroll_no_teams_error' ) }

                </RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                
                    
                        <LinkButton variant="primary" href={ `/${ gameSlug }/userteam/new` } size="s" textTransform="uppercase" external>{ t('common.create_team')}</LinkButton>
                    
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const GameOver = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.cannot_enroll_heading') }</DialogHeader>
            <DialogContent>
                <RegularText>{ t('competitions.enroll_game_closed_error' ) }</RegularText>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.ok_caption')}</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}


const Enrollment = ({ competitionId, initialPassword, item, onClose }) => {
    
    const [value, setValue] = useState(item.fantasyTeams[0].id);
    const [password, setPassword] = useState(initialPassword ||'');
    const [problems, setProblems] = useState();
    const [join] = useJoinCompetition(competitionId)
    const t = useTranslator();
    

    const items = item.fantasyTeams.map(t => ({ value : t.id, label : t.name }) );

    
    const onOk = (e) => {
        e.preventDefault();
        setProblems();

        join(value, password)
            .then(() => { onClose(); })
            .catch(problems => setProblems(problems));
    }

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.enrollment_heading') }</DialogHeader>
            <DialogContent>
                <Form problems={ problems } onSubmit={ onOk }>
                    <FormField name="fantasyTeams">
                        <Label>{ t('competitions.select_team_to_enroll_label') }</Label>

                        <SelectList value={ value } placeholder={ t('competitions.select_team_label') } items={ items } expand onSelect={ v => setValue(v) } />
                    </FormField>

                    {
                        item.requiresPassword &&
                        <FormField name="password">
                            <Label>{ t('competitions.password_label') }</Label>
                            <FauxPasswordInput value={ password } onChange={ e => setPassword(e.target.value) } />
                        </FormField>
                    }
                </Form>
            </DialogContent>
            <DialogFooter>
                <Box textAlign="right">
                    <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ onClose }>{ t('common.cancel_caption') }</XButton>
                    <XButton variant="primary" size="s" textTransform="uppercase" onClick={ onOk } disabled={ false }>{ t('common.ok_caption') }</XButton>
                </Box>
            </DialogFooter>
        </React.Fragment>
    )            
}

const IsFetching = ({ onClose }) => {
    const t = useTranslator();

    return (
        <React.Fragment>
            <DialogHeader>{ t('competitions.enrollment_heading') }</DialogHeader>
            <DialogContent>
                <Spinner />
            </DialogContent>
            <DialogFooter>
            </DialogFooter>
        </React.Fragment>
    )            
}


const EnrollmentDialogConductor = ({ competitionId, password, enrollmentOptions, onClose }) => {
    if (enrollmentOptions.isFetching)
        return <IsFetching onClose={ onClose } />

    switch(enrollmentOptions.item.status) {
        
        case 'closed':
            return <Closed onClose={ onClose } />
        case 'maxReached':
            return <MaxReached onClose={ onClose } />
        case 'no_more_teams':
            return <NoMoreTeams onClose={ onClose } />                    
        case 'no_teams':
            return <NoTeams onClose={ onClose } />        
        case 'filled':
            return <Filled onClose={ onClose } />                    
        case 'banned':
            return <Banned onClose={ onClose } />                    
        case 'ok':
            return <Enrollment competitionId={ competitionId } item={ enrollmentOptions.item } initialPassword={ password } onClose={ onClose } />            
        default:
            return null;

    }
}

const EnrollmentDialog = ({ competitionId, password, open, onClose }) => {
    const [enrollmentOptions] = useEnrollmentOptions(competitionId);


    if (enrollmentOptions.item.status == "game_over")
        return <GameClosedDialog open={ open } onClose={ onClose } />

    return (
        <Dialog open={ open } onClose={ onClose }>
            <EnrollmentDialogConductor competitionId={ competitionId } enrollmentOptions={ enrollmentOptions } password={ password } onClose={ onClose }/>
        </Dialog>
    )
}

export default EnrollmentDialog;
