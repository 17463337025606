import React from 'react';

import * as fromComponents from 'components'


export const HeadingEditor = ({ text, ...otherProps }) => {
    return (
            <fromComponents.Heading { ...otherProps }>{ text }</fromComponents.Heading>     
    )
}

export const Heading = ({ editMode, text, ...otherProps }) => {

    

    return <fromComponents.Heading { ...otherProps }>{ text }</fromComponents.Heading>
}

