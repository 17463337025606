import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import config from 'config'

import { fetchConfigIfNeeded } from '../redux/config/actions'

class WithConfiguration extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetch(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.url != this.props.url) {
            this.props.fetch(nextProps)
        }
    }

    render() {
        return this.props.children(this.props.configuration)
    }
}



WithConfiguration.propTypes = {
    url : PropTypes.string
}

const makeGetConfiguration = () => {
    return createSelector([
        (state, props) => props.url,
        (state, props) => state.configs.byUrl
    ],
    (url, byUrl) => {
        return url && byUrl[url] && byUrl[url].item;
    });
}


const makeMapStateToProps = () => {
    const getConfiguration = makeGetConfiguration();

    return (state, props) => {
        return {
            configuration : getConfiguration(state, props)
        }
    }
} 

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetch : (props) => {
            if (props.url) {
                dispatch(fetchConfigIfNeeded(props.url));
            }
        }
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithConfiguration);
