import React from 'react';

import { MatchHead, FactList, FactListItem, EventDate } from 'components';

import translatable from '../../hocs/translatable';
import MatchContext from '../MatchContext';

const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}

const MotorsportMatchHead = ({ t, id }) => {
    return (
        <MatchContext.Consumer>{ (match) => { 
            const laps = parseInt((match.properties || {}).circuitLaps || '0');
            const length = parseInt((match.properties || {}).circuitLength || '0');
            const distance = parseInt((match.properties || {}).circuitDistance || '0');

            return (
                <MatchHead backgroundImageUrl={ match.location.imageUrl }>
                    <MatchHead.Simple>
                        <FactList large>
                            <FactListItem value={ match.location.name } label={ t('motorsport.venue') } />
                            <FactListItem value={ <EventDate date={ match.start } format="short" /> } label={ t('motorsport.qualificationStart') } />
                        </FactList>

                        <FactList>
                            <FactListItem value={ laps } label={ t('motorsport.laps') } />
                            <FactListItem value={ <Kilometers value={ length } /> } label={ t('motorsport.distance') } />
                            <FactListItem value={ <Kilometers value={ distance } /> } label={ t('motorsport.totalDistance') } />
                        </FactList>
                    </MatchHead.Simple>
                </MatchHead>
            )
        }}</MatchContext.Consumer>
    )
}

export default translatable(MotorsportMatchHead);