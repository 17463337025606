import React from 'react'
import PropTypes from 'prop-types'

const MapToProps = ({ map, object, children }) => {
    const props = Object.keys(map || {}).reduce((agg, source) => { 
        const target = map[source];

        agg[target] = object[source]; 
        return agg; 
    }, {});


    return React.cloneElement(React.Children.only(children), props);
}

export default MapToProps;