import React from 'react'
import PropTypes from 'prop-types'

const  DropDownListItem = ({ selected, children, onSelect }) => {
    const className = selected ? 'dropdown__item dropdown__item--selected' : 'dropdown__item';

    return (
        <li className={ className } onClick={ onSelect }>
            { children }
        </li>
    )
}

DropDownListItem.propTypes = {
    value : PropTypes.any,
    selected : PropTypes.bool.isRequired,
    onSelect : PropTypes.func.isRequired
}

DropDownListItem.defaultProps = {
    selected : false,
    position: 'left',
    onSelect : () => { }
}

export default DropDownListItem;
