import React from 'react';
import { Textbox } from 'components'
import FormContext from '../../FormContext'

export const TextboxWidget = ({ property, placeholder }) => {
    return (
        <FormContext.Consumer>{ ({ data, setElement }) => {
            const value = data[property];

            return (
                <Textbox    value={ value }
                            placeholder={ placeholder }
                            onChange={ (e) => setElement(property, e.target.value, { label : '"' + e.target.value + '"'}) }
                            />
            )
            
        }}</FormContext.Consumer>
    )
}


export default TextboxWidget;