import React, { useEffect, useCallback, useContext, useState, useRef } from 'react'
import ReactDOM from 'react-dom'

import { Icon, WithSticker, InjurySuspensionIcon } from 'components'

import WithPlayer from './WithPlayer'
import PlayerIdentity from './PlayerIdentity';
import GameContext from './GameContext';

import * as fromUI from './widgets/ui'
import useFlyout from '../hooks/useFlyout';

const PlayerIdentityComponent = ({ playerId, captain }) => {
    return (
        <WithPlayer id={ playerId }>{ player => (
            <PlayerIdentity player={ player } captain={ !!captain } />
        )}</WithPlayer>
    );
}


const PlayerAvatarComponent = ({ playerId, imageUrl }) => {
    var { gameConfiguration, injuries, suspensions, permissionManager } = useContext(GameContext);
    const [ push] = useFlyout();
    const imgRef = useRef(null);

    const handleClick = (e) => {
        const tr = imgRef.current.closest('tr');
        let earnings = 0;
        if (tr) {
            try {
                earnings = parseInt(tr.getAttribute('earnings'));
            }
            catch {
                // Ignore
            }
        }

        push('player', { id : playerId, earnings })
    }

    return (
        <WithPlayer id={ playerId }>{ player => {

            const injury = player  ? permissionManager.hasPermission('ListInjuries') && gameConfiguration && gameConfiguration.injuries && injuries && injuries.map[player.person.id + ''] : null;
            const suspension = player  ? permissionManager.hasPermission('ListSuspensions') && gameConfiguration && gameConfiguration.suspensions && suspensions && suspensions.map[player.person.id + ''] : null;
            const suspensionDays = player  ? (suspension || []).reduce((acc, s) => acc += s.remainingMatches, 0) : 0;

           return (
                <WithSticker sticker={ <InjurySuspensionIcon injured={ !!injury } suspensionDays={ suspensionDays } /> }>
                    <img ref={ imgRef } src={ imageUrl } style={{ cursor: 'pointer' }} onClick={ handleClick } />
                </WithSticker>
            )
        }}</WithPlayer>
    );
}

const COMPONENT_MAP = {
    'PlayerIdentity' : PlayerIdentityComponent,
    'PlayerAvatar' : PlayerAvatarComponent,
    'UI.FlyoutButton' : fromUI.FlyoutButton
}



const StaticComponentRenderer = ({ children }) => {

    const [, updateState] = React.useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const elements = Array.from(document.querySelectorAll('[data-react-component]'));


    useEffect(() => {
        window.addEventListener('UIChange', forceUpdate);


        return () => {
            window.removeEventListener('UIChange', forceUpdate);
        }
    })



    var portals = elements.map(el => {

        if (!el.dataset["reactMounted"])
        {
            el.innerHTML = ''
        }

        const props = Object.keys(el.dataset).reduce((acc, k) => {
            if (k.indexOf('reactProps') === 0) {
                const prop = k.substr('reactProps'.length);
                const nProp = prop[0].toLowerCase() + prop.substr(1);

                acc[nProp] = el.dataset[k];
            }

            return acc;
        }, {});

        

        el.dataset['reactMounted'] = true;

        const componentName = el.dataset['reactComponent'];
        const Component = COMPONENT_MAP[componentName];


        if (Component == null)
            return null;

        return ReactDOM.createPortal(<Component {...props} />, el);
    })

    return (
        <React.Fragment>{ portals }</React.Fragment>
    )
}

export default StaticComponentRenderer;