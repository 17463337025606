import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from './Icon'

import styled, { css } from 'styled-components'

const StyledIcon = styled.div`
    color: ${ props => props.theme.colors.themes[props.themeName].action };
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledLabel = styled.div`
    display: flex;
    height: 48px;
    align-items: center;
    color: ${ props => props.theme.colors.themes[props.themeName].action };
    padding: 0 var(--padding) 0 0;
`


const StyledContainer = styled.div`
	display: block;
	font-size: var(--font-size-m);
    
    background: ${ props => props.disabled ? 'var(--checkable-background-color)' : 'var(--checkable-disabled-background-color)' };
	
	border-radius: 3px;

	transition: 0.15s linear border-color;
	will-change: border-color;

    user-select: none;

    border: 3px solid transparent;

    ${ 
        props => !props.disabled && css`
            &:hover {
                cursor: pointer;
                background-color : var(--checkable-hover-background-color);

            }

           
    `}

    ${ 
        props => props.checked && css`
            border-color : ${ props => props.theme.colors.themes[props.themeName].action };
    `}

    ${ 
        props => props.disabled && css`
            ${ StyledIcon } {
                color: var(--checkable-disabled-text-color)
            }

            ${ StyledLabel } {
                color: var(--checkable-disabled-text-color)
            }
    `}
`

const StyledContent = styled.div`
    color: var(--text-color);
    font-size: var(--font-size-s);
    line-height: 18px;
    padding: 0 var(--padding) var(--padding) var(--padding);
`


const RichCheckable = ({ checked, disabled, uncheckedIcon, checkedIcon, label, themeName, children, onClick}) => {
    return (
        <StyledContainer onClick={ onClick } checked={ checked } disabled={ disabled } themeName={ themeName }>
            <StyledLabel themeName={ themeName }>
                <StyledIcon themeName={ themeName }>
                    <Icon type={ checked ? checkedIcon : uncheckedIcon } />
                </StyledIcon>

                { label }
            </StyledLabel>

            {
                children &&
                <StyledContent>
                    { children }
                </StyledContent>
            }

        </StyledContainer>
    )
}


RichCheckable.propTypes = {
    checked : PropTypes.bool.isRequired,
    disabled : PropTypes.bool.isRequired,
    icon : PropTypes.string.isRequired,
    onClick : PropTypes.func.isRequired,
    themeName : PropTypes.string,
}

RichCheckable.defaultProps = {
    checked : false,
    disabled : false,
    icon : 'check',
    themeName : 'default'
}

export default RichCheckable;