import { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { makeGetCompetition } from '../redux/competition/selectors'
import { fetchCompetitionIfNeeded } from '../redux/competition/actions';

const useCompetition = (competitionId) => {
    const dispatch = useDispatch();
    const getCompetition = useMemo(() => makeGetCompetition(), []);

    useEffect(() => {
        dispatch(fetchCompetitionIfNeeded(competitionId));
    }, [competitionId]);

    const competition = useSelector(state => getCompetition(state, competitionId));
    
    return [competition];
}

export default useCompetition;