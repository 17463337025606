import React from 'react'

import { Icon } from '../components'

import translatable from '../hocs/translatable'

import Pill from '../components/Pill'

const MatchStatus = ({ t, status }) => {
    switch(status) {
        case 'pending':
            return <span className="meta">{ t('common.pending') }</span>
        case 'live':
            return (
                <Pill variant="action">
                    <Icon type="stopwatch" size="1-2x"/>
                    { t('common.live') }
                </Pill>
            )
        case 'updated':
            return <span><span className="active"><Icon type="flag" size="1-2x" /></span> { t('common.updating') } </span>
        case 'completed':
            return <span>{ t('common.completed') }</span>
        default:
            return <em>(Unknown)</em>
    }
    
}

export default translatable(MatchStatus);