import React from 'react';

import { Timeline, PersonName } from 'components'

import { fullName, minute  } from 'utils'

import WithMatchEvents from '../../WithMatchEvents'
import WithMatch from '../../WithMatch'
import PlayerLink from '../../PlayerLink'
import MatchContext from '../../MatchContext';
import LocalizeText from '../../LocalizeText';
import ContentBanner from '../../ContentBanner';



const SynthesizeEvents = ({ match, events, synthesizedEventTitles, children }) => {

    synthesizedEventTitles = synthesizedEventTitles || {};
    const home = match.participants.find(p => p.type === 'home') || {};

    let synthesizedEvents = events.filter(e => e.type.isPrimary).map(e => {
        const subEvents = events.filter(se => se.id !== e.id && se.group === e.group);

        return {
            key : e.id,
            event : e,
            subEvents,
            align: e.participant && e.participant.id === home.id ? (e.type.disadvantage ? 'right' : 'left') : (e.type.disadvantage ? 'left' : 'right'),
            type : 'player',
            
            primaryOrder : e.period,
            secondaryOrder : e.time,
            synthetic : false
        }
    });

    (match.periods ||[]).forEach((p, index) => {
        
        const pStartKey = 'PERIODSTART-' + index;
        const startTitle = synthesizedEventTitles[pStartKey] || pStartKey;
        synthesizedEvents.push({
            key : pStartKey,
            event : {
                id : pStartKey,
            },
            title : startTitle,
            type : 'match',
            
            primaryOrder : index,
            secondaryOrder : 0,
            synthetic : true
        });

        if (p.end) {
            const pEndKey = 'PERIODEND-' + index;
            const endTitle = synthesizedEventTitles[pEndKey] || pEndKey;
            synthesizedEvents.push({
                key : pEndKey,
                event : {
                    id : pEndKey,
                },
                title : endTitle,
                type : 'match',
                
                primaryOrder : index,
                secondaryOrder : 999 * 60 * 1000,
                synthetic : true
            })
        }
    })

    synthesizedEvents.sort((a, b) => {
        if (a.primaryOrder !== b.primaryOrder)
            return b.primaryOrder - a.primaryOrder;
        else
            return b.secondaryOrder - a.secondaryOrder;
    })


    return children(synthesizedEvents);
}



const TimelineBuilder = ({ synthesizedEvents }) => {
    return (
        <Timeline>
            {
                synthesizedEvents.map(e => {
                    if (e.type === 'match') {
                        
                        const label = e.synthetic ? <LocalizeText text={ e.title } /> : e.title;
                        
                        
                        return (<Timeline.Separator key={ e.key }>{ label }</Timeline.Separator>)
                    }
                    else {
                        const timeComponent = <Timeline.Row.Time>{ minute(e.event.time) }</Timeline.Row.Time>
                        const eventComponent = (
                            <Timeline.Row.Event imageUrl={ e.event.type.imageUrl } >
                                <PlayerLink player={ e.event.player }/><br />
                                <small className="meta">
                                    {
                                        e.subEvents.length === 0 ?
                                        e.event.type.title :
                                        e.subEvents.map(s => (
                                            <span key={ s.id }>
                                                { s.type.title }:{ ' '}
                                                <PersonName person={s.player.person } />
                                            </span>
                                        ))
                                    }
                                </small>
                            </Timeline.Row.Event>
                        )
                        
                        return (
                            <Timeline.Row key={ e.key } align={ e.align }>
                                { e.align === 'left' ? eventComponent : timeComponent }
                                { e.align === 'left' ? timeComponent : eventComponent }
                            </Timeline.Row>
                        )
                    }
                })
            }
        </Timeline>
    )
}


const TimelineWidget = ({ editMode, synthesizedEventTitles, placeholder }) => {



    if (editMode) {
        return    <Timeline>
                  <Timeline.Row.Event >
                                
                            </Timeline.Row.Event>
        </Timeline>
    }

    return (
        <>
        <ContentBanner />
        <MatchContext.Consumer>{ (match) => {
            if (!match) {
                return null;
            }
            else if (match.status === 'pending') {
                return null;
            }
            else {
                return (
                    <WithMatchEvents id={ match.id }>{ ({ isLoaded, events }) => {
                        if (isLoaded && (!events || events.length === 0)) {
                            return <div style={{ textAlign : 'center'}}><em>{ placeholder || 'No events available' }</em></div>
                        }

                        return (
                            <SynthesizeEvents match={ match } events={ events } synthesizedEventTitles={ synthesizedEventTitles }>{ (synthesizedEvents) => (
                                <TimelineBuilder synthesizedEvents={ synthesizedEvents } />
                            )}</SynthesizeEvents>
                        )
                    }}</WithMatchEvents>
                )
            }
        
        }}</MatchContext.Consumer>
        </>
    )
}


export default TimelineWidget;