import React from 'react'

import classNames from 'classnames'

import * as styles from './MatchCard.scss'

class MatchCardList extends React.PureComponent{
    render () {

        const className = classNames(
            styles.list
     
        )

        const { children, style } = this.props;
        return (
            <div className={ className } style={ style }>{ children }</div>
        )
    }
}

MatchCardList.propTypes = {
}

export default MatchCardList;