import React from 'react'

import { CheckBoxButtonList } from 'components'
import GameContext from '../../GameContext'
import FormContext from '../../FormContext'

const PositionCheckboxListWidget = ({ property, select }) => {
    return (
        <FormContext.Consumer>{ ({ data, setElement }) => {

            const value = data[property] || [];

            return (
            <GameContext.Consumer>{ ({ ruleset }) => {
                const items = ruleset.positions.map(p => ({ value : p[select], text : p.name  }));

                return (
                    <CheckBoxButtonList   selected={ value }
                                    items={ items }
                                    onChange={ (selected) => {
                                        setElement(property, selected, selected.map((s, index) => {
                                            const item = items.find(i => i.value === s);
                                            return { label : item.text, index }
                                        }))
                                    }} />
                
                )
            }}</GameContext.Consumer>
            )
        }}</FormContext.Consumer>
    )
}


export default PositionCheckboxListWidget;