import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

import * as styles from './FlyoutSummary.scss'

export const FlyoutSummaryMedia = ({ children }) =>  (
    <div className={ styles.media }>{ children }</div>
)


export const FlyoutSummaryItem = ({ children }) =>  (
    <div className={ styles.item }>{ children }</div>
)


export const FlyoutSummary = ({ children }) =>  (
    <div className={ styles.container }>{ children }</div>
)

FlyoutSummary.propTypes = {
}

FlyoutSummary.defaultProps = {
}

