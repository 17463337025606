export const shouldFetchItems = (state) => {
    const { isFetching, didInvalidate, items } = state || { isFetching : false, didInvalidate : false, items : null};

    if (isFetching) {
        return false;
    }
    else if (!items) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const shouldFetchItem = (state) => {
    const { isFetching, didInvalidate, item } = state || { isFetching : false, didInvalidate : false, item : null};

    if (isFetching) {
        return false;
    }
    else if (!item) {
        return true;
    }
    else {
        return didInvalidate;
    }
}