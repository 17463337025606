// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y9AKG4E7kgTJo3zCDC0w{text-overflow:ellipsis;color:var(--meta-color);font-size:11px}.kb2mzqv800kU6860fGLn{display:none}@media screen and (min-width: 688px){.y_UqlFb7IAzbjV_biJQi{display:none}.kb2mzqv800kU6860fGLn{display:inline}}`, "",{"version":3,"sources":["webpack://./src/components/PlayerByline.scss"],"names":[],"mappings":"AAAA,sBACC,sBAAA,CACA,uBAAA,CACA,cAAA,CAID,sBAAA,YAAA,CAGA,qCACC,sBAAA,YAAA,CACA,sBAAA,cAAA,CAAA","sourcesContent":[".container {\r\n\ttext-overflow: ellipsis;\r\n\tcolor: var(--meta-color);\r\n\tfont-size: 11px;\r\n}\r\n\r\n\r\n.full { display: none; }\r\n\r\n\r\n@media screen and (min-width: 688px) {\r\n\t.abbr { display: none; }\r\n\t.full { display: inline; }\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `Y9AKG4E7kgTJo3zCDC0w`;
export var full = `kb2mzqv800kU6860fGLn`;
export var abbr = `y_UqlFb7IAzbjV_biJQi`;
export default ___CSS_LOADER_EXPORT___;
