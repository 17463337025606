import React from 'react';

import PlayerContext from '../../PlayerContext'

import { number } from 'utils'

const Value = ({ }) => (
    <PlayerContext.Consumer>{ ({ values }) => {
        if (!values || values.length == 0)
            return "-"
    
        return number(values[values.length - 1].value);
    }}</PlayerContext.Consumer>
)

Value.defaultProps = {
}

export default Value;