import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'

const BreakpointContext = React.createContext();

const createMediaQueries = (breakpoints) => {
    


    const sortedBreakpoints = Object.keys(breakpoints).sort(
        (a, b) => breakpoints[b] - breakpoints[a]
    );

    
    return sortedBreakpoints.map((breakpoint, index) => {
        let query = '';
        const minWidth = breakpoints[breakpoint];
        const nextBreakpoint = sortedBreakpoints[index - 1];
        const maxWidth = breakpoints[nextBreakpoint];
    
        if (minWidth >= 0) {
          query = `(min-width: ${ minWidth }px)`;
        }
    
        if (typeof maxWidth !== 'undefined') {
          if (query) {
            query += " and ";
          }
          query += `(max-width: ${ maxWidth - 1 }px)`;
        }
    
        return {
            index : sortedBreakpoints.length - index - 1,
            breakpoint,
            maxWidth,
            minWidth,
            query
        };
    })
}

export const BreakpointProvider = React.memo(({ breakpoints, children }) => {
    const mediaQueries = useMemo(() => createMediaQueries(breakpoints), [breakpoints]);
    const initialState = useMemo(() => { return mediaQueries.find(mq => window.matchMedia(mq.query).matches) }, [breakpoints]);
    const [breakpoint, setBreakpoint] = useState(initialState);

    useEffect(() => {
        const fns = mediaQueries.map(mq => {
            const mediaQuery = window.matchMedia(mq.query);
            
            const handler = (e) => {
                if (e.matches) {
                    setBreakpoint(mq);
                }
            }

            mediaQuery.addListener(handler);

            return () => mediaQuery.removeListener(handler);
        });

        return () => {
            fns.forEach(fn => fn());
        }

    }, [breakpoints]);

    

    return <BreakpointContext.Provider value={ breakpoint }>{ children }</BreakpointContext.Provider>;

})


export const useBreakpoint = () => {
    return useContext(BreakpointContext);
};



