import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setTitle } from '../redux/ui/actions'

export const useTitle = () => {
    const dispatch = useDispatch();
    const title = useSelector(state => state.ui.title);

    const res = useMemo(() => [title, title => dispatch(setTitle(title))], [title])

    return res;
}


