import React, { useState, useCallback } from 'react';

import { Snackbar } from './Snackbar'
import  { animated,  useTransition, config } from 'react-spring'

export const SnackbarContext = React.createContext();

export const SnackbarProvider = ({ time, children }) => {
    const [items, setItems] = useState([]);
    const [internalTimeout, internalSetTimeout] = useState(null);

    const transitions = useTransition(items, item => item.key, {
        from: { 
            bottom: '24px',
            left: '50%',
            position : 'fixed', 
            transform: 'translate3d(0, 48px, 0)', 
            opacity: 0 ,
            zIndex: 4000
        },
        enter: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        leave: { transform: 'translate3d(0, 48px, 0)', opacity: 0 },
        config : config.stiff
    });

    const hide = useCallback(() => {
        setItems([]);
        internalSetTimeout(null);
    })

    const open = useCallback((message, type) => {
        setItems([{ key : 0, message, type }]);
        
        if (internalTimeout)
            clearTimeout(internalTimeout);
        
        internalSetTimeout(setTimeout(hide, time));
    });
    

    return (
        <SnackbarContext.Provider value={{ open }}>
            {children}

            { transitions.map(({ item, props, key}) => (
                <animated.div key={key} style={props}>
                    <Snackbar type={ item.type }>
                        { item.message}
                    </Snackbar>
                </animated.div>
            ))}
        </SnackbarContext.Provider>
    );
}

SnackbarProvider.defaultProps = {
    time : 3000
}

export const SnackbarConsumer = SnackbarContext.Consumer;