import React from 'react'


import MatchPointList from './MatchPointList'
import GameContext from '../../GameContext'
import { Number } from 'components'
import ContentBanner from '../../ContentBanner'


const SimpleMatchPointList = ({ editMode, home, away, ...otherProps }) =>  {

    if (editMode) {
        return (
            <div>Not available in edit mode</div>
        )
    }



    return (
        <>
        <ContentBanner />
            <GameContext.Consumer>{ ({ tournament }) => {

                return (
                    <MatchPointList {...otherProps }>{ ({ match, growth, player }) => {
                        
                        return (
                            <div style={{ display: 'flex', width : '100%' }}>
                                <div style={{ flex : 1 }}>{ match.name }</div>
                                <Number value={ growth } />
                            </div>
                        )
                    }}</MatchPointList>
                )
            }}</GameContext.Consumer>
            </>
    )
}


SimpleMatchPointList.defaultProps = {
    home : 'Home',
    away : 'Away'
}


export default SimpleMatchPointList;