import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Button.scss'

const ButtonBase = ({ primary, transparent, outline, large, small, tiny, inline, group, disabled, nopadding, children }) => {
    const className = classNames(
        styles.button,
        { 
            [styles.primary] : primary,
            [styles.transparent] : transparent,
            [styles.outline] : outline,
  
            [styles.large] : large,
            [styles.small] : small,
            [styles.tiny] : tiny,
  
            [styles.inline] : inline,
            [styles.group] : group,

            [styles.disabled] : disabled,
            [styles.nopadding] : nopadding
        }
    );

    return children(className);
}

ButtonBase.propTypes = {
    
    primary : PropTypes.bool,
    transparent : PropTypes.bool,
    outline : PropTypes.bool,

    large : PropTypes.bool,
    small : PropTypes.bool,
    tiny : PropTypes.bool,

    inline : PropTypes.bool,
    group : PropTypes.bool,

    disabled : PropTypes.bool,
    nopadding : PropTypes.bool
    
}

export default ButtonBase;