import React from 'react'

import PlayerContext from '../../PlayerContext'
import WithPlayerFantasyEvents from '../../WithPlayerFantasyEvents'

import {  XButton, Accordion, AccordionItem, ListView, ListViewItem, Number } from '../../../components'
import { lo } from '../../../common/utils';

import GameNavigationService from '../../GameNavigationService'
import GameContext from '../../GameContext'
import WithSchedule from '../../WithSchedule'
import translatable from '../../../hocs/translatable';

class MatchPointList extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    
    render() {

        return (
            <PlayerContext.Consumer>{ (player) => (
                <GameContext.Consumer>{({ game, tournament }) => (
                    <WithSchedule id={ game.tournament } gameId={ game.id }>{ ({ schedule }) => (
                        <WithPlayerFantasyEvents gameId={ game.id } playerId={ player.id }>{ ({ fantasyEvents }) => {
                           
                            const matchMap = (schedule && schedule.items || []).reduce((agg, item) => { 
                                agg[item.id] = item; 
                                return agg; 
                            }, {});

                            let rounds = fantasyEvents.reduce((agg, e) => {
                                const match = matchMap[e.match.id + ''];
                                if (!match)
                                    return agg;

                                if (typeof agg[match.round + ''] === 'undefined')  {
                                    agg[match.round] = {
                                        matches : {}
                                    }
                                }

                                if (typeof agg[match.round].matches[e.match.id] === 'undefined')

                                    agg[match.round].matches[e.match.id] = {
                                        match,
                                        fantasyEvents : [],
                                        growth : 0
                                    }
                                

                                if (e.type) {
                                   agg[match.round].matches[e.match.id].fantasyEvents.push(e);
                                   agg[match.round].matches[e.match.id].growth += e.amount * e.type.value;
                                }

                                return agg;
                            }, {});
                            
                            
                            

                            return Object.keys(rounds).reverse().map((r, index) => {

                                const matches = rounds[r].matches
                                let items = Object.values(matches);
                                items.sort((a, b) => b.match.round - a.match.round);

                                const roundTitle = this.props.t('common.roundFormat', { round : r })

                                return (
                                    <div key={ r } style={{ marginBottom : '12px' }}>
                                        <div style={{ height: '24px', lineHeight: '24px', fontSize: '11px', color: '#8a8a8a'}}>{ roundTitle }</div>
                                        <Accordion initialSelectedIndex={ index == 0 ? 0 : -1 }>
                                            {
                                                items.map(item => {

                                                    const header = this.props.children({ match : item.match, growth : item.growth, player })


                                                    return (
                                                        <AccordionItem key={ item.match.id} heading={ header } onClick={ () => {}}>
                                                           
                                                                <ListView>
                                                                    {
                                                                        item.fantasyEvents.map(fe => (
                                                                            <ListViewItem key={ fe.id } height={ 5 } display="flex" alignItems="center" justifyContent="space-between">
                                                                                <div>
                                                                                    { lo(fe.type, 'shortTitle') }
                                                                                    { fe.amount > 1 && <small className="meta">{ ' ' } x { fe.amount }</small> }
                                                                                </div>
                                                                                <div><Number value={ fe.type.value * fe.amount } treatZeroAsMissing /></div>

                                                                            </ListViewItem>
                                                                        ))
                                                                    }

                                                                </ListView>
                                                            
                                                            <GameNavigationService>{ ({ showMatch}) => (
                                                                <XButton variant="transparent" onClick={ () => showMatch(item.match.id)} textTransform="uppercase">{ this.props.detailsCaption }</XButton>
                                                            )}</GameNavigationService>
                                                        </AccordionItem>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </div>
                                )
                            })
                        }}</WithPlayerFantasyEvents>
                    )}</WithSchedule>
                )}</GameContext.Consumer>
            )}</PlayerContext.Consumer>
        )     
    }
}


export default translatable(MatchPointList);