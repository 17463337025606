import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import * as Components from 'components'


const Banner = ({ adUnit, id, sizes }) => {
return null;
}

Banner.propTypes = {
    adUnit : PropTypes.string.isRequired,
    sizes : PropTypes.array.isRequired
}

export default Banner;