// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CdVFmSkJgLbsgmftkO_w {
    max-width: 640px;
    min-height: 568px;
    display: grid;
    
    overflow-x: hidden;
    overflow-y: auto;
}

.BwRSAX1T4VwtoDFGG7dG {
    display: none;
    text-align: center;
    background-position: center center;
    background-size: cover;
}


@media screen and (min-width: 768px) {
    .CdVFmSkJgLbsgmftkO_w {
        width: 640px;
        grid-template-columns: 1fr 1fr;
    }

    .BwRSAX1T4VwtoDFGG7dG {
        display: block;
    }
}

.OAS3YF2OT1Ed804FiRFJ {
    padding: 24px 24px 24px 24px;
}

.n9UwRIUBWnImfndDD3qE {
    position: relative;
}

.aCMWyrui86QvMtDXWbws {
    margin-bottom: 24px;
    margin-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/AuthenticationModal.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;;IAEb,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,kCAAkC;IAClC,sBAAsB;AAC1B;;;AAGA;IACI;QACI,YAAY;QACZ,8BAA8B;IAClC;;IAEA;QACI,cAAc;IAClB;AACJ;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".container {\r\n    max-width: 640px;\r\n    min-height: 568px;\r\n    display: grid;\r\n    \r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n}\r\n\r\n.cover {\r\n    display: none;\r\n    text-align: center;\r\n    background-position: center center;\r\n    background-size: cover;\r\n}\r\n\r\n\r\n@media screen and (min-width: 768px) {\r\n    .container {\r\n        width: 640px;\r\n        grid-template-columns: 1fr 1fr;\r\n    }\r\n\r\n    .cover {\r\n        display: block;\r\n    }\r\n}\r\n\r\n.content {\r\n    padding: 24px 24px 24px 24px;\r\n}\r\n\r\n.content-inner {\r\n    position: relative;\r\n}\r\n\r\n.header {\r\n    margin-bottom: 24px;\r\n    margin-right: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `CdVFmSkJgLbsgmftkO_w`;
export var cover = `BwRSAX1T4VwtoDFGG7dG`;
export var content = `OAS3YF2OT1Ed804FiRFJ`;
var _1 = `n9UwRIUBWnImfndDD3qE`;
export { _1 as "content-inner" };
export var header = `aCMWyrui86QvMtDXWbws`;
export default ___CSS_LOADER_EXPORT___;
