import React from 'react';
import PropTypes from 'prop-types';


import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { points, number } from 'utils'

import PlayerIdentity from '../PlayerIdentity'
import { ErrorBoundary } from '../../components';
import ContentBanner from '../ContentBanner';


const Points = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ points(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const PlayerList = ({ heading, items }) => (
    <React.Fragment>
        <Table.Head>
            <Table.Row>
                <Table.Header>{ heading }</Table.Header>
            </Table.Row>
        </Table.Head>
        
        <Table.Body>
            { 
                items.map((item, index) => {
                    const madeTheCut = item.stats.madeTheCut;

                    return (
                        <Table.Row key={ index } disabled={ !madeTheCut }>
                            <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                        </Table.Row>
                    )
                })
            }
        </Table.Body>
    </React.Fragment>
)


const Golfers = ({ items }) => {

    const players = items.sort((a, b) => {

        if (a.stats.endPosition === b.stats.endPosition) {
            return b.growth - a.growth;
        }
        else
            return (a.stats.endPosition || 0) - (b.stats.endPosition || 0)
    
    });

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  players.length > 0 && <PlayerList items={ players } heading="Spiller" /> }
                </Table>

            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                {  players.length > 0 &&
                    
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center>Pos</Table.Header>
                                <Table.Header center>Til par</Table.Header>
                                <Table.Header center>R1</Table.Header>
                                <Table.Header center>R2</Table.Header>
                                <Table.Header center>R3</Table.Header>
                                <Table.Header center>R4</Table.Header>
                                <Table.Header center>Total</Table.Header>
                                
                            </Table.Row>
                        </Table.Head>

                        <Table.Body>
                            { 
                                players.map((item, index) => {
                                    const par = parseInt(item.match.properties.tournamentPar || '0') * 4;
                                    const total = (item.stats.day1Strokes || 0) + (item.stats.day2Strokes || 0) + (item.stats.day3Strokes || 0) + (item.stats.day4Strokes || 0);
    
                                    const endPosition = item.stats.endPosition;
                                    const toPar = total - par;
                                    const r1 = item.stats.day1Strokes;
                                    const r2 = item.stats.day2Strokes;
                                    const r3 = item.stats.day3Strokes;
                                    const r4 = item.stats.day4Strokes;
                                    const madeTheCut = item.stats.madeTheCut;
                                    

                                    return (
                                        <Table.Row key={ index } disabled={ !madeTheCut }>
                                            <Table.Cell right><Points value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ endPosition } /></Table.Cell>
                                            <Table.Cell center><Number value={ toPar } /></Table.Cell>
                                            <Table.Cell center><Number value={ r1 } /></Table.Cell>
                                            <Table.Cell center><Number value={ r2 } /></Table.Cell>
                                            <Table.Cell center><Number value={ r3 } /></Table.Cell>
                                            <Table.Cell center><Number value={ r4 } /></Table.Cell>
                                            <Table.Cell center><Number value={ total } /></Table.Cell>
                                            

                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const GolfTournamentReport = () => {
    return (
        <>
        <ContentBanner />
        <ErrorBoundary>
            <MatchContext.Consumer>{ (match) => {
                return (
                    <WithMatchEvents id={ match.id }>{ ({ events }) => (
                        <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                            <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {
                                return (
                                    <React.Fragment>
                                        <Golfers items={ items }/>
                                    </React.Fragment>
                                )
                            }}</WithMatchReport>
                        )}</WithMatchFantasyEvents>
                    )}</WithMatchEvents>
                )
            }}</MatchContext.Consumer>
        </ErrorBoundary>
        </>
    )
}


GolfTournamentReport.propTypes = {
 
}

export default GolfTournamentReport;