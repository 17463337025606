import React from 'react'
import PropTypes from 'prop-types'

class Media extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            matches : false
        }
        this.matchMedia = null;
        this.updateMatches = this.updateMatches.bind(this);
    }


    componentWillUnmount() {
        if (this.matchMedia) {
            this.matchMedia.removeListener(this.updateMatches);
        }
        this.matchMedia = null;
    }
    
    componentDidMount() {
        this.matchMedia = window.matchMedia(this.props.query);
        this.matchMedia.addListener(this.updateMatches);
        this.updateMatches(this.matchMedia);
    }

    updateMatches(matchMedia) {
        this.setState({
            matches : matchMedia.matches
        })
    }

    render() {
        const { children } = this.props;
        const { matches } = this.state;
        
        return matches ? React.Children.only(children) : null;
    }  
}

Media.propTypes = {
   query : PropTypes.string,
   placeholder : PropTypes.any
}

Media.defaultProps = {
}

export default Media;

