import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'

import { invalidateMatchFantasyEvents, fetchMatchFantasyEventsIfNeeded } from '../redux/match/actions';

class WithMatchFantasyEvents extends React.PureComponent {

    constructor(props) {
        super(props);

    }


    componentDidMount() {
        this.props.fetch(this.props.id);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id && nextProps.enabled) {
            this.props.fetch(nextProps.id);
        }
    }

    render() {
        const { fantasyEvents, children } = this.props;



        return children({ isLoaded : fantasyEvents && fantasyEvents.isLoaded, fantasyEvents : (fantasyEvents && fantasyEvents.items) || [] });
    }
}

export const makeGetFantasyEvents = () => createSelector([
        (state, props) => props.id,
        (state) => state.match.fantasyEvents,
        (state) => state.entities.fantasyEvents,
        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.teams,
        (state) => state.entities.positions,
        (state) => state.entities.fantasyEventTypes
    ],
    (id, matchEvents, eventEntities, playerEntities, personEntities, teamEntities, positionEntities, fantasyEventTypeEntities) => {
        const list = (matchEvents[id] || {}).items || [];

        const events = list.map(eventId => {
            const e = eventEntities[eventId];
            const player = playerEntities[e.player];
            const person = player && personEntities[player.person];
            const team = player && teamEntities[player.team];
            const position = player && positionEntities[player.position];

            return {
                ...e,
                type : fantasyEventTypeEntities[e.type],
                player : {
                    ...player,
                    person : person,
                    team : team,
                    position : position
                }
            }
        });

        return {
            ...matchEvents[id],
            items : events
        }
    }
)



const makeMapStateToProps = () => {
    const getFantasyEvents = makeGetFantasyEvents();
    return (state, ownProps) => ({
        fantasyEvents : getFantasyEvents(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id, force) {
            if (force) {
                dispatch(invalidateMatchFantasyEvents(id));
            }
            dispatch(fetchMatchFantasyEventsIfNeeded(id));
        }
    }
}

WithMatchFantasyEvents.propTypes = {
    id : PropTypes.any.isRequired
}

WithMatchFantasyEvents.defaultProps = {

}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithMatchFantasyEvents);

