import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'

import { fetchTournamentStandingsIfNeeded } from '../redux/tournament/actions';

class WithTournamentStandings extends React.PureComponent {
    componentDidMount() {
        this.props.fetch(this.props.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.props.fetch(nextProps.id);
        }
    }

    render() {
        const { standings, children } = this.props;
        
        return children(standings);
    }
}



export const makeGetTournamentStandings = () => createSelector([
        (state, props) => props.id,
        (state) => state.tournaments.standingsById,
        (state) => state.entities.matchGroups,
        (state) => state.entities.teams
    ],
    (id, standingsById, matchGroups, teams) => {
        const standings = standingsById[id] || { items : [], isFetching : false };
        
        const groups = (standings.items || []).map(s => {
            const matchGroup = matchGroups[s.group];

            return {
                group : matchGroup,
                standings : (s.standings || []).map(i => {
                    const team = teams[i.team];

                    return {
                        ...i,
                        goalDifference : `${ i.goalsFor}-${ i.goalsAgainst}`,
                        team
                    }
                })
            }
        })
        
        return {
            isFetching : standings.isFetching,
            groups
        }
    }
)



const makeMapStateToProps = () => {
    const getTournamentStandings = makeGetTournamentStandings();
    return (state, ownProps) => ({
        standings : getTournamentStandings(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id) {
            dispatch(fetchTournamentStandingsIfNeeded(id));
        }
    }
}

WithTournamentStandings.propTypes = {
    id : PropTypes.any.isRequired
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithTournamentStandings);

