import React from 'react'

import {  Box, FactList, FactListItem, BodyCopy, Separator } from 'components'

import { useTranslator } from '../hooks/useTranslator'

const Info = ({ competition}) => {
    const t = useTranslator();
    return (
        <Box p="s400">
            <Separator pb="s400"/>

            { 
                competition && competition.description && 
                <BodyCopy pb="s500">
                    { competition.description }
                </BodyCopy>
            }

            <FactList>
                <FactListItem label={ t('competitions.enrolled') } icon="group">{ competition.enrollmentCount }</FactListItem>
                
                { competition.owner &&  <FactListItem label="Administrator" icon="person">{ competition.owner.username }</FactListItem> }
                               
                <FactListItem label={ t('common.rounds') } icon="person">
                    { competition && `${ competition.firstRound }-${ competition.firstRound + competition.rounds }` }
                </FactListItem>
            </FactList>

         
        </Box>
    )
}


export default Info;