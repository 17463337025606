import * as types from './types'

const defaultState = {
    leagues : {
        isFetching : false,
        didFetch : false
    },
    leaderboard : {
        isFetching : false,
        didFetch : false
    }
};

const archiveReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.FETCH_LEAGUE_ARCHIVE_REQUEST:
            return  { ...state, leagues : { ...state.leagues, isFetching : true } };        
        case types.FETCH_LEAGUE_ARCHIVE_SUCCESS:
            return  { 
                ...state,
                leagues : {
                    ...state.leagues,
                    isFetching : false,
                    
                    data : action.payload,
                    didInvalidate: false
                }
            };
        case types.FETCH_LEAGUE_ARCHIVE_FAIL:
            return  {  ...state, leagues : { ...state.leagues, isFetching : false } };             

        /* */

        case types.FETCH_ARCHIVED_LEADERBOARD_REQUEST:
                return  { ...state, leaderboard : { ...state.leaderboard, isFetching : true } };        
        case types.FETCH_ARCHIVED_LEADERBOARD_SUCCESS:
            return  { 
                ...state,
                leaderboard : {
                    ...state.leaderboard,
                    isFetching : false,
                    didFetch : true,
                    data : action.payload,
                    didInvalidate: false
                }
            };
        case types.FETCH_LEAGUE_ARCHIVE_FAIL:
            return  {  ...state, leaderboard : { ...state.leaderboard, isFetching : false } };                         
        default:
            return state
    }
}

export default archiveReducer;
