import React from 'react'
import { withRouter } from 'react-router-dom'
import RoutedDesktopSiteHeader from './RoutedDesktopSiteHeader'

import { GameHeader, GameIdentity, Phase, PhaseText, Countdown} from '../../../components'

import { lo } from '../../../common/utils'
import GameMenu from '../GameMenu'
import translatable from '../../../hocs/translatable'
import GameContext from '../../GameContext';


const RoutedDesktopGameHeader = ({ match, t }) => {
    const { view, gameId, gameSlug } = match.params;


    return (
        
        <GameContext.Consumer>
            {
                ({ game, gameConfiguration }) => (
                    <GameHeader backgroundImageUrl={ gameConfiguration && gameConfiguration.theme && gameConfiguration.theme.gameBackgroundImage.substring(4, gameConfiguration.theme.gameBackgroundImage.length - 1) } theme={ (view !== 'landing' && gameConfiguration) ? gameConfiguration.theme : {} }  themeName="_current" >

                        <RoutedDesktopSiteHeader></RoutedDesktopSiteHeader>
                        
                        {
                            

                            view !== 'landing' && game && gameConfiguration &&
                            <Phase game={ game } configuration={ gameConfiguration }>
                                {
                                    (phase) =>
                                        <GameIdentity title={ lo(game, 'shortName') }
                                                    variant={ lo(game, 'edition') }
                                                    subtitle={ lo(game, 'subtitle') }
                                                    
                                                    round={ phase.round }
                                                    themeName="_current"

                                                    sponsorImageUrl={ gameConfiguration && gameConfiguration.sponsor && gameConfiguration.sponsor.imageUrl } 

                                                    statusHeading={ <PhaseText phase={ phase } text={ phase.heading }/> }
                                                    countdown={ <Countdown to={ phase.deadline } /> }
                                                    statusSubheading={ <PhaseText phase={ phase } text={ phase.progress }/> }
                                                    />
                                }
                            </Phase>
                        }

                        <div style={{ maxWidth: '1200px', margin : '0 auto'}}>
                            {
                                view !== 'landing' &&
                                <GameMenu themeName="_current" />
                            }

                            </div>
                    </GameHeader>
                )
            }
        </GameContext.Consumer>
    )
}

RoutedDesktopGameHeader.propTypes = {
}

export default translatable(
    withRouter(RoutedDesktopGameHeader)
);
