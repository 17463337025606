import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Odds } from '../components';

import { format } from 'utils'
import GameContext from './GameContext'

const MatchOdds = React.memo(({ odds }) => {

    const { gameConfiguration } = useContext(GameContext);

    if (!odds)
        return null;


    const link = gameConfiguration.odds && gameConfiguration.odds.link;

    const o1 = odds['1'];
    const ox = odds['x'];
    const o2 = odds['2'];

    if (!o1 || !ox || !o2 )
        return null;

    const link1 = link && format(link, o1);
    const linkx = link && format(link, ox);
    const link2 = link && format(link, o2);

    return (
        <Odds>
            <Odds.Outcome type="1" odds={ o1?.odds } url={ link1 }/>
            <Odds.Outcome type="X" odds={ ox?.odds } url={ linkx } />
            <Odds.Outcome type="2" odds={ o2?.odds } url={ link2 } />
        </Odds>
    )
})

MatchOdds.displayName = 'MatchOdds';

MatchOdds.propTypes = {
    odds : PropTypes.object
}


export default MatchOdds;