import React from 'react';

import { FactList, FactListItem } from 'components'

import PlayerContext from '../../PlayerContext'
import GameContext from '../../GameContext'
import WithPlayerEvents from '../../WithPlayerEvents'
import WithPlayerValues from '../../WithPlayerValues'
import LocalizeText from '../../LocalizeText'

import { number, percent, camelize, resolve } from 'utils'

const formatValue = ( format, value ) => {
    switch(format) {
        case 'number':
            return number(value);
        case 'percent':
            return percent(value);
        default:
            return value + '';
    }
}

const FactListWidget = ({ large, items }) => (
    <PlayerContext.Consumer>{ ({ id }) => (
        <GameContext.Consumer>{ ({ game, currentRoundNumber }) => (
            <WithPlayerValues gameId={ game.id } playerId={ id }>{ ({ values }) => (
                <WithPlayerEvents playerId={ id }>{ ({ events }) => {
                    const value = values.find(v => v.round === currentRoundNumber);
                    const facts = {
                        ...value,
                        events : events.reduce((agg, e) => {
                            const propertyName = camelize(e.type.name);
                            if (agg.hasOwnProperty(propertyName))
                                agg[propertyName] += e.amount;
                            else
                                agg[propertyName] = e.amount;

                            return agg;
                        }, {})
                    }

                    return (
                        <FactList size={ large ? 'l' : 'm' }>
                            {
                                items.map((item, index) => {
                                    const value = resolve(item.property, facts);

                                    return (
                                        <FactListItem key={ index } label={ <LocalizeText text={ item.label } />}>
                                            { formatValue(item.format, value) }
                                        </FactListItem>
                                    )
                                })
                            }
                        </FactList>
                    )
                }}</WithPlayerEvents>
            )}</WithPlayerValues>
        )}</GameContext.Consumer>
    )}</PlayerContext.Consumer>
)

FactListWidget.defaultProps = {
}

export default FactListWidget;