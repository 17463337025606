import React from 'react'
import PropTypes from 'prop-types'
import TextCountdown from './TextCountdown'

const PhaseText = ({ text, phase }) => {
    
    text = text || '';
    
    return (
        <TextCountdown to={ phase.deadline }>
            {
                (countdown) => {
                    const args = [phase.round, phase.rounds, countdown];
                    return text.replace(/{(\d+)}/g, (match, number) => args[number])
                }
            }
        </TextCountdown>
    )
}

PhaseText.propTypes = {
    game : PropTypes.object,
    configuration : PropTypes.object
}

export default PhaseText;