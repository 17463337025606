import React from 'react'

import {   Table, EventDate, Round, Tag, Icon } from '../../components'

import { ErrorBoundary, Media } from '../../components'
import MatchStatus from '../MatchStatus'
import SimpleSchedule from '../SimpleSchedule'
import GameNavigationService from '../GameNavigationService'






const DesktopStockScheduleTableRow = ({ item, onClick }) => {

    return (
        <Table.Row onClick={ onClick } active={ item.current }>
            <Table.Cell center><Round post={ item.status === 'completed' }>{ item.round }</Round></Table.Cell>
            <Table.Cell><EventDate date={ new Date(item.start) } format="short" /></Table.Cell>
            <Table.Cell><strong className="a">{ item.name }</strong></Table.Cell>
            <Table.Cell right>
                {
                    (() => {
                        switch(item.status) {
                            case 'live':
                                return (
                                    <Tag primary>
                                        <Icon size="1-2x" type="stopwatch"></Icon>
                                        Live
                                    </Tag>
                                );
                            default:
                                return <MatchStatus status={ item.status } />;
                        }
                    })()
                }

                
            
               
            </Table.Cell>
        </Table.Row>
    )
}

const DesktopScheduleTable = ({ schedule, onSelectMatch }) => {

    return (
    <Table>
        <Table.Head>
            <Table.Row>
                <Table.Header center>Omgång</Table.Header>
                <Table.Header>Start</Table.Header>
                
                <Table.Header>Konkurrens</Table.Header>
                <Table.Header right>Status</Table.Header>
            </Table.Row>
        </Table.Head>
        <Table.Body>
            { schedule.items.map(item => <DesktopStockScheduleTableRow key={ item.id } item={ item } onClick={ () => onSelectMatch(item) } ></DesktopStockScheduleTableRow>) }
        </Table.Body>
    </Table>
    )
}


const MobileStockScheduleTableRow = ({ item, onClick }) => {
    return (
        <Table.Row onClick={ onClick } active={ item.current } highlight={ item.status === 'live'}>
            <Table.Cell center><Round post={ item.status === 'completed' }>{ item.round }</Round></Table.Cell>
            <Table.Cell>
                <strong className="a">{ item.name }</strong><br />
                <small><EventDate date={ new Date(item.start) } format="short" /></small>
            </Table.Cell>
            <Table.Cell right><MatchStatus status={ item.status } /></Table.Cell>
        </Table.Row>
    )
}

const MobileScheduleTable = ({ schedule, onSelectMatch }) => {

    return (
    <Table>
        <Table.Head>
            <Table.Row>
                <Table.Header center>Omgång</Table.Header>
                <Table.Header>Konkurrens</Table.Header>
                <Table.Header right>Status</Table.Header>
            </Table.Row>
        </Table.Head>
        <Table.Body>
            { schedule.items.map(item => <MobileStockScheduleTableRow key={ item.id } item={ item } onClick={ () => onSelectMatch(item) } ></MobileStockScheduleTableRow>) }
        </Table.Body>
    </Table>
    )
}

const SkiSchedule = () => {
    return (
        <ErrorBoundary>
            <GameNavigationService>{ ({ showMatch }) => ( 
                <SimpleSchedule>{ ({ schedule }) => (

                    <div>
                        <Media query="(min-width: 1024px)">
                            <DesktopScheduleTable schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   } />
                        </Media>

                        <Media query="(max-width: 1023px)">
                            <MobileScheduleTable schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)   } />
                        </Media>
                    </div>

                    
                        
                )}</SimpleSchedule>
            )}</GameNavigationService>
        </ErrorBoundary>
    )
}

export default SkiSchedule;