import React from 'react';
import PropTypes from 'prop-types'

import MatchContext from '../../MatchContext'

const Title = ({ editMode }) => {
    if (editMode) {
        return "<Match Name>"
    }


    return (
        <MatchContext.Consumer>{ (match) => {
            return match.name
        }}</MatchContext.Consumer>
    )

}

export default Title;