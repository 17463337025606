import React from 'react';
import PropTypes from 'prop-types'


const Link = ({ href, target, children }) => (
    <a href={ href } target={ target }>{ children }</a>

)

Link.propTypes = {
    href : PropTypes.string.isRequired,
    target : PropTypes.string
}

export default Link;