export const FETCH_NOTIFICATIONS_REQUEST       = 'notifications/fetch/request';
export const FETCH_NOTIFICATIONS_SUCCESS       = 'notifications/fetch/success';
export const FETCH_NOTIFICATIONS_FAIL          = 'notifications/fetch/fail';

export const INVOKE_NOTIFICATION_REQUEST       = 'notification/invoke/request';
export const INVOKE_NOTIFICATION_SUCCESS       = 'notification/invoke/success';
export const INVOKE_NOTIFICATION_FAIL          = 'notification/invoke/fail';


export const MARK_NOTIFICATIONS_READ_REQUEST   = 'notifications/markread/request';
export const MARK_NOTIFICATIONS_READ_SUCCESS   = 'notifications/markread/success';
export const MARK_NOTIFICATIONS_READ_FAIL      = 'notifications/markread/fail';

export const ACKNOWLEDGE_NOTIFICATIONS_REQUEST   = 'notifications/acknowledge/request';
export const ACKNOWLEDGE_NOTIFICATIONS_SUCCESS   = 'notifications/acknowledge/success';
export const ACKNOWLEDGE_NOTIFICATIONS_FAIL      = 'notifications/acknowledge/fail';

export const INVALIDATE_NOTIFICATIONS           = 'notifications/invalidate'

export const WS_NOTIFICATION                    = 'notifications/ws'