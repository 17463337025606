import React, {  useEffect } from 'react'
import ReactDOM from 'react-dom'

import ViewContainer from './ViewContainer'

import DataContextProvider from '../components/DataContextProvider'

const StaticWidgetRenderer = () => {
    const elements = Array.from(document.querySelectorAll('[data-view]'));



    return elements.map(e => {
        const view = e.dataset['view'];
        const viewParametersJson = e.dataset['viewParameters'];
        
        const viewParameters = viewParametersJson && JSON.parse(viewParametersJson);

        return ReactDOM.createPortal(
            <DataContextProvider value={ window.staticViewContext } root>
                <ViewContainer name={ view } parameters={ viewParameters }/>
            </DataContextProvider>, e);

    })

    
}

export default StaticWidgetRenderer;