import React, { useContext } from 'react';
import PropTypes from 'prop-types'

import * as Components from 'components';

const Number = ({ value, ...otherProps }) => {
    return <Components.Number value={ value } { ...otherProps }/>
}

Number.propTypes = {
    value : PropTypes.number.isRequired
}

Number.defaultProps = {
    value : 0
}

export default Number;