import React from 'react'


import styled from 'styled-components'


const SectionHeading = styled.div`
	height: 36px;
    line-height: 36px;
    
    margin-bottom: 6px;
`

export default SectionHeading;