import React from 'react'

import { MatchCardList } from '../../components'
import GenericMatchCard from './GenericMatchCard'

const GenericScheduleCardList = ({ schedule, onSelectMatch }) => {
    return (
        <MatchCardList>
            { schedule.items.map(item => <GenericMatchCard key={ item.id }  onClick={ () => onSelectMatch(item) } item={ item } />)  }
        </MatchCardList>
    )
}

export default GenericScheduleCardList;