import React, {  useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DataContext from './DataContext';
import { pick } from 'utils'


import ErrorBoundary from './ErrorBoundary'
import translatable from '../hocs/translatable'

import ComponentEditor from './ComponentEditor'


const parseProps = (componentProps, context, t) => {
    return Object.keys(componentProps).reduce((agg, k) => {
        const value = componentProps[k];

        if (typeof value === 'string' && value.indexOf('!') === 0)  {
            agg[k] = context[value.substring(1)]
        }
        else if (typeof value === 'string' && value.indexOf('@') === 0)  {
            agg[k] = t(value.substring(1))
        }
        else if (Array.isArray(value)) {
            agg[k] = value.map((item) => {
                if (typeof item === 'object' && item !== null)
                    return parseProps(item, context, t)
                else
                    return item;
            });
            }
           else if (typeof value === 'object' && value !== null) {
            agg[k] = parseProps(value, context, t);
        }
        else
            agg[k] = value;
        
        return agg;
    }, {});
}


const capitalize = (val) => {
    return val.toUpperCase()
};




const functions = {
    'capitalize' : capitalize
}


const RuntimeComponentWrapper = translatable(({ t, componentContext, children, component,editMode, ...props }) => {

    const ComponentToRender = component;


    const dataContext = useContext(DataContext);

    const funcs = {
        ...functions,
        translate : t
    }

    
        

    const propertyMapper = useMemo(() => {
        return Object.keys(props).reduce((agg, key) => {
            const value = props[key];
            if (typeof value === 'string' && value.length > 1 &&  value[0] === '=')
            {
                const expr = value.substring(1);
                try {
                    

                    let body = '';
                    Object.keys(funcs).forEach(func => {
                        body += 'const ' + func + ' = functions[\'' + func + '\'];\n';
                    })
                    body += 'return ' + expr + '\n';

                    agg[key] = new Function('context', 'functions', body);
                }
                catch(e) {
                    console.warn('Error evaluating expression \'' + expr + '\'.', e);
                    agg[key] = value;
                }

            }
            else if (typeof value === 'string' && value.length > 1 &&  value[0] === '$')
            {
                const expr = value.substring(1);

                agg[key] = (context) => {
                    return pick(expr, context)
                }

            }
            else {
                agg[key] = value;
            }
            return agg;
        }, {});
    }, [props]);

    const mappedProps = Object.keys(propertyMapper).reduce((agg, key) => {
        const value = propertyMapper[key];
        
        if (typeof value === 'function')
        {
            try {
                agg[key] = typeof value === 'function' ? value(dataContext, funcs) : value;
            }
            catch (e) {
                console.warn('Error evaluating custom expression \'' + props[key] + '\'', e);
                agg[key] = props[key];
            }
        }
        else {
            agg[key] = value;    
        }

        
        return agg;
    }, {})

    return <ComponentToRender {...mappedProps} componentContext={ componentContext } editMode={ editMode }>{ children }</ComponentToRender>
})





const getComponent = (parent, key, configuration, components, context, componentProps, t, editMode, definitions) => {

    const path = [...parent, key];


    const ComponentToRender = components[configuration.type];
    if (typeof ComponentToRender === 'undefined') {
        console.warn(`Invalid component type "${ configuration.type}" - did you misspell it?`);
        return null;
    }

    let children = null;
    if (typeof configuration.children === 'string') {
        children = configuration.children;
    }
    else if (Array.isArray(configuration.children)) {
        children = configuration.children.map((c, index) => getComponent(path, index, c, components, context, componentProps, t, editMode, definitions));
        if (children.length == 1)
            children = children[0];
    }
    else if (typeof configuration.children === 'object') {
        children = getComponent(path, 0, configuration.children, components, context, componentProps, t, editMode, definitions);
    }
    
    const parsedProps = parseProps(configuration.props || {}, context, t);

    const wrappable = Object.values(configuration.props ||{}).some(p => typeof p === 'string' && p.length > 0 && (p[0] === '=' ||p[0] === '$'));

   


    const componentContext = {
        path : path,
        definition : definitions && definitions[configuration.type],
        type: configuration.type
    };

    if (wrappable) {

        if (editMode) {
            return (
                <ComponentEditor key={ key } componentContext={ componentContext}>
                    <RuntimeComponentWrapper  componentContext={ componentContext } {...parsedProps } editMode={ editMode } definitions={ definitions } component={ ComponentToRender }>
                    { children }
                    </RuntimeComponentWrapper>
                </ComponentEditor>
            );
        }
        else {
            return ( <RuntimeComponentWrapper key={ key } componentContext={ componentContext } {...parsedProps } editMode={ editMode } definitions={definitions} component={ ComponentToRender }>
                    { children }
                </RuntimeComponentWrapper>
                )
        }
    }
    else {
        if (editMode) {
            return (
                <ComponentEditor key={ key } componentContext={ componentContext}>
                    <ComponentToRender  {...parsedProps } componentContext={ componentContext } editMode={ editMode }>{ children }</ComponentToRender>
                </ComponentEditor>
            )
        }
        else {
            return <ComponentToRender key={ key } {...parsedProps } componentContext={ componentContext } editMode={ editMode }>{ children }</ComponentToRender>
        }
    }
};


const ViewContext = React.createContext();

export const ViewEditingContext = React.createContext();


export const ViewEditingProvider = ({ onCommand, activePath, hoverPath, children }) => {
    
    

    return (
        <ViewEditingContext.Provider value={{ sendCommand : onCommand, activePath, hoverPath }}>
            { children }
        </ViewEditingContext.Provider>
    );
}


const View = ({ component, components, context, componentProps, t, editMode, children, definitions }) => {


    return (
        <ErrorBoundary>
            { getComponent([], 0, component, components, context, componentProps, t, editMode, definitions, children) }
        </ErrorBoundary>
    )
    
}

View.propTypes = {
    component : PropTypes.object.isRequired,
    components : PropTypes.object.isRequired,
    context : PropTypes.object,
    editMode : PropTypes.bool.isRequired
}

View.defaultProps = {
    context : {},
    editMode : false
}

export default translatable(View);

