import React from 'react'

import { MatchCardList, MatchCard, EventDate, Icon } from '../../components'


const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}

const CyclingMatchCard = React.memo(({ item, onClick }) => {
    const properties = item.properties || {};

    return (
        <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } current={ item.current } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
            <MatchCard.SimpleEventContent round={ item.round }  title={ item.name } />

            <MatchCard.Footer>
                <div>{ properties.stageType }</div>
                <Kilometers value={ properties.stageDistance } />
                <div><EventDate date={ new Date(item.start) } /></div>
            </MatchCard.Footer>
            
            { item.status === 'updated' && <MatchCard.Icon><Icon type="flag" size="1-2x" /></MatchCard.Icon> }
        </MatchCard> 
    )
})


const CyclingScheduleCardView = ({ schedule, onSelectMatch }) => {
    return (
        <MatchCardList>
            { schedule.items.map(item => <CyclingMatchCard key={ item.id }  item={ item } onClick={ () => onSelectMatch(item) } />)  }
        </MatchCardList>
    )
}

export default CyclingScheduleCardView;