import React from 'react'

import styled, { css } from 'styled-components'

const Container = styled.div`
    ${ props => props.identity && css`background-color: var(--identity)` };
`

const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto;

    padding: 12px;
    
`



export const PageContentContainer = ({ identity, children }) => {
    return (
        <Container identity={ identity }>
            <Content>{ children }</Content>
        </Container>   
    )
}
