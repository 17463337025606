import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { pushFlyout, popFlyout, clearFlyouts } from '../redux/ui/actions';

class WithFlyout extends React.PureComponent {
    componentDidMount() {
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.props.fetch(nextProps.id);
        }
    }

    render() {
        const { flyouts, push, pop, clear, children } = this.props;
        return children({ flyouts, push, pop, clear});
    }
}


const mapStateToProps = (state) => {
    return {
        flyouts : state.ui.flyouts
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        push(type, props) {
            dispatch(pushFlyout(type, props));
        },
        pop() {
            dispatch(popFlyout());
        },
        clear() {
            dispatch(clearFlyouts())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(WithFlyout);

