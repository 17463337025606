export const FETCH_PLAYER_EVENTS_REQUEST            = 'FETCH_PLAYER_EVENTS_REQUEST';
export const FETCH_PLAYER_EVENTS_SUCCESS            = 'FETCH_PLAYER_EVENTS_SUCCESS';
export const FETCH_PLAYER_EVENTS_FAILURE            = 'FETCH_PLAYER_EVENTS_FAILURE';


export const FETCH_PLAYER_FANTASY_EVENTS_REQUEST    = 'FETCH_PLAYER_FANTASY_EVENTS_REQUEST';
export const FETCH_PLAYER_FANTASY_EVENTS_SUCCESS    = 'FETCH_PLAYER_FANTASY_EVENTS_SUCCESS';
export const FETCH_PLAYER_FANTASY_EVENTS_FAILURE    = 'FETCH_PLAYER_FANTASY_EVENTS_FAILURE';




export const FETCH_PLAYER_VALUES_REQUEST            = 'FETCH_PLAYER_VALUES_REQUEST';
export const FETCH_PLAYER_VALUES_SUCCESS            = 'FETCH_PLAYER_VALUES_SUCCESS';
export const FETCH_PLAYER_VALUES_FAILURE            = 'FETCH_PLAYER_VALUES_FAILURE';


export const FETCH_PLAYER_PROFILE_REQUEST           = 'FETCH_PLAYER_PROFILE_REQUEST';
export const FETCH_PLAYER_PROFILE_SUCCESS           = 'FETCH_PLAYER_PROFILE_SUCCESS';
export const FETCH_PLAYER_PROFILE_FAILURE           = 'FETCH_PLAYER_PROFILE_FAILURE';


export const FETCH_PLAYER_STATISTICS_REQUEST        = 'FETCH_PLAYER_STATISTICS_REQUEST';
export const FETCH_PLAYER_STATISTICS_SUCCESS        = 'FETCH_PLAYER_STATISTICS_SUCCESS';
export const FETCH_PLAYER_STATISTICS_FAILURE        = 'FETCH_PLAYER_STATISTICS_FAILURE';

export const FETCH_PLAYER_GAME_STATISTICS_REQUEST    = 'FETCH_PLAYER_GAME_STATISTICS_REQUEST';
export const FETCH_PLAYER_GAME_STATISTICS_SUCCESS    = 'FETCH_PLAYER_GAME_STATISTICS_SUCCESS';
export const FETCH_PLAYER_GAME_STATISTICS_FAILURE    = 'FETCH_PLAYER_GAME_STATISTICS_FAILURE';

export const FETCH_STATISTICS_BY_ROUND_REQUEST       = 'FETCH_STATISTICS_BY_ROUND_REQUEST';
export const FETCH_STATISTICS_BY_ROUND_SUCCESS       = 'FETCH_STATISTICS_BY_ROUND_SUCCESS'; 
export const FETCH_STATISTICS_BY_ROUND_FAILURE       = 'FETCH_STATISTICS_BY_ROUND_FAILURE';
