import React from 'react'



import { Media } from '../../components'

import SimpleSchedule from '../SimpleSchedule'
import GolfScheduleCardView from './GolfScheduleCardView';
import GolfScheduleTableView from './GolfScheduleTableView'
import GameNavigationService from '../GameNavigationService'

class GolfSchedule extends React.PureComponent
{
    render() {
        const { onSelectMatch } = this.props;

        return (
            <GameNavigationService>{ ({ showMatch }) => ( 
                <SimpleSchedule>
                    { 
                        ({ schedule }) => (
                            <div>
                                <Media query="(min-width: 1024px)">
                                    <GolfScheduleTableView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)  } />
                                </Media>

                                <Media query="(max-width: 1023px)">
                                    <GolfScheduleCardView schedule={ schedule } onSelectMatch={ (item) => showMatch(item.id)  } />
                                </Media>
                            </div>
                        )
                    }
                </SimpleSchedule>
            )}</GameNavigationService>
        )
    }
}

export default GolfSchedule;