import React from 'react'
import ReactDOM from 'react-dom'
import { ModalWindow } from './ModalWindow';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }
    
    componentDidMount() {
        modalRoot.appendChild(this.el);
    }
    
    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }
    
      /*this.props.children*/
    render() {
        return ReactDOM.createPortal(<ModalWindow {...this.props }/>, this.el);
    }
}


export default Modal;