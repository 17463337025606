import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'

import { fetchStatisticsByRoundIfNeeded } from '../redux/player/actions';
import { camelize, pluralize } from 'utils';

class WithPlayerValues extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetch(this.props.gameId, this.props.round);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.gameId !== this.props.gameId) || (nextProps.round !== this.props.round)) {
            this.props.fetch(nextProps.gameId, nextProps.round);
        }
    }

    render() {
        const { statistics, children } = this.props;
        if (!statistics)
            return null;
        else { 
            return children(statistics);
        }
    }
}

export const makeGetStatistics = () => createSelector([
        (state, props) => props.gameId,
        (state, props) => props.round,
        (state) => state.player.statisticsByRound,
        (state) => state.entities.playerValues,
        (state) => state.entities.eventTypes
        
    ],
    (gameId, round, statisticsByRound, playerValueEntities, eventTypeEntities) => {
        const stats = (statisticsByRound[gameId] && statisticsByRound[gameId][round]) || [];

        

        return (stats.items || []).reduce((agg, stat) => {
            const values = playerValueEntities[stat.values];

            const round = stat.events.round.reduce((agg, r) => {
                const eventType = eventTypeEntities[r.type];
                if (eventType) {
                    agg[camelize(eventType.name)] = r.amount
                    return agg;
                }
            }, {});


            const total = stat.events.total.reduce((agg, r) => {
                const eventType = eventTypeEntities[r.type];
                if (eventType) {
                    agg[camelize(eventType.name)] = r.amount
                    return agg;
                }
            }, {});

            agg[stat.player] = { 
                values,
                round,
                total
            };

            return agg;
        }, {})
    }
)

const makeMapStateToProps = () => {
    const getStatistics = makeGetStatistics();
    return (state, ownProps) => ({
        statistics : getStatistics(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(gameId, round) {
            
            dispatch(fetchStatisticsByRoundIfNeeded(gameId, round));
        }
    }
}

WithPlayerValues.propTypes = {
    gameId : PropTypes.number.isRequired,
    round : PropTypes.number.isRequired
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithPlayerValues);

