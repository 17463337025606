import React, { useContext } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types'

import ViewContainer from './ViewContainer'

const PageRouteContext = React.createContext();

export const PageRouteProvider = ({ parent, routes, children }) => {
    return (
        <PageRouteContext.Provider value={{ parent : parent || '', routes }}>
            { children }
        </PageRouteContext.Provider>
    )
}

PageRouteProvider.propTypes = {
    parent : PropTypes.string,
    routes : PropTypes.array.isRequired
}

export const TemplateContentRegion = withRouter(({ editMode, match }) => {
    if (editMode)
        return "(PageContent)"

    const context = useContext(PageRouteContext);
    const childRoutes = context.routes.filter(r => (r.parent === context.parent) || (!r.parent && !context.parent));

    return (
            <React.Fragment>
                <Switch>
                { 
                    childRoutes.map(r => {
                        return (
                            <Route key={ r.path } path={ r.path } exact={ r.exact }>
                                { 
                                    r.view &&
                                    <PageRouteProvider parent={ r.name } routes={ context.routes }>
                                        <ViewContainer name={ r.view } />
                                    </PageRouteProvider>
                                }
                            </Route>
                        )
                    })
                }
                </Switch>
            </React.Fragment>

    )
});


