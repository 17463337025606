import React, {  useContext } from 'react'
import PropTypes from 'prop-types'

import { View } from 'components'

import GameContext from './GameContext'
import ComponentContext from './ComponentContext'
import WithView from './WithView'

import { currentRound } from 'utils'

const ViewMappingContext = React.createContext();

const createContext = (game) => {
    if (game) {
        const cRound = currentRound(game.rounds);
        const currentRoundIndex = game.rounds.indexOf(cRound);

        return {
            'game_number_of_rounds' : game.rounds.length,
            'game_current_round_number' : currentRoundIndex + 1
        }
    }
    else {
        return {};
    }
}

export const ViewMappingProvider = ({ map, children }) => {
    return (
        <ViewMappingContext.Provider value={ map }>
            { children }
        </ViewMappingContext.Provider>
    )
        
}

const useViewMapping = () => {
    const context = useContext(ViewMappingContext);
    return context;
}


const ViewContainer = ({ name, componentProps, children }) => {

    
 


    const viewMapping = useViewMapping();
    let mappedName = viewMapping && viewMapping[name] || name;

    if (!mappedName)
        return null;




    return (
        <GameContext.Consumer>{ ({ game }) => (
            <ComponentContext.Consumer>{ (components) => {
                const context = createContext(game);

                return (
                    <WithView name={ mappedName }>{ ({ view }) => {
                        if (!view) {
                            return children || null;
                        }
                        else {
                            return <View component={ view } components={ components} componentProps={ componentProps } context={ context } />
                        }
                    }}</WithView>
                )
            }}</ComponentContext.Consumer>
        )}</GameContext.Consumer>
    )
}

ViewContainer.propTypes = {
    name : PropTypes.string
}

ViewContainer.defaultProps = {
}

export default ViewContainer;

