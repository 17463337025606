// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hPFZUnqFJKchPkxAsF4p{position:relative;box-sizing:border-box}.iM4pLLSPMEFXnGzTEmts{position:absolute;top:0;right:0;bottom:0;left:0;z-index:10}.v79YHLNtaawc3n7TOVl6{position:relative;z-index:20}`, "",{"version":3,"sources":["webpack://./src/components/Underlay.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CACA,qBAAA,CAGD,sBACC,iBAAA,CAEA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CAEA,UAAA,CAKD,sBACC,iBAAA,CACA,UAAA","sourcesContent":[".container {\r\n\tposition: relative;\r\n\tbox-sizing: border-box;\r\n}\r\n\r\n.underlay {\r\n\tposition: absolute;\r\n\t\r\n\ttop: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\tleft: 0;\r\n\r\n\tz-index: 10;\r\n}\r\n\r\n\r\n\r\n.content {\r\n\tposition: relative;\r\n\tz-index: 20;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `hPFZUnqFJKchPkxAsF4p`;
export var underlay = `iM4pLLSPMEFXnGzTEmts`;
export var content = `v79YHLNtaawc3n7TOVl6`;
export default ___CSS_LOADER_EXPORT___;
