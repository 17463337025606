import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { pick } from 'utils'

import * as Components from 'components'
import { DataContextProvider } from '../../../components';

export const DataGridColumnWidget = ({ }) => {

}






class Sort extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.parseExpression = this.parseExpression.bind(this);
        this.setSort = this.setSort.bind(this);
        this.sort = this.sort.bind(this);
        this.flip = this.flip.bind(this);

        this.state = {
            items : props.items,
            expressions : this.parseExpression(props.initialSort),
            sort : props.initialSort
        }
    }

    componentDidMount() {
        this.sort(this.props, this.state.expressions);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.items !== this.props.items)) {
            this.sort(nextProps, this.state.expressions);
        }
    }

    parseExpression(sort) {
        if (!sort || sort.length === 0)
            return [];

        return sort.split(',').map(e => {
            const parts = e.split(' ');
            return {
                property : parts[0],
                direction : parts[1] || 'asc',
                default : parts[2]
            }
        });
    }


    flip() {

        this.setState({
            sort : Object.keys(this.state.sort).reduce((agg, key) => {
                const exp = this.state.sort[key];
                agg[key] = {
                    ...exp,
                    direction : exp.direction === 'asc' ? 'desc' : 'asc',
                }

                return agg;
            }, {})
        })
    }

    setSort(sort) {
        let expressions = this.state.expression;

        if (this.state.sort == sort) {
            expressions = this.state.expressions.map(e => {
                return {
                    ...e,
                    direction : e.direction === 'asc' ? 'desc' : 'asc',
                }
            })
        }
        else {
            expressions = this.parseExpression(sort);
            
        }

        this.setState({ sort, expressions : expressions });
        this.sort(this.props, expressions);
    }


    sort(props, expressions) {
        if (expressions.length == 0) {
            this.setState({ items : props.items });
            return;
        }

        const sorted = props.items.slice().sort((a, b) => {
            for(var i = 0; i < expressions.length; i++) {
                const expr = expressions[i];
                const av = pick(expr.property, a) || expr.default;
                const bv = pick(expr.property, b) || expr.default;
    
                if (av > bv)
                    return expr.direction === 'asc' ? 1 : -1;
                else if (bv > av)
                    return expr.direction === 'asc' ? -1 : 1;
            }
    
            return 0;
        });

        this.setState({
            items : sorted,

        })
    }

    render() {
        return this.props.children({ sortedItems : this.state.items, setSort : this.setSort });
    }
}




class Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.page = this.page.bind(this);
    }

    componentDidMount() {
        this.page(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.items !== this.props.items) || (nextProps.page !== this.props.page) || (nextProps.pageSize !== this.props.pageSize)) {
            this.page(nextProps);
        }
    }

    page(props) {
        if (props.pageSize === 0) {
            this.setState({
                items : props.items
            })
        }
        else {
            
            const start = (props.page - 1) * props.pageSize;
            const end = (props.page) * props.pageSize;
            
            this.setState({
                items : props.items.slice(start, end)
            })
        }
    }

    render() {
        return this.props.children({ pagedItems : this.state.items });
    }
}




export const DataGridWidget = ({ defaultActiveColumnIndex, datasource, filters, disabledProperty, pageSize, children }) => {
    const items = datasource || [];


    const [page, setPage] = useState(1);
    const [activeColumnIndex, setActiveColumnIndex] = useState(defaultActiveColumnIndex);

    // If items change reset paging.
    useEffect(() => { 
        
        setPage(1); 
    
    }, [activeColumnIndex, filters])


    const columns = React.Children.map(children, (child) => {
        let innerChild = React.Children.count(child.props.children) > 0 ? React.Children.only(child.props.children) : null;
        
        innerChild = innerChild !== null && innerChild.type.name == 'RuntimeComponentWrapper' ? React.Children.only(innerChild.props.children) : innerChild;

        return <Components.DataGridColumn { ...child.props }>
                    { innerChild && React.cloneElement(innerChild) }
               </Components.DataGridColumn>
    });


    const initialSort = defaultActiveColumnIndex >= 0 ? columns[defaultActiveColumnIndex].props.sort : null;
    
    return (
        <Sort items={ items } initialSort={ initialSort }>{ ({ sortedItems, setSort }) => (
            <Page items={ sortedItems } pageSize={ pageSize } page={ page }>{ ({ pagedItems }) => (
                <React.Fragment>
                    <Components.DataGrid items={ pagedItems } 
                        disabledProperty={ disabledProperty }
                        activeColumnIndex={ activeColumnIndex } 
                        onSort={ (sort, index) => {
                            setPage(1);
                            setActiveColumnIndex(index);
                            setSort(sort);
                        }}>
                        { columns }
                    </Components.DataGrid>

                    { pageSize > 0 && <Components.Pagination count={ (items || []).length  } page={ page } pageSize={ pageSize } onPageChanged= { (page) => { setPage(page)} } /> }
                </React.Fragment>
            )}</Page>
        )}</Sort>
    )
}

DataGridWidget.propTypes = {
    disabledProperty : PropTypes.string.isRequired
}

DataGridWidget.defaultProps = {
    disabledProperty : 'disabled',
    defaultSortColumn: -1,
    pageSize : 25
}