import React from 'react'

import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import ButtonBase from './ButtonBase'
import * as styles from './Button.scss'

const RouteButton = ({ to, history, children, ...otherProps }) => {

  

    return <ButtonBase {...otherProps}>
            {
              (className) => 
                <button className={ className } onClick={ () => history.push(to) }>
                  <span className={ styles.content }>
                    { children }
                  </span>
                </button>  
            }
           </ButtonBase>
}

RouteButton.propTypes = {
  to: PropTypes.string
}

RouteButton.defaultProps = {
  
}


export default withRouter(RouteButton);






