import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Label, Textbox, AuthenticationHeader, Form, FormField, PasswordInput, Checkbox, Fieldset } from 'components'
import { BusyButton } from '../../molecules'

import { signupSubmit } from '../../redux/auth/actions';

import { useTranslator } from '../../hooks/useTranslator'
import { useAppConfig } from '../AppConfigProvider';

import { OptinList } from './OptinList'

export const SignupUsername= () => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [_optins, _setOptins] = useState([]);
    const [password, setPassword] = useState('');
    const appConfig = useAppConfig();
    const t = useTranslator();

    const { email } = useSelector(state => state.auth.data );
    const { code, isBusy, problems } = useSelector(state => state.auth.signup);

    const inputRef = useRef()

    // Focus on username on start
    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef.current])

    const onSubmitInternal = () => {
        dispatch(signupSubmit(email, code, username, password, _optins ));
    }


    const optins = appConfig && appConfig.auth && appConfig.auth.optins || {};
    const enabled = Object.values(optins).length == _optins.length && username.length > 0 && password.length > 0;

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.createAccountHeader') }</AuthenticationHeader>
            
            <Form problems={ problems } onSubmit={ onSubmitInternal }>
                <Fieldset>
                    <FormField name="username">
                        <Label>{ t('authentication.usernameLabel') }</Label>
                        <Textbox name="username" ref={ inputRef } value={ username } tabindex={ 0 } autocomplete="off" onChange={ (e) => setUsername(e.target.value)} />
                    </FormField>

                    <FormField name="password">
                        <Label>{ t('authentication.newPasswordLabel') }</Label>
                        <PasswordInput name="password" autoComplete="new-password" value={ password } tabindex={ 1 } autocomplete="off" onChange={ (e) => setPassword(e.target.value)} />
                    </FormField>


                    <OptinList value={ _optins } onChange={ value => _setOptins(value) } />
                </Fieldset>
                
                <BusyButton type="submit" busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary disabled={ !enabled }  onClick={ onSubmitInternal }>{ t('authentication.createAccountButtonCaption') }</BusyButton>
            </Form>
        </React.Fragment>
    )
}
