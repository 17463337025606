import React from 'react';
import PropTypes from 'prop-types'

import Icon from './Icon'



const getIcon = (injured, suspensionDays) => {
    if (suspensionDays && suspensionDays > 0 && injured) {
        //return `injurySuspension${ suspensionDays }`;
        return "injury";
    }
    else if (suspensionDays && suspensionDays > 0) {
        //return `suspension${ suspensionDays }`;
        return "injury";
    }
    else if (injured) {
        return "injury";
    }
    else    
        return null;
}

const InjurySuspensionIcon = ({ injured, suspensionDays, size }) => {
    const icon = getIcon(injured, suspensionDays);
    
    if (icon)
        return <Icon type={ icon } shadow={ true } size={ size } />
    else
        return null;
}

InjurySuspensionIcon.propTypes = {
}

export default InjurySuspensionIcon;