import * as types from './types'
import * as userTypes from '../user/types'

const defaultState = {
    notifications : {
        page: 1,
        pageSize : 10,
        isFetching : false,
        didInvalidate : false,
        count : 0,
        items : null
    },
    count : 0
};



const notificationReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.INVALIDATE_NOTIFICATIONS:
            return  { 
                notifications : {
                    ...state.notifications,
                    didInvalidate : true
                }
            }
        case types.FETCH_NOTIFICATIONS_REQUEST:
            return  { 
                ...state,
                notifications : {
                    ...state.notifications,
                    isFetching :  true,
                    didInvalidate : false,
                    page : action.page,
                    pageSize : action.pageSize,
                    count : 0
                }
            };
        case types.FETCH_NOTIFICATIONS_SUCCESS:
                return  { 
                    ...state,
                    notifications : {
                        ...state.notifications,
                        isFetching :  false,
                        didInvalidate : false,
                        count : action.count,
                        items : [
                            ...(state.notifications.items || []),
                            ...action.payload.result
                        ]
                    }
                };
        case types.FETCH_NOTIFICATIONS_FAIL:
            return  { 
                ...state,
                notifications : {
                    ...state.notifications,
                    isFetching : false,
                    didInvalidate : false
                }
            };                
        case types.WS_NOTIFICATION:
            return  { 
                ...state,
                notifications : {
                    ...state.notifications,
                    count : state.notifications.count + 1,
                    items : [action.payload.result, ...(state.notifications.items || [])]
                },
                count : state.count + 1,
            };
        case types.ACKNOWLEDGE_NOTIFICATIONS_SUCCESS:
                    return { 
                        ...state,
                        count : 0
                    };
        case userTypes.CURRENT_USER_RECEIVE:
                return { 
                    ...state,
                    count : action.user.notifications
                };
        default:
            return state
    }
}

export default notificationReducer;
