import React from 'react'
import { Dialog, DialogHeader, DialogContent, DialogFooter } from './Dialog'
import { BodyCopy } from './Typography'
import { XButton } from './XButton'

const MessageBox = ({ open, title, message, okCaption, onClose }) => {
    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogHeader px="m">{ title }</DialogHeader>
            <DialogContent p="m">
                <BodyCopy>{ message }</BodyCopy>
            </DialogContent>
            <DialogFooter>
                <XButton size="s" variant="primary" textTransform="uppercase" onClick={ onClose }>Ok</XButton>
            </DialogFooter>
        </Dialog>
    )
}

export default MessageBox;