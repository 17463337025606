import React from 'react'

import { FormGroup } from 'components'

export const GroupWidget = ({ children }) => {
    return (
        <FormGroup>
            { children }
        </FormGroup>
           
    )
}


