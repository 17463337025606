import React from 'react'
import PropTypes from 'prop-types'

class ResizeObserver extends React.PureComponent{
    constructor(props) {
        super(props);

        this.size = { 
            x : 0, 
            y : 0 
        };
        this.timer = null;
        this.resizeObserver = null;
        this.checkSize = this.checkSize.bind(this);
    }

    componentDidMount() {
        this.size = {
            x : this.el.clientWidth,
            y : this.el.clientHeight
        }
        this.installWatcher();
    }

    checkSize() {
        const size = {
            x : this.el.clientWidth,
            y : this.el.clientHeight
        };
        if (this.sizeChanged(size)) {
            this.size = size;
            this.props.onDimensionsChanged();
        }
    }

    componentWillUnmount() {
        this.uninstallWatcher();
    }

    installWatcher() {
       if (window.ResizeObserver) {
            this.resizeObserver = new window.ResizeObserver(entries => {
                this.checkSize();
            })
            this.resizeObserver.observe(this.el);
        }
        else {
            this.timer = setInterval(this.checkSize, 100);
        }
    }

    uninstallWatcher() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(this.el);
        }

        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
     }

    sizeChanged(size) {
        return (size.x != this.size.x || size.y != this.size.y);
    }

    render() {
        
        return (
            <div ref={ (el) => this.el = el }>{ this.props.children }</div>
        )
    }  
}

ResizeObserver.propTypes = {
    onDimensionsChanged : PropTypes.func.isRequired
}

ResizeObserver.defaultProps = {
}


export default ResizeObserver;