import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Accordion.scss'


const AccordionItem = ({ heading, open, onClick, children }) => {
    const classes = classNames(
        styles.item,
        {
            [styles.active] : open
        }
    );

    return  (
        <div className={ classes }>
            <div className={ styles.header } onClick={ onClick }>
                { heading }
            </div>
            <div className={ styles.content }>
                { children }
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    heading : PropTypes.node.isRequired,
    open : PropTypes.bool.isRequired,
    onClick : PropTypes.func.isRequired,
    children : PropTypes.any
}

AccordionItem.defaultProps = {
    open : false
}


const Accordion = ({ initialSelectedIndex, children }) => {
    const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);

    return (
        
            <div className={ styles.container }>
                { React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { 
                        open : index === selectedIndex, 
                        onClick : () => { 
                            
                            setSelectedIndex(index == selectedIndex ? -1 : index);
                        } 
                    })
                })}
            </div>
        
    )
}

Accordion.propTypes = {
    children: function (props, propName, componentName) {
        const prop = props[propName]
    
        let error = null
        React.Children.forEach(prop, function (child) {
          if (child.type !== AccordionItem) {
            error = new Error('`' + componentName + '` children should be of type `AccordionItem`.');
          }
        });
        return error
    },
    initialSelectedIndex : PropTypes.number.isRequired
}

Accordion.defaultProps = {
    initialSelectedIndex : -1
}

export { Accordion, AccordionItem }

