import React from 'react';

import PlayerContext from '../../PlayerContext'
import WithPlayer from '../../WithPlayer'

import PersonSuspensionStatus from '../../PersonSuspensionStatus'
import { AlertBox } from '../../../components';

const SuspensionStatusWidget = ({ editMode, children, ...otherProps }) => {
    if (editMode) {
        return <AlertBox type="error">(Suspension Status)</AlertBox>
    }

    

    return <PlayerContext.Consumer>{ ({ id }) => (
                <WithPlayer id={ id }>{ (player) => (
                    <PersonSuspensionStatus personId={ player.person.id } />
                )}</WithPlayer>
            )}</PlayerContext.Consumer>

}

export default SuspensionStatusWidget;