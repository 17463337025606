import axios from 'axios';

export default 
{
    offsetMilliseconds : 0,

    sync() {
        axios.get('time').then(response => {
            var serverTime = new Date(response.data);
            var localTime = new Date();

            this.offsetMilliseconds = serverTime.getTime() - localTime.getTime();
        
            console.log(`Server offset is ${this.offsetMilliseconds} ms`);
        }, () => {
            console.log('Unable to sync time to server.')
        });
    },

    now() {
        return new Date(new Date().getTime() + this.offsetMilliseconds)
    }
}
