import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Icon from './Icon'
import { HorizontalMenu, MenuItem } from './Menu'

import * as styles from './NavBar.scss'

import styled, { css } from 'styled-components'

const StyledContainer = styled.div`
    
    background-color: ${ props => props.theme.colors.navbarBackground };
    
    position: relative;
    z-index: 1000;

    ${
        props => props.theme.colors.navbarBackground !== 'transparent' && css`
            
            color: ${ props => props.theme.colors.navbarForeground };
            a { 
                color: ${ props => props.theme.colors.navbarForeground };  

                &:hover { color: ${ props => props.theme.colors.navbarForeground }; }
            }
        `
    }


    
`


const NavItem = ({ href, children }) => {
    

    return href.indexOf('://') >= 0 ?
                <a href={ href }>{ children }</a> :
                <Link to={ href }>{ children }</Link>;
}

NavItem.propTypes = {
    href : PropTypes.string.isRequired,
    external : PropTypes.bool
}


class NavBar extends React.PureComponent{


    render() {
        const { brand, aux, themeName } = this.props;

  

        return  <StyledContainer>
                    <div className={ styles.content }>
                    { 
                        brand && 
                        <div className={ styles.brand }>
                            { brand }
                        </div>
                    }
                    <div className={ styles.menu }>
                        <HorizontalMenu themeName={ themeName}>
                            {
                                React.Children.map(this.props.children, child => {
                                    return (
                                        <MenuItem as="a" href={ child.props.href } themeName={themeName}>
                                            { child.props.children }
                                            { child.props.external && <Icon type="externalLink"  opacity={ 0.5 } /> }
                                        </MenuItem>        
                                    )
                                })
                                
                            }
                        </HorizontalMenu>

                    </div>
                    {
                        aux &&
                        <div className={ styles.aux }>
                            { aux }
                        </div>
                    }
                    </div>
               </StyledContainer>
    }  
}

NavBar.propTypes = {
    brand : PropTypes.node,
    aux : PropTypes.node
}

NavBar.defaultProps = {
    
}


export { NavItem, NavBar }

