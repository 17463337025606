import { useState, useEffect } from 'react';

import useJson from './useJson'
import dayjs from 'dayjs'

const createMap = (feed) => {
    if (!feed || !feed.item)
        return {};

    return feed.item.bets.reduce((agg, b) => {
        const day = dayjs(b.betEvent.startTime).format('YYYYMMDD');

        const data = b.odds.reduce((ao, o) => { ao[o.type] = o; return ao; }, { });

        if (data['1'] && data['x'] && data['2']) {
            const key = (day + '+' + data['1'].name + '+' + data['2'].name).toLowerCase();
            agg[key] = data;
        }

        return agg;
    }, {});
}


const useOdds = (url) => {
    const feed = useJson(url);
    const [map, setMap] = useState({ });

    useEffect(() => { setMap(createMap(feed)); }, [feed]);

    return {
        find : (start, home, away) => { 
            
            const day = dayjs(start).format('YYYYMMDD');
            const key = (day + '+' + home + '+' + away).toLowerCase();

            return map[key]; 
        }
    };
};

export default useOdds;