import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { percent, exp } from 'utils';
import DataContext from '../../../components/DataContext'

const Percent = ({ defaultValue, property }) => {
    const context = useContext(DataContext)


    

    const value = exp(property, context);

    if (typeof value == 'number') {
        return percent(value);
    }
    else {
        return defaultValue;
    }
}

Percent.propTypes = {
    property : PropTypes.string
}

Percent.defaultProps = {
    defaultValue : '-'
}

export default Percent;