// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--progress-color: #ff0}.ZhfeAzy0vTE7RLGprIuA{height:7px;position:relative}.ZhfeAzy0vTE7RLGprIuA:before{content:"";position:absolute;background:var(--action);top:3px;height:1px;width:100%;opacity:.1}.GFEUHaZMq8FHzkdZKEmO{background:rgba(var(--action), 0.1);position:absolute;top:0px;bottom:0;width:1px}.GFEUHaZMq8FHzkdZKEmO.zwaxOKU3GKZfrjf03EZ3{background:var(--action)}.n8otYM0vWM82pyfiYowp{background:var(--action);position:relative;top:2px;height:3px;width:20%}`, "",{"version":3,"sources":["webpack://./src/components/Progress.scss"],"names":[],"mappings":"AACA,MACI,sBAAA,CAGJ,sBACI,UAAA,CACA,iBAAA,CAEA,6BACI,UAAA,CACA,iBAAA,CACA,wBAAA,CACA,OAAA,CACA,UAAA,CACA,UAAA,CACA,UAAA,CAIR,sBACI,mCAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CAEA,2CAAA,wBAAA,CAGJ,sBACI,wBAAA,CACA,iBAAA,CACA,OAAA,CACA,UAAA,CACA,SAAA","sourcesContent":["\r\n:root {\r\n    --progress-color: #ff0;\r\n}\r\n\r\n.progress {\r\n    height: 7px;\r\n    position: relative;\r\n\r\n    &:before {\r\n        content: \"\";\r\n        position: absolute;\r\n        background: var(--action);    \r\n        top: 3px;\r\n        height: 1px;\r\n        width: 100%;\r\n        opacity: 0.1;\r\n    }\r\n}\r\n\r\n.stop {\r\n    background: rgba(var(--action), 0.1);\r\n    position: absolute;\r\n    top: 0px;\r\n    bottom: 0;\r\n    width: 1px;\r\n\r\n    &.active { background: var(--action); }\r\n}\r\n\r\n.value {\r\n    background: var(--action);\r\n    position: relative;\r\n    top: 2px;\r\n    height: 3px;\r\n    width: 20%;\r\n}"],"sourceRoot":""}]);
// Exports
export var progress = `ZhfeAzy0vTE7RLGprIuA`;
export var stop = `GFEUHaZMq8FHzkdZKEmO`;
export var active = `zwaxOKU3GKZfrjf03EZ3`;
export var value = `n8otYM0vWM82pyfiYowp`;
export default ___CSS_LOADER_EXPORT___;
