import PropTypes from 'prop-types'

import translatable from '../../hocs/translatable';

const SoccerPeriodStatus = ({ t, period, minutes, seconds, paused }) => {

    if (paused)
        return t('soccer.paused');

    const periodName = t('soccer.periodAbbreviation' + (period - 1));

    return `${ periodName } ${ (minutes + '').padStart(2, '0') }:${ (seconds + '').padStart(2, '0') }`;
};

SoccerPeriodStatus.propTypes = {
    period : PropTypes.number.isRequired,
    minutes : PropTypes.number.isRequired,
    seconds : PropTypes.number.isRequired,
    paused : PropTypes.bool
}

export default translatable(SoccerPeriodStatus);