
import React from 'react'
import { connect } from 'react-redux';

import { pushFlyout, s } from '../redux/ui/actions';

class FlyoutProxy extends React.PureComponent {
    constructor(props) {
        super(props);
        this.openFlyout = this.openFlyout.bind(this);
        window.openFlyout = this.openFlyout;
    }

    openFlyout(type, props) {
        this.props.push(type, props);
    }


    render() {
        return null;
    }
}


function mapDispatchToProps(dispatch, ownProps) {
    return {
        push(type, props) {
            dispatch(pushFlyout(type, props));
        }
    }
}

const mapStateToProps = () => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(FlyoutProxy);



