import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types'
import { number, exp } from 'utils';
import DataContext from '../../../components/DataContext'
import DataContextProvider from '../../../components/DataContextProvider'
import * as Components from 'components';

const Transform = ({ expression, transform, children }) => {
    const context = useContext(DataContext) || {}
    const func = useMemo(() => new Function(['context'], transform), [expression]);

    const safeFunc = (context) => {
        try {
            return func(context)
        }
        catch(e) {
            console.error('An exception occurred evaluating transformation', e)
        }
    }

    return <DataContextProvider value={ safeFunc(context) }> {children}</DataContextProvider>
}

Transform.propTypes = {
    expression : PropTypes.string.isRequired,
    transform : PropTypes.string.isRequired
}

Transform.defaultProps = {
    expression : ''
}

export default Transform;