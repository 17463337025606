import React from 'react'
import PropTypes from 'prop-types'
import GameContext from '../containers/GameContext'

const PLAYER_AVATAR_MAP = {
  tiny : { width : 16, height : 16 },
  small :  { width : 32, height : 32 },
  medium :  { width : 64, height : 64 },
  large :  { width : 128, height : 128 },
  huge : { width : 256, height : 256 }
};

const PlayerAvatar = ({ player, size = 'small', flipX, flipY }) => {
    return (
      <GameContext.Consumer>{ ({ game, gameConfiguration }) => {
        const scaleX = flipX ? '-1' : '1';
        const scaleY = flipY ? '-1' : '1';
    
        const style = {}
    
        if (flipX || flipY) {
            style.transform = `scale3d(${ scaleX }, ${ scaleY }, 1)`;
        }
    
        let item = PLAYER_AVATAR_MAP[size];


        if (gameConfiguration && gameConfiguration.avatarUrl) {
          
          const avatarUrl = gameConfiguration.avatarUrl;

          const url = avatarUrl.replace(/\{\{(.*?)\}\}/g, (match, token) => player[token])
          return <img style={ style }  src={ url } width={ item.width } height={item.height} />  
        }
        else {
          const baseUrl = gameConfiguration && gameConfiguration.playerAvatarBaseUrl || '//static-swush-com.s3.amazonaws.com/players/';
          const url =  `${ baseUrl }${ game.id }/${ player.id }_${ item.width }_${ item.height }.png`;
          return <img style={ style }  src={ url } width={ item.width } height={item.height} />  

        }
      }}</GameContext.Consumer>
    )
}

PlayerAvatar.propTypes = {
  player: PropTypes.object.isRequired,
  size: PropTypes.string,
  flipX : PropTypes.bool,
  flipY : PropTypes.bool
}

PlayerAvatar.defaultProps = {
  flipX : false,
  flipY : false
}


export default PlayerAvatar;
