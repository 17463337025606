import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { createSelector } from 'reselect'

import { fetchWatchlistIfNeeded, addToWatchlist, removeFromWatchlist } from '../redux/watchlist/actions'

import GameContext from './GameContext'

class WithWatchlist extends React.PureComponent {

    constructor(props) {
        super(props);

        this.addToWatchlist = this.addToWatchlist.bind(this);
        this.removeFromWatchlist = this.removeFromWatchlist.bind(this);
    }

    addToWatchlist(playerId) {
        this.props.add(this.props.gameId, playerId);
    }

    removeFromWatchlist(playerId) {
        this.props.remove(this.props.gameId, playerId);
    }


    componentDidMount() {
        this.props.fetchIfNeeded(this.props.gameId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.gameId !== this.props.gameId) {
            
            this.props.fetchIfNeeded(nextProps.gameId);
        }
    }

    render() {
        const { watchlist, children } = this.props;
        
        return children({ watchlist, addToWatchlist : this.addToWatchlist, removeFromWatchlist : this.removeFromWatchlist });
    }
}

const getWatchlist = createSelector([
        (state) => (state.watchlist.byGame[state.games.selected] || {}).items
    ],
    (watchlist) => {
        return (watchlist || []).map(w => w.player);
    })


const mapStateToProps = state => ({
        watchlist : getWatchlist(state)
})

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetchIfNeeded(gameId) {
            dispatch(fetchWatchlistIfNeeded(gameId));
        },
        add(gameId, playerId) {
            dispatch(addToWatchlist(gameId, playerId));
        },
        remove(gameId, playerId) {
            dispatch(removeFromWatchlist(gameId, playerId));
        }
    }
}

WithWatchlist.propTypes = {
    gameId : PropTypes.number.isRequired
}


const ConnectedWithWatchlist = connect(mapStateToProps, mapDispatchToProps)(WithWatchlist);

const WithWatchlistWrapper = ({ match, children }) => (
    <GameContext.Consumer>{ ({ game }) => {
        
        return game && <ConnectedWithWatchlist gameId={ game.id }>{ children }</ConnectedWithWatchlist>
    }}</GameContext.Consumer>
)


export default WithWatchlistWrapper;

