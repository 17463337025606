// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s1PXCzWTRiAXH2z603og{margin:calc(var(--vertical-rythm)/2) 0;padding:0 var(--padding)}.RgIBsRUyrI32_S_lZYML{font-size:11px;color:#8a8a8a;flex:1;line-height:24px}.RgIBsRUyrI32_S_lZYML a,.RgIBsRUyrI32_S_lZYML a:hover,.RgIBsRUyrI32_S_lZYML a:visited,.RgIBsRUyrI32_S_lZYML a:active{color:#8a8a8a;text-decoration:underline}.Rs6n27cinwkNtA2bjDxu{display:flex;flex-direction:row;justify-content:space-between;margin:calc(var(--vertical-rythm)/2) 0}@media screen and (min-width: 1024px){.s1PXCzWTRiAXH2z603og{padding:0}.NdzcIgPOvZUAzOKlfPYK{display:flex;flex-direction:row}.RgIBsRUyrI32_S_lZYML{margin-top:0}.Rs6n27cinwkNtA2bjDxu>*{margin:0 calc(var(--vertical-rythm)/2)}}`, "",{"version":3,"sources":["webpack://./src/components/SiteFooter.scss"],"names":[],"mappings":"AAAA,sBACI,sCAAA,CACA,wBAAA,CAGJ,sBACI,cAAA,CACA,aAAA,CACA,MAAA,CACA,gBAAA,CAEA,qHACI,aAAA,CACA,yBAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,sCAAA,CAMJ,sCAEI,sBACI,SAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CAIA,wBACI,sCAAA,CAAA","sourcesContent":[".siteFooter {\r\n    margin: calc(var(--vertical-rythm) / 2) 0;\r\n    padding: 0 var(--padding);\r\n}\r\n\r\n.copyright {\r\n    font-size: 11px;\r\n    color: #8A8A8A;   \r\n    flex: 1;\r\n    line-height: 24px;\r\n\r\n    a, a:hover, a:visited, a:active {\r\n        color: #8A8A8A;\r\n        text-decoration: underline;\r\n    }\r\n}\r\n\r\n.flair {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n\r\n    margin: calc(var(--vertical-rythm) / 2) 0;\r\n\r\n   \r\n}\r\n\r\n\r\n@media screen and (min-width : 1024px) {\r\n\r\n    .siteFooter {\r\n        padding: 0;\r\n    }\r\n\r\n    .container {\r\n        display: flex;\r\n        flex-direction: row;\r\n    }\r\n\r\n    .copyright {\r\n        margin-top: 0;\r\n    }\r\n\r\n    .flair {\r\n        > * {\r\n            margin: 0 calc(var(--vertical-rythm) / 2);\r\n        }\r\n    }\r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var siteFooter = `s1PXCzWTRiAXH2z603og`;
export var copyright = `RgIBsRUyrI32_S_lZYML`;
export var flair = `Rs6n27cinwkNtA2bjDxu`;
export var container = `NdzcIgPOvZUAzOKlfPYK`;
export default ___CSS_LOADER_EXPORT___;
