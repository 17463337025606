import React from 'react'

const Attachment = ({ attachment, children, top, right }) => {
    if (!attachment)
        return children;

    return (
        <div style={{ position: 'relative' }}>
            { children }
            <div style={{ position: 'absolute', top : top + 'px', right: right + 'px'}}>
                { attachment }
            </div>
        </div>
    )
}

Attachment.defaultProps = {
    top : -3, 
    right : -3
}

export default Attachment;