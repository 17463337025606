import React, { useContext }  from 'react'

import DataContext from './DataContext'

export const Datasource = ({ value, children }) => {
    const dataContext = useContext(DataContext)

    return (
        <DataContext.Provider value={{ ...dataContext, ...value }}>
            { children }
        </DataContext.Provider>
    )
}

Datasource.propTypes = {
}

