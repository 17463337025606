import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

import { start } from '../redux/auth/actions'
import { useAppConfig } from './AppConfigProvider'

const AuthPage = () => {
    const dispatch = useDispatch();
    const cfg = useAppConfig();

    const { isAuthenticated } = useSelector(state => state.auth);

    
    
    useEffect(() => {
        if (isAuthenticated) {
            
            if (!window.URLSearchParams) {
                location.href = '/';    
            }
            else {
                var urlParams = new URLSearchParams(window.location.search);

                if (!urlParams.has('returnUrl')) {
                    location.href = '/';    
                }
                else {
                    var rUrl = urlParams.get('returnUrl')
                    if (rUrl.indexOf('/') == 0) {
                        location.href = rUrl;
                    }
                    else {
                        location.href = '/';    
                    }
                }
            }
        }
    }, [isAuthenticated])


    useEffect(() => {
        if (!cfg)
            return;

        if (!isAuthenticated) {
            if (cfg.auth && cfg.auth.type == 'redirect')
                location.href = (typeof cfg.auth.redirect == 'function') ? cfg.auth.redirect() : cfg.auth.redirect;    
            else {
                dispatch(start());
            }
        }
    }, [cfg, isAuthenticated])

    return null;
}


export default AuthPage;
