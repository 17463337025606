import { createSelector } from 'reselect'

export const makeGetTournamentSuspensions = () => (createSelector([
    (_, props) => props.id,
    (state) => state.suspension.byTournament,
    (state) => state.entities.suspensions,
    (state) => state.entities.suspensionTypes,
    (state) => state.entities.matches
], 
(id, byTournament, suspensionEntities, suspensionTypeEntities) => { 
    const { isFetching, items } = byTournament[id] || { isFetching : false, items : [] }

    
        

    return {
        isFetching,
        map : (items || []).reduce((acc, q) => {
            const suspension = suspensionEntities[q];
            const type = suspensionTypeEntities[suspension.type];

            if (acc[suspension.person])
            {
                acc[suspension.person].push({
                    ...suspension,
                    type
                })
            }
            else {
                acc[suspension.person] = [{
                    ...suspension,
                    type
                }]
            }

            return acc;
        }, {})
    }
}
));