import { createSelector } from 'reselect'

export const makeGetGame = () => (
    createSelector([
        (state, props) => props.id,
        (state) => state.entities.games
    ], 
    (gameId, gameEntities) => { 
        return gameId && gameEntities[gameId]; 
    }
));






export const getSelectedGame = createSelector([
        (state) => state.games.selected,
        (state) => state.entities.games
    ], 
    (gameId, gameEntities) => { 
        return gameId && gameEntities[gameId]; 
    }
);

    