import React from 'react';
import PropTypes from 'prop-types'

import MatchContext from '../../MatchContext'

import { EventDate } from 'components'

const Start = () => (
    <MatchContext.Consumer>{ (match) => (
        <EventDate date={ match.start} />
    )}</MatchContext.Consumer>

)


export default Start;