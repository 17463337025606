import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'


const StyledCommentCountIcon = styled.div`
    position: relative;

    width : 20px;
    height : 16px;
    line-height : 16px;
    
    font-size: 9px;
    
    border-radius: 3px;
        
    font-weight: bold;
    text-align: center;

    pointer-events: none;

    background-color: ${ props => props.theme.colors.utilityYellow };
    color: ${ props => props.theme.colors.utilityBlack };

    &:after {
        content: "";
        position: absolute;
        left: 4px;
        top: 15px;
        border-width: 2px 4px 2px 4px;
        border-style: solid;
        border-color: ${ props => props.theme.colors.utilityYellow } transparent transparent ${ props => props.theme.colors.utilityYellow };
    }
`;



const StyledWrapper = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CommentCountIcon = ({ count, ...props }) => {
    return (
        <StyledWrapper>
            <StyledCommentCountIcon>{ count }</StyledCommentCountIcon>
        </StyledWrapper>
    )
}

CommentCountIcon.propTypes = {
    count : PropTypes.number
}


export default CommentCountIcon;