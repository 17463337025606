import React from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import Box from './Box'
import { Text } from './Typography'

const UserIdentity = ({ user }) => {
    return (
        <Box display="flex" alignItems="center">
            <Box mr="m">
                <Avatar size="small" src={ user && user.imageUrl } name={ user && user.username} />
            </Box>
            <Box>
                <Text fontWeight="bold" display="flex" alignItems="center" color={ user && user.isDeleted && 'siteForegroundMeta' }>
                    { user.isDeleted ? <em>(Deleted)</em> : user.username }
                </Text>
            </Box>
        </Box>
    )
}

UserIdentity.propTypes = {
    user: PropTypes.object.isRequired,
    
}

export default UserIdentity;






