import React, { useRef, useState, useEffect } from 'react'
import AdProviderContext from './AdProviderContext'

const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';



export default function AdProvider({ children }) {
    const slots = useRef([]);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
		const script = document.createElement('script');
		script.async = true;
		script.src = GPT_STANDARD_URL;
		
		document.head.appendChild(script);
        setIsReady(true);

		return () => {
			document.head.removeChild(script);
		}
	}, [])

    const register = (name, slot) => {
        slots.current[name] = slot;
    }

    const unregister = (name, slot) => {
        delete slots.current[name];
    }



    return (
        <AdProviderContext.Provider value={{ register, unregister, slots: slots.current, isReady }}>
            { children }
        </AdProviderContext.Provider>   
    )
}