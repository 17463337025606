import React from 'react'

import { getConfigurationSection } from 'utils'

import WithFlyout from './WithFlyout'
import GameContext from './GameContext'


const absoluteUrl = (url) => {
    let actualUrl = url || '';
    return (actualUrl.indexOf('/') == 0) ? window.swush.externalBaseUrl + actualUrl : window.swush.externalBaseUrl + '/' + actualUrl;
    

}

const GameNavigationService = ({ children }) => {
    return (
        <GameContext.Consumer>{ ({ game, gameConfiguration }) => {
            if (!gameConfiguration)
                return null;

            return (
                <WithFlyout>{ ({ push }) => {

                    const flyouts = getConfigurationSection(gameConfiguration, 'flyouts', window.swush.profile) || {};

                    const funcs = {
                        showMatch : (id) => {
                            if (flyouts.match) {
                                push('match', { id })
                            }
                            else {
                                window.location.href = absoluteUrl(`${ game.slug }/m/${ id }/${ id }` )
                            }
                        },
                        showPlayer : (id, slug, earnings) => {
                            if (flyouts.player) {
                                push('player', { id, earnings })
                            }
                            else {
                                window.location.href = absoluteUrl(`${ game.slug }/players/${ slug }` )
                            }
                        }
                    }

                    return children(funcs);
                }}</WithFlyout>
            )
        }}</GameContext.Consumer>
    )
}

export default GameNavigationService;