import React from 'react'
import PropTypes from 'prop-types'

import styled, { css, keyframes } from 'styled-components'
import { color, space } from 'styled-system'

import basket from '../images/icons/basket.svg'
import sort from '../images/icons/sort.svg';
import copy from '../images/icons/copy.svg';
import filter from '../images/icons/filter.svg';
import bell from '../images/icons/bell.svg';
import gold from '../images/icons/gold.svg';
import envelope from '../images/icons/envelope.svg';
import envelopeOpen from '../images/icons/envelope-open.svg';
import envelopeOpenWithLetter from '../images/icons/envelope-open-with-letter.svg';
import facebook from '../images/icons/social-facebook.svg'
import linkedin from '../images/icons/social-linkedin.svg'
import snapchat from '../images/icons/social-snapchat.svg'
import twitter from '../images/icons/social-twitter.svg'
import sms from '../images/icons/social-sms.svg'
import email from '../images/icons/social-email.svg'
import close from '../images/icons/close-x.svg';
import circle from '../images/icons/radiomark.svg';
import checkbox from '../images/icons/checkbox.svg';
import checkboxChecked from '../images/icons/checkbox-checked.svg';
import radiomark from '../images/icons/radiomark.svg';
import radiomarkChecked from '../images/icons/radiomark-checked.svg';
import speechBubble from '../images/icons/speech-bubble.svg';
import speechBubbleOutline from '../images/icons/speech-bubble-outline.svg';
import check from '../images/icons/checkmark.svg'
import chevronDown from '../images/icons/chevron-down.svg'
import chevronLeft from '../images/icons/chevron-left.svg'
import chevronRight from '../images/icons/chevron-right.svg'
import chevronUp from '../images/icons/chevron-up.svg'
import exclamationTriangle from '../images/icons/exclamation-triangle.svg'
import externalLink from '../images/icons/external-link.svg'
import flameSingle from '../images/icons/flame-single.svg'
import flameDouble from '../images/icons/flame-double.svg'
import group from '../images/icons/group.svg'
import injury from '../images/icons/injury.svg'
import kebabMenu from '../images/icons/kebab-menu.svg'
import pen from '../images/icons/pen.svg'
import injurySuspension1 from '../images/icons/injury-suspension-1.svg'
import injurySuspension2 from '../images/icons/injury-suspension-2.svg'
import injurySuspension3 from '../images/icons/injury-suspension-3.svg'
import injurySuspension4 from '../images/icons/injury-suspension-4.svg'
import injurySuspension5 from '../images/icons/injury-suspension-5.svg'
import injurySuspension6 from '../images/icons/injury-suspension-6.svg'
import injurySuspension7 from '../images/icons/injury-suspension-7.svg'
import injurySuspension8 from '../images/icons/injury-suspension-8.svg'
import injurySuspension9 from '../images/icons/injury-suspension-9.svg'
import info from '../images/icons/info.svg'
import triangleUp from '../images/icons/triangle-up.svg'
import triangleRight from '../images/icons/triangle-right.svg'
import triangleDown from '../images/icons/triangle-down.svg'
import triangleLeft from '../images/icons/triangle-left.svg'
import dot from '../images/icons/dot.svg'
import dotSmall from '../images/icons/dot-small.svg'
import heart from '../images/icons/heart.svg'
import heartOutline from '../images/icons/heart-outline.svg'
import heartSmall from '../images/icons/heart-small.svg'
import magnify from '../images/icons/magnify.svg'
import plus from '../images/icons/plus.svg'
import flag from '../images/icons/flag.svg'
import stopwatch from '../images/icons/stopwatch.svg'
import stopwatchAnimated from '../images/icons/stopwatch-animated.svg'
import meatballs from '../images/icons/meatballs.svg'
import cog from '../images/icons/cog.svg'
import burgermenu from '../images/icons/burgermenu.svg'
import cup from '../images/icons/cup.svg'
import table from '../images/icons/table.svg'
import waitAnimated from '../images/icons/wait-animated.svg'
import settings from '../images/icons/settings.svg'
import period1 from '../images/icons/period-1.svg'
import period1plus from '../images/icons/period-1-plus.svg'
import period2 from '../images/icons/period-2.svg'
import period2plus from '../images/icons/period-2-plus.svg'
import period3 from '../images/icons/period-3.svg'
import period4 from '../images/icons/period-4.svg'
import periodLive from '../images/icons/period-live.svg'
import person from '../images/icons/person.svg'
import personCrossed from '../images/icons/person-crossed.svg'
import padlock from '../images/icons/padlock.svg'
import star from '../images/icons/star.svg'
import share from '../images/icons/share.svg'

import suspension1 from '../images/icons/suspension-1.svg'
import suspension2 from '../images/icons/suspension-2.svg'
import suspension3 from '../images/icons/suspension-3.svg'
import suspension4 from '../images/icons/suspension-4.svg'
import suspension5 from '../images/icons/suspension-5.svg'
import suspension6 from '../images/icons/suspension-6.svg'
import suspension7 from '../images/icons/suspension-7.svg'
import suspension8 from '../images/icons/suspension-8.svg'
import suspension9 from '../images/icons/suspension-9.svg'
import spinner from '../images/icons/spinner.svg'
import eyeOutline from '../images/icons/eye-outline.svg'
import eyeOffOutline from '../images/icons/eye-off-outline.svg'


const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;


const ICON_MAP = {
    basket,
    facebook,
    linkedin,
    snapchat,
    twitter,
    sms,
    email,

    copy,
    envelope,
    envelopeOpen,
    envelopeOpenWithLetter,
    sort,
    filter,
    bell,
    close,
    circle,
    check,
    checkbox,
    checkboxChecked,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    exclamationTriangle,
    externalLink,
    flameSingle,
    flameDouble,
    group,
    kebabMenu,
    info,
    pen,
    injury,
    injurySuspension1,
    injurySuspension2,
    injurySuspension3,
    injurySuspension4,
    injurySuspension5,
    injurySuspension6,
    injurySuspension7,
    injurySuspension8,
    injurySuspension9,
    radiomark,
    radiomarkChecked,
    speechBubble,
    speechBubbleOutline,
    triangleUp,
    triangleRight,
    triangleDown,
    triangleLeft,
    dot,
    dotSmall,
    heart,
    heartOutline,
    heartSmall,
    magnify,
    plus,
    flag,
    stopwatch,
    stopwatchAnimated,
    meatballs,
    cog,
    burgermenu,
    cup,
    table,
    waitAnimated,
    settings,
    period1,
    period1plus,
    period2,
    period2plus,
    period3,
    period4,
    periodLive,
    person,
    personCrossed,
    padlock,
    star,
    suspension1,
    suspension2,
    suspension3,
    suspension4,
    suspension5,
    suspension6,
    suspension7,
    suspension8,
    suspension9,
    spinner,
    share,
    eyeOutline,
    eyeOffOutline,
    gold
}


const sizeMapper = ({ size }) => (
    size == '1-2x' && '12px' ||
    size == 'sm' && '20px' || 
    size == '2x' && '48px' || 
    size == '3x' && '72px' || 
    '24px');

const StyledIcon = styled.div`
    height: ${ sizeMapper };
    width: ${ sizeMapper };
    
    line-height: 1;
    font-size: 0;
    
    vertical-align: middle;

    display: inline-block;

    ${ props => props.shadow && css`
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    `}

    ${
        props => props.animation === 'rotate' && css`
         animation: ${ rotate } 1s linear infinite;
        `
    }

    
    ${ color }
    ${ space }
`



const Icon = React.memo(({ type, size, ...props }) => {
    const html = { __html : ICON_MAP[type] };
    return <StyledIcon size={ size } {...props} dangerouslySetInnerHTML={ html }></StyledIcon>
})

Icon.propTypes = {
    type : PropTypes.string.isRequired,
    color : PropTypes.string.isRequired,
    size : PropTypes.oneOf([ 'default',  '1-2x', '2x', '3x']),
    animation : PropTypes.string
}

Icon.defaultProps = {
    size : 'default',
    color : 'inherit'
}

export default Icon;

