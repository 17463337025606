import React, { useEffect } from 'react'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import useFlyout from '../hooks/useFlyout'


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Share = () => {
    const [showFlyout] = useFlyout();
    const params = useParams();
    const query = useQuery();
 
    const qso = Object.fromEntries(query.entries());
    const { password,  ...rest } = qso;
    const qs = new URLSearchParams(rest).toString();

    useEffect(() => {


        showFlyout('competition_preview', { competitionId : params.competitionId, password : query.get('password') });
    }, []);


    return (
        <Redirect to={ `/${ params.language }/games/${ params.gameSlug }/${ params.gameId }/competitions?${ qs }` } />
        
    )

}

export default Share;