import React from 'react'

import styled, { css } from 'styled-components'
import { layout, space } from 'styled-system'

import { useTransition, animated } from 'react-spring'



const StyledSlideout = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ${ 
     props => props.identity && css`
        background-color : var(--identity-color);
        color : var(--on-identity-color);
      `
  }

  transform: translate3d(0, 0%, 0);
  will-change: transform;

  ${ space }
  ${ layout }


  z-index: 5000;
`

export const Slideout = ({ show, identity, children, ...otherProps }) => {

    const transitions = useTransition(show, null, {
        from: { transform: 'translate3d(0, -100%, 0)' },
        enter: { transform: 'translate3d(0, 0%, 0)' },
        leave: {  transform: 'translate3d(0, -100%, 0)' }
    }, );


    return (
      <React.Fragment>
        { transitions.map(({ item, key, props }) => item && <StyledSlideout key={ key } { ...otherProps } style={ props } identity={ identity }>{ children }</StyledSlideout>) }
      </React.Fragment>
    )
      
}