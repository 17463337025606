import React from 'react';


import { MatchHead, EventDate } from 'components';
import { lo } from 'utils';
import MatchContext from '../MatchContext';


const GenericVersusMatchHead = ({ editMode }) => {
    if (editMode)
        return null;
    
    return (
        <MatchContext.Consumer>{ (match) => { 
            const home = match.participants.find(p => p.type === 'home');
            const away = match.participants.find(p => p.type === 'away');

            const homeScore = (match.properties && match.properties.homeScore || '0' );
            const awayScore = (match.properties && match.properties.awayScore || '0' );

            return (
                    <MatchHead backgroundImageUrl={ match.location.imageUrl }>
                        <MatchHead.Versus
                            group={ match.matchGroup.name }
                            date={ <EventDate date={ match.start } /> }

                            homeScore={ homeScore }
                            awayScore={ awayScore }

                            home={ home ? lo(home.team, 'name') : '-'  }
                            away={ away ? lo(away.team, 'name') : '-'  }

                            score={ match.status !== 'pending' && `${ homeScore || '0' }-${ awayScore || '0' }` }
                        />
                    </MatchHead>
            )
        }}</MatchContext.Consumer>
    )
}


GenericVersusMatchHead.defaultProps = {
    
}

export default GenericVersusMatchHead;