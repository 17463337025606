// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--navbar-background-color: none;--navbar-menu-text-color: white}.D_elGEtQFcFMxG966pgV{--link-color: var(--navbar-link-color);color:var(--navbar-text-color);background-color:var(--navbar-background-color);position:relative;z-index:1000}.CRg23F9wZQh29uJh_cp0{max-width:1200px;margin:0 auto;padding:0 12px;display:flex;flex-direction:row;align-items:center}.BqGsQTSP4UsZLyTT5IkQ{height:24px;padding-right:12px}.BqGsQTSP4UsZLyTT5IkQ img{height:24px}.EY1Asp11Qk6jlyE1Q5Ss{flex:1}.Nf3kyR8eHotdyB8nKlnr{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/components/NavBar.scss"],"names":[],"mappings":"AAAA,MACI,+BAAA,CACA,+BAAA,CAIJ,sBACI,sCAAA,CACA,8BAAA,CACA,+CAAA,CAEA,iBAAA,CACA,YAAA,CAGJ,sBACI,gBAAA,CACA,aAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAKJ,sBACG,WAAA,CACA,kBAAA,CAEC,0BAAA,WAAA,CAGJ,sBACI,MAAA,CAGJ,sBACK,kBAAA","sourcesContent":[":root {\r\n    --navbar-background-color: none;\r\n    --navbar-menu-text-color: white;\r\n}\r\n\r\n\r\n.container {\r\n    --link-color: var(--navbar-link-color);\r\n    color: var(--navbar-text-color);\r\n    background-color: var(--navbar-background-color);\r\n    \r\n    position: relative;\r\n    z-index: 1000;\r\n}\r\n\r\n.content {\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    padding: 0 12px;\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n\r\n\r\n}\r\n\r\n.brand {\r\n   height: 24px;\r\n   padding-right: 12px;\r\n\r\n    img { height: 24px; }\r\n}\r\n\r\n.menu {\r\n    flex: 1;\r\n}\r\n\r\n.aux {\r\n     white-space: nowrap;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `D_elGEtQFcFMxG966pgV`;
export var content = `CRg23F9wZQh29uJh_cp0`;
export var brand = `BqGsQTSP4UsZLyTT5IkQ`;
export var menu = `EY1Asp11Qk6jlyE1Q5Ss`;
export var aux = `Nf3kyR8eHotdyB8nKlnr`;
export default ___CSS_LOADER_EXPORT___;
