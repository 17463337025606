import React from 'react'

import styled from 'styled-components'
import { position, space, color, layout, flexbox, typography, border, shadow } from 'styled-system'

export const Box = styled.div`
    ${ position }
    ${ typography }
    ${ space }
    ${ layout }
    ${ flexbox }
    ${ color }
    ${ border }
    ${ shadow }
`

export default Box;