import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import Icon from './Icon'

import { useTransition, animated, config } from 'react-spring';

import styled from 'styled-components'

const Container = styled.div`
`


const StyledBackground = styled(animated.div)`
    position: fixed;
    font-size: 0;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1200;
    
    background-color: rgba(0, 0, 0, 0.6);
`;

const Background = React.forwardRef(({ open, onClick }, ref) => {
    const transitions = useTransition(open, null, {
        from: { opacity: 0, willChange: 'opacity' },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config : { duration: 100 }
    })

    return transitions.map(({ item, key, props }) =>
        item && <StyledBackground ref={ ref } key={ key } style={ props } onClick={ onClick }>️</StyledBackground>
    )
})




const Window = styled(animated.div)`
    position: fixed;
    
    right: 0;
    top: 0;
    bottom: 0;

    width: calc(100vw - 48px);
    min-width: 320px;
    max-width: 640px;
    
    background-color: ${ props => props.theme.colors.siteBackground };

    box-shadow: ${ props => props.theme.shadows.flyout };
    
    z-index: 1201;

    overflow: hidden;
`



export const FlyoutStack = ({ onPop, children }) => {
    const backgroundRef = useRef();
    

    const onKeyDown = useCallback((e) => {
        if (e.which === 27) {
            onPop();
        }
    })

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [])


    useEffect(() => {
        document.body.style.overflow = children.length > 0 ? 'hidden' : 'auto';
    })

    
    const childCount = React.Children.count(children);
    const transitions = useTransition(children, item => item.key, {
            from: { transform: 'translate3d(100%, 0, 0)' },
            enter: { transform: 'translate3d(0, 0, 0)' },
            leave: { transform: 'translate3d(100%, 0, 0)' },
            config : { ...config.gentle, clamp : true }
    });

    const handleBackgroundClick = (e) => {
        if (!backgroundRef.current) {
            return;
        }

        if (backgroundRef.current != e.target) {
            return;
        }

        onPop() ;
    }

    return  (
        <Container>    
            <Background ref={ backgroundRef} open={ childCount > 0 } onClick={ handleBackgroundClick }></Background>

            { 
                transitions.map(({ item, props, key }) => {
                    return <Window key={ key } style={ props }>
                    
                        { item }
                    
                    </Window>
                })
            }
        </Container>
    )
}

FlyoutStack.propTypes = {
    onPop : PropTypes.func.isRequired
}

FlyoutStack.defaultProps = {
}

