import React from 'react'

const el = document.createElement('div');
el.setAttribute('id', 'contextMenu');
document.body.appendChild(el);
el.style.display = 'none';

class ContextMenuContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.containerRef.current.appendChild(el);
        el.style.display = 'block';
    }

    componentWillUnmount() {
        this.containerRef.current.removeChild(el);
        el.style.display = 'none';
    }

    render() {
        return <div ref={ this.containerRef }></div>
    }
}


export default ContextMenuContainer;