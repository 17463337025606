import * as types from './types'
import * as wsTypes from '../ws/types'

const defaultState = {
    profilesByPlayer : {},
    fantasyStatisticsByPlayer : {},
    statisticsByPlayer : {},
    statisticsByRound : {},

    events : {},
    values : {},
    fantasyEvents : {}
};



const wsMessageReducer = (state, action) => {
    switch (action.message) {
        case 'FANTASYEVENT_INSERT': {
            const { items } = state.fantasyEvents[action.payload.player] || { items : [] }

            return {
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.payload.player] : {
                        items : [...items, action.payload.id]
                    }
                }
            }
        }
        case 'FANTASYEVENT_DELETE': {

            if (!state.fantasyEvents[action.payload.player])
                return state;
            
            const { items } = state.fantasyEvents[action.payload.player] || { items : [] }
            var index = items.indexOf(action.payload.id)
            if (index < 0)
                return state;

            return {
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.payload.player] : {
                        items : items.filter(i => i != action.payload.id)
                    }
                }
            }

            return state;
        }
        default:
            return state;
    }
}



const playerReducer = (state = defaultState, action) =>
{
    switch (action.type) {
     
        case wsTypes.WS_MESSAGE_BULK:
            let nState = state;
            for(let i = 0; i < action.queue.length; i++) {
                nState = wsMessageReducer(nState, action.queue[i]);
            }
            return nState;

        case types.FETCH_PLAYER_EVENTS_SUCCESS:
            return  { 
                ...state,
                events : {
                    ...state.events,
                    [action.playerId] : { items : action.payload.result }
                }
            };

            case types.FETCH_PLAYER_FANTASY_EVENTS_REQUEST:
                    return  { 
                        ...state,
                        fantasyEvents : {
                            ...state.fantasyEvents,
                            [action.playerId] : {
                                ...state.fantasyEvents[action.playerId],
                                isFetching : true
                            }
                        }
                    };               


        case types.FETCH_PLAYER_FANTASY_EVENTS_SUCCESS:
            return  { 
                ...state,
                fantasyEvents : {
                    ...state.fantasyEvents,
                    [action.playerId] : {
                        ...state.fantasyEvents[action.playerId],
                        items : action.payload.result,
                        isFetching : false
                    }
                }
            };            

            case types.FETCH_PLAYER_FANTASY_EVENTS_FAILURE:
                    return  { 
                        ...state,
                        fantasyEvents : {
                            ...state.fantasyEvents,
                            [action.playerId] : {
                                ...state.fantasyEvents[action.playerId],
                                isFetching : false

                            }
                        }
                    };               


        case types.FETCH_PLAYER_VALUES_SUCCESS:
            return  { 
                ...state,
                values : {
                    ...state.values,
                    [action.playerId] : {
                        ...state.values[action.playerId],
                        [action.gameId] : {
                            items : action.payload.result
                        }
                    }
                }
            };            
        
        case types.FETCH_PLAYER_PROFILE_SUCCESS:
            return  { 
                ...state,
                profilesByPlayer : {
                    ...state.profilesByPlayer,
                    [action.playerId] : {
                        ...state.profilesByPlayer[action.playerId],
                        [action.gameId] : action.payload.result
                    }
                }
            };

        case types.FETCH_PLAYER_STATISTICS_SUCCESS:
            return  { 
                ...state,
                statisticsByPlayer : {
                    ...state.statisticsByPlayer,
                    [action.playerId] : action.payload.result
                }
            };
        case types.FETCH_STATISTICS_BY_ROUND_REQUEST:
                return  { 
                    ...state,
                    statisticsByRound : {
                        ...state.statisticsByRound,
                        [action.gameId] : {
                            ...state.statisticsByRound[action.gameId],
                            [action.round] : {
                                ...(state.statisticsByRound[action.gameId] && state.statisticsByRound[action.gameId][action.round]),
                                isFetching : true
                            }
                        }
                    }
                };            
        case types.FETCH_STATISTICS_BY_ROUND_SUCCESS:
            return  { 
                ...state,
                statisticsByRound : {
                    ...state.statisticsByRound,
                    [action.gameId] : {
                        ...state.statisticsByRound[action.gameId],
                        [action.round] : {
                            ...(state.statisticsByRound[action.gameId] && state.statisticsByRound[action.gameId][action.round]),
                            items : action.payload.result,
                            isFetching : false,
                            didInvalidate : false
                        }
                    }
                }
            };
            case types.FETCH_STATISTICS_BY_ROUND_FAILURE:
                    return  { 
                        ...state,
                        statisticsByRound : {
                            ...state.statisticsByRound,
                            [action.gameId] : {
                                ...state.statisticsByRound[action.gameId],
                                [action.round] : {
                                    ...(state.statisticsByRound[action.gameId] && state.statisticsByRound[action.gameId][action.round]),
                                    isFetching : false
                                }
                            }
                        }
                    };                        
        case types.FETCH_PLAYER_GAME_STATISTICS_SUCCESS:
            return  { 
                ...state,
                fantasyStatisticsByPlayer : {
                    ...state.fantasyStatisticsByPlayer,
                    [action.playerId] : {
                        ...state.fantasyStatisticsByPlayer[action.playerId],
                        [action.gameId] : action.payload.result
                    }
                }
            };
        default:
            return state
    }
}

export default playerReducer;
