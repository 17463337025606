// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eKaihOt4AWYUvh9U7TJq{cursor:pointer;height:var(--vertical-rythm);padding:0 24px 0 0px;border-bottom:1px solid rgba(0,0,0,.1);border-top:1px solid rgba(255,255,255,.1);display:flex;flex-direction:row;align-content:center;align-items:center}.m0r16rKzyxI9BKH4DGMp{flex:1}`, "",{"version":3,"sources":["webpack://./src/components/TeamList.scss"],"names":[],"mappings":"AACA,sBACC,cAAA,CAEA,4BAAA,CACA,oBAAA,CAEA,sCAAA,CACA,yCAAA,CAEA,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,kBAAA,CAID,sBAAA,MAAA","sourcesContent":["\r\n.item {\r\n\tcursor: pointer;\r\n\r\n\theight: var(--vertical-rythm);\r\n\tpadding: 0 24px 0 0px;\t\r\n\r\n\tborder-bottom: 1px solid rgba(#000, .1);\r\n\tborder-top: 1px solid rgba(#fff, .1);\r\n\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\talign-content: center;\r\n\talign-items: center;\r\n}\r\n\r\n\r\n.name { flex: 1; }"],"sourceRoot":""}]);
// Exports
export var item = `eKaihOt4AWYUvh9U7TJq`;
export var name = `m0r16rKzyxI9BKH4DGMp`;
export default ___CSS_LOADER_EXPORT___;
