// Libraries
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchUserCompetitionsIfNeeded, fetchStandingsIfNeeded, fetchQualificationsIfNeeded } from '../redux/competition/actions'
import { getUserCompetitions, getStandings, getQualifications } from '../redux/competition/selectors'
import { fetchGameFantasyTeamsIfNeeded } from '../redux/fantasyTeam/actions'

// Services
import GameContext from '../containers/GameContext'
import { currentRound } from '../common/utils';


const useCompetitionList = (isAuthenticated, gameId) => {
    const { game } = useContext(GameContext);
    const dispatch = useDispatch();
    const competitions = useSelector(getUserCompetitions);
    const standings = useSelector(getStandings);
    const qualifications = useSelector(getQualifications);
    const [items, setItems] = useState([]);

    

    useEffect(() => {
        if (isAuthenticated ) {
            
            dispatch(fetchUserCompetitionsIfNeeded(gameId));
            dispatch(fetchGameFantasyTeamsIfNeeded(gameId))
            dispatch(fetchStandingsIfNeeded(gameId));
            dispatch(fetchQualificationsIfNeeded(gameId));
        }
    }, [isAuthenticated, gameId]);


    useEffect(() => {
        
        const cRound = currentRound(game.rounds);
        const currentRoundNumber = game.rounds.indexOf(cRound) + 1;



        const nItems = (competitions.items || []).map(c => {

            const firstRound = game.rounds[c.competition.firstRound - 1];
            const start = new Date(firstRound.close);

            let currentRound = (currentRoundNumber - c.competition.firstRound) + 1;
            if (currentRound < 1)
                currentRound = 1;

            let stage = '';
            if (new Date() < start)
                stage = 'pending';
            else 
                stage = 'started';

            const teams = (c.teams || []).map(t => {

                const tStandings = (standings.items || []).filter(s => s.competition == c.competition.id && s.fantasyTeam == t.id);
                tStandings.sort((a, b) => a.round - b.round);
                
                const tQualification = (qualifications.items || []).find(s => s.competition == c.competition.id && s.fantasyTeam == t.id);

                return {
                    ...t,
                    standings : tStandings,
                    qualification : tQualification
                }
            });
            teams.sort((a, b) => a.name.localeCompare(b.name));


            return { 
                ...c.competition,
                
                currentRound : 1,
                currentRound,

                start,
                stage,

                isEnrolled : c.teams && c.teams.length > 0 || false,
                teams,
                permissions : c.permissions
            }
        });

        nItems.sort((a, b) =>  {
                if (a.kind == 'contest' && b.kind !== 'contest')
                    return -1;
                else if (b.kind == 'contest' && a.kind !== 'contest')
                    return 1;
                else 
                    return  a.name.localeCompare(b.name);
        }
        
        );

           
        setItems(nItems);
    }, [competitions.items, standings.items, qualifications.items])


    if (!isAuthenticated) {
        return [
            [], false, () => { }
        ];
    }

    const isFetching = competitions.isFetching;
    const didFetch = items;

    return [items, isFetching, didFetch];
}


export default useCompetitionList;