import React from 'react';

import PlayerContext from '../../PlayerContext'
import GameContext from '../../GameContext'
import WithSchedule from '../../WithSchedule'
import { Icon } from 'components'


const Form = ({ count, icon, eventNames }) => {
    const normalizedEventNames = eventNames.map(name => name.toLowerCase());

    return (
        <GameContext.Consumer>{ ({ game, tournament }) => (
            <WithSchedule id={ tournament.id } gameId={ game.id }>{ ({ schedule }) => (
                <PlayerContext.Consumer>{ ({ events }) => {
                    if (!events)
                        return "-";


                    let matchForms = Object.values(events.reduce((agg, e) => {
                        if (typeof agg[e.match] === 'undefined')
                            agg[e.match] = 0;
                        
                        if (normalizedEventNames.indexOf(e.type.name.toLowerCase()) >= 0) {
                            agg[e.match] ++;
                        }

                        return agg;
                    }, {}));


                    matchForms.reverse();
                    
                    

                    let components = new Array(count).fill(1).map((k, index) => {
                        const amount = matchForms.length > index ? matchForms[index] : -1;


                        const style = amount > 0 ? { color : 'green' } :
                            amount < 0 ? { color: 'gray'} : { color: 'red'};

                        return (
                            <span key={ index } style={ style }><Icon type={ icon } size="1-2x"/></span>
                        )
                    });

                    components.reverse();
                    return components;

                    
                }}</PlayerContext.Consumer>
                
            )}</WithSchedule>
        )}</GameContext.Consumer>
    )
}

Form.defaultProps = {
    count : 5,
    icon : 'dot'
}

export default Form;