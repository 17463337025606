import React from 'react'
import PropTypes from 'prop-types'
import serverTime from '../common/serverTime';
import dayjs from 'dayjs';
import translatable from '../hocs/translatable';
import { getPeriodBaseMinute, getPeriodName } from '../helpers/soccer'

class SoccerMatchDate extends React.PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            isFuture: false,

            days: 0,
            hours : 0,
            minutes : 0,
            seconds : 0
        };

        this.timer = null;
        this.tick = this.tick.bind(this);
    
    }

    componentDidMount() {
        this.timer = setInterval(() => { this.tick(this.props) }, 100);
        this.tick(this.props);
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
          this.tick(nextProps);
      }
    
      componentWillUnmount() {
          clearInterval(this.timer);
      }
    
    renderLive() {
        const { t, match } = this.props;
        const isPaused = match.periods.length > 0 && match.periods[match.periods.length - 1].end;
        const period = getPeriodName(t, match.periods.length - 1);
        let time = (isPaused) ? 'Slut' : `${ this.state.minutes }:${ this.state.seconds }`;
        return (<em>{ period } { ' '} { time }</em>)
    }

    renderPending() {

    
        const { isFuture, days, hours, minutes, seconds } = this.state;
        const { match } = this.props;

        const mDate = new Date(match.start);
        const now = serverTime.now();

        let s = '';
        if (mDate > now) { // Future
            if (days === 0 && hours === 0 && minutes == 0 && seconds < 30) {
                return 'now';
            }
            else if (days == 0 && hours == 0) {
                return `${ hours }:${ seconds }`; 
            }
            else if (
                mDate.getFullYear() === now.getFullYear() && 
                mDate.getMonth() === now.getMonth() &&
                mDate.getDay() === now.getDay()) {

                s = dayjs(match.start).format(window.env.moment.nearDateFormat); 
            }
            else {
                if (this.props.dateStyle === 'long')
                    return dayjs(match.start).format(window.env.moment.farDateFormat.long); 
                else
                    return dayjs(match.start).format(window.env.moment.farDateFormat.short); 
            }
        }
        else {
            if (days === 0 && hours === 0 && minutes < 5) {
                s = dayjs(match.start).format(window.env.moment.nearDateFormat); 
            }
            else {
                if (this.props.dateStyle === 'long')
                    return dayjs(match.start).format(window.env.moment.farDateFormat.long); 
                else
                    return dayjs(match.start).format(window.env.moment.farDateFormat.short); 
            }
        }

        return s;
        
    }

    renderUpdatedCompleted() {
        if (this.props.dateStyle === 'long')
            return (<div>{ dayjs(this.props.match.start).format(window.env.moment.farDateFormat.long) }</div>)
        else
            return (<div>{ dayjs(this.props.match.start).format(window.env.moment.farDateFormat.short) }</div>)

        
    }



    tickPending(props) {
        const { match } = this.props;

        const delta = serverTime.now() - new Date(match.start);
        
        let seconds = Math.floor(Math.abs(delta) / 1000);
        
        const days = Math.floor(seconds / (60 * 60 * 24));
        seconds -= days * (60 * 60 * 24);
        
        const hours = Math.floor(seconds / (60 * 60));
        seconds -= hours * (60 * 60);
        
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        this.setState({
            isFuture : delta < 0,

            days,
            hours,
            minutes,
            seconds,
        });
    }


    tick(props) {
        const { match } = this.props;

        if (match.status == 'pending') {
            this.tickPending(props);
            
        }
        else if (match.periods.length > 0) {

            const minuteBase = getPeriodBaseMinute(match.periods.length);
        
            const lastPeriod = match.periods[match.periods.length - 1];
            const to = lastPeriod.end ? new Date(lastPeriod.end) : serverTime.now();

            let seconds = Math.floor(((to - new Date(lastPeriod.start)) + (minuteBase * 60 * 1000)) / 1000);
        
            let minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;

            this.setState({
            
            
                    minutes,
                    seconds
            
            });

        }
    }


  
    render() {
        const { match } = this.props;
        
        switch(match.status) {
            case 'pending':
                return this.renderPending();
            case 'live':
                return this.renderLive();
            case 'updated':
            case 'completed':
                return this.renderUpdatedCompleted();
        }





        return <span>{ s }</span>  
    }  
}

SoccerMatchDate.propTypes = {
  match: PropTypes.object.isRequired,
  dateStyle : PropTypes.string.isRequired
}

SoccerMatchDate.defaultProps = {
    dateStyle : 'long'
}

export default translatable(SoccerMatchDate);






