import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppConfig } from '../AppConfigProvider';

import { SignupEmail } from './SignupEmail'
import { SignupCode } from './SignupCode'
import { SignupUsername } from './SignupUsername'
import { SignupWithFacebook } from './SignupWithFacebook'
import { Start } from './Start'
import { SignIn } from './SignIn'
import { Reset } from './Reset'
import { ResetCode } from './ResetCode'
import { ResetConfirm } from './ResetConfirm'

import { cancel } from '../../redux/auth/actions'

import { AuthenticationModal } from '../../components/AuthenticationModal'
import { useTranslator } from '../../hooks/useTranslator'


import { animated, useTransition } from 'react-spring'

export const Authenticator = ({ canClose = true }) => {
    const dispatch = useDispatch();
    const appConfig = useAppConfig();
    const { flow, hasFlowError, showModal } = useSelector(state => state.auth);
    const t = useTranslator();

    const error = hasFlowError && t('authentication.genericError');
    const coverImageUrl = appConfig && appConfig.auth && appConfig.auth.coverImageUrl;

    const transitions = useTransition(flow, item => item, {
        from: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 },
        enter: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 1 },
        leave: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 },
    });

    const onCancel = () => {
        if (canClose) {
            dispatch(cancel());
        }
    }

    return (
        <AuthenticationModal open={ showModal } error={ error } onClose={ onCancel } canClose={ canClose } coverImageUrl={ coverImageUrl }>
            { 
                transitions.map(({ item, props, key }) =>
                    <animated.div key={key} style={props}>
                        {
                            {
                                'start' : <Start />,
                                'signin' : <SignIn />,
                                'signup' : <SignupEmail />,
                                'signup_code' : <SignupCode />,
                                'signup_username' : <SignupUsername />,
                                'facebook' : <SignupWithFacebook />,
                                'reset' : <Reset />,
                                'reset_code' : <ResetCode />,
                                'reset_confirm' : <ResetConfirm />,
                            }[item]
                        }
                    
                    </animated.div>
                )
            }
        </AuthenticationModal>
    )
}


