import React from 'react'
import PropTypes from 'prop-types'

class SortableList extends React.PureComponent {
    render() {
        const sortedItems = this.props.items.slice().sort((a, b) => {
            const aValue = this.props.value(a);
            const bValue = this.props.value(b);

            if (typeof aValue === 'string' || typeof bValue === 'string') {
                return this.props.reverse ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);

            }
            else {
                return this.props.reverse ? bValue - aValue : aValue - bValue;
            }
        })

        return sortedItems.map((item, index) => {
            return this.props.render(item, index);
        });
    }
}


SortableList.propTypes = {
    items : PropTypes.array.isRequired,
    value : PropTypes.func.isRequired,
    render : PropTypes.func.isRequired,
    reverse : PropTypes.bool.isRequired,
}

SortableList.defaultProps = {
    reverse : false
}

export default SortableList;