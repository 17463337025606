import React from 'react';

import PlayerContext from '../../PlayerContext'
import { percent } from 'utils'

const Popularity = ({ }) => (

    
    <PlayerContext.Consumer>{ ({ values }) => {
        if (!values || values.length == 0)
            return "-"
    
        return percent(values[values.length - 1].popularity);
    }}</PlayerContext.Consumer>
)

Popularity.defaultProps = {
}

export default Popularity;