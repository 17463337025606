import React from 'react'
import ButtonBase from './ButtonBase'
import * as styles from './Button.scss'

const IconButton = React.forwardRef(({ solid, onClick, children, label, ...otherProps }, ref) => {
    return (
        <ButtonBase { ...otherProps}>{ (className) => {
                return <div className={ `${ className } ${ styles.withIcon } ${ solid && styles.solid }` } ref={ ref } onClick={ (e) => { e.preventDefault(); onClick(); } }>
                        <span className={ styles.content }>
                            { label && <span className={ styles.label }>{ label }</span> }
                            <span className={ styles.icon }>{ children }</span>
                        </span>
                </div>
            }
        }</ButtonBase>
        
    )     
    
})

export default IconButton;