import React from 'react'

import { MatchCard, EventDate, Icon } from '../../components';

const GenericMatchCard = React.memo(({ item, onClick, facts }) => {
    return (
        <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } current={ item.current } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
            <MatchCard.SimpleEventContent round={ item.round }  title={ item.name } />

            <MatchCard.Footer end={ !facts || facts.length === 0 }>
                {
                    (facts || []).map((f, index) => {
                        const propertyValue = (item.properties || {})[f.property];
                        return <span key={ index }>{ f.render(propertyValue) }</span>
                    })
                }

                <EventDate date={ new Date(item.start) } />
            </MatchCard.Footer>
            
            { item.status === 'updated' && <MatchCard.Icon><Icon type="flag" size="1-2x" /></MatchCard.Icon> }
        </MatchCard> 
    )
})

export default GenericMatchCard;