import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as styles from './Alert.scss'

const Alert = ({ type, image, children }) => {
    const className = classNames(
        styles.alert,
        styles[type]
    );


    return (
        <div className={ className }>
            <div className={ styles.image }>{ image }</div>
            <div className={ styles.content }>
                { children }
            </div>
        </div>
    )
}

Alert.propTypes = {
    type: PropTypes.string
}

Alert.defaultProps = {
    type : 'error'
}

export default Alert