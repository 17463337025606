// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WPKHxrjDIN4EBeF_hmjP {
    position: relative;    
}

.KZodMMhGFvoEN4lyhK18 {
    position: relative;
    z-index: 2;
    
}

.M1dEnoUJbscxRar5yQSA {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100%  - 8px);
    opacity: 0;

    z-index: 1;

    padding-top: 8px;

    background-color: var(--action);
    color: var(--on-action);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    font-size: 11px;
}


.NfF1MPvxTycLJJfZw31N {
    position: absolute;
    left: 0;
    right: 0;

    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 12px;

}`, "",{"version":3,"sources":["webpack://./src/components/CardToast.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;;AAEd;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,UAAU;;IAEV,UAAU;;IAEV,gBAAgB;;IAEhB,+BAA+B;IAC/B,uBAAuB;IACvB,8BAA8B;IAC9B,+BAA+B;;IAE/B,eAAe;AACnB;;;AAGA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;;IAER,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;;AAEnB","sourcesContent":[".container {\r\n    position: relative;    \r\n}\r\n\r\n.content {\r\n    position: relative;\r\n    z-index: 2;\r\n    \r\n}\r\n\r\n.menu {\r\n    position: absolute;\r\n    left: 0;\r\n    right: 0;\r\n    top: calc(100%  - 8px);\r\n    opacity: 0;\r\n\r\n    z-index: 1;\r\n\r\n    padding-top: 8px;\r\n\r\n    background-color: var(--action);\r\n    color: var(--on-action);\r\n    border-bottom-left-radius: 5px;\r\n    border-bottom-right-radius: 5px;\r\n\r\n    font-size: 11px;\r\n}\r\n\r\n\r\n.item {\r\n    position: absolute;\r\n    left: 0;\r\n    right: 0;\r\n\r\n    height: 36px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0 12px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `WPKHxrjDIN4EBeF_hmjP`;
export var content = `KZodMMhGFvoEN4lyhK18`;
export var menu = `M1dEnoUJbscxRar5yQSA`;
export var item = `NfF1MPvxTycLJJfZw31N`;
export default ___CSS_LOADER_EXPORT___;
