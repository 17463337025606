import axios from 'axios'
import { normalize } from 'normalizr'

import { messageBoardPostSchema, messageBoardSchema  } from '../../schemas';
import auth from '../../common/auth'
import * as types from './types';
import { shouldFetchItem, shouldFetchItems } from '../common'

import { safeget } from 'utils';

import ws from '../../common/WebSocketClient'

/* Fetch Posts */

const fetchPostsRequest = (messageBoardId) => ({ type: types.FETCH_POSTS_REQUEST, messageBoardId });
const fetchPostsSuccess = (messageBoardId, payload) => ({ type: types.FETCH_POSTS_SUCCESS, messageBoardId, payload });
const fetchPostsFailure = (messageBoardId) => ({ type: types.FETCH_POSTS_FAIL, messageBoardId });

const fetchPosts = (messageBoardId) => (dispatch) => { 
    dispatch(fetchPostsRequest(messageBoardId));

    const accessToken = auth.getAccessToken();
    return axios.get(`/messageboards/${ messageBoardId }/posts?access_token=${ accessToken }&embed=user`).then(response => {
        
        dispatch(fetchPostsSuccess(messageBoardId, normalize(response.data, 
            {
                items : [messageBoardPostSchema]
            }
        )));
    }, e => {''
        dispatch(fetchPostsFailure(messageBoardId));
    });
}

export const fetchPostsIfNeeded = (messageBoardId) => (dispatch, getState) => {
    const state = getState();
    
    if (shouldFetchItems(safeget(state.messageBoard, ['byMessageBoard', messageBoardId, 'posts']))) {
        dispatch(fetchPosts(messageBoardId));
    }
}

/* Fetch Posts */

const fetchMorePostsRequest = (messageBoardId) => ({ type: types.FETCH_MORE_POSTS_REQUEST, messageBoardId });
const fetchMorePostsSuccess = (messageBoardId, payload) => ({ type: types.FETCH_MORE_POSTS_SUCCESS, messageBoardId, payload });
const fetchMorePostsFailure = (messageBoardId) => ({ type: types.FETCH_MORE_POSTS_FAIL, messageBoardId });

export const fetchMorePosts = (messageBoardId) => (dispatch, getState) => { 

    
    const { posts } = getState().messageBoard.byMessageBoard[messageBoardId];
    const { links } = posts || { links : {} };
    
    dispatch(fetchMorePostsRequest(messageBoardId));

    const accessToken = auth.getAccessToken();

    return axios.get(`${ links.next }&access_token=${ accessToken }&embed=user`).then(response => {
        dispatch(fetchMorePostsSuccess(messageBoardId, normalize(response.data, {
            items : [messageBoardPostSchema]
        })));
    }, e => {''
        dispatch(fetchMorePostsFailure(messageBoardId));
    });
}

/* Invalidate posts */

export const invalidatePosts = (messageBoardId) => ({ type: types.INVALIDATE_POSTS, messageBoardId });

/* Create post  */

const createPostRequest = (messageBoardId) => ({ type: types.CREATE_POST_REQUEST, messageBoardId });
const createPostSuccess = (messageBoardId, payload) => ({ type: types.CREATE_POST_SUCCESS, messageBoardId, payload });
const createPostFailure = (messageBoardId) => ({ type: types.CREATE_POST_FAIL, messageBoardId });

export const createPost = (messageBoardId, data) => (dispatch) => { 
    dispatch(createPostRequest(messageBoardId));

    const accessToken = auth.getAccessToken();

    return axios.post(`/messageboards/${ messageBoardId }/posts?access_token=${ accessToken }&embed=user`, data).then(response => {
        dispatch(createPostSuccess(messageBoardId, normalize(response.data, messageBoardPostSchema)));
    }).catch(() => {
        dispatch(createPostFailure(messageBoardId));
    });
}


/*
export const createPost = (messageBoardId, data) => (dispatch) => { 
    dispatch(createPostRequest(messageBoardId));

    ws.connection.invoke('SendToMessageBoard', messageBoardId, null, data.text).then((data) => {
        dispatch(createPostSuccess(messageBoardId, normalize(data, messageBoardPostSchema)));
    }).catch(() => {
        dispatch(createPostFailure(messageBoardId));
    });
}
*/

/* Fetch Replies */

const fetchRepliesRequest = (messageBoardId, postId) => ({ type: types.FETCH_REPLIES_REQUEST, messageBoardId, postId });
const fetchRepliesSuccess = (messageBoardId, postId, payload) => ({ type: types.FETCH_REPLIES_SUCCESS, messageBoardId, postId, payload });
const fetchRepliesFailure = (messageBoardId, postId) => ({ type: types.FETCH_REPLIES_FAIL, messageBoardId, postId });

const fetchReplies = (messageBoardId, postId) => (dispatch) => { 
    dispatch(fetchRepliesRequest(messageBoardId, postId));

    const accessToken = auth.getAccessToken();

    return axios.get(`/messageboards/${ messageBoardId }/posts/${ postId }/replies?access_token=${ accessToken }&embed=user`).then(response => {
        dispatch(fetchRepliesSuccess(messageBoardId, postId, normalize(response.data, {
            items : [messageBoardPostSchema]
        })));
    }, e => {
        dispatch(fetchRepliesFailure(messageBoardId, postId));
    });
}

const shouldFetchReplies = (state) => {
    const { isFetching, didFetch, didInvalidate, items } = state || { isFetching : false, didInvalidate : false, items : null, didFetch : false};

    if (isFetching) { 
        return false;
    }
    else if (!didFetch) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const fetchRepliesIfNeeded = (messageBoardId, postId) => (dispatch, getState) => new Promise((resolve, reject) => {
    const state = getState();

    const { byPost } = state.messageBoard.byMessageBoard[messageBoardId] || { byPost : null };
    
    const { replies } = byPost || { replies : null }
    
    
    if (shouldFetchReplies(replies)) {
        dispatch(fetchReplies(messageBoardId, postId)).then(
            () => resolve(),
            () => reject()
        );
    } else {
        resolve();
    }
})


/* Fetch More Replies */

const fetchMoreRepliesRequest = (messageBoardId, postId) => ({ type: types.FETCH_MORE_REPLIES_REQUEST, messageBoardId, postId });
const fetchMoreRepliesSuccess = (messageBoardId, postId,  payload) => ({ type: types.FETCH_MORE_REPLIES_SUCCESS, messageBoardId, postId, payload });
const fetchMoreRepliesFailure = (messageBoardId, postId) => ({ type: types.FETCH_MORE_REPLIES_FAIL, messageBoardId, postId });

export const fetchMoreReplies = (messageBoardId, postId) => (dispatch, getState) => { 

    const links = safeget(getState(), ['messageBoard', 'byMessageBoard', messageBoardId, 'repliesByPost', postId, 'links'])
    
    dispatch(fetchMoreRepliesRequest(messageBoardId, postId));

    const accessToken = auth.getAccessToken();

    return axios.get(`${ links.next }&access_token=${ accessToken }&embed=user`).then(response => {
        dispatch(fetchMoreRepliesSuccess(messageBoardId, postId, normalize(response.data, {
            items : [messageBoardPostSchema]
        })));
    }, e => {''
        dispatch(fetchMoreRepliesFailure(messageBoardId, postId));
    });
}


/* Create Reply */

const createReplyRequest = (messageBoardId, postId) => ({ type: types.CREATE_REPLY_REQUEST, messageBoardId, postId });
const createReplySuccess = (messageBoardId, postId, payload) => ({ type: types.CREATE_REPLY_SUCCESS, messageBoardId, postId, payload });
const createReplyFailure = (messageBoardId, postId) => ({ type: types.CREATE_REPLY_FAIL, messageBoardId, postId });

export const createReply = (messageBoardId, postId, data) => (dispatch) => { 
    dispatch(createReplyRequest(messageBoardId, postId));
    const accessToken = auth.getAccessToken();

    return axios.post(`/messageboards/${ messageBoardId }/posts/${ postId }/replies?access_token=${ accessToken }&embed=user`, data).then(response => {
        dispatch(createReplySuccess(messageBoardId, postId, normalize(response.data, messageBoardPostSchema)));
    }).catch(() => {
        dispatch(createReplyFailure(messageBoardId, postId));
    });
}

/* Fetch Messageboard */

const fetchMessageBoardRequest = (messageBoardId) => ({ type: types.FETCH_MESSAGEBOARD_REQUEST, messageBoardId });
const fetchMessageBoardSuccess = (messageBoardId, payload) => ({ type: types.FETCH_MESSAGEBOARD_SUCCESS, messageBoardId, payload });
const fetchMessageBoardFailure = (messageBoardId) => ({ type: types.FETCH_MESSAGEBOARD_FAIL, messageBoardId });

const fetchMessageBoard = (messageBoardId) => (dispatch) => { 
    dispatch(fetchMessageBoardRequest(messageBoardId));

    const accessToken = auth.getAccessToken();

    return axios.get(`/messageboards/${ messageBoardId }?access_token=${ accessToken }`).then(response => {
        dispatch(fetchMessageBoardSuccess(messageBoardId, normalize(response.data, messageBoardSchema)));
    }, e => {''
        dispatch(fetchMessageBoardFailure(messageBoardId));
    });
}

export const fetchMessageBoardIfNeeded = (messageBoardId) => (dispatch, getState) => {
    const state = getState();
    
    if (shouldFetchItem(state.messageBoard.byId[messageBoardId])) {
        dispatch(fetchMessageBoard(messageBoardId));
    }
}

export const invalidateMessageBoard = (messageBoardId) => ({ type: types.INVALIDATE_MESSAGEBOARD, messageBoardId });


/* Mark messageboard as read */

const markMessageBoardReadRequest = (messageBoardId) => ({ type: types.MARK_MESSAGEBOARD_READ_REQUEST, messageBoardId });
const markMessageBoardReadSuccess = (messageBoardId, payload) => ({ type: types.MARK_MESSAGEBOARD_READ_SUCCESS, messageBoardId });
const markMessageBoardReadFailure = (messageBoardId) => ({ type: types.MARK_MESSAGEBOARD_READ_FAIL, messageBoardId });

export const markMessageBoardRead = (messageBoardId) => (dispatch) => { 
    dispatch(markMessageBoardReadRequest(messageBoardId));

    const accessToken = auth.getAccessToken();

    return axios.post(`/messageboards/${ messageBoardId }/mark-read?access_token=${ accessToken }`).then(response => {
        dispatch(markMessageBoardReadSuccess(messageBoardId));
    }, e => {
        dispatch(markMessageBoardReadFailure(messageBoardId));
    });
}

/* Delete Post */

const deletePostRequest = (messageBoardId, postId, parentPostId) => ({ type: types.DELETE_POST_REQUEST, messageBoardId, postId, parentPostId });
const deletePostSuccess = (messageBoardId, postId, parentPostId) => ({ type: types.DELETE_POST_SUCCESS, messageBoardId, postId, parentPostId });
const deletePostFailure = (messageBoardId, postId, parentPostId) => ({ type: types.DELETE_POST_FAIL, messageBoardId, postId, parentPostId });

export const deletePost = (messageBoardId, postId, parentPostId) => (dispatch) => new Promise((resolve, reject) => { 
    dispatch(deletePostRequest(messageBoardId, postId, parentPostId));

    const accessToken = auth.getAccessToken();

    axios.delete(`/messageboards/${ messageBoardId }/posts/${ postId }?access_token=${ accessToken }`).then(response => {
        dispatch(deletePostSuccess(messageBoardId, postId, parentPostId));
        resolve();
    }, e => {
        
        dispatch(deletePostFailure(messageBoardId, postId, parentPostId));
        reject();
    });
})

/* Report Post */

export const reportPost = (messageBoardId, postId, request) => (dispatch) => new Promise((resolve, reject) => { 
    const accessToken = auth.getAccessToken();

    axios.post(`/messageboards/${ messageBoardId }/posts/${ postId }/report?access_token=${ accessToken }`, request).then(response => {
        resolve();
    }, e => {
        reject();
    });
})



/**/

const subscribeMessageBoard = (messageBoardId) => ({ type: types.SUBSCRIBE, messageBoardId });
const unsubscribeMessageBoard = (messageBoardId) => ({ type: types.UNSUBSCRIBE, messageBoardId });

export const subscribe = (messageBoardId) => (dispatch, getState) => { 
    const subscriptions = getState().messageBoard.subscriptions;

    if (subscriptions.indexOf(messageBoardId) < 0) {

        setTimeout(() => {
            ws.connection.send('subscribeMessageBoard', messageBoardId).then(() => {
                dispatch(subscribeMessageBoard(messageBoardId));
            })
        }, 1000); // gagh
    }
};

export const unsubscribe = (messageBoardId) => (dispatch, getState) => { 
    const subscriptions = getState().messageBoard.subscriptions;

    if (subscriptions.indexOf(messageBoardId) >= 0) {
        ws.connection.send('unsubscribeMessageBoard', messageBoardId).then(() => {
            dispatch(unsubscribeMessageBoard(messageBoardId));
        })
    }
};





/* Invalidate posts */

export const applyPendingPosts = (messageBoardId) => ({ type: types.APPLY_PENDING_POSTS, messageBoardId });
export const applyPendingReplies = (messageBoardId, postId) => ({ type: types.APPLY_PENDING_REPLIES, messageBoardId, postId });

const wsOnNewPostReceived = (messageBoardId, postId, payload) => ({ type: types.WS_NEW_POST, messageBoardId, postId, payload });



export const wsOnNewPost = (messageBoardId, postId, post) => (dispatch, getState) => { 
    const userId = auth.getUserId();

    if (userId !== post.user.id) {
        dispatch(wsOnNewPostReceived(messageBoardId, postId, normalize(post, messageBoardPostSchema)))
    }
};






