import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { connect } from 'react-redux';

import { fetchPlayerEventsIfNeeded } from '../redux/player/actions'

class WithPlayerEvents extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.fetch(this.props.playerId);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.playerId !== this.props.playerId) {
            this.props.fetch(nextProps.playerId);
        }
    }

    render() {
        const { events } = this.props;
        return this.props.children({ events });
    }
}

WithPlayerEvents.propTypes = {
    playerId : PropTypes.number.isRequired
}

WithPlayerEvents.defaultProps = {
}



export const makeGetEvents = () => (
    createSelector([
        (state, props) => props.playerId,
        (state) => state.player.events,
        (state) => state.entities.events,
        (state) => state.entities.eventTypes
    ],
    (playerId, playerEvents, eventEntities, eventTypeEntities) => {
           return (playerEvents[playerId] || { items : []}).items.map(pid => {
                const ev = eventEntities[pid];
                const type = ev && eventTypeEntities[ev.type];

                return {
                    ...ev,
                    type
                }

           })
    })
)

const makeMapStateToProps = () => {
    const getEvents = makeGetEvents();
    
    return (state, ownProps) => ({
        events : getEvents(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(playerId) {
            dispatch(fetchPlayerEventsIfNeeded(playerId));
        }
    }
}


export default connect(makeMapStateToProps, mapDispatchToProps)(WithPlayerEvents);


