// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G5A9boVB0nLQ_UgfmWAj{box-sizing:border-box;position:relative;padding:24px 0 12px 24px;overflow:hidden;min-height:144px}.SQfxF8LOcIuJcHJrA035:nth-of-type(1){min-height:120px;display:flex;margin-right:144px}.SQfxF8LOcIuJcHJrA035:nth-of-type(2){display:flex;align-items:flex-start;max-width:472px}@media all and (min-width: 592px){.SQfxF8LOcIuJcHJrA035:nth-of-type(1){min-height:60px}}.jBet7N811MtMa49Fv41I{position:absolute;top:8px;right:8px;width:128px;height:128px}.jBet7N811MtMa49Fv41I img{display:block}`, "",{"version":3,"sources":["webpack://./src/components/FlyoutSummary.scss"],"names":[],"mappings":"AAAA,sBACI,qBAAA,CACA,iBAAA,CACA,wBAAA,CACA,eAAA,CACA,gBAAA,CAGJ,qCACI,gBAAA,CACA,YAAA,CAEA,kBAAA,CAGJ,qCACI,YAAA,CACA,sBAAA,CAEA,eAAA,CAIJ,kCACI,qCACE,eAAA,CAAA,CAKJ,sBACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CAEA,0BACI,aAAA","sourcesContent":[".container {\r\n    box-sizing: border-box;\r\n    position: relative;\r\n    padding: 24px 0 12px 24px;\r\n    overflow: hidden;\r\n    min-height: 144px;\r\n}\r\n\r\n.item:nth-of-type(1) {\r\n    min-height:120px;\r\n    display:flex;\r\n    \r\n    margin-right:calc(8px + 128px + 8px);\r\n}\r\n\r\n.item:nth-of-type(2) {\r\n    display:flex;\r\n    align-items: flex-start;\r\n    \r\n    max-width:calc(640px - (24px + 8px + 128px + 8px));\r\n}\r\n\r\n\r\n@media all and (min-width: calc(640px - 48px)) {\r\n    .item:nth-of-type(1) {\r\n      min-height:60px;\r\n    }\r\n  }\r\n  \r\n\r\n  .media {\r\n    position: absolute;\r\n    top: 8px;\r\n    right: 8px;\r\n    width: 128px;\r\n    height: 128px;\r\n\r\n    img {\r\n        display:block;\r\n      }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `G5A9boVB0nLQ_UgfmWAj`;
export var item = `SQfxF8LOcIuJcHJrA035`;
export var media = `jBet7N811MtMa49Fv41I`;
export default ___CSS_LOADER_EXPORT___;
