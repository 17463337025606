import * as types from './types'

const defaultState = {
    byGame : {
    }
};



const invitationReducer = (state = defaultState, action) =>
{
    switch (action.type) {

        case types.FETCH_GAME_INVITATIONS_SUCCESS:
                return  { 
                    ...state,
                    byGame : {
                        ...state.byGame,
                        [action.game] : action.payload
                    }
                };
            default:
            return state
    }
}

export default invitationReducer;
