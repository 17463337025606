import React from 'react'

import { Table, EventDate, Round } from '../../components'

import MatchStatus from '../MatchStatus'

import translatable from '../../hocs/translatable'

const GolfScheduleTableRow = ({ item, onClick }) => {
    const properties = item.properties || {};

    return (
        <Table.Row onClick={ onClick } active={ item.current }>
            <Table.Cell center><Round post={ item.status === 'completed' }>{ item.round }</Round></Table.Cell>
            <Table.Cell><EventDate date={ new Date(item.start) } format="short" /></Table.Cell>
            <Table.Cell><span className="a">{ item.name }</span></Table.Cell>
            <Table.Cell right>{ properties.tournamentPrize || '-' }</Table.Cell>
            <Table.Cell center>{ properties.tournamentPar || '-' }</Table.Cell>
            <Table.Cell center>{ properties.tournamentCategory || '-' }</Table.Cell>
            <Table.Cell right><MatchStatus status={ item.status } /></Table.Cell>
        </Table.Row>
    )
}

const GolfScheduleTable = ({ t, schedule, onSelectMatch }) => {
    return (
        <Table>
            <Table.Head>
                <Table.Row>
                    <Table.Header center>{ t('common.round' )}</Table.Header>
                    <Table.Header>{ t('common.start' )}</Table.Header>
                    
                    <Table.Header>{ t('golf.tournament' )}</Table.Header>
                    
                    <Table.Header right>{ t('golf.prize' )}</Table.Header>
                    <Table.Header center>{ t('golf.par' )}</Table.Header>
                    <Table.Header center>{ t('golf.class' )}</Table.Header>
                      
                    <Table.Header right>{ t('common.status' )}</Table.Header>
                </Table.Row>
            </Table.Head>
            <Table.Body>
                { schedule.items.map(item => <GolfScheduleTableRow key={ item.id } item={ item } onClick={ () => onSelectMatch(item) } ></GolfScheduleTableRow>) }
            </Table.Body>
        </Table>
    )
}

export default translatable(GolfScheduleTable);