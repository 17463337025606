import React from 'react'

import VersusSchedule from '../VersusSchedule'

import WithFlyout from '../WithFlyout'
import GameContext from '../GameContext'

import GenericVersusMatchCard from './GenericVersusMatchCard'

const GenericVersusSchedule = ({ hideGroup }) => {
    return (

        <WithFlyout>{ ({ push }) => (
            <GameContext.Consumer>{ ({  game, gameConfiguration }) => (
        
                <VersusSchedule game={ game } gameConfiguration={ gameConfiguration } >
                    { 
                        ({ item }) => <GenericVersusMatchCard key={ item.id } item={ item } hideGroup={ hideGroup }  onClick={ () =>  push('match', { id : item.id }) }/> 
                    }
                </VersusSchedule>
            )}</GameContext.Consumer>
        )}</WithFlyout>
    )
}

export default GenericVersusSchedule;