import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

import styled from 'styled-components'

const Container = styled.div`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--meta-foreground);
    text-align: center;
`



const EmptyResultsetPlaceholder = ({ show, icon, placeholder, children }) => {
    if (show) {
        return  <Container>
                    <Icon type={ icon } size="3x" />
                    <em>{ placeholder }</em>
                </Container>
    }
    else {
        return children;
        
    }
}


EmptyResultsetPlaceholder.propTypes = {
    show : PropTypes.bool.isRequired,
    placeholder : PropTypes.any.isRequired,
    icon : PropTypes.string.isRequired
}

EmptyResultsetPlaceholder.defaultProps = {
    icon : 'filter'
}

export default EmptyResultsetPlaceholder;