import * as types from './types'


const defaultState = {
    statistics : {
        round : 1,
        positions: []
    
    },
    schedule : {
        round: null
    },

    flyouts: [],

    selectedPlayer : null,
    createGroup : {
        errors : []
    },
    connection : {
        isFlaky : false,
        isLost : false
    },
    data : {
    },
    title : '',
    modal : null

}

export default function ui (state = defaultState, action) 
{
    switch (action.type) {
        case types.SELECT_TOURNAMENT:
            return { 
                ...state,
                selectedTournament : action.tournament
            };
        case types.UI_SET_DATA:
            return { 
                ...state,
                data : {
                    [action.name] : {
                        ...state.data[action.name],
                        ...action.data
                    }
                }
            };
        case types.UI_REPLACE_DATA:
            return { 
                ...state,
                data : {
                    [action.name] : action.data
                }
            };            
        case types.UI_CLEAR_DATA:
            return { 
                ...state,
                data : {
                    [action.name] : {}
                }
            };            
        case types.UI_STATISTICS_FILTER:
            return { 
                ...state,
                statistics : {
                    ...state.statistics,
                    ...action.options
                }
            };
        case types.UI_SCHEDULE_FILTER:
            return { 
                ...state,
                schedule : {
                    ...state.schedule,
                    ...action.options
                }
            };
        case types.UI_SELECT_PLAYER:
            return { 
                ...state,
                flyout : {
                    open : true,
                    props : { playerId : action.playerId, gameId : action.gameId },
                    theme : action.theme,
                    size : 'large',
                    view : 'player-profile'
                }
            };
        case types.UI_SELECT_MATCH:
            return { 
                ...state,
                matchId : action.matchId
            };
        case types.UI_PUSH_FLYOUT:
            return { 
                ...state,
                flyouts : [...state.flyouts, { view : action.view, props : action.props }] 
            };
        case types.UI_POP_FLYOUT:
            return { 
                ...state,
                flyouts : state.flyouts.slice(0, -1)
            };
        case types.UI_CLEAR_FLYOUTS:
            return { 
                ...state,
                flyouts : []
            };

        case types.UI_SHOW_GROUP_SETTINGS:
            return { 
                ...state,
                flyouts : state.flyouts.push({ 
                    component : 'group', 
                    props: {
                        view : action.view
                    },
                    theme : action.theme,
                    size : 'large'
                })
            };

        case types.UI_SET_TITLE:
            
            return { 
                ...state,
                title : action.title
            };

        case types.SHOW_MODAL:
            return { ...state, modal : { name : action.name, props : action.props, callback : action.callback } };
        case types.HIDE_MODAL:
            return { ...state, modal : null };
        default:
            return state
        }
    }