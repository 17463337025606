import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class FieldError extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        const { errors } = this.props;
        
        const propertyErrors = (errors || []).filter(e => e.source === this.props.field);

        const className = classNames(
            { 'error' : propertyErrors.length > 0 }
        )

        return (
            <div className={ className }>
                { this.props.children }
                <div>
                    { propertyErrors.map((e, idx) => <div key={ idx }><small style={{ color: 'red' }}>{ e.message }</small></div>)}
                </div>
            </div>
        )
    }  
}

FieldError.propTypes = {
    field : PropTypes.string.isRequired,
    errors : PropTypes.any
}

export default FieldError;

