import React, { useRef, useLayoutEffect } from 'react'

import { animated, useTransition } from 'react-spring';

import * as styles from './ExpandablePanel.module.css'
import { useTheme } from 'styled-components';

const ExpandablePanel = ({ open, children }) => {
    const containerRef = useRef();
    const ref = useRef();
    const theme = useTheme();

    useLayoutEffect(() => {
        if (window.ResizeObserver) {

            if ( ref.current) {
                const resizeObserver = new ResizeObserver(entries => {

                    if (containerRef.current) {
                        containerRef.current.style.height = entries[0].contentRect.height + 'px';
                        containerRef.current.style.minHeight = entries[0].contentRect.height + 'px';
                    }
                    
                });

                resizeObserver.observe(ref.current);

                return () => {
                    resizeObserver.unobserve(ref.current);
                }
            }
        }


    }, [ref.current])

    const transitions = useTransition(open, null, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config : { duration : 300 }

    });

    return (
        <div className={ styles.container } ref={ containerRef } style={{ backgroundColor: theme.colors.siteBackground}}>
            <div ref={ ref }>
                
                {
                     transitions.map(({ item, key, props }) => item && <animated.div key={ key } style={ props }>{ children }</animated.div>)

                }
                
            </div>
        </div>
    )
}

export default ExpandablePanel;
