import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { pushFlyout, popFlyout, clearFlyouts } from '../redux/ui/actions';

const useFlyout = () => {
    const dispatch = useDispatch()
    const res = useMemo(() => {
        const push = (type, props) => {
            dispatch(pushFlyout(type, props));
        }
    
        const pop = () => {
            dispatch(popFlyout());
        }
    
        const clear = () => {
            dispatch(clearFlyouts())
        }
    
        return [push, pop, clear] 
    }, [])
    
    return res;
};

export default useFlyout;

