import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from './Icon'

import styled, { css } from 'styled-components'
import { typography } from 'styled-system'
import useTheme from './useTheme';


const StyledIcon = styled.div`
    color: ${ props => props.theme.colors.themes[props.themeName].action };
`

const StyledLabel = styled.span`
    flex: 1; 
    margin-left: 12px; 
    ${ typography };
`

const StyledContainer = styled.div`
	display: block;

	font-size: 14px;
	
    height: 47px;
    
    background: ${ 
        props => props.disabled ? 
            props.theme.colors.buttonLikeBackgroundDisabled : 
            props.theme.colors.buttonLikeBackground
        
    };
    margin: 0 0 1px 0;
    
    line-height: 47px;
    
	padding: 0 ${ props => props.theme.space.s300 };
	border-radius:  ${ props => props.theme.radii.r400 };

	transition: 0.15s linear background-color;
	will-change: background-color;

	display: flex;
	align-items: center;

	color:  ${ props => props.theme.colors.themes[props.themeName].action };

    user-select: none;

    ${ 
        props => !props.disabled && css`
            &:hover {
                cursor: pointer;
                background-color :  ${ props => props.theme.colors.buttonLikeBackground };
                color :  ${ props => props.theme.colors.themes[props.themeName].actionHover };
            }

           
    `}
    
    ${ 
        props => props.disabled && css`
            ${ StyledIcon } {
                color:  ${ props => props.theme.colors.buttonLikeForegroundDisabled };
            }

            ${ StyledLabel } {
                color:  ${ props => props.theme.colors.buttonLikeForegroundDisabled };
            }
    `}
	
`

const Checkable = ({ checked, label, disabled, uncheckedIcon, checkedIcon, themeName, children, onClick}) => {
    const theme = useTheme();

    return (
        <StyledContainer onClick={ onClick } checked={ checked } themeName={ themeName } disabled={ disabled }>
            <StyledIcon themeName={ themeName } >
                <Icon type={ checked ? checkedIcon : uncheckedIcon } />
            </StyledIcon>
            <StyledLabel themeName={ themeName }  {...theme.textStyles.formNormal } >{ children }</StyledLabel>
        </StyledContainer>
    )
}


Checkable.propTypes = {
    checked : PropTypes.bool.isRequired,
    disabled : PropTypes.bool.isRequired,
    icon : PropTypes.string.isRequired,
    onClick : PropTypes.func.isRequired,
    
}

Checkable.defaultProps = {
    checked : false,
    disabled : false,
    icon : 'check',
    themeName : 'default'
}

export default Checkable;