import React from 'react'
import PropTypes from 'prop-types'


import WithTournamentStatistics from './WithTournamentStatistics';

import { matchesConditions, resolve } from 'utils'

class Filter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            items : []
        }
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.filter(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.items !== this.props.items) || (nextProps.conditions !== this.props.conditions)) {
                this.filter(nextProps);
        }
    }

    filter(props) {

        this.setState({
            items : (props.items || []).filter(i => matchesConditions(i, props.conditions))
        })
    }

    render() {
        return this.props.children({ items : this.state.items });
    }
}



class PlayerStatisticsDataGrid extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            filters : [],
            sort : [],
            activeColumnIndex : -1,
            page : 1
        }

        this.updateFilters = this.updateFilters.bind(this);
        this.sortByColumn = this.sortByColumn.bind(this);
    }

    
    updateFilters(props) {
        
        const conditions = [];
        conditions.push({ property : 'player.active', op : 'eq', value : true });

        if (props.name && props.name.length > 0)
            conditions.push({ property : 'player.person.fullName', op : 'like', value : props.name });

        if (props.positions && props.positions.length > 0)
            conditions.push({ property : 'player.position.slug', op : 'in', value : props.positions });

        if (props.team)
            conditions.push({ property : 'player.team.slug', op : 'eq', value : props.team });

        if (props.watchlist)
            conditions.push({ property : 'watchlist', op : 'eq', value : true });

        this.setState({
            filters : conditions
        })
    }

    componentDidMount() {
        this.updateFilters(this.props);
        
        const sortColumn = this.props.columns.find(c => c.sort && c.sort.default);
        if (sortColumn) {
            this.setState({
                activeColumnIndex : this.props.columns.indexOf(sortColumn),
                sort : sortColumn.sort.by || []
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (
            (nextProps.name !== this.props.name) || 
            (nextProps.positions !== this.props.positions) || 
            (nextProps.team !== this.props.team) ||
            (nextProps.watchlist !== this.props.watchlist)
        ) {

            

            this.updateFilters(nextProps);
            this.setState({ page: 1})
        }

        if (nextProps.columns !== this.props.columns) {
            const sortColumn = nextProps.columns.find(c => c.sort && c.sort.default);
            if (sortColumn) {
                this.setState({
                    activeColumnIndex : nextProps.columns.indexOf(sortColumn)
                });
            }
        }
    }

    sortByColumn(index) {
        
        const column = this.props.columns[index];

        if (index === this.state.activeColumnIndex) {
            this.setState({
                sort : this.state.sort.map(s => {
                    return {
                        ...s,
                        direction : s.direction === 'asc' ? 'desc' : 'asc'
                    }
                })
            })
        }
        else {
            this.setState({
                activeColumnIndex : index,
                sort : column.sort.by || []
            })
        }
        
        this.setState({ page: 1})
    }

    render() {
        const child = React.Children.only(this.props.children);

        if (this.props.editMode) {
            return  React.cloneElement(child, { items : [{}, {}, {}] });
        }
        
        return (
            <WithTournamentStatistics round={ this.props.round }>{ ({ items }) => (
                
                <Filter items={ items } conditions={ this.state.filters }>{ ({ items : filteredItems }) => {
                    return React.cloneElement(child, { datasource : filteredItems, filters : this.state.filters });
                }}</Filter>
            )}</WithTournamentStatistics>
        )
    }
}

PlayerStatisticsDataGrid.propTypes = {
    round : PropTypes.number,
    name : PropTypes.string,
    team : PropTypes.string,
    positions : PropTypes.array,
    watchlist : PropTypes.bool,
    columns : PropTypes.array.isRequired,
    pageSize : PropTypes.number.isRequired
}
  
PlayerStatisticsDataGrid.defaultProps = {
    pageSize : 25
}

export default PlayerStatisticsDataGrid;