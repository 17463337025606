import React from 'react'

import { Box, RegularText, LargeText } from 'components'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            message : null,
            showDetails : false
        };
    }
  
    componentDidCatch(error, info) {

        this.setState({ 
            hasError: true,
            message : error + ''
        });

        if (!!window.rg4js) {
            rg4js('send', error);
            rg4js('customTags', info);
        }
    }
  
    render() {
        if (this.state.hasError) {
            return  <Box bg="base750" minHeight="192px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
                        <LargeText>⚠️</LargeText>
                        <RegularText fontWeight="bold">
                            Whoops - an error occurred.<br />
                            You could try refreshing the page.<br />
                            <br />
                            <small><a onClick={ () => this.setState({ showDetails : true })}>Show details</a></small>
                        </RegularText>

                        { 
                            this.state.showDetails &&
                            <pre>
                                Error message : { this.state.message }
                            </pre>
                        }
                    </Box>
        } else {
            return this.props.children ;
        }

    }
}

export default ErrorBoundary;