import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import { tournamentSchema } from '../../schemas'

import * as types from './types'

const defaultState = {
    byId : {},
    standingsById : {}
};

export default function tournaments (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_TOURNAMENT_REQUEST:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_TOURNAMENT_SUCCESS:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_TOURNAMENT_FAILURE:
            return { 
                ...state,
                byId : {
                    ...state.byId,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_TOURNAMENT_STANDINGS_REQUEST:
            return { 
                ...state,
                standingsById : {
                    ...state.standingsById,
                    [action.id] : {
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_TOURNAMENT_STANDINGS_SUCCESS:

            return { 
                ...state,
                standingsById : {
                    ...state.standingsById,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false,
                        items : action.payload.result
                    }
                }
            };
        case types.FETCH_TOURNAMENT_STANDINGS_FAILURE:
            return { 
                ...state,
                standingsById : {
                    ...state.standingsById,
                    [action.id] : {
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };            
        default:
            return state
    }
}


