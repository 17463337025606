import axios from 'axios'
import { normalize } from 'normalizr'

import { competitionSchema, leaderboardSchema,fantasyTeamSchema, groupSchema  } from '../../schemas';
import auth from '../../common/auth'

import * as types from './types';

/* Fetch User Competition Leaderboards */

const fetchGameFantasyTeamsRequest = (gameId) => ({ type: types.FETCH_GAME_FANTASYTEAMS_REQUEST, gameId });
const fetchGameFantasyTeamsSuccess = (gameId, payload) => ({ type: types.FETCH_GAME_FANTASYTEAMS_SUCCESS, gameId, payload });
const fetchGameFantasyTeamsFailure = (gameId) => ({ type: types.FETCH_GAME_FANTASYTEAMS_FAIL, gameId });

const fetchGameFantasyTeams = (gameId) => (dispatch) => { 
    dispatch(fetchGameFantasyTeamsRequest(gameId));

    const accessToken = auth.getAccessToken();
    return axios.get(`/games/${ gameId }/users/me/fantasyteams?access_token=${ accessToken }`).then(response => {
        dispatch(fetchGameFantasyTeamsSuccess(gameId, normalize(response.data, [fantasyTeamSchema])));
    }, e => {
        dispatch(fetchGameFantasyTeamsFailure(gameId));
    });
}

const shouldFetchGameFantasyTeams = (state, gameId) => {
    const { isFetching, didInvalidate, items } = state.fantasyTeam.byGame[gameId] || { isFetching : false, didInvalidate : false, items : null};

    if (isFetching) {
        return false;
    }
    else if (!items) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const fetchGameFantasyTeamsIfNeeded = (gameId) => (dispatch, getState) => {
    if (shouldFetchGameFantasyTeams(getState(), gameId)) {
        dispatch(fetchGameFantasyTeams(gameId));
    }
}


/* Fetch lineup */

const fetchLineupRequest = (fantasyTeamId) => ({ type: types.FETCH_LINEUP_REQUEST, fantasyTeamId });
const fetchLineupSuccess = (fantasyTeamId, payload) => ({ type: types.FETCH_LINEUP_SUCCESS, fantasyTeamId, payload });
const fetchLineupFailure = (fantasyTeamId) => ({ type: types.FETCH_LINEUP_FAIL, fantasyTeamId });

export const fetchLineup = (fantasyTeamId) => (dispatch) => { 
    dispatch(fetchLineupRequest(fantasyTeamId));

    const accessToken = auth.getAccessToken();
    return axios.get(`/fantasyteams/${ fantasyTeamId }/lineup?access_token=${ accessToken }`).then(response => {
        
        dispatch(fetchLineupSuccess(fantasyTeamId, response.data));
    }, e => {
        dispatch(fetchLineupFailure(fantasyTeamId));
    });
}
