import axios from 'axios'
import { normalize } from 'normalizr'

import auth from '../../common/auth'

import * as types from './types';

/* Fetch User Competition Leaderboards */

const fetchResourcePermissionsRequest = (resource) => ({ type: types.FETCH_RESOURCE_PERMISSIONS_REQUEST, resource });
const fetchResourcePermissionsSuccess = (resource, payload) => ({ type: types.FETCH_RESOURCE_PERMISSIONS_SUCCESS, resource, payload });
const fetchResourcePermissionsFailure = (resource) => ({ type: types.FETCH_RESOURCE_PERMISSIONS_FAIL, resource });

const fetchResourcePermissions = (resource) => (dispatch) => { 
    dispatch(fetchResourcePermissionsRequest(resource));

    const accessToken = auth.getAccessToken();

    return axios.get(`/permissions/resources/${ resource }?access_token=${ accessToken }`).then(response => {
        dispatch(fetchResourcePermissionsSuccess(resource, response.data));
    }, e => {
        dispatch(fetchResourcePermissionsFailure(resource));
    });
}


const shouldFetchResourcePermissions = (state, resource) => {

    const { isFetching, didInvalidate, items } = state.permission.resources[resource] || { isFetching : false, didInvalidate : false, items : null};

    if (isFetching) {
        return false;
    }
    else if (!items) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const fetchResourcePermissionsIfNeeded = (resource) => (dispatch, getState) => {
    if (shouldFetchResourcePermissions(getState(), resource)) {
        dispatch(fetchResourcePermissions(resource));
    }
}
