import React from 'react'

import {
    Dialog, DialogHeader, DialogContent, DialogFooter,
    XButton
} from 'components'
import { useTranslator } from '../../hooks/useTranslator'


const ConfirmationDialog = ({ open, title, text, onClose, onCancel, onConfirm }) => {
    const t = useTranslator();
    const handleCancel = () => {
        if (onCancel)
            onCancel();
        if (onClose)
            onClose();
    }

    const handleConfirm = () => {
        if (onConfirm)
            onConfirm();
        if (onClose)
            onClose();
    }

    return (
        <Dialog open={ open } onClose={ handleCancel }>
            <DialogHeader>{ title }</DialogHeader>
            <DialogContent>
                { text }
            </DialogContent>
            <DialogFooter>
                <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ handleCancel }>{ t('common.cancel_caption') }</XButton>
                <XButton variant="primary" size="s" textTransform="uppercase" onClick={ handleConfirm }>{ t('common.ok_caption') }</XButton>
            </DialogFooter>

        </Dialog>
    )    

}

export default ConfirmationDialog