import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { exp } from 'utils';
import PlayerIdentity from '../../PlayerIdentity'
import DataContext from '../../../components/DataContext'

const PlayerIdentityWidget = ({ editMode, form, property, watchlistProperty }) => {
    
     

    if (editMode) {

        const dummy = {
            person : {
                firstName : 'Firstname',
                lastName : 'Lastname'
            },
            position : {
                name : 'Position',
                abbreviation : 'Pos'
            },
            team : {
                name : 'Team',
                abbreviation : 'TEM'
            }
        }

        
        return <PlayerIdentity form={ form || 'auto' } 
                                player={ dummy } 
                                watchlist={ true }/>;
    }
    
    const context = useContext(DataContext);

    const value = exp(property, context);
    const watchlist = exp(watchlistProperty, context);
    
    return  <PlayerIdentity form={ form } player={ value } watchlist={ watchlist }/>;
}

PlayerIdentityWidget.propTypes = {
    property : PropTypes.string.isRequired,
    watchlistProperty : PropTypes.string.isRequired,
    form : PropTypes.string.isRequired,
}

PlayerIdentityWidget.defaultProps = {
    property : 'player',
    watchlistProperty : 'watchlist',
    form : 'auto'
}

export default PlayerIdentityWidget;