import React from 'react';
import PropTypes from 'prop-types'

const Text = ({ value }) => {
    return value + '';
}

Text.propTypes = {
    value : PropTypes.string.isRequired
}

Text.defaultProps = {
    value : ''
}

export default Text;