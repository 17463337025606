import React from 'react';
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { ComponentEditor } from 'components'


export const Image = styled.img`
    max-width: 100%;
    ${ props => props.height && `height : ${ props.height }px` }
    ${ props => props.width && `width : ${ props.width }px` }
    ${ props => props.verticalAlign && `vertical-align : ${ props.verticalAlign }` }
    ${ props => props.margin && `margin : ${ props.margin }` }
    
`




Image.propTypes = {
    src : PropTypes.string.isRequired,
    height : PropTypes.number,
    width : PropTypes.number,
    verticalAlign : PropTypes.string,
    margin : PropTypes.string
}

Image.defaultProps = {
}


export const ImageEditor = ({ ...otherProps}) => {  
    return (
            <Image {...otherProps }/>
    )

}

