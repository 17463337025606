import React from 'react';
import PropTypes from 'prop-types'

import { Toolbar } from 'components'
import LocalizeText from '../../LocalizeText';


export const ToolbarItemWidget = () => null;



export const ToolbarWidget = ({ children, ...otherProps }) => {
    
    const items = React.Children.map(children, (child, index) => { 
        const { children, ...otherProps } = child.props;

        return <Toolbar.Item key={ index } { ...otherProps }>
                    { children }
                </Toolbar.Item>
    });

    return <Toolbar { ...otherProps}>
                { items }
           </Toolbar>

}