import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Head } from 'components';
import { lo } from 'utils';
import GameContext from '../../GameContext'
import { useTitle } from '../../../hooks/useTitle'



 const Page = ({ title, description, keywords, children }) => {
     const { game } = useContext(GameContext);
     const [_, setTitle] = useTitle();



     useEffect(() => { setTitle(title), [title] });
    
     const siteName = window.swush.site && window.swush.site.name;
     const fTitle = game ?
        `${ title } - ${ lo(game, 'name') } - ${ siteName }` :
        `${ title } - ${ siteName }`;


        
    return (
        <React.Fragment>
            <Head>
                { fTitle && <title>{ fTitle }</title> }
                { description && <meta name="description" content={ description } /> }
                { keywords && <meta name="keywords" content={ keywords } /> }
            </Head>

            { children }
        </React.Fragment>
    );
}

Page.propTypes = {
    title : PropTypes.string,
    description : PropTypes.string,
    keywords : PropTypes.string
}

export default Page;