import React from 'react';
import PropTypes from 'prop-types'
import PlayerIdentity from '../../PlayerIdentity'

const PlayerIdentityWidget = ({ editMode, form, hideTeam, hidePosition, value, watchlist }) => {
    

    if (editMode) {

        const dummy = {
            person : {
                firstName : 'Firstname',
                lastName : 'Lastname'
            },
            position : {
                name : 'Position',
                abbreviation : 'Pos'
            },
            team : {
                name : 'Team',
                abbreviation : 'TEM'
            }
        }

        
        return <PlayerIdentity form={ form } 
                                hideTeam={ hideTeam } 
                                hidePosition={ hidePosition} 
                                player={ dummy } 
                                watchlist={ watchlist }/>;
    }
    
    
    
    return <PlayerIdentity form={ form } hideTeam={ hideTeam } hidePosition={ hidePosition} player={ value } watchlist={ watchlist }/>;
}

PlayerIdentityWidget.propTypes = {
    editMode : PropTypes.bool,
    value : PropTypes.object,
    watchlist : PropTypes.bool,
    hideTeam : PropTypes.bool,
    hidePosition : PropTypes.bool,
    form : PropTypes.string.isRequired,
}

PlayerIdentityWidget.defaultProps = {
    form : 'auto'
}

export default PlayerIdentityWidget;