// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--applied-filters-item-background-color: rgba(255, 255, 0, 0.1);--applied-filters-item-icon-color: rgba(255, 255, 0, 0.5);--applied-filters-item-icon-hover-color: #ff0}.UKKqZ0uMEmM3ZnS9m7NJ{display:flex;justify-content:flex-end;flex-wrap:wrap}.rMc4HuWM8kZ4KumaY8Bt{background-color:var(--applied-filters-item-background-color);height:36px;border-radius:18px;line-height:36px;padding:0 6px 0 18px;margin-left:12px;margin-bottom:12px;white-space:nowrap;font-size:11px;font-style:italic;cursor:pointer}.rMc4HuWM8kZ4KumaY8Bt:hover .nf93ZAe15hgUE7Iq4aT_{color:var(--applied-filters-item-icon-hover-color)}.nf93ZAe15hgUE7Iq4aT_{color:var(--applied-filters-item-icon-color)}`, "",{"version":3,"sources":["webpack://./src/components/Filterbar.scss"],"names":[],"mappings":"AAAA,MACI,+DAAA,CACA,yDAAA,CACA,6CAAA,CAGJ,sBACI,YAAA,CACA,wBAAA,CACA,cAAA,CAGJ,sBACI,6DAAA,CAEA,WAAA,CACA,kBAAA,CACA,gBAAA,CAEA,oBAAA,CACA,gBAAA,CAEA,kBAAA,CAEA,kBAAA,CACA,cAAA,CACA,iBAAA,CAEA,cAAA,CAEA,kDACI,kDAAA,CAIR,sBACI,4CAAA","sourcesContent":[":root {\r\n    --applied-filters-item-background-color: rgba(255, 255, 0, 0.1);\r\n    --applied-filters-item-icon-color: rgba(255, 255, 0, 0.5);\r\n    --applied-filters-item-icon-hover-color: #ff0;\r\n}\r\n\r\n.container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.item {\r\n    background-color: var(--applied-filters-item-background-color);\r\n\r\n    height: 36px;\r\n    border-radius: 18px;\r\n    line-height: 36px;\r\n\r\n    padding: 0 6px 0 18px;\r\n    margin-left: 12px;\r\n    \r\n    margin-bottom: 12px;\r\n\r\n    white-space: nowrap;\r\n    font-size: 11px;\r\n    font-style: italic;\r\n\r\n    cursor: pointer;\r\n\r\n    &:hover .icon {\r\n        color: var(--applied-filters-item-icon-hover-color);    \r\n    }\r\n}\r\n\r\n.icon {\r\n    color: var(--applied-filters-item-icon-color);\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `UKKqZ0uMEmM3ZnS9m7NJ`;
export var item = `rMc4HuWM8kZ4KumaY8Bt`;
export var icon = `nf93ZAe15hgUE7Iq4aT_`;
export default ___CSS_LOADER_EXPORT___;
