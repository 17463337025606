import React, { useContext }  from 'react'

import DataContext from './DataContext'

const DataContextProvider = ({ root, value, children }) => {
    const dataContext = useContext(DataContext)

    if (root) {
        return (
            <DataContext.Provider value={{ ...dataContext, ...value }}>
                { children }
            </DataContext.Provider>
        )
    }
    else {
        return (
            <DataContext.Provider value={{ ...dataContext, current : value }}>
                { children }
            </DataContext.Provider>
        )
    }
}

export default DataContextProvider;