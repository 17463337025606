import React from 'react';
import PropTypes from 'prop-types';


import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { currency, number, minute } from 'utils'

import PlayerIdentity from '../PlayerIdentity'
import LocalizeText from '../LocalizeText'


const Currency = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ currency(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const Minute = ({ value }) => {
    if (typeof value === 'undefined' || value === 0)
        return <span className="meta">-</span>

    else return <span>{ minute(value) }</span>;
}


const PlayerList = ({ heading, items }) => (
    <React.Fragment>
        <Table.Head>
            <Table.Row>
                <Table.Header><LocalizeText text={ heading } /></Table.Header>
            </Table.Row>
        </Table.Head>
        
        <Table.Body>
            { 
                items.map((item, index) => {
                    return (
                        <Table.Row key={ index }>
                            <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                        </Table.Row>
                    )
                })
            }
        </Table.Body>
    </React.Fragment>
)


const HockeyLineup = ({ items, type }) => {

    const trainers = items.filter( p => p.participant.type == type && p.player.position.slug === 'hockey_trainer');
    const goalies = items.filter( p => p.participant.type == type && p.player.position.slug === 'hockey_goalkeeper');
    const players = items.filter( p => p.participant.type == type && p.player.position.slug !== 'hockey_trainer' && p.player.position.slug !== 'hockey_goalkeeper').sort((a, b) => a.player.position.order - b.player.position.order);

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  trainers.length > 0 && <PlayerList items={ trainers } heading="@hockey.coaches" /> }
                    {  goalies.length > 0 && <PlayerList items={ goalies } heading="@hockey.goalies" /> }
                    {  players.length > 0 && <PlayerList items={ players } heading="@hockey.players" /> }

                  
                </Table>

            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                {  trainers.length > 0 &&
                    
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right><LocalizeText text="@common.growth" /></Table.Header>
                                <Table.Header colSpan={ 6 }></Table.Header>
                            </Table.Row>
                        </Table.Head>

                        <Table.Body>
                            { 
                                trainers.map((item, index) => {

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell colSpan={ 6 }></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                {  
                    goalies.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right><LocalizeText text="@common.growth" /></Table.Header>
                                <Table.Header center colSpan={ 3 }><LocalizeText text="@hockey.saves" /></Table.Header>
                                <Table.Header center colSpan={ 3 }><LocalizeText text="@hockey.goalsAgainst" /></Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                goalies.map((item, index) => {
                                    const saves = item.stats.saves || 0 + item.stats.save || 0;
                                    const goalsAgainst = item.stats.goalsAgainst || 0 + item.stats.goalAgainst || 0;

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Number value={ item.growth } /></Table.Cell>
                                            <Table.Cell center colSpan={ 3 }><Number value={ saves } /></Table.Cell>
                                            <Table.Cell center colSpan={ 3 }><Number value={ goalsAgainst } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                 {  
                    players.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right><LocalizeText text="@common.growth" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.goals" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.assists" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.plusMinus" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.sog" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.twoMin" /></Table.Header>
                                <Table.Header center><LocalizeText text="@hockey.major" /></Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                players.map((item, index) => {
                                    const goals = item.stats.goal;
                                    const assists = item.stats.assists || 0 + item.stats.assist || 0;
                                    const sogs = item.stats.shots || 0 + item.stats.shotOnGoal || 0;
                                    const plusMinus = item.stats.plusMinus || 0 +
                                        (item.stats.positive || 0 - item.stats.negative || 0);
                                    const minor = item.stats.minorPenalty || 0 + item.stats['suspension/Minor'] || 0;
                                    
                                    const major = item.stats.majorPenalty || 0 + item.stats['suspension/Major'] || 0;
                                    

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Number value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ goals } /></Table.Cell>
                                            <Table.Cell center><Number value={ assists } /></Table.Cell>
                                            <Table.Cell center><Number value={ plusMinus } /></Table.Cell>
                                            <Table.Cell center><Number value={ sogs } /></Table.Cell>
                                            <Table.Cell center><Number value={ minor } /></Table.Cell>
                                            <Table.Cell center><Number value={ major } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }
                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const HockeyLineupWidget = ({ type }) => {
    return (
        <MatchContext.Consumer>{ (match) => {

            return (
                <WithMatchEvents id={ match.id }>{ ({ events }) => (
                    <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                        <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {

                            
                            return (
                                <React.Fragment>
                                    <HockeyLineup items={ items } type={ type } />
                                </React.Fragment>
                            )
                        }}</WithMatchReport>
                    )}</WithMatchFantasyEvents>
                )}</WithMatchEvents>
            )
        }}</MatchContext.Consumer>
        
    )
}


HockeyLineupWidget.propTypes = {
    type : PropTypes.string.isRequired
}

export default HockeyLineupWidget;