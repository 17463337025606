import React from 'react'
import classNames from 'classnames'

import * as styles from './Pill.module.css'

const Pill = ({ children, variant = 'default', size = 'm' }) => {
    
    const className = classNames(
        styles.pill,
        {
            [styles.action] : variant == 'action'
        }
    )

    
    return (
        <div className={ className }>
            { children }
        </div>
    )
}


export default Pill;