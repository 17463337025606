// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OhLoZN6PDwgCsA6Ihibb{position:absolute;top:0;right:0;bottom:0;left:0}.Qa88aduOj648diQDYr55{position:absolute;top:0;right:0;bottom:0;left:0}`, "",{"version":3,"sources":["webpack://./src/components/Vignette.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CAEA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CAGD,sBACC,iBAAA,CAEA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA","sourcesContent":[".container {\r\n\tposition: absolute;\r\n\t\r\n\ttop: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\tleft: 0;\r\n}\r\n\r\n.vignette {\r\n\tposition: absolute;\r\n\t\r\n\ttop: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\tleft: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `OhLoZN6PDwgCsA6Ihibb`;
export var vignette = `Qa88aduOj648diQDYr55`;
export default ___CSS_LOADER_EXPORT___;
