import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { PersonName, ExternalLink } from '../components';

import GameContext from './GameContext'

import { selectPlayer } from '../redux/ui/actions'
import WithFlyout from './WithFlyout'
import GameNavigationService from './GameNavigationService'

class PlayerLink extends React.PureComponent{
    constructor(props) {
        super(props);
    }

    render() {
        const { form, player } = this.props;

        return <GameNavigationService>{ ({ showPlayer }) => {
            return (
                <a onClick={ (e) => {
                    const tr = e.currentTarget.closest('tr');
                    let earnings = 0;
                    if (tr) {
                        try {
                            earnings = parseInt(tr.getAttribute('earnings'));
                        }
                        catch {
                            // Ignore
                        }
                    }

                    showPlayer(player.id, player.person.slug, earnings) 
                }}><PersonName form={ form } person={ player.person } /></a>
            )
        }}</GameNavigationService>
    }  
}

PlayerLink.propTypes = {
    player : PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
}

PlayerLink.defaultProps = {
    form : 'auto'
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        show : () => {
            dispatch(selectPlayer(ownProps.game.id, ownProps.player.id, ownProps.game.theme));
        }
    }
}

export default connect(null, mapDispatchToProps)(PlayerLink);
