import React, { useMemo } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'

const Separator = styled.div`
    height: 0;
    border-top: 1px solid ${ props => props.theme.colors.borderUp };
    ${space}
`

export default Separator;