import React, { useContext } from 'react';

import MatchContext from '../../MatchContext'

import GameContext from '../../GameContext'
import useOdds from '../../../hooks/useOdds'

import { Odds } from 'components'
import { format } from 'utils'

const OddsWidget = () => {

    const { gameConfiguration } = useContext(GameContext);
    const match = useContext(MatchContext);
    const odds = useOdds(gameConfiguration.odds && gameConfiguration.odds.feed);

    const participants = match.participants.reduce((agg, p) => { agg[p.type] = p.team; return agg }, {});

    const home = participants['home'];
    const away = participants['away'];

    if (!home || !away)
        return null;

    
    const mOdds = odds.find(match.start, home.name, away.name);
    if (!mOdds)
        return null;
        
    const link = gameConfiguration.odds && gameConfiguration.odds.link;




    const o1 = mOdds['1'];
    const ox = mOdds['x'];
    const o2 = mOdds['2'];

    const link1 = link && format(link, o1);
    const linkx = link && format(link, ox);
    const link2 = link && format(link, o2);

    return (
        <div style={{ backgroundColor : gameConfiguration.odds && gameConfiguration.odds.backgroundColor, padding: '0 12px', height: '48px', display: 'flex', alignItems: 'center' }}>
            <div style={{ "display": "flex", "flexDirection": "row", "alignItems": "center", "justifyContent": "space-between", "width": "100%" }}>
                <img src={ gameConfiguration.odds && gameConfiguration.odds.imageUrl  } style={{ height: '24px' }}/>
                <Odds>
                    <Odds.Outcome type="1" odds={ o1.odds } url={ link1 }/>
                    <Odds.Outcome type="X" odds={ ox.odds } url={ linkx } />
                    <Odds.Outcome type="2" odds={ o2.odds } url={ link2 } />
                </Odds>
            </div>
        </div>
    )
}


OddsWidget.propTypes = {
}

OddsWidget.defaultProps = {
}

export default OddsWidget;