import React from 'react'
import PropTypes from 'prop-types'

import RoundBadge from './RoundBadge'

import * as styles from './GameIdentity.scss'

const GameIdentity = ({ title, variant, subtitle, round, statusHeading, statusSubheading, countdown, themeName, sponsorImageUrl }) =>{
    return (
        <div className={ styles.container }>
            <div className={ styles.content }>
                <div className={ styles.left }>

                    <div>
                        <div className={ styles.sponsor }>
                            {
                                sponsorImageUrl &&
                                <img src={ sponsorImageUrl } />
                            }
                        </div>

                        <div className={ styles.title }>
                            { title }
                            <span className={ styles.variant }>{ variant }</span>
                        </div>
                        <div className={ styles.text }>{ subtitle }</div>
                    </div>
                </div>

                <div>

                    <div className={ styles.status }>
                        <div className={ styles.text }>{ statusHeading }</div>
                        <div className={ styles.countdown }>{ countdown }</div>
                        <div className={ styles.text }>{ statusSubheading }</div>
                    </div>

                </div>

                <div>
                    <RoundBadge themeName={ themeName }>{ round }</RoundBadge>
                </div>
            </div>
        </div>

    )
}

GameIdentity.propTypes = {
    sponsorImageUrl: PropTypes.string,
    phase : PropTypes.object
}

export default GameIdentity;