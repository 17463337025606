import React, { useContext } from 'react'
import PropTypes from 'prop-types'

const AppConfigContext = React.createContext();


export const AppConfigProvider = ({ config, children }) => {
    return (
        <AppConfigContext.Provider value={ config }>
            { children }
        </AppConfigContext.Provider>
    )
}



export const useAppConfig = () => {
    return useContext(AppConfigContext);
}