import {  useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchMessageBoardIfNeeded, markMessageBoardRead, subscribe, unsubscribe } from '../../redux/messageBoard/actions';
import { makeGetUnreadMessageBoardPosts  } from '../../redux/messageBoard/selectors';

const useMessageBoard = (messageBoardId) => {
    const dispatch = useDispatch();

    const getUnreadMessageBoardPosts = useMemo(() => makeGetUnreadMessageBoardPosts(), []);

    const unread = useSelector(state => {
        return messageBoardId && getUnreadMessageBoardPosts(state, messageBoardId) || 0
    });

    useEffect(() => {
        if (messageBoardId) {
            dispatch(fetchMessageBoardIfNeeded(messageBoardId));
            dispatch(subscribe(messageBoardId));
            
            return () => {
                dispatch(unsubscribe(messageBoardId));
            }
        }
    }, [messageBoardId])

    const markRead = () => {
        dispatch(markMessageBoardRead(messageBoardId));
    }

    const role = '';
    return [unread, role, markRead];
}


export default useMessageBoard;