import axios from 'axios'
import { normalize } from 'normalizr'
import { gameSchema } from '../../schemas'

import * as types from './types';


/* Catalog */

const requestCatalog = () => ({ type: types.FETCH_CATALOG_REQUEST })
const receiveCatalog = (payload) => ({ type: types.FETCH_CATALOG_SUCCESS, payload  })
const fetchCatalog = () => (dispatch) => {
    dispatch(requestCatalog())
    
    axios.get('catalog/games').then(response => {
        dispatch(receiveCatalog(normalize(response.data, [gameSchema])));
    });
    
}

const shouldFetchCatalog = (state) => {
    const game = state.games

    if (!game.catalog.items) {
        return true;
    } else if (game.catalog.isFetching) {
        return false;
    } else {
        return game.catalog.didInvalidate;
    }
}

export const fetchCatalogIfNeeded = () => {
    return (dispatch, getState) => {
        if (shouldFetchCatalog(getState())) {
            return dispatch(fetchCatalog())
        }
    }
}

/* Selected Game */

export const selectGame = (id) => ({ type: types.SELECT_GAME, id : id })
export const selectRound = (round) => ({ type: types.SELECT_ROUND, round })

const fetchGameRequest = (id) => ({ type: types.FETCH_GAME_REQUEST, id })
const fetchGameSuccess = (payload) => ({ type: types.FETCH_GAME_SUCCESS, payload })



export const fetchGame = (id) => (dispatch) => new Promise((resolve, reject) => {
    dispatch(fetchGameRequest(id))
    
    axios.get(`catalog/games/${id}?v=3`).then(response => {
        dispatch(fetchGameSuccess(normalize(response.data, gameSchema)));
        resolve(response.data);
    }, e => {
        reject(e);
    })
/*
    const promise = axios.get(`catalog/games/${id}`);
    promise.then(response =>  {
        dispatch(fetchGameSuccess(normalize(response.data, gameSchema)));
    });

    return promise;*/
});

const shouldFetchGame = (state, id) => {
    const { byId } = state.games || { byId : {} };
    const item = byId[id];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
}

export const fetchGameIfNeeded = (id) => (dispatch, getState) => {

    if (shouldFetchGame(getState(), id)) {
        dispatch(fetchGame(id));
    }
}


