import React from 'react'

import * as styles from './AlertBox.module.css'

import classNames from 'classnames'

const AlertBox = ({ type, children }) => {
    
    const className = classNames(
        styles.container,
        {
            [styles.warning] : type == 'warning',
            [styles.success] : type == 'success',
            [styles.error] : type == 'error',
            [styles.info] : type == 'info'
        }
    )

    return <div className={ className }>{ children }</div>
}

AlertBox.propTypes = {
}

AlertBox.defaultProps = {
    type : 'error'
}

export default AlertBox