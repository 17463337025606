import React from 'react';

import { MatchHead, FactList, FactListItem, EventDate } from 'components';

import translatable from '../../hocs/translatable';
import MatchContext from '../MatchContext';

const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}

const GolfMatchHead = ({ t }) => {
    return (
        <MatchContext.Consumer>{ (match) => { 
            const category = parseInt((match.properties || {}).tournamentCategory || '0');
            const par = parseInt((match.properties || {}).tournamentPar || '0');
            const prize = (match.properties || {}).tournamentPrize;

            return (
                <MatchHead backgroundImageUrl={ match.location.imageUrl }>
                    <MatchHead.Simple>
                        <FactList large>
                            <FactListItem value={ match.location.name } label={ t('golf.venue') } />
                            <FactListItem value={ <EventDate date={ match.start } format="short" /> } label={ t('golf.firstTeeOff') } />
                        </FactList>

                        <FactList>
                            <FactListItem value={ category } label={ t('golf.class') } />
                            <FactListItem value={ par } label={ t('golf.par') } />
                            <FactListItem value={ prize } label={ t('golf.prize') } />
                        </FactList>
                    </MatchHead.Simple>
                </MatchHead>
            )
        }}</MatchContext.Consumer>
    )
}

export default translatable(GolfMatchHead);