import axios from 'axios'
import { normalize } from 'normalizr';

import { viewSchema } from '../../schemas';

import config from 'config'
import auth from '../../common/auth'
import * as types from './types';

const fetchCompiledViewByNameRequest = (name) => ({ type: types.FETCH_COMPILED_VIEW_BY_NAME_REQUEST, name })
const fetchCompiledViewByNameSuccess = (name, payload) => ({ type: types.FETCH_COMPILED_VIEW_BY_NAME_SUCCESS, name, payload })
const fetchCompiledViewByNameFailure = (name) => ({ type: types.FETCH_COMPILED_VIEW_BY_NAME_FAILURE, name })

const fetchCompiledViewByName = (name) => (dispatch) => {
    dispatch(fetchCompiledViewByNameRequest(name))

    return axios.get(`${ config.configBaseUrl }/views/compiled/${ name }.json`).then(response => {
        dispatch(fetchCompiledViewByNameSuccess(name, response.data));
    }, error => {
        dispatch(fetchCompiledViewByNameFailure(name));
    });
}


const shouldFetchCompiledViewByName = (state, name) => {
    const { compiled } = state.views || { compiled : {} };
    const item = compiled[name];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
}
  
export const fetchCompiledViewByNameIfNeeded = (name) => (dispatch, getState) => new Promise((resolve, reject) => {
    if (shouldFetchCompiledViewByName(getState(), name)) {
        dispatch(fetchCompiledViewByName(name)).then((response) => {
            resolve(response);
        });
    }
})


/* */


const fetchViewByNameRequest = (name) => ({ type: types.FETCH_VIEW_BY_NAME_REQUEST, name })
const fetchViewByNameSuccess = (name, payload) => ({ type: types.FETCH_VIEW_BY_NAME_SUCCESS, name, payload })
const fetchViewByNameFailure = (name) => ({ type: types.FETCH_VIEW_BY_NAME_FAILURE, name })

const fetchViewByName = (name) => (dispatch) => {
    dispatch(fetchViewByNameRequest(name))

    axios.get(`/views/${ name }`).then(response => {
        dispatch(fetchViewByNameSuccess(name, normalize(response.data, viewSchema)));
    }, error => {
        dispatch(fetchViewByNameFailure(name));
    });
}


const shouldFetchViewByName = (state, name) => {
    const { byName } = state.views || { byName : {} };
    const item = byName[name];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
}
  
export const fetchViewByNameIfNeeded = (name) => (dispatch, getState) => {
    if (shouldFetchViewByName(getState(), name)) {
        dispatch(fetchViewByName(name));
    }
}


/* */


const fetchViewRequest = (id) => ({ type: types.FETCH_VIEW_REQUEST, id })
const fetchViewSuccess = (id, payload) => ({ type: types.FETCH_VIEW_SUCCESS, id, payload })
const fetchViewFailure = (id) => ({ type: types.FETCH_VIEW_FAILURE, id })

const fetchView = (id) => (dispatch) => {
    dispatch(fetchViewRequest(id));

    const accessToken = auth.getAccessToken();
    const url = `/views/${ id }?access_token=${ accessToken }`;

    axios.get(url).then(response => {
        dispatch(fetchViewSuccess(id, normalize(response.data, viewSchema)));
    }, error => {
        dispatch(fetchViewFailure(id));
    });
}


const shouldFetchView = (state, id) => {
    const { byId } = state.views || { byId : {} };
    const item = byId[id];

    if (!item) {
        return true;
    }
    else if (item.isFetching) {
        return false;
    }
    else {
        return item.didInvalidate;
    }
}
  
export const fetchViewIfNeeded = (id) => (dispatch, getState) => {
    if (shouldFetchView(getState(), id)) {
        dispatch(fetchView(id));
    }
}

/* */



const fetchPrecompiledViewsRequest = (group) => ({ type: types.FETCH_PRECOMPILED_VIEWS_REQUEST, group })
const fetchPrecompiledViewsSuccess = (group, payload) => ({ type: types.FETCH_PRECOMPILED_VIEWS_SUCCESS, group, payload })
const fetchPrecompiledViewsFailure = (group) => ({ type: types.FETCH_PRECOMPILED_VIEWS_FAILURE, group })

const fetchPrecompiledViews = (group) => (dispatch) => {
    dispatch(fetchPrecompiledViewsRequest(group));

    const url = `/views/precompiled/${ group }`;

    axios.get(url).then(response => {
        dispatch(fetchPrecompiledViewsSuccess(group, normalize(response.data, [viewSchema])));
    }, error => {
        dispatch(fetchPrecompiledViewsFailure(group));
    });
}


const shouldFetchPrecompiledViews = (state, group) => {
    const { precompiled } = state.views || { precompiled : { } };
    

    if (!precompiled[group]) {
        return true;
    }
    if (!precompiled[group].items) {
        return true;
    }
    else if (precompiled[group].isFetching) {
        return false;
    }
    else {
        return precompiled[group].didInvalidate;
    }
}
  
export const fetchPrecompiledViewsIfNeeded = (group) => (dispatch, getState) => {
    
    if (shouldFetchPrecompiledViews(getState(), group)) {
        dispatch(fetchPrecompiledViews(group));
    }
}

/* */


const updateViewRequest = (id) => ({ type: types.UPDATE_VIEW_REQUEST, id })
const updateViewSuccess = (id, payload) => ({ type: types.UPDATE_VIEW_SUCCESS, id, payload })
const updateViewFailure = (id) => ({ type: types.UPDATE_VIEW_FAILURE, id })

export const updateView = (id, view) => (dispatch) => new Promise((resolve, reject) => {
    dispatch(updateViewRequest(id));

    const accessToken = auth.getAccessToken();
    const url = `/views/${ id }?access_token=${ accessToken }`;

    axios.put(url, view).then(response => {
        resolve();
        dispatch(updateViewSuccess(id, view));
    }, error => {
        dispatch(updateViewFailure(id));
        reject();
    });
})

/* */

const fetchViewsRequest = (query, page, pageSize) => ({ type: types.FETCH_VIEWS_REQUEST, query, page, pageSize })
const fetchViewsSuccess = (query, page, pageSize, count, payload) => ({ type: types.FETCH_VIEWS_SUCCESS, query, page, pageSize, count, payload })
const fetchViewsFailure = (query, page, pageSize) => ({ type: types.FETCH_VIEWS_FAILURE, query, page, pageSize })

export const fetchViews  = (query, page, pageSize) => (dispatch) => {
    dispatch(fetchViewsRequest(query, page, pageSize))

    const accessToken = auth.getAccessToken();
    const url = `/views?query=${ query }&page=${ page }&pageSize=${ pageSize }&access_token=${ accessToken }`;

    axios.get(url).then(response => {
        const count = parseInt(response.headers['x-pagination-count']);

        dispatch(fetchViewsSuccess(query, page, pageSize, count, response.data));
    }, error => {
        dispatch(fetchViewsFailure(query, page, pageSize));
    });
}



