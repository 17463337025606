import React, { useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useRouteMatch, useHistory } from 'react-router-dom';

import { Flyout, FlyoutHeader, FlyoutContent } from 'components'
import { Box, RegularText, XButton, ListView, ListViewItem, ListViewHeader, RelativeTime,
    ToolbarButton, ToolbarButtonGroup, Icon,
BodyCopy, Brevier
} from '../components';
import Spinner from '../components/Spinner';
import { fetchNotificationsIfNeeded, invokeNotification, markNotificationsRead, invalidateNotifications } from '../redux/notification/actions';
import { getNotifications } from '../redux/notification/selectors';
import LocalizeText from './LocalizeText'
import translatable from '../hocs/translatable';
import useFlyout  from '../hooks/useFlyout'
import { fetchCompetition } from '../redux/competition/actions';
import { fetchGame } from '../redux/game/actions';

const FormattedNotificationText = ({ text }) => {
    return text.split('\n').map((s, index) => <span key={ index }>{s}<br /></span>)
}

const NotificationSection = ({ t, status, items, onInvoke }) => {
    

    return (
        <React.Fragment>
            <ListView>
                <ListViewHeader>
                    <RegularText color="base500" mx="m" my="m">{ status == 'new' ? t('notifications.newNotificationsHeader') : t('notifications.olderNotificationsHeader') }</RegularText>
                </ListViewHeader>

                {
                    items.map(item => {
                        return (
                            <ListViewItem key={ item.id } p={ ['s', 'm'] } bg={ !item.read && "panelBackground" }>

                                <BodyCopy mb="m">
                                    <FormattedNotificationText text={ item.text } />
                                </BodyCopy>

                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box>
                                        {
                                            item.actionText &&
                                            <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ () => onInvoke(item) } nopadding>
                                                { item.actionText }
                                            </XButton>
                                        }
                                    </Box>
                                    
                                    <Brevier color="metaForeground">
                                        <RelativeTime date={ item.created } />
                                    </Brevier>
                                    
                                </Box>

                            </ListViewItem>
                        )
                    })
                }
            </ListView>
        </React.Fragment>
    )

}


const NoNotifications = ({ t }) => {
    return (
        <RegularText mt={ 3 } textAlign="center" color="metaForeground">{ t('notifications.noNotifications') }</RegularText>
    )
}

const NotificationGroupList = ({ t, notifications, hasMore, onMarkAllAsRead, onMore, onInvoke }) => {

  
    const groupedNotifications = useMemo(() => {

        return notifications.items.reduce((agg, un) => {
            const status = un.new ? 'new' : 'default';

            if (!agg[status])
                agg[status] = [];
            
            agg[status].push(un);

            return agg;
        }, {});


    }, [notifications])

    return (
        <React.Fragment>

            <Box display="flex" justifyContent="flex-end" alignItems="center">
                <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ onMarkAllAsRead }>{ t('notifications.markAllAsRead') }</XButton>
            </Box>

            {
                Object.keys(groupedNotifications).map((key, index) => {
                    return (
                        <Box key={ key } mt={ index > 0 ? 3 : 0 }>
                            <NotificationSection t={ t } status={ key } items={ groupedNotifications[key] } onInvoke={ onInvoke }/>
                        </Box>
                    )
                })
            }

            {
                hasMore &&
                <XButton variant="transparent" size="s" textTransform="uppercase" onClick={ onMore }>Show { notifications.pageSize } More</XButton>
            }
            
        </React.Fragment>
    )
}

const NotificationViewConductor = (props) => {



    if (props.notifications.isFetching && (props.notifications.count === 0 ||  typeof(props.notifications.count) === 'undefined'))
        return <Spinner/>
    else if (typeof(props.notifications.count) === 'undefined' || props.notifications.count === 0)
        return <NoNotifications t={ props.t } />
    else
        return <NotificationGroupList { ...props } />
}


const NotificationFlyout = translatable(({ t, onClose }) => {
    const dispatch = useDispatch();
    const [showFlyout] = useFlyout();
    const match =  useRouteMatch("/:language");

    useEffect(() => {
        dispatch(fetchNotificationsIfNeeded(1, 10))
    }, []);

    const notifications = useSelector(getNotifications);
  
    const handleInvoke = (notification) => {
        dispatch(invokeNotification(notification.id)).then((res) => {
            if (res && res.action == 'url') {
                location.href = res.parameters['href'];
            }
            else if (res && res.action == 'competition') {

                dispatch(fetchCompetition(res.parameters['id'])).then(competition => {
                    dispatch(fetchGame(competition.game.id)).then(game => {
                        
                        let url = `/${ match.params.language }/games/${ game.slug }/${ game.id }/competitions/${ competition.slug }/${ competition.id }/share`;
                        
                            location.href = url;
                        

                    })
                    
                })

              // showFlyout('competition_preview', { competitionId : res.parameters['id'] })
            }
        });
    }

    const handleMarkAllRead = () => {
        dispatch(markNotificationsRead());
    }


    const handleShowMore = () => {
        dispatch(fetchNotificationsIfNeeded(notifications.page + 1, 10))
    }


    const hasMore = notifications.count > notifications.page * notifications.pageSize;
    

    return (
        <Flyout>
            

            <FlyoutHeader identity alignItems="center">
                <Box height="xl" display="flex" alignItems="center">
                    <Box flex={ 1 } px="m">
                        <RegularText>{ t('notifications.notifications') }</RegularText>
                    </Box>

                    <ToolbarButtonGroup>
                        <ToolbarButton onClick={ onClose } >
                            <Icon type="close" />
                        </ToolbarButton>
                    </ToolbarButtonGroup>
                </Box>
            </FlyoutHeader>
            <FlyoutContent p={ ['s', 'm'] }>
                <NotificationViewConductor notifications={ notifications } t={ t } onMarkAllAsRead={ handleMarkAllRead } hasMore={ hasMore } onMore={ handleShowMore } onInvoke={ handleInvoke } />
            </FlyoutContent>
        </Flyout>
    )
}) 


export default NotificationFlyout;