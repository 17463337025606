import React from 'react';
import PropTypes from 'prop-types';


import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { currency, number, minute } from 'utils'

import PlayerIdentity from '../PlayerIdentity'

import LocalizeText from '../LocalizeText'
import ContentBanner from '../ContentBanner';


const Currency = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ currency(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const Minute = ({ value }) => {
    if (typeof value === 'undefined' || value === 0)
        return <span className="meta">-</span>

    else return <span>{ minute(value) }</span>;
}



const SoccerLineup = ({ items, type }) => {

    const lineups = items.filter( p => p.participant.type == type && (p.stats.lineup > 0 || p.stats.preLineup > 0)).sort((a, b) => a.player.position.order - b.player.position.order);
    const benched = items.filter( p => p.participant.type == type && (p.stats.benched > 0 || p.stats.substituteIn > 0)).sort((a, b) => a.player.position.order - b.player.position.order);

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  
                        lineups.length > 0 &&
                        <React.Fragment>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Header><LocalizeText text="@soccer.lineup" /></Table.Header>
                                </Table.Row>
                            </Table.Head>
                            
                            <Table.Body>
                                { 
                                    lineups.map((item, index) => {
                                        const disabled = item.stats.substituteOut > 0 ;

                                        return (
                                            <Table.Row key={ index } disabled={ disabled }>
                                                <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </React.Fragment>
                    }

                    {
                        benched.length > 0 &&
                        <React.Fragment>

                            <Table.Head>
                                <Table.Row>
                                    <Table.Header><LocalizeText text="@soccer.bench" /></Table.Header>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                { 
                                    benched.map((item, index) => {
                                        const disabled = (item.stats.substituteIn || 0) === 0;

                                        return (
                                            <Table.Row key={ index } disabled={ disabled }>
                                                <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </React.Fragment>
                    }
                </Table>

            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                {  lineups.length > 0 &&
                    
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right><LocalizeText text="@common.growth" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.goalAbbr" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.assistAbbr" /></Table.Header>
                                
                                <Table.Header center><LocalizeText text="@soccer.shotsAndSavesAbbr" /></Table.Header>

                                <Table.Header center><LocalizeText text="@soccer.yellowAbbr" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.redAbbr" /></Table.Header>
                                
                                <Table.Header center><LocalizeText text="@soccer.outAbbr" /></Table.Header>
                            </Table.Row>
                        </Table.Head>

                        <Table.Body>
                            { 
                                lineups.map((item, index) => {
                                    const goals = item.stats.goal;
                                    const assists = item.stats.assist;
                                    const yellows = (item.stats.yellowCard || 0) + (item.stats.secondYellowCard || 0);

                                    const saves = (item.stats.saveByGoalkeeper || 0) + (item.stats.shotOnGoal || 0)

                                    const reds = (item.stats.redCard || 0);
                                    const disabled = item.stats.substituteOut > 0;
                                    const subbedOut = (item.events.find(e => e.type.name === 'SubstituteOut') || { time : 0 }).time;


                                    return (
                                        <Table.Row key={ index } disabled={ disabled }>
                                            <Table.Cell right><Number value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ goals } /></Table.Cell>
                                            <Table.Cell center><Number value={ assists } /></Table.Cell>
                                            
                                            <Table.Cell center><Number value={ saves } /></Table.Cell>

                                            <Table.Cell center><Number value={ yellows } /></Table.Cell>
                                            <Table.Cell center><Number value={ reds } /></Table.Cell>
                                            
                                            <Table.Cell center><Minute value={ subbedOut } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                {  
                    benched.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                            <Table.Header right><LocalizeText text="@common.growth" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.goalAbbr" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.assistAbbr" /></Table.Header>
                                
                                <Table.Header center><LocalizeText text="@soccer.shotsAndSavesAbbr" /></Table.Header>
                                
                                <Table.Header center><LocalizeText text="@soccer.yellowAbbr" /></Table.Header>
                                <Table.Header center><LocalizeText text="@soccer.redAbbr" /></Table.Header>
                                
                                <Table.Header center><LocalizeText text="@soccer.inAbbr" /></Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                benched.map((item, index) => {
                                    const goals = item.stats.goal;
                                    const assists = item.stats.assist;

                                    const yellows = (item.stats.yellowCard || 0) + (item.stats.secondYellowCard || 0);


                                    const saves = (item.stats.saveByGoalkeeper || 0) + (item.stats.shotOnGoal || 0)

                                    const reds = (item.stats.redCard || 0);
                                    const disabled = (item.stats.substituteIn || 0) === 0;
                                    const subbedIn = (item.events.find(e => e.type.name === 'SubstituteIn') || { time : 0 }).time;

                                    return (
                                        <Table.Row key={ index } disabled={ disabled }>
                                            <Table.Cell right><Number value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ goals } /></Table.Cell>
                                            <Table.Cell center><Number value={ assists } /></Table.Cell>
                                            
                                            <Table.Cell center><Number value={ saves } /></Table.Cell>

                                            <Table.Cell center><Number value={ yellows } /></Table.Cell>
                                            <Table.Cell center><Number value={ reds } /></Table.Cell>
                                            
                                            <Table.Cell center><Minute value={ subbedIn } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }
                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const SoccerLineupWidget = ({ type }) => {
    return (
        <MatchContext.Consumer>{ (match) => {

            return (
                <>
                <ContentBanner />
                <WithMatchEvents id={ match.id }>{ ({ events }) => (
                    <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                        <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {

                            
                            return (
                                <React.Fragment>
                                    <SoccerLineup items={ items } type={ type } />
                                </React.Fragment>
                            )
                        }}</WithMatchReport>
                    )}</WithMatchFantasyEvents>
                )}</WithMatchEvents>
                </>
            )
        }}</MatchContext.Consumer>
        
    )
}


SoccerLineupWidget.propTypes = {
    type : PropTypes.string.isRequired
}

export default SoccerLineupWidget;