import React from 'react'
import Icon from './Icon'

import * as styles from './Filterbar.scss'


const FilterbarItem = ({ children, onRemove }) => {
    return (
        <div className={ styles.item } onClick={ onRemove }>{ children } <span className={ styles.icon }><Icon type="close" /></span></div>
    )
}


class Filterbar extends React.PureComponent {

    static Item = FilterbarItem;

    render() {
        return (
            <div className={ styles.container }>
                { this.props.children }
            </div>
        )
    }

}

Filterbar.propTypes = {
}


export default Filterbar;