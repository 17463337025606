import React from 'react'
import PropTypes from 'prop-types'
import Avatar from './Avatar'
import Icon from './Icon'
import Box from './Box'
import { Text } from './Typography'

const FantasyTeamIdentity = ({ fantasyTeam }) => {
    return (
        <Box display="flex" alignItems="center">
            <Box mr="m">
                <Avatar size="small" src={ fantasyTeam.user.imageUrl } name={ fantasyTeam.user.username} />
            </Box>
            <Box>
                <Text fontWeight="bold" display="flex" alignItems="center">
                    { fantasyTeam.isDeleted ? <em className="meta">(Deleted)</em> : fantasyTeam.name }
                    { fantasyTeam.level === 'gold' &&  <Icon type="gold" ml="xs" size="1-2x" />  }
                </Text>

                <Text size="xs" color="textColorMeta">{ fantasyTeam.user.username }</Text>
            </Box>
        </Box>
    )
}

FantasyTeamIdentity.propTypes = {
    fantasyTeam: PropTypes.object.isRequired,
    
}

export default FantasyTeamIdentity;






