import React from 'react'
import PropTypes from 'prop-types'
import { XButton } from './XButton'
import Icon from './Icon'
import { number } from '../common/utils'

import * as styles from './Pagination.scss'








class Pagination extends React.PureComponent {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        let totalPages = Math.ceil(this.props.count / this.props.pageSize);
        if (this.props.page <= totalPages - 1) {
            if (this.props.onNext)
                this.props.onNext();

            if (this.props.onPageChanged)
                this.props.onPageChanged(this.props.page + 1)
        }
    }

    previous() {
        if (this.props.page > 1) {
            if (this.props.onPageChanged)
                this.props.onPageChanged(this.props.page - 1)

            if (this.props.onPrevious)
                this.props.onPrevious();
        }
    }


  render() {
      const { page } = this.props;
      
      let totalPages = Math.ceil(this.props.count / this.props.pageSize)

      if (totalPages <= 1)
        return null;
    
    return (

        <div className={ styles.container }>
            <small className="meta">{ number(this.props.page) } / { number(totalPages) }</small>
            
            <XButton variant="transparent" size="s" disabled={ page <= 1 } textTransform="uppercase" onClick={ this.previous }>
                <Icon type="chevronLeft" />
            </XButton>
            <XButton variant="transparent" size="s" disabled={ page >= totalPages } textTransform="uppercase"  onClick={ this.next }>
                <Icon type="chevronRight" />
            </XButton>
        </div>
            

      )
  }

}


Pagination.propTypes = {
    pageSize : PropTypes.number,
    count : PropTypes.number,
    page : PropTypes.number,
    windowSize : PropTypes.number,
    onPageChanged : PropTypes.func,
    onNext : PropTypes.func,
    onPrevious : PropTypes.func
}

Pagination.defaultProps = {
  pageSize: 25,
  count: 0,
  page: 1,
  windowSize : 10
}

export default Pagination;