import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from './ButtonBase'
import * as styles from './Button.scss'

const Button = ({ onClick, type, disabled, children, ...otherProps }) => {
    const handleOnClick = (e) => {
        e.preventDefault(); 
        if (!disabled) {
            onClick(e);
        }
    }

    return (
        <ButtonBase { ...otherProps} disabled={ disabled }>
            {
                (className) => {
                    return <button type={ type } className={ className } onClick={ handleOnClick }>
                                <div className={ styles.content }>{ children }</div>
                           </button>
                }
            }
        </ButtonBase>
    )     
}

Button.propTypes = {
    onClick : PropTypes.func,
    children : PropTypes.node,
    type : PropTypes.string
}

export default Button;
