import React, { useRef, useState, useContext } from 'react'

import { ViewEditingContext}  from './View'
import Icon from './Icon'

import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    
    border-color: #ddd;
    border-width: 1px;
    border-style: ${ props => props.active ? 'solid' : 'dashed' };
    cursor: pointer;
    box-sizing: border-box;
`


const Header = styled.div`
    position: absolute;
    
    height: 32px;
    right: 0;
    top: -32px;

    width: 100%;

    background: #1A5276;
    color: #fff;

    font-size: 12px;
    line-height: 32px;
    display: ${ props => props.hover ? 'flex' : 'none' };
    z-index: 10000;
`

const HeaderTitle = styled.div`
    flex: 1;
    padding: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const HeaderActions = styled.div`
    white-space: nowrap;
    display: flex;
`

const HeaderAction = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
`


const Content = styled.div`
    padding: 12px;
`


const ComponentEditor = ({ componentContext, childType, children }) => {
    const editingContext = useContext(ViewEditingContext);

    const pathArr =  (componentContext && componentContext.path || []);
    const path = pathArr.join('.')

    const enter = (e) => {
        e.stopPropagation();
        editingContext.sendCommand({ name : 'hoverEnter', path : pathArr  });
    }

    const leave = () => {
        editingContext.sendCommand({ name : 'hoverLeave', path : pathArr });
    }

    const select = (e) => {
        e.stopPropagation();
        editingContext.sendCommand({ name : 'select', path : pathArr });
    }

    const hover = componentContext && editingContext.hoverPath == path;
    const active = componentContext && editingContext.activePath == path;


    return (
        <Container onClick={select} onMouseOver={ enter}  onMouseLeave={ leave } hover={ hover } active={ active }>
            <Header hover={ hover }>
                <HeaderTitle>
                    { componentContext && componentContext.definition && componentContext.definition.displayName || '(Unknown)'} 
                </HeaderTitle>
                <HeaderActions>
                    {
                        childType &&
                            <HeaderAction onClick={ () => editingContext.sendCommand({ name : 'add', type : childType, path  : pathArr }) } >
                                <Icon type="plus"/>
                            </HeaderAction>        
                    }
                    
                    <HeaderAction  onClick={ () => editingContext.sendCommand({ name : 'select', path  : pathArr }) } >
                        <Icon type="star" />
                    </HeaderAction>
                    
                    <HeaderAction  onClick={ () => editingContext.sendCommand({ name : 'remove', path  : pathArr }) } >
                        <Icon type="close" />
                    </HeaderAction>
                </HeaderActions>
            </Header>
            <Content>
                {hover}
                { children }
            </Content>
        </Container>
    )     
}

ComponentEditor.propTypes = {
}

export default ComponentEditor;
