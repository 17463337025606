import React from 'react';
import PropTypes from 'prop-types'

import { XButton, Icon, ContextMenuPortal, ToolbarButton, Box } from 'components'

import LocalizeText from '../../LocalizeText'
import WithFlyout from '../../WithFlyout'

const FlyoutButton = ({ label, header, view, icon = 'filter' }) => (

    <WithFlyout>{ ({ push }) => (
        <React.Fragment>    
           <ContextMenuPortal>
                <ToolbarButton onClick={ () => push('view', { header, view }) }>
                    <Icon type={ icon } />
                </ToolbarButton>

            </ContextMenuPortal>

            <Box>
                <ToolbarButton  onClick={ () => push('view', { header, view }) }>
                    <Box mr="s200">
                        <Icon type={ icon } />
                    </Box>
                    <LocalizeText text={ label } />
                </ToolbarButton>
            </Box>
        </React.Fragment>
    )}</WithFlyout>

)

FlyoutButton.propTypes = {
    view : PropTypes.string.isRequired,
    header : PropTypes.string
}

FlyoutButton.defaultProps = {
}

export default FlyoutButton;