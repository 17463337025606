import React, { useContext } from 'react';
import PropTypes from 'prop-types'
import { CSSVariables } from 'components';
import { useTheme } from '../../../hooks/useTheme'

const Theme = ({ id, children }) => {
    const theme = useTheme(id);

        
    return (
        <CSSVariables vars={ theme.item && theme.item.properties || {} }>
            {children}
        </CSSVariables>
    );
}

Theme.propTypes = {
    id : PropTypes.number.isRequired
}

Theme.defaultProps = {
    id : ''
}

export default Theme;