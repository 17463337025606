import React, { useMemo, useState } from 'react'

import { space, layout } from 'styled-system'

import Linkify from 'react-linkify'

import styled from 'styled-components'


export const PostCard = styled.div`
    background: var(--card-background-color);
    border-radius: var(--border-radius);

    ${ space }
    ${ layout }
`



export const PostHeader = styled.div`
    display: flex;
    height: 36px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`

export const PostContent = styled.div`
    
    line-height: 16px;
`


export const PostDivider = styled.hr`
    height: 1px;
    margin: 12px 0 ;
`



const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener" >
      {text}
    </a>
 );

export const PostText = ({ text }) => {
    const lines = useMemo(() => text.split('\n'), [text]);

    return <Linkify componentDecorator={ componentDecorator }>{ lines.map((l, index) => <React.Fragment key={ index }>{ l }<br /></React.Fragment>) }</Linkify>
}




