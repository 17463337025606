import serverTime from './serverTime';
import dayjs from 'dayjs'


Array.prototype.except = function(val) {
    return this.filter(function(x) { return x !== val; });        
}; 


window.env = {
    culture : 'da'
};

const points = (value) => { return value + ' p.'; }


const number = (value, postfix, zero) => { 
    postfix = postfix || '';

    if (value === undefined || value === 0)
        return typeof(zero) !== 'undefined' ? zero : '-';
        
    
    const { formatting } = window.swush;
    if (formatting && formatting.number) {
        return formatting.number.format(value) + postfix;
    }
    
    return value.toFixed(0) + postfix; 
}

const currency = (value) => { 
    if (value === undefined)
        return '-';

    const { formatting } = window.swush;
    if (formatting && formatting.currency) {
        return formatting.currency.format(value);
    }
    
    return value.toFixed(0); 
}
const percent = (value) => { 
    if (value === undefined)
        return '-';

    var p = Math.round(value * 1000) / 10;
    
    return p.toFixed(1) + '%'; 
}

const jump = (value) => {
    if (value == 0)
        return '-';
    else if (value < 0)
        return value;
    else
        return '+' + value;
}


const minute = (value) => { 
    if (!value)
        return '-';
    
    return parseInt(value / (1000 * 60)) + '\'';
}

const currentRound = (rounds) => {
    let now = serverTime.now();
    
    if (now < new Date(rounds[0].start))
        return rounds[0];

    for(let i = rounds.length - 1; i >= 0; i--){
        let round = rounds[i];
        if(now > new Date(round.start)) {
            return round;
        }
    }

    if (rounds.length > 0)
        return rounds[rounds.length - 1];

    return null;
}



const getRoundIndex = (rounds, dt) => {
    for(let i = 0; i < rounds.length; i++){
        let round = rounds[i];
        if(dt < new Date(round.end)) {
            return i;
        }
    }

    return rounds.length - 1;
}


export const buildLineups = (rounds, trades) => {
    let lineups = [];
    for (let i = 0; i < rounds.length; i++)
    {
        lineups.push([]);
    }


    for (let i = 0; i < trades.length; i++)
    {
        var trade = trades[i];
        
        const roundIndex = getRoundIndex(rounds, new Date(trade.at));
        
        for(let j = roundIndex; j < rounds.length; j++)
        {
            switch(trade.type)
            {
                case 'purchase':
                    lineups[j].push(trade.player);
                    break;
                case 'sale':
                    var idx = lineups[j].indexOf(trade.player);
                    lineups[j].splice(idx, 1);
                    break;
            }
        }
    }

    return lineups;
}


const publicRound = (rounds) => {
    let now = serverTime.now();

    for(let i = rounds.length - 1; i >= 0; i--){
        let round = rounds[i];
        if(now > new Date(round.close)) {
            return round;
        }
    }

    return rounds[0];
}


// Localize object property
const lo = (o, property) => {
    if (!o)
        return '';

    var translation = o.translations ? o.translations[window.swush.apiCulture] : null;
    if (translation) {
        return translation ? (translation[property] || o[property]) : o[property];
    }
    else {
        return o[property];
    }
}


let qs = (function(a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i)
    {
        var p=a[i].split('=', 2);
        if (p.length == 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
  })(window.location.search.substr(1).split('&'));


const slugify = (s) => {
    s = s || '-';

    s = s.replace(/^\s+|\s+$/g, ''); // trim
    s = s.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l ; i++) 
    {
        s = s.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    s = s.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return s;
}

const camelize = (str) => {
    return (str + '').replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
      return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
}


export { points, number, minute, currency, jump, lo, currentRound, publicRound, getRoundIndex, qs, percent, slugify, camelize };

export const safeget = (o, path) => {
    return path.reduce((obj, p) => {
        return obj && obj[p];
    }, o);
}

export const cmp = (a, b) => {
    if (a > b) { return 1; }
    else if (a < b) { return -1; }
    else return 0;
}


export const fullName = (person) => {

    if (person.firstname && person.lastname)
        return person.firstname + ' ' + person.lastname;
    else if (person.lastname)
        return person.lastname;
    else return person.firstname;
}



export const relativeUrl = (url) => {
    return  window.swush.externalBaseUrl + '/' + url;
}


export const resolve = (path, obj = self, separator='.') => {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => { 
        if (curr === '$first')
            curr = 0;
        else if (curr === '$last') {
            curr = Array.isArray(prev) && prev.length - 1;
        }
        
        return prev && prev[curr]
    
    }, obj);
}


export const evaluateOperation = (value, op, compareTo) => {
    switch(op) {
        case 'eq':
            return value === compareTo;
        case 'neq':
            return value !== compareTo;
        case 'gt':
            return value > compareTo;
        case 'lt':
            return value < compareTo;
        case 'gte':
            return value >= compareTo;
        case 'lte':
            return value <= compareTo;
    }
}


export const interpolateString = (obj, s) => {
    if (!(typeof s === 'string'))
        return s;

    if (s[0] === '@') {
        const path = s.substring(1);
        return resolve(path, obj);
    }
    else {
        return s;
    }

}


export const interpolateObject = (obj, props) => {

    return Object.keys(props).reduce((agg, key) => {
        let value = props[key];

        if (typeof value === 'string') {
            value = interpolateString(obj, value);
        }

        agg[key] = value;
        return agg;
    }, {})
}


export const filterArray = (arr, filters) => {
    
    return arr.filter(r => {
        for(var i = 0; i < filters.length; i++) {
            const f = filters[i];
            const value = interpolateString(r, f.expr);

            if (!evaluateOperation(value, f.op, f.value)) {
                return false;
            }
        }

        return true;
    })

}




export const parseExpressions = (obj) => {
    Object.keys(obj).forEach(key => {
        const val = obj[key];
        
        if (typeof val === 'object') {
            obj[key] = parseExpressions(val);
        }
        else if (typeof val === 'string' && val.indexOf('@@') === 0) {
            const expression = val.substr(2);
            obj[key] = jsep(expression);
        }
        else{
            obj[key] = val
        }

    }, obj);

    return obj;
}




export const matchesCondition = (obj, condition) => {
    const value = resolve(condition.property, obj);

    switch(condition.op) {
        case 'eq':
            return value === condition.value;
        case 'neq':
            return value !== condition.value;
        case 'gt':
            return value > condition.value;
        case 'gte':
            return value >= condition.value;
        case 'lt':
            return value < condition.value;
        case 'lte':
            return value <= condition.value;
        case 'like':
            return (value || '').toLowerCase().indexOf((condition.value || '').toLowerCase()) >= 0;
        case 'in':
            return (condition.value || []).indexOf(value) >= 0;
        case 'defined':
            return typeof value !== 'undefined';
        case 'undefined':
            return typeof value === 'undefined';
        default:
            throw 'Invalid operation \'' + condition.op + '\'';
    }
}


export const matchesConditions = (obj, conditions) => {
    for(let i = 0; i < conditions.length; i++) {
        if (!matchesCondition(obj, conditions[i]))
            return false;
    }

    return true;
}


export const contextMatchesCondition = (obj, condition) => {
    const value = exp(condition.property, obj);

    

    switch(condition.op) {
        case 'eq':
            return value === condition.value;
        case 'neq':
            return value !== condition.value;
        case 'gt':
            return value > condition.value;
        case 'gte':
            return value >= condition.value;
        case 'lt':
            return value < condition.value;
        case 'lte':
            return value <= condition.value;
        case 'like':
            return (value || '').toLowerCase().indexOf((condition.value || '').toLowerCase()) >= 0;
        case 'in':
            return (condition.value || []).indexOf(value) >= 0;
        case 'defined':
            return typeof value !== 'undefined';
        case 'undefined':
            return typeof value === 'undefined';
        default:
            throw 'Invalid operation \'' + condition.op + '\'';
    }
}


export const contextMatchesConditions = (obj, conditions) => {
    for(let i = 0; i < conditions.length; i++) {
        if (!contextMatchesCondition(obj, conditions[i]))
            return false;
    }

    return true;
}



export const format = (str, obj) => {
    return Object.keys(obj).reduce((agg, k) => { 
        return agg.replace('{' + k + '}', obj[k]) ;
    }, str);
}


export const getConfigurationSection = (config, section, profileName) => {
    const profile = config && config.profiles && config.profiles[profileName] || {};
    return profile[section] ? profile[section] : config[section];
}







export const pick = (val, context, _default) => {

    if (typeof val === 'undefined' || val.length === 0) {

        
        return context;
    }
   
    const path = val.split('.');

    return path.reduce((agg, p) => {
        if (p === '$first')
            return agg && agg[0];
        else if (p === '$last')
            return agg && agg[agg.length - 1];
        else
            return agg && agg[p];
    }, context) || _default;


}


export const exp = (val, context, _default) => {
    return val && val[0] == '$' ? pick(val.substring(1), context, _default) : pick(val, context.current, _default) 
}

export const flatten = (obj, separator = '-', parent) => Object.keys(obj).reduce((acc, k) => {
    const pName = parent ? parent + separator + k : k;

    if (typeof obj[k] === 'object') 
        Object.assign(acc, flatten(obj[k], separator, pName));
    else 
        acc[pName] = obj[k];
    
    return acc;
}, {});




export const unflatten = (obj, separator = '.') => {
    let nObj = {};

    Object.keys(obj).forEach(key => {
        const path = key.split(separator);
        const leafs = path.slice(0, path.length - 1);
        
        let iObj = leafs.reduce((agg, l) => {
            agg[l] = {};
            return agg[l];
        }, nObj) || {};

        iObj[path[path.length - 1]] = obj[key];
    });

    return nObj;

}




const getMaximumEliminationRounds = (count) => {
    if (count <= 2)
        return 1;

    return parseInt(Math.floor(Math.log2(count - 1)));
}


const getEliminationStageName = (t, count) => {
    if (count == 2)
        return t('competitions.final');
    else if (count == 4)
        return t('competitions.semifinal');
    else if (count == 8)
        return t('competitions.quaterfinal');
    else
        return `1/${ number(count) }`;
}


export const getCupStructure = (t, rounds, maximumEliminationRounds, count) => {
    const absMaximumEliminationRounds = getMaximumEliminationRounds(count);

    const elimination = maximumEliminationRounds > 0 ? 
        Math.min(absMaximumEliminationRounds, maximumEliminationRounds) : 
        Math.min(absMaximumEliminationRounds, rounds - 1); // Must have 1 qualification round



    const qualification = rounds - elimination;

    let rArr = [];

    for(let i = 0; i < rounds; i++) {
        
        if (i < qualification) {
            rArr.push({ type : 'qualification', name : t('competitions.qualification'), count })
        }
        else {
            const eIdx = rounds - i;
            const rCount = Math.pow(2, eIdx);

            rArr.push({ type : 'elimination', name : getEliminationStageName(t, rCount), count : rCount })
        }
    }

    return { 
        finalists : Math.pow(2, elimination),
        qualificationRounds : qualification,
        eliminationRounds : elimination,
        rounds : rArr 
    };
}



export const relativeTime = (dt) => {


    var elapsed = new Date().getTime() - dt.getTime();

    if (elapsed < 1000) {
        return elapsed + ' ms ago';   
    }
    else if (elapsed < 60 * 1000) {
         return Math.round(elapsed / 1000) + ' seconds ago';   
    }
    else if (elapsed < 60 * 60 * 1000) {
         return Math.round(elapsed / (60 * 1000)) + ' minutes ago';   
    }
    else if (elapsed < 24 * 60 * 60 * 1000 ) {
         return Math.round(elapsed/ (60 * 60 * 1000)) + ' hours ago';   
    }
    else {
        dt
    }
}
