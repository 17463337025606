import React from 'react';

export const Grid = ({ children, cols, rows, gap, rowGap, columnGap, templateRows, templateColumns, autoRows, autoColumns, autoFlow, ...otherProps }) => {
    const style = {
        display : 'grid',
        
        gridTemplateColumns : cols ? [...Array(cols).keys()].map(k => '1fr').join(' ') : templateColumns,
        gridTemplateRows : rows ? [...Array(rows).keys()].map(k => '1fr').join(' ') : templateRows,

        gridGap : gap,

        gridAutoRows : autoRows,
        gridAutoColumns : autoColumns,
        gridAutoFlow : autoFlow
    }
    
    
    
    return <div style={style} >{ children }</div>

}

export const GridItem = ({ children, column, row }) => {

    const style = {
        
        gridColumn : column,
        gridRow : row
    }
    

    return <div style={ style }>{ children }</div>
}