import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { createSelector } from 'reselect'

import { fetchScheduleIfNeeded } from '../redux/schedule/actions'
import GameContext from './GameContext';


class WithSchedule extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetch(this.props.id);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.props.fetch(nextProps.id);
        }
    }

    render() {
        const { schedule, children } = this.props;
        
        return children({ schedule });
    }
}

export const makeGetSchedule = () => (
    createSelector([
        (state, props) => props.id,
        (state, props) => props.gameId,
        (state, props) => props.team,
        (state) => state.schedule.byTournament,
        (state) => state.entities.games,
        (state) => state.entities.matches,
        (state) => state.entities.locations,
        (state) => state.entities.matchGroups,
        (state) => state.entities.teams
    ],
    (id, gameId, team, scheduleByTournament, games, matchEntities, locationEntities, matchGroupEntities, teamEntities) => {
            const schedule = scheduleByTournament[id] || { items : [] };
            const game = games[gameId];

            const now = new Date();

            

            const items = (schedule.items || []).map(id => {
                const match = matchEntities[id];

                const mStart = new Date(match.start);

                const round = game.rounds.find(r => mStart >= new Date(r.start) && mStart <= new Date(r.end));
                const roundIndex = round ? game.rounds.indexOf(round) : -1;

                const isCurrent = round && now >= new Date(round.start) && now < new Date(round.end);

                return {
                    round : roundIndex + 1,
                    current : isCurrent,
                    ...match,
                    participants : match.participants.map(p => {
                        const team = teamEntities[p.team + ''];
                        return {
                            ...p,
                            team
                        }
                    }),
                    location : locationEntities[match.location],
                    group : matchGroupEntities[match.group]
                }
            }).filter(i => i.round > 0 && (!team || i.participants.some(p => p.team.slug === team)));


            return {
                items
            }
        }
    )
)

const makeMapStateToProps = () => {
    const getSchedule = makeGetSchedule();
    
    return (state, ownProps) => ({
        schedule : getSchedule(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(id) {
            if (id) {
                dispatch(fetchScheduleIfNeeded(id));
            }
        }
    }
}

WithSchedule.propTypes = {
    id : PropTypes.any,
    gameId : PropTypes.any,
    team : PropTypes.string
    
}

connect(makeMapStateToProps, mapDispatchToProps)(WithSchedule);


const ConnectedWithSchedule = connect(makeMapStateToProps, mapDispatchToProps)(WithSchedule);

const WithScheduleWrapper = ({ children, ...otherProps }) => {
    const { game } = useContext(GameContext);

    return <ConnectedWithSchedule id={ game.tournament } gameId={ game.id } {...otherProps}>{ children }</ConnectedWithSchedule>
}

export default WithScheduleWrapper;