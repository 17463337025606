import React, { useState, useEffect } from 'react';

import styled, { keyframes } from 'styled-components';

import { animated, useTransition, useTrail, config } from 'react-spring';



const Container = styled.div`
    position: relative;
    background: var(--identity);
    
	color: #fff;
	height: 480px;
	overflow: hidden;
`

const slowzoom =  keyframes`
    0% { transform: scale3d(1.0, 1.0, 1.0); }
    100% { transform: scale3d(1.2, 1.2, 1.2); }
`


const Background = styled(animated.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${ props => `url(${ props.backgroundImage })` };

    animation: ${ slowzoom } 20s ease-in-out infinite alternate;
`

const Content = styled.div`
	max-width: 1200px;
	margin: 0 auto;
	padding: 64px 0 ;
`

const Heading = styled(animated.h1)`
	font-family: 'geomanist';
	font-weight: 900;
    letter-spacing: 0px;
    font-size: 80px;
    line-height: 72px;
    margin: 73px 0 0 0;

`

const Edition = styled.span`
	font-family: 'geomanist';
	font-weight: 300;
    margin-left: 12px;
    

`

const Subtitle = styled(animated.h3)`
	font-family: 'geomanist';
	font-weight: 300;
	font-size: 20px;

    letter-spacing: 0px;
`



const AnimatedSubtitle = ({  subtitle }) => {

    const [show, set] = useState(false);
    
    useEffect(() => {
        set(true);

        return () => {
            set(false);
        }
    })


    const transitions = useTransition(show, null, {
        from: { transform: 'translate3d(96px, 0, 0)', opacity: 0 },
        enter: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        leave: { transform: 'translate3d(-96px, 0, 0)', opacity: 0 },
        trail: 500,
        config : config.slow
    })

    return transitions.map(({ item, key, props }) =>
        item &&
        <Subtitle style={ props }>
            { subtitle }
        </Subtitle>
    )
}



const AnimatedHeading = ({  title, edition }) => {

    const [show, set] = useState(false);
    
    useEffect(() => {
        set(true);

        return () => {
            set(false);
        }
    })


    const transitions = useTransition(show, null, {
        from: { transform: 'translate3d(96px, 0, 0)', opacity: 0 },
        enter: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
        leave: { transform: 'translate3d(-96px, 0, 0)', opacity: 0 },
        config : config.slow
    })

    return transitions.map(({ item, key, props }) =>
        item &&
        <Heading style={ props }>
            { title }
            { edition && <Edition>{ edition }</Edition> }
        </Heading>
    )
}





const AnimatedBackground = ({  backgroundImage }) => {

    const [show, set] = useState(false);
    
    useEffect(() => {
        set(true);

        return () => {
            set(false);
        }
    })


    const transitions = useTransition(show, null, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config : { duration: 2000 }
    })

    return transitions.map(({ item, key, props }) =>
        item &&
        <Background key={ key } style={ props } backgroundImage={ backgroundImage }/>
    )
}

const cfg = { mass: 5, tension: 2500, friction: 125 }

const Trails = ({ children }) => {

    const [toggle, set] = useState(true)
    
    const trail = useTrail(React.Children.count(children), {
      config : cfg,
      opacity: toggle ? 1 : 0,
      x: toggle ? 0 : 40,
      height: toggle ? 80 : 0,
      from: { opacity: 0, x: 40, height: 0 },
    })

    const childs = React.Children.toArray(children);


    return trail.map(({ x, height, ...rest }, index) => {
        return React.cloneElement(
            childs[index], 
            {
                key : index, 
                style : { 
                    ...rest, 
                    transform: x.interpolate(x => `translate3d(${ x }px, 0, 0)`) 
                }
            }
        );

    });

}


const Hero = ({ title, edition, subtitle, backgroundImage }) => {


	return (
		<Container >
            <AnimatedBackground  backgroundImage= { backgroundImage }/>
			<Content>
                <Trails>
                    <Heading >
                        { title }
                        { edition && <Edition>{ edition }</Edition> }
                    </Heading>
                    <Subtitle>{ subtitle }</Subtitle>
                </Trails>
                
			</Content>

            
		</Container>
	)
}


export default Hero;