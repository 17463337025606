import React from 'react';

import { TopBar, Box } from 'components'


export const TopBarLeftWidget = ({ children, ...otherProps }) => {
    return <Box { ...otherProps}>
                { children }
           </Box>

}

export const TopBarRightWidget = ({ children, ...otherProps }) => {
    return <Box { ...otherProps}>
                { children }
           </Box>

}

export const TopBarWidget = ({ children, ...otherProps }) => {
    return <TopBar { ...otherProps } display="flex" px="m">
                { children }
           </TopBar>

}