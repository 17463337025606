import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux'

import { start } from '../redux/auth/actions'
import { useAppConfig } from './AppConfigProvider'
import auth from '../common/auth';

const useAuthenticator = () => {
    const dispatch = useDispatch();
    const cfg = useAppConfig();

    const tryAuthenticate = useCallback(() => {

        if (!auth.authenticated) { 
            if (!cfg)
                alert('Configuration not loaded');

            if (cfg.auth && cfg.auth.type == 'local') {
                dispatch(start());
            }
            else if (cfg.auth && cfg.auth.type == 'redirect') {
                
                location.href = (typeof cfg.auth.redirect == 'function') ? cfg.auth.redirect() : cfg.auth.redirect;
                
            }
            else {
                location.href =  window.swush.externalBaseUrl + '/account/signin';
            }
        }   

        return auth.authenticated;
    });

    return [tryAuthenticate];
}

export default useAuthenticator;