import React from 'react';
import PropTypes from 'prop-types'
import { percent } from 'utils';

const Percent = ({ value, defaultValue }) => {
    if (typeof value == 'number') {
        return percent(value);
    }
    else {
        return defaultValue;
    }
}

Percent.propTypes = {
    value : PropTypes.number
}

Percent.defaultProps = {
    defaultValue : '-'
}

export default Percent;