import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as styles from './Round.scss'

const Round = ({ children, post }) => {

    const className = classNames(
        styles.round,
        {
            
        }
    )

    return (
        <span className={ className }>
            { children }
        </span>
    )
}

Round.propTypes = {
}


export default Round