import axios from 'axios'

import * as types from './types';

const fetchConfigRequest = (url) => ({ type: types.FETCH_CONFIG_REQUEST, url })
const fetchConfigSuccess = (url, payload) => ({ type: types.FETCH_CONFIG_SUCCESS, url, payload })
const fetchConfigFailure = (url) => ({ type: types.FETCH_CONFIG_SUCCESS, url })

const fetchConfig = (url) => (dispatch) => {
    dispatch(fetchConfigRequest(url))

    axios.get(url).then(response => {
        dispatch(fetchConfigSuccess(url, response.data))
    }, error => {
        dispatch(fetchConfigFailure(url))
    });
}


const shouldFetchConfig = (state, url) => {
    const { byUrl } = state.configs || { byUrl : {} };
    
    if (!byUrl[url])
        return true;
    else if (byUrl[url].didInvalidate)
        return true;
    else
        return false;
}
  
  export const fetchConfigIfNeeded = (url) => (dispatch, getState) => {
    if (shouldFetchConfig(getState(), url)) {
        dispatch(fetchConfig(url));
    }
  }