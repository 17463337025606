import React from 'react';

import { Hero } from 'components';


const HeroWidget = ({ ...otherProps }) => {

	return (
		<Hero { ...otherProps } />
	)
}


export default HeroWidget;