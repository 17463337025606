import React from 'react'

import styled, { css } from 'styled-components'

import { display, space } from 'styled-system';

const DefaultStyle = css`
    background: ${ props => props.theme.colors.cardBackground };
    color: ${ props => props.theme.colors.cardForeground };
`

const InfoStyle = css`
    background: ${ props => props.theme.colors.utilityBlue };
    color: ${ props => props.theme.colors.utilityWhite };
`

const WarningStyle = css`
    background: ${ props => props.theme.colors.utilityYellow };
    color: ${ props => props.theme.colors.utilityBlack };
`

const DangerStyle = css`
    background: ${ props => props.theme.colors.utilityRed };
    color: ${ props => props.theme.colors.utilityWhite };
`


const variants = {
    'default' : DefaultStyle,
    'info' : InfoStyle,
    'warning' : WarningStyle,
    'danger' : DangerStyle
}



const Tag = styled.div`
    height: 18px;
    line-height: 18px;
    padding: 0 6px;

    border-radius: 3px;

    display: inline-block;

    font-size: 11px;
    white-space: nowrap;

    cursor: ${ props => props.onClick ? 'pointer' : 'default' };

    ${ props => variants[props.variant] }

    ${ display }
    ${ space }
`

Tag.defaultProps =  {
    variant : 'default',
    size: 'm'
}

export default Tag;
