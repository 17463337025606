import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTransition, animated } from 'react-spring';

import styled, { css } from 'styled-components';

const Container = styled.div`
    font-size: 14px;
`

const Content = styled.div`
    z-index: 1000;         
`

const Cover = styled(animated.div)`
    position: fixed;

    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0;
    
    background: rgba(0, 0, 0, 0.5);
    
    will-change: opacity;
    
    z-index: 990; 
    
    display: ${ props => props.open ? 'block' : 'none' };
    
`

const Menu = styled(animated.div)`

    position: fixed;
    
    top: 0;
    left: 0;
    bottom: 0;
    
    width: var(--sidebar-panel-width, 272px);

    z-index: 1001;       
    background-color: ${ props => props.theme.colors.siteBackground };
    color: ${ props => props.theme.colors.siteForeground };

    overflow-x: hidden;
    overflow-y: auto;

    will-change: transform;

    -webkit-overflow-scrolling: touch;

    display: ${ props => props.open ? 'block' : 'none' };

`


const AnimatedMenu = ({ open, children }) => {
    const transitions = useTransition(open, null, {
        from: { transform: 'translate3d(-100%, 0, 0)', display: 'block' },
        enter: { transform: 'translate3d(0, 0, 0)', display: 'block' },
        leave: { transform: 'translate3d(-100%, 0, 0)', display: 'block' }
    })

    return transitions.map(({ item, key, props }) => (
        item && <Menu open={ open } key={ key } style={ props }>{ children }️</Menu>
    ));
}

const AnimatedCover = ({ open, ...otherProps }) => {
    const transitions = useTransition(open, null, {
        from: { opacity: 0, display: 'block' },
        enter: { opacity: 1, display: 'block' },
        leave: { opacity: 0, display: 'block' }
    })

    return transitions.map(({ item, key, props }) => (
        item && <Cover open={ open } key={ key } style={ props } { ...otherProps }></Cover>
    ));
}


const OffCanvasSidebar = ({ open, pin, menu, onClose, children }) => {
    const onKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    })

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    })
      
    return (
        <Container>
            <AnimatedMenu open={ open } pin={ pin }>{ menu }</AnimatedMenu>
            <Content>
                <AnimatedCover open={ open } onClick={ onClose }></AnimatedCover>

                { children }
            </Content>
        </Container>
    )     
    
}




const PinnedContainer = styled.div`
    font-size: 14px;
    display: grid;
    grid-template-columns: var(--sidebar-panel-width, 272px) 1fr;
    min-height: 100vh;
`

const PinnedMenu = styled(animated.div)`
    z-index: 1001;       
    background-color: ${ props => props.theme.colors.siteBackground };
    color: ${ props => props.theme.colors.siteForeground };
`

const PinnedContent = styled.div`
    position: relative;
`

const PinnedSidebar = ({ menu, children }) => {
    return (
        <PinnedContainer>
            <PinnedMenu>{ menu }</PinnedMenu>
            <PinnedContent>{ children }</PinnedContent>
        </PinnedContainer>
    )     
    
}


const Sidebar = ({ pin, children, ...otherProps }) => {
    if (pin) {
        return <PinnedSidebar {...otherProps }>{ children }</PinnedSidebar>
    }
    else {
        return <OffCanvasSidebar {...otherProps}>{ children }</OffCanvasSidebar>
    }
}

Sidebar.propTypes = {
    open : PropTypes.bool.isRequired,
    pin : PropTypes.bool.isRequired,
    onClose : PropTypes.func,
    menu : PropTypes.node
}

Sidebar.defaultProps = {
    open : false,
    pin : false
}

export default Sidebar;