// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TFFjy8NhNh08mH6wunCM {

    background-color: var(--utility-silver);
    color: var(--utility-black);

    height: 18px;
    border-radius: 9px;
    line-height: 18px;
    padding: 0 9px;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    
    display: inline-flex;
    align-items: center;

}


.tm6e_lQyn8s6crPab67x {
    background-color: var(--action);
    color: var(--on-action);
}`, "",{"version":3,"sources":["webpack://./src/components/Pill.module.css"],"names":[],"mappings":"AAAA;;IAEI,uCAAuC;IACvC,2BAA2B;;IAE3B,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,cAAc;IACd,yBAAyB;IACzB,qBAAqB;;IAErB,oBAAoB;IACpB,mBAAmB;;AAEvB;;;AAGA;IACI,+BAA+B;IAC/B,uBAAuB;AAC3B","sourcesContent":[".pill {\r\n\r\n    background-color: var(--utility-silver);\r\n    color: var(--utility-black);\r\n\r\n    height: 18px;\r\n    border-radius: 9px;\r\n    line-height: 18px;\r\n    padding: 0 9px;\r\n    font-size: 8px;\r\n    text-transform: uppercase;\r\n    letter-spacing: 0.5px;\r\n    \r\n    display: inline-flex;\r\n    align-items: center;\r\n\r\n}\r\n\r\n\r\n.action {\r\n    background-color: var(--action);\r\n    color: var(--on-action);\r\n}"],"sourceRoot":""}]);
// Exports
export var pill = `TFFjy8NhNh08mH6wunCM`;
export var action = `tm6e_lQyn8s6crPab67x`;
export default ___CSS_LOADER_EXPORT___;
