import React, { useMemo } from 'react'

import { SelectList, Box, Text, Icon } from 'components'
import { useTranslator } from '../hooks/useTranslator'

const renderItem = item => {
    return (
        <React.Fragment>
            <Box display="flex" alignItems="center" minHeight="s400">
                <Box display="flex" width="s200" height="s200" mx="s200" alignItems="center" justifyContent="center">
                    { item.selected && <Icon type="check" /> }
                </Box>
                
                <Box flex="1">
                    <Text size="s" fontWeight={ item.selected ? 'bold' : 'normal' }>{ item.label }</Text>
                    <Text size="xs" color="dropdownForegroundMeta">{ item.description }</Text>
                </Box>
                
            </Box>
        </React.Fragment>
    )
}

const CompetitionTypeSelectList = ({ value, onChange }) => {
    const t = useTranslator();

    const items = useMemo(() => {
        return [
            { value : 'leaderboard', label : t('competitions.format_leaderboard'), description : t('competitions.format_description_leaderboard')},
            { value : 'cup' , label : t('competitions.format_cup'), description : t('competitions.format_description_cup') }
        ];
    }, []);

    return (
        <SelectList items={ items } value={ value } onSelect={ value => onChange(value) } expand={ true }  onRenderItem={ renderItem }/>
    )
}

export default CompetitionTypeSelectList;