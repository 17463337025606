import React from 'react'

import { MatchCardList, MatchCard, EventDate, Icon } from '../../components'

import translatable from '../../hocs/translatable'

const Kilometers = ({ value }) => {
    const km = parseInt(value) / 1000.0;

    return (
        <span>{ km.toFixed(2) } km</span>
    )
}

const MotorsportMatchCard = React.memo(({ t, onClick, item }) => {
    const properties = item.properties || {};

    const totalDistance =  properties.circuitDistance ? parseInt(properties.circuitDistance) : 0;
    const laps =  properties.circuitLaps ? parseInt(properties.circuitLaps) : 0;


    return (
        <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } current={ item.current } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
            <MatchCard.SimpleEventContent round={ item.round }  title={ item.name } />

            <MatchCard.Footer>
                { properties.circuitLaps && <span>{ t('motorsport.lapsFormat', [properties.circuitLaps]) }</span> }
                { totalDistance > 0 && laps > 0 && <Kilometers value={ totalDistance / laps  } /> }
                <span><EventDate date={ new Date(item.start) } format="short" /></span>
            </MatchCard.Footer>
            
            { item.status === 'updated' && <MatchCard.Icon><Icon type="flag" size="1-2x" /></MatchCard.Icon> }
        </MatchCard> 
    )
})


const MotorsportScheduleCardView = ({ t, onSelectMatch, schedule }) => {
    return (
        <MatchCardList>
            { schedule.items.map(item => <MotorsportMatchCard t={ t } key={ item.id }  item={ item } onClick={ () => onSelectMatch(item) } />)  }
        </MatchCardList>
    )
}

export default translatable(MotorsportScheduleCardView);