export const getPeriodName = (t, periodIndex) => { return t('soccer.period' + periodIndex); }

export const getPeriodBaseMinute = (periodIndex) => { 
    switch(periodIndex) {
        case 0: return 0;
        case 1: return 45;
        case 2: return 90;
        case 3: return 105;
        case 4: return 120;
        default: return 120;
    }
}

export const getMinute = (match, evt) => {
    var baseMinute = getPeriodBaseMinute(evt.period);
    var period = match.periods[evt.period];
    var delta = new Date(evt.time) - new Date(period.start);
    return Math.floor(delta / 60000) + baseMinute; 
}
