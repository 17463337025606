import React from 'react'
import { ScrollTable, Table, Box } from '../components'

import WithFlyout from './WithFlyout'
import WithTournamentStandings from './WithTournamentStandings'
import GameContext from './GameContext'

import { lo } from '../common/utils'

import LocalizeText from './LocalizeText'


const TournamentStandings = ({ view, columns }) =>{
    
    return (
        <Box p={ ['s', 'm' ]}>
        <GameContext.Consumer>{ ({ game }) => (
            <WithTournamentStandings id={ game.tournament }>{ ({ isFetching, groups}) => (
                <WithFlyout>{ ({ push }) => (
                    


                            <ScrollTable>
                                <ScrollTable.Left>
                                
                                    <Table>

                                        { 
                                            groups.map((g, index) => {
                                                const standings = g.standings.filter(g => g.team);

                                                return (
                                                    <React.Fragment key={ index }>
                                                        <Table.Head>
                                                            <Table.Row>
                                                                <Table.Header>{ lo(g.group, 'name') }</Table.Header>
                                                            </Table.Row>
                                                        </Table.Head>
                                                        <Table.Body>
                                                        {
                                                            standings.map(s => (
                                                                <Table.Row key={ s.team.id }>
                                                                    <Table.Cell>
                                                                        <span  style={{ whiteSpace : 'nowrap'}}>
                                                                            <span className="meta">{ s.rank }</span>
                                                                            { ' ' }
                                                                            { view && <a onClick={ () => push("view", { view : view, args : { team : s.team } })}>{ lo(s.team, 'name') }</a> }
                                                                            { !view && lo(s.team, 'name') }
                                                                        </span>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            ))
                                                        }
                                                        </Table.Body>
                                                    </React.Fragment>
                                                )
                                            }
                                        )}

                                    </Table>
                                    
                                </ScrollTable.Left>
                                <ScrollTable.Right>
                                    <Table>

                                        { 
                                            
                                            groups.map((g, index) => {
                                                const standings = g.standings.filter(g => g.team);

                                                return (
                                                    <React.Fragment key={ index }>
                                                        <Table.Head>
                                                            <Table.Row>
                                                                { columns.map((c, index) => <Table.Header key={ index } center><LocalizeText text={ c.header} /></Table.Header>) }
                                                            </Table.Row>
                                                        </Table.Head>
                                                        <Table.Body>
                                                            {
                                                                standings.map(s => (
                                                                    <Table.Row key={ s.team.id }>
                                                                        { columns.map((c, index) => <Table.Cell key={ index } center>{ s[c.property] }</Table.Cell>) }
                                                                    </Table.Row>
                                                                ))
                                                            }
                                                        </Table.Body>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </Table>
                                </ScrollTable.Right>
                            </ScrollTable>
                        
                    
            )}</WithFlyout>
            )}
            </WithTournamentStandings>
        )}
        </GameContext.Consumer>
        </Box>
    )
}



export default TournamentStandings;