import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchJsonIfNeeded } from '../redux/json/actions'

const useJson = (url) => {
    const item = useSelector(state => state.json.byUrl[url]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchJsonIfNeeded(url));
    }, [url]);

    return item;
};

export default useJson;