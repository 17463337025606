import * as types from './types'
import * as competitionTypes from '../competition/types'

const defaultState = {
    options : [],
    items : [],
    selectedOption : 'friends',
    didFetch : false,
    isFetching : false
};

const recruitsReducer = (state = defaultState, action) =>
{


    switch (action.type) {



        /* Recruits */
        case competitionTypes.INVITE_ALL_SUCCESS:
                return {
                    ...state,
                    items : state.items.map(item => {
                        return { ...item, invited : true }
                    })
                };
        case competitionTypes.INVITE_SUCCESS:
            return {
                ...state,
                items : state.items.map(item => {
                    if (item.user.id === action.userId) {
                        return { ...item, invited : true }
                    } else {
                        return item;
                    }
                })
            };


        case types.SELECT_RECRUITMENT_OPTION:
            return { ...state,  selectedOption : action.value };             
        case types.FETCH_RECRUITS_REQUEST:
            return { ...state, isFetching : true };             
        case types.FETCH_RECRUITS_SUCCESS:
            return  {
                ...state, 
                options : action.payload.options || state.options, 
                items : action.payload.results.items,
                count : action.payload.results.count,
                links : action.payload.results.links,
                didFetch : true, 
                isFetching : false 
            };             
        case types.FETCH_RECRUITS_FAIL:
            return { ...state, isFetching : false };              
        case types.FETCH_MORE_RECRUITS_REQUEST:
            return { ...state, isFetching : true };
        case types.FETCH_MORE_RECRUITS_SUCCESS:
            return {
                ...state,
                isFetching : false,
                items : [
                    ...state.items, 
                    ...action.payload.results.items
                ],
                links : action.payload.results.links
            };
        case types.FETCH_MORE_RECRUITS_FAIL:
            return { ...state, isFetching : false };
        default:
            return state
    }
}

export default recruitsReducer;
