import React from 'react'

import { AccordionVersusHeader } from '../../../components/AccordionVersusHeader'

import MatchPointList from './MatchPointList'
import GameContext from '../../GameContext'
import { Number } from 'components'

import { lo } from 'utils'
import ContentBanner from '../../ContentBanner'

const VersusMatchPointList = ({ editMode, home, away, ...otherProps }) =>  {


    if (editMode) {
        return (
            <div>Not available in edit mode</div>
        )
    }



    return (
        <>
            <ContentBanner />
            <GameContext.Consumer>{ ({ tournament }) => {
                return (
                    <MatchPointList {...otherProps }>{ ({ match, growth }) => {
                        const home = match.participants.find(p => p.type === 'home');
                        const away = match.participants.find(p => p.type === 'away')

                        return <AccordionVersusHeader
                            team={ `${ home ? lo(home.team, 'name') : '?' } - ${ away ? lo(away.team, 'name') : '?' } ` }
                            score={ `${ match.properties.homeScore }-${ match.properties.awayScore }` }
                            growth={ <Number value={ growth } /> }
                        />

                    }}</MatchPointList>
                )
            }}</GameContext.Consumer>

        </>
    )
}


VersusMatchPointList.defaultProps = {
    home : 'Home',
    away : 'Away'
}


export default VersusMatchPointList;