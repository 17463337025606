import { createSelector } from 'reselect'

export const getGameFantasyTeams = createSelector(
    state => state.fantasyTeam.byGame[state.games.selected],
    state => state.entities.fantasyTeams,
    (fantasyTeamsBySelectedGame, fantasyTeamEntities) => {
        var el = fantasyTeamsBySelectedGame || { isFetching : false, didInvalidate : false, items : []}

        return (el.items || []).map(item => fantasyTeamEntities[item]);
    }
)

export const makeGetGameFantasyTeams = () => createSelector(
    (state, gameId) => state.fantasyTeam.byGame[gameId],
    state => state.entities.fantasyTeams,
    (fantasyTeamsBySelectedGame, fantasyTeamEntities) => {
        var el = fantasyTeamsBySelectedGame || { isFetching : false, didInvalidate : false, items : []}

        return {
            ...el,
            items : (el.items ||[]).map(item => fantasyTeamEntities[item])
        }
    }
)




export const makeGetLineup = () => createSelector(
    (state, fantasyTeamId) => state.fantasyTeam.lineups[fantasyTeamId],
    (state) => state.entities.players,
    (state) => state.entities.persons,
    (state) => state.entities.teams,
    (state) => state.entities.positions,
    (fantasyTeamLineup, playerEntities, personEntities, teamEntities, positionEntities) => {
        const { isFetching, didFetch, lineup } = fantasyTeamLineup || { lineup : [] }

        return {
            isFetching,
            didFetch,
            lineup : (lineup || []).map(l => {
                const player = playerEntities[l.player];
                const person = player && personEntities[player.person];
                const team = player && teamEntities[player.team];
                const position = player && positionEntities[player.position];
    
                return {
                    ...player,
                    person,
                    team,
                    position,
                    flags : l.flags
                }
            })
        }
    }
)
