// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D52PNE5HahQOCHfKDD2F {
    display: block;

    user-select: none;

    transition: opacity 0.15s linear;
    will-change: opacity;

    display: flex;
}

.f1tewy1pZMENoAGwNU36 {
    width: 36px;
    height: 36px;

    flex-shrink: 0;

    border-radius: 3px;
    border: 3px solid transparent;

    background-color: var(--button);
    color: var(--action);

    cursor: pointer;

    transition: border-color 0.15s linear;
    will-change: border-color;

    display: flex;
    align-items: center;
    justify-content: center;
}

.D52PNE5HahQOCHfKDD2F input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


.D52PNE5HahQOCHfKDD2F input:disabled ~ .f1tewy1pZMENoAGwNU36 {
    cursor: inherit;
    opacity: 0.5;
    background-color: var(--button-background-disabled);
    color: var(--button-foreground-disabled);
}

.D52PNE5HahQOCHfKDD2F input:not(:disabled) ~ .f1tewy1pZMENoAGwNU36:hover    
{
    border-color: var(--action-light);
}

.D52PNE5HahQOCHfKDD2F input:focus ~ .f1tewy1pZMENoAGwNU36 {
    border-color: var(--utility-blue);
}

.D52PNE5HahQOCHfKDD2F input:checked ~ .f1tewy1pZMENoAGwNU36 > * {
    opacity: 1;
}

.VLCtUnqPwgNv3nIIqM_c {
    font-size: 14px;
    line-height: 22px;
    margin-left: 12px;
    margin-top: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,iBAAiB;;IAEjB,gCAAgC;IAChC,oBAAoB;;IAEpB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,cAAc;;IAEd,kBAAkB;IAClB,6BAA6B;;IAE7B,+BAA+B;IAC/B,oBAAoB;;IAEpB,eAAe;;IAEf,qCAAqC;IACrC,yBAAyB;;IAEzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,SAAS;IACT,QAAQ;AACZ;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,mDAAmD;IACnD,wCAAwC;AAC5C;;AAEA;;IAEI,iCAAiC;AACrC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".container {\r\n    display: block;\r\n\r\n    user-select: none;\r\n\r\n    transition: opacity 0.15s linear;\r\n    will-change: opacity;\r\n\r\n    display: flex;\r\n}\r\n\r\n.faux {\r\n    width: 36px;\r\n    height: 36px;\r\n\r\n    flex-shrink: 0;\r\n\r\n    border-radius: 3px;\r\n    border: 3px solid transparent;\r\n\r\n    background-color: var(--button);\r\n    color: var(--action);\r\n\r\n    cursor: pointer;\r\n\r\n    transition: border-color 0.15s linear;\r\n    will-change: border-color;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.container input {\r\n    position: absolute;\r\n    opacity: 0;\r\n    cursor: pointer;\r\n    height: 0;\r\n    width: 0;\r\n}\r\n\r\n\r\n.container input:disabled ~ .faux {\r\n    cursor: inherit;\r\n    opacity: 0.5;\r\n    background-color: var(--button-background-disabled);\r\n    color: var(--button-foreground-disabled);\r\n}\r\n\r\n.container input:not(:disabled) ~ .faux:hover    \r\n{\r\n    border-color: var(--action-light);\r\n}\r\n\r\n.container input:focus ~ .faux {\r\n    border-color: var(--utility-blue);\r\n}\r\n\r\n.container input:checked ~ .faux > * {\r\n    opacity: 1;\r\n}\r\n\r\n.label {\r\n    font-size: 14px;\r\n    line-height: 22px;\r\n    margin-left: 12px;\r\n    margin-top: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `D52PNE5HahQOCHfKDD2F`;
export var faux = `f1tewy1pZMENoAGwNU36`;
export var label = `VLCtUnqPwgNv3nIIqM_c`;
export default ___CSS_LOADER_EXPORT___;
