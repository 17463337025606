import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { connect } from 'react-redux';

import GameContext from './GameContext'

import { fetchPlayerValuesIfNeeded } from '../redux/player/actions'
import { safeget } from 'utils';

class WithPlayerValues extends React.PureComponent {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.fetch(this.props);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.playerId !== this.props.playerId ||
            nextProps.gameId !== this.props.gameId
            ) {
            this.props.fetch(nextProps);
        }
    }

    render() {
        const { values } = this.props;
        return this.props.children({ values });
    }
}

WithPlayerValues.propTypes = {
    playerId : PropTypes.number.isRequired,
    gameId : PropTypes.number.isRequired
}

WithPlayerValues.defaultProps = {
}


export const makeGetValues = () => (
    createSelector([
        (state, props) => props.playerId,
        (state, props) => props.gameId,
        (state) => state.player.values,
        (state) => state.entities.playerValues,
    ],
    (playerId, gameId, playerValues, playerValueEntities) => {
        const o = safeget(playerValues, [playerId, gameId]);
        return (o || { items : []}).items.map(vid => {
            return playerValueEntities[vid];
        });

    })
)

const makeMapStateToProps = () => {
    const getValues = makeGetValues();
    
    return (state, ownProps) => ({
        values : getValues(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(props) {
            dispatch(fetchPlayerValuesIfNeeded(props.gameId, props.playerId));
        }
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(WithPlayerValues);
