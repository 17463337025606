
import React, { useState } from 'react';
import useKick from './useKick'

import { 
    Dialog, DialogHeader, DialogContent, DialogFooter,
    Checkbox,
    Box,
    Button 
} from 'components'
import { RegularText } from '../components';

import { useTranslator } from '../hooks/useTranslator'

const KickDialog = ({ open, competition, fantasyTeam, onClose }) => {
    const [kick] = useKick();
    const [ban, setBan] = useState(false);
    const t = useTranslator();

    const teamName = fantasyTeam && fantasyTeam.name;
    const userName = fantasyTeam && fantasyTeam.user.username;

    const onConfirm = () => {
        kick(competition.id, fantasyTeam.id, ban).then(() => onClose(true));
    }

    return (
        <Dialog open={ open }>
            <DialogHeader>{ t('competitions.kick_team_heading') }</DialogHeader>
            <DialogContent>
                <RegularText mb="m">{ t('competitions.kick_team_description', { teamName }) }</RegularText>

                <Box display="flex">
                    <Checkbox checked={ ban } onChange={ e => setBan(e.target.checked) } /> 
                    <Box flex="1" ml="m">
                        <RegularText>{ t('competitions.kick_user_description', { userName }) }</RegularText>
                    </Box>
                </Box>
            </DialogContent>
            <DialogFooter>
                <Button small transparent inline onClick={ () => onClose() }>{ t('common.cancel_caption') }</Button>
                <Button small inline onClick={ onConfirm }>{ t('common.confirm_caption') }</Button>
            </DialogFooter>
        </Dialog>
    )
}

export default KickDialog;