import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, EmailIdentity, AuthenticationHeader } from 'components'
import { BusyButton } from '../../molecules'
import { requestReset, goto } from '../../redux/auth/actions'
import { useTranslator } from '../../hooks/useTranslator'

export const Reset = () => {
    const dispatch = useDispatch()
    const translator = useTranslator();
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.reset)

    const onSubmitInternal = (e) => {
        e.preventDefault();
        dispatch(requestReset(email));
    }

    const onReject = ()=> {
        dispatch(goto('start'));
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>Nulstil adgangskode</AuthenticationHeader>
            
            <Form problems={ problems } onSubmit={ e => onSubmitInternal(e) }>
                

                <p>Glemt din adgangskode? Intet problem. Klik på knappen herunder, så sender vi dig en kode til at nulstille din adgangskode.</p>
                
                <EmailIdentity email={ email } rejectCaption="Ikke mig" onReject={ onReject }/>

                <BusyButton type="submit" busy={ isBusy } label={ translator('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ translator('authentication.continueButtonCaption' )}</BusyButton>
            </Form>
        </React.Fragment>
    )
}



