import React from 'react'
import PropTypes from 'prop-types'
import Checkable from './Checkable'

const CheckBoxButton = ({ checked, disabled, children, onChange }) => {
    return (
        <Checkable checkedIcon="checkboxChecked" uncheckedIcon="checkbox" checked={ checked } disabled={ disabled } onClick={ () => onChange(!checked) }>
            { children }
        </Checkable>
    )     
}

CheckBoxButton.propTypes = {
    checked : PropTypes.bool.isRequired,
    onChange : PropTypes.func.isRequired
}

CheckBoxButton.defaultProps = {
    checked : false
}

export default CheckBoxButton;