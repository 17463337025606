// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cRDnlrqShj4XFmNh3Rgv{display:none}@media screen and (min-width: 688px){.XaZELVyN8Z4m5CacX_C8{display:none}.cRDnlrqShj4XFmNh3Rgv{display:inline}}`, "",{"version":3,"sources":["webpack://./src/components/PersonName.scss"],"names":[],"mappings":"AAAA,sBAAA,YAAA,CAGA,qCACC,sBAAA,YAAA,CACA,sBAAA,cAAA,CAAA","sourcesContent":[".full { display: none; }\r\n\r\n\r\n@media screen and (min-width: 688px) {\r\n\t.abbreviated { display: none; }\r\n\t.full { display: inline; }\r\n}"],"sourceRoot":""}]);
// Exports
export var full = `cRDnlrqShj4XFmNh3Rgv`;
export var abbreviated = `XaZELVyN8Z4m5CacX_C8`;
export default ___CSS_LOADER_EXPORT___;
