import React, { useCallback } from 'react';

import { Checkbox } from 'components'

import { useAppConfig } from '../AppConfigProvider';

import styled from 'styled-components'

const StyledOptin = styled.div`
    display: flex;
    line-height: 24px;
    margin-bottom: 12px;
    font-size: 14px;

    > *:first-child {
        margin-right: 12px;
    }

    > *:nth-child(2) {
        margin-top: 6px;
    }
`

export const OptinList = ({ value, onChange }) => {
    const appConfig = useAppConfig();

    const optins = appConfig && appConfig.auth && appConfig.auth.optins || {};
    

    const toggleOptin = useCallback((key) => {
        let index = value.indexOf(key);
        if (index >= 0) {
            onChange([
                ...value.slice(0, index), 
                ...value.slice(index + 1)
            ]);
        }
        else {
            onChange([...value, key]);
        }
    })

    return (
        <React.Fragment>
            {
                Object.keys(optins).map((key, idx) => {
                    const optin = optins[key];
                    const checked = value.indexOf(key) >= 0

                    return (
                        <StyledOptin key={ key }>
                            <div><Checkbox checked={ checked } tabindex={ 2 + idx } onChange={ () => toggleOptin(key) }/></div>
                            <div dangerouslySetInnerHTML={{ __html: optin.text }}></div>
                        </StyledOptin>
                    )

                })
            }
        </React.Fragment>
    )

   
}