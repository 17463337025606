import * as types from './types'
import * as gameTypes from '../game/types'

const defaultState = {
    my : {
        items : null,
        isFetching : false,
        didInvalidate : false
    }
};

const friendsReducer = (state = defaultState, action) =>
{
    switch (action.type) {
        case types.INVALIDATE_FRIENDS:
            return  { ...state, my : { ...state.my, query: '', didInvalidate :  true } };
        case types.FETCH_FRIENDS_REQUEST:
            return  {  ...state, my : { ...state.my, query: action.query, isFetching :  true } };
        case types.FETCH_FRIENDS_SUCCESS:
            return  { 
                ...state,
                my : {
                    ...state.my,
                    query: action.query, 
                    isFetching :  false,
                    didInvalidate : false,
                    item : action.payload.result
                }
            }
        case types.FETCH_FRIENDS_FAIL:
            return { ...state, my : { ...state.my, query: action.query, isFetching : false } };
        case types.FETCH_MORE_FRIENDS_REQUEST:
                return  {  ...state, my : { ...state.my, isFetching :  true } };
            case types.FETCH_MORE_FRIENDS_SUCCESS:
                return  { 
                    ...state,
                    my : {
                        ...state.my,
                        isFetching :  false,
                        item : {
                            ...state.my.item,
                            items : [
                                ...state.my.item.items,
                                ...action.payload.result.items
                            ]
                        }
                    }
                }
            case types.FETCH_MORE_FRIENDS_FAIL:
                return { ...state, my : { ...state.my,  isFetching : false } };
        default:
            return state
    }
}

export default friendsReducer;
