import React, { useMemo } from 'react'

import { Datasource } from 'components'

const Repeater = ({ editMode, items, name, children }) => {
    const child = React.Children.only(children);

    if (editMode) {
        const editItems = [...new Array(5).keys()];

        return (
            editItems.map((i, index ) => {
                return (
                    <Datasource key={ index } value={{ [name] : i }}>
                        { React.cloneElement(child)  }
                    </Datasource>
                )
            })
        )
    }

    return (
        items.map((i, index ) => {
            return (
                <Datasource key={ index } value={{ [name] : i }}>
                    { React.cloneElement(child)  }
                </Datasource>
            )

        })
        
    )
}

export default Repeater;