import React from 'react'
import PropTypes from 'prop-types'

import TabMenu from './TabMenu';


class Tabs extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex : 0
        };
    }

    render() {
        const tab = React.Children.toArray(this.props.children)[this.state.selectedIndex];
        const items = React.Children.map(this.props.children, (c, index) => {
            return c.props.title
        })




        return (
            <div className="tabs">
                <div className="tabs__items">
                    <TabMenu selectedIndex={ this.state.selectedIndex } items={ items } onSelect={ (index) => this.setState({ selectedIndex : index }) }></TabMenu>
                </div>
                <div className="tabs__pane">{ tab }</div>
            </div>
        )
    }  
}

Tabs.propTypes = {
}

export default Tabs;