import axios from 'axios'
import auth from '../../common/auth';
import { fetchCurrentUserIfNeeded } from '../user/actions';

import * as types from  './types';

export const start = () => ({ type: types.START });
export const complete = () => ({ type: types.COMPLETE });
export const cancel = () => ({ type: types.CANCEL });
export const goto = (name) => ({ type: types.GOTO, name });
export const set = (data) => ({ type: types.SET, data });

const authenticationSuccess = (payload) => ({ type : types.AUTHENTICATION_SUCCESS, payload })

/* Check account holder */

const checkAccountRequest = (email) => ({ type: types.CHECK_ACCOUNT_REQUEST, email })
const checkAccountSuccess = (email, payload) => ({ type: types.CHECK_ACCOUNT_SUCCESS, email, payload })
const checkAccountFailure = (problems) => ({ type: types.CHECK_ACCOUNT_FAIL, problems })

export const checkAccount = (email) => (dispatch) => {
    dispatch(checkAccountRequest(email));

    axios.post(`/account/check`, { email }).then(response => {
        dispatch(checkAccountSuccess(email, response.data));
        dispatch(set({ email }));
        if (response.data.hasAccount) {
            dispatch(goto('signin'));    
        }
        else {
            dispatch(goto('signup'));
        }
    }, error => {
        dispatch(checkAccountFailure(error.response && error.response.data));
    });
}

/* Login */

const loginRequest = () => ({ type: types.LOGIN_REQUEST })
const loginSuccess = (o) => ({ type: types.LOGIN_SUCCESS, accessToken : o.token, username : o.username, roles : o.roles, expires : o.expires });
const loginFailure = () => ({ type: types.LOGIN_FAILURE })

export const login = () => {
  
  return (dispatch) =>  {
    dispatch(loginRequest())

    auth.signin().then(response => {
        dispatch(loginSuccess(response));
        dispatch(fetchCurrentUserIfNeeded());
    }, error => {
        dispatch(loginFailure());
    })
  }
  
}

/* Reset, Request */

const requestResetRequest = () => ({ type: types.REQUEST_RESET_REQUEST })
const requestResetSuccess = () => ({ type: types.REQUEST_RESET_SUCCESS })
const requestResetFailure = (problems) => ({ type: types.REQUEST_RESET_FAIL, problems })

export const requestReset = (email) => (dispatch) => {
    dispatch(requestResetRequest());

    axios.post(`/account/reset`, { email }).then(response => {
        dispatch(requestResetSuccess());
        dispatch(goto('reset_code'));
    }, error => {
        dispatch(requestResetFailure(error.response.data));
    });
}


/* Reset */

const resetRequest = () => ({ type: types.RESET_REQUEST })
const resetSuccess = () => ({ type: types.RESET_SUCCESS })
const resetFailure = (problems) => ({ type: types.RESET_FAIL, problems })

export const reset = (email, code, password) => (dispatch) => {
    dispatch(resetRequest());

    axios.post(`/account/reset`, { email, code, password }).then(response => {
        auth.setAuth(response.data);          

        dispatch(resetSuccess());

        legacyLogin(response.data.token).then(() => {
            dispatch(authenticationSuccess(response.data));
            dispatch(fetchCurrentUserIfNeeded());
            dispatch(complete());

            window.dispatchEvent(new Event('signin'));
        })
    }, error => {
        dispatch(resetFailure(error.response.data));
    });
}


/* Signup - Step 1 - Email */

const signupSubmitEmailRequest = () => ({ type: types.SIGNUP_SUBMIT_EMAIL_REQUEST })
const signupSubmitEmailSuccess = () => ({ type: types.SIGNUP_SUBMIT_EMAIL_SUCCESS })
const signupSubmitEmailFailure = (problems) => ({ type: types.SIGNUP_SUBMIT_EMAIL_FAIL, problems })

export const signupSubmitEmail = (email) => (dispatch) => {
    dispatch(signupSubmitEmailRequest());

    axios.post(`/account/flow/0`, { email }).then(response => {
        dispatch(signupSubmitEmailSuccess());
        dispatch(goto('signup_code'));
    }, error => {
        dispatch(signupSubmitEmailFailure(error.response.data));
    });
}

/* Signup - Step 2 - Code */

const signupSubmitCodeRequest = () => ({ type: types.SIGNUP_SUBMIT_CODE_REQUEST })
const signupSubmitCodeSuccess = (code) => ({ type: types.SIGNUP_SUBMIT_CODE_SUCCESS, code })
const signupSubmitCodeFailure = (problems) => ({ type: types.SIGNUP_SUBMIT_CODE_FAIL, problems })

export const signupSubmitCode = (email, code) => (dispatch) => {
    dispatch(signupSubmitCodeRequest());

    axios.post(`/account/flow/1`, { email, code }).then(response => {
        dispatch(signupSubmitCodeSuccess(code));
        dispatch(goto('signup_username'));
    }, error => {
        dispatch(signupSubmitCodeFailure(error.response.data));
    });
}


/* Signup - Step 3 - Complete */

const signupSubmitRequest = () => ({ type: types.SIGNUP_SUBMIT_REQUEST })
const signupSubmitSuccess = () => ({ type: types.SIGNUP_SUBMIT_SUCCESS })
const signupSubmitFailure = (problems) => ({ type: types.SIGNUP_SUBMIT_FAIL, problems })

export const signupSubmit = (email, code, username, password, optins) => (dispatch) => {
    dispatch(signupSubmitRequest());

    axios.post(`/account`, { email, code, username, password, optins }).then(response => {
        auth.setAuth(response.data);          

        dispatch(signupSubmitSuccess());

        legacyLogin(response.data.token, true).then(() => {
            dispatch(authenticationSuccess(response.data));
            dispatch(fetchCurrentUserIfNeeded());
            dispatch(complete());

            window.localStorage.setItem('email', email);
            window.dispatchEvent(new Event('signin'));
        })
        
    }, error => {
        dispatch(signupSubmitFailure(error.response.data));
    });
}


/* Facebook login */

const facebookLoginRequest = (accessToken) => ({ type: types.FACEBOOK_LOGIN_REQUEST, accessToken })
const facebookLoginSuccess = () => ({ type: types.FACEBOOK_LOGIN_SUCCESS })
const facebookLoginFailure = (problems) => ({ type: types.FACEBOOK_LOGIN_FAIL, problems })

export const facebookLogin = (accessToken) => (dispatch) => {
    dispatch(facebookLoginRequest(accessToken));

    axios.post(`/facebook/login`, { accessToken }).then(response => {
        auth.setAuth(response.data);          

        dispatch(facebookLoginSuccess());

        legacyLogin(response.data.token).then(() => {
            dispatch(authenticationSuccess(response.data));
            dispatch(fetchCurrentUserIfNeeded());

            window.dispatchEvent(new Event('signin'));
        });


        dispatch(complete());

    }, error => {
        if (error.response && error.response.status === 401) {
            dispatch(goto('facebook'))
        }
        else {
            dispatch(facebookLoginFailure(error.response && error.response.data));
        }
    });
}


const facebookSignupRequest = () => ({ type: types.FACEBOOK_SIGNUP_REQUEST })
const facebookSignupSuccess = () => ({ type: types.FACEBOOK_SIGNUP_SUCCESS })
const facebookSignupFailure = (problems) => ({ type: types.FACEBOOK_SIGNUP_FAIL, problems })


export const facebookSignup = (username, optins) => (dispatch, getState) => {
    dispatch(facebookSignupRequest());

    const { accessToken } = getState().auth.facebook;

    axios.post(`/facebook/signup`, { accessToken, username, optins }).then(response => {
        auth.setAuth(response.data);          

        dispatch(facebookSignupSuccess());

        legacyLogin(response.data.token, true).then(() => {
            dispatch(authenticationSuccess(response.data));
            dispatch(fetchCurrentUserIfNeeded());

            window.dispatchEvent(new Event('signin'));
        });

        dispatch(complete());


    }, error => {
          dispatch(facebookSignupFailure(error.response.data));
    });
}




/* Signup - Step 3 - Complete */

const signinRequest = () => ({ type: types.SIGNIN_REQUEST })
const signinSuccess = () => ({ type: types.SIGNIN_SUCCESS })
const signinFailure = (problems) => ({ type: types.SIGNIN_FAIL, problems })

export const signin = (email, password) => (dispatch) => {
    dispatch(signinRequest());

    axios.post(`/authenticate`, { email, password }).then(response => {
        auth.setAuth(response.data);          

        dispatch(signinSuccess());

        legacyLogin(response.data.token).then(() => {
            dispatch(authenticationSuccess(response.data));
            dispatch(fetchCurrentUserIfNeeded());
            dispatch(complete());

            window.localStorage.setItem('email', email);
            window.dispatchEvent(new Event('signin'));
        })
        
    }, error => {
        dispatch(signinFailure(error.response && error.response.data));
    });
}



const legacyLogin = (token, registration) => new Promise((resolve, reject) => {
    const url = registration ?
                    window.swush.legacyLoginEndpoint + '?registration=true' :
                    window.swush.legacyLoginEndpoint;

    axios.post(url, { token }).then(response => {
        console.log('Legacy login succeeded');
        
        resolve();

        if (response.data.redirect) {
            location.href = response.data.redirect;
        }
    }, _ => {
        console.log('Legacy login failed');
        reject();
    });
})