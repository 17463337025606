import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { createSelector } from 'reselect'

import { fetchViewByNameIfNeeded } from '../redux/view/actions'

class WithView extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.preloaded) {
            this.props.fetch(this.props.name);
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.preloaded && nextProps.name !== this.props.name) {
            this.props.fetch(nextProps.name);
        }
    }

    render() {
        const { view, children } = this.props;
        
        if (!view)
            return null;
        

        return (
            <div data-view-name={ this.props.name }>
                { children({ 
                    isFetching : view.isFetching, 
                    view : view.item 
                    }) }
            </div>
        );
    }
}

export const makeGetView = () => (
    createSelector([
        (state, props) => props.name,
        (state) => state.views.byName,
        (state) => state.entities.views
    ],
    (name, byName, viewEntities) => {
        const { isFetching, item } = byName[name] || { item : 0, isFetching : false };
        const view =  item > 0 ? viewEntities[item] : null;
        const viewContent = view ? JSON.parse(view.content) : null;

        return {
            isFetching,
            item : viewContent
        }
    })
)

const makeMapStateToProps = () => {
    const getView = makeGetView();
    
    return (state, ownProps) => ({
        view : getView(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        fetch(name) {
            if (name) {
                dispatch(fetchViewByNameIfNeeded(name));
            }
        }
    }
}

WithView.propTypes = {
    name : PropTypes.any,
    preloaded : PropTypes.bool.isRequired
}

WithView.defaultProps = {
    preloaded : false
}


export default connect(makeMapStateToProps, mapDispatchToProps)(WithView);

