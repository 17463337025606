// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tYnsA_y0Y6Yw0aHxeZVz { 
    position: relative;
}


.Hibx2S2WM1MPghPzko4M {
    position: absolute;
    top: 24px;
    left: 50%;

    width: 240px;
    margin-left: -120px;

    background: #fff;
    padding: 12px;
    border-radius: 3px;

    text-align: center;
    color: #000;
    font-size: 11px;

    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ChangeNotification.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;;IAET,YAAY;IACZ,mBAAmB;;IAEnB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;;IAElB,kBAAkB;IAClB,WAAW;IACX,eAAe;;IAEf,eAAe;AACnB","sourcesContent":["\r\n.container { \r\n    position: relative;\r\n}\r\n\r\n\r\n.notification {\r\n    position: absolute;\r\n    top: 24px;\r\n    left: 50%;\r\n\r\n    width: 240px;\r\n    margin-left: -120px;\r\n\r\n    background: #fff;\r\n    padding: 12px;\r\n    border-radius: 3px;\r\n\r\n    text-align: center;\r\n    color: #000;\r\n    font-size: 11px;\r\n\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `tYnsA_y0Y6Yw0aHxeZVz`;
export var notification = `Hibx2S2WM1MPghPzko4M`;
export default ___CSS_LOADER_EXPORT___;
