// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s7yHQV6ls5IeLGaEYuXk {
    height: 0;
    min-height: 0;
    overflow: hidden;
    transition: height 0.25s ease-in-out, min-height 0.25s ease-in-out;
    
}
`, "",{"version":3,"sources":["webpack://./src/components/ExpandablePanel.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,aAAa;IACb,gBAAgB;IAChB,kEAAkE;;AAEtE","sourcesContent":[".container {\r\n    height: 0;\r\n    min-height: 0;\r\n    overflow: hidden;\r\n    transition: height 0.25s ease-in-out, min-height 0.25s ease-in-out;\r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `s7yHQV6ls5IeLGaEYuXk`;
export default ___CSS_LOADER_EXPORT___;
