import React, {useCallback} from 'react'
import { ToolbarButton, ToolbarButtonGroup, Icon } from 'components'
import Enrollments from './Enrollments'
import { Redirect } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { fetchGame } from '../redux/game/actions';


import { 
    Flyout,
    Avatar,
    FlyoutOverlayContent,
    TabBar, 
    TabBarItem,
    Text,
    Box,
    XButton
} from 'components'

import useCompetition from './useCompetition'

import Info from './Info'
import useModal from '../containers/useModal';
import { Panel } from '../components';
import useCurrentGame from '../containers/useCurrentGame';
import useAuthenticator from '../containers/useAuthenticator'
import { useTranslator } from '../hooks/useTranslator'

const Header = ({ competition }) => {
    const t = useTranslator();

    return (
        <Box display="flex" height="s400" alignItems="center" px="s300" boxShadow="flyout">
            <Avatar size="small" name={ competition.name } mr="s200" />
            <Box>
                <Text fontWeight="bold">{ competition && competition.name }</Text>
                <Text size="xs">{ t(`competitions.format_${ competition.type }`) }</Text>
            </Box>
        </Box>
    )
}

const ExtendedHeader = ({ competition }) => {
    const t = useTranslator();

    return (
        <Box minHeight="180px" display="flex" alignItems="center" justifyContent="center">
            <Box textAlign="center">
                <Avatar size="huge" variant="rounded" name={ competition && competition.name } boxShadow="flyout"  mb="s400"/>
                <Text fontWeight="bold"> { competition && competition.name }</Text>
                <Text size="xs">{ t(`competitions.format_${ competition.type }`) }</Text>
            </Box>
        </Box>
    )
}



const CompetitionPreviewFlyout = ({ competitionId, password, onClose }) => {
    const [competition] = useCompetition(competitionId);
    const [showModal] = useModal();
    const game = useCurrentGame();
    const [authenticate] = useAuthenticator();
    const t = useTranslator();
    
    
    const toolbar = (
        <ToolbarButtonGroup>
            <ToolbarButton onClick={ onClose } >
                <Icon type="close"/>
            </ToolbarButton>
        </ToolbarButtonGroup>
    )

    const onEnroll = () => {
        
        if (authenticate()) {
            showModal('enrollment', { competitionId, password })
        }
    }

    return (
        <Flyout>
            <FlyoutOverlayContent  header={ <Header competition={ competition } /> } toolbar={ toolbar }>
                <ExtendedHeader competition={ competition} onEnroll={ onEnroll } />
                <Panel>
                    <Box textAlign="center">
                        <XButton variant="primary" onClick={ onEnroll } textTransform="uppercase">{ t('competitions.join_caption') }</XButton>
                    </Box>
                    <Info competition={ competition }/>
                </Panel> 
                <TabBar identity themeName={ game && game.theme || "default" }>
                    <TabBarItem title={ t('competitions.enrolled') }>
                        <Box flex={ 1 } display="flex" flexDirection="column">
                            <Enrollments competition={ competition } p="m" flex={ 1 } />
                        </Box>
                    </TabBarItem>
                </TabBar>
            </FlyoutOverlayContent>
        </Flyout>
    )
}

export default CompetitionPreviewFlyout;
