import axios from 'axios'
import { normalize } from 'normalizr';

import { tournamentSchema, tournamentStandingSchema } from '../../schemas';

import * as types from './types';

// Tournament

const fetchTournamentRequest = (id) => ({ type: types.FETCH_TOURNAMENT_REQUEST, id})
const fetchTournamentSuccess = (id, payload) => ({ type: types.FETCH_TOURNAMENT_SUCCESS, id, payload })
const fetchTournamentFailure = (id) => ({ type: types.FETCH_TOURNAMENT_FAILURE, id })

const fetchTournament = (id) => (dispatch) => {
    dispatch(fetchTournamentRequest(id))

    axios.get(`tournaments/${ id }`).then(response => {
      dispatch(fetchTournamentSuccess(id, normalize(response.data, tournamentSchema)));
    }, error => {
      dispatch(fetchTournamentFailure(id));
    });
}


const shouldFetchTournament = (state, id) => {
  const { byId } = state.tournaments || { byId : {} };
  const item = byId[id];

  if (!item) {
      return true;
  }
  else if (item.isFetching) {
      return false;
  }
  else {
      return item.didInvalidate;
  }
}


export const fetchTournamentIfNeeded = (id) => (dispatch, getState) => {
  if (shouldFetchTournament(getState(), id)) {
      dispatch(fetchTournament(id));
  }
}



// Tournament Standings

const fetchTournamentStandingsRequest = (id) => ({ type: types.FETCH_TOURNAMENT_STANDINGS_REQUEST, id})
const fetchTournamentStandingsSuccess = (id, payload) => ({ type: types.FETCH_TOURNAMENT_STANDINGS_SUCCESS, id, payload })
const fetchTournamentStandingsFailure = (id) => ({ type: types.FETCH_TOURNAMENT_STANDINGS_FAILURE, id })

const fetchTournamentStandings = (id) => (dispatch) => {
    dispatch(fetchTournamentStandingsRequest(id))

    axios.get(`tournaments/${ id }/standings`).then(response => {
      dispatch(fetchTournamentStandingsSuccess(id, normalize(response.data, [tournamentStandingSchema])));
    }, error => {
      dispatch(fetchTournamentStandingsFailure(id));
    });
}


const shouldFetchTournamentStandings = (state, id) => {
  const { standingsById } = state.tournaments || { standingsById : {} };
  const item = standingsById[id];

  if (!item) {
      return true;
  }
  else if (item.isFetching) {
      return false;
  }
  else {
      return item.didInvalidate;
  }
}


export const fetchTournamentStandingsIfNeeded = (id) => (dispatch, getState) => {
  if (shouldFetchTournamentStandings(getState(), id)) {
      dispatch(fetchTournamentStandings(id));
  }
}
