import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, FormField, PasswordInput, EmailIdentity, HelpLabel, AuthenticationHeader, LinkButton, Fieldset } from 'components'
import { BusyButton } from '../../molecules'

import { signin, goto } from '../../redux/auth/actions'

import { useTranslator } from '../../hooks/useTranslator'


export const SignIn = () => {
    const dispatch = useDispatch();
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.signin);
    const [password, setPassword] = useState('');
    const t = useTranslator();

    const submittable = password && password.length > 0;

    const ref = useRef()

    useEffect(() => {
        ref.current.focus();
    }, [ref.current])

    const onSubmitInternal = (e) => {
        e.preventDefault();
        if (submittable ) {
            dispatch(signin(email, password));
        }
    }

    const onReject = ()=> {
        dispatch(goto('start'));
    }

    const onRequestReset = () => {
        
        
        //dispatch(goto('reset'));
    }

    let nProblems = problems && {
        ...problems,
        password : problems['password'] || [problems.title]
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.signInHeader') }</AuthenticationHeader>
            <EmailIdentity email={ email } rejectCaption={ t('authentication.notMeButtonCaption') } onReject={ onReject }/>
            <Form problems={ nProblems } onSubmit={ e => onSubmitInternal(e) }>
                <Fieldset>
                    <FormField name="password">
                        <HelpLabel text={ t('authentication.passwordLabel') }>
                            <LinkButton type="button" size="xs" variant="transparent" href="/da/account/reset" onClick={ onRequestReset }>{  t('authentication.forgottenButtonCaption') }</LinkButton>
                        </HelpLabel>
                        <input type="text" autoComplete="username" value={ email } style={{ display: 'none' }} />

                        <PasswordInput ref={ ref } autoComplete="current-password" name="password" value={ password } tabindex={ 1 } onChange={ (e) => setPassword(e.target.value)} />
                    </FormField>
                </Fieldset>
                <BusyButton type="submit" disabled={ !submittable } busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ t('authentication.signInButtonCaption')}</BusyButton>
            </Form>
        </React.Fragment>
    )
}


