import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

class SidebarPanel extends React.PureComponent {

    render() {



        return (

            <div className="sidebar__side">
                <div className="sidebar__head">
                    { this.props.title }

                    <a className="sidebar__close" onClick={ this.props.onClose }>
                        <Icon type="close"/>
                    </a>
                </div>
                <div className="sidebar__side_content">
                    { this.props.children }
                </div>
            </div>

        )     
    }
}


SidebarPanel.propTypes = {
    title : PropTypes.node
}

SidebarPanel.defaultProps = {
}

export default SidebarPanel;