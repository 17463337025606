import React, { useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import classNames from 'classnames';

import { animated, useTransition } from 'react-spring';

import { Text } from '../Typography'

import useOnClickOutside from '../useOnClickOutside'
import useOnEscape from '../useOnEscape'

import Box from '../Box'

import * as styles from './Dropdown.module.css'


const defaultItemRenderer = item => {
    return (
        <Box display="flex" height="s400" alignItems="center" px="s300" overflow="hidden">
            <Text fontWeight={ item.selected ? 'bold' : 'normal' }> { item.label }</Text>
        </Box>
    )
}

const useOnWheelOutside = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
              return;
            }
    
            handler(event);
        };
    
        document.addEventListener('wheel', listener);
    
        return () => {
            document.removeEventListener('wheel', listener);
        };
    }, [ref, handler]);
}


export const Dropdown = ({ items, align, onSelect, onRenderItem, expand, themeName, children }) => {
    const [open, setOpen] = useState(false);
    const tetherRef = useRef();
    const ref = useRef();
    
    const close = useCallback(() => {
        setOpen(false) 
    }, []);

    useOnEscape(close);
    useOnClickOutside(ref, close);
    useOnWheelOutside(ref, close);


    useEffect(() => {
        window.addEventListener('scroll', close);
        

        return () => {
            window.removeEventListener('scroll', close);
        
        }

    }, [])





    const handleOpen = () => {

        const tEl = tetherRef.current;
        var tRect = tEl.getBoundingClientRect();
        

        var el = ref.current;

        
        el.style.pointerEvents = 'none';
        el.style.left = tRect.left  + 'px';
        el.style.top = tRect.top  + 'px';
        el.style.width = tRect.width  + 'px';
        el.style.height = tRect.height  + 'px';
        
        setOpen(true);
    }

    const handleSelect = (e, value) => {
        if (open) {
            close();
            onSelect(value);
        }
    }

    const transitions = useTransition(open, null, {
        from: { opacity: 0, transform: `translate3d(0, -12px, 0)` },
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -12px, 0)' },
    });

    const className = classNames(
        styles.container,
        {
            [styles.expand] : expand
        }
    )

    const menuContainerClassName = classNames(
        styles['menu-container'],
        {
            [styles.open] : open,
            [styles.left] : align === 'left',
            [styles.right] : align === 'right'
        }
    )



    return  (
        <React.Fragment>
            <div className={ className } ref={ tetherRef }>
                { children({ open : handleOpen, isOpen : open }) }
            </div>

            {
                ReactDOM.createPortal(
                    <div className={ menuContainerClassName } ref={ ref }>
                        {
                        transitions.map(({ item, key, props }) => item && 
                            <animated.div className={ styles.menu } key={key} style={{ ...props }}>
                                { 
                                    items.map((mi, index) => {
                                        if (mi.separator) {
                                            return (
                                                <div className={ styles.separator } key={ index }>
                                                    <Text size="xs">{ mi.separator }</Text>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className={ styles.item } key={ index } selected={ mi.selected } disabled={ mi.disabled } onClick={ e => handleSelect(e, mi.value) } >
                                                    { onRenderItem(mi) }
                                                </div>
                                            )
                                        }
                                    }) 
                                }   
                            </animated.div>
                        )
                    }
                    </div>
                            

               
        , document.getElementById('modal-root'))
            }
        </React.Fragment>
            
    );
    
    
}

Dropdown.propTypes = {
    align : PropTypes.string,
    items : PropTypes.array,
    onSelect : PropTypes.func,
    selectable : PropTypes.bool,
    onRenderItem : PropTypes.func
}

Dropdown.defaultProps = {
    align : 'right',
    selectable : false,
    items : [],
    onRenderItem : defaultItemRenderer
}
