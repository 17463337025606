import React from 'react'

import { CSSVariables } from '../../../components'

import RoutedMobileSiteHeader from './RoutedMobileSiteHeader'
import GameContext from '../../GameContext';

const RoutedMobileGameHeader = ({ themeName, onShowMenu  }) => {

    
    return (
        <GameContext.Consumer>
            { ({ gameConfiguration}) => (
                <CSSVariables vars={ gameConfiguration ? gameConfiguration.theme : {} }>
                        <RoutedMobileSiteHeader themeName={ themeName } onShowMenu={ onShowMenu }></RoutedMobileSiteHeader>
                </CSSVariables>
            )}
        </GameContext.Consumer>
            
    )
}

RoutedMobileGameHeader.propTypes = {
}



export default RoutedMobileGameHeader;
