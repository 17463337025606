import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'

import GameContext from './GameContext'

import {fullName} from 'utils'

class WithTeamPlayers extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children(this.props.players);
    }
}


WithTeamPlayers.propTypes = {
    id : PropTypes.any.isRequired,
    position: PropTypes.string
}


export const makeGetPlayers = () => (
    createSelector([
        (state, props) => props.id,
        (state, props) => props.tournamentId,
        (state, props) => props.position,
        (state) => state.entities.tournaments,
        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.teams,
        (state) => state.entities.positions
    ], 
    (id, tournamentId, position, tournaments, playerEntities, personEntities,teamEntities, positionEntities) => { 
        const tournament = tournaments[tournamentId] || { players : []};
        const teamPlayers = tournament.players.map(p => playerEntities[p]).filter(p => p.team === id);
        const team = teamEntities[id];


        return teamPlayers.map(p => {
            const person = personEntities[p.person];
            const position = positionEntities[p.position];

            return {
                ...p,
                person : {
                    ...person,
                    fullName : fullName(person)
                },
                team,
                position
            }
        }).filter(p => !position || p.position.slug === position).sort((a, b) => a.person.fullName.localeCompare(b.person.fullName));
    })
);



const makeMapStateToProps = () => {
    const getPlayers = makeGetPlayers();

    return (state, ownProps) => ({
        players : getPlayers(state, ownProps)
    })
}


const ConnectedWithTeamPlayers = connect(makeMapStateToProps)(WithTeamPlayers);


const WithTeamPlayersWrapper = ({ children, ...otherProps }) => {
    const { game } = useContext(GameContext);

    return <ConnectedWithTeamPlayers tournamentId={ game.tournament } {...otherProps}>{ children }</ConnectedWithTeamPlayers>
}


export default WithTeamPlayersWrapper;

