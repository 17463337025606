import * as types from './types'

const defaultState = {
    byUrl : {}
};

export default function configs (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_CONFIG_REQUEST:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        isFetching : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_CONFIG_SUCCESS:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        isFetching : false,
                        didInvalidate : false,
                        item: action.payload
                    }
                }
            };
        case types.FETCH_CONFIG_FAILURE:
            return { 
                ...state,
                byUrl : {
                    ...state.byUrl,
                    [action.url] : {
                        ...state.byUrl[action.url],
                        isFetching : false,
                        didInvalidate : false
                    }
                }
            };
        default:
            return state
    }
}

