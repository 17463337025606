import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SoccerClock, MatchCard, Progress, EventDate, Icon, Pill } from '../../components';
import Growth from '../../components/Growth';
import WithMatchEvents from '../WithMatchEvents';

import SoccerPeriodStatus from './SoccerPeriodStatus';

import { lo, minute, fullName } from '../../common/utils'

import MatchOdds from '../MatchOdds'

import translatable from '../../hocs/translatable'
import useMatchFantasyEventNotifications from '../../hooks/useMatchFantasyEventNotifications'
import CardToast from '../../components/CardToast';

import MatchStatus from '../../containers/MatchStatus'

const SoccerLiveFooter = ({ period, minutes, seconds, paused}) => {

    return (
        <>
            <div className="action"><SoccerPeriodStatus period={ period } minutes={ minutes } seconds={ seconds } paused={ paused } /></div>
            <div>
                <MatchStatus status="live"></MatchStatus>
            </div>
        </>
    )
}


const SoccerMatchCard = React.memo(({ t, game, hideGroup, item, onClick }) => {

    const properties = item.properties || {}
    const homeScore = properties.homeScore || '0';
    const awayScore = properties.awayScore || '0';
    
    const fantasyEventNotifications = useMatchFantasyEventNotifications(item.id);
    const toasts = fantasyEventNotifications.filter(e => e.type && e.type.notify).map(e => ({ key : e.id, event : e }));
 

    const renderToast = ({ event }) => {
        return(
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <div style={{ flex: 1 }}>
                    { event.minute }' - { event.type.shortTitle }, { fullName(event.player.person) }
                </div>
                <Growth value={ event.type.value } treatZeroAsMissing={ true } />
            </div>
            )
    }


    return (

        

                <SoccerClock match={ item }>{ ({ period, minutes, seconds, nominalMinutes, nominalSeconds, stops, paused }) => (
                    <CardToast toasts={ toasts } keySelector={ item => item.id } render={ renderToast }>
                        
                            <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
                                
                                <MatchCard.VersusEventContent 
                                    
                                    home={ lo(item.participants['home'], 'name') }
                                    away={ lo(item.participants['away'], 'name') }
                                    homeScore={ homeScore }
                                    awayScore={ awayScore }
                                    group={ !hideGroup && lo(item.group, 'name') }
                                    status={ item.status }
                                    >
                                </MatchCard.VersusEventContent>

                                { 
                                    item.status === 'live' &&
                                    <MatchCard.Progress>
                                        <Progress stops={ stops.map(s => s * 60) } value={ nominalMinutes * 60 + nominalSeconds } maximum={ stops[stops.length - 1] * 60 } />
                                    </MatchCard.Progress>
                                }
                                <MatchCard.Footer>
                                    { item.status === 'pending' && <MatchOdds odds={ item.odds } /> }
                                    { (item.status === 'updated' || item.status === 'completed') && <span>{ t('soccer.matchCompleted') }</span> }
                                    { item.status === 'live' && <SoccerLiveFooter period={ period } minutes={ minutes } seconds={ seconds } paused={ paused } /> }
                                    { item.status !== 'live' && <span><EventDate date={ new Date(item.start) } displayDate={ false } /></span> }
                                </MatchCard.Footer>
                                
                                { 
                                    item.status === 'updated' &&
                                    <MatchCard.Icon>
                                        <Icon type="flag" size="1-2x" />
                                    </MatchCard.Icon>
                                }
                            </MatchCard> 
                        
                        
                    </CardToast>
                )}</SoccerClock>   

    )
})

export default translatable(SoccerMatchCard);