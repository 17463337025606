import React from 'react'

import styled from 'styled-components'

const StyledContainer = styled.div`
    position: relative;
`

const StyledSticker = styled.div`
    position: absolute;

    top: 0;
    left: 0;
`



const WithSticker = ({ sticker, children }) => {

    return (
        <StyledContainer>
            <StyledSticker>{ sticker }</StyledSticker>

            { children }
        </StyledContainer>
    )
}

export default WithSticker;