import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { camelize } from 'utils'

import PlayerContext from '../../PlayerContext'
import GameContext from '../../GameContext'
import { Datasource } from 'components'

import WithPlayer from '../../WithPlayer'
import WithPlayerValues from '../../WithPlayerValues'
import WithPlayerEvents from '../../WithPlayerEvents'
import WithPlayerFantasyEvents from '../../WithPlayerFantasyEvents'
import WithSchedule from '../../WithSchedule';


const PlayerPassport = ({ name, editMode, children }) => {
    if (editMode) {
        return (
            <Datasource value={{ [name] :  {} }}>
                {children }
            </Datasource> 
        )
    }
    else {
        const { id } = useContext(PlayerContext)
        const { game } = useContext(GameContext);

        

        return (
            <WithPlayer id={ id }>{ (player) => (
                <WithPlayerFantasyEvents gameId={ game.id } playerId={ id }>{ ({ fantasyEvents }) => (
                    <WithPlayerValues gameId={ game.id } playerId={ id }>{ ({ values }) => {

                        

                                
                                const fantasyEventsByRound = fantasyEvents.reduce((agg, e) => {
                                    if (!e.type)
                                        return agg;
                        
                                    const name = camelize(e.type.name);

                                    let roundIndex = e.round - 1;
                                    let round = agg[roundIndex];
                                    if (typeof round === 'undefined') {
                                        round = {};
                                        agg[roundIndex] = round;
                                    }

                                    round[name] = typeof round[name] === 'undefined' ? e.amount : round[name] + e.amount;
                                    return agg;
                                }, {});


                                const statistics = game.rounds.map((r, index) => {
                                    const rval = values[index];
                                    return {
                                        ...fantasyEventsByRound[index],
                                        growth : rval && rval.growth,
                                        value : rval && rval.value
                                    }
                                })

                                const passport =  {
                                    player,
                                    ...values[values.length - 1],
                                    statistics
                                };

                                return (
                                    <Datasource value={{ [name] : passport }}>
                                        { children }
                                    </Datasource>
                                )
                        }}</WithPlayerValues>
                    )}</WithPlayerFantasyEvents>
                )}</WithPlayer>
        )
    }
}




PlayerPassport.propTypes = {
    name : PropTypes.string.isRequired
}

export default PlayerPassport;

