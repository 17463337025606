import React from 'react'

import ScrollContainer from './ScrollContainer'

import * as styles from './ScrollTable.scss'


const ScrollTableLeft = ({ style, children }) => {
    return <div  className={ styles.left } style={ style }>{ children } </div>
}

const ScrollTableRight = ({ style, children }) => {
    return <div  className={ styles.right } style={ style }><ScrollContainer>{ children }</ScrollContainer> </div>
}



class ScrollTable extends React.PureComponent{
    static Left = ScrollTableLeft;
    static Right = ScrollTableRight;

    render() {
        return (
            <div>
                { this.props.label && <div className={ styles.label}>{ this.props.label }</div> }
            <div className={ styles.scrollTable }>
                    
                    { this.props.children }
            </div>
           </div>
        )
    }  
}

ScrollTable.propTypes = {
}

ScrollTable.defaultProps = {
}

export default ScrollTable;
