import React, { useState } from 'react'

import { Box, Textarea, XButton, ExpandablePanel } from 'components';
import { useTranslator } from '../../hooks/useTranslator'

const initialState = { text : '' };

const CreatePost = ({ show, onCreate, onCancel }) => {
    const [item, setItem] = useState({ text: '' });
    const t = useTranslator();

    const onCommit = () => {
        onCreate(item);
            setItem(initialState)   ;
    }

    const handleKeyDown = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            onCreate(item);
            setItem(initialState)   ;
        }
    }

    return (
        <ExpandablePanel open={ show }>
            <Box px="m" pb="m">
                <Box display="flex" mb="m">
                    <Textarea value={ item.text } placeholder={ t('messageboard.leave_comment_placeholder') } rows={ 1 } onKeyDown={ handleKeyDown } onChange={ e => setItem({ text : e.target.value })}></Textarea>
                </Box>
                <Box textAlign="right">
                    <XButton size="xs" variant="transparent" textTransform="uppercase" onClick={ onCancel }>{ t('common.cancel_caption') }</XButton>
                    <XButton size="xs" variant="primary" textTransform="uppercase" onClick={ onCommit }>{ t('common.save_caption') }</XButton>
                </Box>
            </Box>
        </ExpandablePanel>
    )
}

export default CreatePost;