// Fetch message board posts

export const FETCH_POSTS_REQUEST       = 'messageBoard/posts/fetch/request';
export const FETCH_POSTS_SUCCESS       = 'messageBoard/posts/fetch/success';
export const FETCH_POSTS_FAIL          = 'messageBoard/posts/fetch/fail';

export const INVALIDATE_POSTS           = 'messageBoard/posts/invalidate';


// Fetch more message board posts

export const FETCH_MORE_POSTS_REQUEST  = 'messageBoard/more-posts/fetch/request';
export const FETCH_MORE_POSTS_SUCCESS  = 'messageBoard/more-posts/fetch/success';
export const FETCH_MORE_POSTS_FAIL     = 'messageBoard/more-posts/fetch/fail';



// Fetch message board replies

export const FETCH_REPLIES_REQUEST       = 'messageBoard/replies/fetch/request';
export const FETCH_REPLIES_SUCCESS       = 'messageBoard/replies/fetch/success';
export const FETCH_REPLIES_FAIL          = 'messageBoard/replies/fetch/fail';

export const INVALIDATE_REPLIES           = 'messageBoard/replies/invalidate';


// Fetch more replies

export const FETCH_MORE_REPLIES_REQUEST  = 'messageBoard/more-replies/fetch/request';
export const FETCH_MORE_REPLIES_SUCCESS  = 'messageBoard/more-replies/fetch/success';
export const FETCH_MORE_REPLIES_FAIL     = 'messageBoard/more-replies/fetch/fail';




// Fetch message board posts

export const CREATE_POST_REQUEST       = 'messageBoard/post/create/request';
export const CREATE_POST_SUCCESS       = 'messageBoard/post/create/success';
export const CREATE_POST_FAIL          = 'messageBoard/post/create/fail';


// Fetch message board posts

export const CREATE_REPLY_REQUEST       = 'messageBoard/reply/create/request';
export const CREATE_REPLY_SUCCESS       = 'messageboard/reply/create/success';
export const CREATE_REPLY_FAIL          = 'messageBoard/reply/create/fail';


// Fetch message board

export const FETCH_MESSAGEBOARD_REQUEST       = 'messageBoard/fetch/request';
export const FETCH_MESSAGEBOARD_SUCCESS       = 'messageBoard/fetch/success';
export const FETCH_MESSAGEBOARD_FAIL          = 'messageBoard/fetch/fail';

export const INVALIDATE_MESSAGEBOARD           = 'messageBoard/invalidate';

/* */

export const MARK_MESSAGEBOARD_READ_REQUEST = 'messageBoard/mark-read/request'
export const MARK_MESSAGEBOARD_READ_SUCCESS = 'messageBoard/mark-read/success'
export const MARK_MESSAGEBOARD_READ_FAIL = 'messageBoard/mark-read/fail'

/* Delete Post */

export const DELETE_POST_REQUEST = 'messageBoard/post/delete/request'
export const DELETE_POST_SUCCESS = 'messageBoard/post/delete/success'
export const DELETE_POST_FAIL = 'messageBoard/post/delete/fail'


/* */


export const WS_NEW_POST       = 'messageBoard/ws/newPost';

export const SUBSCRIBE       = 'messageBoard/subscribe';
export const UNSUBSCRIBE       = 'messageBoard/unsubscribe';

export const APPLY_PENDING_POSTS = 'APPLY_PENDING_POSTS'
export const APPLY_PENDING_REPLIES = 'APPLY_PENDING_REPLIES'