import React, { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { fetchEnrollments, fetchMoreEnrollments } from '../redux/competition/actions'
import { makeGetEnrollments } from '../redux/competition/selectors'


const useEnrollments = (competitionId, query) => {
    const dispatch = useDispatch();
    const getEnrollments = useMemo(() => makeGetEnrollments(), []);
    
    useEffect(() => {
        if (competitionId) {
            dispatch(fetchEnrollments(competitionId, query));
        }
    }, [competitionId, query]);

    const fetchMore = () => {
        
        dispatch(fetchMoreEnrollments(competitionId));
    }

    const enrollments = useSelector(state => getEnrollments(state, competitionId));

    return [enrollments, fetchMore];
}
export default useEnrollments;