import React from 'react';
import PropTypes from 'prop-types'

import * as fromComponents from 'components'

import useFlyout from '../../../hooks/useFlyout'


export const FlyoutHeaderEditor = ({ children }) => {
    return children;
}

export const FlyoutContentEditor = ({ children }) => {
    return children;
}

export const FlyoutEditor = ({ children }) => {
    return  children;
}

export const FlyoutHeader = ({ children }) => {

    const [_, pop] = useFlyout();
    return <fromComponents.Flyout.Header px={ 0 } themeName="_current" display="flex">
                <fromComponents.Box flex="1">
                    { children }
                </fromComponents.Box>
                <fromComponents.ToolbarButton onClick={ pop }>
                    <fromComponents.Icon type="close" />
                </fromComponents.ToolbarButton>
           </fromComponents.Flyout.Header>
}

export const FlyoutContent = ({ children }) => {
    return <fromComponents.Flyout.Content>
                { children }
           </fromComponents.Flyout.Content>
}

export const Flyout = ({ children }) => {

    return <fromComponents.Flyout>
                { children }
           </fromComponents.Flyout>

}