import React from 'react';
import ReactDOM from 'react-dom';

import RoutedHeader from './routed/RoutedHeader';


const el = document.getElementById('sw-header');

const Header = ({ themeName, onShowMenu }) => 
{
    
    if (!el)
        return null;

    return ReactDOM.createPortal(<RoutedHeader themeName={ themeName } onShowMenu={ onShowMenu } />, el);
}


export default Header;