// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FtHHHpISDQV9lypqwIRg{display:grid;grid-template-columns:min-content auto;width:100%}.lrK_kWGL5AVr4eYl0Da2{position:relative;overflow:hidden}.J___QldWrWk1amfVs_bi{overflow:hidden}.mwfhP7nX_sJWNkv5EVTl{font-size:14px;height:24px;margin-top:12px;display:flex;flex-direction:row;align-items:center;padding-left:6px;display:none}`, "",{"version":3,"sources":["webpack://./src/components/ScrollTable.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sCAAA,CACA,UAAA,CAIJ,sBACI,iBAAA,CACA,eAAA,CAYJ,sBACI,eAAA,CAGJ,sBACI,cAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":[".scrollTable {\r\n    display: grid;\r\n    grid-template-columns: min-content auto;\r\n    width: 100%;\r\n}\r\n\r\n\r\n.left {\r\n    position: relative;\r\n    overflow: hidden;\r\n    /*\r\n    &:before {\r\n        content: \"\";\r\n        position: absolute;\r\n        right: 0;\r\n        top: 25px;\r\n        bottom: 0;\r\n        border-right: 1px solid rgba(#fff, 0.1);\r\n    }*/\r\n}\r\n\r\n.right {\r\n    overflow: hidden;\r\n}\r\n\r\n.label {\r\n    font-size: 14px;\r\n    height: 24px;\r\n    margin-top: 12px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    padding-left: 6px;\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
export var scrollTable = `FtHHHpISDQV9lypqwIRg`;
export var left = `lrK_kWGL5AVr4eYl0Da2`;
export var right = `J___QldWrWk1amfVs_bi`;
export var label = `mwfhP7nX_sJWNkv5EVTl`;
export default ___CSS_LOADER_EXPORT___;
