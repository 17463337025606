import React from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs'

import serverTime from '../common/serverTime';

import translatable from '../hocs/translatable'

const TIME_FORMAT = 'HH:mm';

const DATE_FORMAT = 'DD. MMM YYYY';
const DATETIME_FORMAT = 'DD. MMM YYYY, HH:mm';
const LONG_DATE_FORMAT = 'dddd DD. MMM YYYY';
const LONG_DATETIME_FORMAT = 'dddd DD. MMM YYYY, HH:mm';

const CURRENT_YEAR_DATE_FORMAT = 'DD. MMM';
const CURRENT_YEAR_DATETIME_FORMAT = 'DD. MMM, HH:mm';
const LONG_CURRENT_YEAR_DATE_FORMAT = 'dddd DD. MMM';
const LONG_CURRENT_YEAR_DATETIME_FORMAT = 'dddd DD. MMM, HH:mm';

const EventDate = ({ displayDate, displayTime, format, t, date }) => {

    const dt = dayjs(date);
    const now = dayjs(serverTime.now());
    const diff = now.diff(dt, 'days');

    const yesterday = now.subtract(1, 'day');
    const tomorrow = now.add(1, 'day');


    if (!displayDate) {
        return dt.format(TIME_FORMAT);
    }

    if (dt.year() === tomorrow.year() && dt.month() === tomorrow.month() && dt.date() === tomorrow.date()) {
        return displayTime ? `${ t('common.tomorrow') }, ${ dt.format(TIME_FORMAT) }` : t('common.tomorrow');
    }
    else if (dt.year() === yesterday.year() && dt.month() === yesterday.month() && dt.date() === yesterday.date()) {
        return displayTime ? `${ t('common.yesterday') }, ${ dt.format(TIME_FORMAT) }` : t('common.yesterday');
    }
    else if (dt.year() === now.year() && dt.month() === now.month() && dt.date() === now.date()) {
        return displayTime ? `${ t('common.today') }, ${ dt.format(TIME_FORMAT) }` : t('common.today');
    }
    else if (dt.year() == now.year()) {
        if (format === 'long')
            return displayTime ? dt.format(LONG_CURRENT_YEAR_DATETIME_FORMAT) : dt.format(LONG_CURRENT_YEAR_DATE_FORMAT);
        else
            return displayTime ? dt.format(CURRENT_YEAR_DATETIME_FORMAT) : dt.format(CURRENT_YEAR_DATE_FORMAT);
    }
    else {
        
        if (format === 'long')
            return displayTime ? dt.format(LONG_DATETIME_FORMAT) : dt.format(LONG_DATE_FORMAT);
        else
            return displayTime ? dt.format(DATETIME_FORMAT) : dt.format(DATE_FORMAT);
    }
}

EventDate.propTypes = {
    displayDate : PropTypes.bool.isRequired,
    displayTime : PropTypes.bool.isRequired,
    format : PropTypes.oneOf(['long', 'short'])
}

EventDate.defaultProps = {
    displayDate : true,
    displayTime : true,
    format : 'long'
}

export default translatable(EventDate);
