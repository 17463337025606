// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeKbF7iJj_czw97saQY7 {
    min-height: var(--vertical-rythm);
    background: black;

    background-color : var(--utility-black);
    
    color: var(--utility-white);
    
    display: flex;
    align-items: center;
    position: relative;
    
    padding: 0 12px;
}


.U3H6YSYMkFwkGO4QEPKN { background-color: var(--utility-yellow); }
.GAVyVrkfXXAATq3Xf0ay { background-color: var(--utility-red); }
.hLf4pNfgvck5r_35gaz7 { background-color: var(--utility-green); }
.yILFkuW6QVYirVqMt6sI { background-color: var(--utility-blue); }
`, "",{"version":3,"sources":["webpack://./src/components/AlertBox.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,iBAAiB;;IAEjB,uCAAuC;;IAEvC,2BAA2B;;IAE3B,aAAa;IACb,mBAAmB;IACnB,kBAAkB;;IAElB,eAAe;AACnB;;;AAGA,wBAAW,uCAAuC,EAAE;AACpD,wBAAS,oCAAoC,EAAE;AAC/C,wBAAW,sCAAsC,EAAE;AACnD,wBAAQ,qCAAqC,EAAE","sourcesContent":[".container {\r\n    min-height: var(--vertical-rythm);\r\n    background: black;\r\n\r\n    background-color : var(--utility-black);\r\n    \r\n    color: var(--utility-white);\r\n    \r\n    display: flex;\r\n    align-items: center;\r\n    position: relative;\r\n    \r\n    padding: 0 12px;\r\n}\r\n\r\n\r\n.warning { background-color: var(--utility-yellow); }\r\n.error { background-color: var(--utility-red); }\r\n.success { background-color: var(--utility-green); }\r\n.info { background-color: var(--utility-blue); }\r\n"],"sourceRoot":""}]);
// Exports
export var container = `TeKbF7iJj_czw97saQY7`;
export var warning = `U3H6YSYMkFwkGO4QEPKN`;
export var error = `GAVyVrkfXXAATq3Xf0ay`;
export var success = `hLf4pNfgvck5r_35gaz7`;
export var info = `yILFkuW6QVYirVqMt6sI`;
export default ___CSS_LOADER_EXPORT___;
