import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types';

import TranslationContext from '../components/TranslationContext';

const translatable = (ComponentToWrap) => {
    
    const TranslatableComponent = (props) => {
        const translationContext = useContext(TranslationContext);

        
        const translator = translationContext ? translationContext.translate : (key, params) => key;


        return (<ComponentToWrap {...props} t={ translator } />)
    }

    return TranslatableComponent;
}


export default translatable;

