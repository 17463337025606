import React from 'react'

import GameContext from './GameContext'
import ViewContainer from './ViewContainer'

import { getConfigurationSection } from 'utils'

const TournamentStandingsFlyout = ({ onClose }) => {
    return (
        <GameContext.Consumer>{ ({ gameConfiguration }) => {
            const flyouts = getConfigurationSection(gameConfiguration, 'flyouts', window.swush.profile) || {};
            return (
                <ViewContainer name={ flyouts && flyouts.standings && flyouts.standings.view } />
            )
     
        }}</GameContext.Consumer>
    )
}


export default TournamentStandingsFlyout;