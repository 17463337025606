export const FETCH_CATALOG_REQUEST = 'FETCH_CATALOG_REQUEST'; 
export const FETCH_CATALOG_SUCCESS = 'FETCH_CATALOG_SUCCESS'; 
export const FETCH_CATALOG_FAILURE = 'FETCH_CATALOG_FAILURE'; 

export const SELECT_GAME           = 'SELECT_GAME'; 
export const SELECT_ROUND          = 'SELECT_ROUND'; 
    
export const FETCH_GAME_REQUEST    = 'FETCH_GAME_REQUEST'; 
export const FETCH_GAME_SUCCESS    = 'FETCH_GAME_SUCCESS'; 
export const FETCH_GAME_FAILURE    = 'FETCH_GAME_FAILURE'; 

