// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kMVPWhd6XAMSBbRu1SUB{position:relative;top:0;right:0;bottom:0;left:0;background-size:cover;background-position:bottom right}.kMVPWhd6XAMSBbRu1SUB.c12lVzm1Y_kfCyl3w__N{filter:grayscale(1)}.tBjMoU88gHMBi3kOn5pG{position:absolute;top:0;right:0;bottom:0;left:0;z-index:100}.Yh9miWIaWJIU800SPiso{position:relative;z-index:200;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/CoverImage.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CAEA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CAEA,qBAAA,CACA,gCAAA,CAEA,2CACC,mBAAA,CAKF,sBACC,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CAEA,WAAA,CAGD,sBACC,iBAAA,CACA,WAAA,CAGA,UAAA","sourcesContent":[".container {\r\n\tposition: relative;\r\n\t\r\n\ttop: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\tleft: 0;\r\n\r\n\tbackground-size: cover;\r\n\tbackground-position: bottom right;\r\n\r\n\t&.disabled {\r\n\t\tfilter: grayscale(1);\r\n\t}\r\n}\r\n\r\n\r\n.tint {\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tright: 0;\r\n\tbottom: 0;\r\n\tleft: 0;\r\n\t\t\r\n\tz-index: 100;\r\n}\r\n\r\n.content {\r\n\tposition: relative;\r\n\tz-index: 200;\r\n\t\r\n\t// Should this live here?\r\n\tcolor: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `kMVPWhd6XAMSBbRu1SUB`;
export var disabled = `c12lVzm1Y_kfCyl3w__N`;
export var tint = `tBjMoU88gHMBi3kOn5pG`;
export var content = `Yh9miWIaWJIU800SPiso`;
export default ___CSS_LOADER_EXPORT___;
