import { schema } from 'normalizr';

const userSchema                = new schema.Entity('users');

const fantasyTeamValueSchema    = new schema.Entity('fantasyTeamValues');
const playerValueSchema         = new schema.Entity('playerValues');
const fantasyTeamSchema         = new schema.Entity('fantasyTeams');
const highscoreSchema           = new schema.Entity('highscores');
const gameSchema                = new schema.Entity('games');
const injurySchema              = new schema.Entity('injuries');
const injuryTypeSchema          = new schema.Entity('injuryTypes');
const suspensionSchema          = new schema.Entity('suspensions');
const suspensionTypeSchema      = new schema.Entity('suspensionTypes');


const notificationSchema        = new schema.Entity('notifications');
const personSchema              = new schema.Entity('persons');
const teamSchema                = new schema.Entity('teams');
const positionSchema            = new schema.Entity('positions');
const playerSchema              = new schema.Entity('players');
const rulesetSchema             = new schema.Entity('rulesets');
const tournamentSchema          = new schema.Entity('tournaments');
const formationSchema           = new schema.Entity('formations');
const eventTypeSchema           = new schema.Entity('eventTypes');
const fantasyEventSchema        = new schema.Entity('fantasyEvents');
const fantasyEventTypeSchema    = new schema.Entity('fantasyEventTypes');
const matchSchema               = new schema.Entity('matches');
const matchGroupSchema          = new schema.Entity('matchGroups');
const eventSchema               = new schema.Entity('events');
const groupSchema               = new schema.Entity('groups');
const groupMembershipSchema     = new schema.Entity('groupMemberships');
const competitionSchema         = new schema.Entity('competitions');
const leaderboardSchema         = new schema.Entity('leaderboards');
const cupQualificationSchema    = new schema.Entity('cupQualifications');
const enrollmentSchema          = new schema.Entity('enrollments');
const locationSchema            = new schema.Entity('locations');
const viewSchema                = new schema.Entity('views');
const jobSchema                 = new schema.Entity('jobs');
const jobHistorySchema          = new schema.Entity('jobHistories');
const jobScheduleSchema         = new schema.Entity('jobSchedules');
const jobTypeSchema             = new schema.Entity('jobTypes');
const invitationSchema          = new schema.Entity('invitations');
const leagueSchema              = new schema.Entity('leagues');
const themeSchema               = new schema.Entity('themes');
const matchCrawlerSchema        = new schema.Entity('matchCrawlers');
const messageBoardSchema        = new schema.Entity('messageBoards');
const messageBoardPostSchema    = new schema.Entity('messageBoardPosts');


invitationSchema.define({
    competition : competitionSchema,
    sender : userSchema
});

injurySchema.define({
    type : injuryTypeSchema
});

suspensionSchema.define({
    person : personSchema,
    type : suspensionTypeSchema,
    matches : [matchSchema]
});



fantasyTeamSchema.define({
    lineup: {
        formation : formationSchema,
        players : [playerSchema]
    },
    highscore : highscoreSchema,
    user : userSchema
});


enrollmentSchema.define({
    competition : competitionSchema,
    fantasyTeam: fantasyTeamSchema
});


leaderboardSchema.define({
    competition : competitionSchema,
    fantasyTeam : fantasyTeamSchema,
    user: userSchema
});


cupQualificationSchema.define({
    competition : competitionSchema,
    fantasyTeam : fantasyTeamSchema
});


groupSchema.define({
    
});


competitionSchema.define({
    game : gameSchema,
    owner : userSchema
});


groupMembershipSchema.define({
    user : userSchema,
    group : groupSchema
});


fantasyEventSchema.define({
    player : playerSchema,
    type : fantasyEventTypeSchema
});


eventSchema.define({
    match : matchSchema,
    player : playerSchema,
    type : eventTypeSchema
});

matchSchema.define({
    location : locationSchema,
    group : matchGroupSchema,
    participants : [{
        team : teamSchema
    }]
});

rulesetSchema.define({
    eventTypes : [eventTypeSchema],
    fantasyEventTypes : [fantasyEventTypeSchema],
    formations : [formationSchema],
    positions : [positionSchema],
});

playerValueSchema.define({
    game : gameSchema,
    player : playerSchema,
});

playerSchema.define({
    person : personSchema,
    team : teamSchema,
    position : positionSchema
});

fantasyTeamValueSchema.define({
    fantasyTeam : fantasyTeamSchema
});

fantasyTeamSchema.define({
    game : gameSchema,
    user : userSchema
});

highscoreSchema.define({
    fantasyTeam : fantasyTeamSchema
});

tournamentSchema.define({
    matchGroups : [matchGroupSchema],
    teams : [teamSchema],
    players : [playerSchema],
    persons : [personSchema],
    locations : [locationSchema],
    league : leagueSchema
});



messageBoardPostSchema.define({
    parent : messageBoardPostSchema,
    messageBoard : messageBoardSchema,
    user : userSchema
});

gameSchema.define({
    ruleset : rulesetSchema,
    tournament : tournamentSchema
});


jobSchema.define({
    type : jobTypeSchema
})

jobHistorySchema.define( {
 
})

jobScheduleSchema.define( {
 
})



jobTypeSchema.define( {
 
})

const teamStandingSchema = {
    team: teamSchema
};

const tournamentStandingSchema = {
    group: matchGroupSchema,
    standings : [teamStandingSchema]
};

export { 
    matchCrawlerSchema,
    themeSchema,
    injurySchema,
    suspensionSchema,
    notificationSchema,
    tournamentStandingSchema,
    gameSchema, 
    userSchema, 
    fantasyTeamSchema, 
    highscoreSchema, 
    playerSchema, 
    personSchema, 
    positionSchema, 
    teamSchema, 
    formationSchema, 
    eventTypeSchema,
    fantasyEventSchema, 
    fantasyEventTypeSchema, 
    rulesetSchema, 
    playerValueSchema, 
    matchSchema, 
    matchGroupSchema, 
    eventSchema, 
    tournamentSchema,
    groupSchema,
    groupMembershipSchema,
    competitionSchema,
    leaderboardSchema,
    cupQualificationSchema,
    enrollmentSchema,
    locationSchema,
    viewSchema,
    jobSchema,
    jobHistorySchema,
    jobScheduleSchema,
    jobTypeSchema,
    invitationSchema,
    messageBoardSchema,
    messageBoardPostSchema
}


export const competitionEnrollmentOptionsSchema = {
    competition : competitionSchema,
    options : [{
        fantasyTeam : fantasyTeamSchema
    }]
}


export const playerProfileSchema = {
    player : playerSchema,
    values : playerValueSchema,
    points : [{
        match : matchSchema,
        events : [
            { type : fantasyEventTypeSchema }
        ]
    }],
    statistics : [{
        match : matchSchema,
        events : [
            { type : eventTypeSchema }
        ]
    }],


}

export const playerStatisticsSchema = {
    match : matchSchema,
    events : [{
        type : eventTypeSchema
    }]
}

export const playerGameStatisticsSchema = {
    match : matchSchema,
    events : [{
        type : fantasyEventTypeSchema
    }]
}

export const playerRoundStatisticsSchema = {
    player : playerSchema,
    values : playerValueSchema,
    events : {
        round : [{
            type : eventTypeSchema
        }],
        total : [{
            type : eventTypeSchema
        }]
    }
}

export const watchlistSchema = {
    player : playerSchema
}

