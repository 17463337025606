//************************************************************
// Redefines CSS variables for a game header.
//************************************************************
import React from 'react'

import * as styles from './GameHeader.scss'
import CSSVariables from './CSSVariables';

import styled from 'styled-components'

const StyledHeader = styled.div`
    background-color: ${ props => props.themeName && props.theme.colors.themes[props.themeName].primary };
    --badge-border-color:  ${ props => props.themeName && props.theme.colors.themes[props.themeName].primary };

    --link-color: var(--game-header-link-color);
    --text-color: var(--game-header-text-color);

    background-image: url(${ props => props.backgroundImageUrl });
    background-repeat: no-repeat;
    background-position: center center;
    color: ${ props => props.themeName && props.theme.colors.themes[props.themeName].primaryForeground };
`

const GameHeader = ({ backgroundImageUrl, themeName, children }) =>{
    return (
        <StyledHeader themeName={ themeName } backgroundImageUrl={ backgroundImageUrl }>
            <div className={ styles.content }>
                { children }
            </div>
        </StyledHeader>
    )
}

GameHeader.defaultProps = {
    
}

export default GameHeader;