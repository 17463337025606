import React from 'react';

import PlayerContext from '../../PlayerContext'
import { number } from 'utils'


const Trend = ({ }) => (
    <PlayerContext.Consumer>{ ({ values }) => {
        if (!values || values.length == 0)
            return "-"
    
        return number(values[values.length - 1].trend);
    }}</PlayerContext.Consumer>
)

Trend.defaultProps = {
}

export default Trend;