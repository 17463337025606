import React, { useState } from 'react'

import Modal from './Modal'
import { Text } from './Typography'

import styled from 'styled-components'

import { space, display, flexbox } from 'styled-system'

import * as styles from './Dialog.module.css'

const StyledDialogHeader = styled.div`
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0 48px 0 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 72px;    

    ${ space }
    ${ display }
    ${ flexbox }
`

export const DialogHeader = ({ children }) => {
    
    return (
        <div className={ styles.header }>
            <Text size="l">{ children }</Text>
        </div>
    )
}


export const DialogContent = ({ children }) => {
    return (
        <div className={ styles.content }>
            { children }
        </div>
    )
}




export const DialogFooter = ({ children }) => {
    return (
        <div className={ styles.footer }>
            { children }
        </div>
    )
}

export const Dialog = ({ children, ...otherProps }) => {
    return (
        <Modal { ...otherProps }>
            { children }
        </Modal>
    )
}



export const useDialog = () => {
    const [state, setState] = useState({ isOpen : false, props : {}, onClose : null });
    
    const open = (props) => new Promise((resolve, reject) => {

        const handleClose = (value) => {

            setState({ isOpen : false, props : {}, onClose : null });
            resolve(value);
        }

        setState({ isOpen: true, props, onClose : handleClose });

    })

    const close = () => {
        setState({ isOpen: false, props : {}, onClose : null })
    }

    return [{ open : state.isOpen, onClose : state.onClose, ...state.props }, open, close];

}
