import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components';

const StyledSnackbar = styled.div`
    width: 300px;
    
    margin-left: -150px;

    background: ${ props => props.theme.colors.snackbarBackground };
    
    color : ${ props => (
        {
            information : props.theme.colors.snackbarForeground,
            success : props.theme.colors.utilityGreenContrast,
            warning : props.theme.colors.utilityOrangeContrast,
            error : props.theme.colors.utilityRedContrast
        }[props.type]
    )};

    text-align: center;

    line-height: 24px;
    border-radius: 3px;
    font-size: 14px;

    padding: 12px;

    box-shadow: ${ props => props.theme.shadows.snackbar };

    z-index: 3000;
`

export const Snackbar = ({ children, ...otherProps }) => {
   
    return (
        <StyledSnackbar { ...otherProps }>
            { children }
        </StyledSnackbar>
    )
}

Snackbar.propTypes = {
    type : PropTypes.oneOf(['information', 'success', 'warning', 'error'])
}

Snackbar.defaultProps = {
    type : 'information'
}