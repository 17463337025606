import React, { useMemo} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from './Icon';
import {Dropdown } from './atoms/Dropdown'

import * as styles from './Dropdown.scss'


const DropDownList = ({ value, items, onChange }) => {
    const selectedItem = useMemo(() => items.find(v => v.value === value), [value, items]);

    return (
        <Dropdown items={ items } onSelect={ value => onChange(value) }>{ ({ open }) => (
            <div onClick={ () => open() }>
                <span>{ !!selectedItem ? selectedItem.label : this.props.placeholder }</span>
                <span>
                    <Icon type="triangleDown" />
                </span>
            </div>
        )}</Dropdown>
    )
}

DropDownList.propTypes = {
    placeholder : PropTypes.string,
    value : PropTypes.any,
    items : PropTypes.array,
    inline : PropTypes.bool.isRequired,
    onChange : PropTypes.func
}

DropDownList.defaultProps = {
    alignment: 'left',
    appearance: 'default',
    inline : false
}


export default DropDownList;



