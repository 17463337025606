import React from 'react'

import { ErrorBoundary } from '../components';

import GameContext from './GameContext';
import ViewContainer from './ViewContainer';
import { DefineAdSlot } from './google-publisher-tag';
import { RequestAds } from '../components/ads';
import TopBanner from './TopBanner';

const Schedule = () => {
    

    return (
        <>
            <TopBanner />
        
            <ErrorBoundary>
                <GameContext.Consumer>{ ({ gameConfiguration }) => (
                
                    <>
                        
                        <ViewContainer name={ gameConfiguration.views && gameConfiguration.views.schedule }/>
                    </>
                )}</GameContext.Consumer>
            </ErrorBoundary>

            <RequestAds />
        </>
            )
}

export default Schedule;