import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LinkButton, Button, Label, Textbox, AuthenticationHeader, Form, FormField, Fieldset, Checkbox, LoginButton } from 'components'
import { BusyButton } from '../../molecules'

import { facebookSignup } from '../../redux/auth/actions'

import { useTranslator } from '../../hooks/useTranslator'
import { useAppConfig } from '../AppConfigProvider';

import styled from 'styled-components'

const Optin = styled.div`
    display: flex;
    line-height: 24px;
    margin-bottom: 12px;
    font-size: 14px;

    > *:first-child {
        margin-right: 12px;
    }

    > *:nth-child(2) {
        margin-top: 6px;
    }
`

export const SignupWithFacebook = ({ }) => {
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [_optins, _setOptins] = useState([]);
    
    const appConfig = useAppConfig();
    const t = useTranslator();

    const { problems, isBusy } = useSelector(state => state.auth.facebook);

    const ref = useRef()

    useEffect(() => {
        ref.current.focus();
    }, [ref.current])


    const onSubmitInternal = () => {
        dispatch(facebookSignup(username, _optins));
    }

    const toggleOptin = useCallback((key) => {
        let index = _optins.indexOf(key);
        if (index >= 0) {
            _setOptins([
                ..._optins.slice(0, index), 
                ..._optins.slice(index + 1)
            ]);
        }
        else {
            _setOptins([..._optins, key]);
        }
    })

    const optins = appConfig && appConfig.auth && appConfig.auth.optins || {};
    const enabled = Object.values(optins).length == _optins.length && username.length > 0;

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.createAccountWithFacebookHeader') }</AuthenticationHeader>

            <Form problems={ problems } onSubmit={ onSubmitInternal }>
                <Fieldset>
                <FormField name="username">
                    <Label>{ t('authentication.usernameLabel') }</Label>
                    <Textbox ref={ ref } name="username" value={ username } tabindex={ 0 } autocomplete="off" onChange={ (e) => setUsername(e.target.value)} />
                </FormField>

            
                {
                    Object.keys(optins).map((key, idx) => {
                        const optin = optins[key];
                        const checked = _optins.indexOf(key) >= 0

                        return (
                            <Optin key={ key }>
                                <div><Checkbox checked={ checked } tabindex={ 2 + idx } onChange={ () => toggleOptin(key) }/></div>
                                <div dangerouslySetInnerHTML={{ __html: optin.text }}></div>
                            </Optin>
                        )
            
                    })
                }
                </Fieldset>
        
                <BusyButton type="submit" busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary disabled={ !enabled } onClick={ onSubmitInternal }>
                    { t('authentication.createAccountButtonCaption') }
                </BusyButton>
            </Form>
        </React.Fragment>
    )
}


