import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


const debounce = (fn, delay) => {
    var timer = null;

    return function (e) {
        e.persist();
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

class SearchBox extends React.PureComponent{
    constructor(props) {
        super(props);
        this.onChange = debounce(this.onChange.bind(this), props.debounce);
    }

    onChange(e){ 
        this.props.onQueryChange(e.target.value)
    }

    render() {
      
        const className = classNames(
            'input',
            {
                'input--busy' : this.props.busy
            }
        );

        return (
           <input className={ className } onChange={ this.onChange } />
        )
    }  
}

SearchBox.propTypes = {
    query : PropTypes.string,
    debounce : PropTypes.number.isRequired,
    onQueryChange : PropTypes.func,
    busy : PropTypes.bool.isRequired
}

SearchBox.defaultProps = {
    debounce : 300,
    busy : false
}

export default SearchBox;
