import * as types from './types'

import { safeget } from 'utils'

const defaultState = {
    byMessageBoard : {

    },
    byId: {},
    unreadByMessageBoard : {},
    subscriptions : []
};

const defaultMessageBoardState = {
    posts : {
        isFetching : false,
        didInvalidate : false,
        items: null
    },

    repliesByPost : {},
    replyCountByPost : {},
    pendingPosts : [],
    pendingRepliesByPost : {}
}





const byMessageBoardReducer = (state = defaultMessageBoardState, action) =>
{
    switch(action.type) {
        /* Create Reply */            

        case types.CREATE_REPLY_SUCCESS:
            return  { 
                ...state,
                replyCountByPost : {
                    ...state.replyCountByPost,
                    [action.postId] : (state.replyCountByPost[action.postId] || 0) + 1
                },
                repliesByPost : {
                    ...state.repliesByPost,
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        items : [action.payload.result, ...(safeget(state.repliesByPost, [action.postId, 'items']) || [])]
                    }
                    
                }
            };
        case types.DELETE_POST_SUCCESS:
            if (action.parentPostId) {
                return  { 
                    ...state,
                    repliesByPost : {
                        ...state.repliesByPost,
                        [action.parentPostId] : {
                            ...state.repliesByPost[action.parentPostId],
                            items : (state.repliesByPost[action.parentPostId] || { items : [] }).items.filter(i => i !== action.postId)
                        }
                    },
                    replyCountByPost : {
                        ...state.replyCountByPost,
                        [action.parentPostId] : (state.replyCountByPost[action.parentPostId] || 1) - 1
                    }
                }
            }
            else {
                return  { 
                    ...state,
                    posts : {
                        ...state.posts,
                        items : state.posts.items.filter(i => i !== action.postId)
                    }
                }
            }
    
        case types.FETCH_REPLIES_REQUEST:

            return  { 
                ...state, 
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        isFetching : true 
                    }
                } 
            };
        case types.FETCH_REPLIES_SUCCESS:
            return  { 
                ...state,
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        items : action.payload.result.items,
                        links : action.payload.result.links,
                        count : action.payload.result.count,
                        isFetching : false,
                        didFetch : true,
                        didInvalidate : false
                    }
                }
            };
        case types.FETCH_REPLIES_FAIL:
            return  { 
                ...state, 
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        isFetching : false
                    }
                } 
            };

        /* Fetch More Replies */

        case types.FETCH_MORE_REPLIES_REQUEST:
            return  { 
                ...state, 
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        isFetching : true 
                    }
                } 
            };
        case types.FETCH_MORE_REPLIES_SUCCESS:
            return  { 
                ...state,
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        isFetching : false,
                        items : [...(state.repliesByPost[action.postId] || { items : [] }).items, ...action.payload.result.items],
                        links : action.payload.result.links,
                        count : action.payload.result.count
                    }
                }
            };
        case types.FETCH_MORE_REPLIES_FAIL:
            return  { 
                ...state, 
                repliesByPost : { 
                    ...state.repliesByPost, 
                    [action.postId] : {
                        ...state.repliesByPost[action.postId],
                        isFetching : false
                    }
                } 
            };


        case types.DELETE_POST_SUCCESS:
            return { 
                ...state, 
                posts : {
                    ...state.posts,
                    items : state.posts.items.filter(i => i !== action.postId)
                }               
            }

            /* Fetch Posts */
        case types.FETCH_POSTS_REQUEST:
            return  { 
                ...state, 
                posts : { 
                    ...state.posts, 
                    isFetching : true
                } 
            };
        case types.FETCH_POSTS_SUCCESS:
            return  { 
                ...state,
                replyCountByPost : {
                    ...state.replyCountByPost,

                    ...Object.keys(action.payload.entities.messageBoardPosts || {}).reduce((acc, key) => {
                        acc[key] = action.payload.entities.messageBoardPosts[key].replies;
                        return acc;
                    }, {})
                },
                posts : {
                    ...state.posts,
                    isFetching : false,
                    didFetch : true,
                    didInvalidate : false,
                    items : action.payload.result.items,
                    links : action.payload.result.links,
                    count : action.payload.result.count
                }
            };
        case types.FETCH_POSTS_FAIL:
            return { 
                ...state,
                posts : {
                    ...state.posts,
                    isFetching : false,
                    didInvalidate : false
                }
            };


        /* Fetch More Posts */

        case types.FETCH_MORE_POSTS_REQUEST:
            return  { ...state, posts : { ...state.posts, isFetching : true } };
        case types.FETCH_MORE_POSTS_SUCCESS:
            return  { 
                ...state,
                posts : {
                    ...state.posts,
                    isFetching : false,
                    items : [...state.posts.items, ...action.payload.result.items],
                    links : action.payload.result.links,
                    count : action.payload.result.count
                }
            };
        case types.FETCH_MORE_POSTS_FAIL:
            return { ...state, posts : { ...state.posts, isFetching : false } };

            /* Mark Messageboard Read */




        /* Fetch Replies */

      


        /* Invalidate Posts */

        case types.INVALIDATE_POSTS:
            return  { ...state, posts : { ...state.posts, didInvalidate : true } };

        


        /* Create Post*/
        
        case types.CREATE_POST_SUCCESS:
            return  { 
                ...state,
                posts : {
                    ...state.posts,
                    items : [action.payload.result, ...state.posts.items]
                }
            };


        /* Websocket */

        case types.WS_NEW_POST:
            return  { 
                ...state,
                pendingPosts : action.postId ? state.pendingPosts : [action.payload.result, ...(state.pendingPosts || [])],
                pendingRepliesByPost : action.postId ? 
                {
                    ...state.pendingRepliesByPost,
                    [action.postId] : [action.payload.result, ...(state.pendingRepliesByPost[action.postId] || [])]
                } : state.pendingRepliesByPost
            };

         /* New Post */

            case types.APPLY_PENDING_POSTS:
                return  { 
                    ...state,
                    posts : { 
                        ...state.posts, 
                        items : [
                            ...(state.pendingPosts || []), 
                            ...(state.posts.items || [])
                        ] 
                    },
                    pendingPosts : []
                };

            case types.APPLY_PENDING_REPLIES:
                return  { 
                    ...state,
                    repliesByPost : {
                        ...state.repliesByPost,
                        [action.postId] : { 
                            ...state.repliesByPost[action.postId],
                            items : [
                                ...(state.pendingRepliesByPost[action.postId] || []), 
                                ...(safeget(state.repliesByPost, [action.postId, 'items']) || [])
                            ]
                        }
                    },
                    pendingRepliesByPost : {
                        ...state.pendingRepliesByPost,
                        [action.postId] : []
                    }
                };            

        default:
            return state;

    }
}





const messageBoardReducer = (state = defaultState, action) =>
{
    switch (action.type) {

        case types.INVALIDATE_MESSAGEBOARD:
            return  { 
                ...state, 
                byMessageBoard : {
                    ...state.byMessageBoard,
                    [action.messageBoardId] : defaultMessageBoardState
                }
            };

        /* Delete Post */

        case types.DELETE_POST_SUCCESS:
        case types.FETCH_POSTS_REQUEST:
        case types.FETCH_POSTS_SUCCESS:
        case types.FETCH_POSTS_FAIL:
        case types.CREATE_POST_SUCCESS:
        case types.CREATE_REPLY_SUCCESS:
        case types.FETCH_REPLIES_REQUEST:
        case types.FETCH_REPLIES_SUCCESS:
        case types.FETCH_REPLIES_FAIL:
        case types.FETCH_MORE_REPLIES_REQUEST:
        case types.FETCH_MORE_REPLIES_SUCCESS:
        case types.FETCH_MORE_REPLIES_FAIL:            
        case types.FETCH_MORE_POSTS_REQUEST:
        case types.FETCH_MORE_POSTS_SUCCESS:
        case types.FETCH_MORE_POSTS_FAIL:            
        case types.INVALIDATE_POSTS:            
        case types.APPLY_PENDING_POSTS:        
        case types.APPLY_PENDING_REPLIES:        
            return { 
                ...state, 
                byMessageBoard : {
                    ...state.byMessageBoard,
                    [action.messageBoardId] : byMessageBoardReducer(state.byMessageBoard[action.messageBoardId], action)
                }
            }

        case types.WS_NEW_POST:
                return { 
                    ...state, 
                    byMessageBoard : {
                        ...state.byMessageBoard,
                        [action.messageBoardId] : byMessageBoardReducer(state.byMessageBoard[action.messageBoardId], action)
                    },
                    unreadByMessageBoard : {
                        ...state.unreadByMessageBoard,
                        [action.messageBoardId] : (state.unreadByMessageBoard[action.messageBoardId] || 0) + 1
                    }
                }
          
            case types.MARK_MESSAGEBOARD_READ_SUCCESS:
                    return  { 
                        ...state,
                        unreadByMessageBoard : {
                            ...state.unreadByMessageBoard,
                            [action.messageBoardId] : 0
                        }
                    };

        /* Fetch Messageboard */
               
        case types.FETCH_MESSAGEBOARD_REQUEST:
                return  { 
                    ...state,
                    byId : {
                        ...state.byId,
                        [action.messageBoardId] : {
                            ...state.byId[action.messageBoardId],
                            isFetching : true,
                            didInvalidate : false,
                        }
                    }
                };
            case types.FETCH_MESSAGEBOARD_SUCCESS:
                    return  { 
                        ...state,
                        byId : {
                            ...state.byId,
                            [action.messageBoardId] : {
                                ...state.byId[action.messageBoardId],
                                isFetching : false,
                                item : action.payload.result
                            }
                        },
                        unreadByMessageBoard : {
                            ...state.unreadByMessageBoard,
                            [action.messageBoardId] : action.payload.entities.messageBoards[action.messageBoardId].unreadCount
                        }
                    };
            case types.FETCH_MESSAGEBOARD_FAIL:
                    return  { 
                        ...state,
                        byId : {
                            ...state.byId,
                            [action.messageBoardId] : {
                                ...state.byId[action.messageBoardId],
                                isFetching : false
                            }
                        }
                    };

        /* Subscriptions */

        case types.SUBSCRIBE:
            return { ...state, subscriptions: [...state.subscriptions, action.messageBoardId] }
        case types.UNSUBSCRIBE:
            return { ...state, subscriptions: [...state.subscriptions.filter(s => s != action.messageBoardId)] }
        default:
            return state
    }
}

export default messageBoardReducer;
