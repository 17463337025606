import { useEffect } from 'react'

const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
const GPT_LIMITED_ADS_URL = 'https://pagead2.googlesyndication.com/tag/js/gpt.js';


export default function InitializeGpt({ limitedAds }) {

	return null;
}
