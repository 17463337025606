import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import Icon from './Icon'

import * as styles from './MatchCard.scss'
import styled from 'styled-components'
import Scorebox from './Scorebox'



const MatchCardFooter = ({ children }) => {

    const className = classNames(
        styles.footer
    )

    return <div className={ className }>{ children }</div>
}

const Content = ({ children }) => {
    return <div className={ styles.content }>{ children }</div>

}

const MatchCardIcon = ({ children }) => {
    return <div className={ styles.icon }>{ children }</div>

}

const SimpleEventContent = ({ round, title }) => {

    const className = classNames(
        styles.content,
        styles.simpleEvent
    )

    return  <div className={ className }>
                <div className={ styles.round }>{ round }</div>
                <div className={ styles.title }>{ title }</div>
            </div>
}

const StyledWrapper = styled.div`
	--matchcard-background-color: ${ props => props.theme.colors.cardBackground };	
	--matchcard-border-radius:  ${ props => props.theme.radii.r400 };
	--matchcard-shadow: ${ props => props.theme.shadows.card };
	--matchcard-separator-color: ${ props => props.theme.colors.borderUp };
`

const VersusEventContent = React.memo(({ group, home, away, homeIcon, awayIcon, homeScore, awayScore, status }) => {

    const className = classNames(
        styles.content,
        styles.versus
    )

    return  (
        <div className={ className }>
                <div className={ styles.participants }>
                    { group && <div className={ styles.group }>{ group }</div> }
                    <div className={ styles.team }>
                        <span>{ home ? home : '-' }</span>
                        { homeIcon && <span className={ styles.teamIcon }><Icon type={ homeIcon } size="1-2x" /></span> }
                    </div>
                    <div className={ styles.team }>
                        { away ? away : '-' }
                        { awayIcon && <span className={ styles.teamIcon }><Icon type={ awayIcon } size="1-2x" /></span> }
                    </div>
                </div>

                {
                    status !== 'pending' && 
                
                    <div className={ styles.scoreContainer }>
                        <Scorebox value={ homeScore } />
                        <Scorebox value={ awayScore } />
                    </div>
                }
            </div>
            )
})



const Progress = React.memo(({ children }) => {
    return  <div className={ styles.progress }>
                { children }
            </div>
})




class MatchCard extends React.PureComponent{
    
    static Footer = MatchCardFooter;

    static Content = Content;
    static SimpleEventContent = SimpleEventContent;
    static VersusEventContent = VersusEventContent;
    static Progress = Progress;
    static Icon = MatchCardIcon;

    render () {
        const { children, pre, post, live, unread, current, onClick } = this.props;

        const className = classNames(
            styles.matchCard,
            {
                
                [styles.current] : current,
                //[styles.live] : live,
                [styles.post] : post,
                [styles.unread] : unread
            }
        )

        return (
            <StyledWrapper>
                <div className={ className } onClick={ onClick }>{ children }</div>
            </StyledWrapper>
        )
    }
}

MatchCard.propTypes = {
    onClick : PropTypes.func.isRequired,
    active : PropTypes.bool,
    highlight : PropTypes.bool
}

export default MatchCard;