import React from 'react'
import PropTypes from 'prop-types'

import serverTime from '../common/serverTime';

class DeltaClock extends React.PureComponent{
    constructor(props) {
        super(props);

        this.state = {
            minutes : 0,
            seconds : 0
        };

        this.timer = null;
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(() => { this.tick(this.props) }, 1000);
        this.tick(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.tick(nextProps);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick(props) {
        const { from, to } = this.props;
    
        let delta = (to || serverTime.now()) - from;

        if (delta < 0)
            delta = 0;

        let seconds = Math.floor(delta / 1000);
    
        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        this.setState({ minutes, seconds });
    }

    render() {
        const m = (this.state.minutes + '').padStart(2, '0');
        const s = (this.state.seconds + '').padStart(2, '0');
                
        return (
            <span>{ `${ m }:${ s }` }</span>
        )
    }  
}

DeltaClock.propTypes = {
  from : PropTypes.instanceOf(Date).isRequired,
  to : PropTypes.instanceOf(Date),
}

export default DeltaClock;
