import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Countdown from './Countdown'

import * as styles from './Gamebox.scss'

const GameboxHeader = ({ title, subtitle, status, sponsorImageUrl }) => {
    return (
        <div className={ styles.header}>
            { sponsorImageUrl && <div><img className={ styles.sponsor } src={ sponsorImageUrl } /></div> }
            <div className={ styles.title }>{ title }</div>
            { subtitle && <div className={ styles.subtitle }>{ subtitle }</div> }
            { status && <div className={ styles.status }>{ status }</div> }
        </div>
    )
}

GameboxHeader.propTypes = {
    title : PropTypes.node.isRequired,
    subtitle : PropTypes.node,
    status : PropTypes.node,
    sponsorImageUrl : PropTypes.string,
}



const GameboxCallToAction = ({ title, countdownTo, action }) => {

    const className = classNames(
        styles.cta
    );

    return (
        <div className={ className }>
            <div className={ styles.ctaDescription}>
                <div className={ styles.ctaTitle}>{ title }</div>
                <div className={ styles.ctaCountdown}>
                    <Countdown to={ countdownTo}></Countdown>
                </div>
            </div>
            <div className={ styles.ctaAction}>
                { action }
                
            </div>
        </div>
    )
}


GameboxCallToAction.propTypes = {
    title : PropTypes.string.isRequired,
    large : PropTypes.bool
}


const GameboxContent = ({ children }) => {
    return (
        <div>
            { children }
        </div>
    )
}

class Gamebox extends React.PureComponent { 
    static Header = GameboxHeader;
    static Content = GameboxContent;
    static CallToAction = GameboxCallToAction;

    render() {
        const { large, disabled, children } = this.props;

        const className = classNames(
            styles.gamebox,
            {
                [styles.disabled] : disabled,
                [styles.large] : large
            }

        );

        return (
            <div className={ className }>
                { children }
            </div>
        )
    }
}


Gamebox.propTypes = {
    large : PropTypes.bool,
    disabled : PropTypes.bool
}

Gamebox.defaultProps = {
}

export default Gamebox;