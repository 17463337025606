import React, { useEffect, useRef } from 'react'


var remountBin = document.createElement('div');
remountBin.style.display = 'none';
document.body.appendChild(remountBin);



const RemountElement = ({ editMode, targetId }) => {
    const ref = useRef();

    if (editMode)
        return null;



    useEffect(() => {
        if (ref.current) {
            const el = document.getElementById(targetId);
            if (el) {
                el.style.display = 'block';
                ref.current.appendChild(el);
            }
        }

        return () => {
            const el = document.getElementById(targetId);
            if (el) {
                remountBin.appendChild(el);
            }
        }

    }, [ref.current]);

 
    return (
        <div ref={ ref }></div>
    )
}





export default RemountElement;