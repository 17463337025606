import React from 'react';
import PropTypes from 'prop-types'

import MatchContext from '../../MatchContext'

import { lo } from 'utils'
import { Box } from '../../../components';

const VersusTitle = ({ editMode, separator, flip }) => {

    if (editMode) {
        return 'Home' + separator + 'Away'
    }

    return (
        <Box px="m">
                
                
            <MatchContext.Consumer>{ (match) => {
                

                const home = match.participants.find(p => p.type == 'home');
                const away = match.participants.find(p => p.type == 'away');

                if (!home || !away)
                    return lo(match.matchGroup, 'name');
            
                const homeName = home ? lo(home.team, 'name') : '-'
                const awayName = away ? lo(away.team, 'name') : '-'

                if (flip) {
                    return awayName + separator + homeName;
                }
                else {
                    return homeName + separator + awayName
                }
            }}</MatchContext.Consumer>
        </Box>
    )

}

VersusTitle.propTypes = {
    
    separator : PropTypes.string.isRequired,
    flip : PropTypes.bool
}

VersusTitle.defaultProps = {
    separator : ' - '
}

export default VersusTitle;