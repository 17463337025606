// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gJfZEV8jvtEoHGwzrGsh{margin:0;font-size:14px}.DOQJe4XYsTcNZIVXMShj{background-color:var(--card);border-radius:var(--border-radius);margin-bottom:1px}.DOQJe4XYsTcNZIVXMShj:hover{background-color:var(--card-light)}.arpmuzCgVYMsIWX96yIJ{cursor:pointer;min-height:calc(var(--vertical-rythm) - 1px);display:flex;flex-direction:row;align-items:center;padding:0 12px}.RMIf_ns6XTsH4jyY3nJ2{display:none;padding:0 12px}.CHRN0emifZUVRvr3rZpU .RMIf_ns6XTsH4jyY3nJ2{display:block}`, "",{"version":3,"sources":["webpack://./src/components/Accordion.scss"],"names":[],"mappings":"AAMA,sBACC,QAAA,CACA,cAAA,CAID,sBACC,4BAAA,CAEA,kCAAA,CACA,iBAAA,CAGA,4BACC,kCAAA,CAIF,sBACC,cAAA,CAEA,4CAAA,CAEA,YAAA,CACA,kBAAA,CACA,kBAAA,CAEA,cAAA,CAGD,sBACC,YAAA,CACA,cAAA,CAIA,4CACC,aAAA","sourcesContent":["/*\r\n--accordion-item-background-color\r\n--accordion-item-active-background-color\r\n--accordion-item-hover-background-color\r\n*/\r\n\r\n.container {\r\n\tmargin: 0;\r\n\tfont-size: 14px;\r\n}\r\n\r\n\r\n.item {\r\n\tbackground-color: var(--card);\r\n\t\r\n\tborder-radius: var(--border-radius);\r\n\tmargin-bottom: 1px;\r\n\t\r\n\r\n\t&:hover {\r\n\t\tbackground-color: var(--card-light);\r\n\t}\r\n}\r\n\r\n.header {\r\n\tcursor: pointer;\r\n\r\n\tmin-height: calc(var(--vertical-rythm) - 1px);\r\n\r\n\tdisplay: flex;\r\n\tflex-direction: row;\r\n\talign-items: center;\r\n\t\r\n\tpadding: 0 12px;\r\n}\r\n\r\n.content {\r\n\tdisplay: none;\r\n\tpadding: 0 12px;\r\n}\r\n\r\n.active {\r\n\t.content {\r\n\t\tdisplay: block;\r\n\t}\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `gJfZEV8jvtEoHGwzrGsh`;
export var item = `DOQJe4XYsTcNZIVXMShj`;
export var header = `arpmuzCgVYMsIWX96yIJ`;
export var content = `RMIf_ns6XTsH4jyY3nJ2`;
export var active = `CHRN0emifZUVRvr3rZpU`;
export default ___CSS_LOADER_EXPORT___;
