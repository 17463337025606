import React from 'react'

import HandballLineupWidget from './containers/handball/HandballLineupWidget'
import FootballLineupWidget from './containers/football/FootballLineupWidget'
import HockeyLineupWidget from './containers/hockey/HockeyLineupWidget'
import GenericVersusMatchHead from './containers/generic/GenericVersusMatchHead'
import GolfMatchHead from './containers/golf/GolfMatchHead'
import MotorsportMatchHead from './containers/motorsport/MotorsportMatchHead'
import MotorsportDriverListWidget from './containers/motorsport/MotorsportDriverListWidget'
import MotorsportPitcrewListWidget from './containers/motorsport/MotorsportPitcrewListWidget'
import MotorsportConstructorListWidget from './containers/motorsport/MotorsportConstructorListWidget'
import GolfTournamentReport from './containers/golf/GolfTournamentReport'
import TournamentStandings from './containers/TournamentStandings'
import Competitions from './competitions/Competitions'

import SoccerSchedule from './containers/soccer/SoccerSchedule'
import GolfSchedule from './containers/golf/GolfSchedule'
import CyclingSchedule from './containers/cycling/CyclingSchedule'
import MotorsportSchedule from './containers/motorsport/MotorsportSchedule'
import GenericVersusSchedule from './containers/genericversus/GenericVersusSchedule'
import GenericSchedule from './containers/generic/GenericSchedule'
import WhenMatch from './containers/widgets/WhenMatch'


import StockSchedule from './containers/stock/StockSchedule'
import SkiSchedule from './containers/ski/SkiSchedule'

import SoccerLineupWidget from './containers/soccer/SoccerLineupWidget'
import SoccerMatchHead from './containers/soccer/SoccerMatchHead'
import PlayerStatisticsDataGrid from './containers/PlayerStatisticsDataGrid'

import Game from './containers/Game'
import RemountElement from './containers/RemountElement'

import LineupBuilder from './containers/press/LineupBuilder'

import * as fromData from './containers/widgets/data' 
import * as fromDatasource from './containers/widgets/datasource' 
import * as fromMatch from './containers/widgets/match'
import * as fromPlayer from './containers/widgets/player'
import * as fromForms from './containers/widgets/forms'
import * as fromWeb from './containers/widgets/web'
import * as fromUI from './containers/widgets/ui'
import * as fromLayout from './containers/widgets/layout'

import * as fromSecurity from './containers/widgets/security'


import PluginScript from './containers/widgets/plugins/Script';




const componentMap = {

    //'Data.DataObject' : fromData.DataObject,
    //'Data.Apply' : fromData.Apply,

    'Plugin.Script' : PluginScript,

    'Forms.Form' : fromForms.Form,
    'Forms.Apply' : fromForms.Apply,
    'Forms.Group' : fromForms.Group,
    'Forms.Label' : fromForms.Label,
    'Forms.Changes' : fromForms.Changes,
    'Forms.Textbox' : fromForms.Textbox,
    'Forms.Checkbox': fromForms.Checkbox,
    'Forms.ResetButton' : fromForms.ResetButton,
    'Forms.TeamDropdownList' : fromForms.TeamDropdownList,
    'Forms.RoundDropDownList' : fromForms.RoundDropDownList,
    'Forms.PositionCheckboxList' : fromForms.PositionCheckboxList,
    /*
    'Filtering.AppliedFilterList' : fromFiltering.AppliedFilterList,*/


    

    'Web.Iframe' : fromWeb.IFrame,
    'Web.Image' : fromWeb.Image,
    'Web.Link' : fromWeb.Link,
    'Web.Banner' : fromWeb.Banner,
    

    'UI.Faq' : fromUI.Faq,
    'UI.Faq.Item' : fromUI.FaqItem,
    'UI.Pointsystem' : fromUI.Pointsystem,
    'UI.Pointsystem.Item' : fromUI.PointsystemItem,
    'UI.Block' : fromUI.Block,
    'UI.FlyoutButton' : fromUI.FlyoutButton,
    'UI.TabBar' : fromUI.TabBar,
    'UI.TabBar.Item' : fromUI.TabBarItem,
    'UI.TopBar' : fromUI.TopBar,
    'UI.TopBar.Left' : fromUI.TopBarLeft,
    'UI.TopBar.Right' : fromUI.TopBarRight,
    'UI.Toolbar' : fromUI.Toolbar,
    'UI.Toolbar.Item' : fromUI.ToolbarItem,
    'UI.View' : fromUI.ViewContainer,
    'UI.FactList' : fromUI.FactList,
    'UI.FactList.Item' : fromUI.FactListItem,
    'UI.Overlay' : fromUI.Overlay,
    'UI.Underlay' : fromUI.Underlay,
    'UI.Underlay.Underlay' : fromUI.UnderlayUnderlay,
    'UI.Underlay.Content' : fromUI.UnderlayContent,
    'UI.CoverImage' : fromUI.CoverImage,
    'UI.ContentContainer' : fromUI.ContentContainer,
    'UI.Html' : fromUI.Html,
    'UI.Text' : fromUI.Text,
    'UI.Number' : fromUI.Number,
    'UI.Script' : fromUI.Script,
    'UI.Sticky' : fromUI.Sticky,
    'UI.Grid' : fromUI.Grid,
    'UI.Grid.Item' : fromUI.GridItem,
    'UI.Heading' : fromUI.Heading,
    'UI.Paragraph' : fromUI.Paragraph,
    'UI.SectionHeading' : fromUI.SectionHeading,
    'UI.NavigationMenu' : fromUI.NavigationMenu,
    'UI.Flyout' : fromUI.Flyout,
    'UI.Flyout.Header' : fromUI.FlyoutHeader,
    'UI.Flyout.Content' : fromUI.FlyoutContent,
    'UI.PlayerIdentity' : fromUI.PlayerIdentity,
    'UI.Percent' : fromUI.Percent,
    'UI.Json' : fromUI.Json,
    'UI.EventDate' : fromUI.EventDate,
    'UI.PageTitle' : fromUI.PageTitle,
    'UI.Page' : fromUI.Page,
    'UI.GamePageTitle' : fromUI.GamePageTitle,
    'UI.SoccerMatchCard' : fromUI.SoccerMatchCard,
    'UI.Content' : fromUI.Content,
    'UI.Markdown' : fromUI.Content,
    'UI.SiteFooter' : fromUI.SiteFooter,
    'UI.Sidebar' : fromUI.Sidebar,
    'UI.TemplateContentRegion' : fromUI.TemplateContentRegion,
    'UI.Theme' : fromUI.Theme,
    'UI.Hero' : fromUI.Hero,
    'UI.Panel' : fromUI.Panel,
    'UI.Prize' : fromUI.Prize,
    'UI.Divider' : fromUI.Divider,
    'UI.ContextMenu' : fromUI.ContextMenu,
    
    // This section is deprecated
    'UI.Sum' : fromData.Sum,
    'UI.DataGrid' : fromData.DataGrid,
    'UI.DataGrid.Column' : fromData.DataGridColumn,

    

    'Data.Connect' : fromData.Connect,
    'Data.Group' : fromData.Group,
    'Data.Repeater' : fromData.Repeater,
    'Data.Transform' : fromData.Transform,
    'Data.If' : fromData.If,
    'Data.PlayerProfile' : fromData.PlayerProfile,
    'Data.MatchPlayerStatistics' : fromData.MatchPlayerStatistics,
    'Data.Filter' : fromData.Filter,
    'Data.PlayerIdentity' : fromData.PlayerIdentity,
    'Data.Percent' : fromData.Percent,
    'Data.Sum' : fromData.Sum,
    'Data.Number' : fromData.Number,
    'Data.EventDate' : fromData.EventDate,
    'Data.Text' : fromData.Text,
    'Data.Json' : fromData.Json,
    'Data.DataGrid' : fromData.DataGrid,
    'Data.DataGrid.Column' : fromData.DataGridColumn,
    'Data.SetCurrentContext' : fromData.SetCurrentContext,

    'Datasource.PlayerPassport' : fromDatasource.PlayerPassport,
    'Datasource.TeamPassport' : fromDatasource.TeamPassport,
    'Datasource.TeamPlayers' : fromDatasource.TeamPlayers,
    'Datasource.Schedule' : fromDatasource.Schedule,
    'Datasource.MatchPlayerStatistics' : fromDatasource.MatchPlayerStatistics,
    
    'UI.TournamentPlayerHighlightList' : fromUI.TournamentPlayerHighlightList,

    'Layout.Grid' : fromLayout.Grid,
    'Layout.Grid.Item' : fromLayout.GridItem,
    'Layout.Stack' : fromLayout.Stack,
    'Layout.Stack.Item' : fromLayout.StackItem,
    'Layout.FlyoutSummary' : fromLayout.FlyoutSummary,
    'Layout.FlyoutSummary.Item' : fromLayout.FlyoutSummaryItem,
    'Layout.FlyoutSummary.Media' : fromLayout.FlyoutSummaryMedia,
    'Layout.PageContentContainer' : fromLayout.PageContentContainer,
    'Layout.BlockGridLayout' : fromLayout.BlockGridLayout,
    'Layout.ColumnLayout' : fromLayout.ColumnLayout,
    'Layout.ColumnLayout.Column' : fromLayout.ColumnLayoutColumn,


    'Match.Timeline' : fromMatch.Timeline,
    'Match.VersusTitle' : fromMatch.VersusTitle,
    'Match.Title' : fromMatch.Title,
    'Match.Odds' : fromMatch.Odds,
    'Match.LocationCoverImage' : fromMatch.LocationCoverImage,
    'Match.Location' : fromMatch.Location,
    'Match.Start' : fromMatch.Start,
    'Match.Property' : fromMatch.Property,

    'Player.Avatar' : fromPlayer.Avatar,
    'Player.Value' : fromPlayer.Value,
    'Player.Trend' : fromPlayer.Trend,
    'Player.Popularity' : fromPlayer.Popularity,
    'Player.EventCount' : fromPlayer.EventCount,
    'Player.TotalGrowth' : fromPlayer.TotalGrowth,
    'Player.FactList' : fromPlayer.FactList,
    'Player.VersusMatchPointList' : fromPlayer.VersusMatchPointList,
    'Player.SimpleMatchPointList' : fromPlayer.SimpleMatchPointList,
    'Player.IdentityTopbar' : fromPlayer.IdentityTopbar,
    'Player.Form' : fromPlayer.Form,
    'Player.Statistics' : fromPlayer.Statistics,
    'Player.InjuryStatus' : fromPlayer.InjuryStatus,
    'Player.SuspensionStatus' : fromPlayer.SuspensionStatus,
    
    'WhenMatch' : WhenMatch,

    'SoccerLineup' : SoccerLineupWidget,
    'SoccerMatchHead' : SoccerMatchHead,
    'GolfMatchHead' : GolfMatchHead,
    'MotorsportMatchHead' : MotorsportMatchHead,

    'HandballLineup' : HandballLineupWidget,
    'GenericVersusMatchHead' : GenericVersusMatchHead,

    'FootballLineup' : FootballLineupWidget,
    'HockeyLineup' : HockeyLineupWidget,

    'GolfTournamentReport' : GolfTournamentReport,

    'MotorsportDriverList' : MotorsportDriverListWidget,
    'MotorsportPitcrewList' : MotorsportPitcrewListWidget,
    'MotorsportConstructorList' : MotorsportConstructorListWidget,

    'TournamentStandings' : TournamentStandings,
    'Competitions' : Competitions,
    'SoccerSchedule' : SoccerSchedule,
    'GolfSchedule' :  GolfSchedule,
    'CyclingSchedule' : CyclingSchedule,
    'MotorsportSchedule' : MotorsportSchedule,
    'GenericVersusSchedule' : GenericVersusSchedule,
    'GenericSchedule' : GenericSchedule,
    'StockSchedule' : StockSchedule,
    'SkiSchedule' : SkiSchedule,
    
    'PlayerStatisticsDataGrid' : PlayerStatisticsDataGrid,

    'GameContainer' : Game,
    'RemountElement':  RemountElement,

    'Misc.LineupBuilder':  LineupBuilder,

    'Security.Signup':  fromSecurity.Signup
};


export default componentMap;