import React from 'react';

import * as styles from './ColumnLayout.module.css'

export const ColumnLayoutColumn = ({ children }) => {
   return <div>{ children }</div>;
}

export const ColumnLayout = ({ children }) => {
    const templateColumns = React.Children.map(children, child => child.props.width || '1fr').join(' ');

    return (
        <div className={ styles.container } style={{ gridTemplateColumns : templateColumns }}>
            { children}
        </div>
    )
}

ColumnLayout.defaultProps = {

}