import React, { useState } from 'react'
import { ToolbarButton, ToolbarButtonGroup, Icon } from 'components'
import Enrollments from './Enrollments'
import { matchPath, useLocation } from 'react-router-dom'

import { 
    NotificationIcon,
    Attachment,
    Flyout,
    FlyoutOverlayContent,
    TabBar, TabBarItem,
    Box,
    ExpandablePanel,
    Text,
    SectionHeading,
    Avatar
} from 'components'

import useCompetition from './useCompetition'

import Info from './Info'
//import Invite from './Invite'
import Settings from './Settings'
import MessageBoard from '../containers/messageBoard/MessageBoard'
import useUnread from '../containers/messageBoard/useUnread'
import MyEnrollments from './MyEnrollments';
import { useTranslator } from '../hooks/useTranslator'

import auth from '../common/auth';


const MessageBoardTitle = ({ competition }) => {
    const t = useTranslator();

    const [unread] = useUnread(competition && competition.messageBoard && competition.messageBoard.id)
    return (
        <Attachment attachment={ unread && <NotificationIcon count={ unread} /> } top={ -12 } right={ -16 }>
            { t('common.messageboard') }
        </Attachment>
    )
}



const Header = ({ competition }) => {
    const t = useTranslator();

    return (
        <Box display="flex" height="s400" alignItems="center" px="s300" boxShadow="flyout">
            <Avatar variant="rounded" size="small" name={ competition.name } src={ competition.imageUrl } mr="s200" />
            <Box>
                <Text fontWeight="bold">{ competition && competition.name }</Text>
                <Text size="xs">{ t(`competitions.format_${ competition.type }`) }</Text>
            </Box>
        </Box>
    )
}

const ExtendedHeader = ({ competition }) => {
    const t = useTranslator();
    return (
        <Box minHeight="180px" display="flex" alignItems="center" justifyContent="center">
            <Box textAlign="center">
                <Avatar variant="rounded" size="huge" name={ competition && competition.name } src={ competition && competition.imageUrl }  boxShadow="flyout"  mb="s400"/>
                <Text fontWeight="bold"> { competition && competition.name }</Text>
                <Text size="xs">{ t(`competitions.format_${ competition.type }`) }</Text>
            </Box>
        </Box>
    )
}


const MenuWrap = ({ view, competition, children }) => {

    

    return (
        <Box style={{ position: 'sticky', top: '48px', height: '48px', zIndex: 3000}} backgroundColor="siteBackground">
            <ExpandablePanel open={ view }>
                {
                    view == 'info' && competition &&
                    <Info competition={ competition }/>
                }

            </ExpandablePanel>
            
            {children}
        </Box>
    )
}

const CompetitionFlyout = ({ competitionId, initialTab, onClose }) => {
    const [competition] = useCompetition(competitionId);
    
    const userId = auth.getUserId();
    const [view, setView] = useState();
    const loc = useLocation();
 
    const t = useTranslator();

    const isOwner = competition && competition.owner && competition.owner.id == userId;

    let initialTabIndex = 0;
    if (initialTab == 'messageBoard')
        initialTabIndex = 1;

    const toolbar = (
        <ToolbarButtonGroup>
            <ToolbarButton onClick={ () => view == 'info' ? setView() : setView('info') } selected={ view === 'info' } >
                <Icon type="info"/>
            </ToolbarButton>
            <ToolbarButton  onClick={ onClose } >
                <Icon type="close"/>
            </ToolbarButton>
        </ToolbarButtonGroup>
    )

    const handleDelete = () => {
        const match = matchPath(loc.pathname, { path : '/:language/games/:gameSlug/:gameId'});
        location.href = `/${ match.params.language }/games/${ match.params.gameSlug }/${ match.params.gameId }/competitions`;

        onClose();
    }

    return (
        <Flyout>
            <FlyoutOverlayContent header={ <Header competition={ competition } /> } toolbar={ toolbar }>
                <ExtendedHeader competition={ competition }></ExtendedHeader>

                {
                    competition &&
                    
                    <TabBar themeName="_current"  renderMenu={ (component) => <MenuWrap view={ view } competition={ competition }>{component }</MenuWrap> }  initialTabIndex={ initialTabIndex }>
                        
                        
                        <TabBarItem title={ t('competitions.enrolled') }>
                            <Box p="m" flex="1" display="flex" flexDirection="column">

                                {
                                        competition.kind == 'groupGame' &&
                                        <Box>
                                            <SectionHeading>{ t('competitions.my_teams') }</SectionHeading>
                                            <MyEnrollments competitionId={ competitionId } mb="m" />
                                        </Box>
                                }
                                

                                <Box flex="1" display="flex" flexDirection="column">
                                    <SectionHeading>{ t('competitions.all_enrollments') }</SectionHeading>
                                    <Enrollments competition={ competition }  canKick={ isOwner } />
                                    
                                </Box>
                            </Box>
                        </TabBarItem>

                        
                        {
                            competition.messageBoard &&
                            <TabBarItem title={ <MessageBoardTitle competition={ competition } /> }>
                                {
                                    competition.messageBoard && <MessageBoard messageBoardId={ competition.messageBoard.id }/> || null
                                }
                            </TabBarItem>
                        }

                        {
                            isOwner &&
                            <TabBarItem title={ t('competitions.settings_heading') }>
                                <Box p="m" flex="1">
                                    <Settings competition={ competition } onDelete={ handleDelete }/>
                                </Box>
                            </TabBarItem>
                        }
                    </TabBar>
                }
            </FlyoutOverlayContent>
        </Flyout>

    )
}

export default CompetitionFlyout;
