import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { useRef } from 'react';


const Script = ({ editMode, src, name, arg, children }) => {
    const ref = useRef();    
    
    useEffect(() => {
        if (editMode)
            return;

        if (ref.current) {
            let plugin = null;

            if (window[name]) {
                plugin = window[name](ref.current, arg);


                try {
                    plugin.mount();
                }
                catch {
                    console.error(`An error occurred mounting plugin ${ name }.`);
                }
            }
            else {
                var el = document.createElement('script');

                el.async = true;
                el.type = 'text/javascript';
                el.src = src;
    
                el.onload = () => {
                    if (!window[name]) {
                        console.warn(`Script did not register ${ name } on window.`);
                    }
                    else {
                        plugin = window[name](ref.current, arg);
                        
                        try {
                            plugin.mount();
                        }
                        catch {
                            console.error(`An error occurred mounting plugin ${ name }.`);
                        }
                    }
                }
    
                document.body.appendChild(el);
            }

            return () => {
                if (plugin) {
                    try {
                        plugin.unmount();
                    }
                    catch {
                        console.error(`An error occurred unmounting plugin ${ name }.`);
                    }
                }
            }
        }

    }, [])
    
    
    return <div ref={ ref }>{ children }</div>;
}


export default Script;