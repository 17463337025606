// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YCohuUOZRPw6eqsGMQUo {
    max-width: 1200px;
    margin: 0 auto;
    display: none;
    margin-top: 24px;

    color : var(--meta-text-color);
    font-size: 12px;
}

.YCohuUOZRPw6eqsGMQUo p { 
    font-size: inherit; 
    line-height: 2; 
}

.YCohuUOZRPw6eqsGMQUo a { 
    color : var(--meta-text-color);
    text-decoration: underline;
}

.YCohuUOZRPw6eqsGMQUo a  { opacity: 0.5; transition: 0.1s linear opacity; will-change: opacity; }
.YCohuUOZRPw6eqsGMQUo a:hover img { opacity: 1; }

@media screen and (min-width: 1024px) {
    .YCohuUOZRPw6eqsGMQUo {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/containers/widgets/ui/SiteFooter.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,aAAa;IACb,gBAAgB;;IAEhB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,8BAA8B;IAC9B,0BAA0B;AAC9B;;AAEA,2BAAgB,YAAY,EAAE,+BAA+B,EAAE,oBAAoB,EAAE;AACrF,oCAAyB,UAAU,EAAE;;AAErC;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".container {\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n    display: none;\r\n    margin-top: 24px;\r\n\r\n    color : var(--meta-text-color);\r\n    font-size: 12px;\r\n}\r\n\r\n.container p { \r\n    font-size: inherit; \r\n    line-height: 2; \r\n}\r\n\r\n.container a { \r\n    color : var(--meta-text-color);\r\n    text-decoration: underline;\r\n}\r\n\r\n.container a  { opacity: 0.5; transition: 0.1s linear opacity; will-change: opacity; }\r\n.container a:hover img { opacity: 1; }\r\n\r\n@media screen and (min-width: 1024px) {\r\n    .container {\r\n        display: block;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var container = `YCohuUOZRPw6eqsGMQUo`;
export default ___CSS_LOADER_EXPORT___;
