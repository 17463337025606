import React from 'react';
import PropTypes from 'prop-types'

import { SectionHeading } from 'components'

export const SectionHeadingWidget = ({ editMode, children }) => {

    return <SectionHeading>{ children }</SectionHeading>

}

