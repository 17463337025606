//************************************************************
// Redefines CSS variables for a game header.
//************************************************************
import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as styles from './Table.scss'

const TableHeader = ({  left, right, center, colSpan, children }) =>{
    const className = classNames(
        {
            [styles.left] : left,
            [styles.right] : right,
            [styles.center] : center
        }
    )

    return (
        <th className={ className } colSpan={ colSpan }>
            { children}
        </th>
    )
}

const TableCell = ({ left, right, center, highlight, children, ...otherProps }) =>{
    const className = classNames(
        {
            [styles.left] : left,
            [styles.right] : right,
            [styles.center] : center,
            [styles.highlight] : highlight
        }
    )

    return (
        <td className={ className } { ...otherProps }>
            { children}
        </td>
    )
}

const TableRow = ({ active, highlight, disabled, onClick, children }) =>{
    const className = classNames(
        {
            [styles.active] : active,
            [styles.highlight] : highlight,
            [styles.disabled] : disabled,
            [styles.clickable] : onClick

        }
    )
    
    return (
        <tr className={ className } onClick={ onClick }>
            { children}
        </tr>
    )
}



const TableBody = ({ children }) =>{
    return (
        <tbody>
            { children}
        </tbody>
    )
}


const TableHead = ({ children }) =>{
    return (
        <thead>
            { children}
        </thead>
    )
}


class Table extends React.PureComponent {
    static Head = TableHead;
    static Body = TableBody;

    static Row = TableRow;
    static Header = TableHeader;
    static Cell = TableCell;

    render() {
        const { compact, children } = this.props;

        const className = classNames(
            styles.table,
            {
                [styles.compact] : compact
            }
        );

        return (
            <table className={ className  }>
                { children}
            </table>
        )
    }
}

export default Table;