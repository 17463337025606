import * as types from './types'
import * as gameTypes from '../game/types'

const defaultState = {
    competitions : {
        items : null,
        isFetching : false,
        didInvalidate : false
    },
    search : {},
    standings : {},
    qualifications : {},
    enrollmentOptions : {},
    byCompetition : {},
    invitees : {
        type : 'friends',
        didFetch : false,
        isFetching : false,
        items : null
    },
    previous : {},
    
    competition : {
        item : null,
        isFetching : false,
        didInvalidate : false
    },
    enrollments : {
        isFetching : false, 
        didInvalidate : false, 
        items : null,
        competitionId : 0,
        page: 1,
        pageSize: 25
    },
    competitionHistory : {
        isFetching : false,
        didFetch : false
    }
};

const defaultByCompetitionState = {
    enrollments : {},
    myEnrollments : {},
    enrollmentOptions : {}
}

const defaultSearchState = {
    count : 0,
    items : null,
    links : {}
}

const searchReducer = (state = defaultSearchState, action) => {
    switch (action.type) {
        case types.FIND_COMPETITIONS_REQUEST:
            return { ...state, isFetching : true };
        case types.FIND_COMPETITIONS_SUCCESS:
            return { 
                ...state,
                count : action.count,
                links : action.links,
                isFetching : false,
                items : action.payload.result
            };
        case types.FIND_COMPETITIONS_FAIL:
            return { ...state, isFetching : false };
        case types.FIND_MORE_COMPETITIONS_REQUEST:
            return { ...state, isFetching : true };
        case types.FIND_MORE_COMPETITIONS_SUCCESS:
            return { 
                ...state,
                count : action.count,
                links : action.links,
                isFetching : false,
                items : [...state.items, ...action.payload.result]
            };
        case types.FIND_MORE_COMPETITIONS_FAIL:
            return { ...state, isFetching : false };
        default:
            return state;
    }
}


const byCompetitionReducer = (state = defaultByCompetitionState, action) => {
    switch (action.type) {

        /* Enrollments */

        case types.FETCH_ENROLLMENTS_REQUEST:
            return { ...state, enrollments : { ...state.enrollments, isFetching : true }};        
        case types.FETCH_ENROLLMENTS_SUCCESS:
            return {
                ...state,
                enrollments : {
                    ...state.enrollments,
                    isFetching : false,
                    item : action.payload.result,
                    didInvalidate: false
                }
            };           
        case types.FETCH_ENROLLMENTS_FAIL:
            return  { ...state, enrollments : { ...state.enrollments, isFetching : false } };             
        case types.FETCH_MORE_ENROLLMENTS_REQUEST:
            return { ...state, enrollments : { ...state.enrollments, isFetching : true }};        
        case types.FETCH_MORE_ENROLLMENTS_SUCCESS:
            return {
                ...state,
                enrollments : {
                    ...state.enrollments,
                    isFetching : false,
                    item : {
                        ...state.enrollments.item,
                        items : [...state.enrollments.item.items, ...action.payload.result.items],
                    }
                }
            };           
        case types.FETCH_MORE_ENROLLMENTS_FAIL:
            return  { ...state, enrollments : { ...state.enrollments, isFetching : false } };             



    

        /* My Enrollments */

        case types.FETCH_MY_ENROLLMENTS_REQUEST:
                return { ...state, myEnrollments : { ...state.myEnrollments, isFetching : true } };
        case types.FETCH_MY_ENROLLMENTS_SUCCESS:
            return {
                ...state,
                myEnrollments : {
                    ...state.myEnrollments,
                    isFetching : false,
                    items : action.payload.result,
                    didFetch : true
                }
            };
        case types.FETCH_MY_ENROLLMENTS_FAIL:
            return { ...state, myEnrollments : { ...state.myEnrollments, isFetching : false } };
        

        // EnrollmentOptions
        case types.INVALIDATE_ENROLLMENT_OPTIONS:
            return  { ...state, enrollmentOptions : { ...state.enrollmentOptions, didInvalidate :  true } };
        case types.FETCH_ENROLLMENT_OPTIONS_REQUEST:
            return  { ...state, enrollmentOptions : { ...state.enrollmentOptions, isFetching :  true } };
        case types.FETCH_ENROLLMENT_OPTIONS_SUCCESS:
            return  { 
                ...state,
                enrollmentOptions : {
                    ...state.enrollmentOptions,
                    didFetch : true,
                    isFetching :  false,
                    didInvalidate : false,
                    item : action.payload.result
                }
            };
        case types.FETCH_ENROLLMENT_OPTIONS_FAIL:
            return  { ...state, enrollmentOptions : { ...state.enrollmentOptions, isFetching :  false } };

        default:
            return state;
    }
}


const competitionReducer = (state = defaultState, action) =>
{
    switch (action.type) {
   
//        case types.INVITE_SUCCESS:                     
        case types.FETCH_ENROLLMENTS_REQUEST:
        case types.FETCH_ENROLLMENTS_SUCCESS:
        case types.FETCH_ENROLLMENTS_FAIL:
        case types.FETCH_MORE_ENROLLMENTS_REQUEST:
        case types.FETCH_MORE_ENROLLMENTS_SUCCESS:
        case types.FETCH_MORE_ENROLLMENTS_FAIL:            
        case types.FETCH_MY_ENROLLMENTS_REQUEST:
        case types.FETCH_MY_ENROLLMENTS_SUCCESS:
        case types.FETCH_MY_ENROLLMENTS_FAIL:
        case types.FETCH_ENROLLMENT_OPTIONS_REQUEST:
        case types.FETCH_ENROLLMENT_OPTIONS_SUCCESS:
        case types.FETCH_ENROLLMENT_OPTIONS_FAIL:
        case types.INVALIDATE_ENROLLMENT_OPTIONS:
        case types.RESIGN_SUCCESS:
            return {

                ...state,
                byCompetition : {
                    ...state.byCompetition,
                    [action.competitionId] : byCompetitionReducer(state.byCompetition[action.competitionId], action)
                }
            }

        case types.FIND_COMPETITIONS_REQUEST:
        case types.FIND_COMPETITIONS_SUCCESS:
        case types.FIND_COMPETITIONS_FAIL:
        case types.FIND_MORE_COMPETITIONS_REQUEST:
        case types.FIND_MORE_COMPETITIONS_SUCCESS:                   
        case types.FIND_MORE_COMPETITIONS_FAIL:        
        return {
            ...state,
            search : searchReducer(state.search, action)
        }            

        case gameTypes.SELECT_GAME:
            return defaultState;

        case types.ENROLLMENT_CHANGE:
            return  { 
                ...state,
                enrollmentOptions : { ...state.enrollmentOptions, didInvalidate : true },
                competitions : { didInvalidate : true }
            };

        /* User Competitions */

        case types.INVALIDATE_USER_COMPETITIONS:
            return  { ...state, competitions : { ...state.competitions, didFetch : false, didInvalidate : true } };
        case types.FETCH_USER_COMPETITIONS_REQUEST:
            return { ...state, competitions : { ...state.competitions, isFetching :  true } };
        case types.FETCH_USER_COMPETITIONS_SUCCESS:
            return {
                ...state,
                competitions : {
                    ...state.competitions,
                    didFetch : true,
                    isFetching :  false,
                    didInvalidate : false,
                    items : action.payload.result
                }
            };
        case types.FETCH_USER_COMPETITIONS_FAIL:
            return { ...state, competitions : { ...state.competitions, isFetching :  false } };                

        /* Leaderboards */

        case types.FETCH_USER_LEADERBOARDS_SUCCESS:
            const userState = state.leaderboardsByUser[action.user] || {};    
            const competitionState = userState[action.competition];
        
            return  { 
                ...state,
                leaderboardsByUser : {
                    ...state.leaderboardsByUser,
                    [action.user] : {
                        ...userState,
                        [action.competition] : {
                            ...competitionState,
                            [action.round] : action.payload.result
                        }
                    }
                }
            };

        /* Standings */

        case types.FETCH_STANDINGS_REQUEST:
            return { ...state, standings : { ...state.standings, isFetching : true } };
        case types.FETCH_STANDINGS_SUCCESS:
            return {
                ...state,
                standings : {
                    isFetching : false,
                    didInvalidate : false,
                    items : action.payload.result
                }
            };                          
        case types.FETCH_STANDINGS_FAIL:
            return { ...state, standings : { ...state.standings, isFetching : false } };   

        /* Qualifications */
                
        case types.FETCH_QUALIFICATIONS_REQUEST:
            return  { ...state, qualifications : { ...state.qualifications, isFetching : true } };
        case types.FETCH_QUALIFICATIONS_SUCCESS:
            return  { 
                ...state,
                qualifications : {
                    ...state.qualifications,
                    isFetching : false,
                    didInvalidate : false,
                    items : action.payload.result
                }
            };                          
        case types.FETCH_QUALIFICATIONS_FAIL:
            return  { ...state, qualifications : { ...state.qualifications, isFetching : false }};   
        
        /* Fetch Competition */

        case types.FETCH_COMPETITION_REQUEST:
            return  { ...state, competition : { ...state.competition, isFetching : true } };        
        case types.FETCH_COMPETITION_SUCCESS:
            return  { 
                ...state,
                competition : {
                    ...state.competition,
                    isFetching : false,
                    item : action.payload.result,
                    didInvalidate: false
                }
            };
        case types.FETCH_COMPETITION_FAIL:
            return  {  ...state, competition : { ...state.competition, isFetching : false } };             
                
        /* Invitees */

        case types.FETCH_MY_COMPETITION_HISTORY_REQUEST:
            return  { ...state, competitionHistory : { ...state.competitionHistory, isFetching : true } };        
        case types.FETCH_MY_COMPETITION_HISTORY_SUCCESS:
            return  { 
                ...state,
                competitionHistory : {
                    ...state.competitionHistory,
                    isFetching : false,
                    data : action.payload,
                    didInvalidate: false
                }
            };
        case types.FETCH_MY_COMPETITION_HISTORY_FAIL:
            return  {  ...state, competitionHistory : { ...state.competitionHistory, isFetching : false } };             
        
         
        default:
            return state
    }
}

export default competitionReducer;
