export const UI_SELECT_PLAYER       = 'UI_SELECT_PLAYER';
export const SELECT_TOURNAMENT      = 'ui/select-tournament';
export const UI_HIDE_SIDEBAR        = 'UI_HIDE_SIDEBAR';
export const UI_SELECT_MATCH        = 'UI_SELECT_MATCH';
export const UI_SHOW_GROUP_SETTINGS = 'UI_SHOW_GROUP_SETTINGS';
export const UI_STATISTICS_FILTER   = 'UI_STATISTICS_FILTER';
export const UI_SCHEDULE_FILTER     = 'UI_SCHEDULE_FILTER';

export const UI_PUSH_FLYOUT         = 'UI_PUSH_FLYOUT';
export const UI_POP_FLYOUT          = 'UI_POP_FLYOUT';
export const UI_CLEAR_FLYOUTS       = 'UI_CLEAR_FLYOUTS';

export const UI_SET_DATA            = 'UI_SET_DATA';
export const UI_REPLACE_DATA        = 'UI_REPLACE_DATA';
export const UI_CLEAR_DATA          = 'UI_CLEAR_DATA'

export const UI_SET_TITLE           = 'ui/title/set';

export const UPDATE_NOTIFICATION_COUNT = 'ui/notificationcount/update';

export const HIDE_MODAL             = 'HIDE_MODAL';
export const SHOW_MODAL             = 'SHOW_MODAL';