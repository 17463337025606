import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import auth from '../common/auth';
import Root from '../containers/Root'
import serverTime from '../common/serverTime';

import config from 'config';
import configureStore from './configureStore'

import { login } from '../redux/auth/actions'
import { selectGame } from '../redux/game/actions'

import '../fonts/Geomanist-Light-Webfont/stylesheet.css'
import '../fonts/Geomanist-Ultra-Webfont/stylesheet.css'
import '../fonts/roboto/stylesheet.css'

import ws from '../common/WebSocketClient'

// Dayjs configuration
import dayjs from 'dayjs'
import 'dayjs/locale/da'
import 'dayjs/locale/sv'

window.process = { cwd: () => '' };

import '../css/main.css'



const setDayJsLocale = (locale) => {
    switch(locale) {
        case 'da':
        case 'sv': 
            dayjs.locale(locale);
            break;
    }
}


setDayJsLocale(window.swush.locale);

// Extensions

Array.prototype.groupBy = function(func) {
    return this.reduce(function(groups, item, index) {
        var val = func(item, index);
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
}

axios.defaults.baseURL = config[window.swush.appId + '_apiBaseUrl'];

axios.interceptors.request.use(
    function (cfg) {
        if (cfg.url.indexOf('//') == -1) {
            cfg.url = cfg.url + (cfg.url.indexOf('?') >= 0 ? '&' : '?') + 'appid=' + window.swush.appId + '&culture=' + window.swush.apiCulture;
        }
        
        return cfg;
    },
    function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log('Handle refresh and re-call again.');
        }

        return Promise.reject(error);
    }
)

const store = configureStore();

store.dispatch(login())

ws.initialize(config[window.swush.appId + '_liveWebsocketUrl']);


// A bit of a hack to select the current game for old pages.
if (window.gameId) {
    store.dispatch(selectGame(window.gameId));
}

serverTime.sync();
auth.loadAuth();

export { store }