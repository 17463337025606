
import React from 'react'

import classNames from 'classnames';

import * as styles from './Card.module.css'

export const CardInset = ({ className, children }) => {
    const _className = classNames(
        styles.inset,
        className
    )

    return <div className={ _className }>{ children }</div>
}

export const Card = ({ className, children, contextMenu, onClick, ...otherProps }) => {
    
    const _className = classNames(
        styles.container,
        className,
        {
            [styles.clickable] : onClick
        }
    )

    return (
        <div className={ _className } { ...otherProps } onClick={ onClick}>{ children }</div>
    )
}