import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './GameIdentity.scss'

const GlobalIdentity = ({ title }) =>{
    return (
        <div className={ styles.container }>
            <div className={ styles.content }>
                <div className={ styles.left }>

                    <div>
                      

                        <div className={ styles.title }>
                            { title }
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

GlobalIdentity.propTypes = {
    sponsorImageUrl: PropTypes.string,
    phase : PropTypes.object
}

export default GlobalIdentity;