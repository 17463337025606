import React from 'react'

import * as fromComponents from 'components'

export const FaqItem = ({ children, ...otherProps }) => {
    return (
        <fromComponents.FaqItem { ...otherProps }>{ children }</fromComponents.FaqItem>
    )
}

export const Faq = ({ children, ...otherProps }) => {
    return (
        <fromComponents.Faq { ...otherProps }>
            {
                children
            }
        </fromComponents.Faq>
    )
    
}