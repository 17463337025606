import React from 'react'
import { Component } from 'react'
import PropTypes from 'prop-types';

const GameContext = React.createContext({})

class ContextProvider extends Component {
    getChildContext() {
        
        
        const { context } = this.props;
        return { context };
    }

    render() {
        <GameContext.Provider value={ this.props.context }>
            { this.props.children }
        </GameContext.Provider>
    }
}

ContextProvider.propTypes = {
    context : PropTypes.object
}

ContextProvider.childContextTypes = {
    context : PropTypes.object
}

export default ContextProvider;