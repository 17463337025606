// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JHvmOdXOmoAIxTAG_44X{height:48px;width:100%;display:flex;flex-direction:row;justify-content:center;align-items:center}.pI1W8GqAM7pfXGvGYY4Z{flex:1}.Ju9SXJArII0abJuwm5GS{font-size:14px;font-weight:bold;line-height:16px}.jrqA1vPuA8E0dqeouQYJ{font-size:11px;line-height:14px;color:#8a8a8a}.NezBbuzsaU876EUcg7cw{text-align:right;font-size:14px;line-height:48px}`, "",{"version":3,"sources":["webpack://./src/components/AccordionVersusHeader.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,UAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAIJ,sBACI,MAAA,CAIJ,sBACI,cAAA,CACA,gBAAA,CACA,gBAAA,CAGJ,sBACI,cAAA,CACA,gBAAA,CACA,aAAA,CAIJ,sBACI,gBAAA,CAEA,cAAA,CAEA,gBAAA","sourcesContent":[".container {\r\n    height: 48px;\r\n    width: 100%;\r\n    \r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n\r\n.left {\r\n    flex: 1;\r\n    \r\n}\r\n\r\n.team {\r\n    font-size: 14px;\r\n    font-weight: bold;\r\n    line-height: 16px;\r\n}\r\n\r\n.score {\r\n    font-size: 11px;\r\n    line-height: 14px;\r\n    color: #8A8A8A;\r\n    \r\n}\r\n\r\n.growth {\r\n    text-align: right;\r\n\r\n    font-size: 14px;\r\n\r\n    line-height: 48px;\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `JHvmOdXOmoAIxTAG_44X`;
export var left = `pI1W8GqAM7pfXGvGYY4Z`;
export var team = `Ju9SXJArII0abJuwm5GS`;
export var score = `jrqA1vPuA8E0dqeouQYJ`;
export var growth = `NezBbuzsaU876EUcg7cw`;
export default ___CSS_LOADER_EXPORT___;
