import React from 'react'
import PropTypes from 'prop-types'

const Cell = ({ style, children, column, gridRow, ...otherProps }) =>{
    const innerStyle = {
    }

    if (column) {
        innerStyle.gridColumn = this.props.column;
    }

    if (this.props.row) {
        innerStyle.gridRow = gridRow;
    }

    return (
        <div {...otherProps } style={{ ...style, ...innerStyle }}>
            { children }
        </div>
    )     
}


Cell.propTypes = {
    column : PropTypes.string,
    style : PropTypes.object,
    gridRow : PropTypes.any,
    children : PropTypes.node
}

Cell.defaultProps = {
    
}

export default Cell;