import React from 'react'

import { Label } from 'components'
import LocalizeText from '../../LocalizeText'

export const LabelWidget = ({ text }) => {
    return (
        <Label><LocalizeText text={ text } /></Label>
    )
}


