import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { createSelector } from 'reselect'
import { lo } from 'utils'


class WithTeamPassport extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return this.props.children(this.props.passport);
    }
}


WithTeamPassport.propTypes = {
    id : PropTypes.any.isRequired
}


export const makeGetPassport = () => (
    createSelector([
        (state, props) => props.id,

        (state) => state.entities.teams,
        
    ], 
    (id,  teams) => { 
        const team = teams[id];

        return {
            team : {
                ...team,
                name : lo(team, 'name'),
                abbreviation : lo(team, 'abbreviation')
            }
            
        }
    })
);



const makeMapStateToProps = () => {
    const getPassport = makeGetPassport();

    return (state, ownProps) => ({
        passport : getPassport(state, ownProps)
    })
}


export default  connect(makeMapStateToProps)(WithTeamPassport);


