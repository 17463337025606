import { createSelector } from 'reselect'
import { denormalize } from 'normalizr'

import { tournamentSchema } from '../../schemas'


export const makeGetTournament = () => (
    createSelector([
        (state, props) => props.id,
        (state) => state.entities.tournaments,
        (state) => state.entities.teams,
        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.matchGroups,
        (state) => state.entities.locations,
        (state) => state.entities.participants,
        (state) => state.entities.positions
    ], 
    (id, tournaments,  teams, players, persons, matchGroups, locations, participants, positions) => { 


        const entities = {
            tournaments,
            teams,
            players,
            persons,
            matchGroups,
            locations,
            participants,
            positions
        }
            
        return denormalize(id, tournamentSchema, entities)
    }
    )
);