import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import styled from 'styled-components'

const StyledMasonry = styled.div`
    display: grid;
    grid-row-gap : ${ props => props.rowGap }px;
    grid-column-gap : ${ props => props.columnGap }px;
    grid-template-columns: ${ props => `repeat(auto-fill, minmax(${ props.minimumWidth }px, 1fr))` };
    grid-auto-rows: ${ props => props.rowHeight }px;
`

const measureElement = element => {
    const DOMNode = ReactDOM.findDOMNode(element)

    return {
        width: DOMNode.getBoundingClientRect().width,
        height: DOMNode.getBoundingClientRect().height,
    };
}

const Masonry = ({ rowGap, columnGap, rowHeight, minimumWidth, children  }) =>  {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            for(let i = 0; i < ref.current.childNodes.length; i++) {
                const el = ref.current.childNodes[i];
                
                const height = 0;
                
                for(let j = 0; j < el.childNodes.length; j++) {
                    const node = el.childNodes[j];
                    if (node.getBoundingClientRect) {
                        height += el.childNodes[j].getBoundingClientRect().height;
                    }
                }
                
                const span = Math.ceil(
                    (height) / (rowHeight + rowGap)
                );
        
                el.style.gridRowEnd = "span " + span;
                
            }
        }
    })

        
    return  <StyledMasonry rowGap={ rowGap } columnGap={ columnGap } rowHeight={ rowHeight } minimumWidth={ minimumWidth } ref={ ref }>
                { children }
            </StyledMasonry>
}

Masonry.propTypes = {
    rowGap : PropTypes.number.isRequired,
    columnGap : PropTypes.number.isRequired,
    rowHeight : PropTypes.number.isRequired,
    minimumWidth : PropTypes.number.isRequired
}

Masonry.defaultProps = {
}


export default Masonry;
