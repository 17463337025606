import React from 'react';

import { Block } from 'components'


const BlockWidget = ({ children, ...otherProps}) => {
    return <Block { ...otherProps }>{ children }</Block>

}

export default BlockWidget;