import React from 'react'

import { SelectList } from 'components'
import GameContext from '../../GameContext'
import FormContext from '../../FormContext'

import { lo } from 'utils'

const TeamDropdownListWidget = ({ editMode, property, placeholder, select }) => {

    if (editMode) {
        return <SelectList items={ [] } value={ 0 } placeholder={ placeholder }  />
    }


    return (
        <FormContext.Consumer>{ ({ data, setElement }) => {

            const value = data[property];

            return (
            <GameContext.Consumer>{ ({ tournament }) => {
                
                let teams = (tournament && tournament.teams ||[]).map(t => ({ value : t[select], label : lo(t, 'name') }));
                teams.sort((a, b) => a.label.localeCompare(b.label))
                
                const items = [
                    { value : null, label : placeholder },
                    ...teams
                ];
                
                return (
                    <SelectList items={ items } align="left" value={ value } placeholder={ placeholder }
                    
                    onSelect={ (value) => {
                        const item = items.find(i => i.value === value);
                        setElement(property, value, { label : item.label })

                    }} />
                )
            }}</GameContext.Consumer>
            )
        }}</FormContext.Consumer>
    )
}


export default TeamDropdownListWidget;