import React from 'react'
import { Dropdown } from './atoms/Dropdown'
import Box from './Box'
import Avatar from './Avatar'

import styled from 'styled-components'

const StyledContainer = styled.div`
    cursor: pointer;
`

const StyledUserName = styled.div`
    display: block;
    margin-right: 6px;
    max-width: 128px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
`

const UserDropdown = ({ username, imageUrl, hideName, ...otherProps }) => {
    

    return (
        <Dropdown {...otherProps }>
            { 
                ({ open }) => ( 
                    <StyledContainer onClick={ open }>
                        <Box display="flex" alignItems="center">
                            { !hideName && <StyledUserName>{ username }</StyledUserName> }
                            <Avatar src={ imageUrl } name={ username } size="tiny" />
                        </Box>
                    </StyledContainer>
                )
            }
        </Dropdown>
    )     
}

export default UserDropdown;