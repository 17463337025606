import React, { useContext } from 'react'
import { Box, Icon, AlertBox, Text } from 'components'
import GameContext from './GameContext'
import translatable from '../hocs/translatable'
import { lo } from 'utils'




const PersonSuspensionStatus = ({ t, personId }) => {
    const { suspensions, gameConfiguration, schedule, permissionManager } = useContext(GameContext);

    if (!permissionManager.hasPermission('ListSuspensions'))
        return null;

    const personSuspensions = gameConfiguration.suspensions && suspensions.map[personId + ''];


    if (!personSuspensions)
        return null;

    const matches = personSuspensions.reduce((acc, s) => {
        var sm = s.matches.map(m => { 
            const match = schedule.items.find(i => i.id == m);

            const home = match && match.participants && match.participants.find(p => p.type === 'home');
            const away = match && match.participants && match.participants.find(p => p.type === 'away');
            const homeTeam = home && home.team;
            const awayTeam = away && away.team;

            return `${ homeTeam && lo(homeTeam, 'abbreviation') || '?' } - ${ awayTeam && lo(awayTeam, 'abbreviation') || '?' }`;
        })

        return [...acc, ...sm];

    }, []);

    
    const suspensionDays = (personSuspensions || []).reduce((acc, s) => acc += s.remainingMatches, 0);
    const icon = `suspension${ suspensionDays }`
       

    return (
        <AlertBox type="error">
            <Icon type={ icon } shadow />
            <Box ml="s300" flex={ 1 }>
                {
                    personSuspensions.map(i => (
                        <Text size="xs" key={ i.id }>
                            { t('common.suspensionNDays', { count : i.remainingMatches }) } 
                            { matches && matches.length > 0 && <div>{ matches.join(', ') }</div> }
                        </Text>
                    ))
                }
            </Box>
        </AlertBox>
    )
}

export default translatable(PersonSuspensionStatus);