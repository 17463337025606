import React from 'react'

import SimpleSchedule from '../SimpleSchedule'
import GenericScheduleCardList from './GenericScheduleCardList';

import { Media } from '../../components'

import GenericScheduleTable from './GenericScheduleTable'

const GenericScheduleProperty = ({ name }) => {
}

class GenericSchedule extends React.PureComponent
{
    static Property = GenericScheduleProperty;

    render() {
        const { onSelectMatch } = this.props;

        return (
            <SimpleSchedule>
                { 
                    ({ schedule }) => (
                        <div>
                            <Media query="(min-width: 1024px)">
                                <GenericScheduleTable onSelectMatch={ onSelectMatch } schedule={ schedule } />
                            </Media>

                            <Media query="(max-width: 1023px)">
                                <GenericScheduleCardList onSelectMatch={ onSelectMatch }  schedule={ schedule } />
                            </Media>
                        </div>
                    )
                }
            </SimpleSchedule>
        )
    }
}

export default GenericSchedule;