import React from 'react'
import PropTypes from 'prop-types'
import { XButton } from './XButton'
import * as styles from './Button.scss'

const LinkButton = ({ href, external, children, ...otherProps }) => {
    const aHref = external ?
      (href.indexOf('/') == 0) ? window.swush.externalBaseUrl + href : window.swush.externalBaseUrl + href :
      href;

    return <XButton as="a" {...otherProps} href={ aHref }>
                  { children }
           </XButton>
}

LinkButton.propTypes = {
  href: PropTypes.string,
  external : PropTypes.bool,
  external : PropTypes.bool,
  transparent : PropTypes.bool
}

LinkButton.defaultProps = {
  
}


export default LinkButton;






