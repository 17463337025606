import React from 'react';
import PropTypes from 'prop-types'

import { TopBar, Odds } from 'components'

import MatchContext from '../MatchContext'
import When from './When'


const WhenMatch = ({ conditions, children }) => (
    <MatchContext.Consumer>{ (match) => (
        <When obj={ match } conditions={ conditions}>
            { children }
        </When>
    )}</MatchContext.Consumer>

)

WhenMatch.propTypes = {
    conditions : PropTypes.array.isRequired,
}

WhenMatch.defaultProps = {
}

export default WhenMatch;