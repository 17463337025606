// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.o_A2l_UnDa04Erev33Bj {

	display: grid;
	place-items: center;


  }

`, "",{"version":3,"sources":["webpack://./src/components/ads/AdSlot.module.css"],"names":[],"mappings":";AACA;;CAEC,aAAa;CACb,mBAAmB;;;EAGlB","sourcesContent":["\r\n.adSlot {\r\n\r\n\tdisplay: grid;\r\n\tplace-items: center;\r\n\r\n\r\n  }\r\n\r\n"],"sourceRoot":""}]);
// Exports
export var adSlot = `o_A2l_UnDa04Erev33Bj`;
export default ___CSS_LOADER_EXPORT___;
