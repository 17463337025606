// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hy9tVXy3mpAFN1BYgQy2 {
    display: flex;
    align-items: center;
    margin: 12px 0 24px 0;
}

.D0YQ5PEzSsGKgP1363dS {
    font-size: var(--font-size-m);
    color: #fff;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    white-space: nowrap;

}`, "",{"version":3,"sources":["webpack://./src/components/EmailIdentity.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,OAAO;IACP,mBAAmB;;AAEvB","sourcesContent":[".container {\r\n    display: flex;\r\n    align-items: center;\r\n    margin: 12px 0 24px 0;\r\n}\r\n\r\n.email {\r\n    font-size: var(--font-size-m);\r\n    color: #fff;\r\n    font-weight: bold;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    flex: 1;\r\n    white-space: nowrap;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export var container = `Hy9tVXy3mpAFN1BYgQy2`;
export var email = `D0YQ5PEzSsGKgP1363dS`;
export default ___CSS_LOADER_EXPORT___;
