import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { start } from '../redux/auth/actions'

import { useAppConfig } from './AppConfigProvider'

const LegacyHandler = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector(state => state.auth);
    const cfg = useAppConfig();


    useEffect(() => {
        if (!cfg || !cfg.auth || cfg.auth.type != 'local')
            return;

        const tryAuthenticate = e => {
            if (!isAuthenticated) 
            {
                e.preventDefault();
                e.stopPropagation();
                dispatch(start());
            }
        }

        const elements = Array.from(document.querySelectorAll('[data-requires-authentication]'));
        elements.forEach(el => {
            el.addEventListener('click', tryAuthenticate, true)
        });

        return () => {
            elements.forEach(el => {
                el.removeEventListener('click', tryAuthenticate, true)
            });
        }
    }, [cfg, isAuthenticated])



    return null;
}


export default LegacyHandler;
