import React from 'react';
import PropTypes from 'prop-types';

import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { currency, number, minute } from 'utils'

import PlayerIdentity from '../PlayerIdentity'

const Currency = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ currency(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const Minute = ({ value }) => {
    if (typeof value === 'undefined' || value === 0)
        return <span className="meta">-</span>

    else return <span>{ minute(value) }</span>;
}

const PlayerList = ({ heading, items }) => (
    <React.Fragment>
        <Table.Head>
            <Table.Row>
                <Table.Header>{ heading }</Table.Header>
            </Table.Row>
        </Table.Head>
        
        <Table.Body>
            { 
                items.map((item, index) => {
                    return (
                        <Table.Row key={ index }>
                            <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                        </Table.Row>
                    )
                })
            }
        </Table.Body>
    </React.Fragment>
)

const HandballLineup = ({ items, type }) => {

    const passing = items.filter(p => p.participant.type == type && (p.stats.passingYard > 0 || p.stats.passingYard < 0)).sort((a, b) => b.stats.passingYard - a.stats.passingYard);
    const receiving = items.filter(p => p.participant.type == type && (p.stats.receivingYard > 0 || p.stats.receivingYard < 0)).sort((a, b) => b.stats.receivingYard - a.stats.receivingYard);
    const rushing = items.filter(p => p.participant.type == type && (p.stats.rushingYard > 0 || p.stats.rushingYard < 0)).sort((a, b) => b.stats.rushingYard - a.stats.rushingYard);
    const fumbles = items.filter(p => p.participant.type == type && p.stats.fumble > 0).sort((a, b) => a.player.position.order - b.player.position.order);
    const defense = items.filter(p => p.participant.type == type && p.player.position.slug === 'def');
    const kickers = items.filter(p => p.participant.type == type && p.player.position.slug === 'k');

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  passing.length > 0 && <PlayerList items={ passing } heading="Passing" /> }
                    {  rushing.length > 0 && <PlayerList items={ rushing } heading="Rushing" /> }
                    {  receiving.length > 0 && <PlayerList items={ receiving } heading="Receiving" /> }
                    
                    {  fumbles.length > 0 && <PlayerList items={ fumbles } heading="Fumbles" /> }
                    {  defense.length > 0 && <PlayerList items={ defense } heading="Defense" /> }
                    {  kickers.length > 0 && <PlayerList items={ kickers } heading="Kicker" /> }
                </Table>
            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                    {  
                        passing.length > 0 &&
                    
                        <React.Fragment>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Header right>Vækst</Table.Header>
                                    
                                    <Table.Header colSpan={ 2 }></Table.Header>
                                    <Table.Header center>YDS</Table.Header>
                                    <Table.Header center>TD</Table.Header>
                                    <Table.Header center>INT</Table.Header>
                                    
                                    <Table.Header center>2PT</Table.Header>
                                </Table.Row>
                            </Table.Head>

                            <Table.Body>
                                { 
                                    passing.map((item, index) => {
                                        const interceptions = item.stats.passingInterception;
                                        const yards = item.stats.passingYard;
                                        const touchdowns = item.stats.passingTouchdown;
                                        const twoPts = item.stats.twoPointConversionPass;

                                        return (
                                            <Table.Row key={ index }>
                                                <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                                <Table.Cell colSpan={ 2 }></Table.Cell>
                                                <Table.Cell center><Number value={ yards } /></Table.Cell>
                                                <Table.Cell center><Number value={ touchdowns } /></Table.Cell>
                                                <Table.Cell center><Number value={ interceptions } /></Table.Cell>
                                                <Table.Cell center><Number value={ twoPts } /></Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </React.Fragment>
                    }

{  
                    rushing.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header colSpan={ 3 }></Table.Header>
                                <Table.Header center>YDS</Table.Header>
                                <Table.Header center>TD</Table.Header>
                                
                                <Table.Header center>2PT</Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                rushing.map((item, index) => {
                                    const yards = item.stats.rushingYard;
                                    const touchdowns = item.stats.rushingTouchdown;
                                    const twoPts = item.stats.twoPointConversionRun;


                                    return (
                                        <Table.Row key={ index }>
                                            
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell colSpan={ 3 }></Table.Cell>
                                            <Table.Cell center><Number value={ yards } /></Table.Cell>
                                            <Table.Cell center><Number value={ touchdowns } /></Table.Cell>
                                            
                                            <Table.Cell center><Number value={ twoPts } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

{  
                    receiving.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header colSpan={ 3 }></Table.Header>
                                <Table.Header center>YDS</Table.Header>
                                <Table.Header center>TD</Table.Header>
                                
                                <Table.Header center>2PT</Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                receiving.map((item, index) => {
                                    const yards = item.stats.receivingYard;
                                    const touchdowns = item.stats.receivingTouchdown;
                                    const twoPts = item.stats.twoPointConversionReception;


                                    return (
                                        <Table.Row key={ index }>
                                            
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell colSpan={ 3 }></Table.Cell>
                                            <Table.Cell center><Number value={ yards } /></Table.Cell>
                                            <Table.Cell center><Number value={ touchdowns } /></Table.Cell>
                                            
                                            <Table.Cell center><Number value={ twoPts } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

               

                
                {  
                    fumbles.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center colSpan={ 6 }>F</Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                fumbles.map((item, index) => {
                                    const fumbles = item.stats.fumble;

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell center colspan={ 6 }><Number value={ fumbles } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }


                 {  
                    defense.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center>INT</Table.Header>
                                <Table.Header center>SCK</Table.Header>
                                <Table.Header center>TD</Table.Header>
                                <Table.Header center>FF</Table.Header>
                                <Table.Header center>SAF</Table.Header>
                                <Table.Header center>KP</Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                defense.map((item, index) => {
                                    const sacks = item.stats.sack;
                                    const forcedFumbles = item.stats.fumbleForced;
                                    const safeties = item.stats.safety;
                                    const interceptions = item.stats.interception;
                                    const touchdowns = item.stats.defensiveTouchdown;
                                    const returns =  (item.stats.puntReturnTouchdown || 0) + (item.stats.kickReturnTouchdown || 0);

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ interceptions } /></Table.Cell>
                                            <Table.Cell center><Number value={ sacks } /></Table.Cell>
                                            <Table.Cell center><Number value={ touchdowns } /></Table.Cell>
                                            <Table.Cell center><Number value={ forcedFumbles } /></Table.Cell>
                                            <Table.Cell center><Number value={ safeties } /></Table.Cell>
                                            <Table.Cell center><Number value={ returns } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }


                 {  
                    kickers.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header colSpan={ 3 }></Table.Header>
                                <Table.Header center>FGS</Table.Header>
                                <Table.Header center>LFG</Table.Header>
                                <Table.Header center>XP</Table.Header>
                                
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                kickers.map((item, index) => {
                                    const xps = item.stats.extraPoint;
                                    const fieldGoals = item.stats.fieldGoal;
                                    const longestFieldGoal = item.stats.longestFieldGoal;

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell colSpan={ 3 }></Table.Cell>
                                            <Table.Cell center><Number value={ fieldGoals } /></Table.Cell>
                                            <Table.Cell center><Number value={ longestFieldGoal } /></Table.Cell>
                                            <Table.Cell center><Number value={ xps } />
                                            
                                            
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }
                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const HandballLineupWidget = ({ type }) => {
    return (
        <MatchContext.Consumer>{ (match) => {

            return (
                <WithMatchEvents id={ match.id }>{ ({ events }) => (
                    <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                        <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {

                            
                            return (
                                <React.Fragment>
                                    <HandballLineup items={ items } type={ type } />
                                </React.Fragment>
                            )
                        }}</WithMatchReport>
                    )}</WithMatchFantasyEvents>
                )}</WithMatchEvents>
            )
        }}</MatchContext.Consumer>
        
    )
}


HandballLineupWidget.propTypes = {
    type : PropTypes.string.isRequired
}

export default HandballLineupWidget;