import React from 'react';
import { useSelector } from 'react-redux'

import { UserDropdown, Icon } from 'components'

import { getIsAuthenticated } from '../redux/auth/selectors'
import { getMe } from '../redux/user/selectors'

import { useTranslator } from '../hooks/useTranslator'

import NotificationBell from './NotificationBell'

import AuthenticationButton from './AuthenticationButton'
import { StaticRouter } from 'react-router';

const UnauthenticatedMenu = () => {
    const t = useTranslator();

    return (
            <div>
                <AuthenticationButton variant="outline" size="xs">{ t('common.signIn') }</AuthenticationButton>
                    {' '}
                <AuthenticationButton variant="primary" size="xs">{ t('common.register') }</AuthenticationButton>
            </div>
    )
}


const AuthenticatedUserMenu = ({ hideName,  }) => {
    const t = useTranslator();
    const user = useSelector(state => state.user.me && state.user.byId[state.user.me]);

    if (user == null)
        return null;


    const items = [
        { value : '/users/' + user.slug,  label : t('common.profile') },
        { value : '/account',  label : t('common.account') },
        { value : '/signout',  label : t('common.signout')  }
    ];

    const onSelect = (value) => {
        location.href = window.swush.externalBaseUrl + value
    }

    return (
        <React.Fragment>

            { 
                window.swush.basket && window.swush.basket.count > 0 && 
                    <a href={ window.swush.basket.url }>
                        <Icon type="basket" />
                    </a>
            }

            <NotificationBell></NotificationBell>
        
            <UserDropdown username={ user.username } imageUrl={ user.imageUrl } items={ items } alignment="right" hideName={ hideName } onSelect={ onSelect }>
            </UserDropdown>
        </React.Fragment>
    )
}

const UserMenu = React.memo(({ hideName }) => {
    const isAuthenticated = useSelector(state => getIsAuthenticated(state));
    const isAuthenticating = useSelector(state => state.auth.isAuthenticating);
    const me = useSelector(state => getMe(state));
    
    if (isAuthenticating)
        return null;

    return  isAuthenticated ? <AuthenticatedUserMenu user={ me } hideName={ hideName } /> : <UnauthenticatedMenu />;
})

export default UserMenu;