// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T0Og_bEa4rsdsLzrKB5W{display:flex;flex-wrap:wrap;align-items:center;height:48px;min-height:48px;background-color:var(--toolbar-background-color)}.T0Og_bEa4rsdsLzrKB5W.TsEUWfKnUK7lQT3Qcj0g{justify-content:flex-end}.b85sSTmz_MgSc5Hx3tC4{font-size:0}.b85sSTmz_MgSc5Hx3tC4:last-child{margin-right:0}.b85sSTmz_MgSc5Hx3tC4.TsEUWfKnUK7lQT3Qcj0g{margin-left:auto}.b85sSTmz_MgSc5Hx3tC4.PuFBNpmNEJg0J42gSZed{flex:1}`, "",{"version":3,"sources":["webpack://./src/components/Toolbar.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,cAAA,CACA,kBAAA,CAGA,WAAA,CACA,eAAA,CAEA,gDAAA,CAGA,2CACI,wBAAA,CAIR,sBACI,WAAA,CAEA,iCAAA,cAAA,CACA,2CAAA,gBAAA,CACA,2CAAA,MAAA","sourcesContent":["\r\n.container {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n    \r\n    \r\n    height: 48px;\r\n    min-height: 48px;\r\n\r\n    background-color: var(--toolbar-background-color);\r\n\r\n\r\n    &.right {\r\n        justify-content: flex-end;\r\n    }\r\n}\r\n\r\n.item {\r\n    font-size: 0;\r\n\r\n    &:last-child { margin-right: 0; }\r\n    &.right { margin-left: auto; }\r\n    &.expand { flex: 1 }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export var container = `T0Og_bEa4rsdsLzrKB5W`;
export var right = `TsEUWfKnUK7lQT3Qcj0g`;
export var item = `b85sSTmz_MgSc5Hx3tC4`;
export var expand = `PuFBNpmNEJg0J42gSZed`;
export default ___CSS_LOADER_EXPORT___;
