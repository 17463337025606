import { createSelector } from 'reselect'

export const getMatch = createSelector([
    (state) => state.ui.matchId,
    (state) => state.entities.matches,
    (state) => state.entities.locations,
    (state) => state.entities.matchGroups,
    (state) => state.entities.teams
],
(matchId, matchEntities, locationEntities, matchGroupEntities, teamEntities) => {

        const match = matchEntities[matchId];

        if (!match)
            return null;

        const participants = match && match.participants.map(p => {
            const team = teamEntities[p.team];
            return {
                ...p,
                team
            }
        });

        return {
            ...match,
            location : locationEntities[match.location],
            matchGroup : matchGroupEntities[match.group],
            participants
        }
    }
)


export const getMatchEvents = createSelector([
        (state) => state.match.events[state.ui.matchId],
        (state) => state.entities.events,
        (state) => state.entities.players,
        (state) => state.entities.persons,
        (state) => state.entities.teams,
        (state) => state.entities.positions,
        (state) => state.entities.eventTypes
    ],
    (matchEvents, eventEntities, playerEntities, personEntities, teamEntities, positionEntities, eventTypeEntities) => {

        const events = (matchEvents ? matchEvents.items : []).map(eventId => {
            const e = eventEntities[eventId];
            const player = playerEntities[e.player];
            const person = player && personEntities[player.person];
            const team = player && teamEntities[player.team];
            const position = player && positionEntities[player.position];

            return {
                ...e,
                type : eventTypeEntities[e.type],
                player : {
                    ...player,
                    person : person,
                    team : team,
                    position : position
                }
            }
        });

        return {
            ...matchEvents,
            items : events
        }
    }
)




export const makeGetMatchEventNotifications = () => createSelector([
    (state, id) => state.match.eventNotifications[id],
    (state) => state.entities.events,
    (state) => state.entities.players,
    (state) => state.entities.persons,
    (state) => state.entities.teams,
    (state) => state.entities.positions,
    (state) => state.entities.eventTypes
],
(notifications, eventEntities, playerEntities, personEntities, teamEntities, positionEntities, eventTypeEntities) => {
    
    

    return (notifications || []).map(eventId => {
        const e = eventEntities[eventId];


        const player = playerEntities[e.player];
        const person = player && personEntities[player.person];
        const team = player && teamEntities[player.team];
        const position = player && positionEntities[player.position];


        return {
            ...e,
            type : eventTypeEntities[e.type],
            player : {
                ...player,
                person : person,
                team : team,
                position : position
            }
        }
    });
})



export const makeGetMatchFantasyEventNotifications = () => createSelector([
    (state, id) => state.match.fantasyEventNotifications[id],
    (state) => state.entities.fantasyEvents,
    (state) => state.entities.players,
    (state) => state.entities.persons,
    (state) => state.entities.teams,
    (state) => state.entities.positions,
    (state) => state.entities.fantasyEventTypes
],
(notifications, fantasyEventEntities, playerEntities, personEntities, teamEntities, positionEntities, fantasyEventTypeEntities) => {
    

    return (notifications || []).map(eventId => {
        const e = fantasyEventEntities[eventId];

        const player = playerEntities[e.player];
        const person = player && personEntities[player.person];
        const team = player && teamEntities[player.team];
        const position = player && positionEntities[player.position];

        const type = fantasyEventTypeEntities[e.type];

        return {
            ...e,
            type,
            player : {
                ...player,
                person : person,
                team : team,
                position : position
            }
        }
    });
})