import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { createSelector } from 'reselect'

import { setData, replaceData, clearData } from '../redux/ui/actions'

import FormContext from './FormContext'

class FormContextProvider extends React.PureComponent {

    componentDidMount() {
        if (this.props.defaults) {

            const data = Object.keys(this.props.defaults).reduce((agg, k) => {
                agg[k] = {
                    value : this.props.defaults[k]
                };
                return agg;
            }, {})

            this.props.replaceData(data)


        }

        this.setElement = this.setElement.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.clear = this.clear.bind(this);
    }

    setElement(name, value, change) {

        this.props.setData({
            [name] : {
                value,
                change
            }
        })

        
    }

    removeElement(name, index) {

        

        if (index >= 0) {
            const data = this.props.data[name];
            

            
            this.props.setData({
                [name] : {
                    value : [
                        ...data.value.slice(0, index),
                        ...data.value.slice(index + 1)
                    ],
                    change : [
                        ...data.change.slice(0, index),
                        ...data.change.slice(index + 1)
                    ]
                }
            })
        } else {
            this.props.setData({
                [name] : {
                    value : this.props.defaults[name]
                }
            })
        }
    }

    clear() {
        this.props.clearData();
    }

    render() {
        const { data, defaults, setForm, removeForm, clearForm, children } = this.props;


        const formData = {
            ...defaults,
            ...Object.keys(data).reduce((agg, k) => { agg[k] = data[k].value; return agg }, {})
        }



        const changes = Object.keys(data).reduce((agg, key) => {

            
            const change = data[key].change;
            if (change) {

                if (Array.isArray(change)) {
                    return agg.concat(change.map((f, index) => ({ name : key, label : f.label, index })));
                }
                else {
                    
                    return agg.concat([{ name : key, label : change.label }]);
                }
            }



            return agg;
        }, []);



        const providerValue = {
            data : formData,
            changes,
            defaults,
            setElement : (name, value, filter) => this.setElement(name, value, filter),
            removeElement : (name, index) => this.removeElement(name, index),
            clear : () => this.clear()
        }


        return (
            <FormContext.Provider value={ providerValue }>
                { children }
            </FormContext.Provider>
        )
    }
}

export const makeGetData = () => (
    createSelector([
        (state, props) => props.name,
        (state) => state.ui.data
    ],
    (name, data) => {
        return data[name] || {};
    })
)

const makeMapStateToProps = () => {
    const getData = makeGetData();
    
    return (state, ownProps) => ({
        data : getData(state, ownProps)
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
     
        replaceData(data) {
            dispatch(replaceData(ownProps.name, data));
        },
        setData(data) {
            dispatch(setData(ownProps.name, data));
        },
        clearData() {
            dispatch(clearData(ownProps.name));
        }

    }
}

FormContextProvider.propTypes = {
    //dictionary : PropTypes.object,
    name : PropTypes.string.isRequired
}
/*

const WrappedFormContextProvider = () => {
    return (
        <GameContext.Consumer>{ ({ game }) => {






        }}<GameContext.Consumer>
    )
}*/


export default connect(makeMapStateToProps, mapDispatchToProps)(FormContextProvider);

