import { createSelector } from 'reselect'


export const makeGetSchedule = () => (
    createSelector([
        (state, props) => props.id,
        (state, props) => state.schedule.byTournament,
        (state) => state.entities.matches,
        (state) => state.entities.locations,
        (state) => state.entities.matchGroups,
        (state) => state.entities.teams
    ],
    (id, scheduleByTournament, matchEntities, locationEntities, matchGroupEntities, teamEntities) => {
            const schedule = scheduleByTournament[id] || { items : [] };

            return {
                ...schedule,
                items : (schedule.items || []).map(id => {
                    const match = matchEntities[id];
                                       

                    const participants = match && match.participants.map(p => {
                        const team = teamEntities[p.team];
                        return {
                            ...p,
                            team
                        }
                    }) || [];
        
                    return {
                        ...match,
                        location : match && locationEntities[match.location],
                        group : match && matchGroupEntities[match.group],
                        participants
                    }
                })
            }
        }
    )
)


export const getIsScheduleFetching = createSelector([
    (state) => state.ui.selectedTournament,
    (state) => state.schedule.byTournament
],
(selectedTournament, scheduleByTournament) => {
        const schedule = scheduleByTournament[selectedTournament];
        return schedule && schedule.isFetching;
    }
)

export const getIsScheduleLoaded = createSelector([
    (state) => state.ui.selectedTournament,
    (state) => state.schedule.byTournament
],
(selectedTournament, scheduleByTournament) => {
        const schedule = scheduleByTournament[selectedTournament];
        return schedule && schedule.isLoaded;
    }
)

