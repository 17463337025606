import React, { useContext } from 'react';
import { exp } from 'utils';

import { DataContext } from 'components';

const Connect = ({ children, ...otherProps }) => {
    const context = useContext(DataContext);
    const childProps = Object.keys(otherProps).reduce((agg, p) => {
        const expressionValue = exp(otherProps[p], context);

            agg[p] = expressionValue;

        return agg;
    }, {});
    
    return children.map((child, index) => React.cloneElement(child, { key : index, ...childProps }));
}

Connect.defaultProps = {
}

export default Connect;