import React from 'react'
import PropTypes from 'prop-types'

import serverTime from '../common/serverTime';


const getPeriodBaseMinute = (periodIndex) => { 
    switch(periodIndex) {
        case 0: return 0;
        case 1: return 45;
        case 2: return 90;
        case 3: return 105;
        case 4: return 120;
        default: return 120;
    }
}

const getNominalPeriodDuration = (periodIndex) => { 
    switch(periodIndex) {
        case 0: 
            return 45;
        case 1:
            return 90;
        case 2:
            return 105;
        case 3:
            return 120;
        case 4:
            return 135;
        default: 
            return 135;
    }
}

const getStops = (periodIndex) => { 
    switch(periodIndex) {
        case 0: 
        case 1: 
            return [0, 45, 90];
        case 2: 
        case 3: 
            return [0, 45, 90, 105, 120]
        case 4: 
            return [0, 45, 90, 105, 120, 135]
        default: 
            return [0, 45, 90];
    }
}

class SoccerClock extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            period: 0,
            
            minutes: 0,
            seconds: 0,

            nominalMinutes: 0,
            nominalSeconds: 0,
            
            maximumMinutes : 0,

            stops : [],
            
            started: false,
            paused: false,
            finished: false
        };

        this.timer = null;
        this.update = this.update.bind(this);

        
    }

    componentDidMount() {
        this.timer = setInterval(() => { this.update(this.props) }, 1000);
        this.update(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.update(nextProps);
    }

    componentWillUnmount() {
       clearInterval(this.timer);
    }

    update() {
        const { match } = this.props;

        const periods = match.periods || [];

        if (periods.length == 0) {
            this.setState({
                period : 1,
                
                minutes : 0,
                seconds : 0,

                nominalMinutes : 0,
                nominalSeconds : 0,
    
                maximumMinutes : 90,
    
                paused: false,
    
                stops : getStops(0),
    
                started: false,
                finished: false
            });
            return;

        }


        const period = periods.length;
        const baseMinute = getPeriodBaseMinute(period - 1);

        const currentPeriod = periods[periods.length - 1];
        const end = currentPeriod.end ? new Date(currentPeriod.end) : serverTime.now();
        
        let time = end - new Date(currentPeriod.start);

        if (time < 0)
            time = 0;

        let seconds = parseInt(time / 1000) + (baseMinute * 60);

        let minutes = Math.floor(seconds / (60));
        seconds -= minutes * (60);

        const nominalMinutes = getNominalPeriodDuration(period - 1);


        this.setState({
            period,
            
            minutes,
            seconds,

            nominalMinutes : nominalMinutes < minutes ? nominalMinutes : minutes,
            nominalSeconds : nominalMinutes < minutes ? 0 : seconds,

            maximumMinutes : 90,

            paused: !!currentPeriod.end,

            stops : getStops(periods.length - 1),

            started: match.state === 'live',
            finished: match.state === 'update' || match.state === 'completed'
        });
    }

    render() {
        return this.props.children(this.state)
    }
}

SoccerClock.propTypes = {
    match: PropTypes.object.isRequired
}

export default SoccerClock;
