// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KFKOCDKmRU0e54fNPJTh{font-size:11px;line-height:13px;display:flex;align-items:center}.zt6OYOpFKiU2JVvlCEky{display:flex;align-items:center;margin-right:6px}.ugpbuu1NtnIRVL_jABgn{color:#8a8a8a;text-transform:uppercase;margin-right:3px}.mkEVbG7T0pEb8vt8qOLk{color:#ffb800;font-size:0}`, "",{"version":3,"sources":["webpack://./src/components/Odds.scss"],"names":[],"mappings":"AAAA,sBACC,cAAA,CAEA,gBAAA,CACA,YAAA,CACA,kBAAA,CAGD,sBACC,YAAA,CACA,kBAAA,CACA,gBAAA,CAGD,sBACC,aAAA,CACA,wBAAA,CACA,gBAAA,CAGD,sBACC,aAAA,CACA,WAAA","sourcesContent":[".odds {\r\n\tfont-size: 11px;\r\n\r\n\tline-height: 13px;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n}\r\n\r\n.outcome {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tmargin-right: 6px;\r\n}\r\n\r\n.type {\r\n\tcolor: #8A8A8A;\r\n\ttext-transform: uppercase;\r\n\tmargin-right: 3px;\r\n}\r\n\r\n.hot { \r\n\tcolor: #FFB800; \r\n\tfont-size: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export var odds = `KFKOCDKmRU0e54fNPJTh`;
export var outcome = `zt6OYOpFKiU2JVvlCEky`;
export var type = `ugpbuu1NtnIRVL_jABgn`;
export var hot = `mkEVbG7T0pEb8vt8qOLk`;
export default ___CSS_LOADER_EXPORT___;
