import React from 'react'
import PropTypes from 'prop-types'

import { Filterbar } from 'components'
import FormContext from '../../FormContext'

const ChangesWidget = ({ items }) => {
    return (
        <FormContext.Consumer>{ ({ changes, defaults, data, removeElement }) => {
            {



                const filteredChanges = items && items.length > 0 ? changes.filter(c => data[c.name] !== defaults[c.name]) : changes;


                return (
                    <Filterbar>
                         {
                             filteredChanges.map((c, index) => {
                                return (
                                    <Filterbar.Item key={ index } onRemove={ () => removeElement(c.name, c.index) }>{ c.label }</Filterbar.Item>
                                 )
                            })
                         }
                    </Filterbar>
                )
            }
        }}</FormContext.Consumer>
    )
}

export default ChangesWidget;