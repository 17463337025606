import React from 'react';
import PropTypes from 'prop-types';


import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { currency, number } from 'utils'

import PlayerIdentity from '../PlayerIdentity'
import { ErrorBoundary } from '../../components';
import ContentBanner from '../ContentBanner';


const Currency = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ currency(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const getPosition = (item, prefix) => {
    const ev = item.events.find(e => e.type.name.indexOf(prefix) === 0);
    if (ev) {
        return parseInt(ev.type.name.substring(prefix.length));
    };

    return 0;
}

const PlayerList = ({ heading, items }) => (
    <React.Fragment>
        <Table.Head>
            <Table.Row>
                <Table.Header>{ heading }</Table.Header>
            </Table.Row>
        </Table.Head>
        
        <Table.Body>
            { 
                items.map((item, index) => {
                    const disabled = item.stats.disqualified > 0 || item.stats.retired > 0;

                    return (
                        <Table.Row key={ index } disabled={ disabled }>
                            <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                        </Table.Row>
                    )
                })
            }
        </Table.Body>
    </React.Fragment>
)

const Drivers = ({ items }) => {

    const drivers = items.filter(i => i.player.position.slug === 'chassis').sort((a, b) => {
        const aPos = getPosition(a, 'EndPosition');
        const bPos = getPosition(b, 'EndPosition');

        return aPos - bPos;
    });

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  drivers.length > 0 && <PlayerList items={ drivers } heading="Konstruktører" /> }
                </Table>

            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                {  drivers.length > 0 &&
                    
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center>Position</Table.Header>
                                <Table.Header center>Point</Table.Header>
                            </Table.Row>
                        </Table.Head>

                        <Table.Body>
                            { 
                                drivers.map((item, index) => {
                                    const endPosition = getPosition(item, 'EndPosition');
                                    const points = item.stats.worldCupPoints;
                                    const disabled = item.stats.disqualified > 0 || item.stats.retired > 0;
                                  
                                    return (
                                        <Table.Row key={ index } disabled={ disabled }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ endPosition } /></Table.Cell>
                                            <Table.Cell center><Number value={ points } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const MotorsportDriverListWidget = () => {
    return (
        <>
            <ContentBanner />
        <ErrorBoundary>
            <MatchContext.Consumer>{ (match) => {
                return (
                    <WithMatchEvents id={ match.id }>{ ({ events }) => (
                        <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                            <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {
                                return (
                                    <React.Fragment>
                                        <Drivers items={ items }/>
                                    </React.Fragment>
                                )
                            }}</WithMatchReport>
                        )}</WithMatchFantasyEvents>
                    )}</WithMatchEvents>
                )
            }}</MatchContext.Consumer>
        </ErrorBoundary>
        </>
    )
}


MotorsportDriverListWidget.propTypes = {
 
}

export default MotorsportDriverListWidget;