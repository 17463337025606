import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { NavBar, NavItem,  Box } from '../../../components';

import useFlyout from '../../../hooks/useFlyout'

import translatable from '../../../hocs/translatable';

import UserMenu from './../../UserMenu'



const RoutedDesktopSiteHeader = () => {
    let logoImageUrl = window.swush.logoImageUrl;
    const menuItems = (window.swush.ui && window.swush.ui.menuItems) || [];

    const brand = <a href="/"><img src={ logoImageUrl} /></a>;
    const aux = (
        <Box display="flex" alignItems="center">
            <UserMenu />
        </Box>
    );


    return (
        
            <NavBar brand={ brand } aux={ aux }>
                {
                    menuItems.filter(m => m.type === 'primary' || m.type === 'external').map((i, index) => {
                        return <NavItem key={ index } href={ i.url } external={ i.type === 'external' }>{ i.label }</NavItem>
                    })
                }
            </NavBar>
        
    )
}

RoutedDesktopSiteHeader.propTypes = {
  me: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isAuthenticated : PropTypes.bool.isRequired
}

function mapStateToProps(state) {
    const { isAuthenticated, isAuthenticating, username } = state.auth || { isAuthenticated : false };
    const { notificationCount } = state.ui;

    const { usersById , me } = {
        usersById : state.user.byId,
        me : state.user.me,
        
    };


    return { 
        me : usersById[me] || {},
        username,
        isAuthenticated, 
        isAuthenticating,
        notificationCount
    }
}


export default translatable(connect(mapStateToProps)(RoutedDesktopSiteHeader));
