import React from 'react'
import PropTypes from 'prop-types'

import { ListView } from './ListView'
import Checkable from './Checkable'

class CheckBoxButtonList extends React.PureComponent {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(item) {
        if (item.disabled)
            return;

        const list = this.props.selected;

        const index = list.indexOf(item.value);
        if (index >= 0) {
            const nList = [...list.slice(0, index), ...list.slice(index + 1)];
            this.props.onChange(nList);
        }
        else {
            
            this.props.onChange([...list, item.value]);
        }
    }

    render() {
        const children = this.props.items.map((item, index) => {
            const checked = this.props.selected.indexOf(item.value) >= 0;

            return (
                <Checkable key={ index } checkedIcon="checkboxChecked" uncheckedIcon="checkbox"  checked={ checked } disabled={ item.disabled } onClick={ () => this.onClick(item) }>
                    { item.text }
                </Checkable>
            )
        });

        return (
            <ListView>
                { children }
            </ListView>
        )     
    }
}


CheckBoxButtonList.propTypes = {
    selected : PropTypes.array.isRequired,
    items : PropTypes.array.isRequired,
    onChange : PropTypes.func.isRequired
}

CheckBoxButtonList.defaultProps = {
    selected : [],
    items : []
}

export default CheckBoxButtonList;