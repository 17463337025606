import React from 'react';

import * as fromComponents from 'components'

export const ColumnLayoutColumnEditor = fromComponents.ColumnLayoutColumn;
export const ColumnLayoutEditor = ({ children }) => {
    return (
            <fromComponents.ColumnLayout>
                { children }
            </fromComponents.ColumnLayout>
    );
    
}


export const ColumnLayoutColumn = fromComponents.ColumnLayoutColumn;
export const ColumnLayout = fromComponents.ColumnLayout;

