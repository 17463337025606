import axios from 'axios'

import auth from '../../common/auth'

import * as types from './types';


/* Recruits */

const fetchRecruitsRequest = () => ({ type: types.FETCH_RECRUITS_REQUEST });
const fetchRecruitsSuccess = (payload) => ({ type: types.FETCH_RECRUITS_SUCCESS, payload });
const fetchRecruitsFailure = () => ({ type: types.FETCH_RECRUITS_FAIL });

export const fetchRecruits = (competitionId) => (dispatch) => { 
    dispatch(fetchRecruitsRequest());
    const accessToken = auth.getAccessToken();

    return axios.get(`/competitions/${ competitionId }/recruits?access_token=${ accessToken }`).then(response => {
        dispatch(fetchRecruitsSuccess(response.data));
    }, e => {
        dispatch(fetchRecruitsFailure());
    });
}


export const fetchRecruitsFromSelectedOption = () => (dispatch, getState) => { 
    const state = getState();
    const selectedOption = state.recruits.selectedOption;
    const option = state.recruits.options.find(o => o.id === selectedOption);

    dispatch(fetchRecruitsRequest());
    const accessToken = auth.getAccessToken();

    return axios.get(`${ option.links.results }&access_token=${ accessToken }`).then(response => {
        dispatch(fetchRecruitsSuccess(response.data));
    }, e => {
        dispatch(fetchRecruitsFailure());
    });
}


const fetchMoreRecruitsRequest = () => ({ type: types.FETCH_MORE_RECRUITS_REQUEST });
const fetchMoreRecruitsSuccess = (payload) => ({ type: types.FETCH_MORE_RECRUITS_SUCCESS, payload });
const fetchMoreRecruitsFailure = () => ({ type: types.FETCH_MORE_RECRUITS_FAIL });

export const fetchMoreRecruits = () => (dispatch, getState) => { 
    const state = getState();
    const { isFetching } = state.recruits;

    
    if (!isFetching) {
        const { next } = state.recruits.links;

        if (next) {
            dispatch(fetchMoreRecruitsRequest());

            const accessToken = auth.getAccessToken();
            return axios.get(`${ next }&access_token=${ accessToken }`).then(response => {
                dispatch(fetchMoreRecruitsSuccess(response.data));
            }, e => {
                dispatch(fetchMoreRecruitsFailure());
            });
        }
    }
}





export const selectRecruitmentOption = (value) => ({ type : types.SELECT_RECRUITMENT_OPTION, value });