import * as signalR from '@microsoft/signalr'
import auth from './auth'


class WebSocketClient {
	constructor() {
		this.connection = null;
		this.onClose = this.onClose.bind(this);
		this.retryAttempt = 0;
	}

	initialize(url) {
		this.url = url;
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: () => auth.getAccessToken() })
			.withAutomaticReconnect()
			.build();

		this.connection.onclose(this.onClose);
	}

	on(method, handler) { 
		this.connection.on(method, handler);	
	}
	
	connect() { 

		return this.connection.start()
			.then(() => { 
				console.log('[WebSocket] Connected');
				document.dispatchEvent(new Event('wsConnected'));
			})
			.catch(error => { 
				console.error('[WebSocket] Error', error);
				document.dispatchEvent(new Event('wsError'));
			});
		
		
	}

	onClose(err) {
		if (err) {
			document.dispatchEvent(new Event('wsDisconnected'));
		}
	}
}

export default new WebSocketClient();