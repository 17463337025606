import React from 'react'
import { createGlobalStyle } from 'styled-components'

import geomanistLight from '../fonts/Geomanist-Light-Webfont/geomanist-light-webfont.woff';
import geomanistUltra from '../fonts/Geomanist-Ultra-Webfont/geomanist-ultra-webfont.woff';


const GlobalStyle = createGlobalStyle`
    

   .sw-root {
	/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
	*/

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, strong, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		font: inherit;
        vertical-align: baseline;
        
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	body {
		line-height: 1;
        --scrollbar-width: calc(100vw - 100%);
	}
	ol, ul {
		list-style: none;
	}
	blockquote, q {
		quotes: none;
	}
	blockquote:before, blockquote:after,
	q:before, q:after {
		content: '';
		content: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
}

    .native-section { display: none; }

    :root {
        --button-transparent-color: #000;

        --vertical-rythm: 48px;
        --padding: 12px;
        --border-radius: 3px;
    }

    body {
        background: ${ props => props.theme.colors.siteBackground };
        color: ${ props => props.theme.colors.siteForeground };
        font-family: ${ props => props.theme.fonts.base };
        margin: 0;
        padding: 0;
        min-height: 100vh;

        margin-top: 48px;
    }

    @media screen and (min-width : 1024px) {
        body {
            margin-top: 0;
        }
    }

        
    table {
        border-collapse: separate;
        border-spacing: 0;
        font-size: 14px;
        line-height: 1;
        margin: 0;

        
    }


    .meta {
            color: var(--meta-text-color);
            a {
                color: var(--meta-link-color);
                &:hover {
                    color: var(--meta-link-hover-color);
                }
            }
        }


        .action { color: ${ props => props.theme.colors.themes.default.action } }

        .active { color: ${ props => props.theme.colors.themes.default.action } }

        .a { color: ${ props => props.theme.colors.themes.default.action }; }

        .letterspace { letter-spacing: 1px;}
        .uppercase { text-transform: uppercase; }
        .lh1 { line-height: 1;}
        .pretty-number { font-feature-settings: 'tnum' 1; }


        .game-name {
            font-family: 'geomanist';
            text-transform: uppercase;
            font-weight: 900;
            font-size: 16px;
            
            &--edition {
                font-weight: 300;
                text-transform: none;
            }
        }


    * { box-sizing: border-box; }

    a { 
        color: ${ props => props.theme.colors.themes.default.action };

        text-decoration: none;
        cursor: pointer;

        &:hover { color:  ${ props => props.theme.colors.themes.default.actionHover }; }
        &:focus { color:  ${ props => props.theme.colors.themes.default.actionHover }; }
    }

    #sw-header-root { display: block !important; font-size: 14px; }
    #sw-root { font-size: 16px; }


    .show-for-large { display: none; }

    @media screen and (min-width: 1024px) {
        .show-for-large {  display: block; }
    }

    img { max-width: 100%; height: auto;}

    .neg { color: #f99; }

    small { font-size: 11px;}
    .no-margin {
    margin:0 !important;
}



.hoe {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width:100%;
}

.fh
{
    height: var(--vertical-rythm);
}
.lh1 { line-height: 1; }

h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font-family);
    margin: 0 0 12px 0;
    line-height: 24px;
}

h1 { font-size: 24px; letter-spacing: 1px; }
h2 { font-size: 24px; font-weight: normal; }
h3 { font-size: 20px; font-weight: normal; }
h4 { font-size: 18px; font-weight: normal; }
h5 { font-size: 16px; font-weight: normal; }

p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
}

ul, ol {
    line-height: 24px;
}

hr {
    margin: 12px 0;
    height: 0px;
    border: solid ${ props => props.theme.colors.borderUp };
    border-width: 1px 0 0;
}


    .text-muted { color: var(--muted); }

    .mb-m { margin-bottom: 12px; }



    .mb-0 { margin-bottom: 3px; }
    .mb-1 { margin-bottom: 6px; }
    .mb-2 { margin-bottom: 9px; }
    .mb-3 { margin-bottom: 12px; }
    .mb-4 { margin-bottom: 36px; }
    .mb-5 { margin-bottom: 48xpx; }
    .mb-6 { margin-bottom: 72px; }
    .mb-7 { margin-bottom: 96px; }


    .mx-0 { margin-left: 3px; margin-right: 3px; }
    .mx-1 { margin-left: 6px; margin-right: 6px; }
    .mx-2 { margin-left: 9px; margin-right: 9px; }
    .mx-3 { margin-left: 12px; margin-right: 12px; }
    .mx-4 { margin-left: 36px; margin-right: 36px; }
    .mx-5 { margin-left: 48px; margin-right: 48px; }
    .mx-6 { margin-left: 72px; margin-right: 72px; }
    .mx-7 { margin-left: 96px; margin-right: 96px; }

    .my-0 { margin-top: 3px; margin-bottom: 3px; }
    .my-1 { margin-top: 6px; margin-bottom: 6px; }
    .my-2 { margin-top: 9px; margin-bottom: 9px; }
    .my-3 { margin-top: 12px; margin-bottom: 12px; }
    .my-4 { margin-top: 36px; margin-bottom: 36px; }
    .my-5 { margin-top: 48px; margin-bottom: 48px; }
    .my-6 { margin-top: 72px; margin-bottom: 72px; }
    .my-7 { margin-top: 96px; margin-bottom: 96px; }


    .ios-webview #sw-header { display: none; }
    
    @media screen and (max-width: 768px) {
        .infobox { display: none !important; }
    }

`

export default GlobalStyle;