import React from 'react'

import { MatchCardList, MatchCard, EventDate, Icon } from '../../components'

import translatable from '../../hocs/translatable'

const GolfMatchCard = React.memo(({ t, onClick, item }) => {
    const properties = item.properties || {};

    return (
        <MatchCard pre={ item.status === 'pending' } live={ item.status === 'live' } current={ item.current } post={ item.status === 'updated' || item.status === 'completed' } onClick={ onClick }>
            <MatchCard.SimpleEventContent round={ item.round }  title={ item.name } />

            <MatchCard.Footer>
                { properties.tournamentPrize && <span>{ properties.tournamentPrize }</span> }
                { properties.tournamentCategory && <span>{ t('golf.classFormat', properties.tournamentCategory) }</span> }
                <span><EventDate date={ new Date(item.start) } format="short" /></span>
            </MatchCard.Footer>
            
            { item.status === 'updated' && <MatchCard.Icon><Icon type="flag" size="1-2x" /></MatchCard.Icon> }
        </MatchCard> 
    )
})


const GolfScheduleCardView = ({ t, onSelectMatch, schedule }) => {
    return (
        <MatchCardList>
            { schedule.items.map(item => <GolfMatchCard t={ t } key={ item.id }  item={ item } onClick={ () => onSelectMatch(item) } />)  }
        </MatchCardList>
    )
}

export default translatable(GolfScheduleCardView);