import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import Icon from './Icon'

import { number } from '../common/utils'

import * as styles from './TeamList.scss';


const COLORMAP = {
    'basic' : '#B6B2A8',
    'silver' : '#B6B2A8',
    'gold' : '#FFB800'
};



class TeamList extends React.PureComponent {
    render() {

        return null;
        const { items, onSelect } = this.props;


        if (!items || items.length === 0) {
            return null;
        }


        const className = classNames(
            
           
        );

        return (
           <div className={ className }>
                {
                    items.map((item, index) => {
                        return <div key={ index } className={ styles.item } onClick={ () => onSelect(item) }>
                                    <span style={{ color : COLORMAP[item.level] }}>
                                        <Icon type="dot" />
                                    </span>
                                    <div className={ styles.name }>
                                        { item.name }
                                    </div>
                                    <div>
                                        { item.highscore && number(item.highscore.rankByValue) }
                                    </div>
                               </div>

                    })
                }
           </div>
        )
    }  
}

TeamList.propTypes = {
    items : PropTypes.array.isRequired,
}


export default TeamList;
