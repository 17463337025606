import React from 'react'

import { ListView, ListViewItem, Spinner, FantasyTeamIdentity, Box, XButton } from 'components'

import useMyEnrollments from './useMyEnrollments'
import useModal from '../containers/useModal'
import useCompetition from './useCompetition'
import useCurrentGame from '../containers/useCurrentGame'
import { useTranslator } from '../hooks/useTranslator'

const MyEnrollments = ({ competitionId, ...otherProps }) => {
    const [showModal] = useModal();
    const [myEnrollments, isFetching] = useMyEnrollments(competitionId);
    const [competition] = useCompetition(competitionId)
    const game = useCurrentGame();
    const t = useTranslator();

    const handleResign = (item) => {
        
        

        if (competition.type == 'cup') {
            const firstRound = game.rounds[competition.firstRound - 1];
            if (new Date() > new Date(firstRound.close)) {
                alert(t('competitions.cannot_change_enrollment_after_start_error'));
                return;
            }
        }

        showModal('resign', { competitionId, fantasyTeamId : item.fantasyTeam.id });
    }


    if (isFetching)
        return <Spinner />

    return (
        <Box {...otherProps}>
            <ListView>
                {
                    myEnrollments && myEnrollments.map(item => (
                        <ListViewItem key={ item.id } px="m" display="flex" alignItems="center">
                            <Box flex={ 1 }>
                                <FantasyTeamIdentity fantasyTeam={ item.fantasyTeam } />
                            </Box>
                            <Box>
                                <XButton size="xs" variant="outline" textTransform="uppercase"  onClick={ () => handleResign(item) }>{ t('competitions.resign_caption') }</XButton>
                            </Box>
                        </ListViewItem>
                    ))
                }
            </ListView>

            <Box textAlign="right" mt="m">
                <XButton size="s" variant="primary" textTransform="uppercase" onClick={ () => showModal('enrollment', { competitionId }) }>{ t('competitions.enroll_caption') }</XButton>
            </Box>
        </Box>
    )
}

export default MyEnrollments;