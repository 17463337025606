import React from 'react'
import PropTypes from 'prop-types'

import serverTime from '../common/serverTime';

class SoccerMatchClock extends React.PureComponent{
  constructor(props) {
    super(props);

    this.state = {
        minutes : 0,
        seconds : 0
    };

    this.timer = null;
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.timer = setInterval(() => { this.tick(this.props) }, 1000);
    this.tick(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
      this.tick(nextProps);
  }

  componentWillUnmount() {
      clearInterval(this.timer);
  }

    getMinuteBaseFromPeriods(periods) {
        switch(periods) {
            case 0:
            case 1:
                return 0;
            case 2:
                return 45;
            case 3:
                return 90;
            case 4:
                return 105;
            case 5:
            default:
                return 120;
        }
  }

  tick(props) {
    const { periods } = this.props.match || { periods : [] };
    
    if (!periods || periods.length === 0) {
        this.setState({
            minutes: 0,
            seconds: 0
        });

        return;
    }

    const minuteBase = this.getMinuteBaseFromPeriods(periods.length);
    const lastPeriod = periods[periods.length - 1];
    const to = lastPeriod.end ? new Date(lastPeriod.end) : serverTime.now();

    let milliseconds = (to - new Date(lastPeriod.start)) + (minuteBase * 60 * 1000);
    
    if (milliseconds < 0)
        milliseconds = 0;

    let minutes = Math.floor(milliseconds / 60000);
    milliseconds -= minutes * (60000);

    let seconds = Math.floor(milliseconds / 1000);
    milliseconds -= seconds * 1000;

    this.setState({
        minutes,
        seconds
    });
  }

    renderPart(value) {
        const sVal = value + '';
        return sVal.length < 2 ? '0' + sVal : sVal;
    }


    render() {
        const { match } = this.props;

        if (!match.periods || match.periods.length === 0) {
            return null;
        }
    
        const paused = match.periods.length > 0 && !!match.periods[match.periods.length - 1].end;

        return (
            <span>
                { paused ?  'P' :  match.periods.length + 'H' } 

                { ' ' }
                {this.renderPart(this.state.minutes) }
                :
                {this.renderPart(this.state.seconds) }
            </span>
        )
    }  
}

SoccerMatchClock.propTypes = {
    match : PropTypes.object.isRequired,
    
}

export default SoccerMatchClock;
