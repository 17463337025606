import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pushFlyout, popFlyout, clearFlyouts } from '../redux/ui/actions';


import { FlyoutStack, ErrorBoundary } from '../components'

import WithFlyouts from './WithFlyouts'

import TournamentStandingsFlyout from './TournamentStandingsFlyout'
import MatchReportFlyout from './MatchReportFlyout'
import PlayerFlyout from './PlayerFlyout'
import ViewFlyout from './ViewFlyout'

import FindCompetitionFlyout from '../competitions/FindCompetitionFlyout'
import CompetitionFlyout from '../competitions/CompetitionFlyout'
import CompetitionPreviewFlyout from '../competitions/CompetitionPreviewFlyout'
import NotificationFlyout from './NotificationFlyout';

const map = {
    view : ViewFlyout,
    match : MatchReportFlyout,
    standings : TournamentStandingsFlyout,
    player: PlayerFlyout,
    competition : CompetitionFlyout,
    competition_preview : CompetitionPreviewFlyout,
    find_competition: FindCompetitionFlyout,
    notifications: NotificationFlyout
}



const FlyoutConductor = () => {
    const dispatch = useDispatch();
    const flyouts = useSelector(state => state.ui.flyouts);

    const pop = useCallback(() => { dispatch(popFlyout()); }, []);
    

    
    return (
        <FlyoutStack onPop={ pop }>
            { 
                flyouts.map((f, index) => {
                    const Component = map[f.view];

                    return (
                        <ErrorBoundary key={ index } >
                            <Component { ...f.props } onClose={ pop }/>
                        </ErrorBoundary>
                    )
                })
            }
        </FlyoutStack>
    )

};


export default FlyoutConductor;