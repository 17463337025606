import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

const FantasyTeamLink = ({ fantasyTeam }) => {
    return (
        <span>
            { fantasyTeam.isDeleted ? 
                <em className="meta">(Deleted)</em> : fantasyTeam.name }
                <Icon type="dot" size="1-2x" className={ (fantasyTeam.level === 'gold' ? 'gold' : 'silver') } />
        </span>
    )
}

FantasyTeamLink.propTypes = {
    fantasyTeam: PropTypes.object.isRequired,
}

export default FantasyTeamLink;






