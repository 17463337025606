import axios from 'axios'
import { normalize } from 'normalizr'

import { themeSchema  } from '../../schemas';

import * as types from './types';

/* Fetch User Competition Leaderboards */

const fetchThemeRequest = (id) => ({ type: types.FETCH_THEME_REQUEST, id });
const fetchThemeSuccess = (id, payload) => ({ type: types.FETCH_THEME_SUCCESS, id, payload });
const fetchThemeFailure = (id) => ({ type: types.FETCH_THEME_FAIL, id });

const fetchTheme = (id) => (dispatch) => { 
    dispatch(fetchThemeRequest(id));

    return axios.get(`/themes/${ id }`).then(response => {
        dispatch(fetchThemeSuccess(id, normalize(response.data, themeSchema)));
    }, e => {
        dispatch(fetchThemeFailure(id));
    });
}


const shouldFetchTheme = (state, id) => {
    const { isFetching, didInvalidate, item } = state.theme.byId[id] || { isFetching : false, didInvalidate : false, item : null};

    if (isFetching) {
        return false;
    }
    else if (!item) {
        return true;
    }
    else {
        return didInvalidate;
    }
}

export const fetchThemeIfNeeded = (id) => (dispatch, getState) => {
    if (shouldFetchTheme(getState(), id)) {
        dispatch(fetchTheme(id));
    }
}
