import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import * as styles from './CoverImage.scss'

const CoverImage = ({ disabled, tint, vignetteSize, vignetteStrength, url, children }) => {
    
    const vignetteColor = `rgba(0, 0, 0, ${ vignetteStrength })`;

    let backgrounds = [];


    const tintStyle = tint && { backgroundColor: tint };
    
    if (vignetteSize[0] >= 0) {
        backgrounds.push(`linear-gradient(to bottom, ${ vignetteColor }, transparent ${ vignetteSize[0] * 100 }%)`);
    }

    if (vignetteSize[1] >= 0) {
        backgrounds.push(`linear-gradient(to left, ${ vignetteColor }, transparent ${ vignetteSize[1] * 100 }%)`);
    }

    if (vignetteSize[2] >= 0) {
        backgrounds.push(`linear-gradient(to top, ${ vignetteColor }, transparent ${ vignetteSize[2] * 100 }%)`);
    }

    if (vignetteSize[3] >= 0) {
        backgrounds.push(`linear-gradient(to right, ${ vignetteColor }, transparent ${ vignetteSize[3] * 100 }%)`);
    }

    if (url) {
        backgrounds.push(`url(${ url })`);
    }
    
    const style = {
        backgroundImage : backgrounds.join(', ')
    }
    
    
    const className = classNames(
        styles.container,
        {
            [styles.disabled] : disabled
        }
    )

    return (
        <div style={ style } className={ className}>
            { tint && <div style={ tintStyle } className={ styles.tint }></div> }
            <div className={ styles.content }>
                { children }
            </div>
        </div>
    )
}


CoverImage.propTypes = {
    disabled : PropTypes.bool,
    tint : PropTypes.string
}

CoverImage.defaultProps = {
    vignetteSize : [0.2, 0.2, 0.2, 0.2],
    vignetteStrength : 0.1
}

export default CoverImage;