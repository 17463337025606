import * as types from './types'

const defaultState = {
    byTournament : {

    }
}


export default function injury (state = defaultState, action) 
{
    switch (action.type) {
        case types.FETCH_TOURNAMENT_INJURIES_REQUEST:
            return {
                ...state,
                byTournament : {
                    ...state.byTournament,
                    [action.tournament] : {
                        ...state.byTournament[action.tournament],
                        isFetching : true
                    }
                }
            };
        case types.FETCH_TOURNAMENT_INJURIES_SUCCESS:
            return {
                ...state,
                byTournament : {
                    ...state.byTournament,
                    [action.tournament] : {
                        items : action.payload.result,
                        isFetching : false,
                        didInvalidate : false
                    }

                }
            };
        case types.FETCH_TOURNAMENT_INJURIES_FAIL:
                return {
                    ...state,
                    byTournament : {
                        ...state.byTournament,
                        [action.tournament] : {
                            items : [],
                            isFetching : false,
                            didInvalidate : false
                        }
                    }
                };
        default:
            return state
    }
}