import React from 'react';
import PropTypes from 'prop-types';


import { ScrollTable, Table } from 'components'

import MatchContext from '../MatchContext'
import WithMatchReport from '../WithMatchReport'
import WithMatchFantasyEvents from '../WithMatchFantasyEvents'
import WithMatchEvents from '../WithMatchEvents'

import { currency, number, minute } from 'utils'

import PlayerIdentity from '../PlayerIdentity'


const Currency = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else if (value < 0)
        return <span className="neg">{ currency(value) }</span>
    else
        return <span>{ currency(value) }</span>;
}

const Number = ({ value }) => {
    if (typeof value === 'undefined')
        return <span className="meta">-</span>
    else return <span>{ number(value) }</span>;
}


const Minute = ({ value }) => {
    if (typeof value === 'undefined' || value === 0)
        return <span className="meta">-</span>

    else return <span>{ minute(value) }</span>;
}



const HandballLineup = ({ items, type }) => {

    const goalies = items.filter( p => p.participant.type == type && p.player.position.slug === 'handball_goalkeeper');
    const players = items.filter( p => p.participant.type == type && p.player.position.slug !== 'handball_goalkeeper').sort((a, b) => a.player.position.order - b.player.position.order);

    return (
        <ScrollTable>
            <ScrollTable.Left>
                <Table>
                    {  
                        goalies.length > 0 &&
                        <React.Fragment>
                            <Table.Head>
                                <Table.Row>
                                    <Table.Header>Målvogtere</Table.Header>
                                </Table.Row>
                            </Table.Head>
                            
                            <Table.Body>
                                { 
                                    goalies.map((item, index) => {
                                        return (
                                            <Table.Row key={ index }>
                                                <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </React.Fragment>
                    }

                    {
                        players.length > 0 &&
                        <React.Fragment>

                            <Table.Head>
                                <Table.Row>
                                    <Table.Header>Markspiller</Table.Header>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                { 
                                    players.map((item, index) => {
                                        
                                        return (
                                            <Table.Row key={ index }>
                                                <Table.Cell><PlayerIdentity player={ item.player } /></Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </React.Fragment>
                    }
                </Table>

            </ScrollTable.Left>
            <ScrollTable.Right>
                
                <Table>
                    
                {  goalies.length > 0 &&
                    
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center>Assist</Table.Header>
                                <Table.Header center>R</Table.Header>
                                <Table.Header center>RS</Table.Header>
                                <Table.Header center>Min</Table.Header>
                            </Table.Row>
                        </Table.Head>

                        <Table.Body>
                            { 
                                goalies.map((item, index) => {
                                    const assists = item.stats.assist;
                                    const reds = item.stats.redCard;
                                    const saves = item.stats.saves;
                                    const minutesPlayed = item.stats.minutesPlayed;

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ assists } /></Table.Cell>
                                            <Table.Cell center><Number value={ reds } /></Table.Cell>
                                            <Table.Cell center><Number value={ saves } /></Table.Cell>
                                            <Table.Cell center><Number value={ minutesPlayed } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }

                {  
                    players.length > 0 &&
                    <React.Fragment>
                        <Table.Head>
                            <Table.Row>
                                <Table.Header right>Vækst</Table.Header>
                                <Table.Header center>Mål</Table.Header>
                                <Table.Header center>Assist</Table.Header>
                                <Table.Header center>Gult</Table.Header>
                                <Table.Header center>2 min</Table.Header>
                            </Table.Row>
                        </Table.Head>
                    
                        <Table.Body>
                            { 
                                players.map((item, index) => {
                                    const goals = item.stats.goal;
                                    const assists = item.stats.assist;
                                    const yellows = item.stats.yellowCard;
                                    const twoMin = item.stats.twoMinuteSuspensions;

                                    return (
                                        <Table.Row key={ index }>
                                            <Table.Cell right><Currency value={ item.growth } /></Table.Cell>
                                            <Table.Cell center><Number value={ goals } /></Table.Cell>
                                            <Table.Cell center><Number value={ assists } /></Table.Cell>
                                            <Table.Cell center><Number value={ yellows } /></Table.Cell>
                                            <Table.Cell center><Minute value={ twoMin } /></Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </Table.Body>
                    </React.Fragment>
                }
                </Table>
            </ScrollTable.Right>
        </ScrollTable>

    )

}


const HandballLineupWidget = ({ type }) => {
    return (
        <MatchContext.Consumer>{ (match) => {

            return (
                <WithMatchEvents id={ match.id }>{ ({ events }) => (
                    <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                        <WithMatchReport fantasyEvents={ fantasyEvents } events={ events } match={ match }>{ (items) => {

                            
                            return (
                                <React.Fragment>
                                    <HandballLineup items={ items } type={ type } />
                                </React.Fragment>
                            )
                        }}</WithMatchReport>
                    )}</WithMatchFantasyEvents>
                )}</WithMatchEvents>
            )
        }}</MatchContext.Consumer>
        
    )
}


HandballLineupWidget.propTypes = {
    type : PropTypes.string.isRequired
}

export default HandballLineupWidget;