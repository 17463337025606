import React from 'react'

import { Box, Icon, ToolbarButtonGroup, ToolbarButton } from 'components'

const ContextMenu = ({ items, showLabel, onCommand }) => {
    return (
        <ToolbarButtonGroup variant="transparent" size="s">
            {
                items.map((item, index) => (
                    <ToolbarButton key={ index } onClick={ () => onCommand(item.command) }>
                        <Box mr={ showLabel && item.label && 's200' }>
                            { item.renderIcon ? item.renderIcon(item) : <Icon type={ item.icon } /> }
                        </Box>
                        { showLabel && item.label }
                        
                    </ToolbarButton>
                ))
            }
        </ToolbarButtonGroup>
    )
}


ContextMenu.defaultProps = {
    showLabel : true
}

export default ContextMenu;