import React, { useContext } from 'react';

const TargetingContext = React.createContext();

export const useTargeting = () => {
    return useContext(TargetingContext);
}

export const TargetingProvider = ({ value, children }) => {
    return (
        <TargetingContext.Provider value={ value }>
            { children }
        </TargetingContext.Provider>
    )
}