import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { pushFlyout, popFlyout, clearFlyouts } from '../redux/ui/actions';

class WithFlyout extends React.PureComponent {
    componentDidMount() {
    }

    render() {
        const { push, pop, clear, children } = this.props;
        return children({ push, pop, clear});
    }
}

const mapStateToProps = (state) => ({})

function mapDispatchToProps(dispatch, ownProps) {
    return {
        push(type, props) {
            dispatch(pushFlyout(type, props));
        },
        pop() {
            dispatch(popFlyout());
        },
        clear() {
            dispatch(clearFlyouts())
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(WithFlyout);

