import React from 'react'

import { Table, EventDate } from '../../components'
import MatchStatus from '../MatchStatus'
import translatable from '../../hocs/translatable'


const GenericScheduleTableRow = ({ item, onClick }) => {
    return (
        <Table.Row onClick={ onClick } active={ item.current }>
            <Table.Cell center>{ item.round }</Table.Cell>
            <Table.Cell><EventDate date={ item.start } /></Table.Cell>
            <Table.Cell>{ item.name }</Table.Cell>
            <Table.Cell right><MatchStatus status={ item.status } /></Table.Cell>
        </Table.Row>
    )
}

const GenericScheduleTable = ({ t, schedule, onSelectMatch }) => {
    return (
        <Table>
            <Table.Head>
                <Table.Row>
                    <Table.Header center>{ t('common.round') }</Table.Header>
                    <Table.Header>{ t('common.start') }</Table.Header>
                    <Table.Header>{ t('common.event') }</Table.Header>
                    <Table.Header right>{ t('common.status') }</Table.Header>
                </Table.Row>
            </Table.Head>
            <Table.Body>
                { schedule.items.map(item => <GenericScheduleTableRow key={ item.id } item={ item }  onClick={ () => onSelectMatch(item) } ></GenericScheduleTableRow>) }
            </Table.Body>
        </Table>
    )
}

export default translatable(GenericScheduleTable);