import React from 'react'
import PropTypes from 'prop-types'

import translatable from '../hocs/translatable'

const LocalizeText = ({ t, text }) => {

    if (!text || text.length == 0)
        return null;

    if (text.indexOf('@') == 0)
        return t(text.substring(1));
    else
        return text;
}


LocalizeText.propTypes = {
    text : PropTypes.string
}

export default translatable(LocalizeText);

