import React from 'react'
import PropTypes from 'prop-types'
import TextCountdown from './TextCountdown'

import * as styles from './Countdown.scss'

const Countdown = ({ to }) => {
    

    return (
        <TextCountdown to={ to }>
            {
                text => (<span className={ styles.countdown }>{ text }</span>)
            }
        </TextCountdown>
    )
}

Countdown.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired
}

export default Countdown;
