import React from 'react';
import {  useSelector } from 'react-redux';


import { Attachment, NotificationIcon } from 'components'

const ConnectedNotificationBadge = ({ children }) => {
    const notificationCount = useSelector(state => state.notification.count);

    if (notificationCount > 0)
    {
        return (
            <Attachment attachment={ notificationCount && <NotificationIcon count={ notificationCount } /> }>
                { children }
            </Attachment>
        )
    }
    else 
        return children;
}

export default ConnectedNotificationBadge;