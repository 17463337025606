import React from 'react'
import PropTypes from 'prop-types'

import { Datasource } from 'components'

import WithSchedule from '../../WithSchedule'

const Schedule = ({ team, name, editMode, children }) => {
    
    if (editMode) {
        return (
            <Datasource value={{ [name] : [{}, {}, {}] }}>
                {children }
            </Datasource> 
        )
    }
    else {
        return (
            <WithSchedule team={ team }>{ (schedule) => {
                return (
                    <Datasource value={{ [name] : schedule.schedule.items }}>
                        { children }
                    </Datasource>
                )
            }}</WithSchedule>
        )
    }
}

Schedule.propTypes = {
    team : PropTypes.string,
    name : PropTypes.string
}

export default Schedule;

