import React, { useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Redux
import { findCompetitions, findMoreCompetitions } from '../redux/competition/actions'
import { getCompetitionsSearchResult } from '../redux/competition/selectors'

// Components
import { 
    Flyout, FlyoutHeader, FlyoutContent, 
    ListViewItem, 
    Icon, 
    Box, Pica, ToolbarButtonGroup , ToolbarButton, FlatList,
    SplitView,
    SplitViewTopPane, SplitViewPane,
    HorizontalMenu
} from 'components' 
import { Search } from '../components/Forms';
import GameContext from '../containers/GameContext'
import LocalizeText from '../containers/LocalizeText';
import translatable from '../hocs/translatable';
import useFlyout from '../hooks/useFlyout'

import { PreviewCompetitionCard } from './components/CompetitionCard'
import { MenuItem } from '../components';

const useCompetitionSearch = (gameId, query, view) => {
    const dispatch = useDispatch();
    const searchResult = useSelector(state => getCompetitionsSearchResult(state));

    useEffect(() => {
        dispatch(findCompetitions(gameId, query, view));
    }, [gameId, query, view]);

    const fetchMore = () => {
        dispatch(findMoreCompetitions())
    }


    return [searchResult, fetchMore]
}


const FindCompetitionFlyout = translatable(({ t, match, onClose }) => {
    const { game } = useContext(GameContext);
    const [query, setQuery] = useState('');
    const [showFlyout] = useFlyout();
    const [view, setView] = useState('');
    const [searchResult, fetchMore] = useCompetitionSearch(game.id, query, view);
    

    const showCompetition = (competition) => {
        showFlyout('competition_preview', { competitionId : competition.id });
    }

    const Item = ({ index, style}) => {
        const item = (searchResult.items || [])[index];
        
        if (item == null)
        return <ListViewItem></ListViewItem>

        return (
            <Box key={ item.id }  style={ style }>
                <PreviewCompetitionCard  competition={ item } onClick={ () => showCompetition(item) }/>
            </Box>
        )
    }

    return (
        <Flyout>
            <FlyoutHeader px="m" display="flex" alignItems="center" themeName="_current">
                <Pica flex="1"><LocalizeText text="@competitions.find_mini_league" /></Pica>
                <ToolbarButtonGroup>
                    <ToolbarButton onClick={ onClose } >
                        <Icon type="close"/>
                    </ToolbarButton>
                </ToolbarButtonGroup>
            </FlyoutHeader>
            <FlyoutContent>
                <HorizontalMenu>
                    <MenuItem active={ view == ''} onClick={ () => setView('') }>{ t('competitions.all_menu_item') }</MenuItem>
                    <MenuItem active={ view == 'public'} onClick={ () => setView('public') }>{ t('competitions.only_public_menu_item') }</MenuItem>
                </HorizontalMenu>
                <SplitView>
                    <SplitViewTopPane>
                        <Box p="s400">
                            <Search  placeholder={ t('competitions.league_name_placeholder') } autofocus value={ query } onSearch={ value => setQuery(value) }  buttonCaption={ t('common.search_caption') } />
                        </Box>
                    </SplitViewTopPane>
                    <SplitViewPane>
                            <FlatList 
                                key={ query } 
                                totalCount={ searchResult.count || 0 } 
                                items={ searchResult.items || [] } 
                                itemSize={ 112 }
                                onLoadMore={ fetchMore }>
                                    { Item }
                                
                            </FlatList>
                    </SplitViewPane>
                </SplitView>
            </FlyoutContent>
        </Flyout>
    )
})


export default withRouter(FindCompetitionFlyout);
