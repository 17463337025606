import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Datasource } from 'components'

import GameContext from '../../GameContext'
import MatchContext from '../../MatchContext'
import WithMatchFantasyEvents from '../../WithMatchFantasyEvents'
import WithMatchEvents from '../../WithMatchEvents'

import { camelize } from 'utils'

const MatchPlayerStatistics = ({ output, match, tournament, events, fantasyEvents, children }) => {

    const items = useMemo(() => {
        let data = {};
        fantasyEvents.reduce((agg, e) => {
            if (!e.type)
                return agg;

            if (!agg[e.player.id]) {
                const player = tournament.players.find(p => p.id == e.player.id);
                const participant = (match.participants || []).find(pa => pa.team.id === player.team.id);

                agg[e.player.id] = {
                    player : tournament.players.find(p => p.id == e.player.id),
                    participant,
                    stats : {},
                    growth: 0
                }
            }

            const name = camelize(e.type.name);

            if (typeof agg[e.player.id].stats[name] === 'undefined') {
                agg[e.player.id].stats[name] =e.amount;    
            }
            else {
                agg[e.player.id].stats[name] =  agg[e.player.id].stats[name] + e.amount;
            }

            
            agg[e.player.id].growth += e.amount * e.type.value;

            return agg;
        }, data);

        events.reduce((agg, e) => {
            if (!e.type)
                return agg;

            if (!agg[e.player.id]) {
                const player = tournament.players.find(p => p.id == e.player.id);
                const participant = (match.participants || []).find(pa => pa.team.id === player.team.id);

                agg[e.player.id] = {
                    player : tournament.players.find(p => p.id == e.player.id),
                    participant,
                    stats : {},
                    growth: 0
                }
            }


            const name = camelize(e.type.name);
            if (typeof agg[e.player.id].stats[name] === 'undefined') {
                agg[e.player.id].stats[name] =e.amount;    
            }
            else {
                agg[e.player.id].stats[name] =  agg[e.player.id].stats[name] + e.amount;
            }


            
            return agg;
        }, data);

        return Object.values(data);
    }, [match, tournament, events, fantasyEvents]);
    

    return (
        <Datasource value={{ [output || 'current'] : items }}>
            { children }
        </Datasource>
    )
}

const MatchPlayerStatisticsWrapper = ({ output, editMode, children }) => {
    if (editMode) {
        return (
            <Datasource value={{ [output || 'current'] : [{}, {},{},{},{}] }}>
                {  children  }
            </Datasource>
        )
    }

    const match = useContext(MatchContext);
    const { tournament } = useContext(GameContext);

    return (
        <WithMatchEvents id={ match.id }>{ ({ events }) => (
            <WithMatchFantasyEvents id={ match.id }>{ ({ fantasyEvents }) => (
                <MatchPlayerStatistics output={ output } match={ match } tournament={ tournament } events={ events } fantasyEvents={ fantasyEvents}>{ children }</MatchPlayerStatistics>
            )}</WithMatchFantasyEvents>
        )}</WithMatchEvents>
    )
}


MatchPlayerStatisticsWrapper.propTypes = {
}

export default MatchPlayerStatisticsWrapper;