import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmailIdentity, OnetimePasswordInput, Form, FormField,HelpLabel, XButton, AuthenticationHeader, Fieldset } from 'components'
import { BusyButton } from '../../molecules'
import { signupSubmitCode, goto } from '../../redux/auth/actions'
import { useTranslator } from '../../hooks/useTranslator'

export const SignupCode = ({  }) => {
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const t = useTranslator();
    const { email } = useSelector(state => state.auth.data);
    const { problems, isBusy } = useSelector(state => state.auth.signup);
    const inputRef = useRef()

    const submitEnabled = code && code.length > 0;
   
    // Focus input on start.
    useEffect(() => {
        inputRef.current.focus();
    }, [inputRef.current])


    const onSubmitInternal = e => {
        e.preventDefault();
        if (submitEnabled) {
            dispatch(signupSubmitCode(email, code));
        }
    }

    const onRetry = () => {
        dispatch(goto('signup'))
    }

    return (
        <React.Fragment>
            <AuthenticationHeader>{ t('authentication.createAccountHeader') }</AuthenticationHeader>
            <p>{ t('authentication.createAccountCodeSentMessage') }</p>

            <EmailIdentity email={ email } />

            <Form problems={ problems } onSubmit={ onSubmitInternal }>
                <Fieldset>
                    <FormField name="code">
                        <HelpLabel text={ t('authentication.codeLabel' )}>
                            <XButton size="xs" variant="transparent" textTransform="uppercase" onClick={ onRetry }>{ t('authentication.tryAgainButtonCaption') }</XButton>
                        </HelpLabel>
                        <OnetimePasswordInput ref={ inputRef } value={ code } onChange={ (e) => setCode(e.target.value)} />
                    </FormField>
                </Fieldset>

                <BusyButton type="submit" disabled={ !submitEnabled } busy={ isBusy } label={ t('authentication.busyButtonCaption') } primary onClick={ onSubmitInternal }>{ t('authentication.continueButtonCaption') }</BusyButton>
            </Form>
        </React.Fragment>
    )
}
