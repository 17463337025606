export const FETCH_PRECOMPILED_VIEWS_REQUEST = 'views/precompiled/fetch/request';
export const FETCH_PRECOMPILED_VIEWS_SUCCESS = 'views/precompiled/fetch/success';
export const FETCH_PRECOMPILED_VIEWS_FAILURE = 'views/precompiled/fetch/fail';

export const FETCH_COMPILED_VIEW_BY_NAME_REQUEST = 'FETCH_COMPILED_VIEW_BY_NAME_REQUEST';
export const FETCH_COMPILED_VIEW_BY_NAME_SUCCESS = 'FETCH_COMPILED_VIEW_BY_NAME_SUCCESS';
export const FETCH_COMPILED_VIEW_BY_NAME_FAILURE = 'FETCH_COMPILED_VIEW_BY_NAME_FAILURE';

export const FETCH_VIEW_BY_NAME_REQUEST = 'FETCH_VIEW_BY_NAME_REQUEST';
export const FETCH_VIEW_BY_NAME_SUCCESS = 'FETCH_VIEW_BY_NAME_SUCCESS';
export const FETCH_VIEW_BY_NAME_FAILURE = 'FETCH_VIEW_BY_NAME_FAILURE';

export const FETCH_VIEWS_REQUEST = 'view/list/request';
export const FETCH_VIEWS_SUCCESS = 'view/list/success';
export const FETCH_VIEWS_FAILURE = 'view/list/failure';

export const FETCH_VIEW_REQUEST = 'view/fetch/request';
export const FETCH_VIEW_SUCCESS = 'view/fetch/success';
export const FETCH_VIEW_FAILURE = 'view/fetch/failure';

export const UPDATE_VIEW_REQUEST = 'view/update/request';
export const UPDATE_VIEW_SUCCESS = 'view/update/success';
export const UPDATE_VIEW_FAILURE = 'view/update/failure';
